import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";
import { refreshAccessToken } from "../authUtils.js";

const initialState = {
  profile: {},
  loading: false,
  error: null,
};

const profile = async (language) => {
  let token = localStorage.getItem("accessToken"); // Retrieve the token from local storage

  try {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_User/profile/${language}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Set the Bearer token in the headers
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      token = await refreshAccessToken(); // Refresh the access token
      const retryResponse = await axios.get(
        `${baseUrl}/apiTITSol/TTG24_User/profile/${language}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Set the new Bearer token in the headers
          },
        }
      );

      return retryResponse.data;
    } else {
      throw new Error("Failed to fetch profile");
    }
  }
};

export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  async (language) => {
    const response = await profile(language);
    return response;
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default profileSlice.reducer;
