import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  packages: {},
  pageIndex: 1,
  totalPages: 1,
  loading: false,
  error: null,
};

export const fetchPackages = createAsyncThunk(
  "packages/fetchPackages",
  async ({
    pageIndex,
    searchTerm,
    currency,
    Sort,
    selectedStyle,
    selectedDestination,
    groupSize,
    durations,
    roomType,
    roomNumber,
    destinationId,
    dateValues,
    language,
  }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Packages/${language}?PageIndex=${pageIndex}&RoomType=${roomType}&RoomNumber=${roomNumber}&DateValues=${dateValues}&DestinationId=${destinationId}&Search=${searchTerm}&Sort=${Sort}&currency=${currency}&styles=${selectedStyle.join(
        ","
      )}&destinations=${selectedDestination.join(
        ","
      )}&groupSize=${groupSize.join(",")}&durations=${durations.join(",")}`
    );
    return response.data;
  }
);

const packagesSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    setPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.loading = false;
        state.packages = action.payload;
        state.pageIndex = action.payload.pageIndex;
        state.totalPages = action.payload.totalPages;
      })

      .addCase(fetchPackages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setPageIndex } = packagesSlice.actions;

export default packagesSlice.reducer;
