import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  bookingCabins: {},

  loadingBookingCabins: false,
  errorBookingCabins: null,
};

export const fetchBookingCabins = createAsyncThunk(
  "bookingCabins/fetchBookingCabins",
  async ({
    opid,
    cruises_adultsCruises,
    cruises_childrenCruises,
    currency,
    language,
  }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_NileCruise/${language}/CruiseBookingCabins?OPID=${opid}&AdultNo=${cruises_adultsCruises}&ChildNo=${cruises_childrenCruises}&currency=${currency}`
    );
    return response.data;
  }
);

const bookingCabinsSlice = createSlice({
  name: "bookingCabins",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookingCabins.pending, (state) => {
        state.loadingBooking = true;
      })

      .addCase(fetchBookingCabins.fulfilled, (state, action) => {
        state.loadingBooking = false;
        state.bookingCabins = action.payload;
      })

      .addCase(fetchBookingCabins.rejected, (state, action) => {
        state.loadingBooking = false;
        state.errorBooking = action.error.message;
      });
  },
});

export default bookingCabinsSlice.reducer;
