import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { juniperUrl } from "./juniperUrl.js";

export const fetchCstDataForHotel = createAsyncThunk(
  "cstDataForHotel/fetchCstData",
  async ({ BookingId, currency }) => {
    try {
      const response = await axios.get(
        `${juniperUrl}/HotelBookingReview/en?BookingID=${BookingId}&currency=${currency}`
      );
      console.log("API Response:", response.data); // Log the API response
      return response.data;
    } catch (error) {
      console.error("API Error:", error); // Log any error
      throw new Error("Network response was not ok");
    }
  }
);

const cstDataSlice = createSlice({
  name: "cstDataForHotel",
  initialState: {
    cstDataForHotel: {},
    loadingCstData: false,
    errorCstData: null,
    isErrorCstData: false,
  },
  reducers: {
    deleteExtraID: (state, action) => {
      state.cstDataForHotel.List_tbl_Juniper_Bookings_Extra =
        state.cstDataForHotel.List_tbl_Juniper_Bookings_Extra?.filter(
          (extra) => extra.BookingExtraID !== action.payload
        );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCstDataForHotel.pending, (state) => {
        state.loadingCstData = true;
        state.isErrorCstData = false;
      })
      .addCase(fetchCstDataForHotel.fulfilled, (state, action) => {
        console.log("Fulfilled Action Payload:", action.payload); // Log the payload
        state.loadingCstData = false;
        state.isErrorCstData = false;
        state.cstDataForHotel = action.payload;
      })
      .addCase(fetchCstDataForHotel.rejected, (state, action) => {
        console.error("Rejected Action Error:", action.error.message); // Log the error
        state.loadingCstData = false;
        state.isErrorCstData = true;
        state.errorCstData = action.error.message; // Ensure correct error field
      });
  },
});

export const { deleteExtraID } = cstDataSlice.actions;
export default cstDataSlice.reducer;
