import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl";
import { refreshAccessToken } from "../authUtils.js";
// Async thunk for updating the profile
export const updateProfile = createAsyncThunk(
  "profile/update",
  async (profileData, { rejectWithValue }) => {
    let token = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(
        `${baseUrl}/apiTITSol/TTG24_User/UpdateProfile`,
        profileData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        token = await refreshAccessToken(); // Refresh the access token
        const retryResponse = await axios.get(
          `${baseUrl}/apiTITSol/TTG24_User/UpdateProfile`,
          profileData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Set the new Bearer token in the headers
            },
          }
        );

        return retryResponse.data;
      } else {
        throw new Error("Failed to fetch profile");
      }
    }
  }
);

const profileUpdateSlice = createSlice({
  name: "profileUpdate",
  initialState: {
    loadingUpdateProfile: false,
    successUpdateProfile: false,
    errorUpdateProfile: null,
  },
  reducers: {
    resetProfileUpdateState: (state) => {
      state.loadingUpdateProfile = false;
      state.successUpdateProfile = false;
      state.errorUpdateProfile = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, (state) => {
        state.loadingUpdateProfile = true;
        state.successUpdateProfile = false;
        state.errorUpdateProfile = null;
      })
      .addCase(updateProfile.fulfilled, (state) => {
        state.loadingUpdateProfile = false;
        state.successUpdateProfile = true;
        state.errorUpdateProfile = null;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loadingUpdateProfile = false;
        state.successUpdateProfile = false;
        state.errorUpdateProfile = action.payload || "Failed to update profile";
      });
  },
});

export const { resetProfileUpdateState } = profileUpdateSlice.actions;
export default profileUpdateSlice.reducer;
