import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  transferPrice: "",
  loading: false,
  error: null,
};

export const fetchTransferPrice = createAsyncThunk(
  "transferPrice/fetchTransferPrice",
  async ({ productId, adultNumber, currency }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Transfers/GetTransferPrice?ProductId=${productId}&NumberOfAdults=${adultNumber}&currency=${currency}`
    );
    return response.data;
  }
);

const transferPriceSlice = createSlice({
  name: "transferPrice",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchTransferPrice.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchTransferPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.transferPrice = action.payload;
      })

      .addCase(fetchTransferPrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default transferPriceSlice.reducer;
