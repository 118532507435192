import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  packagePrice: "",
  loading: false,
  error: null,
};

export const fetchPackagePrice = createAsyncThunk(
  "packagePrice/fetchPackagePrice",
  async ({
    productId,
    startDate,
    numberOfchildrens2,
    numberOfchildrens1,
    roomType,
    roomNumber,
    currency,
  }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Packages/GetPackagePrice?ProductId=${productId}&DateValues=${startDate}&NumberOfchildrens2=${numberOfchildrens1}&NumberOfchildrens6=${numberOfchildrens2}&RoomType=${roomType}&RoomNumber=${roomNumber}&currency=${currency}`
    );
    return response.data;
  }
);

const packagePriceSlice = createSlice({
  name: "packagePrice",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchPackagePrice.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchPackagePrice.fulfilled, (state, action) => {
        state.loading = false;
        state.packagePrice = action.payload;
      })

      .addCase(fetchPackagePrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default packagePriceSlice.reducer;
