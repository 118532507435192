import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  service: {},
  loading: false,
  error: null,
};

export const fetchService = createAsyncThunk(
  "service/fetchService",
  async ({ sevicesUrl, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Home/${language}/about-us/${sevicesUrl}`
    );
    return response.data;
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchService.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchService.fulfilled, (state, action) => {
        state.loading = false;
        state.service = action.payload;
      })
      .addCase(fetchService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default serviceSlice.reducer;
