import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  tourPrice: "",
  loading: false,
  error: null,
};

export const fetchTourPrice = createAsyncThunk(
  "tourPrice/fetchTourPrice",
  async ({
    productId,
    startDate,
    numberOfchildrens2,
    numberOfchildrens1,

    adultNumber,
    currency,
  }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Tours/GetTourPrice?ProductId=${productId}&DateValues=${startDate}&NumberOfchildrens2=${numberOfchildrens1}&NumberOfchildrens6=${numberOfchildrens2}&NumberOfAdults=${adultNumber}&currency=${currency}`
    );
    return response.data;
  }
);

const tourPriceSlice = createSlice({
  name: "tourPrice",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchTourPrice.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchTourPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.tourPrice = action.payload;
      })

      .addCase(fetchTourPrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default tourPriceSlice.reducer;
