import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

export const fetchOrderHotelThanks = createAsyncThunk(
  "orderHotelThanksSlice/fetchOrderHotelThanks",
  async (orderId, thunkAPI) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Hotels/ThanksHotelConfirmation/en/${orderId}`
    );
    return response.data;
  }
);

const orderHotelThanksSliceSlice = createSlice({
  name: "orderHotelThanksSlice",
  initialState: {
    orderHotelThanks: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderHotelThanks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrderHotelThanks.fulfilled, (state, action) => {
        state.loading = false;
        state.orderHotelThanks = action.payload;
      })
      .addCase(fetchOrderHotelThanks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default orderHotelThanksSliceSlice.reducer;
