import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  newsDetails: {},
  loading: false,
  error: null,
};

export const fetchNewsDetails = createAsyncThunk(
  "newsDetails/fetchNewsDetails",
  async ({ newsUrl, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_News/${language}/${newsUrl}`
    );
    return response.data;
  }
);

const newsDetailsSlice = createSlice({
  name: "newsDetails",
  initialState,
  reducers: {
    setPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewsDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.newsDetails = action.payload;
      })
      .addCase(fetchNewsDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default newsDetailsSlice.reducer;
