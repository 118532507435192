import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

export const fetchOrderVisaDetails = createAsyncThunk(
  "orderVisaDetails/fetchOrderVisaDetails",
  async ({ orderId, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Visa/ThanksVisaConfirmation/${language}/${orderId}`
    );
    return response.data;
  }
);

const orderVisaDetailsSlice = createSlice({
  name: "orderVisaDetails",
  initialState: {
    orderVisaDetails: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderVisaDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrderVisaDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.orderVisaDetails = action.payload;
      })
      .addCase(fetchOrderVisaDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default orderVisaDetailsSlice.reducer;
