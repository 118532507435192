import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  bookingPackage: {},

  loadingBooking: false,
  errorBooking: null,
};

export const fetchBookingPackage = createAsyncThunk(
  "bookingPackage/fetchBookingPackage",
  async ({
    productId,
    startDate,
    numberOfchildrens2,
    numberOfchildrens1,
    roomType,
    roomNumber,
    currency,
    language,
  }) => {
    const response = await axios.get(
      `https://api.travco.com/apiTITSol/TTG24_Packages/${language}/BookingPackage?RoomType=${roomType}&RoomNumber=${roomNumber}&NumberOfchildrens2=${numberOfchildrens1}&NumberOfchildrens6=${numberOfchildrens2}&DateValues=${startDate}&ProductId=${productId}&currency=${currency}`
    );
    return response.data;
  }
);

const bookingPackageSlice = createSlice({
  name: "bookingPackage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookingPackage.pending, (state) => {
        state.loadingBooking = true;
      })

      .addCase(fetchBookingPackage.fulfilled, (state, action) => {
        state.loadingBooking = false;
        state.bookingPackage = action.payload;
      })

      .addCase(fetchBookingPackage.rejected, (state, action) => {
        state.loadingBooking = false;
        state.errorBooking = action.error.message;
      });
  },
});

export default bookingPackageSlice.reducer;
