import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  transfers: {},
  pageIndex: 1,
  totalPages: 1,
  loading: false,
  error: null,
};

export const fetchTransfers = createAsyncThunk(
  "transfers/fetchTransfers",
  async ({
    pageIndex,
    searchTerm,
    currency,
    Sort,
    selectedStyle,
    selectedDestination,
    participantNumber,
    DateValues,
    DestinationId,
    language,
  }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Transfers/${language}/GetTransfers?PageIndex=${pageIndex}&DestinationId=${DestinationId}&DateValues=${DateValues}&ParticipantNumber=${participantNumber}&Search=${searchTerm}&Sort=${Sort}&currency=${currency}&styles=${selectedStyle.join(
        ","
      )}&destinations=${selectedDestination.join(",")}`
    );
    return response.data;
  }
);

const transfersSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    setPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransfers.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchTransfers.fulfilled, (state, action) => {
        state.loading = false;
        state.transfers = action.payload;
        state.pageIndex = action.payload.pageIndex;
        state.totalPages = action.payload.totalPages;
      })

      .addCase(fetchTransfers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setPageIndex } = transfersSlice.actions;

export default transfersSlice.reducer;
