// NotFound.js

import React from "react";

const NotFound = () => {
  return (
    <section style={{ backgroundColor: "#e60000", padding: "0" }}>
      <h1 className="text-center text-light"></h1>
    </section>
  );
};

export default NotFound;
