// src/index.js
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./Responsive.css";
import { store } from "./data/store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import ReactGA from "react-ga4";

ReactGA.initialize("G-RS6ZVQS9YL"); // Replace with your GA4 Measurement ID
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="263736580748-di1finbitm46nff8hrbekfgbudb7iul8.apps.googleusercontent.com">
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </GoogleOAuthProvider>
);
