import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  packageDetails: {},
  loading: false,
  error: null,
};

export const fetchPackageDetails = createAsyncThunk(
  "packageDetails/fetchPackageDetails",
  async ({ productUrl, currency, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Packages/${language}/${productUrl}?currency=${currency}`
    );
    return response.data;
  }
);

const packageDetailsSlice = createSlice({
  name: "packageDetails",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchPackageDetails.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchPackageDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.packageDetails = action.payload;
      })

      .addCase(fetchPackageDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default packageDetailsSlice.reducer;
