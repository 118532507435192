import { configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./layoutSlice.js";
import homeReducer from "./homeSlice.js";
import hotelsReducer from "./juniper-hotels/hotelsSlice.js";
import aboutReducer from "./about-us/aboutSlice.js";
import destinationsReducer from "./destinations/destinationsSlice.js";
import destinationsEgyptReducer from "./destinations/destinationsEgyptSlice.js";
import travelIdeaReducer from "./travel-idea/travelIdeaSlice.js";
import travelIdeaExploreReducer from "./travel-idea/travelIdeaExploreSlice.js";
import serviceReducer from "./about-us/serviceSlice.js";
import contactUsReducer from "./contact-us/contactUsSlice.js";
import branchesReducer from "./branches/branchesSlice.js";
import brancheDetailsReducer from "./branches/brancheDetailsSlice.js";
import hotelsAvailabilitySlice from "./juniper-hotels/hotelAvailabilitySlice.js";
import roomBookingSlice from "./juniper-hotels/roomBookingSlice.js";
import reviewBookingSlice from "./juniper-hotels/reviewBookingSlice.js";
import cstDataSlice from "./juniper-hotels/cstDataSlice.js";
import removeExtraSlice from "./juniper-hotels/removeExtraSlice.js";
import paymentSlice from "./juniper-hotels/paymentSlice.js";
import orderHotelThanksReducer from "./juniper-hotels/orderHotelThanksSlice.js";
import newsReducer from "./news/newsSlice.js";
import newsDetailsReducer from "./news/newsDetailsSlice.js";
import cruisesReducer from "./cruises/cruisesSlice.js";
import cruiseDetailsReducer from "./cruises/cruiseDetailsSlice.js";
import bookingCruisesReducer from "./cruises/bookingCruisesSlice.js";
import bookingCabinsReducer from "./cruises/bookingCabinsSlice.js";
import checkoutReducer from "./cruises/checkoutSlice.js";
import cstDataForCruisesReducer from "./cruises/postCstDataSlice.js";
import orderCruiseDetailsReducer from "./cruises/orderCruiseDetailsSlice.js";
import packagesReducer from "./packages/packageSlice.js";
import packageDetailsReducer from "./packages/packageDetailsSlice.js";
import packagePriceReducer from "./packages/packagePriceSlice.js";
import bookingPackageReducer from "./packages/bookingPackagSlice.js";
import cstDataReducer from "./packages/postCstDataSlice.js";
import cstDataForTourReducer from "./tours/postCstDataSlice.js";
import toursReducer from "./tours/toursSlice.js";
import tourDetailsReducer from "./tours/tourDetailsSlice.js";
import tourPriceReducer from "./tours/tourPriceSlice.js";
import bookingTourReducer from "./tours/bookingTourSlice.js";
import orderReducer from "./tours/orderSlice.js";
import orderPackageDetailsReducer from "./packages/orderPackageDetailsSlice.js";
import resetPasswordReducer from "./login/resetPasswordSlice.js";
import transfersReducer from "./transfers/transfersSlice.js";
import transfersSearchReducer from "./transfers/transfersSearchSlice.js";
import transferDetailsReducer from "./transfers/transferDetailsSlice.js";
import bookingTransferReducer from "./transfers/bookingTransferSlice.js";
import cstDataForTransferReducer from "./transfers/postCstDataSlice.js";
import internationalOfficesReducer from "./international-offices/internationalOfficesSlice.js";
import formReducer from "./international-offices/formSlice.js";
import affiliatedCompaniesReducer from "./affiliated-companies/affiliatedCompaniesSlice.js";
import affiliatedCompaniesDetailsReducer from "./affiliated-companies/affiliatedCompaniesDetailsSlice.js";
import transferPriceReducer from "./transfers/transferPriceSlice.js";
import orderTransferDetailsReducer from "./transfers/orderTransferDetailsSlice.js";
import testimonialsReducer from "./testimonials/testimonialsSlice.js";
import miceReducer from "./mice/miceSlice.js";
import miceDetailsReducer from "./mice/miceDetailsSlice.js";
import loginDataReducer from "./login/loginDataSlice.js";
import signUpDataReducer from "./sign-up/signUpDataSlice.js";
import destinationGuideReducer from "./destination-guide/destinationGuideSlice.js";
import destinationGuideDetailsReducer from "./destination-guide/destinationGuideDetailsSlice.js";
import destinationHandbookReducer from "./destination-handbook/destinationHandbookSlice.js";
import destinationHandbookDetailsReducer from "./destination-handbook/destinationHandbookDetailsSlice.js";
import brochuresReducer from "./brochures/brochuresSlice.js";
import currencyReducer from "./currencySlice.js";
import beOurPartnerReducer from "./be-our-partner/beOurPartnerSlice.js";
import visaReducer from "./visa/visaSlice.js";
import entryVisaReducer from "./visa/entryVisaSlice.js";
import orderVisaDetailsReducer from "./visa/orderVisaDetailsSlice.js";
import privacyReducer from "./privacy-policy/privacySlice.js";
import termsReducer from "./terms-and-condition/termsSlice.js";
import signUpReducer from "./sign-up/signUpSlice.js";
import loginReducer from "./login/loginSlice.js";
import profileReducer from "./profile/profileSlice.js";
import ordersReducer from "./orders/ordersSlice.js";
import packagesOrdersReducer from "./orders/packagesOrdersSlice.js";
import cruisesOrdersReducer from "./orders/cruisesOrdersSlice.js";
import toursOrdersReducer from "./orders/toursOrdersSlice.js";
import transfersOrdersReducer from "./orders/transfersOrdersSlice.js";
import visaOrdersReducer from "./orders/visaOrdersSlice.js";
import hotelsOrdersReducer from "./orders/hotelsOrdersSlice.js";
import passwordUpdateReducer from "./profile/passwordUpdateSlice.js";
import profileUpdateReducer from "./profile/profileUpdateSlice.js";
import languageReducer from "./languageSlice";
export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    home: homeReducer,
    hotels: hotelsReducer,
    about: aboutReducer,
    destinations: destinationsReducer,
    destinationsEgypt: destinationsEgyptReducer,
    travelIdea: travelIdeaReducer,
    travelIdeaExplore: travelIdeaExploreReducer,
    service: serviceReducer,
    contactUs: contactUsReducer,
    branches: branchesReducer,
    brancheDetails: brancheDetailsReducer,
    hotelsAvailability: hotelsAvailabilitySlice,
    roomBooking: roomBookingSlice,
    reviewBooking: reviewBookingSlice,
    cstDataForHotel: cstDataSlice,
    removeExtra: removeExtraSlice,
    payment: paymentSlice,
    news: newsReducer,
    newsDetails: newsDetailsReducer,
    cruises: cruisesReducer,
    cruiseDetails: cruiseDetailsReducer,
    bookingCruises: bookingCruisesReducer,
    bookingCabins: bookingCabinsReducer,
    checkout: checkoutReducer,
    cstDataForCruises: cstDataForCruisesReducer,
    orderCruiseDetails: orderCruiseDetailsReducer,
    packages: packagesReducer,
    packageDetails: packageDetailsReducer,
    packagePrice: packagePriceReducer,
    bookingPackage: bookingPackageReducer,
    cstData: cstDataReducer,
    tours: toursReducer,
    tourDetails: tourDetailsReducer,
    tourPrice: tourPriceReducer,
    bookingTour: bookingTourReducer,
    cstDataForTour: cstDataForTourReducer,
    transfers: transfersReducer,
    transfersSearch: transfersSearchReducer,
    transferDetails: transferDetailsReducer,
    transferPrice: transferPriceReducer,
    bookingTransfer: bookingTransferReducer,
    cstDataForTransfer: cstDataForTransferReducer,
    orderTransferDetails: orderTransferDetailsReducer,

    internationalOffices: internationalOfficesReducer,
    form: formReducer,
    affiliatedCompanies: affiliatedCompaniesReducer,
    affiliatedCompaniesDetails: affiliatedCompaniesDetailsReducer,
    testimonials: testimonialsReducer,
    mice: miceReducer,
    miceDetails: miceDetailsReducer,
    destinationGuide: destinationGuideReducer,
    destinationGuideDetails: destinationGuideDetailsReducer,
    destinationHandbook: destinationHandbookReducer,
    destinationHandbookDetails: destinationHandbookDetailsReducer,
    brochures: brochuresReducer,
    currency: currencyReducer,
    beOurPartner: beOurPartnerReducer,
    visa: visaReducer,
    entryVisa: entryVisaReducer,
    orderVisaDetails: orderVisaDetailsReducer,
    privacy: privacyReducer,
    terms: termsReducer,
    register: signUpReducer,
    login: loginReducer,
    profile: profileReducer,
    loginData: loginDataReducer,
    signUpData: signUpDataReducer,
    order: orderReducer,
    orderPackageDetails: orderPackageDetailsReducer,
    orderHotelThanks: orderHotelThanksReducer,
    orders: ordersReducer,
    packagesOrders: packagesOrdersReducer,
    cruisesOrders: cruisesOrdersReducer,
    toursOrders: toursOrdersReducer,
    transfersOrders: transfersOrdersReducer,
    visaOrders: visaOrdersReducer,
    hotelsOrders: hotelsOrdersReducer,
    resetPassword: resetPasswordReducer,
    passwordUpdate: passwordUpdateReducer,
    profileUpdate: profileUpdateReducer,
    language: languageReducer,
  },
});
