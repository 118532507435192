import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  transferDetails: {},
  loading: false,
  error: null,
};

export const fetchTransferDetails = createAsyncThunk(
  "transferDetails/fetchTransferDetails",
  async ({ productUrl, currency, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Transfers/${language}/${productUrl}?currency=${currency}`
    );
    return response.data;
  }
);

const transferDetailsSlice = createSlice({
  name: "transferDetails",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchTransferDetails.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchTransferDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.transferDetails = action.payload;
      })

      .addCase(fetchTransferDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default transferDetailsSlice.reducer;
