import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  tourDetails: {},
  loading: false,
  error: null,
};

export const fetchTourDetails = createAsyncThunk(
  "tourDetails/fetchTourDetails",
  async ({ productUrl, currency, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Tours/${language}/${productUrl}?currency=${currency}`
    );
    return response.data;
  }
);

const tourDetailsSlice = createSlice({
  name: "tourDetails",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchTourDetails.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchTourDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.tourDetails = action.payload;
      })

      .addCase(fetchTourDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default tourDetailsSlice.reducer;
