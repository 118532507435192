import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  cruiseDetails: {},
  loading: false,
  error: null,
};

export const fetchCruiseDetails = createAsyncThunk(
  "cruiseDetails/fetchCruiseDetails",
  async ({ cruiseUrl, currency, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_NileCruise/${language}/${cruiseUrl}?currency=${currency}`
    );
    return response.data;
  }
);

const cruiseDetailsSlice = createSlice({
  name: "cruiseDetails",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchCruiseDetails.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchCruiseDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.cruiseDetails = action.payload;
      })

      .addCase(fetchCruiseDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default cruiseDetailsSlice.reducer;
