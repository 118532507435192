import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../baseUrl.js";
import { jwtDecode } from "jwt-decode";
import { isTokenExpired } from "../authUtils.js";

const initialState = {
  loading: false,
  token: localStorage.getItem("accessToken") || "",
  refreshToken: localStorage.getItem("refreshToken") || "",
  user: {
    firstName: "",
    lastName: "",
  },
  error: null,
  message: "",
};

export const register = createAsyncThunk(
  "register/userRegister",
  async (User, thunkAPI) => {
    const res = await fetch(`${baseUrl}/apiTITSol/TTG24_User/Register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(User),
    });
    const data = await res.json();
    if (res.ok) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
    }
    return data;
  }
);
export const loginWithGoogle = createAsyncThunk(
  "login/loginWithGoogle",
  async (idToken, thunkAPI) => {
    try {
      const apiEndpoint =
        "https://api.travco.com/apiTITSol/TTG24_User/LoginWithGoogle";
      const response = await fetch(`${apiEndpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ idToken: idToken }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return thunkAPI.rejectWithValue(errorData);
      }

      const data = await response.json();
      const tokenExpiryTime = new Date();
      tokenExpiryTime.setSeconds(tokenExpiryTime.getSeconds() + data.expiresIn);
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      localStorage.setItem("tokenExpiry", tokenExpiryTime);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: "Failed to login with Google. Please try again.",
      });
    }
  }
);

export const refreshAccessToken = createAsyncThunk(
  "register/refreshAccessToken",
  async (_, thunkAPI) => {
    try {
      const refreshToken = thunkAPI.getState().login.refreshToken;
      const res = await fetch(`${baseUrl}/apiTITSol/TTG24_User/refresh-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken }),
      });

      if (!res.ok) {
        throw new Error("Failed to refresh token");
      }

      const data = await res.json();
      const tokenExpiryTime = new Date();
      tokenExpiryTime.setSeconds(tokenExpiryTime.getSeconds() + data.expiresIn);
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("tokenExpiry", tokenExpiryTime);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: "Failed to refresh access token",
      });
    }
  }
);

export const logout = createAsyncThunk(
  "register/logout",
  async (_, thunkAPI) => {
    let accessToken = localStorage.getItem("accessToken");

    if (isTokenExpired(accessToken)) {
      try {
        accessToken = await refreshAccessToken();
      } catch (error) {
        console.error("Failed to refresh token:", error);
        throw error;
      }
    }

    try {
      const response = await fetch(
        "https://api.travco.com/apiTITSol/TTG24_User/logout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userImage");
        localStorage.removeItem("tokenExpiry");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("email");
        localStorage.removeItem("userLoginToken");
        return true; // Indicate success
      } else {
        const errorData = await response.json();
        console.error("API Error:", errorData);
        throw new Error(`Failed to logout: ${errorData.message}`);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userImage");
        localStorage.removeItem("tokenExpiry");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("email");
        localStorage.removeItem("userLoginToken");
      }
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  }
);
const signUpSlice = createSlice({
  name: "register",
  initialState,

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.message = action.payload.message;

        state.user = {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        };
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to register user";
      })
      .addCase(loginWithGoogle.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.message = action.payload.message;

        state.user = {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        };
      })
      .addCase(loginWithGoogle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to login with Google";
      })
      .addCase(refreshAccessToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refreshAccessToken.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.accessToken;
      })
      .addCase(refreshAccessToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to refresh access token";
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        state.user = null;
        state.token = null;
        state.refreshToken = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default signUpSlice.reducer;
