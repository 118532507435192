import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  bookingTransfer: {},

  loadingBooking: false,
  errorBooking: null,
};

export const fetchBookingTransfer = createAsyncThunk(
  "bookingTransfer/fetchBookingTransfer",
  async ({ productId, startDate, language, numberOfAdults, currency }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Transfers/${language}/BookingTransfer?NumberOfParticipant=${numberOfAdults}&DateValues=${startDate}&ProductId=${productId}&currency=${currency}`
    );
    return response.data;
  }
);

const bookingTransferSlice = createSlice({
  name: "bookingTransfer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookingTransfer.pending, (state) => {
        state.loadingBooking = true;
      })

      .addCase(fetchBookingTransfer.fulfilled, (state, action) => {
        state.loadingBooking = false;
        state.bookingTransfer = action.payload;
      })

      .addCase(fetchBookingTransfer.rejected, (state, action) => {
        state.loadingBooking = false;
        state.errorBooking = action.error.message;
      });
  },
});

export default bookingTransferSlice.reducer;
