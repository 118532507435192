import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { juniperUrl } from "./juniperUrl.js";
export const fetchHotelsAvailability = createAsyncThunk(
  "hotelsAvailability/fetchHotelsAvailability",
  async (hotelData) => {
    const response = await fetch(`${juniperUrl}/HotelsAvailability`, {
      method: "POST",

      body: JSON.stringify(hotelData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  }
);
const hotelsAvailabilitySlice = createSlice({
  name: "hotelsAvailability",
  initialState: {
    hotelsAvailability: {},
    loading: false,
    error: null,
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelsAvailability.pending, (state) => {
        state.loading = true;
        state.isError = false;
      })
      .addCase(fetchHotelsAvailability.fulfilled, (state, action) => {
        state.loading = false;
        state.isError = false;
        state.hotelsAvailability = action.payload;
      })
      .addCase(fetchHotelsAvailability.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.error = action.error.message;
      });
  },
});

export default hotelsAvailabilitySlice.reducer;
