import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { juniperUrl } from "./juniperUrl.js";

export const fetchReviewBooking = createAsyncThunk(
  "reviewBooking/fetchReviewBooking",
  async (cstData) => {
    const response = await fetch(`${juniperUrl}/bookingsave`, {
      method: "POST",
      body: JSON.stringify(cstData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  }
);
const reviewBookingSlice = createSlice({
  name: "reviewBooking",
  initialState: {
    reviewBooking: {},
    loadingReview: false,
    errorReview: null,
    isErrorReview: false,
  },
  reducers: {
    deleteExtraID: (state, action) => {
      state.reviewBooking.Extra = state.reviewBooking.Extra.filter(
        (extra) => extra.ExtraId !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewBooking.pending, (state) => {
        state.loadingReview = true;
        state.isErrorReview = false;
      })
      .addCase(fetchReviewBooking.fulfilled, (state, action) => {
        state.loadingReview = false;
        state.isErrorReview = false;
        state.reviewBooking = action.payload;
      })
      .addCase(fetchReviewBooking.rejected, (state, action) => {
        state.loadingReview = false;
        state.isErrorReview = true;
        state.errorReview = action.error.message;
      });
  },
});

export default reviewBookingSlice.reducer;
