import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  travelIdea: {},
  loading: false,
  error: null,
};

export const fetchTravelIdea = createAsyncThunk(
  "travelIdea/fetchTravelIdea",
  async (language) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_TravelIdea/${language}`
    );
    return response.data;
  }
);

const travelIdeaSlice = createSlice({
  name: "travelIdea",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTravelIdea.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchTravelIdea.fulfilled, (state, action) => {
        state.loading = false;
        state.travelIdea = action.payload;

        state.totalPages = action.payload.totalPages;
      })

      .addCase(fetchTravelIdea.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default travelIdeaSlice.reducer;
