import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  tours: {},
  pageIndex: 1,
  totalPages: 1,
  loading: false,
  error: null,
};

export const fetchTours = createAsyncThunk(
  "tours/fetchTours",
  async ({
    pageIndex,
    searchTerm,
    currency,
    Sort,
    selectedStyle,
    selectedDestination,
    groupSize,
    durations,
    startTourDate,
    tourDestinationId,
    adultsTour,
    childrenTour,
    language,
  }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Tours/${language}?PageIndex=${pageIndex}&DestinationId=${tourDestinationId}&DateValues=${startTourDate}&NumberOfAdults=${adultsTour}&NumberOfChilds=${childrenTour}&Search=${searchTerm}&Sort=${Sort}&currency=${currency}&styles=${selectedStyle.join(
        ","
      )}&destinations=${selectedDestination.join(
        ","
      )}&groupSize=${groupSize.join(",")}&durations=${durations.join(",")}`
    );
    return response.data;
  }
);

const toursSlice = createSlice({
  name: "tours",
  initialState,
  reducers: {
    setPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTours.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchTours.fulfilled, (state, action) => {
        state.loading = false;
        state.tours = action.payload;
        state.pageIndex = action.payload.pageIndex;
        state.totalPages = action.payload.totalPages;
      })

      .addCase(fetchTours.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setPageIndex } = toursSlice.actions;

export default toursSlice.reducer;
