import React, { useState, useEffect } from "react";
import { Suspense } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { resetPassword, resetState } from "../../data/login/resetPasswordSlice";
import { useDispatch, useSelector } from "react-redux";
function ResetPassword() {
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { loading, success, error } = useSelector(
    (state) => state.resetPassword
  );
  useEffect(() => {
    if (success) {
      setOpen(true);
      dispatch(resetState());
    }
  }, [success, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPassword(email));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <section className="outter">
        <div
          id="rlr_banner_slider"
          className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
        >
          <div className="splide__track rlr-banner-splide__track">
            <ul className="splide__list height-internal-pages">
              <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                  <div className="overlay-banner mb-0"></div>
                  <Suspense fallback={<div>Loading...</div>}>
                    <img
                      src="/banner1.jpg"
                      alt="ResetPassword page"
                      loading="lazy"
                    />
                  </Suspense>
                </div>
                <article className=" rlr-banner-splide__content-wrapper container">
                  <div className="row justify-content-start">
                    <div className="col-xl-9">
                      <div className="rlr-banner-splide__header">
                        <h2
                          className="rlr-banner-splide__slogan rlr-section__title--main"
                          style={{ textShadow: "1px 1px 2px #000" }}
                        >
                          Reset Password
                        </h2>
                      </div>
                    </div>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <main id="rlr-main" className="rlr-main--fixed-top ">
        <div
          className="rlr-section__content--lg-top  mt-0"
          style={{ marginTop: "0px !important" }}
        >
          <section
            className="rlr-section rlr-section__mt rlr-account  mt-0"
            style={{ marginTop: "0px !important" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 offset-lg-12">
                  <form
                    className="rlr-authforms"
                    id="access"
                    onSubmit={handleSubmit}
                  >
                    <div className="rlr-authforms__header">
                      <h2>Reset Password</h2>
                    </div>
                    <div className="rlr-authforms__form row d-flex justify-content-center">
                      <div className="rlr-authforms__inputgroup col-lg-6 col-md-8">
                        <label className="rlr-form-label rlr-form-label--light required">
                          Email
                        </label>
                        <input
                          required
                          type="email"
                          name="CustomerEmailAddress"
                          className="form-control form-control--light"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-12 d-flex justify-content-center">
                        <div className="col-lg-4 col-md-8">
                          <button
                            type="submit"
                            className="btn mb-3 rlr-button rlr-button--fullwidth rlr-button--primary"
                            disabled={loading}
                          >
                            {loading
                              ? "Processing..."
                              : "Generate New Password"}
                          </button>
                        </div>
                      </div>
                      {error && <p>Error: {error.message}</p>}
                    </div>
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        Password reset link sent successfully!
                      </Alert>
                    </Snackbar>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default ResetPassword;
