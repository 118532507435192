import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchOrderDetails = createAsyncThunk(
  "order/fetchOrderDetails",
  async ({ orderId, language }) => {
    const response = await axios.get(
      `https://api.travco.com/apiTITSol/TTG24_Tours/ThanksTourConfirmation/${language}/${orderId}`
    );
    return response.data;
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderDetails: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.orderDetails = action.payload;
      })
      .addCase(fetchOrderDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default orderSlice.reducer;
