import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HotelBooking from "../../components/HotelBooking";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchHotels } from "../../data/juniper-hotels/hotelsSlice";
import Loader from "../../Loader";
const Hotels = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const { hotels, loading, error } = useSelector((state) => state.hotels);

  useEffect(() => {
    dispatch(fetchHotels(language));
  }, [dispatch, language]);
  return (
    // <>
    //   {loading ? (
    //     <Loader />
    //   ) : error ? (
    //     <section
    //       style={{ backgroundColor: "#e60000", padding: "200px 0 100px" }}
    //     >
    //       <h1 className="text-center text-light">{error}</h1>
    //     </section>
    //   ) : (
    <>
      <Helmet>
        <title>{hotels.pageDetails?.pageMetatagTitle || "Hotels"}</title>
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={`https://travco.com/${language}/hotels-destinations`}
          data-react-helmet="true"
        />
        <meta
          name="description"
          content={hotels.pageDetails?.pageMetatagDescription}
        />
        <meta
          property="og:title"
          content={hotels.pageDetails?.metatagTitleFacebook}
        />
        <meta
          property="og:description"
          content={hotels.pageDetails?.metatagDescriptionFacebook}
        />
        <meta
          name="twitter:title"
          content={hotels.pageDetails?.metatagTitleTwitter}
        />
        <meta
          name="twitter:description"
          content={hotels.pageDetails?.metatagDescriptionTwitter}
        />
        <link href="css/hotels.css" rel="stylesheet" type="text/css" />
        <link
          href="TITSolutions/WS/assets/hotel-form.css"
          rel="stylesheet"
          type="text/css"
        />
        {/* <script type="text/javascript">
          {`
                        window.$jbe = {
                            juniperLang: "en",
                            numberFormat: { "decimalSeparator": ".", "groupSeparator": ",", "decimals": 2, "fixedDecimals": false, "round": "trunc", "showDecimals": true },
                            deeplinkMode: false,
                            googleApiKey: "AIzaSyCkArXjIQf6jq9DN2f9WRU-9YdMUmEIwME",
                            dateFormat: "dd/MM/yyyy",
                            currencies: [
                                { "currency": "EGP", "prefix": "", "suffix": "EGP" },
                                { "currency": "USD", "prefix": "", "suffix": "&nbsp;USD" },
                                { "currency": "AED", "prefix": "", "suffix": "AED" },
                                { "currency": "QAR", "prefix": "", "suffix": "QAR" },
                                { "currency": "EUR", "prefix": "", "suffix": "&euro;" },
                                { "currency": "GBP", "prefix": "&pound;", "suffix": "" },
                                { "currency": "SAR", "prefix": "", "suffix": "SAR" }
                            ],
                            currency: "USD"
                        };
                    `}
        </script> */}
        <link
          rel="stylesheet"
          href="ajax/libs/font-awesome/5.15.3/css/all.min.css"
        />
        <style type="text/css">
          {`
                        .brochure:hover {
                            filter: drop-shadow(0px 0px 10px #e60000);
                            padding-bottom: 2px;
                        }

                        @media (min-width: 992px) {
                            #right-side .navigation-dropdown.account {
                                width: 238px;
                                padding-bottom: 0.5rem !important;
                            }

                            #right-side #currency {
                                width: 90px;
                                padding-bottom: 0.5rem !important;
                            }

                            #right-side .rlr-navigation.navigation-transparent .navigation-dropdown-item:first-child {
                                padding-top: 0.5rem !important;
                            }
                        }

                        @media (max-width: 767px) {
                            #hotel-searcher-_ctl1__ctl1__ctl1_pageBody_pageBody_searcher_ctlMultiSearcher__ctl1_ctlRoomSelector-room-selector-button {
                                margin-bottom: 18px;
                            }

                            .popover-content .form-control {
                                position: relative !important;
                                height: 60px !important;
                                margin-right: 10px;
                            }
                        }

                        .w-search {
                            width: 0 !important;
                            display: none !important;
                            transition: all 1s ease-in-out;
                        }

                        .navigation-item a {
                            cursor: pointer;
                        }

                        .searchform {
                            position: relative;
                            transition: all 1s;
                            width: 50px;
                            height: 50px;
                            box-sizing: border-box;
                            padding: 5px;
                        }

                        #inputsearch {
                            position: absolute;
                            top: 4px;
                            left: 0;
                            width: 100%;
                            height: 37px;
                            line-height: 30px;
                            outline: 0;
                            border: 0;
                            display: none;
                            border-radius: 20px;
                            padding: 0 20px;
                        }

                        .imageSearchIcon {
                            box-sizing: border-box;
                            width: 35px;
                            height: 35px;
                            position: absolute;
                            top: 5px;
                            right: 0;
                            border-radius: 50%;
                            color: #e60000;
                            text-align: center;
                            font-size: 1.2em;
                            transition: all 1s ease-out;
                        }

                        .searchform:hover {
                            width: 280px;
                            cursor: pointer;
                        }

                        .searchform:hover #inputsearch {
                            display: block;
                        }

                        .searchform:hover .imageSearchIcon {
                            background: #e60000;
                            color: black;
                            padding: 3px;
                        }
                             
}
                    `}
        </style>
      </Helmet>
      <aside className="rlr-hero--half-mast ">
        <div className="outter">
          <div
            id="rlr_banner_slider"
            className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
          >
            <div className="splide__track rlr-banner-splide__track">
              <ul className="splide__list height-internal-pages">
                <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                  <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                    <div className="overlay-banner"></div>
                    <Suspense fallback={<div>Loading...</div>}>
                      <img
                        className="rlr-banner-splide__banner-img"
                        src={
                          isMobile
                            ? hotels.pageDetails?.pageBannerMobile
                            : isTablet
                            ? hotels.pageDetails?.pageBannerTablet
                            : hotels.pageDetails?.pageBannerPc
                        }
                        alt={hotels.pageDetails?.pageTitle}
                        loading="lazy"
                      />
                    </Suspense>
                  </div>
                  <article className="rlr-banner-splide__content-wrapper container">
                    <div className="row justify-content-start">
                      <div className="col-xl-9">
                        <div className="rlr-banner-splide__header">
                          <h2
                            className="rlr-banner-splide__slogan rlr-section__title--main"
                            style={{ textShadow: "1px 1px 2px #000" }}
                          >
                            {hotels.pageDetails?.pageTitle}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <HotelBooking />
      <section
        className={`blog-standard-section  ${isMobile ? "" : "pt-200"} pb-70`}
      >
        <div className="container">
          <div className="main-post" style={{ borderBottom: "0px" }}>
            <div className="entry-content">
              <h3 className="rlr-section__title--main text-center pb-30 fs-1">
                {hotels.pageDetails?.pageTitle}
              </h3>
            </div>
          </div>
          <div
            className="SiteFontP text-center"
            dangerouslySetInnerHTML={{
              __html: `${hotels.pageDetails?.pageText}`,
            }}
          />
        </div>
      </section>
    </>
    //   )}
    // </>
  );
};
export default Hotels;
