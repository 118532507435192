import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { juniperUrl } from "./juniperUrl.js";

export const fetchRoomBooking = createAsyncThunk(
  "roomBooking/fetchRoomBooking",
  async (roomData) => {
    const response = await fetch(`${juniperUrl}/RoomDetails`, {
      method: "POST",
      body: JSON.stringify(roomData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  }
);
const roomBookingSlice = createSlice({
  name: "roomBooking",
  initialState: {
    roomBooking: {},
    loading: false,
    error: null,
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomBooking.pending, (state) => {
        state.loading = true;
        state.isError = false;
      })
      .addCase(fetchRoomBooking.fulfilled, (state, action) => {
        state.loading = false;
        state.isError = false;
        state.roomBooking = action.payload;
      })
      .addCase(fetchRoomBooking.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.error = action.error.message;
      });
  },
});

export default roomBookingSlice.reducer;
