import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

// Define the initial state of the cstData
const initialState = {
  cstData: {},
  loadingCstData: false,
  errorCstData: null,
};

// Define an async thunk to send the cstData data
export const postCstData = createAsyncThunk(
  "cstData/postCstData",
  async ({ cstData, language }) => {
    const response = await axios.post(
      `${baseUrl}/apiTITSol/TTG24_Packages/${language}/opInsertOrderPackage`,
      cstData
    );
    return response.data;
  }
);
const cstDataSlice = createSlice({
  name: "cstData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postCstData.pending, (state) => {
        state.loadingCstData = true;
      })
      .addCase(postCstData.fulfilled, (state, action) => {
        state.loadingCstData = false;
        state.cstData = action.payload;
      })
      .addCase(postCstData.rejected, (state, action) => {
        state.loadingCstData = false;
        state.errorCstData = action.error.message;
      });
  },
});

export default cstDataSlice.reducer;
