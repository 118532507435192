import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

export const fetchOrderTransferDetails = createAsyncThunk(
  "orderTransferDetails/fetchOrderTransferDetails",
  async ({ orderId, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Transfers/ThanksTransferConfirmation/${language}/${orderId}`
    );
    return response.data;
  }
);

const orderTransferDetailsSlice = createSlice({
  name: "orderTransferDetails",
  initialState: {
    orderTransferDetails: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderTransferDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrderTransferDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.orderTransferDetails = action.payload;
      })
      .addCase(fetchOrderTransferDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default orderTransferDetailsSlice.reducer;
