import { useMediaQuery } from "@mui/material";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BookingStepFour from "../../components/booking-steps/BookingStepFour";
import Swal from "sweetalert2";
import {
  deleteExtraID,
  fetchCstDataForHotel,
} from "../../data/juniper-hotels/cstDataSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { fetchRemoveExtra } from "../../data/juniper-hotels/removeExtraSlice";
import { fetchPayment } from "../../data/juniper-hotels/paymentSlice";
import { Spinner } from "react-bootstrap";

const HotelBookingReview = () => {
  const { BookingId } = useParams();
  const extraRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [extraIdDeleted, setExtraIdDeleted] = useState(null);
  const roomsHotelsLocalStorage = localStorage.getItem("roomsHotels");
  const adultsHotels1RLocalStorage = localStorage.getItem("adultsHotels1R");
  const adultsHotels2RLocalStorage = localStorage.getItem("adultsHotels2R");
  const adultsHotels3RLocalStorage = localStorage.getItem("adultsHotels3R");
  const totalAdults =
    Number(adultsHotels1RLocalStorage) +
    Number(adultsHotels2RLocalStorage) +
    Number(adultsHotels3RLocalStorage);
  const childrenHotels1RLocalStorage = localStorage.getItem("childrenHotels1R");
  const childrenAge1R1LocalStorage = localStorage.getItem("childrenAge1R1");
  const childrenAge1R2LocalStorage = localStorage.getItem("childrenAge1R2");
  const childrenHotels2RLocalStorage = localStorage.getItem("childrenHotels2R");
  const childrenAge2R1LocalStorage = localStorage.getItem("childrenAge2R1");
  const childrenAge2R2LocalStorage = localStorage.getItem("childrenAge2R2");
  const childrenHotels3RLocalStorage = localStorage.getItem("childrenHotels3R");
  const childrenAge3R1LocalStorage = localStorage.getItem("childrenAge3R1");
  const childrenAge3R2LocalStorage = localStorage.getItem("childrenAge3R2");
  const totalChildren =
    Number(childrenHotels1RLocalStorage) +
    Number(childrenHotels2RLocalStorage) +
    Number(childrenHotels3RLocalStorage);
  const { reviewBooking, loadingReview, errorReview, isErrorReview } =
    useSelector((state) => state.reviewBooking);
  const { cstDataForHotel, loadingCstData, errorCstData } = useSelector(
    (state) => state.cstDataForHotel
  );

  const currency = useSelector((state) => state.currency.currency);
  useEffect(() => {
    dispatch(fetchCstDataForHotel({ BookingId, currency }));
  }, [BookingId, dispatch, extraIdDeleted, currency]);

  const handleDelete = (extraId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you certain you want to delete this?",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#e60000",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes Confirm delete!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform the delete action here
        // Example: deleteItem(itemId);
        dispatch(deleteExtraID(extraId));
        setExtraIdDeleted(extraId);
        localStorage.setItem("scrollToExtra", "true");
        setTimeout(() => {
          window.location.reload();
        }, 2000);

        // Show another confirmation alert
        Swal.fire({
          title: "Deleted!",
          text: "Your item has been deleted.",
          icon: "success",
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "You have Cancelled it!.",
          icon: "error",
        });
      }
    });
  };
  useEffect(() => {
    // Check if the flag is set in localStorage
    const scrollToExtra = localStorage.getItem("scrollToExtra");

    if (scrollToExtra) {
      // Scroll to the extra section
      if (extraRef.current) {
        extraRef.current.scrollIntoView({ behavior: "smooth" });
      }

      // Remove the flag from localStorage
      localStorage.removeItem("scrollToExtra");
    }
  }, []);
  useEffect(() => {
    if (extraIdDeleted) {
      dispatch(fetchRemoveExtra({ extraIdDeleted, BookingId }));
    }
  }, [BookingId, dispatch, extraIdDeleted]);
  const { payment, loadingPayment, errorPayment, isErrorPayment } = useSelector(
    (state) => state.payment
  );
  useEffect(() => {
    dispatch(fetchPayment({ BookingId, currency }));
  }, [BookingId, dispatch, currency]);
  const handlePayment = (e) => {
    e.preventDefault();

    if (payment?.success && !isErrorPayment && !loadingPayment) {
      window.location = payment.PaymetURL;
    } else if (isErrorPayment) {
      alert(errorPayment);
    }
  };

  return (
    <>
      {" "}
      <Helmet>
        <title>{cstDataForHotel.PageDetails?.PageMetatagTitle}</title>
        <meta
          name="description"
          content={cstDataForHotel.PageDetails?.PageMetatagDescription}
        />

        <link
          href="TITSolutions/WS/assets/bookingReview.css"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>
      <aside className="rlr-hero--half-mast ">
        <div className="outter">
          <div
            id="rlr_banner_slider"
            className="splide rlr-banner-splide rlr-banner-splide--fullwidth-v2"
          >
            <div className="splide__track rlr-banner-splide__track">
              <ul className="splide__list height-internal-pages">
                <li className="splide__slide height-internal-pages rlr-banner-splide__slide">
                  <div className="rlr-banner-splide__image-wrapper height-internal-pages">
                    <div className="overlay-banner"></div>
                    <Suspense fallback={<div>Loading...</div>}>
                      <img
                        className="rlr-banner-splide__banner-img"
                        src={
                          isMobile
                            ? `images/uploads/1.jpg`
                            : isTablet
                            ? `images/uploads/2.jpg`
                            : `images/uploads/Banner-Hotel-Booking.jpg`
                        }
                        alt={cstDataForHotel.PageDetails?.PageTitle || "Hotels"}
                        loading="lazy"
                      />
                    </Suspense>
                  </div>
                  <article className="rlr-banner-splide__content-wrapper container">
                    <div className="row justify-content-start">
                      <div className="col-xl-9">
                        <div className="rlr-banner-splide__header">
                          <h2
                            className="rlr-banner-splide__slogan rlr-section__title--main"
                            style={{ textShadow: "1px 1px 2px #000" }}
                          >
                            {cstDataForHotel.PageDetails?.PageTitle || "Hotels"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <BookingStepFour />
      <section id="extra" ref={extraRef}>
        <div className="container">
          <div className="row gutters">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="invoice-container">
                    <div className="invoice-header">
                      <div className="row gutters">
                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                          <div className="invoice-details">
                            <address>
                              <h5>
                                {" "}
                                <i className="fa-mytravel-hotel mr-2 text-primary font-weight-semi-bold font-size-22"></i>
                                {cstDataForHotel.Details_tbl_Hotels?.HotelName}
                              </h5>
                              <i className="fa-mytravel-pin-1 mr-2 text-primary font-weight-semi-bold font-size-22"></i>
                              {cstDataForHotel.Details_tbl_Hotels?.HotelAddress}
                              <br />
                              <b>Check-in:</b>{" "}
                              {
                                cstDataForHotel.Details_tbl_Juniper_Bookings
                                  ?.BookingStartDate
                              }
                              <br />
                              <b>Check-out:</b>{" "}
                              {
                                cstDataForHotel.Details_tbl_Juniper_Bookings
                                  ?.BookingEndDate
                              }
                              <br />
                            </address>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 me-auto">
                          <div className="invoice-details">
                            <div className="invoice-num me-auto text-left">
                              <div>
                                {
                                  cstDataForHotel.Details_tbl_Juniper_Bookings
                                    ?.Customer_FirstName
                                }{" "}
                                {
                                  cstDataForHotel.Details_tbl_Juniper_Bookings
                                    ?.Customer_LastName
                                }
                              </div>
                              <div>
                                {
                                  cstDataForHotel.Details_tbl_Juniper_Bookings
                                    ?.Customer_EmailAddress
                                }
                              </div>
                              <div>
                                {
                                  cstDataForHotel.Details_tbl_Juniper_Bookings
                                    ?.Customer_Mobile
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="invoice-body">
                      <div className="row gutters">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="table-responsive">
                            <table className="table custom-table m-0">
                              <thead>
                                <tr>
                                  <th>Items</th>

                                  <th className="text-center">Quantity</th>
                                  <th className="text-center">Sub Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={{ verticalAlign: "middle" }}>
                                    <strong>Accommodation</strong>
                                    {cstDataForHotel.List_tbl_Juniper_Bookings_Rooms?.map(
                                      (room, index) => (
                                        <p
                                          key={index}
                                          className="m-0 "
                                          style={{
                                            paddingBottom: "10px",
                                            paddingTop: "10px",
                                          }}
                                        >
                                          {room.RoomName}
                                          <br /> (Adults:{" "}
                                          {index === 0 &&
                                            adultsHotels1RLocalStorage}{" "}
                                          {index === 1 &&
                                            adultsHotels2RLocalStorage}
                                          {index === 2 &&
                                            adultsHotels3RLocalStorage}{" "}
                                          - Children:{" "}
                                          {index === 0 &&
                                            childrenHotels1RLocalStorage}{" "}
                                          {index === 1 &&
                                            childrenHotels2RLocalStorage}
                                          {index === 2 &&
                                            childrenHotels3RLocalStorage}
                                          )
                                        </p>
                                      )
                                    )}
                                  </td>

                                  <td
                                    className="text-center"
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    {
                                      cstDataForHotel
                                        .Details_tbl_Juniper_Bookings
                                        ?.NumberofRooms
                                    }{" "}
                                    {cstDataForHotel
                                      .Details_tbl_Juniper_Bookings
                                      ?.NumberofRooms > 1
                                      ? "Rooms"
                                      : "Room"}
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    {
                                      cstDataForHotel.CurrencyDetails
                                        ?.CurrencySign
                                    }{" "}
                                    {
                                      cstDataForHotel
                                        .Details_tbl_Juniper_Bookings
                                        ?.PriceGross
                                    }
                                  </td>
                                </tr>
                                {cstDataForHotel.List_tbl_Juniper_Bookings_Extra?.map(
                                  (extra) => (
                                    <tr key={extra.BookingExtraID}>
                                      <td className="d-flex justify-content-between align-items-center py-3">
                                        <p className="my-auto">
                                          {extra.ProductName}
                                        </p>

                                        <button
                                          id="Submit1"
                                          type="submit"
                                          title="Remove"
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "50%",
                                            padding: "5px",
                                            backgroundColor: "#e60000",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          onClick={() =>
                                            handleDelete(extra.BookingExtraID)
                                          }
                                        >
                                          <i
                                            className="fa-mytravel-close text-center mx-auto text-light ms-5"
                                            style={{ fontSize: "8px" }}
                                          ></i>
                                        </button>
                                      </td>

                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        {extra.TotalPax}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        {extra.CurrencySign} {extra.ExtraPrice}
                                      </td>
                                    </tr>
                                  )
                                )}

                                <tr>
                                  <td>&nbsp;</td>
                                  <td
                                    style={{ verticalAlign: "middle" }}
                                    className="py-3"
                                  >
                                    <h5 className="text-success mb-0">
                                      <strong>Grand Total</strong>
                                    </h5>
                                  </td>
                                  <td
                                    style={{ verticalAlign: "middle" }}
                                    className="py-3"
                                  >
                                    <h5 className="text-success mb-0">
                                      <strong>
                                        {
                                          cstDataForHotel.CurrencyDetails
                                            ?.CurrencySign
                                        }{" "}
                                        {
                                          cstDataForHotel
                                            .Details_tbl_Juniper_Bookings
                                            ?.TotalBookingPayment
                                        }
                                      </strong>
                                    </h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <form
                              onSubmit={handlePayment}
                              className="d-flex justify-content-end "
                            >
                              <button
                                id="Submit1"
                                type="submit"
                                className="btn btn-primary mt-3"
                              >
                                {loadingPayment ? (
                                  <>
                                    <Spinner
                                      as="span"
                                      className="pe-2"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />{" "}
                                    Loading...
                                  </>
                                ) : (
                                  "Pay Now"
                                )}
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HotelBookingReview;
