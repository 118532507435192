import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  news: {},
  pageIndex: 1,
  totalPages: 1,
  loading: false,
  error: null,
};

export const fetchNews = createAsyncThunk(
  "news/fetchNews",
  async ({ pageIndex, searchTerm, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_News/${language}?PageIndex=${pageIndex}&Search=${searchTerm}`
    );
    return response.data;
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchNews.fulfilled, (state, action) => {
        state.loading = false;
        state.news = action.payload;
        state.pageIndex = action.payload.pageIndex;
        state.totalPages = action.payload.totalPages;
      })

      .addCase(fetchNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setPageIndex } = newsSlice.actions;

export default newsSlice.reducer;
