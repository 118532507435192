import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  cruises: {},
  pageIndex: 1,
  totalPages: 1,
  loading: false,
  error: null,
};

export const fetchCruises = createAsyncThunk(
  "cruises/fetchCruises",
  async ({
    pageIndex,
    searchTerm,
    currency,
    Sort,
    selectedStyle,
    selectedDestination,
    groupSize,
    durations,
    language,
  }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_NileCruise/${language}?PageIndex=${pageIndex}&Search=${searchTerm}&currency=${currency}`
    );
    return response.data;
  }
);

const cruisesSlice = createSlice({
  name: "cruises",
  initialState,
  reducers: {
    setPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCruises.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchCruises.fulfilled, (state, action) => {
        state.loading = false;
        state.cruises = action.payload;
        state.pageIndex = action.payload.pageIndex;
        state.totalPages = action.payload.totalPages;
      })

      .addCase(fetchCruises.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setPageIndex } = cruisesSlice.actions;

export default cruisesSlice.reducer;
