import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

export const fetchOrderCruiseDetails = createAsyncThunk(
  "orderCruiseDetails/fetchOrderCruiseDetails",
  async ({ orderId, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_NileCruise/ThanksNileCruiseConfirmation/${language}/${orderId}`
    );
    return response.data;
  }
);

const orderCruiseDetailsSlice = createSlice({
  name: "orderCruiseDetails",
  initialState: {
    orderCruiseDetails: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderCruiseDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrderCruiseDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.orderCruiseDetails = action.payload;
      })
      .addCase(fetchOrderCruiseDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default orderCruiseDetailsSlice.reducer;
