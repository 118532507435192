import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  signUpData: {},
  loading: false,
  error: null,
};

export const fetchSignUpData = createAsyncThunk(
  "signUpData/fetchSignUpData",
  async (language) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_User/${language}/registerPage`
    );
    return response.data;
  }
);

const signUpDataSlice = createSlice({
  name: "signUpData",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSignUpData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSignUpData.fulfilled, (state, action) => {
        state.loading = false;
        state.signUpData = action.payload;
      })
      .addCase(fetchSignUpData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default signUpDataSlice.reducer;
