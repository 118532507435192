import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { juniperUrl } from "./juniperUrl.js";

export const fetchRemoveExtra = createAsyncThunk(
  "removeExtra/fetchRemoveExtra",
  async ({ extraIdDeleted, BookingId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${juniperUrl}/opReviewRemoveExtra?txt_BookingExtraID=${extraIdDeleted}&txt_BookingID=${BookingId}`
      );
      return { extraIdDeleted, BookingId, data: response.data };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
const removeExtraSlice = createSlice({
  name: "removeExtra",
  initialState: {
    removeExtra: {},
    loading: false,
    error: null,
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRemoveExtra.pending, (state) => {
        state.loading = true;
        state.isError = false;
      })
      .addCase(fetchRemoveExtra.fulfilled, (state, action) => {
        state.loading = false;
        state.isError = false;
        state.removeExtra = action.payload;
      })
      .addCase(fetchRemoveExtra.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.error = action.payload || action.error.message;
      });
  },
});

export default removeExtraSlice.reducer;
