export const Airports_Options = [
  {
    label: "Bagram, Bagram - Afghanistan (OAI)",
    value: "OAI",
  },
  {
    label: "Bamyan, Bamyan - Afghanistan (BIN)",
    value: "BIN",
  },
  {
    label: "Camp Bastion, Camp Bastion - Afghanistan (OAZ)",
    value: "OAZ",
  },
  {
    label: "Chaghcharan, Chaghcharan - Afghanistan (CCN)",
    value: "CCN",
  },
  {
    label: "Darwaz, Darwaz - Afghanistan (DAZ)",
    value: "DAZ",
  },
  {
    label: "Dwyer, Air Base - Afghanistan (DWR)",
    value: "DWR",
  },
  {
    label: "Faizabad, Faizabad - Afghanistan (FBD)",
    value: "FBD",
  },
  {
    label: "Farah, Farah - Afghanistan (FAH)",
    value: "FAH",
  },
  {
    label: "Gardez, Gardez - Afghanistan (GRG)",
    value: "GRG",
  },
  {
    label: "Ghazni, Ghazni - Afghanistan (GZI)",
    value: "GZI",
  },
  {
    label: "Herat, Herat - Afghanistan (HEA)",
    value: "HEA",
  },
  {
    label: "Jalalabad, Jalalabad - Afghanistan (JAA)",
    value: "JAA",
  },
  {
    label: "Kabul, International - Afghanistan (KBL)",
    value: "KBL",
  },
  {
    label: "Kandahar, International - Afghanistan (KDH)",
    value: "KDH",
  },
  {
    label: "Khost, Chapman - Afghanistan (KHT)",
    value: "KHT",
  },
  {
    label: "Khwahan, Khwahan - Afghanistan (KWH)",
    value: "KWH",
  },
  {
    label: "Kunduz, Kunduz - Afghanistan (UND)",
    value: "UND",
  },
  {
    label: "Kuran Wa Munjan, Razer - Afghanistan (KUR)",
    value: "KUR",
  },
  {
    label: "Lashkar Gah, Bost - Afghanistan (BST)",
    value: "BST",
  },
  {
    label: "Maimanah, Maimanah - Afghanistan (MMZ)",
    value: "MMZ",
  },
  {
    label: "Mazar-E Sharif, Mazar-E Sharif - Afghanistan (MZR)",
    value: "MZR",
  },
  {
    label: "Qalah-Ye Now, Qalah-Ye Now - Afghanistan (LQN)",
    value: "LQN",
  },
  {
    label: "Salerno, Air Base - Afghanistan (OLR)",
    value: "OLR",
  },
  {
    label: "Sardeh Band, Sardeh Band - Afghanistan (SBF)",
    value: "SBF",
  },
  {
    label: "Shank, Air Base - Afghanistan (OAA)",
    value: "OAA",
  },
  {
    label: "Sharana, Air Base - Afghanistan (OAS)",
    value: "OAS",
  },
  {
    label: "Sheghnan, Sheghnan - Afghanistan (SGA)",
    value: "SGA",
  },
  {
    label: "Taloqan, Taloqan - Afghanistan (TQN)",
    value: "TQN",
  },
  {
    label: "Tarin Kot, Tereen - Afghanistan (TII)",
    value: "TII",
  },
  {
    label: "Urgun, Urgun - Afghanistan (URN)",
    value: "URN",
  },
  {
    label: "Uruzgan, Uruzgan - Afghanistan (URZ)",
    value: "URZ",
  },
  {
    label: "Zaranj, Zaranj - Afghanistan (ZAJ)",
    value: "ZAJ",
  },
  {
    label: "Tirana, Albania (TIA)",
    value: "TIA",
  },
  {
    label: "Tirana, Nene Tereza Intl - Albania (TIA)",
    value: "TIA",
  },
  {
    label: "Adrar, Algeria (AZR)",
    value: "AZR",
  },
  {
    label: "Adrar, Touat - Algeria (AZR)",
    value: "AZR",
  },
  {
    label: "Algiers, Algeria (ALG)",
    value: "ALG",
  },
  {
    label: "Algiers, Houari Boumediene - Algeria (ALG)",
    value: "ALG",
  },
  {
    label: "Annaba, Algeria (AAE)",
    value: "AAE",
  },
  {
    label: "Annaba, Rabah Bitat - Algeria (AAE)",
    value: "AAE",
  },
  {
    label: "Batna, Algeria (BLJ)",
    value: "BLJ",
  },
  {
    label: "Batna, Mostepha Ben Boulaid - Algeria (BLJ)",
    value: "BLJ",
  },
  {
    label: "Bechar, Boudghene B Ali Lotfi - Algeria (CBH)",
    value: "CBH",
  },
  {
    label: "Bejaia, Soummam Abane Ramdane - Algeria (BJA)",
    value: "BJA",
  },
  {
    label: "Biskra, Mohamed Khider - Algeria (BSK)",
    value: "BSK",
  },
  {
    label: "Bordj Mokhtar, Bordj Mokhtar - Algeria (BMW)",
    value: "BMW",
  },
  {
    label: "Bou Saada, Bou Saada - Algeria (BUJ)",
    value: "BUJ",
  },
  {
    label: "Chlef, Aboubakr Belkaid - Algeria (CFK)",
    value: "CFK",
  },
  {
    label: "Constantine, Mohamed Boudiaf - Algeria (CZL)",
    value: "CZL",
  },
  {
    label: "Djanet, Tiska - Algeria (DJG)",
    value: "DJG",
  },
  {
    label: "El Bayadh, El Bayadh - Algeria (EBH)",
    value: "EBH",
  },
  {
    label: "El Golea, El Golea - Algeria (ELG)",
    value: "ELG",
  },
  {
    label: "El Oued, Guemar - Algeria (ELU)",
    value: "ELU",
  },
  {
    label: "Ghardaia, Noumerat - Algeria (GHA)",
    value: "GHA",
  },
  {
    label: "Hassi Messaoud, Oued Irara - Algeria (HME)",
    value: "HME",
  },
  {
    label: "Hassi R'mel, Tilrempt - Algeria (HRM)",
    value: "HRM",
  },
  {
    label: "Illizi, Takhamalt - Algeria (VVZ)",
    value: "VVZ",
  },
  {
    label: "In Amenas, Zarzaitine - Algeria (IAM)",
    value: "IAM",
  },
  {
    label: "In Guezzam, In Guezzam - Algeria (INF)",
    value: "INF",
  },
  {
    label: "In Salah, In Salah - Algeria (INZ)",
    value: "INZ",
  },
  {
    label: "Jijel, Ferhat Abbas - Algeria (GJL)",
    value: "GJL",
  },
  {
    label: "Laghouat, L'mekrareg - Algeria (LOO)",
    value: "LOO",
  },
  {
    label: "Mascara, Ghriss - Algeria (MUW)",
    value: "MUW",
  },
  {
    label: "Mecheria, Mecheria - Algeria (MZW)",
    value: "MZW",
  },
  {
    label: "Mostaganem, Mostaganem - Algeria (MQV)",
    value: "MQV",
  },
  {
    label: "Oran, Ahmed Ben Bella - Algeria (ORN)",
    value: "ORN",
  },
  {
    label: "Oran, Tafaraoui - Algeria (TAF)",
    value: "TAF",
  },
  {
    label: "Ouargla, Ain El Beida - Algeria (OGX)",
    value: "OGX",
  },
  {
    label: "Setif, 8 Mai 45 - Algeria (QSF)",
    value: "QSF",
  },
  {
    label: "Sidi Belabbes, Sidi Belabbes - Algeria (BFW)",
    value: "BFW",
  },
  {
    label: "Skikda, Skikda - Algeria (SKI)",
    value: "SKI",
  },
  {
    label: "Tamanrasset, Aguenar - Algeria (TMR)",
    value: "TMR",
  },
  {
    label: "Tebessa, Tbessa - Algeria (TEE)",
    value: "TEE",
  },
  {
    label: "Tiaret, Bou Chekif - Algeria (TID)",
    value: "TID",
  },
  {
    label: "Timimoun, Timimoun - Algeria (TMX)",
    value: "TMX",
  },
  {
    label: "Tindouf, Tindouf - Algeria (TIN)",
    value: "TIN",
  },
  {
    label: "Tlemcen, Zenata Messali El Hadj - Algeria (TLM)",
    value: "TLM",
  },
  {
    label: "Touggourt, Sidi Mahdi - Algeria (TGR)",
    value: "TGR",
  },
  {
    label: "Fitiuta, Fitiuta - American Samoa (FTI)",
    value: "FTI",
  },
  {
    label: "Ofu Island, Ofu Island - American Samoa (OFU)",
    value: "OFU",
  },
  {
    label: "Pago Pago, Pago Pago Intl - American Samoa (PPG)",
    value: "PPG",
  },
  {
    label: "Tau, Tau - American Samoa (TAV)",
    value: "TAV",
  },
  {
    label: "Ambriz, Ambriz - Angola (AZZ)",
    value: "AZZ",
  },
  {
    label: "Andulo, Andulo - Angola (ANL)",
    value: "ANL",
  },
  {
    label: "Benguela, 17 De Setembro - Angola (BUG)",
    value: "BUG",
  },
  {
    label: "Cabinda, Cabinda - Angola (CAB)",
    value: "CAB",
  },
  {
    label: "Cafunfo, Cafunfo - Angola (CFF)",
    value: "CFF",
  },
  {
    label: "Cangamba, Cangamba - Angola (CNZ)",
    value: "CNZ",
  },
  {
    label: "Capanda, Capanda - Angola (KNP)",
    value: "KNP",
  },
  {
    label: "Catumbela, Catumbela - Angola (CBT)",
    value: "CBT",
  },
  {
    label: "Cazombo, Cazombo - Angola (CAV)",
    value: "CAV",
  },
  {
    label: "Chitato, Chitato - Angola (PGI)",
    value: "PGI",
  },
  {
    label: "Cuito Cuanavale, Cuito Cuanavale - Angola (CTI)",
    value: "CTI",
  },
  {
    label: "Dirico, Dirico - Angola (DRC)",
    value: "DRC",
  },
  {
    label: "Dundo, Dundo - Angola (DUE)",
    value: "DUE",
  },
  {
    label: "Huambo, Albano Machado - Angola (NOV)",
    value: "NOV",
  },
  {
    label: "Jamba, Jamba - Angola (JMB)",
    value: "JMB",
  },
  {
    label: "Kuito, Kuito - Angola (SVP)",
    value: "SVP",
  },
  {
    label: "Lobito, Lobito - Angola (LLT)",
    value: "LLT",
  },
  {
    label: "Luanda, 4 De Fevereiro - Angola (LAD)",
    value: "LAD",
  },
  {
    label: "Luau, Luau - Angola (UAL)",
    value: "UAL",
  },
  {
    label: "Lubango, Mukanka - Angola (SDD)",
    value: "SDD",
  },
  {
    label: "Lucapa, Lucapa - Angola (LBZ)",
    value: "LBZ",
  },
  {
    label: "Luena, Luena - Angola (LUO)",
    value: "LUO",
  },
  {
    label: "Lumbala N' Guimbo, Lumbala N' Guimbo - Angola (GGC)",
    value: "GGC",
  },
  {
    label: "Luzamba, Luzamba - Angola (LZM)",
    value: "LZM",
  },
  {
    label: "Malanje, Malanje - Angola (MEG)",
    value: "MEG",
  },
  {
    label: "M'banza Congo, M'banza Congo - Angola (SSY)",
    value: "SSY",
  },
  {
    label: "Menongue, Menongue - Angola (SPP)",
    value: "SPP",
  },
  {
    label: "Namibe, Yuri Gagarin - Angola (MSZ)",
    value: "MSZ",
  },
  {
    label: "N'dalatando Location, N'dalatando Location - Angola (NDF)",
    value: "NDF",
  },
  {
    label: "Negage, Negage - Angola (GXG)",
    value: "GXG",
  },
  {
    label: "Nzagi, Nzagi - Angola (NZA)",
    value: "NZA",
  },
  {
    label: "N'zeto, N'zeto - Angola (ARZ)",
    value: "ARZ",
  },
  {
    label: "Ondjiva, Ondjiva - Angola (VPE)",
    value: "VPE",
  },
  {
    label: "Porto Amboim, Porto Amboim - Angola (PBN)",
    value: "PBN",
  },
  {
    label: "Saurimo, Saurimo - Angola (VHC)",
    value: "VHC",
  },
  {
    label: "Soyo, Soyo - Angola (SZA)",
    value: "SZA",
  },
  {
    label: "Sumbe, Sumbe - Angola (NDD)",
    value: "NDD",
  },
  {
    label: "Uige, Uige - Angola (UGO)",
    value: "UGO",
  },
  {
    label: "Waku Kungo, Waku Kungo - Angola (CEO)",
    value: "CEO",
  },
  {
    label: "Xangongo, Xangongo - Angola (XGN)",
    value: "XGN",
  },
  {
    label: "Anguilla, Clayton J.Lloyd Intl - Anguilla (AXA)",
    value: "AXA",
  },
  {
    label: "Teniente R  Marsh, Tte.R.Marsh Martin - Antartica (TNM)",
    value: "TNM",
  },
  {
    label: "Antigua, Vc Bird Intl - Antigua And Barbuda (ANU)",
    value: "ANU",
  },
  {
    label: "Barbuda, Codrington - Antigua And Barbuda (BBQ)",
    value: "BBQ",
  },
  {
    label: "Alto Rio Senguerr, Alto Rio Senguer - Argentina (ARR)",
    value: "ARR",
  },
  {
    label: "Bahia Blanca, Comandante Espora - Argentina (BHI)",
    value: "BHI",
  },
  {
    label: "Buenos Aires - Argentina (BUE)",
    value: "BUE",
  },
  {
    label: "Buenos Aires, J. Newbery - Argentina (AEP)",
    value: "AEP",
  },
  {
    label: "Buenos Aires, Pistarini - Argentina (EZE)",
    value: "EZE",
  },
  {
    label: "Caleta Olivia, Caleta Olivia - Argentina (CVI)",
    value: "CVI",
  },
  {
    label: "Carmen De Patagones, Carmen De Patagones - Argentina (CPG)",
    value: "CPG",
  },
  {
    label: "Catamarca, Catamarca - Argentina (CTC)",
    value: "CTC",
  },
  {
    label: "Caviahue, Caviahue - Argentina (CVH)",
    value: "CVH",
  },
  {
    label: "Ceres, Ceres - Argentina (CRR)",
    value: "CRR",
  },
  {
    label: "Charata, Charata - Argentina (CNT)",
    value: "CNT",
  },
  {
    label: "Chos Malal, Chos Malal - Argentina (HOS)",
    value: "HOS",
  },
  {
    label: "Clorinda, Clorinda - Argentina (CLX)",
    value: "CLX",
  },
  {
    label: "Colonia Catriel, Colonia Catriel - Argentina (CCT)",
    value: "CCT",
  },
  {
    label: "Colonia Sarmiento, Sarmiento - Argentina (OLN)",
    value: "OLN",
  },
  {
    label: "Comodoro Rivadavi, General E.Mosconi - Argentina (CRD)",
    value: "CRD",
  },
  {
    label: "Concordia, Comodoro Pierrestegui - Argentina (COC)",
    value: "COC",
  },
  {
    label: "Cordoba, Pajas Blancas - Argentina (COR)",
    value: "COR",
  },
  {
    label: "Coronel Suarez, Brigadier Hector Ruiz - Argentina (CSZ)",
    value: "CSZ",
  },
  {
    label: "Corrientes, Corrientes - Argentina (CNQ)",
    value: "CNQ",
  },
  {
    label: "Curuzu Cuatia, Curuzu Cuatia - Argentina (UZU)",
    value: "UZU",
  },
  {
    label: "Cutral, Cutral-Co - Argentina (CUT)",
    value: "CUT",
  },
  {
    label: "El Bolson, El Bolson - Argentina (EHL)",
    value: "EHL",
  },
  {
    label: "El Calafate, Coandante A.Tola - Argentina (FTE)",
    value: "FTE",
  },
  {
    label: "El Maiten, El Maiten - Argentina (EMX)",
    value: "EMX",
  },
  {
    label: "El Palomar, El Palomar - Argentina (EPA)",
    value: "EPA",
  },
  {
    label: "Eldorado, Eldorado - Argentina (ELO)",
    value: "ELO",
  },
  {
    label: "Embarcacion, Embarcacion - Argentina (ZAI)",
    value: "ZAI",
  },
  {
    label: "Esquel, Esquel - Argentina (EQS)",
    value: "EQS",
  },
  {
    label: "Formosa, Formosa - Argentina (FMA)",
    value: "FMA",
  },
  {
    label: "Gdor  Gregores, Gdor  Gregores - Argentina (GGS)",
    value: "GGS",
  },
  {
    label: "General Pico, General Pico - Argentina (GPO)",
    value: "GPO",
  },
  {
    label: "General Roca, Dr.Arturo U.Illia - Argentina (GNR)",
    value: "GNR",
  },
  {
    label: "General Villegas, General Villegas - Argentina (VGS)",
    value: "VGS",
  },
  {
    label: "Goya, Goya - Argentina (OYA)",
    value: "OYA",
  },
  {
    label: "Gualeguaychu, Gualeguaychu - Argentina (GHU)",
    value: "GHU",
  },
  {
    label: "Iguazu, Cataratas Del Iguazu - Argentina (IGR)",
    value: "IGR",
  },
  {
    label: "Ingeniero Jacobacci, Ingeniero Jacobacci - Argentina (IGB)",
    value: "IGB",
  },
  {
    label: "Jose D San Martin, Jose De San Martin - Argentina (JSM)",
    value: "JSM",
  },
  {
    label: "Jujuy, Gobernador H.Guzman - Argentina (JUJ)",
    value: "JUJ",
  },
  {
    label: "Junin, Junin - Argentina (JNI)",
    value: "JNI",
  },
  {
    label: "La Cumbre, La Cumbre - Argentina (LCM)",
    value: "LCM",
  },
  {
    label: "La Plata, La Plata - Argentina (LPG)",
    value: "LPG",
  },
  {
    label: "La Rioja, Captain V.A Almonacid - Argentina (IRJ)",
    value: "IRJ",
  },
  {
    label: "Lago Argentino, Lago Argentino - Argentina (ING)",
    value: "ING",
  },
  {
    label: "Las Heras, Las Heras - Argentina (LHS)",
    value: "LHS",
  },
  {
    label: "Las Lomitas, Alferez A.Rodriguez - Argentina (LLS)",
    value: "LLS",
  },
  {
    label: "Ledesma, Ledesma - Argentina (XMX)",
    value: "XMX",
  },
  {
    label: "Loncopue, Loncopue - Argentina (LCP)",
    value: "LCP",
  },
  {
    label: "Los Menucos, Los Menucos - Argentina (LMD)",
    value: "LMD",
  },
  {
    label: "Malargue, Comodoro D.R.Salomon - Argentina (LGS)",
    value: "LGS",
  },
  {
    label: "Maquinchao, Maquinchao - Argentina (MQD)",
    value: "MQD",
  },
  {
    label: "Mar Del Plata, Astor Piazzola - Argentina (MDQ)",
    value: "MDQ",
  },
  {
    label: "Mendoza, El Plumerillo - Argentina (MDZ)",
    value: "MDZ",
  },
  {
    label: "Mercedes, Mercedes - Argentina (MDX)",
    value: "MDX",
  },
  {
    label: "Merlo, Valle Del Conlara - Argentina (RLO)",
    value: "RLO",
  },
  {
    label: "Miramar, Miramar - Argentina (MJR)",
    value: "MJR",
  },
  {
    label: "Monte Caseros, Monte Caseros - Argentina (MCS)",
    value: "MCS",
  },
  {
    label: "Mosconi, Mosconi - Argentina (XOS)",
    value: "XOS",
  },
  {
    label: "Necochea, Necochea - Argentina (NEC)",
    value: "NEC",
  },
  {
    label: "Neuquen, Presidente Peron - Argentina (NQN)",
    value: "NQN",
  },
  {
    label: "Olavarria, Olavarria - Argentina (OVR)",
    value: "OVR",
  },
  {
    label: "Oran, Oran - Argentina (ORA)",
    value: "ORA",
  },
  {
    label: "Parana, General Urquiza - Argentina (PRA)",
    value: "PRA",
  },
  {
    label: "Paso De Los Libres, Paso De Los Libres - Argentina (AOL)",
    value: "AOL",
  },
  {
    label: "Pehuajo, Comodoro P.Zanni - Argentina (PEH)",
    value: "PEH",
  },
  {
    label: "Perito Moreno, Perito Moreno - Argentina (PMQ)",
    value: "PMQ",
  },
  {
    label: "Pichanal, Pichanal - Argentina (XMV)",
    value: "XMV",
  },
  {
    label: "Posadas, Jose De San Martin - Argentina (PSS)",
    value: "PSS",
  },
  {
    label: "Pres  Roque Saenz, Pres  Roque Saenz - Argentina (PRQ)",
    value: "PRQ",
  },
  {
    label: "Puerto Deseado, Puerto Deseado - Argentina (PUD)",
    value: "PUD",
  },
  {
    label: "Puerto Madryn, Tehuelche - Argentina (PMY)",
    value: "PMY",
  },
  {
    label: "Rafaela, Rafaela - Argentina (RAF)",
    value: "RAF",
  },
  {
    label: "Reconquista, Reconquista - Argentina (RCQ)",
    value: "RCQ",
  },
  {
    label: "Resistencia, Resistencia - Argentina (RES)",
    value: "RES",
  },
  {
    label: "Rincon De Los Sauces, Rincon De Los Sauces - Argentina (RDS)",
    value: "RDS",
  },
  {
    label: "Rio Cuarto, Las Higueras - Argentina (RCU)",
    value: "RCU",
  },
  {
    label: "Rio Gallegos, Piloto N.Fernandez - Argentina (RGL)",
    value: "RGL",
  },
  {
    label: "Rio Grande, Hermes Quijada - Argentina (RGA)",
    value: "RGA",
  },
  {
    label: "Rio Hondo, Termas De Rio Hondo - Argentina (RHD)",
    value: "RHD",
  },
  {
    label: "Rio Mayo, Rio Mayo - Argentina (ROY)",
    value: "ROY",
  },
  {
    label: "Rio Turbio, Rio Turbio - Argentina (RYO)",
    value: "RYO",
  },
  {
    label: "Rosario, Islas Malvinas - Argentina (ROS)",
    value: "ROS",
  },
  {
    label: "Saenz Pena, Saenz Pena - Argentina (SZQ)",
    value: "SZQ",
  },
  {
    label: "Salta, Martin M.De Guemes - Argentina (SLA)",
    value: "SLA",
  },
  {
    label: "San Antonio Oeste, Antoine De St Exupery - Argentina (OES)",
    value: "OES",
  },
  {
    label: "San Carlos De Bariloche, International - Argentina (BRC)",
    value: "BRC",
  },
  {
    label: "San Juan, Domingo F.Sarmiento - Argentina (UAQ)",
    value: "UAQ",
  },
  {
    label: "San Julian, Capitan J.D Vazquez - Argentina (ULA)",
    value: "ULA",
  },
  {
    label: "San Luis, D.Cesar Raul Ojeda - Argentina (LUQ)",
    value: "LUQ",
  },
  {
    label: "San Martin De Los Andes, Aviador Carlos Campos - Argentina (CPC)",
    value: "CPC",
  },
  {
    label: "San Pedro, San Pedro - Argentina (XPD)",
    value: "XPD",
  },
  {
    label: "San Rafael, San Rafael - Argentina (AFA)",
    value: "AFA",
  },
  {
    label: "Santa Cruz, Santa Cruz - Argentina (RZA)",
    value: "RZA",
  },
  {
    label: "Santa Fe, Sauce Viejo - Argentina (SFN)",
    value: "SFN",
  },
  {
    label: "Santa Rosa, Santa Rosa - Argentina (RSA)",
    value: "RSA",
  },
  {
    label: "Santa Teresita, Santa Teresita - Argentina (SST)",
    value: "SST",
  },
  {
    label: "Santiago, A.De La Paz Aragonez - Argentina (SDE)",
    value: "SDE",
  },
  {
    label: "Sierra Grande, Sierra Grande - Argentina (SGV)",
    value: "SGV",
  },
  {
    label: "Sunchales, Sunchales - Argentina (NCJ)",
    value: "NCJ",
  },
  {
    label: "Tandil, Tandil - Argentina (TDL)",
    value: "TDL",
  },
  {
    label: "Tartagal, Gral.Enrique Mosconi - Argentina (TTG)",
    value: "TTG",
  },
  {
    label: "Trelew, Almirante M.A.Zar - Argentina (REL)",
    value: "REL",
  },
  {
    label: "Tres Arroyos, Tres Arroyos - Argentina (OYO)",
    value: "OYO",
  },
  {
    label: "Tucuman, Benjamin Matienzo - Argentina (TUC)",
    value: "TUC",
  },
  {
    label: "Ushuaia, Malvinas Argentinas - Argentina (USH)",
    value: "USH",
  },
  {
    label: "Valcheta, Valcheta - Argentina (VCF)",
    value: "VCF",
  },
  {
    label: "Viedma, Gobernador E.Castello - Argentina (VDM)",
    value: "VDM",
  },
  {
    label: "Villa Dolores, Villa Dolores - Argentina (VDR)",
    value: "VDR",
  },
  {
    label: "Villa Gesell, Villa Gesell - Argentina (VLG)",
    value: "VLG",
  },
  {
    label: "Villa Mercedes, Villa Reynolds - Argentina (VME)",
    value: "VME",
  },
  {
    label: "Zapala, Zapala - Argentina (APZ)",
    value: "APZ",
  },
  {
    label: "Yerevan - Armenia (EVN)",
    value: "EVN",
  },
  {
    label: "Yerevan, Zvartnots Intl - Armenia (EVN)",
    value: "EVN",
  },
  {
    label: "Gyumri - Armenia (LWN)",
    value: "LWN",
  },
  {
    label: "Gyumri, Shirak - Armenia (LWN)",
    value: "LWN",
  },
  {
    label: "Aruba, Reina Beatrix - Aruba (AUA)",
    value: "AUA",
  },
  {
    label: "Abingdon Downs - Australia (ABG)",
    value: "ABG",
  },
  {
    label: "Abingdon Downs, Abingdon Downs - Australia (ABG)",
    value: "ABG",
  },
  {
    label: "Agnew - Australia (AGW)",
    value: "AGW",
  },
  {
    label: "Agnew, Agnew - Australia (AGW)",
    value: "AGW",
  },
  {
    label: "Alpha - Australia (ABH)",
    value: "ABH",
  },
  {
    label: "Alpha, Alpha - Australia (ABH)",
    value: "ABH",
  },
  {
    label: "Aramac - Australia (AXC)",
    value: "AXC",
  },
  {
    label: "Aramac, Aramac - Australia (AXC)",
    value: "AXC",
  },
  {
    label: "Arrabury - Australia (AAB)",
    value: "AAB",
  },
  {
    label: "Arrabury, Arrabury - Australia (AAB)",
    value: "AAB",
  },
  {
    label: "Augustus Downs - Australia (AUD)",
    value: "AUD",
  },
  {
    label: "Augustus Downs, Augustus Downs - Australia (AUD)",
    value: "AUD",
  },
  {
    label: "Aurukun - Australia (AUU)",
    value: "AUU",
  },
  {
    label: "Aurukun, Aurukun - Australia (AUU)",
    value: "AUU",
  },
  {
    label: "Ayr - Australia (AYR)",
    value: "AYR",
  },
  {
    label: "Ayr, Ayr - Australia (AYR)",
    value: "AYR",
  },
  {
    label: "Badu Island - Australia (BDD)",
    value: "BDD",
  },
  {
    label: "Badu Island, Badu Island - Australia (BDD)",
    value: "BDD",
  },
  {
    label: "Bamaga - Australia (ABM)",
    value: "ABM",
  },
  {
    label: "Bamaga, Northern Peninsula - Australia (ABM)",
    value: "ABM",
  },
  {
    label: "Barcaldine - Australia (BCI)",
    value: "BCI",
  },
  {
    label: "Barcaldine, Barcaldine - Australia (BCI)",
    value: "BCI",
  },
  {
    label: "Batavia Downs - Australia (BVW)",
    value: "BVW",
  },
  {
    label: "Batavia Downs, Batavia Downs - Australia (BVW)",
    value: "BVW",
  },
  {
    label: "Bedourie - Australia (BEU)",
    value: "BEU",
  },
  {
    label: "Bedourie, Bedourie - Australia (BEU)",
    value: "BEU",
  },
  {
    label: "Betoota - Australia (BTX)",
    value: "BTX",
  },
  {
    label: "Betoota, Betoota - Australia (BTX)",
    value: "BTX",
  },
  {
    label: "Biloela - Australia (ZBL)",
    value: "ZBL",
  },
  {
    label: "Biloela, Biloela - Australia (ZBL)",
    value: "ZBL",
  },
  {
    label: "Birdsville - Australia (BVI)",
    value: "BVI",
  },
  {
    label: "Birdsville, Birdsville - Australia (BVI)",
    value: "BVI",
  },
  {
    label: "Bizant - Australia (BZP)",
    value: "BZP",
  },
  {
    label: "Bizant, Bizant - Australia (BZP)",
    value: "BZP",
  },
  {
    label: "Blackall - Australia (BKQ)",
    value: "BKQ",
  },
  {
    label: "Blackall, Blackall - Australia (BKQ)",
    value: "BKQ",
  },
  {
    label: "Blackwater - Australia (BLT)",
    value: "BLT",
  },
  {
    label: "Blackwater, Blackwater - Australia (BLT)",
    value: "BLT",
  },
  {
    label: "Bloomfield River - Australia (BFC)",
    value: "BFC",
  },
  {
    label: "Bloomfield River, Bloomfield River - Australia (BFC)",
    value: "BFC",
  },
  {
    label: "Boigu Island - Australia (GIC)",
    value: "GIC",
  },
  {
    label: "Boigu Island, Boigu Island - Australia (GIC)",
    value: "GIC",
  },
  {
    label: "Bollon - Australia (BLS)",
    value: "BLS",
  },
  {
    label: "Bollon, Bollon - Australia (BLS)",
    value: "BLS",
  },
  {
    label: "Bolwarra - Australia (BCK)",
    value: "BCK",
  },
  {
    label: "Bolwarra, Bolwarra - Australia (BCK)",
    value: "BCK",
  },
  {
    label: "Boulia - Australia (BQL)",
    value: "BQL",
  },
  {
    label: "Boulia, Boulia - Australia (BQL)",
    value: "BQL",
  },
  {
    label: "Bowen, Bowen - Australia (ZBO)",
    value: "ZBO",
  },
  {
    label: "Bowen, Bowen - Australia (ZBO)",
    value: "ZBO",
  },
  {
    label: "Brampton Island - Australia (BMP)",
    value: "BMP",
  },
  {
    label: "Brampton Island, Brampton Island - Australia (BMP)",
    value: "BMP",
  },
  {
    label: "Brighton Downs - Australia (BHT)",
    value: "BHT",
  },
  {
    label: "Brighton Downs, Brighton Downs - Australia (BHT)",
    value: "BHT",
  },
  {
    label: "Brisbane - Australia (BNE)",
    value: "BNE",
  },
  {
    label: "Brisbane, Brisbane - Australia (BNE)",
    value: "BNE",
  },
  {
    label: "Bulimba - Australia (BIP)",
    value: "BIP",
  },
  {
    label: "Bulimba, Bulimba - Australia (BIP)",
    value: "BIP",
  },
  {
    label: "Bundaberg - Australia (BDB)",
    value: "BDB",
  },
  {
    label: "Bundaberg, Bundaberg - Australia (BDB)",
    value: "BDB",
  },
  {
    label: "Burketown - Australia (BUC)",
    value: "BUC",
  },
  {
    label: "Burketown, Burketown - Australia (BUC)",
    value: "BUC",
  },
  {
    label: "Canobie - Australia (CBY)",
    value: "CBY",
  },
  {
    label: "Canobie, Canobie - Australia (CBY)",
    value: "CBY",
  },
  {
    label: "Cairns - Australia (CNS)",
    value: "CNS",
  },
  {
    label: "Cairns, International - Australia (CNS)",
    value: "CNS",
  },
  {
    label: "Caloundra - Australia (CUD)",
    value: "CUD",
  },
  {
    label: "Caloundra, Caloundra - Australia (CUD)",
    value: "CUD",
  },
  {
    label: "Camooweal - Australia (CML)",
    value: "CML",
  },
  {
    label: "Camooweal, Camooweal - Australia (CML)",
    value: "CML",
  },
  {
    label: "Cape Flattery - Australia (CQP)",
    value: "CQP",
  },
  {
    label: "Cape Flattery, Cape Flattery - Australia (CQP)",
    value: "CQP",
  },
  {
    label: "Carpentaria Downs - Australia (CFP)",
    value: "CFP",
  },
  {
    label: "Carpentaria Downs, Carpentaria Downs - Australia (CFP)",
    value: "CFP",
  },
  {
    label: "Charleville - Australia (CTL)",
    value: "CTL",
  },
  {
    label: "Charleville, Charleville - Australia (CTL)",
    value: "CTL",
  },
  {
    label: "Charters Towers - Australia (CXT)",
    value: "CXT",
  },
  {
    label: "Charters Towers, Charters Towers - Australia (CXT)",
    value: "CXT",
  },
  {
    label: "Cherrabah - Australia (CRH)",
    value: "CRH",
  },
  {
    label: "Cherrabah, Cherrabah - Australia (CRH)",
    value: "CRH",
  },
  {
    label: "Chillagoe - Australia (LLG)",
    value: "LLG",
  },
  {
    label: "Chillagoe, Chillagoe - Australia (LLG)",
    value: "LLG",
  },
  {
    label: "Chinchilla - Australia (CCL)",
    value: "CCL",
  },
  {
    label: "Chinchilla, Chinchilla - Australia (CCL)",
    value: "CCL",
  },
  {
    label: "Clermont - Australia (CMQ)",
    value: "CMQ",
  },
  {
    label: "Clermont, Clermont - Australia (CMQ)",
    value: "CMQ",
  },
  {
    label: "Cloncurry - Australia (CNJ)",
    value: "CNJ",
  },
  {
    label: "Cloncurry, Cloncurry - Australia (CNJ)",
    value: "CNJ",
  },
  {
    label: "Cluny - Australia (CZY)",
    value: "CZY",
  },
  {
    label: "Cluny, Cluny - Australia (CZY)",
    value: "CZY",
  },
  {
    label: "Coconut Island - Australia (CNC)",
    value: "CNC",
  },
  {
    label: "Coconut Island, Coconut Island - Australia (CNC)",
    value: "CNC",
  },
  {
    label: "Coen - Australia (CUQ)",
    value: "CUQ",
  },
  {
    label: "Coen, Coen - Australia (CUQ)",
    value: "CUQ",
  },
  {
    label: "Collinsville - Australia (KCE)",
    value: "KCE",
  },
  {
    label: "Collinsville, Collinsville - Australia (KCE)",
    value: "KCE",
  },
  {
    label: "Cooinda - Australia (CDA)",
    value: "CDA",
  },
  {
    label: "Cooinda, Cooinda - Australia (CDA)",
    value: "CDA",
  },
  {
    label: "Cooktown - Australia (CTN)",
    value: "CTN",
  },
  {
    label: "Cooktown, Cooktown - Australia (CTN)",
    value: "CTN",
  },
  {
    label: "Croydon - Australia (CDQ)",
    value: "CDQ",
  },
  {
    label: "Croydon, Croydon - Australia (CDQ)",
    value: "CDQ",
  },
  {
    label: "Cunnamulla - Australia (CMA)",
    value: "CMA",
  },
  {
    label: "Cunnamulla, Cunnamulla - Australia (CMA)",
    value: "CMA",
  },
  {
    label: "Dalby - Australia (DBY)",
    value: "DBY",
  },
  {
    label: "Dalby, Dalby - Australia (DBY)",
    value: "DBY",
  },
  {
    label: "Darnley Island - Australia (NLF)",
    value: "NLF",
  },
  {
    label: "Darnley Island, Darnley Island - Australia (NLF)",
    value: "NLF",
  },
  {
    label: "Davenport Downs - Australia (DVP)",
    value: "DVP",
  },
  {
    label: "Davenport Downs, Davenport Downs - Australia (DVP)",
    value: "DVP",
  },
  {
    label: "Delta Downs - Australia (DDN)",
    value: "DDN",
  },
  {
    label: "Delta Downs, Delta Downs - Australia (DDN)",
    value: "DDN",
  },
  {
    label: "Diamantina Lakes - Australia (DYM)",
    value: "DYM",
  },
  {
    label: "Diamantina Lakes, Diamantina Lakes - Australia (DYM)",
    value: "DYM",
  },
  {
    label: "Dirranbandi - Australia (DRN)",
    value: "DRN",
  },
  {
    label: "Dirranbandi, Dirranbandi - Australia (DRN)",
    value: "DRN",
  },
  {
    label: "Dixie - Australia (DXD)",
    value: "DXD",
  },
  {
    label: "Dixie, Dixie - Australia (DXD)",
    value: "DXD",
  },
  {
    label: "Doomadgee - Australia (DMD)",
    value: "DMD",
  },
  {
    label: "Doomadgee, Doomadgee - Australia (DMD)",
    value: "DMD",
  },
  {
    label: "Dorunda - Australia (DRD)",
    value: "DRD",
  },
  {
    label: "Dorunda, Dorunda - Australia (DRD)",
    value: "DRD",
  },
  {
    label: "Drumduff - Australia (DFP)",
    value: "DFP",
  },
  {
    label: "Drumduff, Drumduff - Australia (DFP)",
    value: "DFP",
  },
  {
    label: "Dunbar - Australia (DNB)",
    value: "DNB",
  },
  {
    label: "Dunbar, Dunbar - Australia (DNB)",
    value: "DNB",
  },
  {
    label: "Dunk Island - Australia (DKI)",
    value: "DKI",
  },
  {
    label: "Dunk Island, Dunk Island - Australia (DKI)",
    value: "DKI",
  },
  {
    label: "Stradbroke Isl - Australia (SRR)",
    value: "SRR",
  },
  {
    label: "Stradbroke Isl, Dunwich - Australia (SRR)",
    value: "SRR",
  },
  {
    label: "Durham Downs - Australia (DHD)",
    value: "DHD",
  },
  {
    label: "Durham Downs, Durham Downs - Australia (DHD)",
    value: "DHD",
  },
  {
    label: "Durrie - Australia (DRR)",
    value: "DRR",
  },
  {
    label: "Durrie, Durrie - Australia (DRR)",
    value: "DRR",
  },
  {
    label: "Dysart - Australia (DYA)",
    value: "DYA",
  },
  {
    label: "Dysart, Dysart - Australia (DYA)",
    value: "DYA",
  },
  {
    label: "Einasleigh - Australia (EIH)",
    value: "EIH",
  },
  {
    label: "Einasleigh, Einasleigh - Australia (EIH)",
    value: "EIH",
  },
  {
    label: "Adelaide - Australia (ADL)",
    value: "ADL",
  },
  {
    label: "Adelaide, International - Australia (ADL)",
    value: "ADL",
  },
  {
    label: "Elrose Mine - Australia (ERQ)",
    value: "ERQ",
  },
  {
    label: "Elrose Mine, Elrose Mine - Australia (ERQ)",
    value: "ERQ",
  },
  {
    label: "Emerald - Australia (EMD)",
    value: "EMD",
  },
  {
    label: "Emerald, Emerald - Australia (EMD)",
    value: "EMD",
  },
  {
    label: "Fraser Island - Australia (OKB)",
    value: "OKB",
  },
  {
    label: "Fraser Island, Orchid Beach - Australia (OKB)",
    value: "OKB",
  },
  {
    label: "Gamboola - Australia (GBP)",
    value: "GBP",
  },
  {
    label: "Gamboola, Gamboola - Australia (GBP)",
    value: "GBP",
  },
  {
    label: "Georgetown - Australia (GTT)",
    value: "GTT",
  },
  {
    label: "Georgetown, Georgetown - Australia (GTT)",
    value: "GTT",
  },
  {
    label: "Gladstone - Australia (GLT)",
    value: "GLT",
  },
  {
    label: "Gladstone, Gladstone - Australia (GLT)",
    value: "GLT",
  },
  {
    label: "Gayndah - Australia (GAH)",
    value: "GAH",
  },
  {
    label: "Gayndah, Gayndah - Australia (GAH)",
    value: "GAH",
  },
  {
    label: "Glengyle - Australia (GLG)",
    value: "GLG",
  },
  {
    label: "Glengyle, Glengyle - Australia (GLG)",
    value: "GLG",
  },
  {
    label: "Glenormiston - Australia (GLM)",
    value: "GLM",
  },
  {
    label: "Glenormiston, Glenormiston - Australia (GLM)",
    value: "GLM",
  },
  {
    label: "Gold Coast - Australia (OOL)",
    value: "OOL",
  },
  {
    label: "Gold Coast, Coolangatta - Australia (OOL)",
    value: "OOL",
  },
  {
    label: "Goondiwind - Australia (GOO)",
    value: "GOO",
  },
  {
    label: "Goondiwindi, Goondiwindi - Australia (GOO)",
    value: "GOO",
  },
  {
    label: "Great Keppel Isla - Australia (GKL)",
    value: "GKL",
  },
  {
    label: "Great Keppel Isla, Great Keppel Isla - Australia (GKL)",
    value: "GKL",
  },
  {
    label: "Greenvale - Australia (GVP)",
    value: "GVP",
  },
  {
    label: "Greenvale, Greenvale - Australia (GVP)",
    value: "GVP",
  },
  {
    label: "Gregory Downs - Australia (GGD)",
    value: "GGD",
  },
  {
    label: "Gregory Downs, Gregory Downs - Australia (GGD)",
    value: "GGD",
  },
  {
    label: "Gympie - Australia (GYP)",
    value: "GYP",
  },
  {
    label: "Gympie, Gympie - Australia (GYP)",
    value: "GYP",
  },
  {
    label: "Hamilton Island - Australia (HTI)",
    value: "HTI",
  },
  {
    label: "Hamilton Island, Great Barrier Reef - Australia (HTI)",
    value: "HTI",
  },
  {
    label: "Headingly - Australia (HIP)",
    value: "HIP",
  },
  {
    label: "Headingly, Headingly - Australia (HIP)",
    value: "HIP",
  },
  {
    label: "Heathlands - Australia (HAT)",
    value: "HAT",
  },
  {
    label: "Heathlands, Heathlands - Australia (HAT)",
    value: "HAT",
  },
  {
    label: "Helenvale - Australia (HLV)",
    value: "HLV",
  },
  {
    label: "Helenvale, Helenvale - Australia (HLV)",
    value: "HLV",
  },
  {
    label: "Hervey Bay - Australia (HVB)",
    value: "HVB",
  },
  {
    label: "Hervey Bay, Hervey Bay - Australia (HVB)",
    value: "HVB",
  },
  {
    label: "Hopevale - Australia (HPE)",
    value: "HPE",
  },
  {
    label: "Hopevale, Hopevale - Australia (HPE)",
    value: "HPE",
  },
  {
    label: "Horn Island - Australia (HID)",
    value: "HID",
  },
  {
    label: "Horn Island, Horn Island - Australia (HID)",
    value: "HID",
  },
  {
    label: "Hughenden - Australia (HGD)",
    value: "HGD",
  },
  {
    label: "Hughenden, Hughenden - Australia (HGD)",
    value: "HGD",
  },
  {
    label: "Iffley - Australia (IFF)",
    value: "IFF",
  },
  {
    label: "Iffley, Iffley - Australia (IFF)",
    value: "IFF",
  },
  {
    label: "Ingham - Australia (IGH)",
    value: "IGH",
  },
  {
    label: "Ingham, Ingham - Australia (IGH)",
    value: "IGH",
  },
  {
    label: "Injune - Australia (INJ)",
    value: "INJ",
  },
  {
    label: "Injune, Injune - Australia (INJ)",
    value: "INJ",
  },
  {
    label: "Inkerman - Australia (IKP)",
    value: "IKP",
  },
  {
    label: "Inkerman, Inkerman - Australia (IKP)",
    value: "IKP",
  },
  {
    label: "Innisfail - Australia (IFL)",
    value: "IFL",
  },
  {
    label: "Innisfail, Innisfail - Australia (IFL)",
    value: "IFL",
  },
  {
    label: "Isisford - Australia (ISI)",
    value: "ISI",
  },
  {
    label: "Isisford, Isisford - Australia (ISI)",
    value: "ISI",
  },
  {
    label: "Julia Creek - Australia (JCK)",
    value: "JCK",
  },
  {
    label: "Julia Creek, Julia Creek - Australia (JCK)",
    value: "JCK",
  },
  {
    label: "Jundah - Australia (JUN)",
    value: "JUN",
  },
  {
    label: "Jundah, Jundah - Australia (JUN)",
    value: "JUN",
  },
  {
    label: "Kalpowar - Australia (KPP)",
    value: "KPP",
  },
  {
    label: "Kalpowar, Kalpowar - Australia (KPP)",
    value: "KPP",
  },
  {
    label: "Kamaran Downs - Australia (KDS)",
    value: "KDS",
  },
  {
    label: "Kamaran Downs, Kamaran Downs - Australia (KDS)",
    value: "KDS",
  },
  {
    label: "Kamileroi - Australia (KML)",
    value: "KML",
  },
  {
    label: "Kamileroi, Kamileroi - Australia (KML)",
    value: "KML",
  },
  {
    label: "Karumba - Australia (KRB)",
    value: "KRB",
  },
  {
    label: "Karumba, Karumba - Australia (KRB)",
    value: "KRB",
  },
  {
    label: "Kingaroy - Australia (KGY)",
    value: "KGY",
  },
  {
    label: "Kingaroy, Kingaroy - Australia (KGY)",
    value: "KGY",
  },
  {
    label: "Koolatah - Australia (KOH)",
    value: "KOH",
  },
  {
    label: "Koolatah, Koolatah - Australia (KOH)",
    value: "KOH",
  },
  {
    label: "Koolburra - Australia (KKP)",
    value: "KKP",
  },
  {
    label: "Koolburra, Koolburra - Australia (KKP)",
    value: "KKP",
  },
  {
    label: "Kowanyama - Australia (KWM)",
    value: "KWM",
  },
  {
    label: "Kowanyama, Kowanyama - Australia (KWM)",
    value: "KWM",
  },
  {
    label: "Kubin - Australia (KUG)",
    value: "KUG",
  },
  {
    label: "Kubin, Kubin - Australia (KUG)",
    value: "KUG",
  },
  {
    label: "Lady Elliot Islan - Australia (LYT)",
    value: "LYT",
  },
  {
    label: "Lady Elliot Islan, Lady Elliot Islan - Australia (LYT)",
    value: "LYT",
  },
  {
    label: "Lakefield - Australia (LFP)",
    value: "LFP",
  },
  {
    label: "Lakefield, Lakefield - Australia (LFP)",
    value: "LFP",
  },
  {
    label: "Lakeland Downs - Australia (LKD)",
    value: "LKD",
  },
  {
    label: "Lakeland Downs, Lakeland Downs - Australia (LKD)",
    value: "LKD",
  },
  {
    label: "Laura - Australia (LUU)",
    value: "LUU",
  },
  {
    label: "Laura, Laura - Australia (LUU)",
    value: "LUU",
  },
  {
    label: "Lawn Hill - Australia (LWH)",
    value: "LWH",
  },
  {
    label: "Lawn Hill, Lawn Hill - Australia (LWH)",
    value: "LWH",
  },
  {
    label: "Lansdowne Station - Australia (LDW)",
    value: "LDW",
  },
  {
    label: "Lansdowne Station, Lansdowne Station - Australia (LDW)",
    value: "LDW",
  },
  {
    label: "Linda Downs - Australia (LLP)",
    value: "LLP",
  },
  {
    label: "Linda Downs, Linda Downs - Australia (LLP)",
    value: "LLP",
  },
  {
    label: "Lindeman Island - Australia (LDC)",
    value: "LDC",
  },
  {
    label: "Lindeman Island, Lindeman Island - Australia (LDC)",
    value: "LDC",
  },
  {
    label: "Lizard Island - Australia (LZR)",
    value: "LZR",
  },
  {
    label: "Lizard Island, Lizard Island - Australia (LZR)",
    value: "LZR",
  },
  {
    label: "Lockhart River - Australia (IRG)",
    value: "IRG",
  },
  {
    label: "Lockhart River, Lockhart River - Australia (IRG)",
    value: "IRG",
  },
  {
    label: "Longreach - Australia (LRE)",
    value: "LRE",
  },
  {
    label: "Longreach, Longreach - Australia (LRE)",
    value: "LRE",
  },
  {
    label: "Lorraine - Australia (LOA)",
    value: "LOA",
  },
  {
    label: "Lorraine, Lorraine - Australia (LOA)",
    value: "LOA",
  },
  {
    label: "Lotus Vale Station - Australia (LTV)",
    value: "LTV",
  },
  {
    label: "Lotus Vale Station, Lotus Vale Station - Australia (LTV)",
    value: "LTV",
  },
  {
    label: "Lyndhurst Station - Australia (LTP)",
    value: "LTP",
  },
  {
    label: "Lyndhurst Station, Lyndhurst Station - Australia (LTP)",
    value: "LTP",
  },
  {
    label: "Mabuiag Island - Australia (UBB)",
    value: "UBB",
  },
  {
    label: "Mabuiag Island, Mabuiag Island - Australia (UBB)",
    value: "UBB",
  },
  {
    label: "Mackay - Australia (MKY)",
    value: "MKY",
  },
  {
    label: "Mackay, Mackay - Australia (MKY)",
    value: "MKY",
  },
  {
    label: "Mareeba - Australia (MRG)",
    value: "MRG",
  },
  {
    label: "Mareeba, Mareeba - Australia (MRG)",
    value: "MRG",
  },
  {
    label: "Marion Downs - Australia (MXD)",
    value: "MXD",
  },
  {
    label: "Marion Downs, Marion Downs - Australia (MXD)",
    value: "MXD",
  },
  {
    label: "Sunshine Coast - Australia (MCY)",
    value: "MCY",
  },
  {
    label: "Sunshine Coast, Maroochydore - Australia (MCY)",
    value: "MCY",
  },
  {
    label: "Maryborough - Australia (MBH)",
    value: "MBH",
  },
  {
    label: "Maryborough, Maryborough - Australia (MBH)",
    value: "MBH",
  },
  {
    label: "Merluna - Australia (MLV)",
    value: "MLV",
  },
  {
    label: "Merluna, Merluna - Australia (MLV)",
    value: "MLV",
  },
  {
    label: "Middlemount - Australia (MMM)",
    value: "MMM",
  },
  {
    label: "Middlemount, Middlemount - Australia (MMM)",
    value: "MMM",
  },
  {
    label: "Miles - Australia (WLE)",
    value: "WLE",
  },
  {
    label: "Miles, Miles - Australia (WLE)",
    value: "WLE",
  },
  {
    label: "Miners Lake - Australia (MRL)",
    value: "MRL",
  },
  {
    label: "Miners Lake, Miners Lake - Australia (MRL)",
    value: "MRL",
  },
  {
    label: "Mitchell - Australia (MTQ)",
    value: "MTQ",
  },
  {
    label: "Mitchell, Mitchell - Australia (MTQ)",
    value: "MTQ",
  },
  {
    label: "Monkira - Australia (ONR)",
    value: "ONR",
  },
  {
    label: "Monkira, Monkira - Australia (ONR)",
    value: "ONR",
  },
  {
    label: "Monto - Australia (MNQ)",
    value: "MNQ",
  },
  {
    label: "Monto, Monto - Australia (MNQ)",
    value: "MNQ",
  },
  {
    label: "Mooraberree - Australia (OOR)",
    value: "OOR",
  },
  {
    label: "Mooraberree, Mooraberree - Australia (OOR)",
    value: "OOR",
  },
  {
    label: "Moranbah - Australia (MOV)",
    value: "MOV",
  },
  {
    label: "Moranbah, Moranbah - Australia (MOV)",
    value: "MOV",
  },
  {
    label: "Moreton - Australia (MET)",
    value: "MET",
  },
  {
    label: "Moreton, Moreton - Australia (MET)",
    value: "MET",
  },
  {
    label: "Morney - Australia (OXY)",
    value: "OXY",
  },
  {
    label: "Morney, Morney - Australia (OXY)",
    value: "OXY",
  },
  {
    label: "Mornington Island - Australia (ONG)",
    value: "ONG",
  },
  {
    label: "Mornington Island, Mornington Island - Australia (ONG)",
    value: "ONG",
  },
  {
    label: "Mount Gordon Mine - Australia (GPD)",
    value: "GPD",
  },
  {
    label: "Mount Gordon Mine, Mount Gordon Mine - Australia (GPD)",
    value: "GPD",
  },
  {
    label: "Mount Isa - Australia (ISA)",
    value: "ISA",
  },
  {
    label: "Mount Isa, Mount Isa - Australia (ISA)",
    value: "ISA",
  },
  {
    label: "Murray Island - Australia (MYI)",
    value: "MYI",
  },
  {
    label: "Murray Island, Murray Island - Australia (MYI)",
    value: "MYI",
  },
  {
    label: "Musgrave - Australia (MVU)",
    value: "MVU",
  },
  {
    label: "Musgrave, Musgrave - Australia (MVU)",
    value: "MVU",
  },
  {
    label: "Muttaburra - Australia (UTB)",
    value: "UTB",
  },
  {
    label: "Muttaburra, Muttaburra - Australia (UTB)",
    value: "UTB",
  },
  {
    label: "Nappa Merrie - Australia (NMR)",
    value: "NMR",
  },
  {
    label: "Nappa Merrie, Nappa Merrie - Australia (NMR)",
    value: "NMR",
  },
  {
    label: "New Laura - Australia (LUT)",
    value: "LUT",
  },
  {
    label: "New Laura, New Laura - Australia (LUT)",
    value: "LUT",
  },
  {
    label: "New Moon - Australia (NMP)",
    value: "NMP",
  },
  {
    label: "New Moon, New Moon - Australia (NMP)",
    value: "NMP",
  },
  {
    label: "Noosaville - Australia (NSV)",
    value: "NSV",
  },
  {
    label: "Noosaville, Noosa - Australia (NSV)",
    value: "NSV",
  },
  {
    label: "Normanton - Australia (NTN)",
    value: "NTN",
  },
  {
    label: "Normanton, Normanton - Australia (NTN)",
    value: "NTN",
  },
  {
    label: "Oban - Australia (OBA)",
    value: "OBA",
  },
  {
    label: "Oban, Oban - Australia (OBA)",
    value: "OBA",
  },
  {
    label: "Orientos - Australia (OXO)",
    value: "OXO",
  },
  {
    label: "Orientos, Orientos - Australia (OXO)",
    value: "OXO",
  },
  {
    label: "Orpheus Island - Australia (ORS)",
    value: "ORS",
  },
  {
    label: "Orpheus Island, Waterport Spb - Australia (ORS)",
    value: "ORS",
  },
  {
    label: "Osborne Mine - Australia (OSO)",
    value: "OSO",
  },
  {
    label: "Osborne Mine, Osborne Mine - Australia (OSO)",
    value: "OSO",
  },
  {
    label: "Palm Island - Australia (PMK)",
    value: "PMK",
  },
  {
    label: "Palm Island, Palm Island - Australia (PMK)",
    value: "PMK",
  },
  {
    label: "Phosphate Hill - Australia (PHQ)",
    value: "PHQ",
  },
  {
    label: "Phosphate Hill, The Monument - Australia (PHQ)",
    value: "PHQ",
  },
  {
    label: "Pormpuraaw - Australia (EDR)",
    value: "EDR",
  },
  {
    label: "Pormpuraaw, Pormpuraaw - Australia (EDR)",
    value: "EDR",
  },
  {
    label: "Proserpine - Australia (PPP)",
    value: "PPP",
  },
  {
    label: "Proserpine, Whitsunday Coast - Australia (PPP)",
    value: "PPP",
  },
  {
    label: "Quilpie - Australia (ULP)",
    value: "ULP",
  },
  {
    label: "Quilpie, Quilpie - Australia (ULP)",
    value: "ULP",
  },
  {
    label: "Richmond - Australia (RCM)",
    value: "RCM",
  },
  {
    label: "Richmond, Richmond - Australia (RCM)",
    value: "RCM",
  },
  {
    label: "Robinhood - Australia (ROH)",
    value: "ROH",
  },
  {
    label: "Robinhood, Robinhood - Australia (ROH)",
    value: "ROH",
  },
  {
    label: "Rockhampton - Australia (ROK)",
    value: "ROK",
  },
  {
    label: "Rockhampton, Rockhampton - Australia (ROK)",
    value: "ROK",
  },
  {
    label: "Rokeby - Australia (RKY)",
    value: "RKY",
  },
  {
    label: "Rokeby, Rokeby - Australia (RKY)",
    value: "RKY",
  },
  {
    label: "Roma - Australia (RMA)",
    value: "RMA",
  },
  {
    label: "Roma, Roma - Australia (RMA)",
    value: "RMA",
  },
  {
    label: "Roseberth - Australia (RSB)",
    value: "RSB",
  },
  {
    label: "Roseberth, Roseberth - Australia (RSB)",
    value: "RSB",
  },
  {
    label: "Rosella Plains - Australia (RLP)",
    value: "RLP",
  },
  {
    label: "Rosella Plains, Rosella Plains - Australia (RLP)",
    value: "RLP",
  },
  {
    label: "Rutland Plains - Australia (RTP)",
    value: "RTP",
  },
  {
    label: "Rutland Plains, Rutland Plains - Australia (RTP)",
    value: "RTP",
  },
  {
    label: "Saibai Island - Australia (SBR)",
    value: "SBR",
  },
  {
    label: "Saibai Island, Saibai Island - Australia (SBR)",
    value: "SBR",
  },
  {
    label: "Sandringham Station - Australia (SRM)",
    value: "SRM",
  },
  {
    label: "Sandringham Station, Sandringham Station - Australia (SRM)",
    value: "SRM",
  },
  {
    label: "Silver Plains - Australia (SSP)",
    value: "SSP",
  },
  {
    label: "Silver Plains, Silver Plains - Australia (SSP)",
    value: "SSP",
  },
  {
    label: "South Galway - Australia (ZGL)",
    value: "ZGL",
  },
  {
    label: "South Galway, South Galway - Australia (ZGL)",
    value: "ZGL",
  },
  {
    label: "Southport - Australia (SHQ)",
    value: "SHQ",
  },
  {
    label: "Southport, Southport - Australia (SHQ)",
    value: "SHQ",
  },
  {
    label: "Spring Creek - Australia (SCG)",
    value: "SCG",
  },
  {
    label: "Spring Creek, Spring Creek - Australia (SCG)",
    value: "SCG",
  },
  {
    label: "Springvale - Australia (KSV)",
    value: "KSV",
  },
  {
    label: "Springvale, Springvale - Australia (KSV)",
    value: "KSV",
  },
  {
    label: "Saint George - Australia (SGO)",
    value: "SGO",
  },
  {
    label: "Saint George, Saint George - Australia (SGO)",
    value: "SGO",
  },
  {
    label: "Saint Pauls - Australia (SVM)",
    value: "SVM",
  },
  {
    label: "Saint Pauls, Saint Pauls - Australia (SVM)",
    value: "SVM",
  },
  {
    label: "Stanthorpe - Australia (SNH)",
    value: "SNH",
  },
  {
    label: "Stanthorpe, Stanthorpe - Australia (SNH)",
    value: "SNH",
  },
  {
    label: "Stephens Island - Australia (STF)",
    value: "STF",
  },
  {
    label: "Stephens Island, Stephens Island - Australia (STF)",
    value: "STF",
  },
  {
    label: "Strathmore - Australia (STH)",
    value: "STH",
  },
  {
    label: "Strathmore, Strathmore - Australia (STH)",
    value: "STH",
  },
  {
    label: "Tanbar - Australia (TXR)",
    value: "TXR",
  },
  {
    label: "Tanbar, Tanbar - Australia (TXR)",
    value: "TXR",
  },
  {
    label: "Tangalooma - Australia (TAN)",
    value: "TAN",
  },
  {
    label: "Tangalooma, Tangalooma - Australia (TAN)",
    value: "TAN",
  },
  {
    label: "Tara - Australia (XTR)",
    value: "XTR",
  },
  {
    label: "Tara, Tara - Australia (XTR)",
    value: "XTR",
  },
  {
    label: "Taroom - Australia (XTO)",
    value: "XTO",
  },
  {
    label: "Taroom, Taroom - Australia (XTO)",
    value: "XTO",
  },
  {
    label: "Tewantin - Australia (TWN)",
    value: "TWN",
  },
  {
    label: "Tewantin, Tewantin - Australia (TWN)",
    value: "TWN",
  },
  {
    label: "Thangool - Australia (THG)",
    value: "THG",
  },
  {
    label: "Thangool, Thangool - Australia (THG)",
    value: "THG",
  },
  {
    label: "Thargomindah - Australia (XTG)",
    value: "XTG",
  },
  {
    label: "Thargomindah, Thargomindah - Australia (XTG)",
    value: "XTG",
  },
  {
    label: "Theodore - Australia (TDR)",
    value: "TDR",
  },
  {
    label: "Theodore, Theodore - Australia (TDR)",
    value: "TDR",
  },
  {
    label: "Thylungra - Australia (TYG)",
    value: "TYG",
  },
  {
    label: "Thylungra, Thylungra - Australia (TYG)",
    value: "TYG",
  },
  {
    label: "Toowoomba - Australia (TWB)",
    value: "TWB",
  },
  {
    label: "Toowoomba, Toowoomba - Australia (TWB)",
    value: "TWB",
  },
  {
    label: "Toowoomba - Australia (WTB)",
    value: "WTB",
  },
  {
    label: "Torwood - Australia (TWP)",
    value: "TWP",
  },
  {
    label: "Torwood, Torwood - Australia (TWP)",
    value: "TWP",
  },
  {
    label: "Townsville - Australia (TSV)",
    value: "TSV",
  },
  {
    label: "Townsville, International - Australia (TSV)",
    value: "TSV",
  },
  {
    label: "Trepell - Australia (TQP)",
    value: "TQP",
  },
  {
    label: "Trepell, Trepell - Australia (TQP)",
    value: "TQP",
  },
  {
    label: "Undara - Australia (UDA)",
    value: "UDA",
  },
  {
    label: "Undara, Undara - Australia (UDA)",
    value: "UDA",
  },
  {
    label: "Vanrook Station - Australia (VNR)",
    value: "VNR",
  },
  {
    label: "Vanrook Station, Vanrook Station - Australia (VNR)",
    value: "VNR",
  },
  {
    label: "Wando Vale - Australia (MFL)",
    value: "MFL",
  },
  {
    label: "Wando Vale, Mount Full Stop - Australia (MFL)",
    value: "MFL",
  },
  {
    label: "Warwick - Australia (WAZ)",
    value: "WAZ",
  },
  {
    label: "Warwick, Warwick - Australia (WAZ)",
    value: "WAZ",
  },
  {
    label: "Waverney - Australia (WAN)",
    value: "WAN",
  },
  {
    label: "Waverney, Waverney - Australia (WAN)",
    value: "WAN",
  },
  {
    label: "Weipa - Australia (WEI)",
    value: "WEI",
  },
  {
    label: "Weipa, Weipa - Australia (WEI)",
    value: "WEI",
  },
  {
    label: "Windorah - Australia (WNR)",
    value: "WNR",
  },
  {
    label: "Windorah, Windorah - Australia (WNR)",
    value: "WNR",
  },
  {
    label: "Winton - Australia (WIN)",
    value: "WIN",
  },
  {
    label: "Winton, Winton - Australia (WIN)",
    value: "WIN",
  },
  {
    label: "Airlie Beach Shute Hbr - Australia (WSY)",
    value: "WSY",
  },
  {
    label: "Airlie Beach Shute Hbr, Whitsunday - Australia (WSY)",
    value: "WSY",
  },
  {
    label: "Wondai - Australia (WDI)",
    value: "WDI",
  },
  {
    label: "Wondai, Wondai - Australia (WDI)",
    value: "WDI",
  },
  {
    label: "Wondoola - Australia (WON)",
    value: "WON",
  },
  {
    label: "Wondoola, Wondoola - Australia (WON)",
    value: "WON",
  },
  {
    label: "Albury, Albury - Australia (ABX)",
    value: "ABX",
  },
  {
    label: "Albury, Albury - Australia (ABX)",
    value: "ABX",
  },
  {
    label: "Armidale, Armidale - Australia (ARM)",
    value: "ARM",
  },
  {
    label: "Armidale, Armidale - Australia (ARM)",
    value: "ARM",
  },
  {
    label: "Wrotham Park - Australia (WPK)",
    value: "WPK",
  },
  {
    label: "Wrotham Park, Wrotham Park - Australia (WPK)",
    value: "WPK",
  },
  {
    label: "Yam Island - Australia (XMY)",
    value: "XMY",
  },
  {
    label: "Yam Island, Yam Island - Australia (XMY)",
    value: "XMY",
  },
  {
    label: "Yorke Island - Australia (OKR)",
    value: "OKR",
  },
  {
    label: "Yorke Island, Yorke Island - Australia (OKR)",
    value: "OKR",
  },
  {
    label: "Ballina - Australia (BNK)",
    value: "BNK",
  },
  {
    label: "Ballina, Byron Gateway - Australia (BNK)",
    value: "BNK",
  },
  {
    label: "Balranald - Australia (BZD)",
    value: "BZD",
  },
  {
    label: "Balranald, Balranald - Australia (BZD)",
    value: "BZD",
  },
  {
    label: "Bathurst - Australia (BHS)",
    value: "BHS",
  },
  {
    label: "Bathurst, Bathurst - Australia (BHS)",
    value: "BHS",
  },
  {
    label: "Bourke - Australia (BRK)",
    value: "BRK",
  },
  {
    label: "Bourke, Bourke - Australia (BRK)",
    value: "BRK",
  },
  {
    label: "Bathurst Island - Australia (BRT)",
    value: "BRT",
  },
  {
    label: "Bathurst Island, Bathurst Island - Australia (BRT)",
    value: "BRT",
  },
  {
    label: "Brewarrina - Australia (BWQ)",
    value: "BWQ",
  },
  {
    label: "Brewarrina, Brewarrina - Australia (BWQ)",
    value: "BWQ",
  },
  {
    label: "Broken Hill - Australia (BHQ)",
    value: "BHQ",
  },
  {
    label: "Broken Hill, Broken Hill - Australia (BHQ)",
    value: "BHQ",
  },
  {
    label: "Camden - Australia (CDU)",
    value: "CDU",
  },
  {
    label: "Camden, Camden - Australia (CDU)",
    value: "CDU",
  },
  {
    label: "Cessnock - Australia (CES)",
    value: "CES",
  },
  {
    label: "Cessnock, Cessnock - Australia (CES)",
    value: "CES",
  },
  {
    label: "Cobar - Australia (CAZ)",
    value: "CAZ",
  },
  {
    label: "Cobar, Cobar - Australia (CAZ)",
    value: "CAZ",
  },
  {
    label: "Coffs Harbour - Australia (CFS)",
    value: "CFS",
  },
  {
    label: "Coffs Harbour, Coffs Harbour - Australia (CFS)",
    value: "CFS",
  },
  {
    label: "Collarenebri - Australia (CRB)",
    value: "CRB",
  },
  {
    label: "Collarenebri, Collarenebri - Australia (CRB)",
    value: "CRB",
  },
  {
    label: "Condobolin - Australia (CBX)",
    value: "CBX",
  },
  {
    label: "Condobolin, Condobolin - Australia (CBX)",
    value: "CBX",
  },
  {
    label: "Coolah - Australia (CLH)",
    value: "CLH",
  },
  {
    label: "Coolah, Coolah - Australia (CLH)",
    value: "CLH",
  },
  {
    label: "Cooma - Australia (OOM)",
    value: "OOM",
  },
  {
    label: "Cooma, Snowy Mountains - Australia (OOM)",
    value: "OOM",
  },
  {
    label: "Coonabarabran - Australia (COJ)",
    value: "COJ",
  },
  {
    label: "Coonabarabran, Coonabarabran - Australia (COJ)",
    value: "COJ",
  },
  {
    label: "Coonamble - Australia (CNB)",
    value: "CNB",
  },
  {
    label: "Coonamble, Coonamble - Australia (CNB)",
    value: "CNB",
  },
  {
    label: "Cootamundra - Australia (CMD)",
    value: "CMD",
  },
  {
    label: "Cootamundra, Cootamundra - Australia (CMD)",
    value: "CMD",
  },
  {
    label: "Corowa - Australia (CWW)",
    value: "CWW",
  },
  {
    label: "Corowa, Corowa - Australia (CWW)",
    value: "CWW",
  },
  {
    label: "Cowra - Australia (CWT)",
    value: "CWT",
  },
  {
    label: "Cowra, Cowra - Australia (CWT)",
    value: "CWT",
  },
  {
    label: "Cudal - Australia (CUG)",
    value: "CUG",
  },
  {
    label: "Cudal, Cudal - Australia (CUG)",
    value: "CUG",
  },
  {
    label: "Deniliquin - Australia (DNQ)",
    value: "DNQ",
  },
  {
    label: "Deniliquin, Deniliquin - Australia (DNQ)",
    value: "DNQ",
  },
  {
    label: "Dubbo - Australia (DBO)",
    value: "DBO",
  },
  {
    label: "Dubbo, City Airport - Australia (DBO)",
    value: "DBO",
  },
  {
    label: "Evans Head - Australia (EVH)",
    value: "EVH",
  },
  {
    label: "Evans Head, Evans Head - Australia (EVH)",
    value: "EVH",
  },
  {
    label: "Finley - Australia (FLY)",
    value: "FLY",
  },
  {
    label: "Finley, Finley - Australia (FLY)",
    value: "FLY",
  },
  {
    label: "Forbes - Australia (FRB)",
    value: "FRB",
  },
  {
    label: "Forbes, Forbes - Australia (FRB)",
    value: "FRB",
  },
  {
    label: "Forster - Australia (FOT)",
    value: "FOT",
  },
  {
    label: "Forster, Forster - Australia (FOT)",
    value: "FOT",
  },
  {
    label: "Glen Innes - Australia (GLI)",
    value: "GLI",
  },
  {
    label: "Glen Innes, Glen Innes - Australia (GLI)",
    value: "GLI",
  },
  {
    label: "Goulburn - Australia (GUL)",
    value: "GUL",
  },
  {
    label: "Goulburn, Goulburn - Australia (GUL)",
    value: "GUL",
  },
  {
    label: "Grafton - Australia (GFN)",
    value: "GFN",
  },
  {
    label: "Grafton, Clarence Valley - Australia (GFN)",
    value: "GFN",
  },
  {
    label: "Griffith - Australia (GFF)",
    value: "GFF",
  },
  {
    label: "Griffith, Griffith - Australia (GFF)",
    value: "GFF",
  },
  {
    label: "Gunnedah - Australia (GUH)",
    value: "GUH",
  },
  {
    label: "Gunnedah, Gunnedah - Australia (GUH)",
    value: "GUH",
  },
  {
    label: "Hay - Australia (HXX)",
    value: "HXX",
  },
  {
    label: "Hay, Hay - Australia (HXX)",
    value: "HXX",
  },
  {
    label: "Inverell - Australia (IVR)",
    value: "IVR",
  },
  {
    label: "Inverell, Inverell - Australia (IVR)",
    value: "IVR",
  },
  {
    label: "Kempsey - Australia (KPS)",
    value: "KPS",
  },
  {
    label: "Kempsey, Kempsey - Australia (KPS)",
    value: "KPS",
  },
  {
    label: "Newcastle - Australia (NTL)",
    value: "NTL",
  },
  {
    label: "Newcastle, Williamtown - Australia (NTL)",
    value: "NTL",
  },
  {
    label: "Newcastle, Aeropelican - Australia (BEO)",
    value: "BEO",
  },
  {
    label: "Lightning Ridge - Australia (LHG)",
    value: "LHG",
  },
  {
    label: "Lightning Ridge, Lightning Ridge - Australia (LHG)",
    value: "LHG",
  },
  {
    label: "Lismore - Australia (LSY)",
    value: "LSY",
  },
  {
    label: "Lismore, Lismore - Australia (LSY)",
    value: "LSY",
  },
  {
    label: "Lord Howe Island - Australia (LDH)",
    value: "LDH",
  },
  {
    label: "Lord Howe Island, Lord Howe Island - Australia (LDH)",
    value: "LDH",
  },
  {
    label: "Maitland - Australia (MTL)",
    value: "MTL",
  },
  {
    label: "Maitland, Maitland - Australia (MTL)",
    value: "MTL",
  },
  {
    label: "Merimbula - Australia (MIM)",
    value: "MIM",
  },
  {
    label: "Merimbula, Merimbula - Australia (MIM)",
    value: "MIM",
  },
  {
    label: "Moree - Australia (MRZ)",
    value: "MRZ",
  },
  {
    label: "Moree, Moree - Australia (MRZ)",
    value: "MRZ",
  },
  {
    label: "Moruya - Australia (MYA)",
    value: "MYA",
  },
  {
    label: "Moruya, Moruya - Australia (MYA)",
    value: "MYA",
  },
  {
    label: "Mudgee - Australia (DGE)",
    value: "DGE",
  },
  {
    label: "Mudgee, Mudgee - Australia (DGE)",
    value: "DGE",
  },
  {
    label: "Nambucca Heads - Australia (NBH)",
    value: "NBH",
  },
  {
    label: "Nambucca Heads, Nambucca Heads - Australia (NBH)",
    value: "NBH",
  },
  {
    label: "Narrabri - Australia (NAA)",
    value: "NAA",
  },
  {
    label: "Narrabri, Narrabri - Australia (NAA)",
    value: "NAA",
  },
  {
    label: "Narrandera - Australia (NRA)",
    value: "NRA",
  },
  {
    label: "Narrandera, Narrandera - Australia (NRA)",
    value: "NRA",
  },
  {
    label: "Nyngan - Australia (NYN)",
    value: "NYN",
  },
  {
    label: "Nyngan, Nyngan - Australia (NYN)",
    value: "NYN",
  },
  {
    label: "Orange - Australia (OAG)",
    value: "OAG",
  },
  {
    label: "Orange, Orange - Australia (OAG)",
    value: "OAG",
  },
  {
    label: "Sydney - Australia (SYD)",
    value: "SYD",
  },
  {
    label: "Sydney, Kingsford Smith - Australia (SYD)",
    value: "SYD",
  },
  {
    label: "Sydney, Bankstown - Australia (BWU)",
    value: "BWU",
  },
  {
    label: "Sydney, Palm Beach Spb - Australia (LBH)",
    value: "LBH",
  },
  {
    label: "Sydney, Rose Bay Spb - Australia (RSE)",
    value: "RSE",
  },
  {
    label: "Parkes - Australia (PKE)",
    value: "PKE",
  },
  {
    label: "Parkes, Parkes - Australia (PKE)",
    value: "PKE",
  },
  {
    label: "Port Macquarie - Australia (PQQ)",
    value: "PQQ",
  },
  {
    label: "Port Macquarie, Port Macquarie - Australia (PQQ)",
    value: "PQQ",
  },
  {
    label: "Quirindi - Australia (UIR)",
    value: "UIR",
  },
  {
    label: "Quirindi, Quirindi - Australia (UIR)",
    value: "UIR",
  },
  {
    label: "Scone - Australia (NSO)",
    value: "NSO",
  },
  {
    label: "Scone, Scone - Australia (NSO)",
    value: "NSO",
  },
  {
    label: "Tamworth - Australia (TMW)",
    value: "TMW",
  },
  {
    label: "Tamworth, Tamworth - Australia (TMW)",
    value: "TMW",
  },
  {
    label: "Taree - Australia (TRO)",
    value: "TRO",
  },
  {
    label: "Taree, Taree - Australia (TRO)",
    value: "TRO",
  },
  {
    label: "Temora - Australia (TEM)",
    value: "TEM",
  },
  {
    label: "Temora, Temora - Australia (TEM)",
    value: "TEM",
  },
  {
    label: "Tibooburra - Australia (TYB)",
    value: "TYB",
  },
  {
    label: "Tibooburra, Tibooburra - Australia (TYB)",
    value: "TYB",
  },
  {
    label: "Tocumwal - Australia (TCW)",
    value: "TCW",
  },
  {
    label: "Tocumwal, Tocumwal - Australia (TCW)",
    value: "TCW",
  },
  {
    label: "Tumut - Australia (TUM)",
    value: "TUM",
  },
  {
    label: "Tumut, Tumut - Australia (TUM)",
    value: "TUM",
  },
  {
    label: "Wagga Wagga - Australia (WGA)",
    value: "WGA",
  },
  {
    label: "Wagga Wagga, Wagga Wagga - Australia (WGA)",
    value: "WGA",
  },
  {
    label: "Walcha - Australia (WLC)",
    value: "WLC",
  },
  {
    label: "Walcha, Walcha - Australia (WLC)",
    value: "WLC",
  },
  {
    label: "Walgett - Australia (WGE)",
    value: "WGE",
  },
  {
    label: "Walgett, Walgett - Australia (WGE)",
    value: "WGE",
  },
  {
    label: "West Wyalong - Australia (WWY)",
    value: "WWY",
  },
  {
    label: "West Wyalong, West Wyalong - Australia (WWY)",
    value: "WWY",
  },
  {
    label: "Wilcannia - Australia (WIO)",
    value: "WIO",
  },
  {
    label: "Wilcannia, Wilcannia - Australia (WIO)",
    value: "WIO",
  },
  {
    label: "Wollongong - Australia (WOL)",
    value: "WOL",
  },
  {
    label: "Wollongong, Illawarra - Australia (WOL)",
    value: "WOL",
  },
  {
    label: "Young - Australia (NGA)",
    value: "NGA",
  },
  {
    label: "Young, Young - Australia (NGA)",
    value: "NGA",
  },
  {
    label: "Alexandria Station - Australia (AXL)",
    value: "AXL",
  },
  {
    label: "Alexandria Station, Alexandria Station - Australia (AXL)",
    value: "AXL",
  },
  {
    label: "Alice Springs - Australia (ASP)",
    value: "ASP",
  },
  {
    label: "Alice Springs, Airport - Australia (ASP)",
    value: "ASP",
  },
  {
    label: "Alroy Downs - Australia (AYD)",
    value: "AYD",
  },
  {
    label: "Alroy Downs, Alroy Downs - Australia (AYD)",
    value: "AYD",
  },
  {
    label: "Ammaroo - Australia (AMX)",
    value: "AMX",
  },
  {
    label: "Ammaroo, Ammaroo - Australia (AMX)",
    value: "AMX",
  },
  {
    label: "Angus Downs Station - Australia (ANZ)",
    value: "ANZ",
  },
  {
    label: "Angus Downs Station, Angus Downs Station - Australia (ANZ)",
    value: "ANZ",
  },
  {
    label: "Anthony Lagoon - Australia (AYL)",
    value: "AYL",
  },
  {
    label: "Anthony Lagoon, Anthony Lagoon - Australia (AYL)",
    value: "AYL",
  },
  {
    label: "Finke - Australia (FIK)",
    value: "FIK",
  },
  {
    label: "Finke, Finke - Australia (FIK)",
    value: "FIK",
  },
  {
    label: "Austral Downs Station - Australia (AWP)",
    value: "AWP",
  },
  {
    label: "Austral Downs Station, Austral Downs Station - Australia (AWP)",
    value: "AWP",
  },
  {
    label: "Auvergne Station - Australia (AVG)",
    value: "AVG",
  },
  {
    label: "Auvergne Station, Auvergne Station - Australia (AVG)",
    value: "AVG",
  },
  {
    label: "Baniyala - Australia (BYX)",
    value: "BYX",
  },
  {
    label: "Baniyala, Baniyala - Australia (BYX)",
    value: "BYX",
  },
  {
    label: "Barkly Downs - Australia (BKP)",
    value: "BKP",
  },
  {
    label: "Barkly Downs, Barkly Downs - Australia (BKP)",
    value: "BKP",
  },
  {
    label: "Delissaville - Australia (DLV)",
    value: "DLV",
  },
  {
    label: "Delissaville, Delissaville - Australia (DLV)",
    value: "DLV",
  },
  {
    label: "Borroloola - Australia (BOX)",
    value: "BOX",
  },
  {
    label: "Borroloola, Borroloola - Australia (BOX)",
    value: "BOX",
  },
  {
    label: "Bickerton Island - Australia (BCZ)",
    value: "BCZ",
  },
  {
    label: "Bickerton Island, Bickerton Island - Australia (BCZ)",
    value: "BCZ",
  },
  {
    label: "Brunette Downs - Australia (BTD)",
    value: "BTD",
  },
  {
    label: "Brunette Downs, Brunette Downs - Australia (BTD)",
    value: "BTD",
  },
  {
    label: "Camfield Station - Australia (CFI)",
    value: "CFI",
  },
  {
    label: "Camfield Station, Camfield Station - Australia (CFI)",
    value: "CFI",
  },
  {
    label: "Coolibah - Australia (COB)",
    value: "COB",
  },
  {
    label: "Coolibah, Coolibah - Australia (COB)",
    value: "COB",
  },
  {
    label: "Cresswell Downs - Australia (CSD)",
    value: "CSD",
  },
  {
    label: "Cresswell Downs, Cresswell Downs - Australia (CSD)",
    value: "CSD",
  },
  {
    label: "Croker Island - Australia (CKI)",
    value: "CKI",
  },
  {
    label: "Croker Island, Croker Island - Australia (CKI)",
    value: "CKI",
  },
  {
    label: "Kalkgurung - Australia (KFG)",
    value: "KFG",
  },
  {
    label: "Kalkgurung, Kalkgurung - Australia (KFG)",
    value: "KFG",
  },
  {
    label: "Daly River Mission - Australia (DVR)",
    value: "DVR",
  },
  {
    label: "Daly River Mission, Daly River Mission - Australia (DVR)",
    value: "DVR",
  },
  {
    label: "Daly Waters - Australia (DYW)",
    value: "DYW",
  },
  {
    label: "Daly Waters, Daly Waters - Australia (DYW)",
    value: "DYW",
  },
  {
    label: "Darwin - Australia (DRW)",
    value: "DRW",
  },
  {
    label: "Darwin, International - Australia (DRW)",
    value: "DRW",
  },
  {
    label: "Elcho Island - Australia (ELC)",
    value: "ELC",
  },
  {
    label: "Elcho Island, Elcho Island - Australia (ELC)",
    value: "ELC",
  },
  {
    label: "Elkedra - Australia (EKD)",
    value: "EKD",
  },
  {
    label: "Elkedra, Elkedra - Australia (EKD)",
    value: "EKD",
  },
  {
    label: "Erldunda - Australia (EDD)",
    value: "EDD",
  },
  {
    label: "Erldunda, Erldunda - Australia (EDD)",
    value: "EDD",
  },
  {
    label: "Eva Downs - Australia (EVD)",
    value: "EVD",
  },
  {
    label: "Eva Downs, Eva Downs - Australia (EVD)",
    value: "EVD",
  },
  {
    label: "Gapuwiyak - Australia (LEL)",
    value: "LEL",
  },
  {
    label: "Gapuwiyak, Lake Evella - Australia (LEL)",
    value: "LEL",
  },
  {
    label: "South Goulburn Island - Australia (GBL)",
    value: "GBL",
  },
  {
    label: "South Goulburn Island, South Goulburn Island - Australia (GBL)",
    value: "GBL",
  },
  {
    label: "The Granites - Australia (GTS)",
    value: "GTS",
  },
  {
    label: "The Granites, The Granites - Australia (GTS)",
    value: "GTS",
  },
  {
    label: "Groote Eylandt - Australia (GTE)",
    value: "GTE",
  },
  {
    label: "Groote Eylandt, Groote Eylandt - Australia (GTE)",
    value: "GTE",
  },
  {
    label: "Oenpelli - Australia (OPI)",
    value: "OPI",
  },
  {
    label: "Oenpelli, Oenpelli - Australia (OPI)",
    value: "OPI",
  },
  {
    label: "Henbury - Australia (HRY)",
    value: "HRY",
  },
  {
    label: "Henbury, Henbury - Australia (HRY)",
    value: "HRY",
  },
  {
    label: "Hermannsburg - Australia (HMG)",
    value: "HMG",
  },
  {
    label: "Hermannsburg, Hermannsburg - Australia (HMG)",
    value: "HMG",
  },
  {
    label: "Humbert River - Australia (HUB)",
    value: "HUB",
  },
  {
    label: "Humbert River, Humbert River - Australia (HUB)",
    value: "HUB",
  },
  {
    label: "Inverway - Australia (IVW)",
    value: "IVW",
  },
  {
    label: "Inverway, Inverway - Australia (IVW)",
    value: "IVW",
  },
  {
    label: "Jabiru - Australia (JAB)",
    value: "JAB",
  },
  {
    label: "Jabiru, Jabiru - Australia (JAB)",
    value: "JAB",
  },
  {
    label: "Wave Hill - Australia (WAV)",
    value: "WAV",
  },
  {
    label: "Wave Hill, Wave Hill - Australia (WAV)",
    value: "WAV",
  },
  {
    label: "Docker River - Australia (DKV)",
    value: "DKV",
  },
  {
    label: "Docker River, Docker River - Australia (DKV)",
    value: "DKV",
  },
  {
    label: "Kings Canyon - Australia (KBJ)",
    value: "KBJ",
  },
  {
    label: "Kings Canyon, Kings Canyon - Australia (KBJ)",
    value: "KBJ",
  },
  {
    label: "Kings Creek Stati - Australia (KCS)",
    value: "KCS",
  },
  {
    label: "Kings Creek Stati, Kings Creek Stati - Australia (KCS)",
    value: "KCS",
  },
  {
    label: "Kirkimbie Station - Australia (KBB)",
    value: "KBB",
  },
  {
    label: "Kirkimbie Station, Kirkimbie Station - Australia (KBB)",
    value: "KBB",
  },
  {
    label: "Kulgera - Australia (KGR)",
    value: "KGR",
  },
  {
    label: "Kulgera, Kulgera Roadhouse - Australia (KGR)",
    value: "KGR",
  },
  {
    label: "Kurundi Station - Australia (KRD)",
    value: "KRD",
  },
  {
    label: "Kurundi Station, Kurundi Station - Australia (KRD)",
    value: "KRD",
  },
  {
    label: "Lajamanu - Australia (HOK)",
    value: "HOK",
  },
  {
    label: "Lajamanu, Hooker Creek - Australia (HOK)",
    value: "HOK",
  },
  {
    label: "Lake Nash - Australia (LNH)",
    value: "LNH",
  },
  {
    label: "Lake Nash, Lake Nash - Australia (LNH)",
    value: "LNH",
  },
  {
    label: "Limbunya Station - Australia (LIB)",
    value: "LIB",
  },
  {
    label: "Limbunya Station, Limbunya Station - Australia (LIB)",
    value: "LIB",
  },
  {
    label: "Lissadell - Australia (LLL)",
    value: "LLL",
  },
  {
    label: "Lissadell, Lissadell - Australia (LLL)",
    value: "LLL",
  },
  {
    label: "Macdonald Downs - Australia (MNW)",
    value: "MNW",
  },
  {
    label: "Macdonald Downs, Macdonald Downs - Australia (MNW)",
    value: "MNW",
  },
  {
    label: "Mainoru - Australia (MIZ)",
    value: "MIZ",
  },
  {
    label: "Mainoru, Mainoru - Australia (MIZ)",
    value: "MIZ",
  },
  {
    label: "Maningrida - Australia (MNG)",
    value: "MNG",
  },
  {
    label: "Maningrida, Maningrida - Australia (MNG)",
    value: "MNG",
  },
  {
    label: "Manners Creek Station - Australia (MFP)",
    value: "MFP",
  },
  {
    label: "Manners Creek Station, Manners Creek Station - Australia (MFP)",
    value: "MFP",
  },
  {
    label: "Marqua - Australia (MQE)",
    value: "MQE",
  },
  {
    label: "Marqua, Marqua - Australia (MQE)",
    value: "MQE",
  },
  {
    label: "Milikapiti - Australia (SNB)",
    value: "SNB",
  },
  {
    label: "Milikapiti, Snake Bay - Australia (SNB)",
    value: "SNB",
  },
  {
    label: "Milingimbi Island - Australia (MGT)",
    value: "MGT",
  },
  {
    label: "Milingimbi Island, Milingimbi Island - Australia (MGT)",
    value: "MGT",
  },
  {
    label: "Mittiebah - Australia (MIY)",
    value: "MIY",
  },
  {
    label: "Mittiebah, Mittiebah - Australia (MIY)",
    value: "MIY",
  },
  {
    label: "Moroak - Australia (MRT)",
    value: "MRT",
  },
  {
    label: "Moroak, Moroak - Australia (MRT)",
    value: "MRT",
  },
  {
    label: "Mount Cavenagh - Australia (MKV)",
    value: "MKV",
  },
  {
    label: "Mount Cavenagh, Mount Cavenagh - Australia (MKV)",
    value: "MKV",
  },
  {
    label: "Mount Sandford Station - Australia (MTD)",
    value: "MTD",
  },
  {
    label: "Mount Sandford Station, Mount Sandford Station - Australia (MTD)",
    value: "MTD",
  },
  {
    label: "Mount Swan - Australia (MSF)",
    value: "MSF",
  },
  {
    label: "Mount Swan, Mount Swan - Australia (MSF)",
    value: "MSF",
  },
  {
    label: "Mount Valley - Australia (MNV)",
    value: "MNV",
  },
  {
    label: "Mount Valley, Mount Valley - Australia (MNV)",
    value: "MNV",
  },
  {
    label: "Mulga Park - Australia (MUP)",
    value: "MUP",
  },
  {
    label: "Mulga Park, Mulga Park - Australia (MUP)",
    value: "MUP",
  },
  {
    label: "Napperby - Australia (NPP)",
    value: "NPP",
  },
  {
    label: "Napperby, Napperby - Australia (NPP)",
    value: "NPP",
  },
  {
    label: "Newry - Australia (NRY)",
    value: "NRY",
  },
  {
    label: "Newry, Newry - Australia (NRY)",
    value: "NRY",
  },
  {
    label: "Ngukurr - Australia (RPM)",
    value: "RPM",
  },
  {
    label: "Ngukurr, Ngukurr - Australia (RPM)",
    value: "RPM",
  },
  {
    label: "Nhulunbuy - Australia (GOV)",
    value: "GOV",
  },
  {
    label: "Nhulunbuy, Gove - Australia (GOV)",
    value: "GOV",
  },
  {
    label: "Numbulwar - Australia (NUB)",
    value: "NUB",
  },
  {
    label: "Numbulwar, Numbulwar - Australia (NUB)",
    value: "NUB",
  },
  {
    label: "Nutwood Downs - Australia (UTD)",
    value: "UTD",
  },
  {
    label: "Nutwood Downs, Nutwood Downs - Australia (UTD)",
    value: "UTD",
  },
  {
    label: "Peppimenarti - Australia (PEP)",
    value: "PEP",
  },
  {
    label: "Peppimenarti, Peppimenarti - Australia (PEP)",
    value: "PEP",
  },
  {
    label: "Garden Point - Australia (GPN)",
    value: "GPN",
  },
  {
    label: "Garden Point, Garden Point - Australia (GPN)",
    value: "GPN",
  },
  {
    label: "Ramingining - Australia (RAM)",
    value: "RAM",
  },
  {
    label: "Ramingining, Ramingining - Australia (RAM)",
    value: "RAM",
  },
  {
    label: "Robinson River - Australia (RRV)",
    value: "RRV",
  },
  {
    label: "Robinson River, Robinson River - Australia (RRV)",
    value: "RRV",
  },
  {
    label: "Roper Bar - Australia (RPB)",
    value: "RPB",
  },
  {
    label: "Roper Bar, Roper Bar - Australia (RPB)",
    value: "RPB",
  },
  {
    label: "Roper Valley - Australia (RPV)",
    value: "RPV",
  },
  {
    label: "Roper Valley, Roper Valley - Australia (RPV)",
    value: "RPV",
  },
  {
    label: "Smith Point - Australia (SHU)",
    value: "SHU",
  },
  {
    label: "Smith Point, Smith Point - Australia (SHU)",
    value: "SHU",
  },
  {
    label: "Tennant Creek - Australia (TCA)",
    value: "TCA",
  },
  {
    label: "Tennant Creek, Tennant Creek - Australia (TCA)",
    value: "TCA",
  },
  {
    label: "Timber Creek - Australia (TBK)",
    value: "TBK",
  },
  {
    label: "Timber Creek, Timber Creek - Australia (TBK)",
    value: "TBK",
  },
  {
    label: "Tobermorey - Australia (TYP)",
    value: "TYP",
  },
  {
    label: "Tobermorey, Tobermorey - Australia (TYP)",
    value: "TYP",
  },
  {
    label: "Ayers Rock - Australia (AYQ)",
    value: "AYQ",
  },
  {
    label: "Ayers Rock, Connellan - Australia (AYQ)",
    value: "AYQ",
  },
  {
    label: "Victoria River Do - Australia (VCD)",
    value: "VCD",
  },
  {
    label: "Victoria River Do, Victoria River Do - Australia (VCD)",
    value: "VCD",
  },
  {
    label: "Wollogorang - Australia (WLL)",
    value: "WLL",
  },
  {
    label: "Wollogorang, Wollogorang - Australia (WLL)",
    value: "WLL",
  },
  {
    label: "Yuendumu - Australia (YUE)",
    value: "YUE",
  },
  {
    label: "Yuendumu, Yuendumu - Australia (YUE)",
    value: "YUE",
  },
  {
    label: "Alton Downs - Australia (AWN)",
    value: "AWN",
  },
  {
    label: "Alton Downs, Alton Downs - Australia (AWN)",
    value: "AWN",
  },
  {
    label: "Amata - Australia (AMT)",
    value: "AMT",
  },
  {
    label: "Amata, Amata - Australia (AMT)",
    value: "AMT",
  },
  {
    label: "American River - Australia (RCN)",
    value: "RCN",
  },
  {
    label: "American River, American River - Australia (RCN)",
    value: "RCN",
  },
  {
    label: "Andamooka - Australia (ADO)",
    value: "ADO",
  },
  {
    label: "Andamooka, Andamooka - Australia (ADO)",
    value: "ADO",
  },
  {
    label: "Balcanoona - Australia (LCN)",
    value: "LCN",
  },
  {
    label: "Balcanoona, Balcanoona - Australia (LCN)",
    value: "LCN",
  },
  {
    label: "Ceduna - Australia (CED)",
    value: "CED",
  },
  {
    label: "Ceduna, Ceduna - Australia (CED)",
    value: "CED",
  },
  {
    label: "Cleve - Australia (CVC)",
    value: "CVC",
  },
  {
    label: "Cleve, Cleve - Australia (CVC)",
    value: "CVC",
  },
  {
    label: "Clifton Hills - Australia (CFH)",
    value: "CFH",
  },
  {
    label: "Clifton Hills, Clifton Hills - Australia (CFH)",
    value: "CFH",
  },
  {
    label: "Coober Pedy - Australia (CPD)",
    value: "CPD",
  },
  {
    label: "Coober Pedy, Coober Pedy - Australia (CPD)",
    value: "CPD",
  },
  {
    label: "Coorabie - Australia (CRJ)",
    value: "CRJ",
  },
  {
    label: "Coorabie, Coorabie - Australia (CRJ)",
    value: "CRJ",
  },
  {
    label: "Cordillo Downs - Australia (ODL)",
    value: "ODL",
  },
  {
    label: "Cordillo Downs, Cordillo Downs - Australia (ODL)",
    value: "ODL",
  },
  {
    label: "Cowarie - Australia (CWR)",
    value: "CWR",
  },
  {
    label: "Cowarie, Cowarie - Australia (CWR)",
    value: "CWR",
  },
  {
    label: "Cowell - Australia (CCW)",
    value: "CCW",
  },
  {
    label: "Cowell, Cowell - Australia (CCW)",
    value: "CCW",
  },
  {
    label: "Dulkaninna - Australia (DLK)",
    value: "DLK",
  },
  {
    label: "Dulkaninna, Dulkaninna - Australia (DLK)",
    value: "DLK",
  },
  {
    label: "Etadunna - Australia (ETD)",
    value: "ETD",
  },
  {
    label: "Etadunna, Etadunna - Australia (ETD)",
    value: "ETD",
  },
  {
    label: "Hawker - Australia (HWK)",
    value: "HWK",
  },
  {
    label: "Hawker, Hawker - Australia (HWK)",
    value: "HWK",
  },
  {
    label: "Indulkana - Australia (IDK)",
    value: "IDK",
  },
  {
    label: "Indulkana, Indulkana - Australia (IDK)",
    value: "IDK",
  },
  {
    label: "Innamincka - Australia (INM)",
    value: "INM",
  },
  {
    label: "Innamincka, Innamincka - Australia (INM)",
    value: "INM",
  },
  {
    label: "Kingscote - Australia (KGC)",
    value: "KGC",
  },
  {
    label: "Kingscote, Kingscote - Australia (KGC)",
    value: "KGC",
  },
  {
    label: "Leigh Creek - Australia (LGH)",
    value: "LGH",
  },
  {
    label: "Leigh Creek, Leigh Creek - Australia (LGH)",
    value: "LGH",
  },
  {
    label: "Lock - Australia (LOC)",
    value: "LOC",
  },
  {
    label: "Lock, Lock - Australia (LOC)",
    value: "LOC",
  },
  {
    label: "Marla - Australia (MRP)",
    value: "MRP",
  },
  {
    label: "Marla, Marla - Australia (MRP)",
    value: "MRP",
  },
  {
    label: "Marree - Australia (RRE)",
    value: "RRE",
  },
  {
    label: "Marree, Marree - Australia (RRE)",
    value: "RRE",
  },
  {
    label: "Merty Merty - Australia (RTY)",
    value: "RTY",
  },
  {
    label: "Merty Merty, Merty Merty - Australia (RTY)",
    value: "RTY",
  },
  {
    label: "Millicent - Australia (MLR)",
    value: "MLR",
  },
  {
    label: "Millicent, Millicent - Australia (MLR)",
    value: "MLR",
  },
  {
    label: "Minnipa - Australia (MIN)",
    value: "MIN",
  },
  {
    label: "Minnipa, Minnipa - Australia (MIN)",
    value: "MIN",
  },
  {
    label: "Minlaton - Australia (XML)",
    value: "XML",
  },
  {
    label: "Minlaton, Minlaton - Australia (XML)",
    value: "XML",
  },
  {
    label: "Moolawatana - Australia (MWT)",
    value: "MWT",
  },
  {
    label: "Moolawatana, Moolawatana - Australia (MWT)",
    value: "MWT",
  },
  {
    label: "Moomba - Australia (MOO)",
    value: "MOO",
  },
  {
    label: "Moomba, Moomba - Australia (MOO)",
    value: "MOO",
  },
  {
    label: "Mount Gambier - Australia (MGB)",
    value: "MGB",
  },
  {
    label: "Mount Gambier, Mount Gambier - Australia (MGB)",
    value: "MGB",
  },
  {
    label: "Mount Gunson - Australia (GSN)",
    value: "GSN",
  },
  {
    label: "Mount Gunson, Mount Gunson - Australia (GSN)",
    value: "GSN",
  },
  {
    label: "Mulka - Australia (MVK)",
    value: "MVK",
  },
  {
    label: "Mulka, Mulka - Australia (MVK)",
    value: "MVK",
  },
  {
    label: "Mungeranie - Australia (MNE)",
    value: "MNE",
  },
  {
    label: "Mungeranie, Mungeranie - Australia (MNE)",
    value: "MNE",
  },
  {
    label: "Olympic Dam - Australia (OLP)",
    value: "OLP",
  },
  {
    label: "Olympic Dam, Olympic Dam - Australia (OLP)",
    value: "OLP",
  },
  {
    label: "Oodnadatta - Australia (ODD)",
    value: "ODD",
  },
  {
    label: "Oodnadatta, Oodnadatta - Australia (ODD)",
    value: "ODD",
  },
  {
    label: "Pandie Pandie - Australia (PDE)",
    value: "PDE",
  },
  {
    label: "Pandie Pandie, Pandie Pandie - Australia (PDE)",
    value: "PDE",
  },
  {
    label: "Parndana - Australia (PDN)",
    value: "PDN",
  },
  {
    label: "Parndana, Parndana - Australia (PDN)",
    value: "PDN",
  },
  {
    label: "Port Augusta - Australia (PUG)",
    value: "PUG",
  },
  {
    label: "Port Augusta, Port Augusta - Australia (PUG)",
    value: "PUG",
  },
  {
    label: "Penneshaw - Australia (PEA)",
    value: "PEA",
  },
  {
    label: "Penneshaw, Penneshaw - Australia (PEA)",
    value: "PEA",
  },
  {
    label: "Penong - Australia (PEY)",
    value: "PEY",
  },
  {
    label: "Penong, Penong - Australia (PEY)",
    value: "PEY",
  },
  {
    label: "Port Lincoln - Australia (PLO)",
    value: "PLO",
  },
  {
    label: "Port Lincoln, Port Lincoln - Australia (PLO)",
    value: "PLO",
  },
  {
    label: "Port Pirie - Australia (PPI)",
    value: "PPI",
  },
  {
    label: "Port Pirie, Port Pirie - Australia (PPI)",
    value: "PPI",
  },
  {
    label: "Prominent Hill - Australia (PXH)",
    value: "PXH",
  },
  {
    label: "Prominent Hill, Prominent Hill Apt - Australia (PXH)",
    value: "PXH",
  },
  {
    label: "Ernabella - Australia (ERB)",
    value: "ERB",
  },
  {
    label: "Ernabella, Ernabella - Australia (ERB)",
    value: "ERB",
  },
  {
    label: "Renmark - Australia (RMK)",
    value: "RMK",
  },
  {
    label: "Renmark, Renmark - Australia (RMK)",
    value: "RMK",
  },
  {
    label: "Streaky Bay - Australia (KBY)",
    value: "KBY",
  },
  {
    label: "Streaky Bay, Streaky Bay - Australia (KBY)",
    value: "KBY",
  },
  {
    label: "Tarcoola - Australia (TAQ)",
    value: "TAQ",
  },
  {
    label: "Tarcoola, Tarcoola - Australia (TAQ)",
    value: "TAQ",
  },
  {
    label: "Whyalla - Australia (WYA)",
    value: "WYA",
  },
  {
    label: "Whyalla, Whyalla - Australia (WYA)",
    value: "WYA",
  },
  {
    label: "Wudinna - Australia (WUD)",
    value: "WUD",
  },
  {
    label: "Wudinna, Wudinna - Australia (WUD)",
    value: "WUD",
  },
  {
    label: "Yalata Mission - Australia (KYI)",
    value: "KYI",
  },
  {
    label: "Yalata Mission, Yalata Mission - Australia (KYI)",
    value: "KYI",
  },
  {
    label: "Yorketown - Australia (ORR)",
    value: "ORR",
  },
  {
    label: "Yorketown, Yorketown - Australia (ORR)",
    value: "ORR",
  },
  {
    label: "Cape Barren - Australia (CBI)",
    value: "CBI",
  },
  {
    label: "Cape Barren, Cape Barren - Australia (CBI)",
    value: "CBI",
  },
  {
    label: "Devonport - Australia (DPO)",
    value: "DPO",
  },
  {
    label: "Devonport, Devonport - Australia (DPO)",
    value: "DPO",
  },
  {
    label: "Flinders Island - Australia (FLS)",
    value: "FLS",
  },
  {
    label: "Flinders Island, Flinders Island - Australia (FLS)",
    value: "FLS",
  },
  {
    label: "George Town - Australia (GEE)",
    value: "GEE",
  },
  {
    label: "George Town, George Town - Australia (GEE)",
    value: "GEE",
  },
  {
    label: "Queenstown - Australia (UEE)",
    value: "UEE",
  },
  {
    label: "Queenstown, Queenstown - Australia (UEE)",
    value: "UEE",
  },
  {
    label: "Hobart - Australia (HBA)",
    value: "HBA",
  },
  {
    label: "Hobart, International - Australia (HBA)",
    value: "HBA",
  },
  {
    label: "King Island - Australia (KNS)",
    value: "KNS",
  },
  {
    label: "King Island, King Island - Australia (KNS)",
    value: "KNS",
  },
  {
    label: "Latrobe - Australia (LTB)",
    value: "LTB",
  },
  {
    label: "Latrobe, Latrobe - Australia (LTB)",
    value: "LTB",
  },
  {
    label: "Launceston - Australia (LST)",
    value: "LST",
  },
  {
    label: "Launceston, Launceston - Australia (LST)",
    value: "LST",
  },
  {
    label: "Saint Helens - Australia (HLS)",
    value: "HLS",
  },
  {
    label: "Saint Helens, Saint Helens - Australia (HLS)",
    value: "HLS",
  },
  {
    label: "Smithton - Australia (SIO)",
    value: "SIO",
  },
  {
    label: "Smithton, Smithton - Australia (SIO)",
    value: "SIO",
  },
  {
    label: "Strahan - Australia (SRN)",
    value: "SRN",
  },
  {
    label: "Strahan, Strahan - Australia (SRN)",
    value: "SRN",
  },
  {
    label: "Burnie - Australia (BWT)",
    value: "BWT",
  },
  {
    label: "Burnie, Wynyard - Australia (BWT)",
    value: "BWT",
  },
  {
    label: "Ararat - Australia (ARY)",
    value: "ARY",
  },
  {
    label: "Ararat, Ararat - Australia (ARY)",
    value: "ARY",
  },
  {
    label: "Melbourne - Australia (MEL)",
    value: "MEL",
  },
  {
    label: "Melbourne, Melbourne Airport - Australia (MEL)",
    value: "MEL",
  },
  {
    label: "Melbourne, Avalon - Australia (AVV)",
    value: "AVV",
  },
  {
    label: "Melbourne, Essendon - Australia (MEB)",
    value: "MEB",
  },
  {
    label: "Melbourne, Moorabbin - Australia (MBW)",
    value: "MBW",
  },
  {
    label: "Bairnsdale - Australia (BSJ)",
    value: "BSJ",
  },
  {
    label: "Bairnsdale, Bairnsdale - Australia (BSJ)",
    value: "BSJ",
  },
  {
    label: "Benalla - Australia (BLN)",
    value: "BLN",
  },
  {
    label: "Benalla, Benalla - Australia (BLN)",
    value: "BLN",
  },
  {
    label: "Bendigo - Australia (BXG)",
    value: "BXG",
  },
  {
    label: "Bendigo, Bendigo - Australia (BXG)",
    value: "BXG",
  },
  {
    label: "Bright - Australia (BRJ)",
    value: "BRJ",
  },
  {
    label: "Bright, Bright - Australia (BRJ)",
    value: "BRJ",
  },
  {
    label: "Colac - Australia (XCO)",
    value: "XCO",
  },
  {
    label: "Colac, Colac - Australia (XCO)",
    value: "XCO",
  },
  {
    label: "Corryong - Australia (CYG)",
    value: "CYG",
  },
  {
    label: "Corryong, Corryong - Australia (CYG)",
    value: "CYG",
  },
  {
    label: "Echuca - Australia (ECH)",
    value: "ECH",
  },
  {
    label: "Echuca, Echuca - Australia (ECH)",
    value: "ECH",
  },
  {
    label: "Hamilton - Australia (HLT)",
    value: "HLT",
  },
  {
    label: "Hamilton, Hamilton - Australia (HLT)",
    value: "HLT",
  },
  {
    label: "Hopetoun - Australia (HTU)",
    value: "HTU",
  },
  {
    label: "Hopetoun, Hopetoun - Australia (HTU)",
    value: "HTU",
  },
  {
    label: "Horsham - Australia (HSM)",
    value: "HSM",
  },
  {
    label: "Horsham, Horsham - Australia (HSM)",
    value: "HSM",
  },
  {
    label: "Kerang - Australia (KRA)",
    value: "KRA",
  },
  {
    label: "Kerang, Kerang - Australia (KRA)",
    value: "KRA",
  },
  {
    label: "Traralgon - Australia (TGN)",
    value: "TGN",
  },
  {
    label: "Traralgon, Latrobe Valley - Australia (TGN)",
    value: "TGN",
  },
  {
    label: "Mallacoota - Australia (XMC)",
    value: "XMC",
  },
  {
    label: "Mallacoota, Mallacoota - Australia (XMC)",
    value: "XMC",
  },
  {
    label: "Mildura - Australia (MQL)",
    value: "MQL",
  },
  {
    label: "Mildura, Mildura - Australia (MQL)",
    value: "MQL",
  },
  {
    label: "Mount Hotham - Australia (MHU)",
    value: "MHU",
  },
  {
    label: "Mount Hotham, Mount Hotham - Australia (MHU)",
    value: "MHU",
  },
  {
    label: "Orbost - Australia (RBS)",
    value: "RBS",
  },
  {
    label: "Orbost, Orbost - Australia (RBS)",
    value: "RBS",
  },
  {
    label: "Ouyen - Australia (OYN)",
    value: "OYN",
  },
  {
    label: "Ouyen, Ouyen - Australia (OYN)",
    value: "OYN",
  },
  {
    label: "Mount Buffalo - Australia (MBF)",
    value: "MBF",
  },
  {
    label: "Mount Buffalo, Porepunkah - Australia (MBF)",
    value: "MBF",
  },
  {
    label: "Portland - Australia (PTJ)",
    value: "PTJ",
  },
  {
    label: "Portland, Portland - Australia (PTJ)",
    value: "PTJ",
  },
  {
    label: "Robinvale - Australia (RBC)",
    value: "RBC",
  },
  {
    label: "Robinvale, Robinvale - Australia (RBC)",
    value: "RBC",
  },
  {
    label: "Sale - Australia (SXE)",
    value: "SXE",
  },
  {
    label: "Sale, West Sale - Australia (SXE)",
    value: "SXE",
  },
  {
    label: "Shepparton - Australia (SHT)",
    value: "SHT",
  },
  {
    label: "Shepparton, Shepparton - Australia (SHT)",
    value: "SHT",
  },
  {
    label: "Stawell - Australia (SWC)",
    value: "SWC",
  },
  {
    label: "Stawell, Stawell - Australia (SWC)",
    value: "SWC",
  },
  {
    label: "Swan Hill - Australia (SWH)",
    value: "SWH",
  },
  {
    label: "Swan Hill, Swan Hill - Australia (SWH)",
    value: "SWH",
  },
  {
    label: "Wangaratta - Australia (WGT)",
    value: "WGT",
  },
  {
    label: "Wangaratta, Wangaratta - Australia (WGT)",
    value: "WGT",
  },
  {
    label: "Warracknabeal - Australia (WKB)",
    value: "WKB",
  },
  {
    label: "Warracknabeal, Warracknabeal - Australia (WKB)",
    value: "WKB",
  },
  {
    label: "Warrnambool - Australia (WMB)",
    value: "WMB",
  },
  {
    label: "Warrnambool, Warrnambool - Australia (WMB)",
    value: "WMB",
  },
  {
    label: "Welshpool - Australia (WHL)",
    value: "WHL",
  },
  {
    label: "Welshpool, Welshpool - Australia (WHL)",
    value: "WHL",
  },
  {
    label: "Albany - Australia (ALH)",
    value: "ALH",
  },
  {
    label: "Albany, Albany - Australia (ALH)",
    value: "ALH",
  },
  {
    label: "Argyle - Australia (GYL)",
    value: "GYL",
  },
  {
    label: "Argyle, Argyle - Australia (GYL)",
    value: "GYL",
  },
  {
    label: "Balgo Hill - Australia (BQW)",
    value: "BQW",
  },
  {
    label: "Balgo Hill, Balgo Hill - Australia (BQW)",
    value: "BQW",
  },
  {
    label: "Barimunya - Australia (BYP)",
    value: "BYP",
  },
  {
    label: "Barimunya, Barimunya - Australia (BYP)",
    value: "BYP",
  },
  {
    label: "Barrow Island - Australia (BWB)",
    value: "BWB",
  },
  {
    label: "Barrow Island, Barrow Island - Australia (BWB)",
    value: "BWB",
  },
  {
    label: "Beagle Bay - Australia (BEE)",
    value: "BEE",
  },
  {
    label: "Beagle Bay, Beagle Bay - Australia (BEE)",
    value: "BEE",
  },
  {
    label: "Bedford Downs - Australia (BDW)",
    value: "BDW",
  },
  {
    label: "Bedford Downs, Bedford Downs - Australia (BDW)",
    value: "BDW",
  },
  {
    label: "Beverley Springs - Australia (BVZ)",
    value: "BVZ",
  },
  {
    label: "Beverley Springs, Beverley Springs - Australia (BVZ)",
    value: "BVZ",
  },
  {
    label: "Boolgeeda - Australia (OCM)",
    value: "OCM",
  },
  {
    label: "Boolgeeda, Boolgeeda - Australia (OCM)",
    value: "OCM",
  },
  {
    label: "Broome - Australia (BME)",
    value: "BME",
  },
  {
    label: "Broome, International - Australia (BME)",
    value: "BME",
  },
  {
    label: "Bunbury - Australia (BUY)",
    value: "BUY",
  },
  {
    label: "Bunbury, Bunbury - Australia (BUY)",
    value: "BUY",
  },
  {
    label: "Busselton - Australia (BQB)",
    value: "BQB",
  },
  {
    label: "Busselton, Busselton - Australia (BQB)",
    value: "BQB",
  },
  {
    label: "Caiguna - Australia (CGV)",
    value: "CGV",
  },
  {
    label: "Caiguna, Caiguna - Australia (CGV)",
    value: "CGV",
  },
  {
    label: "Carlton Hill - Australia (CRY)",
    value: "CRY",
  },
  {
    label: "Carlton Hill, Carlton Hill - Australia (CRY)",
    value: "CRY",
  },
  {
    label: "Carnarvon - Australia (CVQ)",
    value: "CVQ",
  },
  {
    label: "Carnarvon, Carnarvon - Australia (CVQ)",
    value: "CVQ",
  },
  {
    label: "Cherrabun - Australia (CBC)",
    value: "CBC",
  },
  {
    label: "Cherrabun, Cherrabun - Australia (CBC)",
    value: "CBC",
  },
  {
    label: "Christmas Creek Mine - Australia (CKW)",
    value: "CKW",
  },
  {
    label: "Christmas Creek Mine, Christmas Creek Mine - Australia (CKW)",
    value: "CKW",
  },
  {
    label: "Christmas Creek Stn - Australia (CXQ)",
    value: "CXQ",
  },
  {
    label: "Christmas Creek Stn, Christmas Creek Stn - Australia (CXQ)",
    value: "CXQ",
  },
  {
    label: "Collie - Australia (CIE)",
    value: "CIE",
  },
  {
    label: "Collie, Collie - Australia (CIE)",
    value: "CIE",
  },
  {
    label: "Coolawanyah Station - Australia (COY)",
    value: "COY",
  },
  {
    label: "Coolawanyah Station, Coolawanyah Station - Australia (COY)",
    value: "COY",
  },
  {
    label: "Coondewanna - Australia (CJF)",
    value: "CJF",
  },
  {
    label: "Coondewanna, Coondewanna - Australia (CJF)",
    value: "CJF",
  },
  {
    label: "Cue - Australia (CUY)",
    value: "CUY",
  },
  {
    label: "Cue, Cue - Australia (CUY)",
    value: "CUY",
  },
  {
    label: "Dalgaranga Gold Mine - Australia (DGD)",
    value: "DGD",
  },
  {
    label: "Dalgaranga Gold Mine, Dalgaranga Gold Mine - Australia (DGD)",
    value: "DGD",
  },
  {
    label: "Denham - Australia (DNM)",
    value: "DNM",
  },
  {
    label: "Denham, Denham - Australia (DNM)",
    value: "DNM",
  },
  {
    label: "Derby - Australia (DRB)",
    value: "DRB",
  },
  {
    label: "Derby, Derby Airport - Australia (DRB)",
    value: "DRB",
  },
  {
    label: "Derby, Raaf Curtin - Australia (DCN)",
    value: "DCN",
  },
  {
    label: "Dongara - Australia (DOX)",
    value: "DOX",
  },
  {
    label: "Dongara, Dongara - Australia (DOX)",
    value: "DOX",
  },
  {
    label: "Doogan - Australia (DNG)",
    value: "DNG",
  },
  {
    label: "Doogan, Doogan Airport - Australia (DNG)",
    value: "DNG",
  },
  {
    label: "Drysdale River - Australia (DRY)",
    value: "DRY",
  },
  {
    label: "Drysdale River, Drysdale River Airport - Australia (DRY)",
    value: "DRY",
  },
  {
    label: "Eneabba - Australia (ENB)",
    value: "ENB",
  },
  {
    label: "Eneabba, Eneabba - Australia (ENB)",
    value: "ENB",
  },
  {
    label: "Esperance - Australia (EPR)",
    value: "EPR",
  },
  {
    label: "Esperance, Esperance - Australia (EPR)",
    value: "EPR",
  },
  {
    label: "Eucla - Australia (EUC)",
    value: "EUC",
  },
  {
    label: "Eucla, Eucla - Australia (EUC)",
    value: "EUC",
  },
  {
    label: "Learmonth - Australia (LEA)",
    value: "LEA",
  },
  {
    label: "Learmonth, Learmonth - Australia (LEA)",
    value: "LEA",
  },
  {
    label: "Fitzroy Crossing - Australia (FIZ)",
    value: "FIZ",
  },
  {
    label: "Fitzroy Crossing, Fitzroy Crossing - Australia (FIZ)",
    value: "FIZ",
  },
  {
    label: "Flora Valley - Australia (FVL)",
    value: "FVL",
  },
  {
    label: "Flora Valley, Flora Valley - Australia (FVL)",
    value: "FVL",
  },
  {
    label: "Forrest River - Australia (FVR)",
    value: "FVR",
  },
  {
    label: "Forrest River, Forrest River Arpt - Australia (FVR)",
    value: "FVR",
  },
  {
    label: "Forrest River Mission - Australia (FOS)",
    value: "FOS",
  },
  {
    label: "Forrest River Mission, Forrest River Mission - Australia (FOS)",
    value: "FOS",
  },
  {
    label: "Fossil Downs Station - Australia (FSL)",
    value: "FSL",
  },
  {
    label: "Fossil Downs Station, Fossil Downs Station - Australia (FSL)",
    value: "FSL",
  },
  {
    label: "Cloudbreak - Australia (KFE)",
    value: "KFE",
  },
  {
    label: "Cloudbreak, Fortescue Dave Forrest - Australia (KFE)",
    value: "KFE",
  },
  {
    label: "Gascoyne Junction - Australia (GSC)",
    value: "GSC",
  },
  {
    label: "Gascoyne Junction, Gascoyne Junction - Australia (GSC)",
    value: "GSC",
  },
  {
    label: "Geraldton - Australia (GET)",
    value: "GET",
  },
  {
    label: "Geraldton, Geraldton - Australia (GET)",
    value: "GET",
  },
  {
    label: "Gibb River - Australia (GBV)",
    value: "GBV",
  },
  {
    label: "Gibb River, Gibb River - Australia (GBV)",
    value: "GBV",
  },
  {
    label: "Ginbata - Australia (GBW)",
    value: "GBW",
  },
  {
    label: "Ginbata, Ginbata - Australia (GBW)",
    value: "GBW",
  },
  {
    label: "Mount Goldsworthy - Australia (GLY)",
    value: "GLY",
  },
  {
    label: "Mount Goldsworthy, Mount Goldsworthy - Australia (GLY)",
    value: "GLY",
  },
  {
    label: "Gordon Downs - Australia (GDD)",
    value: "GDD",
  },
  {
    label: "Gordon Downs, Gordon Downs - Australia (GDD)",
    value: "GDD",
  },
  {
    label: "Halls Creek - Australia (HCQ)",
    value: "HCQ",
  },
  {
    label: "Halls Creek, Halls Creek - Australia (HCQ)",
    value: "HCQ",
  },
  {
    label: "Hillside - Australia (HLL)",
    value: "HLL",
  },
  {
    label: "Hillside, Hillside - Australia (HLL)",
    value: "HLL",
  },
  {
    label: "Highbury - Australia (HIG)",
    value: "HIG",
  },
  {
    label: "Highbury, Highbury - Australia (HIG)",
    value: "HIG",
  },
  {
    label: "Perth - Australia (PER)",
    value: "PER",
  },
  {
    label: "Perth, Perth - Australia (PER)",
    value: "PER",
  },
  {
    label: "Perth, Jandakot - Australia (JAD)",
    value: "JAD",
  },
  {
    label: "Jurien Bay - Australia (JUR)",
    value: "JUR",
  },
  {
    label: "Jurien Bay, Jurien Bay - Australia (JUR)",
    value: "JUR",
  },
  {
    label: "Kalumburu - Australia (UBU)",
    value: "UBU",
  },
  {
    label: "Kalumburu, Kalumburu - Australia (UBU)",
    value: "UBU",
  },
  {
    label: "Kalbarri - Australia (KAX)",
    value: "KAX",
  },
  {
    label: "Kalbarri, Kalbarri - Australia (KAX)",
    value: "KAX",
  },
  {
    label: "Kalgoorlie Boulder - Australia (KGI)",
    value: "KGI",
  },
  {
    label: "Kalgoorlie Boulder, Kalgoorlie Boulder - Australia (KGI)",
    value: "KGI",
  },
  {
    label: "Kambalda - Australia (KDB)",
    value: "KDB",
  },
  {
    label: "Kambalda, Kambalda - Australia (KDB)",
    value: "KDB",
  },
  {
    label: "Kararra - Australia (KQR)",
    value: "KQR",
  },
  {
    label: "Kararra, Kararra - Australia (KQR)",
    value: "KQR",
  },
  {
    label: "Solomon - Australia (SLJ)",
    value: "SLJ",
  },
  {
    label: "Solomon, Solomon - Australia (SLJ)",
    value: "SLJ",
  },
  {
    label: "Karratha - Australia (KTA)",
    value: "KTA",
  },
  {
    label: "Karratha, Karratha - Australia (KTA)",
    value: "KTA",
  },
  {
    label: "Katanning - Australia (KNI)",
    value: "KNI",
  },
  {
    label: "Katanning, Katanning - Australia (KNI)",
    value: "KNI",
  },
  {
    label: "Kimberley Downs Station - Australia (KBD)",
    value: "KBD",
  },
  {
    label: "Kimberley Downs Station, Kimberley Downs Station - Australia (KBD)",
    value: "KBD",
  },
  {
    label: "Kununurra - Australia (KNX)",
    value: "KNX",
  },
  {
    label: "Kununurra, Kununurra - Australia (KNX)",
    value: "KNX",
  },
  {
    label: "Lake Gregory - Australia (LGE)",
    value: "LGE",
  },
  {
    label: "Lake Gregory, Mulan - Australia (LGE)",
    value: "LGE",
  },
  {
    label: "Laverton - Australia (LVO)",
    value: "LVO",
  },
  {
    label: "Laverton, Laverton - Australia (LVO)",
    value: "LVO",
  },
  {
    label: "Leinster - Australia (LER)",
    value: "LER",
  },
  {
    label: "Leinster, Leinster - Australia (LER)",
    value: "LER",
  },
  {
    label: "Leonora - Australia (LNO)",
    value: "LNO",
  },
  {
    label: "Leonora, Leonora - Australia (LNO)",
    value: "LNO",
  },
  {
    label: "Mandora Station - Australia (MQA)",
    value: "MQA",
  },
  {
    label: "Mandora Station, Mandora Station - Australia (MQA)",
    value: "MQA",
  },
  {
    label: "Manjimup - Australia (MJP)",
    value: "MJP",
  },
  {
    label: "Manjimup, Manjimup - Australia (MJP)",
    value: "MJP",
  },
  {
    label: "Marble Bar - Australia (MBB)",
    value: "MBB",
  },
  {
    label: "Marble Bar, Marble Bar - Australia (MBB)",
    value: "MBB",
  },
  {
    label: "Margaret River - Australia (MQZ)",
    value: "MQZ",
  },
  {
    label: "Margaret River, Margaret River - Australia (MQZ)",
    value: "MQZ",
  },
  {
    label: "Margaret River Station - Australia (MGV)",
    value: "MGV",
  },
  {
    label: "Margaret River Station, Margaret River Station - Australia (MGV)",
    value: "MGV",
  },
  {
    label: "Meekatharra - Australia (MKR)",
    value: "MKR",
  },
  {
    label: "Meekatharra, Meekatharra - Australia (MKR)",
    value: "MKR",
  },
  {
    label: "Billiluna - Australia (BIW)",
    value: "BIW",
  },
  {
    label: "Billiluna, Billiluna - Australia (BIW)",
    value: "BIW",
  },
  {
    label: "Mitchell Plateau - Australia (MIH)",
    value: "MIH",
  },
  {
    label: "Mitchell Plateau, Mitchell Plateau - Australia (MIH)",
    value: "MIH",
  },
  {
    label: "Monkey Mia - Australia (MJK)",
    value: "MJK",
  },
  {
    label: "Monkey Mia, Shark Bay - Australia (MJK)",
    value: "MJK",
  },
  {
    label: "Morawa - Australia (MWB)",
    value: "MWB",
  },
  {
    label: "Morawa, Morawa - Australia (MWB)",
    value: "MWB",
  },
  {
    label: "Mount Barnett - Australia (MBN)",
    value: "MBN",
  },
  {
    label: "Mount Barnett, Mount Barnett - Australia (MBN)",
    value: "MBN",
  },
  {
    label: "Mount House - Australia (MHO)",
    value: "MHO",
  },
  {
    label: "Mount House, Mount House - Australia (MHO)",
    value: "MHO",
  },
  {
    label: "Mount Keith - Australia (WME)",
    value: "WME",
  },
  {
    label: "Mount Keith, Mount Keith - Australia (WME)",
    value: "WME",
  },
  {
    label: "Mount Magnet - Australia (MMG)",
    value: "MMG",
  },
  {
    label: "Mount Magnet, Mount Magnet - Australia (MMG)",
    value: "MMG",
  },
  {
    label: "Mullewa - Australia (MXU)",
    value: "MXU",
  },
  {
    label: "Mullewa, Mullewa - Australia (MXU)",
    value: "MXU",
  },
  {
    label: "Truscott-Mangalalu - Australia (TTX)",
    value: "TTX",
  },
  {
    label: "Truscott-Mangalalu, Truscott-Mangalalu - Australia (TTX)",
    value: "TTX",
  },
  {
    label: "Murrin - Australia (WUI)",
    value: "WUI",
  },
  {
    label: "Murrin, Murrin - Australia (WUI)",
    value: "WUI",
  },
  {
    label: "Myroodah Station - Australia (MYO)",
    value: "MYO",
  },
  {
    label: "Myroodah Station, Myroodah Station - Australia (MYO)",
    value: "MYO",
  },
  {
    label: "Narrogin - Australia (NRG)",
    value: "NRG",
  },
  {
    label: "Narrogin, Narrogin - Australia (NRG)",
    value: "NRG",
  },
  {
    label: "Newman - Australia (ZNE)",
    value: "ZNE",
  },
  {
    label: "Newman, Newman - Australia (ZNE)",
    value: "ZNE",
  },
  {
    label: "Nicholson - Australia (NLS)",
    value: "NLS",
  },
  {
    label: "Nicholson, Nicholson - Australia (NLS)",
    value: "NLS",
  },
  {
    label: "Nifty - Australia (NIF)",
    value: "NIF",
  },
  {
    label: "Nifty, Nifty - Australia (NIF)",
    value: "NIF",
  },
  {
    label: "Noonkanbah - Australia (NKB)",
    value: "NKB",
  },
  {
    label: "Noonkanbah, Noonkanbah - Australia (NKB)",
    value: "NKB",
  },
  {
    label: "Norseman - Australia (NSM)",
    value: "NSM",
  },
  {
    label: "Norseman, Norseman - Australia (NSM)",
    value: "NSM",
  },
  {
    label: "Nullagine - Australia (NLL)",
    value: "NLL",
  },
  {
    label: "Nullagine, Nullagine - Australia (NLL)",
    value: "NLL",
  },
  {
    label: "Onslow - Australia (ONS)",
    value: "ONS",
  },
  {
    label: "Onslow, Onslow - Australia (ONS)",
    value: "ONS",
  },
  {
    label: "Ord River - Australia (ODR)",
    value: "ODR",
  },
  {
    label: "Ord River, Ord River - Australia (ODR)",
    value: "ODR",
  },
  {
    label: "Paraburdoo - Australia (PBO)",
    value: "PBO",
  },
  {
    label: "Paraburdoo, Paraburdoo - Australia (PBO)",
    value: "PBO",
  },
  {
    label: "Pardoo Station - Australia (PRD)",
    value: "PRD",
  },
  {
    label: "Pardoo Station, Pardoo Station - Australia (PRD)",
    value: "PRD",
  },
  {
    label: "Port Hedland - Australia (PHE)",
    value: "PHE",
  },
  {
    label: "Port Hedland, International - Australia (PHE)",
    value: "PHE",
  },
  {
    label: "Bellburn - Australia (BXF)",
    value: "BXF",
  },
  {
    label: "Bellburn, Bellburn - Australia (BXF)",
    value: "BXF",
  },
  {
    label: "Ravensthorpe - Australia (RVT)",
    value: "RVT",
  },
  {
    label: "Ravensthorpe, Ravensthorpe - Australia (RVT)",
    value: "RVT",
  },
  {
    label: "Roebourne - Australia (RBU)",
    value: "RBU",
  },
  {
    label: "Roebourne, Roebourne - Australia (RBU)",
    value: "RBU",
  },
  {
    label: "Rottnest Island - Australia (RTS)",
    value: "RTS",
  },
  {
    label: "Rottnest Island, Rottnest Island - Australia (RTS)",
    value: "RTS",
  },
  {
    label: "Roy Hill - Australia (RHL)",
    value: "RHL",
  },
  {
    label: "Roy Hill, Roy Hill - Australia (RHL)",
    value: "RHL",
  },
  {
    label: "Sandstone - Australia (NDS)",
    value: "NDS",
  },
  {
    label: "Sandstone, Sandstone - Australia (NDS)",
    value: "NDS",
  },
  {
    label: "Shay Gap - Australia (SGP)",
    value: "SGP",
  },
  {
    label: "Shay Gap, Shay Gap - Australia (SGP)",
    value: "SGP",
  },
  {
    label: "Southern Cross - Australia (SQC)",
    value: "SQC",
  },
  {
    label: "Southern Cross, Southern Cross - Australia (SQC)",
    value: "SQC",
  },
  {
    label: "Shaw River - Australia (SWB)",
    value: "SWB",
  },
  {
    label: "Shaw River, Shaw River - Australia (SWB)",
    value: "SWB",
  },
  {
    label: "Springvale - Australia (ZVG)",
    value: "ZVG",
  },
  {
    label: "Springvale, Springvale - Australia (ZVG)",
    value: "ZVG",
  },
  {
    label: "Sturt Creek - Australia (SSK)",
    value: "SSK",
  },
  {
    label: "Sturt Creek, Sturt Creek - Australia (SSK)",
    value: "SSK",
  },
  {
    label: "Tablelands - Australia (TBL)",
    value: "TBL",
  },
  {
    label: "Tablelands, Tableland Homestead - Australia (TBL)",
    value: "TBL",
  },
  {
    label: "Telfer - Australia (TEF)",
    value: "TEF",
  },
  {
    label: "Telfer, Telfer - Australia (TEF)",
    value: "TEF",
  },
  {
    label: "Theda Station - Australia (TDN)",
    value: "TDN",
  },
  {
    label: "Theda Station, Theda Station - Australia (TDN)",
    value: "TDN",
  },
  {
    label: "Tom Price - Australia (TPR)",
    value: "TPR",
  },
  {
    label: "Tom Price, Tom Price - Australia (TPR)",
    value: "TPR",
  },
  {
    label: "Useless Loop - Australia (USL)",
    value: "USL",
  },
  {
    label: "Useless Loop, Useless Loop - Australia (USL)",
    value: "USL",
  },
  {
    label: "Wallal Downs - Australia (WLA)",
    value: "WLA",
  },
  {
    label: "Wallal Downs, Wallal Downs - Australia (WLA)",
    value: "WLA",
  },
  {
    label: "Turkey Creek - Australia (TKY)",
    value: "TKY",
  },
  {
    label: "Turkey Creek, Turkey Creek - Australia (TKY)",
    value: "TKY",
  },
  {
    label: "Warrawagine Station - Australia (WRW)",
    value: "WRW",
  },
  {
    label: "Warrawagine Station, Warrawagine Station - Australia (WRW)",
    value: "WRW",
  },
  {
    label: "West Angelas - Australia (WLP)",
    value: "WLP",
  },
  {
    label: "West Angelas, West Angelas - Australia (WLP)",
    value: "WLP",
  },
  {
    label: "Wiluna - Australia (WUN)",
    value: "WUN",
  },
  {
    label: "Wiluna, Wiluna - Australia (WUN)",
    value: "WUN",
  },
  {
    label: "Wittenoom - Australia (WIT)",
    value: "WIT",
  },
  {
    label: "Wittenoom, Wittenoom - Australia (WIT)",
    value: "WIT",
  },
  {
    label: "Windarra - Australia (WND)",
    value: "WND",
  },
  {
    label: "Windarra, Windarra - Australia (WND)",
    value: "WND",
  },
  {
    label: "Woodie Woodie - Australia (WWI)",
    value: "WWI",
  },
  {
    label: "Woodie Woodie, Woodie Woodie - Australia (WWI)",
    value: "WWI",
  },
  {
    label: "Wyndham - Australia (WYN)",
    value: "WYN",
  },
  {
    label: "Wyndham, Wyndham - Australia (WYN)",
    value: "WYN",
  },
  {
    label: "Yalgoo - Australia (YLG)",
    value: "YLG",
  },
  {
    label: "Yalgoo, Yalgoo - Australia (YLG)",
    value: "YLG",
  },
  {
    label: "Yandicoogina - Australia (YNN)",
    value: "YNN",
  },
  {
    label: "Yandicoogina, Yandicoogina - Australia (YNN)",
    value: "YNN",
  },
  {
    label: "Yeelirrie - Australia (KYF)",
    value: "KYF",
  },
  {
    label: "Yeelirrie, Yeelirrie - Australia (KYF)",
    value: "KYF",
  },
  {
    label: "Christmas Island - Australia (XCH)",
    value: "XCH",
  },
  {
    label: "Christmas Island, Christmas Island - Australia (XCH)",
    value: "XCH",
  },
  {
    label: "Cocos Islands - Australia (CCK)",
    value: "CCK",
  },
  {
    label: "Cocos Islands, Cocos Islands - Australia (CCK)",
    value: "CCK",
  },
  {
    label: "Norfolk Island - Australia (NLK)",
    value: "NLK",
  },
  {
    label: "Norfolk Island, Norfolk Island - Australia (NLK)",
    value: "NLK",
  },
  {
    label: "Katherine - Australia (KTR)",
    value: "KTR",
  },
  {
    label: "Katherine, Raaf Tindal - Australia (KTR)",
    value: "KTR",
  },
  {
    label: "Richmond - Australia (XRH)",
    value: "XRH",
  },
  {
    label: "Richmond, Raaf Base - Australia (XRH)",
    value: "XRH",
  },
  {
    label: "Woomera - Australia (UMR)",
    value: "UMR",
  },
  {
    label: "Woomera, Raaf Base - Australia (UMR)",
    value: "UMR",
  },
  {
    label: "Oakey - Australia (OKY)",
    value: "OKY",
  },
  {
    label: "Oakey, Army Aviation Centre - Australia (OKY)",
    value: "OKY",
  },
  {
    label: "Nowra - Australia (NOA)",
    value: "NOA",
  },
  {
    label: "Nowra, Nowra - Australia (NOA)",
    value: "NOA",
  },
  {
    label: "Canberra - Australia (CBR)",
    value: "CBR",
  },
  {
    label: "Canberra, Canberra - Australia (CBR)",
    value: "CBR",
  },
  {
    label: "Casino - Australia (CSI)",
    value: "CSI",
  },
  {
    label: "Casino, Casino - Australia (CSI)",
    value: "CSI",
  },
  {
    label: "Cattle Creek - Australia (CTR)",
    value: "CTR",
  },
  {
    label: "Cattle Creek, Cattle Creek - Australia (CTR)",
    value: "CTR",
  },
  {
    label: "Cessnock - Australia (CES)",
    value: "CES",
  },
  {
    label: "Cessnock, Cessnock - Australia (CES)",
    value: "CES",
  },
  {
    label: "Eden - Australia (QDN)",
    value: "QDN",
  },
  {
    label: "Eden, Eden - Australia (QDN)",
    value: "QDN",
  },
  {
    label: "Exmouth - Australia (EXM)",
    value: "EXM",
  },
  {
    label: "Exmouth, Exmouth - Australia (EXM)",
    value: "EXM",
  },
  {
    label: "Geelong - Australia (GEX)",
    value: "GEX",
  },
  {
    label: "Geelong, Geelong - Australia (GEX)",
    value: "GEX",
  },
  {
    label: "Gosford - Australia (GOS)",
    value: "GOS",
  },
  {
    label: "Gosford, Gosford - Australia (GOS)",
    value: "GOS",
  },
  {
    label: "Grenfell - Australia (GFE)",
    value: "GFE",
  },
  {
    label: "Grenfell, Grenfell - Australia (GFE)",
    value: "GFE",
  },
  {
    label: "Macksville - Australia (MVH)",
    value: "MVH",
  },
  {
    label: "Macksville, Macksville - Australia (MVH)",
    value: "MVH",
  },
  {
    label: "Mcarthur River Mine - Australia (MCV)",
    value: "MCV",
  },
  {
    label: "Mcarthur River Mine, Mcarthur River Mine - Australia (MCV)",
    value: "MCV",
  },
  {
    label: "Miranda Downs - Australia (MWY)",
    value: "MWY",
  },
  {
    label: "Miranda Downs, Miranda Downs - Australia (MWY)",
    value: "MWY",
  },
  {
    label: "Muccan Station - Australia (MUQ)",
    value: "MUQ",
  },
  {
    label: "Muccan Station, Muccan Station - Australia (MUQ)",
    value: "MUQ",
  },
  {
    label: "Naracoorte - Australia (NAC)",
    value: "NAC",
  },
  {
    label: "Naracoorte, Naracoorte - Australia (NAC)",
    value: "NAC",
  },
  {
    label: "Nullarbor - Australia (NUR)",
    value: "NUR",
  },
  {
    label: "Nullarbor, Nullarbor Motel - Australia (NUR)",
    value: "NUR",
  },
  {
    label: "Rockhampton Downs - Australia (RDA)",
    value: "RDA",
  },
  {
    label: "Rockhampton Downs, Rockhampton Downs - Australia (RDA)",
    value: "RDA",
  },
  {
    label: "Singleton - Australia (SIX)",
    value: "SIX",
  },
  {
    label: "Singleton, Singleton - Australia (SIX)",
    value: "SIX",
  },
  {
    label: "Warraber Island - Australia (SYU)",
    value: "SYU",
  },
  {
    label: "Warraber Island, Warraber Island - Australia (SYU)",
    value: "SYU",
  },
  {
    label: "Waterloo - Australia (WLO)",
    value: "WLO",
  },
  {
    label: "Waterloo, Waterloo - Australia (WLO)",
    value: "WLO",
  },
  {
    label: "Wauchope - Australia (WAU)",
    value: "WAU",
  },
  {
    label: "Wauchope, Wauchope - Australia (WAU)",
    value: "WAU",
  },
  {
    label: "Wee Waa - Australia (WEW)",
    value: "WEW",
  },
  {
    label: "Wee Waa, Wee Waa - Australia (WEW)",
    value: "WEW",
  },
  {
    label: "Windarling - Australia (WRN)",
    value: "WRN",
  },
  {
    label: "Windarling, Windarling - Australia (WRN)",
    value: "WRN",
  },
  {
    label: "Woodgreen - Australia (WOG)",
    value: "WOG",
  },
  {
    label: "Woodgreen, Woodgreen - Australia (WOG)",
    value: "WOG",
  },
  {
    label: "Graz - Austria (GRZ)",
    value: "GRZ",
  },
  {
    label: "Graz, Graz Airport - Austria (GRZ)",
    value: "GRZ",
  },
  {
    label: "Innsbruck - Austria (INN)",
    value: "INN",
  },
  {
    label: "Innsbruck, Innsbruck Airport - Austria (INN)",
    value: "INN",
  },
  {
    label: "Klagenfurt - Austria (KLU)",
    value: "KLU",
  },
  {
    label: "Klagenfurt, Klagenfurt - Austria (KLU)",
    value: "KLU",
  },
  {
    label: "Linz - Austria (LNZ)",
    value: "LNZ",
  },
  {
    label: "Linz, Blue Danube - Austria (LNZ)",
    value: "LNZ",
  },
  {
    label: "Salzburg - Austria (SZG)",
    value: "SZG",
  },
  {
    label: "Salzburg, Wolfgang A Mozart - Austria (SZG)",
    value: "SZG",
  },
  {
    label: "Vienna - Austria (VIE)",
    value: "VIE",
  },
  {
    label: "Vienna, Vienna International - Austria (VIE)",
    value: "VIE",
  },
  {
    label: "Hohenems Dornbirn - Austria (HOH)",
    value: "HOH",
  },
  {
    label: "Hohenems Dornbirn, Airport - Austria (HOH)",
    value: "HOH",
  },
  {
    label: "Sightseeing Flight - Austria (FQQ)",
    value: "FQQ",
  },
  {
    label: "Sightseeing Flight, Sightseeing Flight - Austria (FQQ)",
    value: "FQQ",
  },
  {
    label: "Baku - Azerbaijan (BAK)",
    value: "BAK",
  },
  {
    label: "Baku, Heydar Aliyev Intl - Azerbaijan (GYD)",
    value: "GYD",
  },
  {
    label: "Baku, Zabrat Airport - Azerbaijan (ZXT)",
    value: "ZXT",
  },
  {
    label: "Gabala - Azerbaijan (GBB)",
    value: "GBB",
  },
  {
    label: "Gabala, International - Azerbaijan (GBB)",
    value: "GBB",
  },
  {
    label: "Ganja - Azerbaijan (KVD)",
    value: "KVD",
  },
  {
    label: "Ganja, Ganja - Azerbaijan (KVD)",
    value: "KVD",
  },
  {
    label: "Lankaran - Azerbaijan (LLK)",
    value: "LLK",
  },
  {
    label: "Lankaran, Lankaran - Azerbaijan (LLK)",
    value: "LLK",
  },
  {
    label: "Nakchivan - Azerbaijan (NAJ)",
    value: "NAJ",
  },
  {
    label: "Nakchivan, Nakchivan - Azerbaijan (NAJ)",
    value: "NAJ",
  },
  {
    label: "Yevlakh - Azerbaijan (YLV)",
    value: "YLV",
  },
  {
    label: "Yevlakh, Yevlakh - Azerbaijan (YLV)",
    value: "YLV",
  },
  {
    label: "Zaqatala - Azerbaijan (ZTU)",
    value: "ZTU",
  },
  {
    label: "Zaqatala, Zaqatala - Azerbaijan (ZTU)",
    value: "ZTU",
  },
  {
    label: "Andros Town, International - Bahamas (ASD)",
    value: "ASD",
  },
  {
    label: "Arthur's Town, Arthur's Town - Bahamas (ATC)",
    value: "ATC",
  },
  {
    label: "Bimini, North Seaplane Base - Bahamas (NSB)",
    value: "NSB",
  },
  {
    label: "Bimini, South Bimini - Bahamas (BIM)",
    value: "BIM",
  },
  {
    label: "Cape Eleuthera, Cape Eleuthera - Bahamas (CEL)",
    value: "CEL",
  },
  {
    label: "Cat Cays, Cat Cays - Bahamas (CXY)",
    value: "CXY",
  },
  {
    label: "Chub Cay, International - Bahamas (CCZ)",
    value: "CCZ",
  },
  {
    label: "Congo Town, Congo Town - Bahamas (COX)",
    value: "COX",
  },
  {
    label: "Crooked Island, Colonel Hill - Bahamas (CRI)",
    value: "CRI",
  },
  {
    label: "Deadmans Cay, Deadmans Cay - Bahamas (LGI)",
    value: "LGI",
  },
  {
    label: "Duncan Town, Duncan Town - Bahamas (DCT)",
    value: "DCT",
  },
  {
    label: "Freeport, Grand Bahama Intl - Bahamas (FPO)",
    value: "FPO",
  },
  {
    label: "George Town, Exuma Int - Bahamas (GGT)",
    value: "GGT",
  },
  {
    label: "Governors Harbour, Governor's Harbour - Bahamas (GHB)",
    value: "GHB",
  },
  {
    label: "Grand Bahama, Aux Ab - Bahamas (GBI)",
    value: "GBI",
  },
  {
    label: "Great Harbour, Great Harbour - Bahamas (GHC)",
    value: "GHC",
  },
  {
    label: "Green Turtle, Green Turtle - Bahamas (GTC)",
    value: "GTC",
  },
  {
    label: "Harbour Island, Harbour Island - Bahamas (HBI)",
    value: "HBI",
  },
  {
    label: "Inagua, Matthew Town - Bahamas (IGA)",
    value: "IGA",
  },
  {
    label: "Mangrove Cay, Clarence A.Bain - Bahamas (MAY)",
    value: "MAY",
  },
  {
    label: "Marsh Harbour, Marsh Harbour - Bahamas (MHH)",
    value: "MHH",
  },
  {
    label: "Mastic Point, Mastic Point - Bahamas (MSK)",
    value: "MSK",
  },
  {
    label: "Mayaguana, Mayaguana - Bahamas (MYG)",
    value: "MYG",
  },
  {
    label: "Nassau, Lynden Pindling Intl - Bahamas (NAS)",
    value: "NAS",
  },
  {
    label: "Nassau, Paradise Island - Bahamas (PID)",
    value: "PID",
  },
  {
    label: "Nassau, Seaplane Base - Bahamas (WZY)",
    value: "WZY",
  },
  {
    label: "Norman's Cay, Norman's Cay - Bahamas (NMC)",
    value: "NMC",
  },
  {
    label: "North Eleuthera, North Eleuthera - Bahamas (ELH)",
    value: "ELH",
  },
  {
    label: "Pitts Town, Pitts Town - Bahamas (PWN)",
    value: "PWN",
  },
  {
    label: "Powell Point, Powell Point - Bahamas (PPO)",
    value: "PPO",
  },
  {
    label: "Rock Sound, International - Bahamas (RSD)",
    value: "RSD",
  },
  {
    label: "Rum Cay, Port Nelson - Bahamas (RCY)",
    value: "RCY",
  },
  {
    label: "San Andros, San Andros - Bahamas (SAQ)",
    value: "SAQ",
  },
  {
    label: "San Salvador, Cockburn Town - Bahamas (ZSA)",
    value: "ZSA",
  },
  {
    label: "South Andros, South Andros - Bahamas (TZN)",
    value: "TZN",
  },
  {
    label: "Spanish Wells, Spanish Wells - Bahamas (SWL)",
    value: "SWL",
  },
  {
    label: "Spring Point, Spring Point - Bahamas (AXP)",
    value: "AXP",
  },
  {
    label: "Staniel Cay, Staniel Cay - Bahamas (TYM)",
    value: "TYM",
  },
  {
    label: "Stella Maris, Stella Maris - Bahamas (SML)",
    value: "SML",
  },
  {
    label: "The Bight, New Bight - Bahamas (TBI)",
    value: "TBI",
  },
  {
    label: "Treasure Cay, Treasure Cay - Bahamas (TCB)",
    value: "TCB",
  },
  {
    label: "Walker's Cay, Walker's Cay - Bahamas (WKR)",
    value: "WKR",
  },
  {
    label: "West End, West End - Bahamas (WTD)",
    value: "WTD",
  },
  {
    label: "Bahrain - Bahrain (BAH)",
    value: "BAH",
  },
  {
    label: "Bahrain, Bahrain Intl - Bahrain (BAH)",
    value: "BAH",
  },
  {
    label: "Muharraq - Bahrain (GBQ)",
    value: "GBQ",
  },
  {
    label: "Muharraq, Muharraq - Bahrain (GBQ)",
    value: "GBQ",
  },
  {
    label: "Port Keats, Port Keats - Australia (PKT)",
    value: "PKT",
  },
  {
    label: "Port Keats, Port Keats - Australia (PKT)",
    value: "PKT",
  },
  {
    label: "Barisal, Barisal - Bangladesh (BZL)",
    value: "BZL",
  },
  {
    label: "Chittagong, Shah Amanat Intl - Bangladesh (CGP)",
    value: "CGP",
  },
  {
    label: "Comilla, Comilla - Bangladesh (CLA)",
    value: "CLA",
  },
  {
    label: "Cox's Bazar, Cox's Bazar - Bangladesh (CXB)",
    value: "CXB",
  },
  {
    label: "Dhaka, Hazrat Shahjalal Intl - Bangladesh (DAC)",
    value: "DAC",
  },
  {
    label: "Ishurdi, Ishurdi - Bangladesh (IRD)",
    value: "IRD",
  },
  {
    label: "Jessore, Jessore - Bangladesh (JSR)",
    value: "JSR",
  },
  {
    label: "Rajshahi, Shah Makhdum - Bangladesh (RJH)",
    value: "RJH",
  },
  {
    label: "Saidpur, Saidpur - Bangladesh (SPD)",
    value: "SPD",
  },
  {
    label: "Shamshernagar, Shamshernagar - Bangladesh (ZHM)",
    value: "ZHM",
  },
  {
    label: "Sylhet, Osmani Intl - Bangladesh (ZYL)",
    value: "ZYL",
  },
  {
    label: "Thakurgaon, Thakurgaon - Bangladesh (TKR)",
    value: "TKR",
  },
  {
    label: "Bridgetown, Grantley Adams - Barbados (BGI)",
    value: "BGI",
  },
  {
    label: "Brest - Belarus (BQT)",
    value: "BQT",
  },
  {
    label: "Brest, Brest - Belarus (BQT)",
    value: "BQT",
  },
  {
    label: "Gomel - Belarus (GME)",
    value: "GME",
  },
  {
    label: "Gomel, Gomel - Belarus (GME)",
    value: "GME",
  },
  {
    label: "Grodno - Belarus (GNA)",
    value: "GNA",
  },
  {
    label: "Grodno, Grodno - Belarus (GNA)",
    value: "GNA",
  },
  {
    label: "Minsk - Belarus (MSQ)",
    value: "MSQ",
  },
  {
    label: "Minsk, Minsk 2 International - Belarus (MSQ)",
    value: "MSQ",
  },
  {
    label: "Minsk, Minsk 1 - Belarus (MHP)",
    value: "MHP",
  },
  {
    label: "Mogilev - Belarus (MVQ)",
    value: "MVQ",
  },
  {
    label: "Mogilev, Mogilev - Belarus (MVQ)",
    value: "MVQ",
  },
  {
    label: "Vitebsk - Belarus (VTB)",
    value: "VTB",
  },
  {
    label: "Vitebsk, Vitebsk - Belarus (VTB)",
    value: "VTB",
  },
  {
    label: "Brussels - Belgium (BRU)",
    value: "BRU",
  },
  {
    label: "Brussels, Brussels Airport - Belgium (BRU)",
    value: "BRU",
  },
  {
    label: "Brussels, Brussels S Charleroi - Belgium (CRL)",
    value: "CRL",
  },
  {
    label: "Brussels, Antwerp, International - Belgium (ANR)",
    value: "ANR",
  },
  {
    label: "Kortrijk - Belgium (KJK)",
    value: "KJK",
  },
  {
    label: "Kortrijk, Wevelgem - Belgium (KJK)",
    value: "KJK",
  },
  {
    label: "Liege - Belgium (LGG)",
    value: "LGG",
  },
  {
    label: "Liege, Airport - Belgium (LGG)",
    value: "LGG",
  },
  {
    label: "Oostende Brugge - Belgium (OST)",
    value: "OST",
  },
  {
    label: "Oostende Brugge, Airport - Belgium (OST)",
    value: "OST",
  },
  {
    label: "Zoersel - Belgium (OBL)",
    value: "OBL",
  },
  {
    label: "Zoersel, Oostmalle - Belgium (OBL)",
    value: "OBL",
  },
  {
    label: "Belize City, Belize City Mnpl - Belize (TZA)",
    value: "TZA",
  },
  {
    label: "Belize City, P.S.W. Goldson Intl - Belize (BZE)",
    value: "BZE",
  },
  {
    label: "Belmopan, Hector Silva - Belize (BCV)",
    value: "BCV",
  },
  {
    label: "Big Creek, Big Creek - Belize (BGK)",
    value: "BGK",
  },
  {
    label: "Caye Caulker, Caye Caulker - Belize (CUK)",
    value: "CUK",
  },
  {
    label: "Caye Chapel, Caye Chapel - Belize (CYC)",
    value: "CYC",
  },
  {
    label: "Corozal, Corozal - Belize (CZH)",
    value: "CZH",
  },
  {
    label: "Dangriga, Dangriga - Belize (DGA)",
    value: "DGA",
  },
  {
    label: "Independence, Independence - Belize (INB)",
    value: "INB",
  },
  {
    label: "Manatee, Manatee - Belize (MZE)",
    value: "MZE",
  },
  {
    label: "Melinda, Melinda - Belize (MDB)",
    value: "MDB",
  },
  {
    label: "Orange Walk, Orange Walk - Belize (ORZ)",
    value: "ORZ",
  },
  {
    label: "Placencia, Placencia - Belize (PLJ)",
    value: "PLJ",
  },
  {
    label: "Punta Gorda, Punta Gorda - Belize (PND)",
    value: "PND",
  },
  {
    label: "San Ignacia, Matthew Spain - Belize (SQS)",
    value: "SQS",
  },
  {
    label: "San Ignacio , Town Airstip - Belize (CYD)",
    value: "CYD",
  },
  {
    label: "San Pedro, San Pedro - Belize (SPR)",
    value: "SPR",
  },
  {
    label: "Santa Cruz, Santa Cruz - Belize (STU)",
    value: "STU",
  },
  {
    label: "Sartaneja, Sarteneja - Belize (SJX)",
    value: "SJX",
  },
  {
    label: "Silver Creek, Silver Creek - Belize (SVK)",
    value: "SVK",
  },
  {
    label: "Cotonou, Cadjehoun - Benin (COO)",
    value: "COO",
  },
  {
    label: "Djougou, Djougou - Benin (DJA)",
    value: "DJA",
  },
  {
    label: "Kandi, Kandi - Benin (KDC)",
    value: "KDC",
  },
  {
    label: "Natitingou, Natitingou - Benin (NAE)",
    value: "NAE",
  },
  {
    label: "Parakou, Parakou - Benin (PKO)",
    value: "PKO",
  },
  {
    label: "Save, Save - Benin (SVF)",
    value: "SVF",
  },
  {
    label: "Bermuda, L.F Wade International - Bermuda (BDA)",
    value: "BDA",
  },
  {
    label: "Bermuda, Nas - Bermuda (NWU)",
    value: "NWU",
  },
  {
    label: "Gelephu, Gelephu - Bhutan (GLU)",
    value: "GLU",
  },
  {
    label: "Jakar, Bathpalathang - Bhutan (BUT)",
    value: "BUT",
  },
  {
    label: "Paro, International - Bhutan (PBH)",
    value: "PBH",
  },
  {
    label: "Trashigang, Yonphula - Bhutan (YON)",
    value: "YON",
  },
  {
    label: "Apolo, Apolo - Bolivia (APB)",
    value: "APB",
  },
  {
    label: "Ascension, Ascension - Bolivia (ASC)",
    value: "ASC",
  },
  {
    label: "Baures, Baures - Bolivia (BVL)",
    value: "BVL",
  },
  {
    label: "Bermejo, Bermejo - Bolivia (BJO)",
    value: "BJO",
  },
  {
    label: "Camiri, Camiri - Bolivia (CAM)",
    value: "CAM",
  },
  {
    label: "Cobija, Captain Anibal Arab - Bolivia (CIJ)",
    value: "CIJ",
  },
  {
    label: "Cochabamba, Jorge Wilstermann Intl - Bolivia (CBB)",
    value: "CBB",
  },
  {
    label: "Concepcion, Concepcion - Bolivia (CEP)",
    value: "CEP",
  },
  {
    label: "Guayaramerin, Guayaramerin - Bolivia (GYA)",
    value: "GYA",
  },
  {
    label: "Huacaraje, Huacaraje - Bolivia (BVK)",
    value: "BVK",
  },
  {
    label: "La Paz, El Alto Intl - Bolivia (LPB)",
    value: "LPB",
  },
  {
    label: "Magdalena, Magdalena - Bolivia (MGD)",
    value: "MGD",
  },
  {
    label: "Monteagudo, Monteagudo - Bolivia (MHW)",
    value: "MHW",
  },
  {
    label: "Oruro, Juan Mendoza - Bolivia (ORU)",
    value: "ORU",
  },
  {
    label: "Potosi, Capitan Nicolas Rojas - Bolivia (POI)",
    value: "POI",
  },
  {
    label: "Puerto Rico, Puerto Rico - Bolivia (PUR)",
    value: "PUR",
  },
  {
    label: "Puerto Suarez, International - Bolivia (PSZ)",
    value: "PSZ",
  },
  {
    label: "Reyes, Reyes - Bolivia (REY)",
    value: "REY",
  },
  {
    label: "Riberalta, Riberalta - Bolivia (RIB)",
    value: "RIB",
  },
  {
    label: "Robore, Robore - Bolivia (RBO)",
    value: "RBO",
  },
  {
    label: "Rurrenabaque, Rurrenabaque - Bolivia (RBQ)",
    value: "RBQ",
  },
  {
    label: "San Borja, German Quiroga Guardia - Bolivia (SRJ)",
    value: "SRJ",
  },
  {
    label: "San Ignacio De M, San Ignacio De Moxos - Bolivia (SNM)",
    value: "SNM",
  },
  {
    label: "San Ignacio De Ve, Cap Juan Cochamanidis - Bolivia (SNG)",
    value: "SNG",
  },
  {
    label: "San Javier, San Javier - Bolivia (SJV)",
    value: "SJV",
  },
  {
    label: "San Joaquin, San Joaquin - Bolivia (SJB)",
    value: "SJB",
  },
  {
    label: "San Jose, San Jose De Chiquitos - Bolivia (SJS)",
    value: "SJS",
  },
  {
    label: "San Matias, San Matias - Bolivia (MQK)",
    value: "MQK",
  },
  {
    label: "San Ramon, San Ramon - Bolivia (SRD)",
    value: "SRD",
  },
  {
    label: "Santa Ana, Santa Ana Del Yacuma - Bolivia (SBL)",
    value: "SBL",
  },
  {
    label: "Santa Cruz, El Trompillo - Bolivia (SRZ)",
    value: "SRZ",
  },
  {
    label: "Santa Cruz, Viru Viru Intl - Bolivia (VVI)",
    value: "VVI",
  },
  {
    label: "Santa Rosa, Santa Rosa - Bolivia (SRB)",
    value: "SRB",
  },
  {
    label: "Sucre, Alcantari - Bolivia (SRE)",
    value: "SRE",
  },
  {
    label: "Tarija, Cap Oriel Lea Plaza - Bolivia (TJA)",
    value: "TJA",
  },
  {
    label: "Trinidad, Jorge Henrich Arauz - Bolivia (TDD)",
    value: "TDD",
  },
  {
    label: "Uyuni, Uyuni - Bolivia (UYU)",
    value: "UYU",
  },
  {
    label: "Vallegrande, V Villagomez Toledo - Bolivia (VAH)",
    value: "VAH",
  },
  {
    label: "Villamontes, Rafael Pabon - Bolivia (VLM)",
    value: "VLM",
  },
  {
    label: "Yacuiba, Yacuiba - Bolivia (BYC)",
    value: "BYC",
  },
  {
    label: "Bonaire, Flamingo Intl - Bonaire St Eustatius And Saba  (BON)",
    value: "BON",
  },
  {
    label:
      "Saba Island, Juancho E.Yrausquin - Bonaire St Eustatius And Saba  (SAB)",
    value: "SAB",
  },
  {
    label:
      "Saint Eustatius, F.D.Roosevelt - Bonaire St Eustatius And Saba  (EUX)",
    value: "EUX",
  },
  {
    label: "Banja Luka - Bosnia-Herzegovina (BNX)",
    value: "BNX",
  },
  {
    label: "Banja Luka, International - Bosnia-Herzegovina (BNX)",
    value: "BNX",
  },
  {
    label: "Mostar - Bosnia-Herzegovina (OMO)",
    value: "OMO",
  },
  {
    label: "Mostar, International - Bosnia-Herzegovina (OMO)",
    value: "OMO",
  },
  {
    label: "Sarajevo - Bosnia-Herzegovina (SJJ)",
    value: "SJJ",
  },
  {
    label: "Sarajevo, Sarajevo International - Bosnia-Herzegovina (SJJ)",
    value: "SJJ",
  },
  {
    label: "Tuzla - Bosnia-Herzegovina (TZL)",
    value: "TZL",
  },
  {
    label: "Tuzla, International - Bosnia-Herzegovina (TZL)",
    value: "TZL",
  },
  {
    label: "Francistown, Francistown - Botswana (FRW)",
    value: "FRW",
  },
  {
    label: "Gaborone, Sir Seretse Khama Intl - Botswana (GBE)",
    value: "GBE",
  },
  {
    label: "Ghanzi, Ghanzi - Botswana (GNZ)",
    value: "GNZ",
  },
  {
    label: "Hukuntsi, Hukuntsi - Botswana (HUK)",
    value: "HUK",
  },
  {
    label: "Jwaneng, Jwaneng - Botswana (JWA)",
    value: "JWA",
  },
  {
    label: "Kasane, Kasane - Botswana (BBK)",
    value: "BBK",
  },
  {
    label: "Khwai River Lodge, Khwai River Lodge - Botswana (KHW)",
    value: "KHW",
  },
  {
    label: "Lobatse, Lobatse - Botswana (LOQ)",
    value: "LOQ",
  },
  {
    label: "Maun, Maun - Botswana (MUB)",
    value: "MUB",
  },
  {
    label: "Orapa, Orapa - Botswana (ORP)",
    value: "ORP",
  },
  {
    label: "Savuti, Savuti - Botswana (SVT)",
    value: "SVT",
  },
  {
    label: "Selebi Phikwe, Selebi Phikwe - Botswana (PKW)",
    value: "PKW",
  },
  {
    label: "Shakawe, Shakawe - Botswana (SWX)",
    value: "SWX",
  },
  {
    label: "Sua Pan, Sua Pan - Botswana (SXN)",
    value: "SXN",
  },
  {
    label: "Tshabong, Tshabong - Botswana (TBY)",
    value: "TBY",
  },
  {
    label: "Tuli Lodge, Limpopo Valley - Botswana (TLD)",
    value: "TLD",
  },
  {
    label: "Alegrete, G.Machado Ramos - Brazil (ALQ)",
    value: "ALQ",
  },
  {
    label: "Alenquer, Alenquer - Brazil (ALT)",
    value: "ALT",
  },
  {
    label: "Almenara, Cirilo Queiroz - Brazil (AMJ)",
    value: "AMJ",
  },
  {
    label: "Alta Floresta, Oswaldo Marques Dias - Brazil (AFL)",
    value: "AFL",
  },
  {
    label: "Altamira, Altamira - Brazil (ATM)",
    value: "ATM",
  },
  {
    label: "Alto Parnaiba, Alto Parnaiba - Brazil (APY)",
    value: "APY",
  },
  {
    label: "Anapolis, Anapolis - Brazil (APS)",
    value: "APS",
  },
  {
    label: "Apucarana, Captain Joao Busse - Brazil (APU)",
    value: "APU",
  },
  {
    label: "Aracaju, Santa Maria - Brazil (AJU)",
    value: "AJU",
  },
  {
    label: "Aracatuba, Dario Guarita - Brazil (ARU)",
    value: "ARU",
  },
  {
    label: "Aragarcas, Aragarcas - Brazil (ARS)",
    value: "ARS",
  },
  {
    label: "Araguaina, Araguaina - Brazil (AUX)",
    value: "AUX",
  },
  {
    label: "Arapiraca, Arapiraca - Brazil (APQ)",
    value: "APQ",
  },
  {
    label: "Arapongas, Alberto Bertelli - Brazil (APX)",
    value: "APX",
  },
  {
    label: "Arapoti, Avelino Vieira - Brazil (AAG)",
    value: "AAG",
  },
  {
    label: "Araraquara, Bartolomeu De Gusmao - Brazil (AQA)",
    value: "AQA",
  },
  {
    label: "Araxa, Araxa - Brazil (AAX)",
    value: "AAX",
  },
  {
    label: "Aripuana, Aripuana - Brazil (AIR)",
    value: "AIR",
  },
  {
    label: "Ariquemes, Ariquemes - Brazil (AQM)",
    value: "AQM",
  },
  {
    label: "Arraias, Arraias - Brazil (AAI)",
    value: "AAI",
  },
  {
    label: "Assis, Marcelo P.Halzhausen - Brazil (AIF)",
    value: "AIF",
  },
  {
    label: "Atibaia, Atibaia - Brazil (QTA)",
    value: "QTA",
  },
  {
    label: "Atibaia, Atibaia - Brazil (ZBW)",
    value: "ZBW",
  },
  {
    label: "Bage, Gustavo Kraemer - Brazil (BGX)",
    value: "BGX",
  },
  {
    label: "Balsas, Balsas - Brazil (BSS)",
    value: "BSS",
  },
  {
    label: "Barbelos, Barcelos - Brazil (BAZ)",
    value: "BAZ",
  },
  {
    label: "Barra, Barra - Brazil (BQQ)",
    value: "BQQ",
  },
  {
    label: "Barra Do Corda, Barra Do Corda - Brazil (BDC)",
    value: "BDC",
  },
  {
    label: "Barra Do Garcas, Barra Do Garcas - Brazil (BPG)",
    value: "BPG",
  },
  {
    label: "Barreiras, Barreiras - Brazil (BRA)",
    value: "BRA",
  },
  {
    label: "Barreirinhas, Barreirinhas - Brazil (BRB)",
    value: "BRB",
  },
  {
    label: "Barretos, Chafei Amsei - Brazil (BAT)",
    value: "BAT",
  },
  {
    label: "Bauru, Moussa Nakhl Tobias - Brazil (JTC)",
    value: "JTC",
  },
  {
    label: "Belem, Val De Cans Intl - Brazil (BEL)",
    value: "BEL",
  },
  {
    label: "Belmonte, Belmonte - Brazil (BVM)",
    value: "BVM",
  },
  {
    label: "Belo Horizonte - Brazil (BHZ)",
    value: "BHZ",
  },
  {
    label: "Belo Horizonte, Tancredo Neves Intl - Brazil (CNF)",
    value: "CNF",
  },
  {
    label: "Belo Horizonte, Pampulha - Brazil (PLU)",
    value: "PLU",
  },
  {
    label: "Bento Goncalves, Bento Goncalves - Brazil (BGV)",
    value: "BGV",
  },
  {
    label: "Blumenau, Blumenau - Brazil (BNU)",
    value: "BNU",
  },
  {
    label: "Boa Vista, A.B. Cantanhede Intl - Brazil (BVB)",
    value: "BVB",
  },
  {
    label: "Boca Do Acre, Novo Campo - Brazil (BCR)",
    value: "BCR",
  },
  {
    label: "Bom Jesus Da Lapa, Bom Jesus Da Lapa - Brazil (LAZ)",
    value: "LAZ",
  },
  {
    label: "Bonito, Bonito - Brazil (BYO)",
    value: "BYO",
  },
  {
    label: "Borba, Borba - Brazil (RBB)",
    value: "RBB",
  },
  {
    label: "Braganca Paulista, Arthur Siqueira - Brazil (BJP)",
    value: "BJP",
  },
  {
    label: "Brasilia - Brazil (BSB)",
    value: "BSB",
  },
  {
    label: "Brasilia, J.Kubitschek Intl - Brazil (BSB)",
    value: "BSB",
  },
  {
    label: "Breves, Breves - Brazil (BVS)",
    value: "BVS",
  },
  {
    label: "Brumado, S.Mariani Bittencourt - Brazil (BMS)",
    value: "BMS",
  },
  {
    label: "Buzios, Umberto Modiano - Brazil (BZC)",
    value: "BZC",
  },
  {
    label: "Cabo Frio, International - Brazil (CFB)",
    value: "CFB",
  },
  {
    label: "Cacador, C.A Da Costa Neves - Brazil (CFC)",
    value: "CFC",
  },
  {
    label: "Caceres, Caceres - Brazil (CCX)",
    value: "CCX",
  },
  {
    label: "Cachoeira, Cachoeira - Brazil (CCQ)",
    value: "CCQ",
  },
  {
    label: "Cachoeiro De Itapemirim, Raimundo De Andrade - Brazil (CDI)",
    value: "CDI",
  },
  {
    label: "Cacoal, Capital Do Cafe - Brazil (OAL)",
    value: "OAL",
  },
  {
    label: "Caldas Novas, Nelson R.Guimaraes - Brazil (CLV)",
    value: "CLV",
  },
  {
    label: "Cameta, Cameta - Brazil (CMT)",
    value: "CMT",
  },
  {
    label: "Camocim, Camocim - Brazil (CMC)",
    value: "CMC",
  },
  {
    label: "Campina Grande, Joao Suas - Brazil (CPV)",
    value: "CPV",
  },
  {
    label: "Campinas, Campinas Intl - Brazil (CPQ)",
    value: "CPQ",
  },
  {
    label: "Campo Grande, Campo Grande Intl - Brazil (CGR)",
    value: "CGR",
  },
  {
    label: "Campo Mourao, G.Guias De Aquino - Brazil (CBW)",
    value: "CBW",
  },
  {
    label: "Campos, Bartolomeu Lisand - Brazil (CAW)",
    value: "CAW",
  },
  {
    label: "Cana Brava, Cana Brava - Brazil (NBV)",
    value: "NBV",
  },
  {
    label: "Canarana, Canarana - Brazil (CQA)",
    value: "CQA",
  },
  {
    label: "Canavieiras, Socrates Rezende - Brazil (CNV)",
    value: "CNV",
  },
  {
    label: "Carajas, Carajas - Brazil (CKS)",
    value: "CKS",
  },
  {
    label: "Carauari, Carauari - Brazil (CAF)",
    value: "CAF",
  },
  {
    label: "Caravelas, Caravelas - Brazil (CRQ)",
    value: "CRQ",
  },
  {
    label: "Carolina, Carolina - Brazil (CLN)",
    value: "CLN",
  },
  {
    label: "Caruaru, Oscar Laranjeiras - Brazil (CAU)",
    value: "CAU",
  },
  {
    label: "Carutapera, Carutapera - Brazil (CTP)",
    value: "CTP",
  },
  {
    label: "Cascavel, A.Mendes Da Silva - Brazil (CAC)",
    value: "CAC",
  },
  {
    label: "Cassilandia, Cassilandia - Brazil (CSS)",
    value: "CSS",
  },
  {
    label: "Catalao, Catalao - Brazil (TLZ)",
    value: "TLZ",
  },
  {
    label: "Caxias Do Sul, Hugo Cantergiani - Brazil (CXJ)",
    value: "CXJ",
  },
  {
    label: "Chapeco, Serafin Enoss Bertaso - Brazil (XAP)",
    value: "XAP",
  },
  {
    label: "Coari, Coari - Brazil (CIZ)",
    value: "CIZ",
  },
  {
    label: "Colorado Do Oeste, Colorado Do Oeste - Brazil (CSW)",
    value: "CSW",
  },
  {
    label: "Conceicao Do Arag, Conceicao Do Araguaia - Brazil (CDJ)",
    value: "CDJ",
  },
  {
    label: "Concordia, Olavo Cecco Rigon - Brazil (CCI)",
    value: "CCI",
  },
  {
    label: "Confreza, Confresa - Brazil (CFO)",
    value: "CFO",
  },
  {
    label: "Cornelio Procopio, Francisco Lacerda Jr - Brazil (CKO)",
    value: "CKO",
  },
  {
    label: "Corumba, Corumba Intl - Brazil (CMG)",
    value: "CMG",
  },
  {
    label: "Costa Marques, Costa Marques - Brazil (CQS)",
    value: "CQS",
  },
  {
    label: "Cotriguacu, Andre Maggi - Brazil (OTT)",
    value: "OTT",
  },
  {
    label: "Criciuma, Diomcio Freitas - Brazil (CCM)",
    value: "CCM",
  },
  {
    label: "Cruz Alta, Carlos Ruhl - Brazil (CZB)",
    value: "CZB",
  },
  {
    label: "Cruzeiro Do Sul, International - Brazil (CZS)",
    value: "CZS",
  },
  {
    label: "Cuiaba, Marechal Rondon Intl - Brazil (CGB)",
    value: "CGB",
  },
  {
    label: "Curitiba - Brazil (CWB)",
    value: "CWB",
  },
  {
    label: "Curitiba, Afonso Pena Intl - Brazil (CWB)",
    value: "CWB",
  },
  {
    label: "Curitiba, Bacacheri - Brazil (BFH)",
    value: "BFH",
  },
  {
    label: "Cururupu, Cururupu - Brazil (CPU)",
    value: "CPU",
  },
  {
    label: "Diamantina, Diamantina - Brazil (DTI)",
    value: "DTI",
  },
  {
    label: "Diamantino, Diamantino - Brazil (DMT)",
    value: "DMT",
  },
  {
    label: "Dianopolis, Dianopolis - Brazil (DNO)",
    value: "DNO",
  },
  {
    label: "Divinopolis, Brigadeiro Cabral - Brazil (DIQ)",
    value: "DIQ",
  },
  {
    label: "Dourados, F.De Matos Pereira - Brazil (DOU)",
    value: "DOU",
  },
  {
    label: "Eirunepe, Amaury Feitosa Tomaz - Brazil (ERN)",
    value: "ERN",
  },
  {
    label: "Erechim, Erechim - Brazil (ERM)",
    value: "ERM",
  },
  {
    label: "Espinosa, Espinosa - Brazil (ESI)",
    value: "ESI",
  },
  {
    label: "Feijo, Feijo - Brazil (FEJ)",
    value: "FEJ",
  },
  {
    label: "Feira De Santana, Joao Durval Carneiro - Brazil (FEC)",
    value: "FEC",
  },
  {
    label: "Fernando De Noron, Fernando De Noron - Brazil (FEN)",
    value: "FEN",
  },
  {
    label: "Floriano, Cangapara - Brazil (FLB)",
    value: "FLB",
  },
  {
    label: "Florianopolis, Hercilio Luz Intl - Brazil (FLN)",
    value: "FLN",
  },
  {
    label: "Fonte Boa, Fonte Boa - Brazil (FBA)",
    value: "FBA",
  },
  {
    label: "Fortaleza - Brazil (FOR)",
    value: "FOR",
  },
  {
    label: "Fortaleza, Pinto Martins Intl - Brazil (FOR)",
    value: "FOR",
  },
  {
    label: "Foz Do Iguacu, Cataratas - Brazil (IGU)",
    value: "IGU",
  },
  {
    label: "Franca, Tenete Lund Presetto - Brazil (FRC)",
    value: "FRC",
  },
  {
    label: "Francisco Beltrao, Paulo Abdala - Brazil (FBE)",
    value: "FBE",
  },
  {
    label: "Goiania, Santa Genoveva - Brazil (GYN)",
    value: "GYN",
  },
  {
    label: "Governador Valadares, A.Machado Oliveira - Brazil (GVR)",
    value: "GVR",
  },
  {
    label: "Gravatai, Gravatai - Brazil (GCV)",
    value: "GCV",
  },
  {
    label: "Guadalupe, Guadalupe - Brazil (GDP)",
    value: "GDP",
  },
  {
    label: "Guajara Mirim, Guajara Mirim - Brazil (GJM)",
    value: "GJM",
  },
  {
    label: "Guanambi, Guanambi - Brazil (GNM)",
    value: "GNM",
  },
  {
    label: "Guarapari, Guarapari - Brazil (GUZ)",
    value: "GUZ",
  },
  {
    label: "Guarapuava, T.Thomas De Faria - Brazil (GPB)",
    value: "GPB",
  },
  {
    label: "Guaratingueta, Guaratingueta - Brazil (GUJ)",
    value: "GUJ",
  },
  {
    label: "Guimaraes, Antonio Guerreiro - Brazil (GMS)",
    value: "GMS",
  },
  {
    label: "Gurupi, Gurupi - Brazil (GRP)",
    value: "GRP",
  },
  {
    label: "Horizontina, Walter Bundchen - Brazil (HRZ)",
    value: "HRZ",
  },
  {
    label: "Humaita, F.Correa Da Cruz - Brazil (HUW)",
    value: "HUW",
  },
  {
    label: "Ijui, J Batista Bos Filho - Brazil (IJU)",
    value: "IJU",
  },
  {
    label: "Ilha Comandatuba/Una, Una - Brazil (UNA)",
    value: "UNA",
  },
  {
    label: "Ilha Solteira, Ilha Solteira - Brazil (ILB)",
    value: "ILB",
  },
  {
    label: "Ilheus, Jorge Amado - Brazil (IOS)",
    value: "IOS",
  },
  {
    label: "Imperatriz, Renato Moreira - Brazil (IMP)",
    value: "IMP",
  },
  {
    label: "Ipatinga, Usiminas - Brazil (IPN)",
    value: "IPN",
  },
  {
    label: "Ipiau, Ipiau - Brazil (IPU)",
    value: "IPU",
  },
  {
    label: "Ipiranga, Ipiranga - Brazil (IPG)",
    value: "IPG",
  },
  {
    label: "Irece, Irece - Brazil (IRE)",
    value: "IRE",
  },
  {
    label: "Itabuna, Itabuna - Brazil (ITN)",
    value: "ITN",
  },
  {
    label: "Itacoatiara, Itacoatiara - Brazil (ITA)",
    value: "ITA",
  },
  {
    label: "Itaituba, Itaituba - Brazil (ITB)",
    value: "ITB",
  },
  {
    label: "Itambacuri, Fazenda Americana - Brazil (ITI)",
    value: "ITI",
  },
  {
    label: "Itaperuna, Itaperuna - Brazil (ITP)",
    value: "ITP",
  },
  {
    label: "Itaqui, Itaqui - Brazil (ITQ)",
    value: "ITQ",
  },
  {
    label: "Itubera, Itubera - Brazil (ITE)",
    value: "ITE",
  },
  {
    label: "Itumbiara, F.Vilela Do Amaral - Brazil (ITR)",
    value: "ITR",
  },
  {
    label: "Jacareacanga, Jacareacanga - Brazil (JCR)",
    value: "JCR",
  },
  {
    label: "Jacobina, Jacobina - Brazil (JCM)",
    value: "JCM",
  },
  {
    label: "Jaguaruna, Humberto Bortoluzzi - Brazil (JJG)",
    value: "JJG",
  },
  {
    label: "Jales, Jales - Brazil (JLS)",
    value: "JLS",
  },
  {
    label: "Januaria, Januaria - Brazil (JNA)",
    value: "JNA",
  },
  {
    label: "Jatai, Jatai - Brazil (JTI)",
    value: "JTI",
  },
  {
    label: "Jequie, Jequie - Brazil (JEQ)",
    value: "JEQ",
  },
  {
    label: "Ji Parana, Jose Coleto - Brazil (JPR)",
    value: "JPR",
  },
  {
    label: "Joacaba, Santa Terezinha - Brazil (JCB)",
    value: "JCB",
  },
  {
    label: "Joao Pessoa, Castro Pinto Intl - Brazil (JPA)",
    value: "JPA",
  },
  {
    label: "Joinville, Lauro Carneiro Loyola - Brazil (JOI)",
    value: "JOI",
  },
  {
    label: "Juara, I.Luiz Do Nascimento - Brazil (JUA)",
    value: "JUA",
  },
  {
    label: "Juazeiro Do Norte, O.Bezerra De Menezes - Brazil (JDO)",
    value: "JDO",
  },
  {
    label: "Juina, Juina - Brazil (JIA)",
    value: "JIA",
  },
  {
    label: "Juiz De Fora, Francisco De Assis - Brazil (JDF)",
    value: "JDF",
  },
  {
    label: "Juiz De Fora, Presidente I.Franco - Brazil (IZA)",
    value: "IZA",
  },
  {
    label: "Juruena, Juruena - Brazil (JRN)",
    value: "JRN",
  },
  {
    label: "Labrea, Labrea - Brazil (LBR)",
    value: "LBR",
  },
  {
    label: "Lages, Correia Pinto - Brazil (LAJ)",
    value: "LAJ",
  },
  {
    label: "Lencois, Horacio De Mattos - Brazil (LEC)",
    value: "LEC",
  },
  {
    label: "Leopoldina, Leopoldina - Brazil (LEP)",
    value: "LEP",
  },
  {
    label: "Lins, Lucas Nogueira Garcez - Brazil (LIP)",
    value: "LIP",
  },
  {
    label: "Livramento, Santana Do Livramento - Brazil (LVB)",
    value: "LVB",
  },
  {
    label: "London - United Kingdom (LON)",
    value: "LON",
  },
  {
    label: "London, Heathrow - United Kingdom (LHR)",
    value: "LHR",
  },
  {
    label: "London, Gatwick - United Kingdom (LGW)",
    value: "LGW",
  },
  {
    label: "London, Luton - United Kingdom (LTN)",
    value: "LTN",
  },
  {
    label: "London, Southend - United Kingdom (SEN)",
    value: "SEN",
  },
  {
    label: "London, London City Apt - United Kingdom (LCY)",
    value: "LCY",
  },
  {
    label: "London, Liverpool Street - United Kingdom (ZLS)",
    value: "ZLS",
  },
  {
    label: "London, Biggin Hill - United Kingdom (BQH)",
    value: "BQH",
  },
  {
    label: "London, St Pancras Intl Railst - United Kingdom (QQS)",
    value: "QQS",
  },
  {
    label: "London, Stansted - United Kingdom (STN)",
    value: "STN",
  },
  {
    label: "Londrina, Jose Richa - Brazil (LDB)",
    value: "LDB",
  },
  {
    label: "Lucas Do Rio Verde, Bom Futuro - Brazil (LVR)",
    value: "LVR",
  },
  {
    label: "Macae, Benedito Lacerda - Brazil (MEA)",
    value: "MEA",
  },
  {
    label: "Macapa, Alberto Alcolumbre Int - Brazil (MCP)",
    value: "MCP",
  },
  {
    label: "Maceio, Zumbi Dos Palmares Int - Brazil (MCZ)",
    value: "MCZ",
  },
  {
    label: "Manaus, Eduardo Gomes - Brazil (MAO)",
    value: "MAO",
  },
  {
    label: "Manicore, Manicore - Brazil (MNX)",
    value: "MNX",
  },
  {
    label: "Maraba, Joao Correa Da Rocha - Brazil (MAB)",
    value: "MAB",
  },
  {
    label: "Marilia, Frank M Milenkowichi - Brazil (MII)",
    value: "MII",
  },
  {
    label: "Maringa, Silvio Name Jr. - Brazil (MGF)",
    value: "MGF",
  },
  {
    label: "Mato Grosso, Mato Grosso - Brazil (MTG)",
    value: "MTG",
  },
  {
    label: "Matupa, Orlando Villas-Boas - Brazil (MBK)",
    value: "MBK",
  },
  {
    label: "Maues, Maues - Brazil (MBZ)",
    value: "MBZ",
  },
  {
    label: "Minacu, Minacu - Brazil (MQH)",
    value: "MQH",
  },
  {
    label: "Miracema Do Norte, Miracema Do Tocantins - Brazil (NTM)",
    value: "NTM",
  },
  {
    label: "Monte Alegre, Monte Alegre - Brazil (MTE)",
    value: "MTE",
  },
  {
    label: "Monte Dourado, Serra Do Areao - Brazil (MEU)",
    value: "MEU",
  },
  {
    label: "Montes Claros, Mario Ribeiro - Brazil (MOC)",
    value: "MOC",
  },
  {
    label: "Mossoro, Dixsept Rosado - Brazil (MVF)",
    value: "MVF",
  },
  {
    label: "Mucuri, Mucuri - Brazil (MVS)",
    value: "MVS",
  },
  {
    label: "Nanuque, Nanuque - Brazil (NNU)",
    value: "NNU",
  },
  {
    label: "Natal, Augusto Severo - Brazil (NSA)",
    value: "NSA",
  },
  {
    label: "Natal, International - Brazil (NAT)",
    value: "NAT",
  },
  {
    label: "Navegantes, Victor Konder Intl - Brazil (NVT)",
    value: "NVT",
  },
  {
    label: "Niquelandia, Niquelandia - Brazil (NQL)",
    value: "NQL",
  },
  {
    label: "Nova Xavantina, Nova Xavantina - Brazil (NOK)",
    value: "NOK",
  },
  {
    label: "Novo Aripuana, Novo Aripuana - Brazil (NVP)",
    value: "NVP",
  },
  {
    label: "Novo Progresso, Novo Progresso - Brazil (NPR)",
    value: "NPR",
  },
  {
    label: "Obidos, Obidos - Brazil (OBI)",
    value: "OBI",
  },
  {
    label: "Oiapoque, Oiapoque - Brazil (OYK)",
    value: "OYK",
  },
  {
    label: "Oriximina, Oriximina - Brazil (ORX)",
    value: "ORX",
  },
  {
    label: "Ourilandia, Ourilandia Do Norte - Brazil (OIA)",
    value: "OIA",
  },
  {
    label: "Ourinhos, Benedito Pimentel - Brazil (OUS)",
    value: "OUS",
  },
  {
    label: "Palmas, Lysias Rodrigues - Brazil (PMW)",
    value: "PMW",
  },
  {
    label: "Paranagua, Santos Dumont - Brazil (PNG)",
    value: "PNG",
  },
  {
    label: "Paranaiba, Paranaiba - Brazil (PBB)",
    value: "PBB",
  },
  {
    label: "Paranavai, Edu Chaves - Brazil (PVI)",
    value: "PVI",
  },
  {
    label: "Parintins, Julio Belem - Brazil (PIN)",
    value: "PIN",
  },
  {
    label: "Parnaiba, Joao Silva Filho Intl - Brazil (PHB)",
    value: "PHB",
  },
  {
    label: "Passo Fundo, Lauro Kurtz - Brazil (PFB)",
    value: "PFB",
  },
  {
    label: "Passos, Jose Figueiredo - Brazil (PSW)",
    value: "PSW",
  },
  {
    label: "Pato Branco, J.Loureiro Cardoso - Brazil (PTO)",
    value: "PTO",
  },
  {
    label: "Patos De Minas, Patos De Minas - Brazil (POJ)",
    value: "POJ",
  },
  {
    label: "Paulo Afonso, Paulo Afonso - Brazil (PAV)",
    value: "PAV",
  },
  {
    label: "Pelotas, International - Brazil (PET)",
    value: "PET",
  },
  {
    label: "Petrolina, Senador Nilo Coelho - Brazil (PNZ)",
    value: "PNZ",
  },
  {
    label: "Picos, Picos - Brazil (PCS)",
    value: "PCS",
  },
  {
    label: "Pimenta Bueno, Pimenta Bueno - Brazil (PBQ)",
    value: "PBQ",
  },
  {
    label: "Pinheiro, Pinheiro - Brazil (PHI)",
    value: "PHI",
  },
  {
    label: "Pirapora, Pirapora - Brazil (PIV)",
    value: "PIV",
  },
  {
    label: "Pitinga, Pitinga - Brazil (PIG)",
    value: "PIG",
  },
  {
    label: "Pocos De Caldas, Walther Moreira Salles - Brazil (POO)",
    value: "POO",
  },
  {
    label: "Ponta Grossa, A.Amilton Beraldo - Brazil (PGZ)",
    value: "PGZ",
  },
  {
    label: "Ponta Pelada, Ponta Pelada - Brazil (PLL)",
    value: "PLL",
  },
  {
    label: "Ponta Pora, Ponta Pora Intl - Brazil (PMG)",
    value: "PMG",
  },
  {
    label: "Pontes E Lacerda, Pontes E Lacerda - Brazil (LCB)",
    value: "LCB",
  },
  {
    label: "Porto Alegre - Brazil (POA)",
    value: "POA",
  },
  {
    label: "Porto Alegre, Salgado Filho Intl - Brazil (POA)",
    value: "POA",
  },
  {
    label: "Porto Alegre Do Norte - Brazil (PBX)",
    value: "PBX",
  },
  {
    label: "Porto Alegre Do Norte, Fazenda Piraguassu - Brazil (PBX)",
    value: "PBX",
  },
  {
    label: "Porto De Moz, Porto De Moz - Brazil (PTQ)",
    value: "PTQ",
  },
  {
    label: "Porto Nacional, Porto Nacional - Brazil (PNB)",
    value: "PNB",
  },
  {
    label: "Porto Seguro, Porto Seguro - Brazil (BPS)",
    value: "BPS",
  },
  {
    label: "Porto Velho, J.T De Oliveira Intl - Brazil (PVH)",
    value: "PVH",
  },
  {
    label: "Portos Dos Gauchos, Porto Dos Gauchos - Brazil (PBV)",
    value: "PBV",
  },
  {
    label: "Pouso Alegre, Pouso Alegre - Brazil (PPY)",
    value: "PPY",
  },
  {
    label: "Prado, Prado - Brazil (PDF)",
    value: "PDF",
  },
  {
    label: "Presidente Dutra, Presidente Dutra - Brazil (PDR)",
    value: "PDR",
  },
  {
    label: "Presidente Prudente, Presidente Prudente - Brazil (PPB)",
    value: "PPB",
  },
  {
    label: "Progresso, Progresso - Brazil (PGG)",
    value: "PGG",
  },
  {
    label: "Recife - Brazil (REC)",
    value: "REC",
  },
  {
    label: "Recife, Guararapes Intl - Brazil (REC)",
    value: "REC",
  },
  {
    label: "Redencao, Redencao - Brazil (RDC)",
    value: "RDC",
  },
  {
    label: "Resende, Resende - Brazil (REZ)",
    value: "REZ",
  },
  {
    label: "Ribeirao Preto, Leite Lopes - Brazil (RAO)",
    value: "RAO",
  },
  {
    label: "Rio Branco, Placido De Castro Intl - Brazil (RBR)",
    value: "RBR",
  },
  {
    label: "Rio De Janeiro - Brazil (RIO)",
    value: "RIO",
  },
  {
    label: "Rio De Janeiro, Galeao A.C Jobim Intl - Brazil (GIG)",
    value: "GIG",
  },
  {
    label: "Rio De Janeiro, Santa Cruz Ab - Brazil (SNZ)",
    value: "SNZ",
  },
  {
    label: "Rio De Janeiro, Santos Dumont - Brazil (SDU)",
    value: "SDU",
  },
  {
    label: "Rio Grande, Regional - Brazil (RIG)",
    value: "RIG",
  },
  {
    label: "Rio Verde, Leite De Castro - Brazil (RVD)",
    value: "RVD",
  },
  {
    label: "Rondonopolis, Maestro Marinho Franco - Brazil (ROO)",
    value: "ROO",
  },
  {
    label: "Salvador - Brazil (SSA)",
    value: "SSA",
  },
  {
    label: "Salvador, D.L.E.Magalhaes - Brazil (SSA)",
    value: "SSA",
  },
  {
    label: "Santa Cruz Do Sul, Santa Cruz Do Sul - Brazil (CSU)",
    value: "CSU",
  },
  {
    label: "Santa Cruz Rio Pardo, Santa Cruz Rio Pardo - Brazil (QNR)",
    value: "QNR",
  },
  {
    label: "Santa Fe Do Sul, Fazenda Tres Irmaos - Brazil (SFV)",
    value: "SFV",
  },
  {
    label: "Santa Isabel Do M, Santa Isabel Do M - Brazil (IDO)",
    value: "IDO",
  },
  {
    label: "Santa Isabel Rio Negro, Santa Isabel Rio Negro - Brazil (IRZ)",
    value: "IRZ",
  },
  {
    label: "Santa Maria, Santa Maria - Brazil (RIA)",
    value: "RIA",
  },
  {
    label: "Santa Rosa, Luis Alberto Lehr - Brazil (SRA)",
    value: "SRA",
  },
  {
    label: "Santa Terezinha, Santa Terezinha - Brazil (STZ)",
    value: "STZ",
  },
  {
    label: "Santa Vitoria, Santa Vitoria - Brazil (CTQ)",
    value: "CTQ",
  },
  {
    label: "Santana Do Aragua, Santana Do Araguaia - Brazil (CMP)",
    value: "CMP",
  },
  {
    label: "Santarem, Maestro Wilson Fonseca - Brazil (STM)",
    value: "STM",
  },
  {
    label: "Santo Angelo, Sepe Tiaraju - Brazil (GEL)",
    value: "GEL",
  },
  {
    label: "Santos, Afb - Brazil (SSZ)",
    value: "SSZ",
  },
  {
    label: "Sao Carlos, Mario Pereira Lopes - Brazil (QSC)",
    value: "QSC",
  },
  {
    label: "Sao Felix Do Arag, Sao Felix Do Araguaia - Brazil (SXO)",
    value: "SXO",
  },
  {
    label: "Sao Felix Do Xing, Sao Felix Do Xingu - Brazil (SXX)",
    value: "SXX",
  },
  {
    label: "Sao Gabriel, Da Cachoeira - Brazil (SJL)",
    value: "SJL",
  },
  {
    label: "Sao Joao Del Rei, O.De Almeida Neves - Brazil (JDR)",
    value: "JDR",
  },
  {
    label: "Sao Jose Do Rio Preto, Eribelto Manoel Reino - Brazil (SJP)",
    value: "SJP",
  },
  {
    label: "Sao Jose Dos Campos, U.E Stumpf Intl - Brazil (SJK)",
    value: "SJK",
  },
  {
    label: "Sao Lourenco, Sao Lourenco - Brazil (SSO)",
    value: "SSO",
  },
  {
    label: "Sao Lourenco Do Sul, Sao Lourenco Do Sul - Brazil (SQY)",
    value: "SQY",
  },
  {
    label: "Sao Luiz, Cunha Machado Int - Brazil (SLZ)",
    value: "SLZ",
  },
  {
    label: "Sao Mateus, Sao Mateus - Brazil (SBJ)",
    value: "SBJ",
  },
  {
    label: "Sao Miguel Araguaia, Sao Miguel Do Araguaia - Brazil (SQM)",
    value: "SQM",
  },
  {
    label: "Sao Miguel Do Oeste, Helio Wasum - Brazil (SQX)",
    value: "SQX",
  },
  {
    label: "Sao Paulo - Brazil (SAO)",
    value: "SAO",
  },
  {
    label: "Sao Paulo, Congonhas - Brazil (CGH)",
    value: "CGH",
  },
  {
    label: "Sao Paulo, Guarulhos Intl - Brazil (GRU)",
    value: "GRU",
  },
  {
    label: "Sao Paulo, Viracopos Campinas Int - Brazil (VCP)",
    value: "VCP",
  },
  {
    label: "Sao Paulo De Olivenca - Brazil (OLC)",
    value: "OLC",
  },
  {
    label: "Sao Paulo De Olivenca, Sao Paulo De Olivenca - Brazil (OLC)",
    value: "OLC",
  },
  {
    label: "Sapezal, Fazenda Tucunare - Brazil (AZL)",
    value: "AZL",
  },
  {
    label: "Sena Madureira, Sena Madureira - Brazil (ZMD)",
    value: "ZMD",
  },
  {
    label: "Senhor Do Bonfim, Senhor Do Bonfim - Brazil (SEI)",
    value: "SEI",
  },
  {
    label: "Serra Norte, Serra Norte - Brazil (RRN)",
    value: "RRN",
  },
  {
    label: "Serra Pelada, Serra Pelada - Brazil (RSG)",
    value: "RSG",
  },
  {
    label: "Sinop, Jao B.Figueiredo - Brazil (OPS)",
    value: "OPS",
  },
  {
    label: "Sorocaba, Bertram Luiz Leupolz - Brazil (SOD)",
    value: "SOD",
  },
  {
    label: "Soure, Soure - Brazil (SFK)",
    value: "SFK",
  },
  {
    label: "Suia Missu, Suia Missu - Brazil (SWM)",
    value: "SWM",
  },
  {
    label: "Sumare, Sumare - Brazil (RWS)",
    value: "RWS",
  },
  {
    label: "Tabatinga, Tabatinga Intl - Brazil (TBT)",
    value: "TBT",
  },
  {
    label: "Tangara Da Serra, Tangara - Brazil (TGQ)",
    value: "TGQ",
  },
  {
    label: "Tarauaca, Jose Galera Dos Santos - Brazil (TRQ)",
    value: "TRQ",
  },
  {
    label: "Tefe, Tefe - Brazil (TFF)",
    value: "TFF",
  },
  {
    label: "Teixeira Freitas, Teixeira Freitas - Brazil (TXF)",
    value: "TXF",
  },
  {
    label: "Telemaco Borba, Telemaco Borba - Brazil (TEC)",
    value: "TEC",
  },
  {
    label: "Teofilo Otoni, Juscelino Kubitscheck - Brazil (TFL)",
    value: "TFL",
  },
  {
    label: "Teresina, Petronio Portella - Brazil (THE)",
    value: "THE",
  },
  {
    label: "Toledo, Luis Dal Canalle Filho - Brazil (TOW)",
    value: "TOW",
  },
  {
    label: "Torres, Torres - Brazil (TSQ)",
    value: "TSQ",
  },
  {
    label: "Tres Lagoas, Plinio Alarcom - Brazil (TJL)",
    value: "TJL",
  },
  {
    label: "Trombetas, Trombetas - Brazil (TMT)",
    value: "TMT",
  },
  {
    label: "Tucuma, Tucuma - Brazil (TUZ)",
    value: "TUZ",
  },
  {
    label: "Tucurui, Tucurui - Brazil (TUR)",
    value: "TUR",
  },
  {
    label: "Ubatuba, Gastao Madeira - Brazil (UBT)",
    value: "UBT",
  },
  {
    label: "Uberaba, M.De Almeida Franco - Brazil (UBA)",
    value: "UBA",
  },
  {
    label: "Uberlandia, Cesar Bombonato - Brazil (UDI)",
    value: "UDI",
  },
  {
    label: "Umuarama, Orlando De Carvalho - Brazil (UMU)",
    value: "UMU",
  },
  {
    label: "Urubupunga, Urubupunga - Brazil (URB)",
    value: "URB",
  },
  {
    label: "Uruguaiana, Rubem Berta Intl - Brazil (URG)",
    value: "URG",
  },
  {
    label: "Valenca, Valenca - Brazil (VAL)",
    value: "VAL",
  },
  {
    label: "Varginha, Maj Brig - Brazil (VAG)",
    value: "VAG",
  },
  {
    label: "Videira, Angelo Ponzoni - Brazil (VIA)",
    value: "VIA",
  },
  {
    label: "Vila Rica, Vila Rica - Brazil (VLP)",
    value: "VLP",
  },
  {
    label: "Vilhena, Brigadeiro Camarao - Brazil (BVH)",
    value: "BVH",
  },
  {
    label: "Vitoria, E.De Aguiar Salles - Brazil (VIX)",
    value: "VIX",
  },
  {
    label: "Vitoria Da Conquista, P.Otacilio Figueiredo - Brazil (VDC)",
    value: "VDC",
  },
  {
    label: "Votuporanga, Domingos Pignatari - Brazil (VOT)",
    value: "VOT",
  },
  {
    label: "Xanxere, Joao Winckler - Brazil (AXE)",
    value: "AXE",
  },
  {
    label: "Xinguera, Xinguera - Brazil (XIG)",
    value: "XIG",
  },
  {
    label: "Bandar Seri Begawan, Brunei Intl - Brunei Darussalam (BWN)",
    value: "BWN",
  },
  {
    label: "Kuala Belait, Kuala Belait - Brunei Darussalam (KUB)",
    value: "KUB",
  },
  {
    label: "Burgas - Bulgaria (BOJ)",
    value: "BOJ",
  },
  {
    label: "Burgas, Burgas - Bulgaria (BOJ)",
    value: "BOJ",
  },
  {
    label: "Gorna Oryahovitsa - Bulgaria (GOZ)",
    value: "GOZ",
  },
  {
    label: "Gorna Oryahovitsa, Gorna Oryahovitsa - Bulgaria (GOZ)",
    value: "GOZ",
  },
  {
    label: "Haskovo - Bulgaria (HKV)",
    value: "HKV",
  },
  {
    label: "Haskovo, Malevo - Bulgaria (HKV)",
    value: "HKV",
  },
  {
    label: "Jambol - Bulgaria (JAM)",
    value: "JAM",
  },
  {
    label: "Jambol, Bezmer - Bulgaria (JAM)",
    value: "JAM",
  },
  {
    label: "Plovdiv - Bulgaria (PDV)",
    value: "PDV",
  },
  {
    label: "Plovdiv, Krumovo - Bulgaria (PDV)",
    value: "PDV",
  },
  {
    label: "Ruse - Bulgaria (ROU)",
    value: "ROU",
  },
  {
    label: "Ruse, Shtruklevo - Bulgaria (ROU)",
    value: "ROU",
  },
  {
    label: "Silistra - Bulgaria (SLS)",
    value: "SLS",
  },
  {
    label: "Silistra, Polkovnik Lambrinovo - Bulgaria (SLS)",
    value: "SLS",
  },
  {
    label: "Sofia - Bulgaria (SOF)",
    value: "SOF",
  },
  {
    label: "Sofia, Sofia - Bulgaria (SOF)",
    value: "SOF",
  },
  {
    label: "Stara Zagora - Bulgaria (SZR)",
    value: "SZR",
  },
  {
    label: "Stara Zagora, Kolyu Ganchevo - Bulgaria (SZR)",
    value: "SZR",
  },
  {
    label: "Targovishte - Bulgaria (TGV)",
    value: "TGV",
  },
  {
    label: "Targovishte, Bukhovtsi - Bulgaria (TGV)",
    value: "TGV",
  },
  {
    label: "Varna - Bulgaria (VAR)",
    value: "VAR",
  },
  {
    label: "Varna, Varna - Bulgaria (VAR)",
    value: "VAR",
  },
  {
    label: "Aribinda, Aribinda - Burkina Faso (XAR)",
    value: "XAR",
  },
  {
    label: "Arly, Arly - Burkina Faso (ARL)",
    value: "ARL",
  },
  {
    label: "Banfora, Banfora - Burkina Faso (BNR)",
    value: "BNR",
  },
  {
    label: "Bobo Dioulasso, Bobo Dioulasso - Burkina Faso (BOY)",
    value: "BOY",
  },
  {
    label: "Bogande, Bogande - Burkina Faso (XBG)",
    value: "XBG",
  },
  {
    label: "Boulsa, Boulsa - Burkina Faso (XBO)",
    value: "XBO",
  },
  {
    label: "Dedougou, Dedougou - Burkina Faso (DGU)",
    value: "DGU",
  },
  {
    label: "Diapaga, Diapaga - Burkina Faso (DIP)",
    value: "DIP",
  },
  {
    label: "Diebougou, Diebougou - Burkina Faso (XDE)",
    value: "XDE",
  },
  {
    label: "Djibo, Djibo - Burkina Faso (XDJ)",
    value: "XDJ",
  },
  {
    label: "Dori, Dori - Burkina Faso (DOR)",
    value: "DOR",
  },
  {
    label: "Fada N'gourma, Fada N'gourma - Burkina Faso (FNG)",
    value: "FNG",
  },
  {
    label: "Gaoua, Amilcar Cabral - Burkina Faso (XGA)",
    value: "XGA",
  },
  {
    label: "Gorom Gorom, Gorom Gorom - Burkina Faso (XGG)",
    value: "XGG",
  },
  {
    label: "Kantchari, Kantchari - Burkina Faso (XKA)",
    value: "XKA",
  },
  {
    label: "Kaya, Kaya - Burkina Faso (XKY)",
    value: "XKY",
  },
  {
    label: "Leo, Leo - Burkina Faso (XLU)",
    value: "XLU",
  },
  {
    label: "Nouna, Nouna - Burkina Faso (XNU)",
    value: "XNU",
  },
  {
    label: "Ouagadougou, Ouagadougou - Burkina Faso (OUA)",
    value: "OUA",
  },
  {
    label: "Ouahigouya, Ouahigouya - Burkina Faso (OUG)",
    value: "OUG",
  },
  {
    label: "Pama, Pama - Burkina Faso (XPA)",
    value: "XPA",
  },
  {
    label: "Sebba, Sebba - Burkina Faso (XSE)",
    value: "XSE",
  },
  {
    label: "Tambao, Tambao - Burkina Faso (TMQ)",
    value: "TMQ",
  },
  {
    label: "Tenkodogo, Tenkodogo - Burkina Faso (TEG)",
    value: "TEG",
  },
  {
    label: "Tougan, Tougan - Burkina Faso (TUQ)",
    value: "TUQ",
  },
  {
    label: "Zabre, Zabre - Burkina Faso (XZA)",
    value: "XZA",
  },
  {
    label: "Bujumbura, Bujumbura Intl - Burundi (BJM)",
    value: "BJM",
  },
  {
    label: "Gitega, Gitega - Burundi (GID)",
    value: "GID",
  },
  {
    label: "Kirundo, Kirundo - Burundi (KRE)",
    value: "KRE",
  },
  {
    label: "Battambang, Battambang - Cambodia (BBM)",
    value: "BBM",
  },
  {
    label: "Kampong Chhnang, Kampong Chhnang - Cambodia (KZC)",
    value: "KZC",
  },
  {
    label: "Kampot, Kampot - Cambodia (KMT)",
    value: "KMT",
  },
  {
    label: "Koh Kong, Koh Kong - Cambodia (KKZ)",
    value: "KKZ",
  },
  {
    label: "Krakor, Krakor - Cambodia (KZD)",
    value: "KZD",
  },
  {
    label: "Kratie, Kratie - Cambodia (KTI)",
    value: "KTI",
  },
  {
    label: "Mondulkiri, Mondulkiri - Cambodia (MWV)",
    value: "MWV",
  },
  {
    label: "Phnom Penh, International - Cambodia (PNH)",
    value: "PNH",
  },
  {
    label: "Ratanakiri, Ratanakiri - Cambodia (RBE)",
    value: "RBE",
  },
  {
    label: "Siem Reap, Angkor Intl - Cambodia (REP)",
    value: "REP",
  },
  {
    label: "Sihanoukville, International - Cambodia (KOS)",
    value: "KOS",
  },
  {
    label: "Stung Treng, Stung Treng - Cambodia (TNX)",
    value: "TNX",
  },
  {
    label: "Thbeng Meanchey, Preah Vinhear - Cambodia (OMY)",
    value: "OMY",
  },
  {
    label: "Bafoussam, Bafoussam - Cameroon-Republic Of (BFX)",
    value: "BFX",
  },
  {
    label: "Bali, Bali - Cameroon-Republic Of (BLC)",
    value: "BLC",
  },
  {
    label: "Bamenda, Bamenda - Cameroon-Republic Of (BPC)",
    value: "BPC",
  },
  {
    label: "Batouri, Batouri - Cameroon-Republic Of (OUR)",
    value: "OUR",
  },
  {
    label: "Bertoua, Bertoua - Cameroon-Republic Of (BTA)",
    value: "BTA",
  },
  {
    label: "Douala, Douala Intl - Cameroon-Republic Of (DLA)",
    value: "DLA",
  },
  {
    label: "Dschang, Dschang - Cameroon-Republic Of (DSC)",
    value: "DSC",
  },
  {
    label: "Ebolowa, Ebolowa - Cameroon-Republic Of (EBW)",
    value: "EBW",
  },
  {
    label: "Foumban, Nkounja - Cameroon-Republic Of (FOM)",
    value: "FOM",
  },
  {
    label: "Garoua, Garoua Intl - Cameroon-Republic Of (GOU)",
    value: "GOU",
  },
  {
    label: "Kaele, Kaele - Cameroon-Republic Of (KLE)",
    value: "KLE",
  },
  {
    label: "Kribi, Kribi - Cameroon-Republic Of (KBI)",
    value: "KBI",
  },
  {
    label: "Mamfe, Mamfe - Cameroon-Republic Of (MMF)",
    value: "MMF",
  },
  {
    label: "Maroua, Salak - Cameroon-Republic Of (MVR)",
    value: "MVR",
  },
  {
    label: "Ngaoundere, Ngaoundere - Cameroon-Republic Of (NGE)",
    value: "NGE",
  },
  {
    label: "Nkongsamba, Nkongsamba - Cameroon-Republic Of (NKS)",
    value: "NKS",
  },
  {
    label: "Tiko, Tiko - Cameroon-Republic Of (TKC)",
    value: "TKC",
  },
  {
    label: "Yagoua, Yagoua - Cameroon-Republic Of (GXX)",
    value: "GXX",
  },
  {
    label: "Yaounde, Nsimalen Intl - Cameroon-Republic Of (NSI)",
    value: "NSI",
  },
  {
    label: "Yaounde, Yaounde Ville Apt - Cameroon-Republic Of (YAO)",
    value: "YAO",
  },
  {
    label: "Abbotsford, International - Canada (YXX)",
    value: "YXX",
  },
  {
    label: "Aklavik, Freddie Carmichael - Canada (LAK)",
    value: "LAK",
  },
  {
    label: "Akulivik, Akulivik - Canada (AKV)",
    value: "AKV",
  },
  {
    label: "Alert, Alert - Canada (YLT)",
    value: "YLT",
  },
  {
    label: "Alert Bay, Alert Bay - Canada (YAL)",
    value: "YAL",
  },
  {
    label: "Alice Arm, Silver City Spb - Canada (ZAA)",
    value: "ZAA",
  },
  {
    label: "Alma, Alma - Canada (YTF)",
    value: "YTF",
  },
  {
    label: "Alta Lake, Alta Lake - Canada (YAE)",
    value: "YAE",
  },
  {
    label: "Amos, Magny - Canada (YEY)",
    value: "YEY",
  },
  {
    label: "Anahim Lake, Anahim Lake - Canada (YAA)",
    value: "YAA",
  },
  {
    label: "Angling Lake, Wapekeka - Canada (YAX)",
    value: "YAX",
  },
  {
    label: "Arctic Bay, Arctic Bay - Canada (YAB)",
    value: "YAB",
  },
  {
    label: "Armstrong, Armstrong - Canada (YYW)",
    value: "YYW",
  },
  {
    label: "Arnes, Arnes - Canada (YNR)",
    value: "YNR",
  },
  {
    label: "Arviat, Arviat - Canada (YEK)",
    value: "YEK",
  },
  {
    label: "Ashcroft, Regional - Canada (YZA)",
    value: "YZA",
  },
  {
    label: "Atikokan, Municipal - Canada (YIB)",
    value: "YIB",
  },
  {
    label: "Attawapiskat, Attawapiskat - Canada (YAT)",
    value: "YAT",
  },
  {
    label: "Aupaluk, Aupaluk - Canada (YPJ)",
    value: "YPJ",
  },
  {
    label: "Bagotville, Bagotville - Canada (YBG)",
    value: "YBG",
  },
  {
    label: "Baie Comeau, Baie-Comeau - Canada (YBC)",
    value: "YBC",
  },
  {
    label: "Baie Johan Beetz, Spb - Canada (YBJ)",
    value: "YBJ",
  },
  {
    label: "Baker Lake, Baker Lake - Canada (YBK)",
    value: "YBK",
  },
  {
    label: "Bamfield, Spb - Canada (YBF)",
    value: "YBF",
  },
  {
    label: "Banff, Banff - Canada (YBA)",
    value: "YBA",
  },
  {
    label: "Bar River, Bar River - Canada (YEB)",
    value: "YEB",
  },
  {
    label: "Bathurst, Bathurst - Canada (ZBF)",
    value: "ZBF",
  },
  {
    label: "Bearskin Lake, Bearskin Lake - Canada (XBE)",
    value: "XBE",
  },
  {
    label: "Beatton River, Beatton River - Canada (YZC)",
    value: "YZC",
  },
  {
    label: "Beaver Creek, Beaver Creek - Canada (YXQ)",
    value: "YXQ",
  },
  {
    label: "Bedwell Harbor, Spb - Canada (YBW)",
    value: "YBW",
  },
  {
    label: "Bella Bella, Campbell Island - Canada (ZEL)",
    value: "ZEL",
  },
  {
    label: "Bella Bella, Shearwater - Canada (YSX)",
    value: "YSX",
  },
  {
    label: "Bella Coola, Bella Coola - Canada (QBC)",
    value: "QBC",
  },
  {
    label: "Berens River, Berens River - Canada (YBV)",
    value: "YBV",
  },
  {
    label: "Big Bay Marina, Big Bay Spb - Canada (YIG)",
    value: "YIG",
  },
  {
    label: "Big Bay Yacht Clu, Big Bay Yacht Clu - Canada (YYA)",
    value: "YYA",
  },
  {
    label: "Big Trout, Big Trout Lake - Canada (YTL)",
    value: "YTL",
  },
  {
    label: "Black Tickle, Black Tickle - Canada (YBI)",
    value: "YBI",
  },
  {
    label: "Blanc Sablon, Lourdes-De-Blancsablon - Canada (YBX)",
    value: "YBX",
  },
  {
    label: "Bloodvein, Bloodvein River - Canada (YDV)",
    value: "YDV",
  },
  {
    label: "Blubber Bay, Spb - Canada (XBB)",
    value: "XBB",
  },
  {
    label: "Bobquinn Lake, Bob Quinn Lake - Canada (YBO)",
    value: "YBO",
  },
  {
    label: "Bonaventure, Bonaventure - Canada (YVB)",
    value: "YVB",
  },
  {
    label: "Bonnyville, Bonnyville - Canada (YBY)",
    value: "YBY",
  },
  {
    label: "Borden, Cfb Heliport - Canada (YBN)",
    value: "YBN",
  },
  {
    label: "Brandon, Municipal - Canada (YBR)",
    value: "YBR",
  },
  {
    label: "Broadview, Broadview - Canada (YDR)",
    value: "YDR",
  },
  {
    label: "Brochet, Brochet - Canada (YBT)",
    value: "YBT",
  },
  {
    label: "Brockville, Regional Tackaberry - Canada (XBR)",
    value: "XBR",
  },
  {
    label: "Bromont, Roland-Desourdy - Canada (ZBM)",
    value: "ZBM",
  },
  {
    label: "Bronson Creek, Bronson Creek - Canada (YBM)",
    value: "YBM",
  },
  {
    label: "Buchans, Buchans - Canada (YZM)",
    value: "YZM",
  },
  {
    label: "Buffalo Narrows, Buffalo Narrows - Canada (YVT)",
    value: "YVT",
  },
  {
    label: "Bull Harbour, Spb - Canada (YBH)",
    value: "YBH",
  },
  {
    label: "Burns Lake, Burns Lake - Canada (YPZ)",
    value: "YPZ",
  },
  {
    label: "Burwash Landings, Burwash - Canada (YDB)",
    value: "YDB",
  },
  {
    label: "Cabin Plant, Heliport - Canada (DCP)",
    value: "DCP",
  },
  {
    label: "Calgary, Calgary Intl - Canada (YYC)",
    value: "YYC",
  },
  {
    label: "Cambridge Bay, Cambridge Bay - Canada (YCB)",
    value: "YCB",
  },
  {
    label: "Campbell River, Airport - Canada (YBL)",
    value: "YBL",
  },
  {
    label: "Campbell River, Spb - Canada (YHH)",
    value: "YHH",
  },
  {
    label: "Cape Dorset, Cape Dorset - Canada (YTE)",
    value: "YTE",
  },
  {
    label: "Cape Dyer, Cape Dyer - Canada (YVN)",
    value: "YVN",
  },
  {
    label: "Cartwright, Cartwright - Canada (YRF)",
    value: "YRF",
  },
  {
    label: "Castlegar, West Kootenay Regional - Canada (YCG)",
    value: "YCG",
  },
  {
    label: "Cat Lake, Cat Lake - Canada (YAC)",
    value: "YAC",
  },
  {
    label: "Centralia, James T Field Memorial - Canada (YCE)",
    value: "YCE",
  },
  {
    label: "Chapleau, Chapleau - Canada (YLD)",
    value: "YLD",
  },
  {
    label: "Charlo, Charlo - Canada (YCL)",
    value: "YCL",
  },
  {
    label: "Charlottetown, Charlottetown - Canada (YYG)",
    value: "YYG",
  },
  {
    label: "Charlottetown, Charlottetown - Canada (YHG)",
    value: "YHG",
  },
  {
    label: "Chatham, Chatham Kent - Canada (XCM)",
    value: "XCM",
  },
  {
    label: "Chesterfield Inlet, Chesterfield Inlet - Canada (YCS)",
    value: "YCS",
  },
  {
    label: "Chetwynd, Chetwynd - Canada (YCQ)",
    value: "YCQ",
  },
  {
    label: "Chevery, Chevery - Canada (YHR)",
    value: "YHR",
  },
  {
    label: "Chibougamau, Chapais - Canada (YMT)",
    value: "YMT",
  },
  {
    label: "Chilko Lake, Tsylos Park Lodge - Canada (CJH)",
    value: "CJH",
  },
  {
    label: "Chilliwack, Chilliwack - Canada (YCW)",
    value: "YCW",
  },
  {
    label: "Chisasibi, Chisasibi - Canada (YKU)",
    value: "YKU",
  },
  {
    label: "Churchill, Airport - Canada (YYQ)",
    value: "YYQ",
  },
  {
    label: "Churchill Falls, Churchill Falls - Canada (ZUM)",
    value: "ZUM",
  },
  {
    label: "Chute-Des-Passes, Lac Margane Spb - Canada (YWQ)",
    value: "YWQ",
  },
  {
    label: "Clinton Creek, Clinton Creek - Canada (YLM)",
    value: "YLM",
  },
  {
    label: "Cluff Lake, Cluff Lake - Canada (XCL)",
    value: "XCL",
  },
  {
    label: "Clyde River, Clyde River - Canada (YCY)",
    value: "YCY",
  },
  {
    label: "Cochrane, Cochrane - Canada (YCN)",
    value: "YCN",
  },
  {
    label: "Cold Lake, R.W Mcnair - Canada (YOD)",
    value: "YOD",
  },
  {
    label: "Collins Bay, Collins Bay - Canada (YKC)",
    value: "YKC",
  },
  {
    label: "Colville Lake, Tommy Kochon - Canada (YCK)",
    value: "YCK",
  },
  {
    label: "Comox, Comox - Canada (YQQ)",
    value: "YQQ",
  },
  {
    label: "Coral Harbour, Coral Harbour - Canada (YZS)",
    value: "YZS",
  },
  {
    label: "Cornwall, Regional - Canada (YCC)",
    value: "YCC",
  },
  {
    label: "Coronation, Coronation - Canada (YCT)",
    value: "YCT",
  },
  {
    label: "Cortes Bay, Spb - Canada (YCF)",
    value: "YCF",
  },
  {
    label: "Courtenay, Airpark - Canada (YCA)",
    value: "YCA",
  },
  {
    label: "Cowley, Cowley - Canada (YYM)",
    value: "YYM",
  },
  {
    label: "Cranbrook, Canadian Rockies Intl - Canada (YXC)",
    value: "YXC",
  },
  {
    label: "Creston, Canada (CFQ)",
    value: "CFQ",
  },
  {
    label: "Creston, Creston - Canada (CFQ)",
    value: "CFQ",
  },
  {
    label: "Cross Lake, Charlie Sinclair Mem. - Canada (YCR)",
    value: "YCR",
  },
  {
    label: "Dauphin, Lt. W.G. Billy)Barker - Canada (YDN)",
    value: "YDN",
  },
  {
    label: "Dawson City, Dawson City - Canada (YDA)",
    value: "YDA",
  },
  {
    label: "Dawson Creek, Dawson Creek - Canada (YDQ)",
    value: "YDQ",
  },
  {
    label: "Dean River, Dean River - Canada (YRD)",
    value: "YRD",
  },
  {
    label: "Dease Lake, Dease Lake - Canada (YDL)",
    value: "YDL",
  },
  {
    label: "Deer Lake, Deer Lake - Canada (YVZ)",
    value: "YVZ",
  },
  {
    label: "Deer Lake, Regional - Canada (YDF)",
    value: "YDF",
  },
  {
    label: "Deline, Deline - Canada (YWJ)",
    value: "YWJ",
  },
  {
    label: "Diavik, Diavik - Canada (DVK)",
    value: "DVK",
  },
  {
    label: "Digby, Annapolis Regional - Canada (YDG)",
    value: "YDG",
  },
  {
    label: "Dolbeau, St-Felicien - Canada (YDO)",
    value: "YDO",
  },
  {
    label: "Douglas Lake, Douglas Lake - Canada (DGF)",
    value: "DGF",
  },
  {
    label: "Drayton Valley, Ind Apt - Canada (YDC)",
    value: "YDC",
  },
  {
    label: "Dryden, Regional - Canada (YHD)",
    value: "YHD",
  },
  {
    label: "Duncan Quam, Duncan - Canada (DUQ)",
    value: "DUQ",
  },
  {
    label: "Earlton, Timiskaming Regional - Canada (YXR)",
    value: "YXR",
  },
  {
    label: "East Main, Eastmain River - Canada (ZEM)",
    value: "ZEM",
  },
  {
    label: "Edmonton, Cfb Namao Heliport - Canada (YED)",
    value: "YED",
  },
  {
    label: "Edmonton, City Center Airport - Canada (YXD)",
    value: "YXD",
  },
  {
    label: "Edmonton, Edmonton Intl - Canada (YEG)",
    value: "YEG",
  },
  {
    label: "Edson, Edson - Canada (YET)",
    value: "YET",
  },
  {
    label: "Ekati, Ekati Airport - Canada (YOA)",
    value: "YOA",
  },
  {
    label: "Elliot Lake, Municipal - Canada (YEL)",
    value: "YEL",
  },
  {
    label: "Esquimalt, Esquimalt - Canada (YPF)",
    value: "YPF",
  },
  {
    label: "Estevan, Regional - Canada (YEN)",
    value: "YEN",
  },
  {
    label: "Eureka, Eureka - Canada (YEU)",
    value: "YEU",
  },
  {
    label: "Fairmount Springs, Fairmount Hot Springs - Canada (YCZ)",
    value: "YCZ",
  },
  {
    label: "Fairview, Fairview - Canada (ZFW)",
    value: "ZFW",
  },
  {
    label: "Falher, Donnelly - Canada (YOE)",
    value: "YOE",
  },
  {
    label: "Faro, Faro - Canada (ZFA)",
    value: "ZFA",
  },
  {
    label: "Flin Flon, Flin Flon - Canada (YFO)",
    value: "YFO",
  },
  {
    label: "Fond Du Lac, Fond-Du-Lac - Canada (ZFD)",
    value: "ZFD",
  },
  {
    label: "Fontanges, Fontanges - Canada (YFG)",
    value: "YFG",
  },
  {
    label: "Forestville, Forestville - Canada (YFE)",
    value: "YFE",
  },
  {
    label: "Fort Albany, Fort Albany - Canada (YFA)",
    value: "YFA",
  },
  {
    label: "Fort Chipewyan, Fort Chipewyan - Canada (YPY)",
    value: "YPY",
  },
  {
    label: "Fort Frances, Municipal - Canada (YAG)",
    value: "YAG",
  },
  {
    label: "Fort Good Hope, Fort Good Hope - Canada (YGH)",
    value: "YGH",
  },
  {
    label: "Fort Hope, Fort Hope - Canada (YFH)",
    value: "YFH",
  },
  {
    label: "Fort Liard, Fort Liard - Canada (YJF)",
    value: "YJF",
  },
  {
    label: "Fort Mackay, Albian - Canada (JHL)",
    value: "JHL",
  },
  {
    label: "Fort Mackay, Firebag - Canada (YFI)",
    value: "YFI",
  },
  {
    label: "Fort Mackay, Horizon - Canada (HZP)",
    value: "HZP",
  },
  {
    label: "Fort Mcmurray, Fort Mcmurray - Canada (YMM)",
    value: "YMM",
  },
  {
    label: "Fort Mcmurray, Mildred Lake - Canada (NML)",
    value: "NML",
  },
  {
    label: "Fort Mcpherson, Fort Mcpherson - Canada (ZFM)",
    value: "ZFM",
  },
  {
    label: "Fort Nelson, Northern Rockies Rgnl - Canada (YYE)",
    value: "YYE",
  },
  {
    label: "Fort Reliance, Spb - Canada (YFL)",
    value: "YFL",
  },
  {
    label: "Fort Resolution, Fort Resolution - Canada (YFR)",
    value: "YFR",
  },
  {
    label: "Fort Severn, Fort Severn - Canada (YER)",
    value: "YER",
  },
  {
    label: "Fort Simpson, Fort Simpson - Canada (YFS)",
    value: "YFS",
  },
  {
    label: "Fort Smith, Fort Smith - Canada (YSM)",
    value: "YSM",
  },
  {
    label: "Fort St John, North Peace - Canada (YXJ)",
    value: "YXJ",
  },
  {
    label: "Fox Harbour, Saint Lewis Fox Harbour - Canada (YFX)",
    value: "YFX",
  },
  {
    label: "Fredericton, International - Canada (YFC)",
    value: "YFC",
  },
  {
    label: "Gagetown, Cfb Heliport - Canada (YCX)",
    value: "YCX",
  },
  {
    label: "Gander, International - Canada (YQX)",
    value: "YQX",
  },
  {
    label: "Ganges Harbour, Spb - Canada (YGG)",
    value: "YGG",
  },
  {
    label: "Gaspe, Michel-Pouliot - Canada (YGP)",
    value: "YGP",
  },
  {
    label: "Geraldton, Greenstone Regional - Canada (YGQ)",
    value: "YGQ",
  },
  {
    label: "Germansen, Germansen - Canada (YGS)",
    value: "YGS",
  },
  {
    label: "Gethsemani, La Romaine - Canada (ZGS)",
    value: "ZGS",
  },
  {
    label: "Gillam, Gillam - Canada (YGX)",
    value: "YGX",
  },
  {
    label: "Gillies Bay, Gillies Bay - Canada (YGB)",
    value: "YGB",
  },
  {
    label: "Gimli, Industrial Park - Canada (YGM)",
    value: "YGM",
  },
  {
    label: "Gjoa Haven, Gjoa Haven - Canada (YHK)",
    value: "YHK",
  },
  {
    label: "Gods Narrows, Gods Lake Narrows - Canada (YGO)",
    value: "YGO",
  },
  {
    label: "Gods River, Gods River - Canada (ZGI)",
    value: "ZGI",
  },
  {
    label: "Goose Bay, Goose Bay - Canada (YYR)",
    value: "YYR",
  },
  {
    label: "Gore Bay, Manitoulin - Canada (YZE)",
    value: "YZE",
  },
  {
    label: "Gorge Harbor, Spb - Canada (YGE)",
    value: "YGE",
  },
  {
    label: "Grand Forks, Grand Forks - Canada (ZGF)",
    value: "ZGF",
  },
  {
    label: "Grande Cache, Grande Cache - Canada (YGC)",
    value: "YGC",
  },
  {
    label: "Grande Prairie, Grande Prairie - Canada (YQU)",
    value: "YQU",
  },
  {
    label: "Great Bear Lake, Great Bear Lake - Canada (DAS)",
    value: "DAS",
  },
  {
    label: "Greenway Sound, Spb - Canada (YGN)",
    value: "YGN",
  },
  {
    label: "Greenwood, Greenwood - Canada (YZX)",
    value: "YZX",
  },
  {
    label: "Grise Fiord, Grise Fiord - Canada (YGZ)",
    value: "YGZ",
  },
  {
    label: "Haines Junction, Haines Junction - Canada (YHT)",
    value: "YHT",
  },
  {
    label: "Hakai Pass, Spb - Canada (YHC)",
    value: "YHC",
  },
  {
    label: "Halifax, Shearwater Heliport - Canada (YAW)",
    value: "YAW",
  },
  {
    label: "Halifax, South End Heliport - Canada (YWF)",
    value: "YWF",
  },
  {
    label: "Halifax, Stanfield Intl - Canada (YHZ)",
    value: "YHZ",
  },
  {
    label: "Hall Beach, Hall Beach - Canada (YUX)",
    value: "YUX",
  },
  {
    label: "Hartley Bay, Spb - Canada (YTB)",
    value: "YTB",
  },
  {
    label: "Hatchet Lake, Hatchet Lake - Canada (YDJ)",
    value: "YDJ",
  },
  {
    label: "Havre St Pierre, Havre-St-Pierre - Canada (YGV)",
    value: "YGV",
  },
  {
    label: "Hay River, Merlyn Carter - Canada (YHY)",
    value: "YHY",
  },
  {
    label: "Hearst, Rene Fontaine Muni - Canada (YHF)",
    value: "YHF",
  },
  {
    label: "High Level, High Level - Canada (YOJ)",
    value: "YOJ",
  },
  {
    label: "High Prairie, High Prairie - Canada (ZHP)",
    value: "ZHP",
  },
  {
    label: "Hope, Hope - Canada (YHE)",
    value: "YHE",
  },
  {
    label: "Hope Bay, Doris Lake Ice A - Canada (JOJ)",
    value: "JOJ",
  },
  {
    label: "Hope Bay, Hope Bay Airport - Canada (UZM)",
    value: "UZM",
  },
  {
    label: "Hopedale, Hopedale - Canada (YHO)",
    value: "YHO",
  },
  {
    label: "Horn River, Heliport - Canada (DHO)",
    value: "DHO",
  },
  {
    label: "Hornepayne, Municipal - Canada (YHN)",
    value: "YHN",
  },
  {
    label: "Hudson Bay, Hudson Bay - Canada (YHB)",
    value: "YHB",
  },
  {
    label: "Hudson's Hope, Hudson Hope - Canada (YNH)",
    value: "YNH",
  },
  {
    label: "Igloolik, Igloolik - Canada (YGT)",
    value: "YGT",
  },
  {
    label: "Ignace, Municipal - Canada (ZUC)",
    value: "ZUC",
  },
  {
    label: "Iles De La Madele, Iles De La Madeleine - Canada (YGR)",
    value: "YGR",
  },
  {
    label: "Ilford, Ilford - Canada (ILF)",
    value: "ILF",
  },
  {
    label: "Inukjuak, Inukjuak - Canada (YPH)",
    value: "YPH",
  },
  {
    label: "Inuvik, Mike Zubko - Canada (YEV)",
    value: "YEV",
  },
  {
    label: "Inverlake, Cheadle - Canada (TIL)",
    value: "TIL",
  },
  {
    label: "Iqaluit, Iqaluit - Canada (YFB)",
    value: "YFB",
  },
  {
    label: "Island Lk Garden Hill, Island Lk Garden Hill - Canada (YIV)",
    value: "YIV",
  },
  {
    label: "Ivujivik, Ivujivik - Canada (YIK)",
    value: "YIK",
  },
  {
    label: "Jasper, Hinton - Canada (YJA)",
    value: "YJA",
  },
  {
    label: "Jasper Hinton, Jasper-Hinton - Canada (YJP)",
    value: "YJP",
  },
  {
    label: "Jenpeg, Jenpeg - Canada (ZJG)",
    value: "ZJG",
  },
  {
    label: "Johnny Mountain, Johnny Mountain - Canada (YJO)",
    value: "YJO",
  },
  {
    label: "Kamloops, Kamloops - Canada (YKA)",
    value: "YKA",
  },
  {
    label: "Kangiqsualujjuaq, Georges River - Canada (XGR)",
    value: "XGR",
  },
  {
    label: "Kangiqsujuaq, Wakeham Bay - Canada (YWB)",
    value: "YWB",
  },
  {
    label: "Kangirsuk, Kangirsuk - Canada (YKG)",
    value: "YKG",
  },
  {
    label: "Kapuskasing, Kapuskasing - Canada (YYU)",
    value: "YYU",
  },
  {
    label: "Kasabonika, Kasabonika - Canada (XKS)",
    value: "XKS",
  },
  {
    label: "Kasba Lake, Kasba Lake - Canada (YDU)",
    value: "YDU",
  },
  {
    label: "Kaschechewan, Kashechewan - Canada (ZKE)",
    value: "ZKE",
  },
  {
    label: "Kattiniq, Donaldson - Canada (YAU)",
    value: "YAU",
  },
  {
    label: "Keewaywin, Keewaywin - Canada (KEW)",
    value: "KEW",
  },
  {
    label: "Kegaska, Kegaska - Canada (ZKG)",
    value: "ZKG",
  },
  {
    label: "Kelowna, International - Canada (YLW)",
    value: "YLW",
  },
  {
    label: "Kelsey, Kelsey - Canada (KES)",
    value: "KES",
  },
  {
    label: "Kemano, Heliport - Canada (XKO)",
    value: "XKO",
  },
  {
    label: "Kenora, Kenora - Canada (YQK)",
    value: "YQK",
  },
  {
    label: "Key Lake, Key Lake - Canada (YKJ)",
    value: "YKJ",
  },
  {
    label: "Kimberley, Kimberley - Canada (YQE)",
    value: "YQE",
  },
  {
    label: "Kimmirut Lake Harbour, Kimmirut - Canada (YLC)",
    value: "YLC",
  },
  {
    label: "Kincardine, Kincardine - Canada (YKD)",
    value: "YKD",
  },
  {
    label: "Kindersley, Regional - Canada (YKY)",
    value: "YKY",
  },
  {
    label: "Kingfisher Lake, Kingfisher Lake - Canada (KIF)",
    value: "KIF",
  },
  {
    label: "Kingston, Norman Rogers - Canada (YGK)",
    value: "YGK",
  },
  {
    label: "Kinoosao, Kinoosao - Canada (KNY)",
    value: "KNY",
  },
  {
    label: "Kirkland Lake, Kirkland Lake - Canada (YKX)",
    value: "YKX",
  },
  {
    label: "Kitkatla, Spb - Canada (YKK)",
    value: "YKK",
  },
  {
    label: "Kiwigana Lodge, Heliport - Canada (DKL)",
    value: "DKL",
  },
  {
    label: "Klemtu, Spb - Canada (YKT)",
    value: "YKT",
  },
  {
    label: "Knee Lake, Knee Lake - Canada (YKE)",
    value: "YKE",
  },
  {
    label: "Knights Inlet, Knights Inlet - Canada (KNV)",
    value: "KNV",
  },
  {
    label: "Kugluktuk Coppermine, Kugluktuk - Canada (YCO)",
    value: "YCO",
  },
  {
    label: "Kuujjuaq, Kuujjuaq - Canada (YVP)",
    value: "YVP",
  },
  {
    label: "Kuujjuarapik, Kuujjuarapik - Canada (YGW)",
    value: "YGW",
  },
  {
    label: "La Grande, La Grande Riviere - Canada (YGL)",
    value: "YGL",
  },
  {
    label: "La Ronge, Barber Field - Canada (YVC)",
    value: "YVC",
  },
  {
    label: "La Sarre, La Sarre - Canada (SSQ)",
    value: "SSQ",
  },
  {
    label: "La Tabatiere, La Tabatiere - Canada (ZLT)",
    value: "ZLT",
  },
  {
    label: "La Tuque, La Tuque - Canada (YLQ)",
    value: "YLQ",
  },
  {
    label: "Lac Biche, Lac La Biche - Canada (YLB)",
    value: "YLB",
  },
  {
    label: "Lac Brochet, Lac Brochet - Canada (XLB)",
    value: "XLB",
  },
  {
    label: "Lagrande 3, Lagrande-3 - Canada (YAR)",
    value: "YAR",
  },
  {
    label: "Lagrande 4, Lagrande-4 - Canada (YAH)",
    value: "YAH",
  },
  {
    label: "Langara, Langara - Canada (YLA)",
    value: "YLA",
  },
  {
    label: "Langley, Langley Regional - Canada (YLY)",
    value: "YLY",
  },
  {
    label: "Lansdowne House, Lansdowne House - Canada (YLH)",
    value: "YLH",
  },
  {
    label: "Laurie River, Laurie River - Canada (LRQ)",
    value: "LRQ",
  },
  {
    label: "Leaf Rapids, Leaf Rapids - Canada (YLR)",
    value: "YLR",
  },
  {
    label: "Lebel Sur Quevillon, Lebel-Sur-Quevillon - Canada (YLS)",
    value: "YLS",
  },
  {
    label: "Lethbridge, Lethbridge County - Canada (YQL)",
    value: "YQL",
  },
  {
    label: "Liard River, Liard River - Canada (YZL)",
    value: "YZL",
  },
  {
    label: "Little Grand Rapids, Little Grand Rapids - Canada (ZGR)",
    value: "ZGR",
  },
  {
    label: "Lloydminster, Lloydminster - Canada (YLL)",
    value: "YLL",
  },
  {
    label: "London, International - Canada (YXU)",
    value: "YXU",
  },
  {
    label: "Lupin, Lupin - Canada (YWO)",
    value: "YWO",
  },
  {
    label: "Lutselke Snowdrift, Lutselke - Canada (YSG)",
    value: "YSG",
  },
  {
    label: "Lyall Harbour, Lyall Harbour Spb - Canada (YAJ)",
    value: "YAJ",
  },
  {
    label: "Lynn Lake, Lynn Lake - Canada (YYL)",
    value: "YYL",
  },
  {
    label: "Macmillan Pass, Macmillan Pass - Canada (XMP)",
    value: "XMP",
  },
  {
    label: "Makkovik, Makkovik - Canada (YMN)",
    value: "YMN",
  },
  {
    label: "Manitouwadge, Manitouwadge - Canada (YMG)",
    value: "YMG",
  },
  {
    label: "Manitowaning, Manitoulin East Muni - Canada (YEM)",
    value: "YEM",
  },
  {
    label: "Maniwaki, Maniwaki - Canada (YMW)",
    value: "YMW",
  },
  {
    label: "Mansons Landing, Spb - Canada (YMU)",
    value: "YMU",
  },
  {
    label: "Maple Bay, Spb - Canada (YAQ)",
    value: "YAQ",
  },
  {
    label: "Marathon, Marathon - Canada (YSP)",
    value: "YSP",
  },
  {
    label: "Mary River, Mary River - Canada (YMV)",
    value: "YMV",
  },
  {
    label: "Mary's Harbour, Mary's Harbour - Canada (YMH)",
    value: "YMH",
  },
  {
    label: "Masset, Masset - Canada (ZMT)",
    value: "ZMT",
  },
  {
    label: "Matagami, Matagami - Canada (YNM)",
    value: "YNM",
  },
  {
    label: "Matane, Matane - Canada (YME)",
    value: "YME",
  },
  {
    label: "Mayo, Mayo - Canada (YMA)",
    value: "YMA",
  },
  {
    label: "Meadow Lake, Meadow Lake - Canada (YLJ)",
    value: "YLJ",
  },
  {
    label: "Medicine Hat, Medicine Hat - Canada (YXH)",
    value: "YXH",
  },
  {
    label: "Merritt, Merritt - Canada (YMB)",
    value: "YMB",
  },
  {
    label: "Mile Ranch 108, South Cariboo Regional - Canada (ZMH)",
    value: "ZMH",
  },
  {
    label: "Miners Bay, Miners Bay Spb - Canada (YAV)",
    value: "YAV",
  },
  {
    label: "Mingan, Mingan - Canada (YLP)",
    value: "YLP",
  },
  {
    label: "Miramichi, Airport - Canada (YCH)",
    value: "YCH",
  },
  {
    label: "Moncton, Greater Moncton Intl - Canada (YQM)",
    value: "YQM",
  },
  {
    label: "Mont Joli, Mont-Joli - Canada (YYY)",
    value: "YYY",
  },
  {
    label: "Mont Tremblant, International - Canada (YTM)",
    value: "YTM",
  },
  {
    label: "Montagne Harbor, Montague Harbour Spb - Canada (YMF)",
    value: "YMF",
  },
  {
    label: "Montreal, Mirabel Intl - Canada (YMX)",
    value: "YMX",
  },
  {
    label: "Montreal, Pierre E. Trudeau Intl - Canada (YUL)",
    value: "YUL",
  },
  {
    label: "Montreal, St-Hubert - Canada (YHU)",
    value: "YHU",
  },
  {
    label: "Moose Jaw, C.M.Mcewen - Canada (YMJ)",
    value: "YMJ",
  },
  {
    label: "Moose Lake, Moose Lake - Canada (YAD)",
    value: "YAD",
  },
  {
    label: "Moosonee, Moosonee - Canada (YMO)",
    value: "YMO",
  },
  {
    label: "Mould Bay, Mould Bay - Canada (YMD)",
    value: "YMD",
  },
  {
    label: "Murray Bay, Charlevoix - Canada (YML)",
    value: "YML",
  },
  {
    label: "Muskoka, Muskoka - Canada (YQA)",
    value: "YQA",
  },
  {
    label: "Muskrat Dam, Muskrat Dam - Canada (MSA)",
    value: "MSA",
  },
  {
    label: "Nain, Nain - Canada (YDP)",
    value: "YDP",
  },
  {
    label: "Nakina, Nakina - Canada (YQN)",
    value: "YQN",
  },
  {
    label: "Namu, Spb - Canada (ZNU)",
    value: "ZNU",
  },
  {
    label: "Nanaimo, Airport - Canada (YCD)",
    value: "YCD",
  },
  {
    label: "Nanaimo, Harbour Spb - Canada (ZNA)",
    value: "ZNA",
  },
  {
    label: "Natashquan, Natashquan - Canada (YNA)",
    value: "YNA",
  },
  {
    label: "Natuashish, Natuashish - Canada (YNP)",
    value: "YNP",
  },
  {
    label: "Nemiscau, Nemiscau - Canada (YNS)",
    value: "YNS",
  },
  {
    label: "New Westminster, New Westminster - Canada (YBD)",
    value: "YBD",
  },
  {
    label: "Nootka Sound, Nootka Sound - Canada (YNK)",
    value: "YNK",
  },
  {
    label: "Norman Wells, Norman Wells - Canada (YVQ)",
    value: "YVQ",
  },
  {
    label: "North Battleford, Cameron Mcintosh - Canada (YQW)",
    value: "YQW",
  },
  {
    label: "North Bay, Jack Garland - Canada (YYB)",
    value: "YYB",
  },
  {
    label: "North Spirit Lake, North Spirit Lake - Canada (YNO)",
    value: "YNO",
  },
  {
    label: "Norway House, Norway House - Canada (YNE)",
    value: "YNE",
  },
  {
    label: "Obre Lake, North Of Sixty - Canada (YDW)",
    value: "YDW",
  },
  {
    label: "Ocean Falls, Spb - Canada (ZOF)",
    value: "ZOF",
  },
  {
    label: "Ogoki, Ogoki Post - Canada (YOG)",
    value: "YOG",
  },
  {
    label: "Old Crow, Old Crow - Canada (YOC)",
    value: "YOC",
  },
  {
    label: "Opapamiska Lake, Opapamiskan Lake - Canada (YBS)",
    value: "YBS",
  },
  {
    label: "Opinaca, Opinaca - Canada (YOI)",
    value: "YOI",
  },
  {
    label: "Oro Station, Lake Simcoe Regional - Canada (YLK)",
    value: "YLK",
  },
  {
    label: "Oshawa, Oshawa - Canada (YOO)",
    value: "YOO",
  },
  {
    label: "Ottawa, Fallowfield Rail Stn - Canada (ZIF)",
    value: "ZIF",
  },
  {
    label: "Ottawa, Gatineau - Canada (YND)",
    value: "YND",
  },
  {
    label: "Ottawa, Macdonald Cartier Intl - Canada (YOW)",
    value: "YOW",
  },
  {
    label: "Ottawa, Rockcliffe - Canada (YRO)",
    value: "YRO",
  },
  {
    label: "Owen Sound, Owen Sound - Canada (YOS)",
    value: "YOS",
  },
  {
    label: "Oxford House, Oxford House - Canada (YOH)",
    value: "YOH",
  },
  {
    label: "Pakuashipi, St-Augustin - Canada (YIF)",
    value: "YIF",
  },
  {
    label: "Pangnirtung, Pangnirtung - Canada (YXP)",
    value: "YXP",
  },
  {
    label: "Paradise River, Paradise River - Canada (YDE)",
    value: "YDE",
  },
  {
    label: "Parry Sound, Parry Sound Area Muni - Canada (YPD)",
    value: "YPD",
  },
  {
    label: "Paulatuk, Nora A.Ruben - Canada (YPC)",
    value: "YPC",
  },
  {
    label: "Peace River, Peace River - Canada (YPE)",
    value: "YPE",
  },
  {
    label: "Peawanuck, Peawanuck - Canada (YPO)",
    value: "YPO",
  },
  {
    label: "Pelly Bay, Kugaaruk - Canada (YBB)",
    value: "YBB",
  },
  {
    label: "Pembroke, Pembroke - Canada (YTA)",
    value: "YTA",
  },
  {
    label: "Pender Harbour, Spb - Canada (YPT)",
    value: "YPT",
  },
  {
    label: "Penticton, Regional - Canada (YYF)",
    value: "YYF",
  },
  {
    label: "Petawawa, Petawawa - Canada (YWA)",
    value: "YWA",
  },
  {
    label: "Peterborough, Peterborough - Canada (YPQ)",
    value: "YPQ",
  },
  {
    label: "Pickle Lake, Pickle Lake - Canada (YPL)",
    value: "YPL",
  },
  {
    label: "Pikangikum, Pikangikum - Canada (YPM)",
    value: "YPM",
  },
  {
    label: "Pikwitonei, Pikwitonei - Canada (PIW)",
    value: "PIW",
  },
  {
    label: "Pincher Creek, Pincher Creek - Canada (WPC)",
    value: "WPC",
  },
  {
    label: "Pine House, Pinehouse Lake - Canada (ZPO)",
    value: "ZPO",
  },
  {
    label: "Pine Point, Pine Point - Canada (YPP)",
    value: "YPP",
  },
  {
    label: "Points North Land, Points North Land - Canada (YNL)",
    value: "YNL",
  },
  {
    label: "Pond Inlet, Pond Inlet - Canada (YIO)",
    value: "YIO",
  },
  {
    label: "Poplar Hill, Poplar Hill - Canada (YHP)",
    value: "YHP",
  },
  {
    label: "Poplar River, Poplar River - Canada (XPP)",
    value: "XPP",
  },
  {
    label: "Port Alberni, Alberni Valley Rgnl - Canada (YPB)",
    value: "YPB",
  },
  {
    label: "Port Hardy, Port Hardy - Canada (YZT)",
    value: "YZT",
  },
  {
    label: "Port Hawkesbury, Port Hawkesbury - Canada (YPS)",
    value: "YPS",
  },
  {
    label: "Port Hope Simpson, Port Hope Simpson - Canada (YHA)",
    value: "YHA",
  },
  {
    label: "Port Mcneil, Port Mcneill - Canada (YMP)",
    value: "YMP",
  },
  {
    label: "Port Menier, Port Menier - Canada (YPN)",
    value: "YPN",
  },
  {
    label: "Port Simpson, Spb - Canada (YPI)",
    value: "YPI",
  },
  {
    label: "Portage La Prairie, Southport - Canada (YPG)",
    value: "YPG",
  },
  {
    label: "Postville, Postville - Canada (YSO)",
    value: "YSO",
  },
  {
    label: "Povungnituk, Puvirnituq - Canada (YPX)",
    value: "YPX",
  },
  {
    label: "Powell River, Powell Lake Spb - Canada (WPL)",
    value: "WPL",
  },
  {
    label: "Powell River, Powell River - Canada (YPW)",
    value: "YPW",
  },
  {
    label: "Prince Albert, Glass Field - Canada (YPA)",
    value: "YPA",
  },
  {
    label: "Prince George, Airport - Canada (YXS)",
    value: "YXS",
  },
  {
    label: "Prince Rupert, Airport - Canada (YPR)",
    value: "YPR",
  },
  {
    label: "Prince Rupert, Seal Cove Spb - Canada (ZSW)",
    value: "ZSW",
  },
  {
    label: "Pukatawagan, Pukatawagan - Canada (XPK)",
    value: "XPK",
  },
  {
    label: "Qikiqtarjuaq, Qikiqtarjuaq - Canada (YVM)",
    value: "YVM",
  },
  {
    label: "Quadra Island, April Point Spb - Canada (YQJ)",
    value: "YQJ",
  },
  {
    label: "Qualicum, Qualicum Beach - Canada (XQU)",
    value: "XQU",
  },
  {
    label: "Quaqtaq, Quaqtaq - Canada (YQC)",
    value: "YQC",
  },
  {
    label: "Quebec, Jean Lesage Intl - Canada (YQB)",
    value: "YQB",
  },
  {
    label: "Quebec, Quebec City Rail Stn - Canada (XLJ)",
    value: "XLJ",
  },
  {
    label: "Queen Charlotte Is, Spb - Canada (ZQS)",
    value: "ZQS",
  },
  {
    label: "Quesnel, Quesnel - Canada (YQZ)",
    value: "YQZ",
  },
  {
    label: "Rae Lakes, Rae Lakes - Canada (YRA)",
    value: "YRA",
  },
  {
    label: "Rainbow Lake, Rainbow Lake - Canada (YOP)",
    value: "YOP",
  },
  {
    label: "Rankin Inlet, Rankin Inlet - Canada (YRT)",
    value: "YRT",
  },
  {
    label: "Red Deer, Regional - Canada (YQF)",
    value: "YQF",
  },
  {
    label: "Red Lake, Red Lake - Canada (YRL)",
    value: "YRL",
  },
  {
    label: "Red Sucker Lake, Red Sucker Lake - Canada (YRS)",
    value: "YRS",
  },
  {
    label: "Refuge Cove, Spb - Canada (YRC)",
    value: "YRC",
  },
  {
    label: "Regina, International - Canada (YQR)",
    value: "YQR",
  },
  {
    label: "Repulse Bay, Repulse Bay - Canada (YUT)",
    value: "YUT",
  },
  {
    label: "Resolute, Resolute Bay - Canada (YRB)",
    value: "YRB",
  },
  {
    label: "Revelstoke, Revelstoke - Canada (YRV)",
    value: "YRV",
  },
  {
    label: "Rigolet, Rigolet - Canada (YRG)",
    value: "YRG",
  },
  {
    label: "Rimouski, Rimouski - Canada (YXK)",
    value: "YXK",
  },
  {
    label: "Rivers, Rivers - Canada (YYI)",
    value: "YYI",
  },
  {
    label: "Rivers Inlet, Spb - Canada (YRN)",
    value: "YRN",
  },
  {
    label: "Riviere Du Loup, Riviere-Du-Loup - Canada (YRI)",
    value: "YRI",
  },
  {
    label: "Roberval, Roberval - Canada (YRJ)",
    value: "YRJ",
  },
  {
    label: "Rocky Mountain Ho, Rocky Mountain Ho - Canada (YRM)",
    value: "YRM",
  },
  {
    label: "Ross River, Ross River - Canada (XRR)",
    value: "XRR",
  },
  {
    label: "Round Lake, Weagamow Lake - Canada (ZRJ)",
    value: "ZRJ",
  },
  {
    label: "Rouyn, Rouyn-Noranda - Canada (YUY)",
    value: "YUY",
  },
  {
    label: "Sable Island, Sable Island - Canada (YSA)",
    value: "YSA",
  },
  {
    label: "Sachigo Lake, Sachigo Lake - Canada (ZPB)",
    value: "ZPB",
  },
  {
    label: "Sachs Harbour, D.Nasogaluak Saaryuaq - Canada (YSY)",
    value: "YSY",
  },
  {
    label: "Saint Anthony, Saint Anthony - Canada (YAY)",
    value: "YAY",
  },
  {
    label: "Saint Catharines, Niagara District - Canada (YCM)",
    value: "YCM",
  },
  {
    label: "Saint Jean, St-Jean - Canada (YJN)",
    value: "YJN",
  },
  {
    label: "Saint John, Saint John - Canada (YSJ)",
    value: "YSJ",
  },
  {
    label: "Saint Johns, International - Canada (YYT)",
    value: "YYT",
  },
  {
    label: "Saint Leonard, Saint Leonard - Canada (YSL)",
    value: "YSL",
  },
  {
    label: "Saint Thomas, Municipal - Canada (YQS)",
    value: "YQS",
  },
  {
    label: "Sainte Therese Point, Sainte Theresa Point - Canada (YST)",
    value: "YST",
  },
  {
    label: "Salluit, Salluit - Canada (YZG)",
    value: "YZG",
  },
  {
    label: "Salmon Arm, Salmon Arm - Canada (YSN)",
    value: "YSN",
  },
  {
    label: "Sandspit, Sandspit - Canada (YZP)",
    value: "YZP",
  },
  {
    label: "Sandy Lake, Sandy Lake - Canada (ZSJ)",
    value: "ZSJ",
  },
  {
    label: "Sanikiluaq, Sanikiluaq - Canada (YSK)",
    value: "YSK",
  },
  {
    label: "Sans Souci, Sans Souci Spb - Canada (YSI)",
    value: "YSI",
  },
  {
    label: "Sarnia, Chris Hadfield - Canada (YZR)",
    value: "YZR",
  },
  {
    label: "Saskatoon, J.G.Diefenbaker Intl - Canada (YXE)",
    value: "YXE",
  },
  {
    label: "Sault Ste Marie, Sault Ste Marie - Canada (YAM)",
    value: "YAM",
  },
  {
    label: "Schefferville, Schefferville - Canada (YKL)",
    value: "YKL",
  },
  {
    label: "Sechelt, Sechelt - Canada (YHS)",
    value: "YHS",
  },
  {
    label: "Sept Iles, Sept Iles - Canada (YZV)",
    value: "YZV",
  },
  {
    label: "Shamattawa, Shamattawa - Canada (ZTM)",
    value: "ZTM",
  },
  {
    label: "Sherbrooke, Sherbrooke - Canada (YSC)",
    value: "YSC",
  },
  {
    label: "Shilo, Cfb Shilo Heliport - Canada (YLO)",
    value: "YLO",
  },
  {
    label: "Silva Bay, Silva Bay Spb - Canada (SYF)",
    value: "SYF",
  },
  {
    label: "Sioux Lookout, Sioux Lookout - Canada (YXL)",
    value: "YXL",
  },
  {
    label: "Slave Lake, Slave Lake - Canada (YZH)",
    value: "YZH",
  },
  {
    label: "Smith Falls, Montague - Canada (YSH)",
    value: "YSH",
  },
  {
    label: "Smithers, Smithers - Canada (YYD)",
    value: "YYD",
  },
  {
    label: "Snake River, Snake River - Canada (YXF)",
    value: "YXF",
  },
  {
    label: "Snap Lake, Snap Lake - Canada (YNX)",
    value: "YNX",
  },
  {
    label: "Snare Lake, Wekweeti - Canada (YFJ)",
    value: "YFJ",
  },
  {
    label: "South Indian Lake, South Indian Lake - Canada (XSI)",
    value: "XSI",
  },
  {
    label: "South Trout Lake, South Trout Lake - Canada (ZFL)",
    value: "ZFL",
  },
  {
    label: "Spring Island, Spring Island - Canada (YSQ)",
    value: "YSQ",
  },
  {
    label: "Squamish, Squamish - Canada (YSE)",
    value: "YSE",
  },
  {
    label: "Squirrel Cove, Squirrel Cove - Canada (YSZ)",
    value: "YSZ",
  },
  {
    label: "Stephenville, International - Canada (YJT)",
    value: "YJT",
  },
  {
    label: "Stewart, Stewart - Canada (ZST)",
    value: "ZST",
  },
  {
    label: "Stony Rapids, Stony Rapids - Canada (YSF)",
    value: "YSF",
  },
  {
    label: "Stuart Island, Airstrip - Canada (YRR)",
    value: "YRR",
  },
  {
    label: "Sudbury, Junction Rail St - Canada (XDY)",
    value: "XDY",
  },
  {
    label: "Sudbury, Sudbury - Canada (YSB)",
    value: "YSB",
  },
  {
    label: "Suffield, Cfb Heliport - Canada (YSD)",
    value: "YSD",
  },
  {
    label: "Sullivan Bay, Spb - Canada (YTG)",
    value: "YTG",
  },
  {
    label: "Summer Beaver, Summer Beaver - Canada (SUR)",
    value: "SUR",
  },
  {
    label: "Summerside, Summerside - Canada (YSU)",
    value: "YSU",
  },
  {
    label: "Swan River, Swan River - Canada (ZJN)",
    value: "ZJN",
  },
  {
    label: "Swift Current, Swift Current - Canada (YYN)",
    value: "YYN",
  },
  {
    label: "Sydney, J.A. Douglas Mccurdy - Canada (YQY)",
    value: "YQY",
  },
  {
    label: "Tadoule Lake, Tadoule Lake - Canada (XTL)",
    value: "XTL",
  },
  {
    label: "Tahsis, Spb - Canada (ZTS)",
    value: "ZTS",
  },
  {
    label: "Taloyoak, Taloyoak - Canada (YYH)",
    value: "YYH",
  },
  {
    label: "Taltheilei, Taltheilei - Canada (GSL)",
    value: "GSL",
  },
  {
    label: "Tasiujuaq, Tasiujaq - Canada (YTQ)",
    value: "YTQ",
  },
  {
    label: "Tasu, Spb - Canada (YTU)",
    value: "YTU",
  },
  {
    label: "Telegraph Creek, Telegraph Creek - Canada (YTX)",
    value: "YTX",
  },
  {
    label: "Telegraph Harbour, Telegraph Harbour Spb - Canada (YBQ)",
    value: "YBQ",
  },
  {
    label: "Terrace, Northwest Regional - Canada (YXT)",
    value: "YXT",
  },
  {
    label: "Terrace Bay, Terrace Bay - Canada (YTJ)",
    value: "YTJ",
  },
  {
    label: "Teslin, Teslin - Canada (YZW)",
    value: "YZW",
  },
  {
    label: "Tete A La Baleine, Tete A La Baleine - Canada (ZTB)",
    value: "ZTB",
  },
  {
    label: "The Pas, Airport - Canada (YQD)",
    value: "YQD",
  },
  {
    label: "Thicket Portage, Thicket Portage - Canada (YTD)",
    value: "YTD",
  },
  {
    label: "Thompson, Thompson - Canada (YTH)",
    value: "YTH",
  },
  {
    label: "Thunder Bay, International - Canada (YQT)",
    value: "YQT",
  },
  {
    label: "Timmins, Victor M.Power - Canada (YTS)",
    value: "YTS",
  },
  {
    label: "Tisdale, Tisdale - Canada (YTT)",
    value: "YTT",
  },
  {
    label: "Tofino, Harbour Spb - Canada (YTP)",
    value: "YTP",
  },
  {
    label: "Tofino, Long Beach - Canada (YAZ)",
    value: "YAZ",
  },
  {
    label: "Toronto, Billy Bishop City A - Canada (YTZ)",
    value: "YTZ",
  },
  {
    label: "Toronto, Buttonville Municipal - Canada (YKZ)",
    value: "YKZ",
  },
  {
    label: "Toronto, Hamilton Jc Munro Intl - Canada (YHM)",
    value: "YHM",
  },
  {
    label: "Toronto, Lester B. Pearson Intl - Canada (YYZ)",
    value: "YYZ",
  },
  {
    label: "Toronto, Region Of Waterloo Int - Canada (YKF)",
    value: "YKF",
  },
  {
    label: "Trail, Trail - Canada (YZZ)",
    value: "YZZ",
  },
  {
    label: "Trenton, Trenton - Canada (YTR)",
    value: "YTR",
  },
  {
    label: "Trois Rivieres, Trois Rivieres - Canada (YRQ)",
    value: "YRQ",
  },
  {
    label: "Tuktoyaktuk, James Gruben - Canada (YUB)",
    value: "YUB",
  },
  {
    label: "Tulita Fort Norman, Tulita - Canada (ZFN)",
    value: "ZFN",
  },
  {
    label: "Tumbler Ridge, Tumbler Ridge - Canada (TUX)",
    value: "TUX",
  },
  {
    label: "Tungsten, Cantung - Canada (TNS)",
    value: "TNS",
  },
  {
    label: "Ulukhaktok, Ulukhaktok - Canada (YHI)",
    value: "YHI",
  },
  {
    label: "Umiujaq, Umiujaq - Canada (YUD)",
    value: "YUD",
  },
  {
    label: "Uranium City, Uranium City - Canada (YBE)",
    value: "YBE",
  },
  {
    label: "Val D'or, Val D'or - Canada (YVO)",
    value: "YVO",
  },
  {
    label: "Valcartier, Cfb Heliport - Canada (YOY)",
    value: "YOY",
  },
  {
    label: "Vancouver, Boundary Bay - Canada (YDT)",
    value: "YDT",
  },
  {
    label: "Vancouver, Harbour Spb - Canada (CXH)",
    value: "CXH",
  },
  {
    label: "Vancouver, International - Canada (YVR)",
    value: "YVR",
  },
  {
    label: "Vancouver, Pacif. Central Rail St - Canada (XEA)",
    value: "XEA",
  },
  {
    label: "Vermilion, Vermilion - Canada (YVG)",
    value: "YVG",
  },
  {
    label: "Vernon, Regional - Canada (YVE)",
    value: "YVE",
  },
  {
    label: "Victoria, Inner Harbour Spb - Canada (YWH)",
    value: "YWH",
  },
  {
    label: "Victoria, Victoria Intl - Canada (YYJ)",
    value: "YYJ",
  },
  {
    label: "Wabush, Wabush - Canada (YWK)",
    value: "YWK",
  },
  {
    label: "Waskaganish, Waskaganish - Canada (YKQ)",
    value: "YKQ",
  },
  {
    label: "Watson Lake, Watson Lake - Canada (YQH)",
    value: "YQH",
  },
  {
    label: "Wawa, Wawa - Canada (YXZ)",
    value: "YXZ",
  },
  {
    label: "Webequie, Webequie - Canada (YWP)",
    value: "YWP",
  },
  {
    label: "Wemindji, Wemindji - Canada (YNC)",
    value: "YNC",
  },
  {
    label: "Wha Ti Lac La Martre, Whati - Canada (YLE)",
    value: "YLE",
  },
  {
    label: "Whale Cove, Whale Cove - Canada (YXN)",
    value: "YXN",
  },
  {
    label: "Whistler, Green Lake Spb - Canada (YWS)",
    value: "YWS",
  },
  {
    label: "White River, Spb - Canada (YWR)",
    value: "YWR",
  },
  {
    label: "Whitecourt, Whitecourt - Canada (YZU)",
    value: "YZU",
  },
  {
    label: "Whitehorse, Erik Nielsen Intl - Canada (YXY)",
    value: "YXY",
  },
  {
    label: "Wiarton, Wiarton - Canada (YVV)",
    value: "YVV",
  },
  {
    label: "Williams Harbour, Williams Harbour - Canada (YWM)",
    value: "YWM",
  },
  {
    label: "Williams Lake, Williams Lake - Canada (YWL)",
    value: "YWL",
  },
  {
    label: "Windsor, Airport - Canada (YQG)",
    value: "YQG",
  },
  {
    label: "Winisk, Winisk - Canada (YWN)",
    value: "YWN",
  },
  {
    label: "Winnipeg, J A Richardson Intl - Canada (YWG)",
    value: "YWG",
  },
  {
    label: "Wollaston Lake, Wollaston Lake - Canada (ZWL)",
    value: "ZWL",
  },
  {
    label: "Wrigley, Wrigley - Canada (YWY)",
    value: "YWY",
  },
  {
    label: "Wunnummin Lake, Wunnummin Lake - Canada (WNN)",
    value: "WNN",
  },
  {
    label: "Yarmouth, Yarmouth - Canada (YQI)",
    value: "YQI",
  },
  {
    label: "Yellowknife, Yellowknife - Canada (YZF)",
    value: "YZF",
  },
  {
    label: "York Landing, York Landing - Canada (ZAC)",
    value: "ZAC",
  },
  {
    label: "Yorkton, Municipal - Canada (YQV)",
    value: "YQV",
  },
  {
    label: "Boa Vista Island, Rabil - Cape Verde-Republic Of (BVC)",
    value: "BVC",
  },
  {
    label: "Brava Island, Esperadinha - Cape Verde-Republic Of (BVR)",
    value: "BVR",
  },
  {
    label: "Maio Island, Maio Island - Cape Verde-Republic Of (MMO)",
    value: "MMO",
  },
  {
    label: "Mosteiros, Mosteiros - Cape Verde-Republic Of (MTI)",
    value: "MTI",
  },
  {
    label: "Praia, Praia Intl - Cape Verde-Republic Of (RAI)",
    value: "RAI",
  },
  {
    label: "Sal Island, Amilcar Cabral Intl - Cape Verde-Republic Of (SID)",
    value: "SID",
  },
  {
    label: "Santo Antao Island, Agostinho Neto - Cape Verde-Republic Of (NTO)",
    value: "NTO",
  },
  {
    label: "Sao Filipe, Sao Filipe - Cape Verde-Republic Of (SFL)",
    value: "SFL",
  },
  {
    label: "Sao Nicolau Island, Preguica - Cape Verde-Republic Of (SNE)",
    value: "SNE",
  },
  {
    label: "Sao Vicente Island, Sao Pedro - Cape Verde-Republic Of (VXE)",
    value: "VXE",
  },
  {
    label: "Cayman Brac Is, C. Kirkconnell Intl - Cayman Islands (CYB)",
    value: "CYB",
  },
  {
    label: "Grand Cayman Is, Owen Roberts Intl - Cayman Islands (GCM)",
    value: "GCM",
  },
  {
    label: "Little Cayman, Edward Bodden - Cayman Islands (LYB)",
    value: "LYB",
  },
  {
    label: "Bakouma, Bakouma - Central African Republic (BMF)",
    value: "BMF",
  },
  {
    label: "Bambari, Bambari - Central African Republic (BBY)",
    value: "BBY",
  },
  {
    label: "Bangassou, Bangassou - Central African Republic (BGU)",
    value: "BGU",
  },
  {
    label: "Bangui, Mpoko Intl - Central African Republic (BGF)",
    value: "BGF",
  },
  {
    label: "Batangafo, Batangafo - Central African Republic (BTG)",
    value: "BTG",
  },
  {
    label: "Berberati, Berberati - Central African Republic (BBT)",
    value: "BBT",
  },
  {
    label: "Birao, Birao - Central African Republic (IRO)",
    value: "IRO",
  },
  {
    label: "Bossangoa, Bossangoa - Central African Republic (BSN)",
    value: "BSN",
  },
  {
    label: "Bouar, Bouar - Central African Republic (BOP)",
    value: "BOP",
  },
  {
    label: "Bouca, Bouca - Central African Republic (BCF)",
    value: "BCF",
  },
  {
    label: "Bozoum, Bozoum - Central African Republic (BOZ)",
    value: "BOZ",
  },
  {
    label: "Bria, Bria - Central African Republic (BIV)",
    value: "BIV",
  },
  {
    label: "Carnot, Carnot - Central African Republic (CRF)",
    value: "CRF",
  },
  {
    label: "Gounda, Gounda - Central African Republic (GDA)",
    value: "GDA",
  },
  {
    label: "Kawadjia, Kawadjia - Central African Republic (KWD)",
    value: "KWD",
  },
  {
    label: "Koumala, Koumala - Central African Republic (KOL)",
    value: "KOL",
  },
  {
    label: "Melle, Gordil - Central African Republic (GDI)",
    value: "GDI",
  },
  {
    label: "Ndele, Ndele - Central African Republic (NDL)",
    value: "NDL",
  },
  {
    label: "Obo, Mboki - Central African Republic (MKI)",
    value: "MKI",
  },
  {
    label: "Ouadda, Ouadda - Central African Republic (ODA)",
    value: "ODA",
  },
  {
    label: "Ouanda Djalle, Ouanda Djalle - Central African Republic (ODJ)",
    value: "ODJ",
  },
  {
    label: "Rafai, Rafai - Central African Republic (RFA)",
    value: "RFA",
  },
  {
    label: "Yalinga, Yalinga - Central African Republic (AIG)",
    value: "AIG",
  },
  {
    label: "Zemio, Zemio - Central African Republic (IMO)",
    value: "IMO",
  },
  {
    label: "Abeche, Abeche - Chad (AEH)",
    value: "AEH",
  },
  {
    label: "Abou Deia, Abou Deia - Chad (AOD)",
    value: "AOD",
  },
  {
    label: "Am Timan, Am Timan - Chad (AMC)",
    value: "AMC",
  },
  {
    label: "Ati, Ati - Chad (ATV)",
    value: "ATV",
  },
  {
    label: "Bokoro, Bokoro - Chad (BKR)",
    value: "BKR",
  },
  {
    label: "Bol, Berim - Chad (OTC)",
    value: "OTC",
  },
  {
    label: "Bongor, Bongor - Chad (OGR)",
    value: "OGR",
  },
  {
    label: "Bousso, Bousso - Chad (OUT)",
    value: "OUT",
  },
  {
    label: "Faya-Largeau, Faya-Largeau - Chad (FYT)",
    value: "FYT",
  },
  {
    label: "Lai, Lai - Chad (LTC)",
    value: "LTC",
  },
  {
    label: "Mao, Mao - Chad (AMO)",
    value: "AMO",
  },
  {
    label: "Melfi, Melfi - Chad (MEF)",
    value: "MEF",
  },
  {
    label: "Mongo, Mongo - Chad (MVO)",
    value: "MVO",
  },
  {
    label: "Moundou, Moundou - Chad (MQQ)",
    value: "MQQ",
  },
  {
    label: "N'djamena, Hassan Djamous Intl - Chad (NDJ)",
    value: "NDJ",
  },
  {
    label: "Oum Hadjer, Oum Hadjer - Chad (OUM)",
    value: "OUM",
  },
  {
    label: "Pala, Pala - Chad (PLF)",
    value: "PLF",
  },
  {
    label: "Sarh, Sarh - Chad (SRH)",
    value: "SRH",
  },
  {
    label: "Zakouma, Zakouma - Chad (AKM)",
    value: "AKM",
  },
  {
    label: "Alto Palena, Alto Palena - Chile (WAP)",
    value: "WAP",
  },
  {
    label: "Ancud, Pupelde - Chile (ZUD)",
    value: "ZUD",
  },
  {
    label: "Antofagasta, Cerro Moreno - Chile (ANF)",
    value: "ANF",
  },
  {
    label: "Arica, Chacalluta - Chile (ARI)",
    value: "ARI",
  },
  {
    label: "Balmaceda, Balmaceda - Chile (BBA)",
    value: "BBA",
  },
  {
    label: "Calama, El Loa - Chile (CJC)",
    value: "CJC",
  },
  {
    label: "Castro, Gamboa - Chile (WCA)",
    value: "WCA",
  },
  {
    label: "Castro, Mocopulli - Chile (MHC)",
    value: "MHC",
  },
  {
    label: "Cerro Sombrero, Franco Bianco - Chile (SMB)",
    value: "SMB",
  },
  {
    label: "Chaiten, Chaiten - Chile (WCH)",
    value: "WCH",
  },
  {
    label: "Chanaral, Chanaral - Chile (CNR)",
    value: "CNR",
  },
  {
    label: "Chile Chico, Chile Chico - Chile (CCH)",
    value: "CCH",
  },
  {
    label: "Chillan, General B. O Higgins - Chile (YAI)",
    value: "YAI",
  },
  {
    label: "Cochrane, Cochrane - Chile (LGR)",
    value: "LGR",
  },
  {
    label: "Concepcion, Carriel Sur - Chile (CCP)",
    value: "CCP",
  },
  {
    label: "Copiapo, Desierto De Atacama - Chile (CPO)",
    value: "CPO",
  },
  {
    label: "Coquimbo, Tambillos - Chile (COW)",
    value: "COW",
  },
  {
    label: "Coyhaique, Teniente Vidal - Chile (GXQ)",
    value: "GXQ",
  },
  {
    label: "Easter Island, Mataveri Intl - Chile (IPC)",
    value: "IPC",
  },
  {
    label: "El Salvador, Ricardo Garcia Posada - Chile (ESR)",
    value: "ESR",
  },
  {
    label: "Frutillar, Frutillar - Chile (FRT)",
    value: "FRT",
  },
  {
    label: "Futaleufu, Futaleufu - Chile (FFU)",
    value: "FFU",
  },
  {
    label: "Iquique, Diego Aracena - Chile (IQQ)",
    value: "IQQ",
  },
  {
    label: "La Serena, La Florida - Chile (LSC)",
    value: "LSC",
  },
  {
    label: "Los Andes, San Rafael - Chile (LOB)",
    value: "LOB",
  },
  {
    label: "Los Angeles, Maria Dolores - Chile (LSQ)",
    value: "LSQ",
  },
  {
    label: "Osorno, Canal Bajo - Chile (ZOS)",
    value: "ZOS",
  },
  {
    label: "Ovalle, El Tuqui - Chile (OVL)",
    value: "OVL",
  },
  {
    label: "Porvenir, Captain F.Martinez - Chile (WPR)",
    value: "WPR",
  },
  {
    label: "Pucon, Pucon - Chile (ZPC)",
    value: "ZPC",
  },
  {
    label: "Puerto Aisen, Cabo Juan Roman - Chile (WPA)",
    value: "WPA",
  },
  {
    label: "Puerto Montt, El Tepual - Chile (PMC)",
    value: "PMC",
  },
  {
    label: "Puerto Natales, Teniente - Chile (PNT)",
    value: "PNT",
  },
  {
    label: "Puerto Varas, El Mirador - Chile (PUX)",
    value: "PUX",
  },
  {
    label: "Puerto Williams, Guardiamarina Zanartu - Chile (WPU)",
    value: "WPU",
  },
  {
    label: "Punta Arenas, C.Ibanez Del Campo - Chile (PUQ)",
    value: "PUQ",
  },
  {
    label: "Santiago - Chile (SCL)",
    value: "SCL",
  },
  {
    label: "Santiago, A Merino Benitez - Chile (SCL)",
    value: "SCL",
  },
  {
    label: "Santiago, Los Cerrillos - Chile (ULC)",
    value: "ULC",
  },
  {
    label: "Talca, Panguilemo - Chile (TLX)",
    value: "TLX",
  },
  {
    label: "Taltal, Las Breas - Chile (TTC)",
    value: "TTC",
  },
  {
    label: "Temuco, La Araucania - Chile (ZCO)",
    value: "ZCO",
  },
  {
    label: "Temuco, Maquehue - Chile (PZS)",
    value: "PZS",
  },
  {
    label: "Tocopilla, Barriles - Chile (TOQ)",
    value: "TOQ",
  },
  {
    label: "Valdivia, Pichoy - Chile (ZAL)",
    value: "ZAL",
  },
  {
    label: "Vallenar, Vallenar - Chile (VLR)",
    value: "VLR",
  },
  {
    label: "Valparaiso, Rodelillo - Chile (VAP)",
    value: "VAP",
  },
  {
    label: "Victoria, Victoria - Chile (ZIC)",
    value: "ZIC",
  },
  {
    label: "Vina Del Mar, Vina Del Mar - Chile (KNA)",
    value: "KNA",
  },
  {
    label: "Anqing - China (AQG)",
    value: "AQG",
  },
  {
    label: "Anqing, Tianzhushan - China (AQG)",
    value: "AQG",
  },
  {
    label: "Chizhou - China (JUH)",
    value: "JUH",
  },
  {
    label: "Chizhou, Jiuhuashan - China (JUH)",
    value: "JUH",
  },
  {
    label: "Fuyang - China (FUG)",
    value: "FUG",
  },
  {
    label: "Fuyang, Xiguan - China (FUG)",
    value: "FUG",
  },
  {
    label: "Hefei - China (HFE)",
    value: "HFE",
  },
  {
    label: "Hefei, Xinqiao Intl - China (HFE)",
    value: "HFE",
  },
  {
    label: "Huangshan - China (TXN)",
    value: "TXN",
  },
  {
    label: "Huangshan, Tunxi Intl - China (TXN)",
    value: "TXN",
  },
  {
    label: "Beijing - China (BJS)",
    value: "BJS",
  },
  {
    label: "Beijing, Capital Intl - China (PEK)",
    value: "PEK",
  },
  {
    label: "Beijing, Nanyuan Apt - China (NAY)",
    value: "NAY",
  },
  {
    label: "Chongqing - China (CKG)",
    value: "CKG",
  },
  {
    label: "Chongqing, Jiangbei Intl - China (CKG)",
    value: "CKG",
  },
  {
    label: "Qianjiang - China (JIQ)",
    value: "JIQ",
  },
  {
    label: "Qianjiang, Wulingshan - China (JIQ)",
    value: "JIQ",
  },
  {
    label: "Wanzhou - China (WXN)",
    value: "WXN",
  },
  {
    label: "Wanzhou, Wuqiao - China (WXN)",
    value: "WXN",
  },
  {
    label: "Fuzhou - China (FOC)",
    value: "FOC",
  },
  {
    label: "Fuzhou, Changle Intl - China (FOC)",
    value: "FOC",
  },
  {
    label: "Longyan - China (LCX)",
    value: "LCX",
  },
  {
    label: "Longyan, Guanzhishan - China (LCX)",
    value: "LCX",
  },
  {
    label: "Quanzhou - China (JJN)",
    value: "JJN",
  },
  {
    label: "Quanzhou, Jinjiang - China (JJN)",
    value: "JJN",
  },
  {
    label: "Wuyishan - China (WUS)",
    value: "WUS",
  },
  {
    label: "Wuyishan, Wuyishan - China (WUS)",
    value: "WUS",
  },
  {
    label: "Xiamen - China (XMN)",
    value: "XMN",
  },
  {
    label: "Xiamen, Gaoqi Intl - China (XMN)",
    value: "XMN",
  },
  {
    label: "Dunhuang - China (DNH)",
    value: "DNH",
  },
  {
    label: "Dunhuang, Dunhuang - China (DNH)",
    value: "DNH",
  },
  {
    label: "Jiayuguan - China (JGN)",
    value: "JGN",
  },
  {
    label: "Jiayuguan, Jiayuguan - China (JGN)",
    value: "JGN",
  },
  {
    label: "Jinchang - China (JIC)",
    value: "JIC",
  },
  {
    label: "Jinchang, Jinchuan - China (JIC)",
    value: "JIC",
  },
  {
    label: "Lanzhou - China (LHW)",
    value: "LHW",
  },
  {
    label: "Lanzhou, Zhongchuan - China (LHW)",
    value: "LHW",
  },
  {
    label: "Qingyang - China (IQN)",
    value: "IQN",
  },
  {
    label: "Qingyang, Qingyang - China (IQN)",
    value: "IQN",
  },
  {
    label: "Tianshui - China (THQ)",
    value: "THQ",
  },
  {
    label: "Tianshui, Maijishan - China (THQ)",
    value: "THQ",
  },
  {
    label: "Xiahe - China (GXH)",
    value: "GXH",
  },
  {
    label: "Xiahe, Gannan Xiahe - China (GXH)",
    value: "GXH",
  },
  {
    label: "Zhangye - China (YZY)",
    value: "YZY",
  },
  {
    label: "Zhangye, Ganzhou - China (YZY)",
    value: "YZY",
  },
  {
    label: "Foshan - China (FUO)",
    value: "FUO",
  },
  {
    label: "Foshan, Shadi - China (FUO)",
    value: "FUO",
  },
  {
    label: "Guangzhou - China (CAN)",
    value: "CAN",
  },
  {
    label: "Guangzhou, Baiyun Intl - China (CAN)",
    value: "CAN",
  },
  {
    label: "Huizhou - China (HUZ)",
    value: "HUZ",
  },
  {
    label: "Huizhou, Huizhou - China (HUZ)",
    value: "HUZ",
  },
  {
    label: "Meixian - China (MXZ)",
    value: "MXZ",
  },
  {
    label: "Meixian, Meixian - China (MXZ)",
    value: "MXZ",
  },
  {
    label: "Shantou - China (SWA)",
    value: "SWA",
  },
  {
    label: "Shantou, Jieyang Chaoshan - China (SWA)",
    value: "SWA",
  },
  {
    label: "Shaoguan - China (HSC)",
    value: "HSC",
  },
  {
    label: "Shaoguan, Guitou - China (HSC)",
    value: "HSC",
  },
  {
    label: "Shenzhen - China (SZX)",
    value: "SZX",
  },
  {
    label: "Shenzhen, Bao An Intl - China (SZX)",
    value: "SZX",
  },
  {
    label: "Zhanjiang - China (ZHA)",
    value: "ZHA",
  },
  {
    label: "Zhanjiang, Zhanjiang - China (ZHA)",
    value: "ZHA",
  },
  {
    label: "Zhuhai - China (ZUH)",
    value: "ZUH",
  },
  {
    label: "Zhuhai, Sanzao Intl - China (ZUH)",
    value: "ZUH",
  },
  {
    label: "Baise - China (AEB)",
    value: "AEB",
  },
  {
    label: "Baise, Youjiang - China (AEB)",
    value: "AEB",
  },
  {
    label: "Beihai - China (BHY)",
    value: "BHY",
  },
  {
    label: "Beihai, Fucheng - China (BHY)",
    value: "BHY",
  },
  {
    label: "Guilin - China (KWL)",
    value: "KWL",
  },
  {
    label: "Guilin, Liangjiang Intl - China (KWL)",
    value: "KWL",
  },
  {
    label: "Hechi - China (HCJ)",
    value: "HCJ",
  },
  {
    label: "Hechi, Jin Cheng Jiang - China (HCJ)",
    value: "HCJ",
  },
  {
    label: "Liuzhou - China (LZH)",
    value: "LZH",
  },
  {
    label: "Liuzhou, Bailian - China (LZH)",
    value: "LZH",
  },
  {
    label: "Nanning - China (NNG)",
    value: "NNG",
  },
  {
    label: "Nanning, Wuxu Intl - China (NNG)",
    value: "NNG",
  },
  {
    label: "Wuzhou - China (WUZ)",
    value: "WUZ",
  },
  {
    label: "Wuzhou, Changzhoudao - China (WUZ)",
    value: "WUZ",
  },
  {
    label: "Anshun - China (AVA)",
    value: "AVA",
  },
  {
    label: "Anshun, Hunagguoshu - China (AVA)",
    value: "AVA",
  },
  {
    label: "Bijie - China (BFJ)",
    value: "BFJ",
  },
  {
    label: "Bijie, Bijie - China (BFJ)",
    value: "BFJ",
  },
  {
    label: "Guiyang - China (KWE)",
    value: "KWE",
  },
  {
    label: "Guiyang, Longdongbao Intl - China (KWE)",
    value: "KWE",
  },
  {
    label: "Huang Ping - China (KJH)",
    value: "KJH",
  },
  {
    label: "Huang Ping, Kai Li - China (KJH)",
    value: "KJH",
  },
  {
    label: "Libo - China (LLB)",
    value: "LLB",
  },
  {
    label: "Libo, Libo - China (LLB)",
    value: "LLB",
  },
  {
    label: "Liping - China (HZH)",
    value: "HZH",
  },
  {
    label: "Liping, Liping - China (HZH)",
    value: "HZH",
  },
  {
    label: "Liupanshui - China (LPF)",
    value: "LPF",
  },
  {
    label: "Liupanshui, Yue Zhao - China (LPF)",
    value: "LPF",
  },
  {
    label: "Tongren - China (TEN)",
    value: "TEN",
  },
  {
    label: "Tongren, Fenhuang - China (TEN)",
    value: "TEN",
  },
  {
    label: "Xingyi - China (ACX)",
    value: "ACX",
  },
  {
    label: "Xingyi, Xingyi - China (ACX)",
    value: "ACX",
  },
  {
    label: "Haikou - China (HAK)",
    value: "HAK",
  },
  {
    label: "Haikou, Meilan Intl - China (HAK)",
    value: "HAK",
  },
  {
    label: "Sanya - China (SYX)",
    value: "SYX",
  },
  {
    label: "Sanya, Phoenix Intl - China (SYX)",
    value: "SYX",
  },
  {
    label: "Handan - China (HDG)",
    value: "HDG",
  },
  {
    label: "Handan, Handan - China (HDG)",
    value: "HDG",
  },
  {
    label: "Shijiazhuang - China (SJW)",
    value: "SJW",
  },
  {
    label: "Shijiazhuang, Zhengding Intl - China (SJW)",
    value: "SJW",
  },
  {
    label: "Tangshan - China (TVS)",
    value: "TVS",
  },
  {
    label: "Tangshan, Sannuhe - China (TVS)",
    value: "TVS",
  },
  {
    label: "Xingtai - China (XNT)",
    value: "XNT",
  },
  {
    label: "Xingtai, Dalian - China (XNT)",
    value: "XNT",
  },
  {
    label: "Zhangjiakou - China (ZQZ)",
    value: "ZQZ",
  },
  {
    label: "Zhangjiakou, Zhangjiakou - China (ZQZ)",
    value: "ZQZ",
  },
  {
    label: "Daqing - China (DQA)",
    value: "DQA",
  },
  {
    label: "Daqing, Sartu - China (DQA)",
    value: "DQA",
  },
  {
    label: "Fuyuan - China (FYJ)",
    value: "FYJ",
  },
  {
    label: "Fuyuan, Fuyuan - China (FYJ)",
    value: "FYJ",
  },
  {
    label: "Harbin - China (HRB)",
    value: "HRB",
  },
  {
    label: "Harbin, Taiping Intl - China (HRB)",
    value: "HRB",
  },
  {
    label: "Heihe - China (HEK)",
    value: "HEK",
  },
  {
    label: "Heihe, Heihe - China (HEK)",
    value: "HEK",
  },
  {
    label: "Jiagedaqi - China (JGD)",
    value: "JGD",
  },
  {
    label: "Jiagedaqi, Jiagedaqi - China (JGD)",
    value: "JGD",
  },
  {
    label: "Jiamusi - China (JMU)",
    value: "JMU",
  },
  {
    label: "Jiamusi, Dongjiao - China (JMU)",
    value: "JMU",
  },
  {
    label: "Jixi - China (JXA)",
    value: "JXA",
  },
  {
    label: "Jixi, Xingkaihu - China (JXA)",
    value: "JXA",
  },
  {
    label: "Mohe - China (OHE)",
    value: "OHE",
  },
  {
    label: "Mohe, Gulian - China (OHE)",
    value: "OHE",
  },
  {
    label: "Mudanjiang, Hailang - China (MDG)",
    value: "MDG",
  },
  {
    label: "Mudanjiang - China (MDG)",
    value: "MDG",
  },
  {
    label: "Qiqihar, Sanjiazi - China (NDG)",
    value: "NDG",
  },
  {
    label: "Qiqihar - China (NDG)",
    value: "NDG",
  },
  {
    label: "Wudalianchi, Dedu - China (DTU)",
    value: "DTU",
  },
  {
    label: "Wudalianchi - China (DTU)",
    value: "DTU",
  },
  {
    label: "Yichun - China (LDS)",
    value: "LDS",
  },
  {
    label: "Yichun, Lindu - China (LDS)",
    value: "LDS",
  },
  {
    label: "Luoyang - China (LYA)",
    value: "LYA",
  },
  {
    label: "Luoyang, Beijiao - China (LYA)",
    value: "LYA",
  },
  {
    label: "Nanyang - China (NNY)",
    value: "NNY",
  },
  {
    label: "Nanyang, Jiangying - China (NNY)",
    value: "NNY",
  },
  {
    label: "Zhengzhou - China (CGO)",
    value: "CGO",
  },
  {
    label: "Zhengzhou, Xinzheng Intl - China (CGO)",
    value: "CGO",
  },
  {
    label: "Zhengzhou, Shangjie - China (HSJ)",
    value: "HSJ",
  },
  {
    label: "Hong Kong - Hong Kong (HKG)",
    value: "HKG",
  },
  {
    label: "Hong Kong, International - Hong Kong (HKG)",
    value: "HKG",
  },
  {
    label: "Enshi - China (ENH)",
    value: "ENH",
  },
  {
    label: "Enshi, Xujiaping - China (ENH)",
    value: "ENH",
  },
  {
    label: "Shennongjia - China (HPG)",
    value: "HPG",
  },
  {
    label: "Shennongjia, Hongping - China (HPG)",
    value: "HPG",
  },
  {
    label: "Shiyan - China (WDS)",
    value: "WDS",
  },
  {
    label: "Shiyan, Wudangshan - China (WDS)",
    value: "WDS",
  },
  {
    label: "Wuhai - China (WUA)",
    value: "WUA",
  },
  {
    label: "Wuhai, Wuhai - China (WUA)",
    value: "WUA",
  },
  {
    label: "Xiangyang - China (XFN)",
    value: "XFN",
  },
  {
    label: "Xiangyang, Liuji - China (XFN)",
    value: "XFN",
  },
  {
    label: "Yichang - China (YIH)",
    value: "YIH",
  },
  {
    label: "Yichang, Sanxia - China (YIH)",
    value: "YIH",
  },
  {
    label: "Changde - China (CGD)",
    value: "CGD",
  },
  {
    label: "Changde, Taohuayuan - China (CGD)",
    value: "CGD",
  },
  {
    label: "Changsha - China (CSX)",
    value: "CSX",
  },
  {
    label: "Changsha, Huanghua International - China (CSX)",
    value: "CSX",
  },
  {
    label: "Hengyang - China (HNY)",
    value: "HNY",
  },
  {
    label: "Hengyang, Bajialing - China (HNY)",
    value: "HNY",
  },
  {
    label: "Huaihua - China (HJJ)",
    value: "HJJ",
  },
  {
    label: "Huaihua, Zhijiang - China (HJJ)",
    value: "HJJ",
  },
  {
    label: "Yongzhou - China (LLF)",
    value: "LLF",
  },
  {
    label: "Yongzhou, Ling Ling - China (LLF)",
    value: "LLF",
  },
  {
    label: "Zhangjiajie - China (DYG)",
    value: "DYG",
  },
  {
    label: "Zhangjiajie, Hehua - China (DYG)",
    value: "DYG",
  },
  {
    label: "Alxa Left Banner - China (AXF)",
    value: "AXF",
  },
  {
    label: "Alxa Left Banner, Alxa Left Banner - China (AXF)",
    value: "AXF",
  },
  {
    label: "Alxa Right Banner - China (RHT)",
    value: "RHT",
  },
  {
    label: "Alxa Right Banner, Alxa Right Banner - China (RHT)",
    value: "RHT",
  },
  {
    label: "Aershan - China (YIE)",
    value: "YIE",
  },
  {
    label: "Aershan, Yiershi Airport - China (YIE)",
    value: "YIE",
  },
  {
    label: "Baotou - China (BAV)",
    value: "BAV",
  },
  {
    label: "Baotou, Erliban - China (BAV)",
    value: "BAV",
  },
  {
    label: "Bayannur - China (RLK)",
    value: "RLK",
  },
  {
    label: "Bayannur, Tianjitai - China (RLK)",
    value: "RLK",
  },
  {
    label: "Chifeng - China (CIF)",
    value: "CIF",
  },
  {
    label: "Chifeng, Yulong - China (CIF)",
    value: "CIF",
  },
  {
    label: "Ejina Banner - China (EJN)",
    value: "EJN",
  },
  {
    label: "Ejina Banner, Taolai - China (EJN)",
    value: "EJN",
  },
  {
    label: "Erenhot - China (ERL)",
    value: "ERL",
  },
  {
    label: "Erenhot, Saiwusu Intl - China (ERL)",
    value: "ERL",
  },
  {
    label: "Hailar - China (HLD)",
    value: "HLD",
  },
  {
    label: "Hailar, Dongshan - China (HLD)",
    value: "HLD",
  },
  {
    label: "Hohhot - China (HET)",
    value: "HET",
  },
  {
    label: "Hohhot, Baita Intl - China (HET)",
    value: "HET",
  },
  {
    label: "Huolinguole - China (HUO)",
    value: "HUO",
  },
  {
    label: "Huolinguole, Huolinhe - China (HUO)",
    value: "HUO",
  },
  {
    label: "Manzhouli - China (NZH)",
    value: "NZH",
  },
  {
    label: "Manzhouli, Xijiao - China (NZH)",
    value: "NZH",
  },
  {
    label: "Ordos - China (DSN)",
    value: "DSN",
  },
  {
    label: "Ordos, Ejin Horo - China (DSN)",
    value: "DSN",
  },
  {
    label: "Tongliao - China (TGO)",
    value: "TGO",
  },
  {
    label: "Tongliao, Tongliao - China (TGO)",
    value: "TGO",
  },
  {
    label: "Ulanhot, Ulanhot - China (HLH)",
    value: "HLH",
  },
  {
    label: "Ulanhot - China (HLH)",
    value: "HLH",
  },
  {
    label: "Ulanqab - China (UCB)",
    value: "UCB",
  },
  {
    label: "Ulanqab, Ulanqab - China (UCB)",
    value: "UCB",
  },
  {
    label: "Xilinhot - China (XIL)",
    value: "XIL",
  },
  {
    label: "Xilinhot, Xilinhot - China (XIL)",
    value: "XIL",
  },
  {
    label: "Zhalantun - China (NZL)",
    value: "NZL",
  },
  {
    label: "Zhalantun, Chengjisihan - China (NZL)",
    value: "NZL",
  },
  {
    label: "Changzhou - China (CZX)",
    value: "CZX",
  },
  {
    label: "Changzhou, Benniu - China (CZX)",
    value: "CZX",
  },
  {
    label: "Huai'an - China (HIA)",
    value: "HIA",
  },
  {
    label: "Huai'an, Lianshui - China (HIA)",
    value: "HIA",
  },
  {
    label: "Lianyungang - China (LYG)",
    value: "LYG",
  },
  {
    label: "Lianyungang, Baitabu - China (LYG)",
    value: "LYG",
  },
  {
    label: "Nanjing - China (NKG)",
    value: "NKG",
  },
  {
    label: "Nanjing, Lukou Intl - China (NKG)",
    value: "NKG",
  },
  {
    label: "Nantong - China (NTG)",
    value: "NTG",
  },
  {
    label: "Nantong, Xingdong - China (NTG)",
    value: "NTG",
  },
  {
    label: "Wuxi - China (WUX)",
    value: "WUX",
  },
  {
    label: "Wuxi, Sunan Shuofang - China (WUX)",
    value: "WUX",
  },
  {
    label: "Xuzhou - China (XUZ)",
    value: "XUZ",
  },
  {
    label: "Xuzhou, Guanyin - China (XUZ)",
    value: "XUZ",
  },
  {
    label: "Yancheng - China (YNZ)",
    value: "YNZ",
  },
  {
    label: "Yancheng, Nanyang - China (YNZ)",
    value: "YNZ",
  },
  {
    label: "Yangzhou - China (YTY)",
    value: "YTY",
  },
  {
    label: "Yangzhou, Taizhou - China (YTY)",
    value: "YTY",
  },
  {
    label: "Ganzhou - China (KOW)",
    value: "KOW",
  },
  {
    label: "Ganzhou, Huangjin - China (KOW)",
    value: "KOW",
  },
  {
    label: "Ji'an - China (JGS)",
    value: "JGS",
  },
  {
    label: "Ji'an, Jinggangshan - China (JGS)",
    value: "JGS",
  },
  {
    label: "Jingdezhen - China (JDZ)",
    value: "JDZ",
  },
  {
    label: "Jingdezhen, Jingdezhen - China (JDZ)",
    value: "JDZ",
  },
  {
    label: "Jiujiang - China (JIU)",
    value: "JIU",
  },
  {
    label: "Jiujiang, Lushan - China (JIU)",
    value: "JIU",
  },
  {
    label: "Nanchang - China (KHN)",
    value: "KHN",
  },
  {
    label: "Nanchang, Changbei Intl - China (KHN)",
    value: "KHN",
  },
  {
    label: "Shangrao - China (SQD)",
    value: "SQD",
  },
  {
    label: "Shangrao, Sanqingshan - China (SQD)",
    value: "SQD",
  },
  {
    label: "Yichun Jiangxi - China (YIC)",
    value: "YIC",
  },
  {
    label: "Yichun Jiangxi, Mingyeshan - China (YIC)",
    value: "YIC",
  },
  {
    label: "Baicgeng - China (DBC)",
    value: "DBC",
  },
  {
    label: "Baicgeng, Chang An - China (DBC)",
    value: "DBC",
  },
  {
    label: "Baishan - China (NBS)",
    value: "NBS",
  },
  {
    label: "Baishan, Changbaishan - China (NBS)",
    value: "NBS",
  },
  {
    label: "Changchun - China (CGQ)",
    value: "CGQ",
  },
  {
    label: "Changchun, Longjia Intl - China (CGQ)",
    value: "CGQ",
  },
  {
    label: "Tonghua - China (TNH)",
    value: "TNH",
  },
  {
    label: "Tonghua, Sanyuanpu - China (TNH)",
    value: "TNH",
  },
  {
    label: "Yanji - China (YNJ)",
    value: "YNJ",
  },
  {
    label: "Yanji, Chaoyangchuan - China (YNJ)",
    value: "YNJ",
  },
  {
    label: "Anshan - China (AOG)",
    value: "AOG",
  },
  {
    label: "Anshan, Teng'ao - China (AOG)",
    value: "AOG",
  },
  {
    label: "Changhai - China (CNI)",
    value: "CNI",
  },
  {
    label: "Changhai, Changhai - China (CNI)",
    value: "CNI",
  },
  {
    label: "Chaoyang - China (CHG)",
    value: "CHG",
  },
  {
    label: "Chaoyang, Chaoyang Airport - China (CHG)",
    value: "CHG",
  },
  {
    label: "Dalian - China (DLC)",
    value: "DLC",
  },
  {
    label: "Dalian, Zhoushuizi Intl - China (DLC)",
    value: "DLC",
  },
  {
    label: "Dandong - China (DDG)",
    value: "DDG",
  },
  {
    label: "Dandong, Langtou - China (DDG)",
    value: "DDG",
  },
  {
    label: "Jinzhou - China (JNZ)",
    value: "JNZ",
  },
  {
    label: "Jinzhou, Jinzhouwan - China (JNZ)",
    value: "JNZ",
  },
  {
    label: "Shenyang - China (SHE)",
    value: "SHE",
  },
  {
    label: "Shenyang, Taoxian Intl - China (SHE)",
    value: "SHE",
  },
  {
    label: "Yingkou - China (YKH)",
    value: "YKH",
  },
  {
    label: "Yingkou, Airport - China (YKH)",
    value: "YKH",
  },
  {
    label: "Macau - Macao -Sar Of China- (MFM)",
    value: "MFM",
  },
  {
    label: "Macau, International - Macao -Sar Of China- (MFM)",
    value: "MFM",
  },
  {
    label: "Guyuan - China (GYU)",
    value: "GYU",
  },
  {
    label: "Guyuan, Liupanshan - China (GYU)",
    value: "GYU",
  },
  {
    label: "Yinchuan - China (INC)",
    value: "INC",
  },
  {
    label: "Yinchuan, Hedong - China (INC)",
    value: "INC",
  },
  {
    label: "Zhongwei - China (ZHY)",
    value: "ZHY",
  },
  {
    label: "Zhongwei, Xiangshan - China (ZHY)",
    value: "ZHY",
  },
  {
    label: "Delingha - China (HXD)",
    value: "HXD",
  },
  {
    label: "Delingha, Delingha - China (HXD)",
    value: "HXD",
  },
  {
    label: "Golmud - China (GOQ)",
    value: "GOQ",
  },
  {
    label: "Golmud, Golmud - China (GOQ)",
    value: "GOQ",
  },
  {
    label: "Golog - China (GMQ)",
    value: "GMQ",
  },
  {
    label: "Golog, Maqin - China (GMQ)",
    value: "GMQ",
  },
  {
    label: "Xining - China (XNN)",
    value: "XNN",
  },
  {
    label: "Xining, Caojiabao - China (XNN)",
    value: "XNN",
  },
  {
    label: "Yushu - China (YUS)",
    value: "YUS",
  },
  {
    label: "Yushu, Batang - China (YUS)",
    value: "YUS",
  },
  {
    label: "Ankang - China (AKA)",
    value: "AKA",
  },
  {
    label: "Ankang, Walipu - China (AKA)",
    value: "AKA",
  },
  {
    label: "Hanzhong - China (HZG)",
    value: "HZG",
  },
  {
    label: "Hanzhong, Xiguan - China (HZG)",
    value: "HZG",
  },
  {
    label: "Xi An - China (SIA)",
    value: "SIA",
  },
  {
    label: "Xi An, Xiguan - China (SIA)",
    value: "SIA",
  },
  {
    label: "Xi An, Xianyang Intl - China (XIY)",
    value: "XIY",
  },
  {
    label: "Yan An - China (ENY)",
    value: "ENY",
  },
  {
    label: "Yan An, Ershilipu - China (ENY)",
    value: "ENY",
  },
  {
    label: "Yulin - China (UYN)",
    value: "UYN",
  },
  {
    label: "Yulin, Yuyang - China (UYN)",
    value: "UYN",
  },
  {
    label: "Dongying - China (DOY)",
    value: "DOY",
  },
  {
    label: "Dongying, Shengli - China (DOY)",
    value: "DOY",
  },
  {
    label: "Jinan - China (TNA)",
    value: "TNA",
  },
  {
    label: "Jinan, Yaoqiang Intl - China (TNA)",
    value: "TNA",
  },
  {
    label: "Jining - China (JNG)",
    value: "JNG",
  },
  {
    label: "Jining, Qufu - China (JNG)",
    value: "JNG",
  },
  {
    label: "Linyi - China (LYI)",
    value: "LYI",
  },
  {
    label: "Linyi, Shubuling - China (LYI)",
    value: "LYI",
  },
  {
    label: "Qingdao - China (TAO)",
    value: "TAO",
  },
  {
    label: "Qingdao, Liuting Intl - China (TAO)",
    value: "TAO",
  },
  {
    label: "Weifang - China (WEF)",
    value: "WEF",
  },
  {
    label: "Weifang, Weifang - China (WEF)",
    value: "WEF",
  },
  {
    label: "Weihai - China (WEH)",
    value: "WEH",
  },
  {
    label: "Weihai, Dashuibo - China (WEH)",
    value: "WEH",
  },
  {
    label: "Yantai - China (YNT)",
    value: "YNT",
  },
  {
    label: "Yantai, Penglai Intl - China (YNT)",
    value: "YNT",
  },
  {
    label: "Shanghai - China (SHA)",
    value: "SHA",
  },
  {
    label: "Shanghai, Hongqiao Intl - China (SHA)",
    value: "SHA",
  },
  {
    label: "Shanghai, Pudong Intl - China (PVG)",
    value: "PVG",
  },
  {
    label: "Changzhi - China (CIH)",
    value: "CIH",
  },
  {
    label: "Changzhi, Wangcun - China (CIH)",
    value: "CIH",
  },
  {
    label: "Datong - China (DAT)",
    value: "DAT",
  },
  {
    label: "Datong, Beijiazao - China (DAT)",
    value: "DAT",
  },
  {
    label: "Linfen - China (LFQ)",
    value: "LFQ",
  },
  {
    label: "Linfen, Qiaoli - China (LFQ)",
    value: "LFQ",
  },
  {
    label: "Luliang - China (LLV)",
    value: "LLV",
  },
  {
    label: "Luliang, Luliang - China (LLV)",
    value: "LLV",
  },
  {
    label: "Taiyuan - China (TYN)",
    value: "TYN",
  },
  {
    label: "Taiyuan, Wusu Intl - China (TYN)",
    value: "TYN",
  },
  {
    label: "Xinzhou - China (WUT)",
    value: "WUT",
  },
  {
    label: "Xinzhou, Wutaishan - China (WUT)",
    value: "WUT",
  },
  {
    label: "Yuncheng - China (YCU)",
    value: "YCU",
  },
  {
    label: "Yuncheng, Guangong - China (YCU)",
    value: "YCU",
  },
  {
    label: "Chengdu - China (CTU)",
    value: "CTU",
  },
  {
    label: "Chengdu, Shuangliu Intl - China (CTU)",
    value: "CTU",
  },
  {
    label: "Chengdu, Port Free Trade Zone - China (XTX)",
    value: "XTX",
  },
  {
    label: "Daocheng - China (DCY)",
    value: "DCY",
  },
  {
    label: "Daocheng, Yading - China (DCY)",
    value: "DCY",
  },
  {
    label: "Dazhou - China (DAX)",
    value: "DAX",
  },
  {
    label: "Dazhou, Heshi - China (DAX)",
    value: "DAX",
  },
  {
    label: "Guangyuan - China (GYS)",
    value: "GYS",
  },
  {
    label: "Guangyuan, Panlong - China (GYS)",
    value: "GYS",
  },
  {
    label: "Aba Hongyuan - China (AHJ)",
    value: "AHJ",
  },
  {
    label: "Aba Hongyuan, Aba Hongyuan - China (AHJ)",
    value: "AHJ",
  },
  {
    label: "Jiuzhaigou - China (JZH)",
    value: "JZH",
  },
  {
    label: "Jiuzhaigou, Jiuzhai Huanglong - China (JZH)",
    value: "JZH",
  },
  {
    label: "Kangding - China (KGT)",
    value: "KGT",
  },
  {
    label: "Kangding, Kangding - China (KGT)",
    value: "KGT",
  },
  {
    label: "Luzhou - China (LZO)",
    value: "LZO",
  },
  {
    label: "Luzhou, Lantian - China (LZO)",
    value: "LZO",
  },
  {
    label: "Mianyang - China (MIG)",
    value: "MIG",
  },
  {
    label: "Mianyang, Nanjiao - China (MIG)",
    value: "MIG",
  },
  {
    label: "Nanchong - China (NAO)",
    value: "NAO",
  },
  {
    label: "Nanchong, Gaoping - China (NAO)",
    value: "NAO",
  },
  {
    label: "Panzhihua - China (PZI)",
    value: "PZI",
  },
  {
    label: "Panzhihua, Bao Angong - China (PZI)",
    value: "PZI",
  },
  {
    label: "Xichang - China (XIC)",
    value: "XIC",
  },
  {
    label: "Xichang, Qingshan - China (XIC)",
    value: "XIC",
  },
  {
    label: "Yibin - China (YBP)",
    value: "YBP",
  },
  {
    label: "Yibin, Caiba - China (YBP)",
    value: "YBP",
  },
  {
    label: "Tianjin - China (TSN)",
    value: "TSN",
  },
  {
    label: "Tianjin, Binhai Intl - China (TSN)",
    value: "TSN",
  },
  {
    label: "Lhasa/Lasa - China (LXA)",
    value: "LXA",
  },
  {
    label: "Lhasa/Lasa, Gonggar - China (LXA)",
    value: "LXA",
  },
  {
    label: "Nyingchi/Linzhi - China (LZY)",
    value: "LZY",
  },
  {
    label: "Nyingchi/Linzhi, Mainling - China (LZY)",
    value: "LZY",
  },
  {
    label: "Qamdo/Changdu - China (BPX)",
    value: "BPX",
  },
  {
    label: "Qamdo/Changdu, Bamda - China (BPX)",
    value: "BPX",
  },
  {
    label: "Xigaze Rikaze - China (RKZ)",
    value: "RKZ",
  },
  {
    label: "Xigaze Rikaze, Peace - China (RKZ)",
    value: "RKZ",
  },
  {
    label: "Shiquanhe - China (NGQ)",
    value: "NGQ",
  },
  {
    label: "Shiquanhe, Ngari Gunsa Ali Kunsha - China (NGQ)",
    value: "NGQ",
  },
  {
    label: "Aksu - China (AKU)",
    value: "AKU",
  },
  {
    label: "Aksu, Aksu - China (AKU)",
    value: "AKU",
  },
  {
    label: "Altay - China (AAT)",
    value: "AAT",
  },
  {
    label: "Altay, Altay - China (AAT)",
    value: "AAT",
  },
  {
    label: "Bole - China (BPL)",
    value: "BPL",
  },
  {
    label: "Bole, Alashankou - China (BPL)",
    value: "BPL",
  },
  {
    label: "Fuyun - China (FYN)",
    value: "FYN",
  },
  {
    label: "Fuyun, Fuyun - China (FYN)",
    value: "FYN",
  },
  {
    label: "Hami - China (HMI)",
    value: "HMI",
  },
  {
    label: "Hami, Hami - China (HMI)",
    value: "HMI",
  },
  {
    label: "Hotan - China (HTN)",
    value: "HTN",
  },
  {
    label: "Hotan, Hotan - China (HTN)",
    value: "HTN",
  },
  {
    label: "Burqin - China (KJI)",
    value: "KJI",
  },
  {
    label: "Burqin, Kanas - China (KJI)",
    value: "KJI",
  },
  {
    label: "Korla - China (KRL)",
    value: "KRL",
  },
  {
    label: "Korla, Korla - China (KRL)",
    value: "KRL",
  },
  {
    label: "Kuqa - China (KCA)",
    value: "KCA",
  },
  {
    label: "Kuqa, Quici - China (KCA)",
    value: "KCA",
  },
  {
    label: "Karamay - China (KRY)",
    value: "KRY",
  },
  {
    label: "Karamay, Karamay - China (KRY)",
    value: "KRY",
  },
  {
    label: "Kashi - China (KHG)",
    value: "KHG",
  },
  {
    label: "Kashi, Kashi - China (KHG)",
    value: "KHG",
  },
  {
    label: "Qiemo - China (IQM)",
    value: "IQM",
  },
  {
    label: "Qiemo, Qiemo - China (IQM)",
    value: "IQM",
  },
  {
    label: "Shanhaiguan - China (SHF)",
    value: "SHF",
  },
  {
    label: "Shanhaiguan, Huayuan - China (SHF)",
    value: "SHF",
  },
  {
    label: "Tacheng - China (TCG)",
    value: "TCG",
  },
  {
    label: "Tacheng, Tacheng - China (TCG)",
    value: "TCG",
  },
  {
    label: "Turpan - China (TLQ)",
    value: "TLQ",
  },
  {
    label: "Turpan, Jiaohe - China (TLQ)",
    value: "TLQ",
  },
  {
    label: "Urumqi - China (URC)",
    value: "URC",
  },
  {
    label: "Urumqi, Diwopu Intl - China (URC)",
    value: "URC",
  },
  {
    label: "Xinyuan - China (NLT)",
    value: "NLT",
  },
  {
    label: "Xinyuan, Nalati - China (NLT)",
    value: "NLT",
  },
  {
    label: "Yining - China (YIN)",
    value: "YIN",
  },
  {
    label: "Yining, Yining - China (YIN)",
    value: "YIN",
  },
  {
    label: "Baoshan - China (BSD)",
    value: "BSD",
  },
  {
    label: "Baoshan, Baoshan - China (BSD)",
    value: "BSD",
  },
  {
    label: "Cangyuan - China (CWJ)",
    value: "CWJ",
  },
  {
    label: "Cangyuan, Washan - China (CWJ)",
    value: "CWJ",
  },
  {
    label: "Dali - China (DLU)",
    value: "DLU",
  },
  {
    label: "Dali, Dali - China (DLU)",
    value: "DLU",
  },
  {
    label: "Jinghong - China (JHG)",
    value: "JHG",
  },
  {
    label: "Jinghong, Xishuangbanna Gasa Int - China (JHG)",
    value: "JHG",
  },
  {
    label: "Kunming - China (KMG)",
    value: "KMG",
  },
  {
    label: "Kunming, Kunming - China (KMG)",
    value: "KMG",
  },
  {
    label: "Lijiang - China (LJG)",
    value: "LJG",
  },
  {
    label: "Lijiang, Sanyi - China (LJG)",
    value: "LJG",
  },
  {
    label: "Lincang - China (LNJ)",
    value: "LNJ",
  },
  {
    label: "Lincang, Lincang Airport - China (LNJ)",
    value: "LNJ",
  },
  {
    label: "Mangshi - China (LUM)",
    value: "LUM",
  },
  {
    label: "Mangshi, Dehong Mangshi - China (LUM)",
    value: "LUM",
  },
  {
    label: "Ninglang - China (NLH)",
    value: "NLH",
  },
  {
    label: "Ninglang, Luguhu - China (NLH)",
    value: "NLH",
  },
  {
    label: "Simao - China (SYM)",
    value: "SYM",
  },
  {
    label: "Simao, Simao - China (SYM)",
    value: "SYM",
  },
  {
    label: "Deqen - China (DIG)",
    value: "DIG",
  },
  {
    label: "Deqen, Shangri-La - China (DIG)",
    value: "DIG",
  },
  {
    label: "Tengchong - China (TCZ)",
    value: "TCZ",
  },
  {
    label: "Tengchong, Tuofeng - China (TCZ)",
    value: "TCZ",
  },
  {
    label: "Wenshan - China (WNH)",
    value: "WNH",
  },
  {
    label: "Wenshan, Puzhehei - China (WNH)",
    value: "WNH",
  },
  {
    label: "Zhaotong - China (ZAT)",
    value: "ZAT",
  },
  {
    label: "Zhaotong, Zhaotong - China (ZAT)",
    value: "ZAT",
  },
  {
    label: "Hangzhou - China (HGH)",
    value: "HGH",
  },
  {
    label: "Hangzhou, Xiaoshan Intl - China (HGH)",
    value: "HGH",
  },
  {
    label: "Ningbo - China (NGB)",
    value: "NGB",
  },
  {
    label: "Ningbo, Lishe Intl - China (NGB)",
    value: "NGB",
  },
  {
    label: "Quzhou - China (JUZ)",
    value: "JUZ",
  },
  {
    label: "Quzhou, Quzhou - China (JUZ)",
    value: "JUZ",
  },
  {
    label: "Taizhou - China (HYN)",
    value: "HYN",
  },
  {
    label: "Taizhou, Luqiao - China (HYN)",
    value: "HYN",
  },
  {
    label: "Wenzhou - China (WNZ)",
    value: "WNZ",
  },
  {
    label: "Wenzhou, Yongqiang Intl - China (WNZ)",
    value: "WNZ",
  },
  {
    label: "Yiwu - China (YIW)",
    value: "YIW",
  },
  {
    label: "Yiwu, Yiwu - China (YIW)",
    value: "YIW",
  },
  {
    label: "Zhoushan - China (HSN)",
    value: "HSN",
  },
  {
    label: "Zhoushan, Putuoshan - China (HSN)",
    value: "HSN",
  },
  {
    label: "Anyang - China (AYN)",
    value: "AYN",
  },
  {
    label: "Anyang, Anyang - China (AYN)",
    value: "AYN",
  },
  {
    label: "Bengbu - China (BFU)",
    value: "BFU",
  },
  {
    label: "Bengbu, Bengbu - China (BFU)",
    value: "BFU",
  },
  {
    label: "Dazu - China (DZU)",
    value: "DZU",
  },
  {
    label: "Dazu, Dazu - China (DZU)",
    value: "DZU",
  },
  {
    label: "Guanghan - China (GHN)",
    value: "GHN",
  },
  {
    label: "Guanghan, Guanghan - China (GHN)",
    value: "GHN",
  },
  {
    label: "Jilin - China (JIL)",
    value: "JIL",
  },
  {
    label: "Jilin, Ertaizi - China (JIL)",
    value: "JIL",
  },
  {
    label: "Jingzhou - China (SHS)",
    value: "SHS",
  },
  {
    label: "Jingzhou, Shashi - China (SHS)",
    value: "SHS",
  },
  {
    label: "Laohekou - China (LHK)",
    value: "LHK",
  },
  {
    label: "Laohekou, Laohekou - China (LHK)",
    value: "LHK",
  },
  {
    label: "Liangping - China (LIA)",
    value: "LIA",
  },
  {
    label: "Liangping, Liangping - China (LIA)",
    value: "LIA",
  },
  {
    label: "Penglai - China (PNJ)",
    value: "PNJ",
  },
  {
    label: "Penglai, Shahekou - China (PNJ)",
    value: "PNJ",
  },
  {
    label: "Qinhuangdao - China (SHP)",
    value: "SHP",
  },
  {
    label: "Qinhuangdao, Shanhaiguan - China (SHP)",
    value: "SHP",
  },
  {
    label: "Rugao - China (RUG)",
    value: "RUG",
  },
  {
    label: "Rugao, Rugao - China (RUG)",
    value: "RUG",
  },
  {
    label: "Shanshan - China (SXJ)",
    value: "SXJ",
  },
  {
    label: "Shanshan, Shanshan - China (SXJ)",
    value: "SXJ",
  },
  {
    label: "Suzhou - China (SZV)",
    value: "SZV",
  },
  {
    label: "Suzhou, Guangfu - China (SZV)",
    value: "SZV",
  },
  {
    label: "Tianjin - China (XTF)",
    value: "XTF",
  },
  {
    label: "Tianjin, Port Free Trade Zone - China (XTF)",
    value: "XTF",
  },
  {
    label: "Urad Middle Banner - China (WZQ)",
    value: "WZQ",
  },
  {
    label: "Urad Middle Banner, Urad Middle Banner - China (WZQ)",
    value: "WZQ",
  },
  {
    label: "Wuhan - China (WUH)",
    value: "WUH",
  },
  {
    label: "Wuhan, Tianhe Intl - China (WUH)",
    value: "WUH",
  },
  {
    label: "Wuhu - China (WHU)",
    value: "WHU",
  },
  {
    label: "Wuhu, Wuhu - China (WHU)",
    value: "WHU",
  },
  {
    label: "Xingcheng - China (XEN)",
    value: "XEN",
  },
  {
    label: "Xingcheng, Xingcheng - China (XEN)",
    value: "XEN",
  },
  {
    label: "Xingning - China (XIN)",
    value: "XIN",
  },
  {
    label: "Xingning, Xingning - China (XIN)",
    value: "XIN",
  },
  {
    label: "Yilan - China (YLN)",
    value: "YLN",
  },
  {
    label: "Yilan, Yilan - China (YLN)",
    value: "YLN",
  },
  {
    label: "Yuanmou - China (YUA)",
    value: "YUA",
  },
  {
    label: "Yuanmou, Yuanmou - China (YUA)",
    value: "YUA",
  },
  {
    label: "Acandi, Alcides Fernandez - Colombia (ACD)",
    value: "ACD",
  },
  {
    label: "Acaricuara, Acaricuara - Colombia (ARF)",
    value: "ARF",
  },
  {
    label: "Aguaclara, Aguaclara - Colombia (ACL)",
    value: "ACL",
  },
  {
    label: "Amalfi, Amalfi - Colombia (AFI)",
    value: "AFI",
  },
  {
    label: "Andes, Andes - Colombia (ADN)",
    value: "ADN",
  },
  {
    label: "Apartado, Carepa Ar Betancourt - Colombia (APO)",
    value: "APO",
  },
  {
    label: "Apiay, Luis F.Gomez Nino Ab - Colombia (API)",
    value: "API",
  },
  {
    label: "Araracuara, Araracuara - Colombia (ACR)",
    value: "ACR",
  },
  {
    label: "Arauca, Santiago Perez Quiroz - Colombia (AUC)",
    value: "AUC",
  },
  {
    label: "Arauquita, El Troncal - Colombia (ARQ)",
    value: "ARQ",
  },
  {
    label: "Arboletas, Arboletes - Colombia (ARO)",
    value: "ARO",
  },
  {
    label: "Arica, Arica - Colombia (ACM)",
    value: "ACM",
  },
  {
    label: "Armenia, El Eden Intl - Colombia (AXM)",
    value: "AXM",
  },
  {
    label: "Ayacucho, La Gloria - Colombia (AYC)",
    value: "AYC",
  },
  {
    label: "Ayapel, El Cebruno - Colombia (AYA)",
    value: "AYA",
  },
  {
    label: "Bahia Cupica, Cupica - Colombia (BHF)",
    value: "BHF",
  },
  {
    label: "Bahia Solano, Jose Celestino Mutis - Colombia (BSC)",
    value: "BSC",
  },
  {
    label: "Barranca De Upia, Guadalito - Colombia (BAC)",
    value: "BAC",
  },
  {
    label: "Barrancabermeja, Yariguies - Colombia (EJA)",
    value: "EJA",
  },
  {
    label: "Barrancominas, Barranco Minas - Colombia (NBB)",
    value: "NBB",
  },
  {
    label: "Barranquilla, Ernesto Cortissoz Intl - Colombia (BAQ)",
    value: "BAQ",
  },
  {
    label: "Bogota - Colombia (BOG)",
    value: "BOG",
  },
  {
    label: "Bogota, El Dorado Intl - Colombia (BOG)",
    value: "BOG",
  },
  {
    label: "Bucaramanga, Palonegro Intl - Colombia (BGA)",
    value: "BGA",
  },
  {
    label: "Buenaventura, Gerardo Tobar Lopez - Colombia (BUN)",
    value: "BUN",
  },
  {
    label: "Cali, Alfonso B Aragon Intl - Colombia (CLO)",
    value: "CLO",
  },
  {
    label: "Candilejas, Candilejas - Colombia (CJD)",
    value: "CJD",
  },
  {
    label: "Capurgana, Capurgana - Colombia (CPB)",
    value: "CPB",
  },
  {
    label: "Caquetania, Caquetania - Colombia (CQT)",
    value: "CQT",
  },
  {
    label: "Carimagua, Carimagua - Colombia (CCO)",
    value: "CCO",
  },
  {
    label: "Cartagena, Rafael Nunez Intl - Colombia (CTG)",
    value: "CTG",
  },
  {
    label: "Cartago, Santa Ana - Colombia (CRC)",
    value: "CRC",
  },
  {
    label: "Caruru, Caruru - Colombia (CUO)",
    value: "CUO",
  },
  {
    label: "Casuarito, Casuarito - Colombia (CSR)",
    value: "CSR",
  },
  {
    label: "Caucasia, Juan H White - Colombia (CAQ)",
    value: "CAQ",
  },
  {
    label: "Chaparral, General Navas Pardo - Colombia (CPL)",
    value: "CPL",
  },
  {
    label: "Chigorodo, Jaime Ortis Betancur - Colombia (IGO)",
    value: "IGO",
  },
  {
    label: "Chivolo, Chivolo - Colombia (IVO)",
    value: "IVO",
  },
  {
    label: "Cimitarra, Cimitarra - Colombia (CIM)",
    value: "CIM",
  },
  {
    label: "Codazzi, Las Flores - Colombia (DZI)",
    value: "DZI",
  },
  {
    label: "Condoto, Mandinga - Colombia (COG)",
    value: "COG",
  },
  {
    label: "Corozal, Las Brujas - Colombia (CZU)",
    value: "CZU",
  },
  {
    label: "Covenas, Covenas - Colombia (CVE)",
    value: "CVE",
  },
  {
    label: "Cravo Norte, Cravo Norte - Colombia (RAV)",
    value: "RAV",
  },
  {
    label: "Cucuta, Camilo Daza - Colombia (CUC)",
    value: "CUC",
  },
  {
    label: "Currillo, Currillo - Colombia (CUI)",
    value: "CUI",
  },
  {
    label: "El Bagre, El Bagre - Colombia (EBG)",
    value: "EBG",
  },
  {
    label: "El Banco, Las Flores - Colombia (ELB)",
    value: "ELB",
  },
  {
    label: "El Charco, El Charco - Colombia (ECR)",
    value: "ECR",
  },
  {
    label: "El Encanto, El Encanto - Colombia (ECO)",
    value: "ECO",
  },
  {
    label: "El Recreo, El Recreo - Colombia (ELJ)",
    value: "ELJ",
  },
  {
    label: "El Yopal, El Alcaravan - Colombia (EYP)",
    value: "EYP",
  },
  {
    label: "Florencia, Gustavo A Paredes - Colombia (FLA)",
    value: "FLA",
  },
  {
    label: "Gamarra, Gamarra - Colombia (GRA)",
    value: "GRA",
  },
  {
    label: "Gilgal, Gilgal - Colombia (GGL)",
    value: "GGL",
  },
  {
    label: "Girardot, Santiago Vila - Colombia (GIR)",
    value: "GIR",
  },
  {
    label: "Guacamaya, Guacamayas - Colombia (GCA)",
    value: "GCA",
  },
  {
    label: "Guamal, Guamal - Colombia (GAA)",
    value: "GAA",
  },
  {
    label: "Guapi, Juan Casiano - Colombia (GPI)",
    value: "GPI",
  },
  {
    label: "Guerima, Guerima - Colombia (GMC)",
    value: "GMC",
  },
  {
    label: "Hato Corozal, Hato Corozal - Colombia (HTZ)",
    value: "HTZ",
  },
  {
    label: "Herrera, La Herrera - Colombia (HRR)",
    value: "HRR",
  },
  {
    label: "Ibague, Perales - Colombia (IBE)",
    value: "IBE",
  },
  {
    label: "Ipiales, San Luis - Colombia (IPI)",
    value: "IPI",
  },
  {
    label: "Iscuande, Iscuande - Colombia (ISD)",
    value: "ISD",
  },
  {
    label: "Jurado, Jurado - Colombia (JUO)",
    value: "JUO",
  },
  {
    label: "La Chorrera, La Chorrera - Colombia (LCR)",
    value: "LCR",
  },
  {
    label: "La Pedrera, La Pedrera - Colombia (LPD)",
    value: "LPD",
  },
  {
    label: "La Primavera, La Primavera - Colombia (LPE)",
    value: "LPE",
  },
  {
    label: "La Uribe, La Uribe - Colombia (LAT)",
    value: "LAT",
  },
  {
    label: "Lamacarena, La Macarena - Colombia (LMC)",
    value: "LMC",
  },
  {
    label: "Las Gaviotas, Las Gaviotas - Colombia (LGT)",
    value: "LGT",
  },
  {
    label: "Leguizamo, Leguizamo - Colombia (LGZ)",
    value: "LGZ",
  },
  {
    label: "Leticia, Alfredo V Cobb Intl - Colombia (LET)",
    value: "LET",
  },
  {
    label: "Lopez De Micay, Lopez De Micay - Colombia (LMX)",
    value: "LMX",
  },
  {
    label: "Lorica, Lorica - Colombia (LRI)",
    value: "LRI",
  },
  {
    label: "Macanal, Macanal - Colombia (NAD)",
    value: "NAD",
  },
  {
    label: "Magangue, Baracoa - Colombia (MGN)",
    value: "MGN",
  },
  {
    label: "Maicao, Jorge Isaacs - Colombia (MCJ)",
    value: "MCJ",
  },
  {
    label: "Manizales, La Nubia - Colombia (MZL)",
    value: "MZL",
  },
  {
    label: "Mariquita, Jose Celestino Mutis - Colombia (MQU)",
    value: "MQU",
  },
  {
    label: "Medellin, Jose Maria Cordova Int - Colombia (MDE)",
    value: "MDE",
  },
  {
    label: "Medellin, Olaya Herrera - Colombia (EOH)",
    value: "EOH",
  },
  {
    label: "Medina, Medina - Colombia (MND)",
    value: "MND",
  },
  {
    label: "Miraflores, Miraflores - Colombia (MFS)",
    value: "MFS",
  },
  {
    label: "Miriti, Miriti Parana - Colombia (MIX)",
    value: "MIX",
  },
  {
    label: "Mitu, Fabio A Leon Bentley - Colombia (MVP)",
    value: "MVP",
  },
  {
    label: "Mompos, San Bernardo - Colombia (MMP)",
    value: "MMP",
  },
  {
    label: "Monfort, Monfort - Colombia (MFB)",
    value: "MFB",
  },
  {
    label: "Monte Libano, Montelibano - Colombia (MTB)",
    value: "MTB",
  },
  {
    label: "Monteria, Los Garzones - Colombia (MTR)",
    value: "MTR",
  },
  {
    label: "Monterrey, Monterrey - Colombia (MOY)",
    value: "MOY",
  },
  {
    label: "Morichal, Morichal - Colombia (MHF)",
    value: "MHF",
  },
  {
    label: "Mosquera, Mosquera - Colombia (MQR)",
    value: "MQR",
  },
  {
    label: "Mulatos, Mulatos - Colombia (ULS)",
    value: "ULS",
  },
  {
    label: "Nare, Puerto Nare - Colombia (NAR)",
    value: "NAR",
  },
  {
    label: "Necocli, Necocli - Colombia (NCI)",
    value: "NCI",
  },
  {
    label: "Neiva, Benito Salas - Colombia (NVA)",
    value: "NVA",
  },
  {
    label: "Nunchia, Nunchia - Colombia (NUH)",
    value: "NUH",
  },
  {
    label: "Nuqui, Reques Murillo - Colombia (NQU)",
    value: "NQU",
  },
  {
    label: "Ocana, Aguas Claras - Colombia (OCV)",
    value: "OCV",
  },
  {
    label: "Orocue, Orocue - Colombia (ORC)",
    value: "ORC",
  },
  {
    label: "Otu, Otu - Colombia (OTU)",
    value: "OTU",
  },
  {
    label: "Palanquero, German Olano Moreno Ab - Colombia (PAL)",
    value: "PAL",
  },
  {
    label: "Paratebueno, Paratebueno - Colombia (EUO)",
    value: "EUO",
  },
  {
    label: "Pasto, Antonio Narino - Colombia (PSO)",
    value: "PSO",
  },
  {
    label: "Payan, Payan - Colombia (PYN)",
    value: "PYN",
  },
  {
    label: "Paz De Ariporo, Paz De Ariporo - Colombia (PZA)",
    value: "PZA",
  },
  {
    label: "Pereira, Matecana Intl - Colombia (PEI)",
    value: "PEI",
  },
  {
    label: "Pitalito, Pitalito - Colombia (PTX)",
    value: "PTX",
  },
  {
    label: "Planadas, Planadas - Colombia (PLA)",
    value: "PLA",
  },
  {
    label: "Planeta Rica, Planeta Rica - Colombia (PLC)",
    value: "PLC",
  },
  {
    label: "Plato, Plato - Colombia (PLT)",
    value: "PLT",
  },
  {
    label: "Popayan, Guillermo L Valencia - Colombia (PPN)",
    value: "PPN",
  },
  {
    label: "Pore, Pore - Colombia (PRE)",
    value: "PRE",
  },
  {
    label: "Providencia, El Embrujo - Colombia (PVA)",
    value: "PVA",
  },
  {
    label: "Puerto Asis, Tres De Mayo - Colombia (PUU)",
    value: "PUU",
  },
  {
    label: "Puerto Berrio, Puerto Berrio - Colombia (PBE)",
    value: "PBE",
  },
  {
    label: "Puerto Boyaca, Velasquez - Colombia (PYA)",
    value: "PYA",
  },
  {
    label: "Puerto Carreno, German Olano - Colombia (PCR)",
    value: "PCR",
  },
  {
    label: "Puerto Inirida, Obando - Colombia (PDA)",
    value: "PDA",
  },
  {
    label: "Puerto Leguizamo, Caucaya - Colombia (LQM)",
    value: "LQM",
  },
  {
    label: "Puerto Rico, Puerto Rico - Colombia (PCC)",
    value: "PCC",
  },
  {
    label: "Quibdo, El Carano - Colombia (UIB)",
    value: "UIB",
  },
  {
    label: "Riohacha, Aimirante Padilla - Colombia (RCH)",
    value: "RCH",
  },
  {
    label: "Rondon, Juan Jose Rondon - Colombia (RON)",
    value: "RON",
  },
  {
    label: "Sabana De Torres, Las Cruces - Colombia (SNT)",
    value: "SNT",
  },
  {
    label: "San Andres Island, Gustavo R Pinilla Int - Colombia (ADZ)",
    value: "ADZ",
  },
  {
    label: "San Felipe, San Felipe - Colombia (SSD)",
    value: "SSD",
  },
  {
    label: "San Jose Del Gua, Jorge E Gonzalez T - Colombia (SJE)",
    value: "SJE",
  },
  {
    label: "San Juan D Ur, San Juan D Uraba - Colombia (SJR)",
    value: "SJR",
  },
  {
    label: "San Juan Del Cesa, San Juan Del Cesar - Colombia (SJH)",
    value: "SJH",
  },
  {
    label: "San Luis De Pale, San Luis De Palenque - Colombia (SQE)",
    value: "SQE",
  },
  {
    label: "San Marcos, San Marcos - Colombia (SRS)",
    value: "SRS",
  },
  {
    label: "San Pedro Jagua, San Pedro De Jagua - Colombia (SJG)",
    value: "SJG",
  },
  {
    label: "San Pedro Uraba, San Pedro De Uraba - Colombia (NPU)",
    value: "NPU",
  },
  {
    label: "San Vicente, Eduardo Falla Solano - Colombia (SVI)",
    value: "SVI",
  },
  {
    label: "Santa Ana, Santa Ana - Colombia (SQB)",
    value: "SQB",
  },
  {
    label: "Santa Catalina, Santa Catalina - Colombia (SCA)",
    value: "SCA",
  },
  {
    label: "Santa Maria, Santa Maria - Colombia (SMC)",
    value: "SMC",
  },
  {
    label: "Santa Marta, Simon Bolivar Intl - Colombia (SMR)",
    value: "SMR",
  },
  {
    label: "Santa Rosalia, Santa Rosalia - Colombia (SSL)",
    value: "SSL",
  },
  {
    label: "Santana Ramos, Santana Ramos - Colombia (SRO)",
    value: "SRO",
  },
  {
    label: "Saravena, Los Colonizadores - Colombia (RVE)",
    value: "RVE",
  },
  {
    label: "Sogamoso, Alberto Lleras Camargo - Colombia (SOX)",
    value: "SOX",
  },
  {
    label: "Solano, Solano - Colombia (SQF)",
    value: "SQF",
  },
  {
    label: "Solita, Solita - Colombia (SOH)",
    value: "SOH",
  },
  {
    label: "Tablon De Tamara, Tablon De Tamara - Colombia (TTM)",
    value: "TTM",
  },
  {
    label: "Tame, Gabriel Vargas Santos - Colombia (TME)",
    value: "TME",
  },
  {
    label: "Tarapaca, Tarapaca - Colombia (TCD)",
    value: "TCD",
  },
  {
    label: "Tauramena, Tauramena - Colombia (TAU)",
    value: "TAU",
  },
  {
    label: "Tibu, Tibu - Colombia (TIB)",
    value: "TIB",
  },
  {
    label: "Timbiqui, Timbiqui - Colombia (TBD)",
    value: "TBD",
  },
  {
    label: "Tolu, Golfo De Morrosquillo - Colombia (TLU)",
    value: "TLU",
  },
  {
    label: "Tres Esquinas, E.Esquerra Cubides Ab - Colombia (TQS)",
    value: "TQS",
  },
  {
    label: "Trinidad, Trinidad - Colombia (TDA)",
    value: "TDA",
  },
  {
    label: "Tulua, Heriberto Gil Martinez - Colombia (ULQ)",
    value: "ULQ",
  },
  {
    label: "Tumaco, La Florida - Colombia (TCO)",
    value: "TCO",
  },
  {
    label: "Turbo, Gonzalo Mejia - Colombia (TRB)",
    value: "TRB",
  },
  {
    label: "Unguia, Unguia - Colombia (UNC)",
    value: "UNC",
  },
  {
    label: "Uribe, Uribe - Colombia (URI)",
    value: "URI",
  },
  {
    label: "Urrao, Urrao - Colombia (URR)",
    value: "URR",
  },
  {
    label: "Valledupar, Alfonso Lopez Pumarejo - Colombia (VUP)",
    value: "VUP",
  },
  {
    label: "Villagarzon, Villagarzon - Colombia (VGZ)",
    value: "VGZ",
  },
  {
    label: "Villavicencio, Vanguardia - Colombia (VVC)",
    value: "VVC",
  },
  {
    label: "Yaguara, Yaguara Ii - Colombia (AYG)",
    value: "AYG",
  },
  {
    label: "Yari, Yari - Colombia (AYI)",
    value: "AYI",
  },
  {
    label: "Yavarate, Yavarate - Colombia (VAB)",
    value: "VAB",
  },
  {
    label: "Zapatoca, Zapatoca - Colombia (AZT)",
    value: "AZT",
  },
  {
    label: "Anjouan, Ouani - Comoros (AJN)",
    value: "AJN",
  },
  {
    label: "Moheli, Bandar Es Salam - Comoros (NWA)",
    value: "NWA",
  },
  {
    label: "Moroni, Iconi - Comoros (YVA)",
    value: "YVA",
  },
  {
    label: "Moroni, Prince Said Ibrahim - Comoros (HAH)",
    value: "HAH",
  },
  {
    label: "Betou, Betou - Congo Brazzaville (BTB)",
    value: "BTB",
  },
  {
    label: "Boundji, Boundji - Congo Brazzaville (BOE)",
    value: "BOE",
  },
  {
    label: "Brazzaville, Maya-Maya - Congo Brazzaville (BZV)",
    value: "BZV",
  },
  {
    label: "Djambala, Djambala - Congo Brazzaville (DJM)",
    value: "DJM",
  },
  {
    label: "Dolisie, Dolisie - Congo Brazzaville (DIS)",
    value: "DIS",
  },
  {
    label: "Epena, Epena - Congo Brazzaville (EPN)",
    value: "EPN",
  },
  {
    label: "Ewo, Ewo - Congo Brazzaville (EWO)",
    value: "EWO",
  },
  {
    label: "Gamboma, Gamboma - Congo Brazzaville (GMM)",
    value: "GMM",
  },
  {
    label: "Impfondo, Impfondo - Congo Brazzaville (ION)",
    value: "ION",
  },
  {
    label: "Kelle, Kelle - Congo Brazzaville (KEE)",
    value: "KEE",
  },
  {
    label: "Kindamba, Kindamba - Congo Brazzaville (KNJ)",
    value: "KNJ",
  },
  {
    label: "Lage, Lage - Congo Brazzaville (LCO)",
    value: "LCO",
  },
  {
    label: "Lekana, Lekana - Congo Brazzaville (LKC)",
    value: "LKC",
  },
  {
    label: "Makabana, Makabana - Congo Brazzaville (KMK)",
    value: "KMK",
  },
  {
    label: "Makoua, Makoua - Congo Brazzaville (MKJ)",
    value: "MKJ",
  },
  {
    label: "Mossendjo, Mossendjo - Congo Brazzaville (MSX)",
    value: "MSX",
  },
  {
    label: "Mouyondzi, Mouyondzi - Congo Brazzaville (MUY)",
    value: "MUY",
  },
  {
    label: "N'kayi, Yokangassi - Congo Brazzaville (NKY)",
    value: "NKY",
  },
  {
    label: "Okoyo, Okoyo - Congo Brazzaville (OKG)",
    value: "OKG",
  },
  {
    label: "Ouesso, Ouesso - Congo Brazzaville (OUE)",
    value: "OUE",
  },
  {
    label: "Owando, Owando - Congo Brazzaville (FTX)",
    value: "FTX",
  },
  {
    label: "Oyo, Ollombo - Congo Brazzaville (OLL)",
    value: "OLL",
  },
  {
    label: "Pointe-Noire, Pointe-Noire - Congo Brazzaville (PNR)",
    value: "PNR",
  },
  {
    label: "Sibiti, Sibiti - Congo Brazzaville (SIB)",
    value: "SIB",
  },
  {
    label: "Souanke, Souanke - Congo Brazzaville (SOE)",
    value: "SOE",
  },
  {
    label: "Zanaga, Zanaga - Congo Brazzaville (ANJ)",
    value: "ANJ",
  },
  {
    label: "Bandundu, Bandundu - Congo The Democratic Rep Of (FDU)",
    value: "FDU",
  },
  {
    label: "Basankusu, Basankusu - Congo The Democratic Rep Of (BSU)",
    value: "BSU",
  },
  {
    label: "Basongo, Basongo - Congo The Democratic Rep Of (BAN)",
    value: "BAN",
  },
  {
    label: "Beni, Mavivi - Congo The Democratic Rep Of (BNC)",
    value: "BNC",
  },
  {
    label: "Boende, Boende - Congo The Democratic Rep Of (BNB)",
    value: "BNB",
  },
  {
    label: "Boma, Boma - Congo The Democratic Rep Of (BOA)",
    value: "BOA",
  },
  {
    label: "Bukavu, Kavumu - Congo The Democratic Rep Of (BKY)",
    value: "BKY",
  },
  {
    label: "Bumba, Bumba - Congo The Democratic Rep Of (BMB)",
    value: "BMB",
  },
  {
    label: "Bunia, Bunia - Congo The Democratic Rep Of (BUX)",
    value: "BUX",
  },
  {
    label: "Buta, Zega - Congo The Democratic Rep Of (BZU)",
    value: "BZU",
  },
  {
    label: "Butembo, Butembo - Congo The Democratic Rep Of (RUE)",
    value: "RUE",
  },
  {
    label: "Gandajika, Gandajika - Congo The Democratic Rep Of (GDJ)",
    value: "GDJ",
  },
  {
    label: "Gbadolite, Gbadolite - Congo The Democratic Rep Of (BDT)",
    value: "BDT",
  },
  {
    label: "Gemena, Gemena - Congo The Democratic Rep Of (GMA)",
    value: "GMA",
  },
  {
    label: "Goma, Goma International - Congo The Democratic Rep Of (GOM)",
    value: "GOM",
  },
  {
    label: "Idiofa, Idiofa - Congo The Democratic Rep Of (IDF)",
    value: "IDF",
  },
  {
    label: "Ikela, Ikela - Congo The Democratic Rep Of (IKL)",
    value: "IKL",
  },
  {
    label: "Ilebo, Ilebo - Congo The Democratic Rep Of (PFR)",
    value: "PFR",
  },
  {
    label: "Inongo, Inongo - Congo The Democratic Rep Of (INO)",
    value: "INO",
  },
  {
    label: "Isiro, Matari - Congo The Democratic Rep Of (IRP)",
    value: "IRP",
  },
  {
    label: "Kabalo, Kabalo - Congo The Democratic Rep Of (KBO)",
    value: "KBO",
  },
  {
    label: "Kabinda, Tunta - Congo The Democratic Rep Of (KBN)",
    value: "KBN",
  },
  {
    label: "Kalemie, Kalemie - Congo The Democratic Rep Of (FMI)",
    value: "FMI",
  },
  {
    label: "Kalima, Kalima - Congo The Democratic Rep Of (KLY)",
    value: "KLY",
  },
  {
    label: "Kamina, Kamina Ville - Congo The Democratic Rep Of (KMN)",
    value: "KMN",
  },
  {
    label: "Kananga, Kananga - Congo The Democratic Rep Of (KGA)",
    value: "KGA",
  },
  {
    label: "Kaniama, Kaniama - Congo The Democratic Rep Of (KNM)",
    value: "KNM",
  },
  {
    label: "Kapanga, Kapanga - Congo The Democratic Rep Of (KAP)",
    value: "KAP",
  },
  {
    label: "Kasenga, Kasenga - Congo The Democratic Rep Of (KEC)",
    value: "KEC",
  },
  {
    label: "Kasongo-Lunda, Kasongo-Lunda - Congo The Democratic Rep Of (KGN)",
    value: "KGN",
  },
  {
    label: "Kikwit, Kikwit - Congo The Democratic Rep Of (KKW)",
    value: "KKW",
  },
  {
    label: "Kilwa, Kilwa - Congo The Democratic Rep Of (KIL)",
    value: "KIL",
  },
  {
    label: "Kindu, Kindu - Congo The Democratic Rep Of (KND)",
    value: "KND",
  },
  {
    label: "Kinshasa, N'djili Intl - Congo The Democratic Rep Of (FIH)",
    value: "FIH",
  },
  {
    label: "Kinshasa, N'dolo - Congo The Democratic Rep Of (NLO)",
    value: "NLO",
  },
  {
    label: "Kiri, Kiri - Congo The Democratic Rep Of (KRZ)",
    value: "KRZ",
  },
  {
    label: "Kisangani, Bangoka Intl - Congo The Democratic Rep Of (FKI)",
    value: "FKI",
  },
  {
    label: "Kolo, Kolo - Congo The Democratic Rep Of (NKL)",
    value: "NKL",
  },
  {
    label: "Kolwezi, Kolwezi - Congo The Democratic Rep Of (KWZ)",
    value: "KWZ",
  },
  {
    label: "Kongolo, Kongolo - Congo The Democratic Rep Of (KOO)",
    value: "KOO",
  },
  {
    label: "Kotakoli, Kotakoli - Congo The Democratic Rep Of (KLI)",
    value: "KLI",
  },
  {
    label: "Libenge, Libenge - Congo The Democratic Rep Of (LIE)",
    value: "LIE",
  },
  {
    label: "Lisala, Lisala - Congo The Democratic Rep Of (LIQ)",
    value: "LIQ",
  },
  {
    label: "Lodja, Lodja - Congo The Democratic Rep Of (LJA)",
    value: "LJA",
  },
  {
    label: "Lubumbashi, Luano Intl - Congo The Democratic Rep Of (FBM)",
    value: "FBM",
  },
  {
    label: "Luiza, Luiza - Congo The Democratic Rep Of (LZA)",
    value: "LZA",
  },
  {
    label: "Luozi, Luozi - Congo The Democratic Rep Of (LZI)",
    value: "LZI",
  },
  {
    label: "Lusambo, Lusambo - Congo The Democratic Rep Of (LBO)",
    value: "LBO",
  },
  {
    label: "Lusanga, Lusanga - Congo The Democratic Rep Of (LUS)",
    value: "LUS",
  },
  {
    label: "Manono, Manono - Congo The Democratic Rep Of (MNO)",
    value: "MNO",
  },
  {
    label: "Masi-Manimba, Masi-Manimba - Congo The Democratic Rep Of (MSM)",
    value: "MSM",
  },
  {
    label: "Matadi, Tshimpi - Congo The Democratic Rep Of (MAT)",
    value: "MAT",
  },
  {
    label: "Mbandaka, Mbandaka - Congo The Democratic Rep Of (MDK)",
    value: "MDK",
  },
  {
    label: "Mbuji-Mayi, Mbuji-Mayi - Congo The Democratic Rep Of (MJM)",
    value: "MJM",
  },
  {
    label: "Moba, Moba - Congo The Democratic Rep Of (BDV)",
    value: "BDV",
  },
  {
    label: "Muanda, Muanda - Congo The Democratic Rep Of (MNB)",
    value: "MNB",
  },
  {
    label: "Mweka, Mweka - Congo The Democratic Rep Of (MEW)",
    value: "MEW",
  },
  {
    label: "Nioki, Nioki - Congo The Democratic Rep Of (NIO)",
    value: "NIO",
  },
  {
    label: "Punia, Punia - Congo The Democratic Rep Of (PUN)",
    value: "PUN",
  },
  {
    label: "Pweto, Pweto - Congo The Democratic Rep Of (PWO)",
    value: "PWO",
  },
  {
    label: "Tshikapa, Tshikapa - Congo The Democratic Rep Of (TSH)",
    value: "TSH",
  },
  {
    label: "Yangambi, Yangambi - Congo The Democratic Rep Of (YAN)",
    value: "YAN",
  },
  {
    label: "Aitutaki Island, Aitutaki Island - Cook Islands (AIT)",
    value: "AIT",
  },
  {
    label: "Atiu Island, Atiu Island - Cook Islands (AIU)",
    value: "AIU",
  },
  {
    label: "Mangaia Island, Auau - Cook Islands (MGS)",
    value: "MGS",
  },
  {
    label: "Manihiki Atoll, Manihiki Atoll - Cook Islands (MHX)",
    value: "MHX",
  },
  {
    label: "Mauke Island, Mauke Island - Cook Islands (MUK)",
    value: "MUK",
  },
  {
    label: "Mitiaro Island, Nukuroa - Cook Islands (MOI)",
    value: "MOI",
  },
  {
    label: "Penrhyn Atoll, Penrhyn Atoll - Cook Islands (PYE)",
    value: "PYE",
  },
  {
    label: "Pukapuka Island, Pukapuka Island - Cook Islands (PZK)",
    value: "PZK",
  },
  {
    label: "Rarotonga Island, Rarotonga Island - Cook Islands (RAR)",
    value: "RAR",
  },
  {
    label: "Barra Colorado, Barra Del Colorado - Costa Rica (BCL)",
    value: "BCL",
  },
  {
    label: "Buenos Aires, Buenos Aires - Costa Rica (BAI)",
    value: "BAI",
  },
  {
    label: "Canas, Mojica - Costa Rica (CSC)",
    value: "CSC",
  },
  {
    label: "Carillo, Carillo - Costa Rica (RIK)",
    value: "RIK",
  },
  {
    label: "Coto 47, Coto 47 - Costa Rica (OTR)",
    value: "OTR",
  },
  {
    label: "Drake Bay, Drake Bay - Costa Rica (DRK)",
    value: "DRK",
  },
  {
    label: "Flamingo, Flamingo - Costa Rica (FMG)",
    value: "FMG",
  },
  {
    label: "Fortuna, Arenal - Costa Rica (FON)",
    value: "FON",
  },
  {
    label: "Golfito, Golfito - Costa Rica (GLF)",
    value: "GLF",
  },
  {
    label: "Guapiles, Guapiles - Costa Rica (GPL)",
    value: "GPL",
  },
  {
    label: "Las Canas, Las Canas - Costa Rica (LCS)",
    value: "LCS",
  },
  {
    label: "Liberia, D.Oduber Quiros Intl - Costa Rica (LIR)",
    value: "LIR",
  },
  {
    label: "Limon, International - Costa Rica (LIO)",
    value: "LIO",
  },
  {
    label: "Los Chiles, Los Chiles - Costa Rica (LSL)",
    value: "LSL",
  },
  {
    label: "Nicoya, Nicoya - Costa Rica (NCT)",
    value: "NCT",
  },
  {
    label: "Nosara Beach, Nosara - Costa Rica (NOB)",
    value: "NOB",
  },
  {
    label: "Palmar, Palmar Sur - Costa Rica (PMZ)",
    value: "PMZ",
  },
  {
    label: "Playa Samara, Playa Samara - Costa Rica (PLD)",
    value: "PLD",
  },
  {
    label: "Puerto Jimenez, Puerto Jimenez - Costa Rica (PJM)",
    value: "PJM",
  },
  {
    label: "Punta Islita, Islita - Costa Rica (PBP)",
    value: "PBP",
  },
  {
    label: "Punta Renes, Chacarita - Costa Rica (JAP)",
    value: "JAP",
  },
  {
    label: "Quepos, La Managua - Costa Rica (XQP)",
    value: "XQP",
  },
  {
    label: "Rio Frio, Rio Frio - Costa Rica (RFR)",
    value: "RFR",
  },
  {
    label: "San Jose, Juan Santamaria - Costa Rica (SJO)",
    value: "SJO",
  },
  {
    label: "San Jose, Tobias Bolanos Intl - Costa Rica (SYQ)",
    value: "SYQ",
  },
  {
    label: "San Vito, San Vito Airport - Costa Rica (TOO)",
    value: "TOO",
  },
  {
    label: "Santa Cruz, Guanacaste - Costa Rica (SZC)",
    value: "SZC",
  },
  {
    label: "Tamarindo, Tamarindo - Costa Rica (TNO)",
    value: "TNO",
  },
  {
    label: "Tambor, Tambor - Costa Rica (TMU)",
    value: "TMU",
  },
  {
    label: "Tortuquero, Tortuguero - Costa Rica (TTQ)",
    value: "TTQ",
  },
  {
    label: "Upala, Upala - Costa Rica (UPL)",
    value: "UPL",
  },
  {
    label: "Abengourou, Abengourou - Cote D Ivoire (OGO)",
    value: "OGO",
  },
  {
    label: "Abidjan, F Houphouet Boigny - Cote D Ivoire (ABJ)",
    value: "ABJ",
  },
  {
    label: "Aboisso, Aboisso - Cote D Ivoire (ABO)",
    value: "ABO",
  },
  {
    label: "Bondoukou, Soko - Cote D Ivoire (BDK)",
    value: "BDK",
  },
  {
    label: "Bouake, Bouake - Cote D Ivoire (BYK)",
    value: "BYK",
  },
  {
    label: "Bouna, Tehini - Cote D Ivoire (BQO)",
    value: "BQO",
  },
  {
    label: "Boundiali, Boundiali - Cote D Ivoire (BXI)",
    value: "BXI",
  },
  {
    label: "Daloa, Daloa - Cote D Ivoire (DJO)",
    value: "DJO",
  },
  {
    label: "Dimbokro, Ville - Cote D Ivoire (DIM)",
    value: "DIM",
  },
  {
    label: "Divo, Divo - Cote D Ivoire (DIV)",
    value: "DIV",
  },
  {
    label: "Ferkessedougou, Ferkessedougou - Cote D Ivoire (FEK)",
    value: "FEK",
  },
  {
    label: "Gagnoa, Gagnoa - Cote D Ivoire (GGN)",
    value: "GGN",
  },
  {
    label: "Grand Bereby, Nero-Mer - Cote D Ivoire (BBV)",
    value: "BBV",
  },
  {
    label: "Guiglo, Guiglo - Cote D Ivoire (GGO)",
    value: "GGO",
  },
  {
    label: "Katiola, Katiola - Cote D Ivoire (KTC)",
    value: "KTC",
  },
  {
    label: "Korhogo, Korhogo - Cote D Ivoire (HGO)",
    value: "HGO",
  },
  {
    label: "Man, Man - Cote D Ivoire (MJC)",
    value: "MJC",
  },
  {
    label: "Odienne, Odienne - Cote D Ivoire (KEO)",
    value: "KEO",
  },
  {
    label: "Ouango Fitini, Ouango Fitini - Cote D Ivoire (OFI)",
    value: "OFI",
  },
  {
    label: "San Pedro, San Pedro - Cote D Ivoire (SPY)",
    value: "SPY",
  },
  {
    label: "Sassandra, Sassandra - Cote D Ivoire (ZSS)",
    value: "ZSS",
  },
  {
    label: "Seguela, Seguela - Cote D Ivoire (SEO)",
    value: "SEO",
  },
  {
    label: "Tabou, Tabou - Cote D Ivoire (TXU)",
    value: "TXU",
  },
  {
    label: "Touba, Mahana - Cote D Ivoire (TOZ)",
    value: "TOZ",
  },
  {
    label: "Yamoussoukro, Yamoussoukro - Cote D Ivoire (ASK)",
    value: "ASK",
  },
  {
    label: "Brac - Croatia (BWK)",
    value: "BWK",
  },
  {
    label: "Brac, Bol - Croatia (BWK)",
    value: "BWK",
  },
  {
    label: "Dubrovnik - Croatia (DBV)",
    value: "DBV",
  },
  {
    label: "Dubrovnik, Dubrovnik - Croatia (DBV)",
    value: "DBV",
  },
  {
    label: "Pula - Croatia (PUY)",
    value: "PUY",
  },
  {
    label: "Pula, Pula - Croatia (PUY)",
    value: "PUY",
  },
  {
    label: "Split - Croatia (SPU)",
    value: "SPU",
  },
  {
    label: "Split, Split - Croatia (SPU)",
    value: "SPU",
  },
  {
    label: "Zadar - Croatia (ZAD)",
    value: "ZAD",
  },
  {
    label: "Zadar, Zadar - Croatia (ZAD)",
    value: "ZAD",
  },
  {
    label: "Zagreb - Croatia (ZAG)",
    value: "ZAG",
  },
  {
    label: "Zagreb, Pleso - Croatia (ZAG)",
    value: "ZAG",
  },
  {
    label: "Mali Losinj - Croatia (LSZ)",
    value: "LSZ",
  },
  {
    label: "Mali Losinj, Mali Losinj - Croatia (LSZ)",
    value: "LSZ",
  },
  {
    label: "Osijek - Croatia (OSI)",
    value: "OSI",
  },
  {
    label: "Osijek, Osijek - Croatia (OSI)",
    value: "OSI",
  },
  {
    label: "Rijeka - Croatia (RJK)",
    value: "RJK",
  },
  {
    label: "Rijeka, Rijeka - Croatia (RJK)",
    value: "RJK",
  },
  {
    label: "Baracoa, Gustavo Rizo - Cuba (BCA)",
    value: "BCA",
  },
  {
    label: "Bayamo, Carlos M. De Cespedes - Cuba (BYM)",
    value: "BYM",
  },
  {
    label: "Camaguey, Ignacio Agramonte Intl - Cuba (CMW)",
    value: "CMW",
  },
  {
    label: "Cayo Coco, Jardines Del Rey - Cuba (CCC)",
    value: "CCC",
  },
  {
    label: "Cayo Largo Del Sur, Vilo Acuna Intl - Cuba (CYO)",
    value: "CYO",
  },
  {
    label: "Cayo Las Brujas, Las Brujas - Cuba (BWW)",
    value: "BWW",
  },
  {
    label: "Ciego De Avila, Maximo Gomez - Cuba (AVI)",
    value: "AVI",
  },
  {
    label: "Cienfuegos, Jaime Gonzalez - Cuba (CFG)",
    value: "CFG",
  },
  {
    label: "Guantanamo, Guantanamo Bay Ns - Cuba (GAO)",
    value: "GAO",
  },
  {
    label: "Guantanamo, Mariana Grajales - Cuba (NBW)",
    value: "NBW",
  },
  {
    label: "Havana, Jose Marti Intl - Cuba (HAV)",
    value: "HAV",
  },
  {
    label: "Havana, Playa Baracoa - Cuba (UPB)",
    value: "UPB",
  },
  {
    label: "Holguin, Frank Pais Intl - Cuba (HOG)",
    value: "HOG",
  },
  {
    label: "La Coloma, La Coloma - Cuba (LCL)",
    value: "LCL",
  },
  {
    label: "Las Tunas, Hermanos Ameijeiras - Cuba (VTU)",
    value: "VTU",
  },
  {
    label: "Manzanillo, Sierra Maestra - Cuba (MZO)",
    value: "MZO",
  },
  {
    label: "Mayajigua, Mayajigua - Cuba (MJG)",
    value: "MJG",
  },
  {
    label: "Moa, Orestes Acosta - Cuba (MOA)",
    value: "MOA",
  },
  {
    label: "Nicaro, Nicaro - Cuba (ICR)",
    value: "ICR",
  },
  {
    label: "Nueva Gerona, Rafael Cabre - Cuba (GER)",
    value: "GER",
  },
  {
    label: "Preston, Preston - Cuba (PST)",
    value: "PST",
  },
  {
    label: "Punta Alegre, Punta Alegre - Cuba (UPA)",
    value: "UPA",
  },
  {
    label: "Punta De Maisi, Punta De Maisi - Cuba (UMA)",
    value: "UMA",
  },
  {
    label: "San Julian, Air Base - Cuba (SNJ)",
    value: "SNJ",
  },
  {
    label: "Sancti Spiritus, Sancti Spiritus - Cuba (USS)",
    value: "USS",
  },
  {
    label: "Santa Clara, Abel Santamaria - Cuba (SNU)",
    value: "SNU",
  },
  {
    label: "Santiago, Antonio Maceo Intl - Cuba (SCU)",
    value: "SCU",
  },
  {
    label: "Siguanea, Siguanea Airport - Cuba (SZJ)",
    value: "SZJ",
  },
  {
    label: "Trinidad, Alberto Delgado - Cuba (TND)",
    value: "TND",
  },
  {
    label: "Varadero, Juan G. Gomez Intl - Cuba (VRA)",
    value: "VRA",
  },
  {
    label: "Varadero, Kawama Airport - Cuba (VRO)",
    value: "VRO",
  },
  {
    label: "Curacao, Hato Intl - Curacao (CUR)",
    value: "CUR",
  },
  {
    label: "Larnaca - Cyprus (LCA)",
    value: "LCA",
  },
  {
    label: "Larnaca, Larnaca - Cyprus (LCA)",
    value: "LCA",
  },
  {
    label: "Akrotiri - Cyprus (AKT)",
    value: "AKT",
  },
  {
    label: "Akrotiri, Raf Station - Cyprus (AKT)",
    value: "AKT",
  },
  {
    label: "Ercan - Cyprus (ECN)",
    value: "ECN",
  },
  {
    label: "Ercan, Ercan - Cyprus (ECN)",
    value: "ECN",
  },
  {
    label: "Gecitkale - Cyprus (GEC)",
    value: "GEC",
  },
  {
    label: "Gecitkale, Gecitkale - Cyprus (GEC)",
    value: "GEC",
  },
  {
    label: "Nicosia - Cyprus (NIC)",
    value: "NIC",
  },
  {
    label: "Nicosia, Nicosia - Cyprus (NIC)",
    value: "NIC",
  },
  {
    label: "Paphos - Cyprus (PFO)",
    value: "PFO",
  },
  {
    label: "Paphos, Paphos Intl - Cyprus (PFO)",
    value: "PFO",
  },
  {
    label: "Brno - Czech Republic (BRQ)",
    value: "BRQ",
  },
  {
    label: "Brno, Turany - Czech Republic (BRQ)",
    value: "BRQ",
  },
  {
    label: "Karlovy Vary - Czech Republic (KLV)",
    value: "KLV",
  },
  {
    label: "Karlovy Vary, International - Czech Republic (KLV)",
    value: "KLV",
  },
  {
    label: "Marianske Lazne - Czech Republic (MKA)",
    value: "MKA",
  },
  {
    label: "Marianske Lazne, Marianske Lazne - Czech Republic (MKA)",
    value: "MKA",
  },
  {
    label: "Olomouc - Czech Republic (OLO)",
    value: "OLO",
  },
  {
    label: "Olomouc, Neredin - Czech Republic (OLO)",
    value: "OLO",
  },
  {
    label: "Ostrava - Czech Republic (OSR)",
    value: "OSR",
  },
  {
    label: "Ostrava, Leos Janacek - Czech Republic (OSR)",
    value: "OSR",
  },
  {
    label: "Pardubice - Czech Republic (PED)",
    value: "PED",
  },
  {
    label: "Pardubice, Airport - Czech Republic (PED)",
    value: "PED",
  },
  {
    label: "Prague - Czech Republic (PRG)",
    value: "PRG",
  },
  {
    label: "Prague, Ruzyne - Czech Republic (PRG)",
    value: "PRG",
  },
  {
    label: "Prague, Vodochody - Czech Republic (VOD)",
    value: "VOD",
  },
  {
    label: "Prerov - Czech Republic (PRV)",
    value: "PRV",
  },
  {
    label: "Prerov, Prerov - Czech Republic (PRV)",
    value: "PRV",
  },
  {
    label: "Uherske Hradiste - Czech Republic (UHE)",
    value: "UHE",
  },
  {
    label: "Uherske Hradiste, Kunovice - Czech Republic (UHE)",
    value: "UHE",
  },
  {
    label: "Zabreh - Czech Republic (ZBE)",
    value: "ZBE",
  },
  {
    label: "Zabreh, Dolni Benesov - Czech Republic (ZBE)",
    value: "ZBE",
  },
  {
    label: "Zlin - Czech Republic (GTW)",
    value: "GTW",
  },
  {
    label: "Zlin, Holesov - Czech Republic (GTW)",
    value: "GTW",
  },
  {
    label: "Aalbaek - Denmark (XOA)",
    value: "XOA",
  },
  {
    label: "Aalborg - Denmark (AAL)",
    value: "AAL",
  },
  {
    label: "Aalborg, Aalborg - Denmark (AAL)",
    value: "AAL",
  },
  {
    label: "Aarhus - Denmark (AAR)",
    value: "AAR",
  },
  {
    label: "Aarhus, Airport - Denmark (AAR)",
    value: "AAR",
  },
  {
    label: "Aarhus, Limousine - Denmark (ZBU)",
    value: "ZBU",
  },
  {
    label: "Billund - Denmark (BLL)",
    value: "BLL",
  },
  {
    label: "Billund, Billund - Denmark (BLL)",
    value: "BLL",
  },
  {
    label: "Bornholm - Denmark (RNN)",
    value: "RNN",
  },
  {
    label: "Bornholm, Ronne - Denmark (RNN)",
    value: "RNN",
  },
  {
    label: "Copenhagen - Denmark (CPH)",
    value: "CPH",
  },
  {
    label: "Copenhagen, Kastrup - Denmark (CPH)",
    value: "CPH",
  },
  {
    label: "Copenhagen, Roskilde - Denmark (RKE)",
    value: "RKE",
  },
  {
    label: "Esbjerg - Denmark (EBJ)",
    value: "EBJ",
  },
  {
    label: "Esbjerg, Airport - Denmark (EBJ)",
    value: "EBJ",
  },
  {
    label: "Karup - Denmark (KRP)",
    value: "KRP",
  },
  {
    label: "Karup, Karup - Denmark (KRP)",
    value: "KRP",
  },
  {
    label: "Laeso - Denmark (BYR)",
    value: "BYR",
  },
  {
    label: "Laeso, Laeso - Denmark (BYR)",
    value: "BYR",
  },
  {
    label: "Maribo - Denmark (MRW)",
    value: "MRW",
  },
  {
    label: "Maribo, Lolland Falster - Denmark (MRW)",
    value: "MRW",
  },
  {
    label: "Odense - Denmark (ODE)",
    value: "ODE",
  },
  {
    label: "Odense, Airport - Denmark (ODE)",
    value: "ODE",
  },
  {
    label: "Sindal - Denmark (CNL)",
    value: "CNL",
  },
  {
    label: "Sindal, Sindal - Denmark (CNL)",
    value: "CNL",
  },
  {
    label: "Skive - Denmark (SQW)",
    value: "SQW",
  },
  {
    label: "Skive, Airport - Denmark (SQW)",
    value: "SQW",
  },
  {
    label: "Sonderborg - Denmark (SGD)",
    value: "SGD",
  },
  {
    label: "Sonderborg, Airport - Denmark (SGD)",
    value: "SGD",
  },
  {
    label: "Stauning - Denmark (STA)",
    value: "STA",
  },
  {
    label: "Stauning, Stauning - Denmark (STA)",
    value: "STA",
  },
  {
    label: "Thisted - Denmark (TED)",
    value: "TED",
  },
  {
    label: "Thisted, Thisted - Denmark (TED)",
    value: "TED",
  },
  {
    label: "Vojens - Denmark (SKS)",
    value: "SKS",
  },
  {
    label: "Vojens, Skrydstrup - Denmark (SKS)",
    value: "SKS",
  },
  {
    label: "Ali Sabieh, Ali Sabieh - Djibouti (AII)",
    value: "AII",
  },
  {
    label: "Djibouti, Ambouli - Djibouti (JIB)",
    value: "JIB",
  },
  {
    label: "Moucha Island, Moucha Island - Djibouti (MHI)",
    value: "MHI",
  },
  {
    label: "Obock, Obock - Djibouti (OBC)",
    value: "OBC",
  },
  {
    label: "Tadjoura, Tadjoura - Djibouti (TDJ)",
    value: "TDJ",
  },
  {
    label: "Dominica, Canefield - Dominica (DCF)",
    value: "DCF",
  },
  {
    label: "Dominica, Melville Hall - Dominica (DOM)",
    value: "DOM",
  },
  {
    label: "Barahona - Dominican Republic (BRX)",
    value: "BRX",
  },
  {
    label: "Barahona, Maria Montez Intl - Dominican Republic (BRX)",
    value: "BRX",
  },
  {
    label: "Cabo Rojo - Dominican Republic (CBJ)",
    value: "CBJ",
  },
  {
    label: "Cabo Rojo, Cabo Rojo - Dominican Republic (CBJ)",
    value: "CBJ",
  },
  {
    label: "Constanza - Dominican Republic (COZ)",
    value: "COZ",
  },
  {
    label: "Constanza, 14 De Junio - Dominican Republic (COZ)",
    value: "COZ",
  },
  {
    label: "La Romana - Dominican Republic (LRM)",
    value: "LRM",
  },
  {
    label: "La Romana, Casa De Campo Intl - Dominican Republic (LRM)",
    value: "LRM",
  },
  {
    label: "Puerto Plata - Dominican Republic (POP)",
    value: "POP",
  },
  {
    label: "Puerto Plata, Gregorio Luperon Intl - Dominican Republic (POP)",
    value: "POP",
  },
  {
    label: "Punta Cana - Dominican Republic (PUJ)",
    value: "PUJ",
  },
  {
    label: "Punta Cana, International - Dominican Republic (PUJ)",
    value: "PUJ",
  },
  {
    label: "Sabana De Mar - Dominican Republic (SNX)",
    value: "SNX",
  },
  {
    label: "Sabana De Mar, Sabana De Mar - Dominican Republic (SNX)",
    value: "SNX",
  },
  {
    label: "Samana - Dominican Republic (AZS)",
    value: "AZS",
  },
  {
    label: "Samana, El Catey Intl - Dominican Republic (AZS)",
    value: "AZS",
  },
  {
    label: "Samana, El Portillo Airport - Dominican Republic (EPS)",
    value: "EPS",
  },
  {
    label: "San Juan - Dominican Republic (SJM)",
    value: "SJM",
  },
  {
    label: "San Juan, San Juan - Dominican Republic (SJM)",
    value: "SJM",
  },
  {
    label: "Santiago - Dominican Republic (STI)",
    value: "STI",
  },
  {
    label: "Santiago, Cibao Intl - Dominican Republic (STI)",
    value: "STI",
  },
  {
    label: "Santo Domingo - Dominican Republic (SDQ)",
    value: "SDQ",
  },
  {
    label: "Santo Domingo, Las Americas Intl - Dominican Republic (SDQ)",
    value: "SDQ",
  },
  {
    label: "Santo Domingo, Dr Joaquin Balaguer - Dominican Republic (JBQ)",
    value: "JBQ",
  },
  {
    label: "Santo Domingo, Herrera - Dominican Republic (HEX)",
    value: "HEX",
  },
  {
    label: "Ambato, Chachoan - Ecuador (ATF)",
    value: "ATF",
  },
  {
    label: "Bahia De Caraquez, Los Perales - Ecuador (BHA)",
    value: "BHA",
  },
  {
    label: "Baltra Island, Seymour - Ecuador (GPS)",
    value: "GPS",
  },
  {
    label: "Coca, Francisco De Orellana - Ecuador (OCC)",
    value: "OCC",
  },
  {
    label: "Cuenca, Mariscal Lamar Intl - Ecuador (CUE)",
    value: "CUE",
  },
  {
    label: "Esmeraldas, C Concha Torres Intl - Ecuador (ESM)",
    value: "ESM",
  },
  {
    label: "Guayaquil, Jose Joaquin De Olmedo - Ecuador (GYE)",
    value: "GYE",
  },
  {
    label: "Isabela Island, General Villamil - Ecuador (IBB)",
    value: "IBB",
  },
  {
    label: "Jipijapa, Jipijapa - Ecuador (JIP)",
    value: "JIP",
  },
  {
    label: "Lago Agrio, Lago Agrio - Ecuador (LGQ)",
    value: "LGQ",
  },
  {
    label: "Latacunga, Cotapaxi Intl - Ecuador (LTX)",
    value: "LTX",
  },
  {
    label: "Loja, Catamayo - Ecuador (LOH)",
    value: "LOH",
  },
  {
    label: "Macara, J M Velasco Ibarra - Ecuador (MRR)",
    value: "MRR",
  },
  {
    label: "Macas, Edmundo Carvajal - Ecuador (XMS)",
    value: "XMS",
  },
  {
    label: "Machala, General Manuel Serrano - Ecuador (MCH)",
    value: "MCH",
  },
  {
    label: "Manta, Eloy Alfaro Intl - Ecuador (MEC)",
    value: "MEC",
  },
  {
    label: "Mendez, Mendez - Ecuador (MZD)",
    value: "MZD",
  },
  {
    label: "Pastaza, Rio Amazonas - Ecuador (PTZ)",
    value: "PTZ",
  },
  {
    label: "Portoviejo, Reales Tamarindos - Ecuador (PVO)",
    value: "PVO",
  },
  {
    label: "Putumayo, Putumayo - Ecuador (PYO)",
    value: "PYO",
  },
  {
    label: "Quito - Ecuador (UIO)",
    value: "UIO",
  },
  {
    label: "Quito, Mariscal Sucre Intl - Ecuador (UIO)",
    value: "UIO",
  },
  {
    label: "Salinas, General Ulpiano Paez - Ecuador (SNC)",
    value: "SNC",
  },
  {
    label: "San Cristobal Island, San Cristobal - Ecuador (SCY)",
    value: "SCY",
  },
  {
    label: "Santa Rosa, International - Ecuador (ETR)",
    value: "ETR",
  },
  {
    label: "Sucua, Sucua - Ecuador (SUQ)",
    value: "SUQ",
  },
  {
    label: "Taisha, Taisha - Ecuador (TSC)",
    value: "TSC",
  },
  {
    label: "Tarapoa, Tarapoa - Ecuador (TPC)",
    value: "TPC",
  },
  {
    label: "Tena, Jumandy - Ecuador (TNW)",
    value: "TNW",
  },
  {
    label: "Tiputini, Tiputini - Ecuador (TPN)",
    value: "TPN",
  },
  {
    label: "Tulcan, Luis A Mantilla Intl - Ecuador (TUA)",
    value: "TUA",
  },
  {
    label: "Abu Rudeis, Abu Rudeis - Egypt (AUE)",
    value: "AUE",
  },
  {
    label: "Abu Simbel, Abu Simbel - Egypt (ABS)",
    value: "ABS",
  },
  {
    label: "Alexandria, Borg El Arab - Egypt (HBE)",
    value: "HBE",
  },
  {
    label: "Alexandria, El Nouzha - Egypt (ALY)",
    value: "ALY",
  },
  {
    label: "Aswan, Aswan International - Egypt (ASW)",
    value: "ASW",
  },
  {
    label: "Asyut, Asyut - Egypt (ATZ)",
    value: "ATZ",
  },
  {
    label: "Cairo, Cairo Intl - Egypt (CAI)",
    value: "CAI",
  },
  {
    label: "Dabah, Al Alamain Intl - Egypt (DBB)",
    value: "DBB",
  },
  {
    label: "Dakhla Oasis, Airport - Egypt (DAK)",
    value: "DAK",
  },
  {
    label: "El Arish, International - Egypt (AAC)",
    value: "AAC",
  },
  {
    label: "El Minya, El Minya - Egypt (EMY)",
    value: "EMY",
  },
  {
    label: "El Tor, El Tor - Egypt (ELT)",
    value: "ELT",
  },
  {
    label: "Hurghada, International - Egypt (HRG)",
    value: "HRG",
  },
  {
    label: "Kharga, Kharga - Egypt (UVL)",
    value: "UVL",
  },
  {
    label: "Luxor, International - Egypt (LXR)",
    value: "LXR",
  },
  {
    label: "Marsa Alam, International - Egypt (RMF)",
    value: "RMF",
  },
  {
    label: "Mersa Matruh, Mersa Matruh - Egypt (MUH)",
    value: "MUH",
  },
  {
    label: "Port Said, Port Said - Egypt (PSD)",
    value: "PSD",
  },
  {
    label: "Ramadan City, Wadi Al Jandali - Egypt (TFR)",
    value: "TFR",
  },
  {
    label: "Saint Catherine, International - Egypt (SKV)",
    value: "SKV",
  },
  {
    label: "Shark El Owainat, International - Egypt (GSQ)",
    value: "GSQ",
  },
  {
    label: "Sharm El Sheikh, Sharm El Sheikh Intl - Egypt (SSH)",
    value: "SSH",
  },
  {
    label: "Sidi Barrani, Sidi Barrani - Egypt (SQK)",
    value: "SQK",
  },
  {
    label: "Siwa Oasis, North - Egypt (SEW)",
    value: "SEW",
  },
  {
    label: "Sohag, International - Egypt (HMB)",
    value: "HMB",
  },
  {
    label: "Taba, Taba International - Egypt (TCP)",
    value: "TCP",
  },
  {
    label: "San Salvador, El Salvador Intl - El Salvador (SAL)",
    value: "SAL",
  },
  {
    label: "Annobon, Annobon - Equatorial Guinea (NBN)",
    value: "NBN",
  },
  {
    label: "Bata, Bata - Equatorial Guinea (BSG)",
    value: "BSG",
  },
  {
    label: "Corisco Island, Corisco Island - Equatorial Guinea (OCS)",
    value: "OCS",
  },
  {
    label: "Malabo, International - Equatorial Guinea (SSG)",
    value: "SSG",
  },
  {
    label: "Mongomo, Mongomo - Equatorial Guinea (GEM)",
    value: "GEM",
  },
  {
    label: "Asmara, Asmara International - Eritrea (ASM)",
    value: "ASM",
  },
  {
    label: "Assab, Assab International - Eritrea (ASA)",
    value: "ASA",
  },
  {
    label: "Massawa, Massawa International - Eritrea (MSW)",
    value: "MSW",
  },
  {
    label: "Teseney, Teseney - Eritrea (TES)",
    value: "TES",
  },
  {
    label: "Tallinn - Estonia (TLL)",
    value: "TLL",
  },
  {
    label: "Tallinn, Lennart Meri - Estonia (TLL)",
    value: "TLL",
  },
  {
    label: "Kardla - Estonia (KDL)",
    value: "KDL",
  },
  {
    label: "Kardla, Kardla - Estonia (KDL)",
    value: "KDL",
  },
  {
    label: "Kuressaare - Estonia (URE)",
    value: "URE",
  },
  {
    label: "Kuressaare, Kuressaare - Estonia (URE)",
    value: "URE",
  },
  {
    label: "Parnu - Estonia (EPU)",
    value: "EPU",
  },
  {
    label: "Parnu, Parnu - Estonia (EPU)",
    value: "EPU",
  },
  {
    label: "Tartu - Estonia (TAY)",
    value: "TAY",
  },
  {
    label: "Tartu, Ulenurme - Estonia (TAY)",
    value: "TAY",
  },
  {
    label: "Addis Ababa - Ethiopia (ADD)",
    value: "ADD",
  },
  {
    label: "Addis Ababa, Bole Intl - Ethiopia (ADD)",
    value: "ADD",
  },
  {
    label: "Arba Minch, Arba Minch - Ethiopia (AMH)",
    value: "AMH",
  },
  {
    label: "Asela, Asela - Ethiopia (ALK)",
    value: "ALK",
  },
  {
    label: "Asosa, Asosa - Ethiopia (ASO)",
    value: "ASO",
  },
  {
    label: "Awasa, Awasa - Ethiopia (AWA)",
    value: "AWA",
  },
  {
    label: "Axum, Axum - Ethiopia (AXU)",
    value: "AXU",
  },
  {
    label: "Bahar Dar, Bahar Dar - Ethiopia (BJR)",
    value: "BJR",
  },
  {
    label: "Bedele, Buno Bedele - Ethiopia (XBL)",
    value: "XBL",
  },
  {
    label: "Beica, Beica - Ethiopia (BEI)",
    value: "BEI",
  },
  {
    label: "Bulchi, Bulchi - Ethiopia (BCY)",
    value: "BCY",
  },
  {
    label: "Debre Marcos, Debre Marcos Airport - Ethiopia (DBM)",
    value: "DBM",
  },
  {
    label: "Debre Tabor, Debre Tabor - Ethiopia (DBT)",
    value: "DBT",
  },
  {
    label: "Degeh Bur, Degeh Bur - Ethiopia (DGC)",
    value: "DGC",
  },
  {
    label: "Dembi Dolo, Dembi Dolo - Ethiopia (DEM)",
    value: "DEM",
  },
  {
    label: "Dessie, Kombolcha - Ethiopia (DSE)",
    value: "DSE",
  },
  {
    label: "Dire Dawa, Aba Tenna D Yilma Intl - Ethiopia (DIR)",
    value: "DIR",
  },
  {
    label: "Fincha'a, Fincha'a - Ethiopia (FNH)",
    value: "FNH",
  },
  {
    label: "Gambela, Gambela - Ethiopia (GMB)",
    value: "GMB",
  },
  {
    label: "Geladi, Geladi - Ethiopia (GLC)",
    value: "GLC",
  },
  {
    label: "Ginir, Ginir - Ethiopia (GNN)",
    value: "GNN",
  },
  {
    label: "Goba, Robe - Ethiopia (GOB)",
    value: "GOB",
  },
  {
    label: "Gode, Gode - Ethiopia (GDE)",
    value: "GDE",
  },
  {
    label: "Gonder, Azezo - Ethiopia (GDQ)",
    value: "GDQ",
  },
  {
    label: "Gore, Gore - Ethiopia (GOR)",
    value: "GOR",
  },
  {
    label: "Humera, Humera - Ethiopia (HUE)",
    value: "HUE",
  },
  {
    label: "Inda Selassie, Shire - Ethiopia (SHC)",
    value: "SHC",
  },
  {
    label: "Jijiga, Jijiga - Ethiopia (JIJ)",
    value: "JIJ",
  },
  {
    label: "Jimma, Aba Segud - Ethiopia (JIM)",
    value: "JIM",
  },
  {
    label: "Jinka, Bako - Ethiopia (BCO)",
    value: "BCO",
  },
  {
    label: "Kabri Dehar, Kabri Dehar - Ethiopia (ABK)",
    value: "ABK",
  },
  {
    label: "Kelafo, Kelafo - Ethiopia (LFO)",
    value: "LFO",
  },
  {
    label: "Lalibela, Lalibela - Ethiopia (LLI)",
    value: "LLI",
  },
  {
    label: "Mekane Selam, Mekane Selam - Ethiopia (MKS)",
    value: "MKS",
  },
  {
    label: "Mekele, Alula Aba Nega - Ethiopia (MQX)",
    value: "MQX",
  },
  {
    label: "Mena, Mena - Ethiopia (MZX)",
    value: "MZX",
  },
  {
    label: "Mendi, Masslo - Ethiopia (NDM)",
    value: "NDM",
  },
  {
    label: "Metema, Genda Wuha - Ethiopia (ETE)",
    value: "ETE",
  },
  {
    label: "Mizan Teferi, Mizan Teferi - Ethiopia (MTF)",
    value: "MTF",
  },
  {
    label: "Mota, Mota - Ethiopia (OTA)",
    value: "OTA",
  },
  {
    label: "Mui, Mui - Ethiopia (MUJ)",
    value: "MUJ",
  },
  {
    label: "Negele, Negele - Ethiopia (EGL)",
    value: "EGL",
  },
  {
    label: "Nejo, Nejo - Ethiopia (NEJ)",
    value: "NEJ",
  },
  {
    label: "Nekemte, Nekemte - Ethiopia (NEK)",
    value: "NEK",
  },
  {
    label: "Pawe, Beles - Ethiopia (PWI)",
    value: "PWI",
  },
  {
    label: "Semera, Semera - Ethiopia (SZE)",
    value: "SZE",
  },
  {
    label: "Shakiso, Shakiso - Ethiopia (SKR)",
    value: "SKR",
  },
  {
    label: "Shilabo, Shilabo - Ethiopia (HIL)",
    value: "HIL",
  },
  {
    label: "Sodo, Sodo - Ethiopia (SXU)",
    value: "SXU",
  },
  {
    label: "Tippi, Tippi - Ethiopia (TIE)",
    value: "TIE",
  },
  {
    label: "Tum, Tum - Ethiopia (TUJ)",
    value: "TUJ",
  },
  {
    label: "Waca, Waca - Ethiopia (WAC)",
    value: "WAC",
  },
  {
    label: "Warder, Warder - Ethiopia (WRA)",
    value: "WRA",
  },
  {
    label: "Mount Pleasant, Mount Pleasant - Falkland Islands (MPN)",
    value: "MPN",
  },
  {
    label: "Port Stanley, Port Stanley - Falkland Islands (PSY)",
    value: "PSY",
  },
  {
    label: "Faroe Islands, Vagar - Faroe Islands (FAE)",
    value: "FAE",
  },
  {
    label: "Bua, Dama - Fiji (BVF)",
    value: "BVF",
  },
  {
    label: "Castaway Island, Spb - Fiji (CST)",
    value: "CST",
  },
  {
    label: "Cicia Island, Cicia Island - Fiji (ICI)",
    value: "ICI",
  },
  {
    label: "Gau Island, Gau Island - Fiji (NGI)",
    value: "NGI",
  },
  {
    label: "Kadavu Island, Vunisea - Fiji (KDV)",
    value: "KDV",
  },
  {
    label: "Koro Island, Koro Island - Fiji (KXF)",
    value: "KXF",
  },
  {
    label: "Korolevu, Spb - Fiji (KVU)",
    value: "KVU",
  },
  {
    label: "Labasa, Labasa - Fiji (LBS)",
    value: "LBS",
  },
  {
    label: "Lakeba Island, Lakeba Island - Fiji (LKB)",
    value: "LKB",
  },
  {
    label: "Laucala Island, Laucala Island - Fiji (LUC)",
    value: "LUC",
  },
  {
    label: "Levuka, Bureta - Fiji (LEV)",
    value: "LEV",
  },
  {
    label: "Malolo Lailai Island, Malolo Lailai Island - Fiji (PTF)",
    value: "PTF",
  },
  {
    label: "Mana Island, Mana Island - Fiji (MNF)",
    value: "MNF",
  },
  {
    label: "Moala Island, Moala Island - Fiji (MFJ)",
    value: "MFJ",
  },
  {
    label: "Nadi, Nadi Intl - Fiji (NAN)",
    value: "NAN",
  },
  {
    label: "Nanuya Lagoon, Blue Lagoon Spb - Fiji (BXL)",
    value: "BXL",
  },
  {
    label: "Nanuya Levu, Turtle Island Spb - Fiji (TTL)",
    value: "TTL",
  },
  {
    label: "Natadola, Natadola - Fiji (NTA)",
    value: "NTA",
  },
  {
    label: "Ono-I-Lau, Ono-I-Lau - Fiji (ONU)",
    value: "ONU",
  },
  {
    label: "Pacific Harbour, Pacific Harbour - Fiji (PHR)",
    value: "PHR",
  },
  {
    label: "Rabi Island, Rabi Island - Fiji (RBI)",
    value: "RBI",
  },
  {
    label: "Rotuma Island, Rotuma Island - Fiji (RTA)",
    value: "RTA",
  },
  {
    label: "Saqani, Saqani - Fiji (AQS)",
    value: "AQS",
  },
  {
    label: "Savusavu, Savusavu - Fiji (SVU)",
    value: "SVU",
  },
  {
    label: "Suva, Nausori Intl - Fiji (SUV)",
    value: "SUV",
  },
  {
    label: "Taveuni Island, Matei - Fiji (TVU)",
    value: "TVU",
  },
  {
    label: "Vanuabalavu Island, Vanuabalavu Island - Fiji (VBV)",
    value: "VBV",
  },
  {
    label: "Vatukoula, Vatukoula - Fiji (VAU)",
    value: "VAU",
  },
  {
    label: "Vatulele Island, Vatulele Island - Fiji (VTF)",
    value: "VTF",
  },
  {
    label: "Wakaya Island, Wakaya Island - Fiji (KAY)",
    value: "KAY",
  },
  {
    label: "Yasawa, Yasawa - Fiji (YAS)",
    value: "YAS",
  },
  {
    label: "Helsinki - Finland (HEL)",
    value: "HEL",
  },
  {
    label: "Helsinki, Helsinki Vantaa - Finland (HEL)",
    value: "HEL",
  },
  {
    label: "Helsinki, Helsinki Malmi - Finland (HEM)",
    value: "HEM",
  },
  {
    label: "Oulu - Finland (OUL)",
    value: "OUL",
  },
  {
    label: "Oulu, Oulu - Finland (OUL)",
    value: "OUL",
  },
  {
    label: "Rovaniemi - Finland (RVN)",
    value: "RVN",
  },
  {
    label: "Rovaniemi, Rovaniemi - Finland (RVN)",
    value: "RVN",
  },
  {
    label: "Tampere - Finland (TMP)",
    value: "TMP",
  },
  {
    label: "Tampere, Tampere Pirkkala - Finland (TMP)",
    value: "TMP",
  },
  {
    label: "Turku - Finland (TKU)",
    value: "TKU",
  },
  {
    label: "Turku, Turku - Finland (TKU)",
    value: "TKU",
  },
  {
    label: "Vaasa - Finland (VAA)",
    value: "VAA",
  },
  {
    label: "Vaasa, Vaasa - Finland (VAA)",
    value: "VAA",
  },
  {
    label: "Enontekio - Finland (ENF)",
    value: "ENF",
  },
  {
    label: "Enontekio, Enontekio - Finland (ENF)",
    value: "ENF",
  },
  {
    label: "Halli - Finland (KEV)",
    value: "KEV",
  },
  {
    label: "Halli, Halli - Finland (KEV)",
    value: "KEV",
  },
  {
    label: "Ivalo - Finland (IVL)",
    value: "IVL",
  },
  {
    label: "Ivalo, Ivalo - Finland (IVL)",
    value: "IVL",
  },
  {
    label: "Joensuu - Finland (JOE)",
    value: "JOE",
  },
  {
    label: "Joensuu, Joensuu - Finland (JOE)",
    value: "JOE",
  },
  {
    label: "Jyvaskyla - Finland (JYV)",
    value: "JYV",
  },
  {
    label: "Jyvaskyla, Jyvaskyla - Finland (JYV)",
    value: "JYV",
  },
  {
    label: "Kajaani - Finland (KAJ)",
    value: "KAJ",
  },
  {
    label: "Kajaani, Kajaani - Finland (KAJ)",
    value: "KAJ",
  },
  {
    label: "Kauhajoki - Finland (KHJ)",
    value: "KHJ",
  },
  {
    label: "Kauhajoki, Kauhajoki - Finland (KHJ)",
    value: "KHJ",
  },
  {
    label: "Kauhava - Finland (KAU)",
    value: "KAU",
  },
  {
    label: "Kauhava, Kauhava - Finland (KAU)",
    value: "KAU",
  },
  {
    label: "Kemi Tornio - Finland (KEM)",
    value: "KEM",
  },
  {
    label: "Kemi Tornio, Kemi Tornio - Finland (KEM)",
    value: "KEM",
  },
  {
    label: "Kitee - Finland (KTQ)",
    value: "KTQ",
  },
  {
    label: "Kitee, Kitee - Finland (KTQ)",
    value: "KTQ",
  },
  {
    label: "Kittila - Finland (KTT)",
    value: "KTT",
  },
  {
    label: "Kittila, Kittila - Finland (KTT)",
    value: "KTT",
  },
  {
    label: "Kokkola Pietarsaa - Finland (KOK)",
    value: "KOK",
  },
  {
    label: "Kokkola Pietarsaa, Kruunupyy - Finland (KOK)",
    value: "KOK",
  },
  {
    label: "Kuopio - Finland (KUO)",
    value: "KUO",
  },
  {
    label: "Kuopio, Kuopio - Finland (KUO)",
    value: "KUO",
  },
  {
    label: "Kuusamo - Finland (KAO)",
    value: "KAO",
  },
  {
    label: "Kuusamo, Kuusamo - Finland (KAO)",
    value: "KAO",
  },
  {
    label: "Lappeenranta - Finland (LPP)",
    value: "LPP",
  },
  {
    label: "Lappeenranta, Lappeenranta - Finland (LPP)",
    value: "LPP",
  },
  {
    label: "Mariehamn - Finland (MHQ)",
    value: "MHQ",
  },
  {
    label: "Mariehamn, Mariehamn - Finland (MHQ)",
    value: "MHQ",
  },
  {
    label: "Mikkeli - Finland (MIK)",
    value: "MIK",
  },
  {
    label: "Mikkeli, Mikkeli - Finland (MIK)",
    value: "MIK",
  },
  {
    label: "Pori - Finland (POR)",
    value: "POR",
  },
  {
    label: "Pori, Pori - Finland (POR)",
    value: "POR",
  },
  {
    label: "Savonlinna - Finland (SVL)",
    value: "SVL",
  },
  {
    label: "Savonlinna, Savonlinna - Finland (SVL)",
    value: "SVL",
  },
  {
    label: "Seinajoki - Finland (SJY)",
    value: "SJY",
  },
  {
    label: "Seinajoki, Seinajoki - Finland (SJY)",
    value: "SJY",
  },
  {
    label: "Sodankyla - Finland (SOT)",
    value: "SOT",
  },
  {
    label: "Sodankyla, Sodankyla - Finland (SOT)",
    value: "SOT",
  },
  {
    label: "Utti - Finland (UTI)",
    value: "UTI",
  },
  {
    label: "Utti, Utti - Finland (UTI)",
    value: "UTI",
  },
  {
    label: "Varkaus - Finland (VRK)",
    value: "VRK",
  },
  {
    label: "Varkaus, Varkaus - Finland (VRK)",
    value: "VRK",
  },
  {
    label: "Ylivieska - Finland (YLI)",
    value: "YLI",
  },
  {
    label: "Ylivieska, Ylivieska - Finland (YLI)",
    value: "YLI",
  },
  {
    label: "Ajaccio - France (AJA)",
    value: "AJA",
  },
  {
    label: "Ajaccio, Napoleon Bonaparte - France (AJA)",
    value: "AJA",
  },
  {
    label: "Mulhouse Basel - France (EAP)",
    value: "EAP",
  },
  {
    label: "Mulhouse Basel, Basel Euroairport - France (BSL)",
    value: "BSL",
  },
  {
    label: "Mulhouse Basel, Mulhouse Euroairport - France (MLH)",
    value: "MLH",
  },
  {
    label: "Bastia - France (BIA)",
    value: "BIA",
  },
  {
    label: "Bastia, Poretta - France (BIA)",
    value: "BIA",
  },
  {
    label: "Bergerac - France (EGC)",
    value: "EGC",
  },
  {
    label: "Bergerac, Roumaniere - France (EGC)",
    value: "EGC",
  },
  {
    label: "Biarritz - France (BIQ)",
    value: "BIQ",
  },
  {
    label: "Biarritz, Pays Basque - France (BIQ)",
    value: "BIQ",
  },
  {
    label: "Bordeaux, Merignac - France (BOD)",
    value: "BOD",
  },
  {
    label: "Bordeaux, Merignac - France (BOD)",
    value: "BOD",
  },
  {
    label: "Brest - France (BES)",
    value: "BES",
  },
  {
    label: "Brest, Bretagne - France (BES)",
    value: "BES",
  },
  {
    label: "Figari - France (FSC)",
    value: "FSC",
  },
  {
    label: "Figari, Sud Corse - France (FSC)",
    value: "FSC",
  },
  {
    label: "Lille - France (LIL)",
    value: "LIL",
  },
  {
    label: "Lille, Lesquin - France (LIL)",
    value: "LIL",
  },
  {
    label: "Lyon - France (LYS)",
    value: "LYS",
  },
  {
    label: "Lyon, Saint Exupery - France (LYS)",
    value: "LYS",
  },
  {
    label: "Lyon, Bron - France (LYN)",
    value: "LYN",
  },
  {
    label: "Lyon, Grenoble Saint Geoirs - France (GNB)",
    value: "GNB",
  },
  {
    label: "Marseille - France (MRS)",
    value: "MRS",
  },
  {
    label: "Marseille, Provence - France (MRS)",
    value: "MRS",
  },
  {
    label: "Montpellier - France (MPL)",
    value: "MPL",
  },
  {
    label: "Montpellier, Mediterranee - France (MPL)",
    value: "MPL",
  },
  {
    label: "Nantes - France (NTE)",
    value: "NTE",
  },
  {
    label: "Nantes, Atlantique - France (NTE)",
    value: "NTE",
  },
  {
    label: "Nice - France (NCE)",
    value: "NCE",
  },
  {
    label: "Nice, Cote D Azur - France (NCE)",
    value: "NCE",
  },
  {
    label: "Agen - France (AGF)",
    value: "AGF",
  },
  {
    label: "Agen, La Garenne - France (AGF)",
    value: "AGF",
  },
  {
    label: "Albert - France (BYF)",
    value: "BYF",
  },
  {
    label: "Albert, Bray - France (BYF)",
    value: "BYF",
  },
  {
    label: "Albi - France (LBI)",
    value: "LBI",
  },
  {
    label: "Albi, Le Sequestre - France (LBI)",
    value: "LBI",
  },
  {
    label: "Angers - France (ANE)",
    value: "ANE",
  },
  {
    label: "Angers, Marce - France (ANE)",
    value: "ANE",
  },
  {
    label: "Angouleme, Brie-Champniers - France (ANG)",
    value: "ANG",
  },
  {
    label: "Angouleme, Brie-Champniers - France (ANG)",
    value: "ANG",
  },
  {
    label: "Annecy - France (NCY)",
    value: "NCY",
  },
  {
    label: "Annecy, Meythet - France (NCY)",
    value: "NCY",
  },
  {
    label: "Aubenas - France (OBS)",
    value: "OBS",
  },
  {
    label: "Aubenas, Ardeche Meridionale - France (OBS)",
    value: "OBS",
  },
  {
    label: "Aurillac - France (AUR)",
    value: "AUR",
  },
  {
    label: "Aurillac, Aurillac - France (AUR)",
    value: "AUR",
  },
  {
    label: "Auxerre - France (AUF)",
    value: "AUF",
  },
  {
    label: "Auxerre, Branches - France (AUF)",
    value: "AUF",
  },
  {
    label: "Avignon - France (AVN)",
    value: "AVN",
  },
  {
    label: "Avignon, Caumont - France (AVN)",
    value: "AVN",
  },
  {
    label: "Barcelonnette - France (BAE)",
    value: "BAE",
  },
  {
    label: "Barcelonnette, Saint Pons - France (BAE)",
    value: "BAE",
  },
  {
    label: "Beziers - France (BZR)",
    value: "BZR",
  },
  {
    label: "Beziers, Vias - France (BZR)",
    value: "BZR",
  },
  {
    label: "Bourges - France (BOU)",
    value: "BOU",
  },
  {
    label: "Bourges, Bourges - France (BOU)",
    value: "BOU",
  },
  {
    label: "Brive La Gaillard - France (BVE)",
    value: "BVE",
  },
  {
    label: "Brive La Gaillard, Vallee De La Dordogne - France (BVE)",
    value: "BVE",
  },
  {
    label: "Caen - France (CFR)",
    value: "CFR",
  },
  {
    label: "Caen, Carpiquet - France (CFR)",
    value: "CFR",
  },
  {
    label: "Cahors - France (ZAO)",
    value: "ZAO",
  },
  {
    label: "Cahors, Lalbenque - France (ZAO)",
    value: "ZAO",
  },
  {
    label: "Calais Dunkerque - France (CQF)",
    value: "CQF",
  },
  {
    label: "Calais Dunkerque, Calais - France (CQF)",
    value: "CQF",
  },
  {
    label: "Calvi - France (CLY)",
    value: "CLY",
  },
  {
    label: "Calvi, Sainte Catherine - France (CLY)",
    value: "CLY",
  },
  {
    label: "Cannes - France (CEQ)",
    value: "CEQ",
  },
  {
    label: "Cannes, Mandelieu - France (CEQ)",
    value: "CEQ",
  },
  {
    label: "Carcassonne - France (CCF)",
    value: "CCF",
  },
  {
    label: "Carcassonne, Salvaza - France (CCF)",
    value: "CCF",
  },
  {
    label: "Castres - France (DCM)",
    value: "DCM",
  },
  {
    label: "Castres, Mazamet - France (DCM)",
    value: "DCM",
  },
  {
    label: "Chambery Aix Les Bains - France (CMF)",
    value: "CMF",
  },
  {
    label: "Chambery Aix Les Bains, Chambery Aix Les Bains - France (CMF)",
    value: "CMF",
  },
  {
    label: "Chateauroux - France (CHR)",
    value: "CHR",
  },
  {
    label: "Chateauroux, Deols - France (CHR)",
    value: "CHR",
  },
  {
    label: "Cherbourg - France (CER)",
    value: "CER",
  },
  {
    label: "Cherbourg, Maupertus - France (CER)",
    value: "CER",
  },
  {
    label: "Cholet - France (CET)",
    value: "CET",
  },
  {
    label: "Cholet, Le Pontreau - France (CET)",
    value: "CET",
  },
  {
    label: "City Name Test - France (III)",
    value: "III",
  },
  {
    label: "City Name Test, City Name Test - France (III)",
    value: "III",
  },
  {
    label: "City Name Test Three - France (VVE)",
    value: "VVE",
  },
  {
    label: "City Name Test Three, City Name Test Three - France (VVE)",
    value: "VVE",
  },
  {
    label: "Clermont Ferrand - France (CFE)",
    value: "CFE",
  },
  {
    label: "Clermont Ferrand, Auvergne - France (CFE)",
    value: "CFE",
  },
  {
    label: "Cognac - France (CNG)",
    value: "CNG",
  },
  {
    label: "Cognac, Chateaubernard - France (CNG)",
    value: "CNG",
  },
  {
    label: "Colmar - France (CMR)",
    value: "CMR",
  },
  {
    label: "Colmar, Houssen - France (CMR)",
    value: "CMR",
  },
  {
    label: "Courchevel - France (CVF)",
    value: "CVF",
  },
  {
    label: "Courchevel, Courchevel - France (CVF)",
    value: "CVF",
  },
  {
    label: "Creil - France (CSF)",
    value: "CSF",
  },
  {
    label: "Creil, Creil - France (CSF)",
    value: "CSF",
  },
  {
    label: "Deauville - France (DOL)",
    value: "DOL",
  },
  {
    label: "Deauville, Saint Gatien - France (DOL)",
    value: "DOL",
  },
  {
    label: "Dieppe - France (DPE)",
    value: "DPE",
  },
  {
    label: "Dieppe, Saint Aubin - France (DPE)",
    value: "DPE",
  },
  {
    label: "Dijon - France (DIJ)",
    value: "DIJ",
  },
  {
    label: "Dijon, Longvic - France (DIJ)",
    value: "DIJ",
  },
  {
    label: "Dinard St Malo - France (DNR)",
    value: "DNR",
  },
  {
    label: "Dinard St Malo, Pleurtuit - France (DNR)",
    value: "DNR",
  },
  {
    label: "Dole - France (DLE)",
    value: "DLE",
  },
  {
    label: "Dole, Tavaux - France (DLE)",
    value: "DLE",
  },
  {
    label: "Epinal - France (EPL)",
    value: "EPL",
  },
  {
    label: "Epinal, Mirecourt - France (EPL)",
    value: "EPL",
  },
  {
    label: "Evreux - France (EVX)",
    value: "EVX",
  },
  {
    label: "Evreux, Fauville - France (EVX)",
    value: "EVX",
  },
  {
    label: "Gap - France (GAT)",
    value: "GAT",
  },
  {
    label: "Gap, Tallard - France (GAT)",
    value: "GAT",
  },
  {
    label: "Granville - France (GFR)",
    value: "GFR",
  },
  {
    label: "Granville, Granville - France (GFR)",
    value: "GFR",
  },
  {
    label: "Hazebrouck - France (HZB)",
    value: "HZB",
  },
  {
    label: "Hazebrouck, Merville Colonne - France (HZB)",
    value: "HZB",
  },
  {
    label: "Ile D'yeu - France (IDY)",
    value: "IDY",
  },
  {
    label: "Ile D'yeu, Ile D'yeu - France (IDY)",
    value: "IDY",
  },
  {
    label: "La Baule Escoublac - France (LBY)",
    value: "LBY",
  },
  {
    label: "La Baule Escoublac, La Baule Escoublac - France (LBY)",
    value: "LBY",
  },
  {
    label: "La Roche Sur Yon - France (EDM)",
    value: "EDM",
  },
  {
    label: "La Roche Sur Yon, Les Ajoncs - France (EDM)",
    value: "EDM",
  },
  {
    label: "La Rochelle - France (LRH)",
    value: "LRH",
  },
  {
    label: "La Rochelle, Ile De Re - France (LRH)",
    value: "LRH",
  },
  {
    label: "L'alpe D'huez - France (AHZ)",
    value: "AHZ",
  },
  {
    label: "L'alpe D'huez, L'alpe D'huez - France (AHZ)",
    value: "AHZ",
  },
  {
    label: "Landivisiau - France (LDV)",
    value: "LDV",
  },
  {
    label: "Landivisiau, Landivisiau - France (LDV)",
    value: "LDV",
  },
  {
    label: "Lannion - France (LAI)",
    value: "LAI",
  },
  {
    label: "Lannion, Lannion - France (LAI)",
    value: "LAI",
  },
  {
    label: "Laval - France (LVA)",
    value: "LVA",
  },
  {
    label: "Laval, Entrammes - France (LVA)",
    value: "LVA",
  },
  {
    label: "Le Castellet - France (CTT)",
    value: "CTT",
  },
  {
    label: "Le Castellet, Le Castellet - France (CTT)",
    value: "CTT",
  },
  {
    label: "Le Havre - France (LEH)",
    value: "LEH",
  },
  {
    label: "Le Havre, Octeville - France (LEH)",
    value: "LEH",
  },
  {
    label: "Le Mans - France (LME)",
    value: "LME",
  },
  {
    label: "Le Mans, Arnage - France (LME)",
    value: "LME",
  },
  {
    label: "Le Puy - France (LPY)",
    value: "LPY",
  },
  {
    label: "Le Puy, Loudes - France (LPY)",
    value: "LPY",
  },
  {
    label: "Le Touquet Paris Plage - France (LTQ)",
    value: "LTQ",
  },
  {
    label: "Le Touquet Paris Plage, Le Touquet Paris Plage - France (LTQ)",
    value: "LTQ",
  },
  {
    label: "Les Sables - France (LSO)",
    value: "LSO",
  },
  {
    label: "Les Sables, Talmont - France (LSO)",
    value: "LSO",
  },
  {
    label: "Limoges - France (LIG)",
    value: "LIG",
  },
  {
    label: "Limoges, Bellegarde - France (LIG)",
    value: "LIG",
  },
  {
    label: "Lorient - France (LRT)",
    value: "LRT",
  },
  {
    label: "Lorient, Lann Bihoue - France (LRT)",
    value: "LRT",
  },
  {
    label: "Lourdes Tarbes - France (LDE)",
    value: "LDE",
  },
  {
    label: "Lourdes Tarbes, Pyrenees - France (LDE)",
    value: "LDE",
  },
  {
    label: "Megeve - France (MVV)",
    value: "MVV",
  },
  {
    label: "Megeve, Megeve - France (MVV)",
    value: "MVV",
  },
  {
    label: "Mende - France (MEN)",
    value: "MEN",
  },
  {
    label: "Mende, Brenoux - France (MEN)",
    value: "MEN",
  },
  {
    label: "Meribel - France (MFX)",
    value: "MFX",
  },
  {
    label: "Meribel, Meribel - France (MFX)",
    value: "MFX",
  },
  {
    label: "Metz Nancy - France (ETZ)",
    value: "ETZ",
  },
  {
    label: "Metz Nancy, Lorraine - France (ETZ)",
    value: "ETZ",
  },
  {
    label: "Metz Nancy, Frescaty - France (MZM)",
    value: "MZM",
  },
  {
    label: "Metz Nancy, Nancy Essey - France (ENC)",
    value: "ENC",
  },
  {
    label: "Mont Dauphin - France (SCP)",
    value: "SCP",
  },
  {
    label: "Mont Dauphin, Saint Crepin - France (SCP)",
    value: "SCP",
  },
  {
    label: "Montlucon - France (MCU)",
    value: "MCU",
  },
  {
    label: "Montlucon, Gueret - France (MCU)",
    value: "MCU",
  },
  {
    label: "Morlaix - France (MXN)",
    value: "MXN",
  },
  {
    label: "Morlaix, Ploujean - France (MXN)",
    value: "MXN",
  },
  {
    label: "Nevers - France (NVS)",
    value: "NVS",
  },
  {
    label: "Nevers, Fourchambault - France (NVS)",
    value: "NVS",
  },
  {
    label: "Nimes - France (FNI)",
    value: "FNI",
  },
  {
    label: "Nimes, Garons - France (FNI)",
    value: "FNI",
  },
  {
    label: "Niort - France (NIT)",
    value: "NIT",
  },
  {
    label: "Niort, Souche - France (NIT)",
    value: "NIT",
  },
  {
    label: "Offline Point - France (QAF)",
    value: "QAF",
  },
  {
    label: "Offline Point, Offline Point - France (QAF)",
    value: "QAF",
  },
  {
    label: "Orleans - France (ORE)",
    value: "ORE",
  },
  {
    label: "Orleans, Saint Denis De L Hotel - France (ORE)",
    value: "ORE",
  },
  {
    label: "Paris - France (PAR)",
    value: "PAR",
  },
  {
    label: "Paris, Charles De Gaulle - France (CDG)",
    value: "CDG",
  },
  {
    label: "Paris, Orly - France (ORY)",
    value: "ORY",
  },
  {
    label: "Paris, Beauvais Tille - France (BVA)",
    value: "BVA",
  },
  {
    label: "Paris, Chalons Vatry - France (XCR)",
    value: "XCR",
  },
  {
    label: "Paris, Issy Les Moulineaux Hp - France (JDP)",
    value: "JDP",
  },
  {
    label: "Paris, Le Bourget - France (LBG)",
    value: "LBG",
  },
  {
    label: "Paris, Pontoise Cormeilles - France (POX)",
    value: "POX",
  },
  {
    label: "Paris, Toussus Le Noble - France (TNF)",
    value: "TNF",
  },
  {
    label: "Paris, Villacoublay Velizy - France (VIY)",
    value: "VIY",
  },
  {
    label: "Strasbourg - France (SXB)",
    value: "SXB",
  },
  {
    label: "Strasbourg, Entzheim - France (SXB)",
    value: "SXB",
  },
  {
    label: "Toulon - France (TLN)",
    value: "TLN",
  },
  {
    label: "Toulon, Hyeres - France (TLN)",
    value: "TLN",
  },
  {
    label: "Toulouse - France (TLS)",
    value: "TLS",
  },
  {
    label: "Toulouse, Blagnac - France (TLS)",
    value: "TLS",
  },
  {
    label: "Pau - France (PUF)",
    value: "PUF",
  },
  {
    label: "Pau, Pyrenees - France (PUF)",
    value: "PUF",
  },
  {
    label: "Perigueux - France (PGX)",
    value: "PGX",
  },
  {
    label: "Perigueux, Bassillac - France (PGX)",
    value: "PGX",
  },
  {
    label: "Perpignan - France (PGF)",
    value: "PGF",
  },
  {
    label: "Perpignan, Rivesaltes - France (PGF)",
    value: "PGF",
  },
  {
    label: "Poitiers - France (PIS)",
    value: "PIS",
  },
  {
    label: "Poitiers, Biard - France (PIS)",
    value: "PIS",
  },
  {
    label: "Propriano - France (PRP)",
    value: "PRP",
  },
  {
    label: "Propriano, Propriano - France (PRP)",
    value: "PRP",
  },
  {
    label: "Quimper - France (UIP)",
    value: "UIP",
  },
  {
    label: "Quimper, Pluguffan - France (UIP)",
    value: "UIP",
  },
  {
    label: "Reims - France (RHE)",
    value: "RHE",
  },
  {
    label: "Reims, Champagne - France (RHE)",
    value: "RHE",
  },
  {
    label: "Rennes - France (RNS)",
    value: "RNS",
  },
  {
    label: "Rennes, Saint Jacques - France (RNS)",
    value: "RNS",
  },
  {
    label: "Roanne - France (RNE)",
    value: "RNE",
  },
  {
    label: "Roanne, Renaison - France (RNE)",
    value: "RNE",
  },
  {
    label: "Rochefort - France (RCO)",
    value: "RCO",
  },
  {
    label: "Rochefort, Saint Agnant - France (RCO)",
    value: "RCO",
  },
  {
    label: "Rodez - France (RDZ)",
    value: "RDZ",
  },
  {
    label: "Rodez, Marcillac - France (RDZ)",
    value: "RDZ",
  },
  {
    label: "Rouen - France (URO)",
    value: "URO",
  },
  {
    label: "Rouen, Vallee De Seine - France (URO)",
    value: "URO",
  },
  {
    label: "Royan - France (RYN)",
    value: "RYN",
  },
  {
    label: "Royan, Medis - France (RYN)",
    value: "RYN",
  },
  {
    label: "Saint Brieuc - France (SBK)",
    value: "SBK",
  },
  {
    label: "Saint Brieuc, Armor - France (SBK)",
    value: "SBK",
  },
  {
    label: "Saint Etienne - France (EBU)",
    value: "EBU",
  },
  {
    label: "Saint Etienne, Boutheon - France (EBU)",
    value: "EBU",
  },
  {
    label: "Saint Nazaire - France (SNR)",
    value: "SNR",
  },
  {
    label: "Saint Nazaire, Montoir - France (SNR)",
    value: "SNR",
  },
  {
    label: "Saint Tropez - France (LTT)",
    value: "LTT",
  },
  {
    label: "Saint Tropez, La Mole - France (LTT)",
    value: "LTT",
  },
  {
    label: "Saint Yan - France (SYT)",
    value: "SYT",
  },
  {
    label: "Saint Yan, Saint Yan - France (SYT)",
    value: "SYT",
  },
  {
    label: "Solenzara - France (SOZ)",
    value: "SOZ",
  },
  {
    label: "Solenzara, Solenzara - France (SOZ)",
    value: "SOZ",
  },
  {
    label: "Tours - France (TUF)",
    value: "TUF",
  },
  {
    label: "Tours, Val De Loire - France (TUF)",
    value: "TUF",
  },
  {
    label: "Val-D'isere - France (VAZ)",
    value: "VAZ",
  },
  {
    label: "Val-D'isere, Val-D'isere - France (VAZ)",
    value: "VAZ",
  },
  {
    label: "Valence - France (VAF)",
    value: "VAF",
  },
  {
    label: "Valence, Chabeuil - France (VAF)",
    value: "VAF",
  },
  {
    label: "Vannes - France (VNE)",
    value: "VNE",
  },
  {
    label: "Vannes, Meucon - France (VNE)",
    value: "VNE",
  },
  {
    label: "Vichy - France (VHY)",
    value: "VHY",
  },
  {
    label: "Vichy, Charmeil - France (VHY)",
    value: "VHY",
  },
  {
    label: "Vittel - France (VTL)",
    value: "VTL",
  },
  {
    label: "Vittel, Champ De Course - France (VTL)",
    value: "VTL",
  },
  {
    label: "Cayenne, Felix Eboue - French Guiana (CAY)",
    value: "CAY",
  },
  {
    label: "Maripasoula, Maripasoula - French Guiana (MPY)",
    value: "MPY",
  },
  {
    label: "Regina, Regina - French Guiana (REI)",
    value: "REI",
  },
  {
    label: "Saint Georges De Loy, Saint Georges Oyapock - French Guiana (OYP)",
    value: "OYP",
  },
  {
    label:
      "Saint Laurent Du Maroni, Saint Laurent Du Maroni - French Guiana (LDX)",
    value: "LDX",
  },
  {
    label: "Saul, Saul - French Guiana (XAU)",
    value: "XAU",
  },
  {
    label: "Ahe, Ahe - French Polynesia (AHE)",
    value: "AHE",
  },
  {
    label: "Anaa, Anaa - French Polynesia (AAA)",
    value: "AAA",
  },
  {
    label: "Apataki, Apataki - French Polynesia (APK)",
    value: "APK",
  },
  {
    label: "Aratika, Aratika-Nord - French Polynesia (RKA)",
    value: "RKA",
  },
  {
    label: "Arutua, Arutua - French Polynesia (AXR)",
    value: "AXR",
  },
  {
    label: "Bora Bora, Motu Mute - French Polynesia (BOB)",
    value: "BOB",
  },
  {
    label: "Faaite, Faaite - French Polynesia (FAC)",
    value: "FAC",
  },
  {
    label: "Fakahina, Fakahina - French Polynesia (FHZ)",
    value: "FHZ",
  },
  {
    label: "Fakarava, Fakarava - French Polynesia (FAV)",
    value: "FAV",
  },
  {
    label: "Fangatau, Fangatau - French Polynesia (FGU)",
    value: "FGU",
  },
  {
    label: "Hao, Hao - French Polynesia (HOI)",
    value: "HOI",
  },
  {
    label: "Hikueru, Hikueru - French Polynesia (HHZ)",
    value: "HHZ",
  },
  {
    label: "Hiva Oa, Atuona - French Polynesia (AUQ)",
    value: "AUQ",
  },
  {
    label: "Huahine, Fare - French Polynesia (HUH)",
    value: "HUH",
  },
  {
    label: "Katiu, Katiu - French Polynesia (KXU)",
    value: "KXU",
  },
  {
    label: "Kauehi, Kauehi - French Polynesia (KHZ)",
    value: "KHZ",
  },
  {
    label: "Kaukura, Kaukura - French Polynesia (KKR)",
    value: "KKR",
  },
  {
    label: "Makemo, Makemo - French Polynesia (MKP)",
    value: "MKP",
  },
  {
    label: "Manihi, Manihi - French Polynesia (XMH)",
    value: "XMH",
  },
  {
    label: "Mataiva, Mataiva - French Polynesia (MVT)",
    value: "MVT",
  },
  {
    label: "Maupiti, Maupiti - French Polynesia (MAU)",
    value: "MAU",
  },
  {
    label: "Moorea, Temae - French Polynesia (MOZ)",
    value: "MOZ",
  },
  {
    label: "Moruroa Atoll, Moruroa Atoll - French Polynesia (UOA)",
    value: "UOA",
  },
  {
    label: "Napuka, Napuka - French Polynesia (NAU)",
    value: "NAU",
  },
  {
    label: "Niau, Niau - French Polynesia (NIU)",
    value: "NIU",
  },
  {
    label: "Nuku Hiva, Nuku Hiva - French Polynesia (NHV)",
    value: "NHV",
  },
  {
    label: "Nukutavake, Nukutavake - French Polynesia (NUK)",
    value: "NUK",
  },
  {
    label: "Nukutepipi, Nukutepipi - French Polynesia (NKP)",
    value: "NKP",
  },
  {
    label: "Puka Puka, Puka Puka - French Polynesia (PKP)",
    value: "PKP",
  },
  {
    label: "Pukarua, Pukarua - French Polynesia (PUK)",
    value: "PUK",
  },
  {
    label: "Raiatea, Uturoa - French Polynesia (RFP)",
    value: "RFP",
  },
  {
    label: "Raivavae, Raivavae - French Polynesia (RVV)",
    value: "RVV",
  },
  {
    label: "Rangiroa, Rangiroa - French Polynesia (RGI)",
    value: "RGI",
  },
  {
    label: "Raroia, Raroia - French Polynesia (RRR)",
    value: "RRR",
  },
  {
    label: "Reao, Reao - French Polynesia (REA)",
    value: "REA",
  },
  {
    label: "Rimatara, Rimatara - French Polynesia (RMT)",
    value: "RMT",
  },
  {
    label: "Rurutu, Rurutu - French Polynesia (RUR)",
    value: "RUR",
  },
  {
    label: "Tahiti, Faaa - French Polynesia (PPT)",
    value: "PPT",
  },
  {
    label: "Takapoto, Takapoto - French Polynesia (TKP)",
    value: "TKP",
  },
  {
    label: "Takaroa, Takaroa - French Polynesia (TKX)",
    value: "TKX",
  },
  {
    label: "Takume, Takume - French Polynesia (TJN)",
    value: "TJN",
  },
  {
    label: "Tatakoto, Tatakoto - French Polynesia (TKV)",
    value: "TKV",
  },
  {
    label: "Tetiaroa, Tetiaroa - French Polynesia (TTI)",
    value: "TTI",
  },
  {
    label: "Tikehau, Tikehau - French Polynesia (TIH)",
    value: "TIH",
  },
  {
    label: "Totegegie, Totegegie - French Polynesia (GMR)",
    value: "GMR",
  },
  {
    label: "Tubuai, Mataura - French Polynesia (TUB)",
    value: "TUB",
  },
  {
    label: "Tupai, Tupai - French Polynesia (TPX)",
    value: "TPX",
  },
  {
    label: "Tureia, Tureia - French Polynesia (ZTA)",
    value: "ZTA",
  },
  {
    label: "Ua Huka, Ua Huka - French Polynesia (UAH)",
    value: "UAH",
  },
  {
    label: "Ua Pou, Ua Pou - French Polynesia (UAP)",
    value: "UAP",
  },
  {
    label: "Vahitahi, Vahitahi - French Polynesia (VHZ)",
    value: "VHZ",
  },
  {
    label: "Akieni, Akieni - Gabon (AKE)",
    value: "AKE",
  },
  {
    label: "Alowe, Alowe - Gabon (AWE)",
    value: "AWE",
  },
  {
    label: "Biawonque, Biawonque - Gabon (BAW)",
    value: "BAW",
  },
  {
    label: "Bitam, Bitam - Gabon (BMM)",
    value: "BMM",
  },
  {
    label: "Bongo, Bongo - Gabon (BGP)",
    value: "BGP",
  },
  {
    label: "Booue, Booue - Gabon (BGB)",
    value: "BGB",
  },
  {
    label: "Fougamou, Fougamou - Gabon (FOU)",
    value: "FOU",
  },
  {
    label: "Franceville, Mvengue - Gabon (MVB)",
    value: "MVB",
  },
  {
    label: "Gamba, Yenzi - Gabon (GAX)",
    value: "GAX",
  },
  {
    label: "Iguela, Tchongorove - Gabon (IGE)",
    value: "IGE",
  },
  {
    label: "Kongoboumba, Kongoboumba - Gabon (GKO)",
    value: "GKO",
  },
  {
    label: "Koulamoutou, Koulamoutou - Gabon (KOU)",
    value: "KOU",
  },
  {
    label: "Lambarene, Lambarene - Gabon (LBQ)",
    value: "LBQ",
  },
  {
    label: "Lastoursville, Lastoursville - Gabon (LTL)",
    value: "LTL",
  },
  {
    label: "Lekoni, Lekoni - Gabon (LEO)",
    value: "LEO",
  },
  {
    label: "Libreville, Leon M'ba Intl - Gabon (LBV)",
    value: "LBV",
  },
  {
    label: "Makokou, Makokou - Gabon (MKU)",
    value: "MKU",
  },
  {
    label: "Mandji, Mandji - Gabon (KMD)",
    value: "KMD",
  },
  {
    label: "Manega, Manega - Gabon (MGO)",
    value: "MGO",
  },
  {
    label: "Mayumba, Mayumba - Gabon (MYB)",
    value: "MYB",
  },
  {
    label: "Mbigou, Mbigou - Gabon (MBC)",
    value: "MBC",
  },
  {
    label: "Medouneu, Medouneu - Gabon (MDV)",
    value: "MDV",
  },
  {
    label: "Mekambo, Mekambo - Gabon (MKB)",
    value: "MKB",
  },
  {
    label: "Mevang, Mevang - Gabon (MVG)",
    value: "MVG",
  },
  {
    label: "Miele Mimbale, Miele Mimbale - Gabon (GIM)",
    value: "GIM",
  },
  {
    label: "Minvoul, Minvoul - Gabon (MVX)",
    value: "MVX",
  },
  {
    label: "Mitzic, Mitzic - Gabon (MZC)",
    value: "MZC",
  },
  {
    label: "Moabi, Moabi - Gabon (MGX)",
    value: "MGX",
  },
  {
    label: "Moanda, Moanda - Gabon (MFF)",
    value: "MFF",
  },
  {
    label: "Mouila, Mouila-Ville - Gabon (MJL)",
    value: "MJL",
  },
  {
    label: "Ndende, Ndende - Gabon (KDN)",
    value: "KDN",
  },
  {
    label: "Ndjole, Ndjole-Ville - Gabon (KDJ)",
    value: "KDJ",
  },
  {
    label: "Nkan, Nkan - Gabon (NKA)",
    value: "NKA",
  },
  {
    label: "Okondja, Okondja - Gabon (OKN)",
    value: "OKN",
  },
  {
    label: "Omboue, Omboue-Hopital - Gabon (OMB)",
    value: "OMB",
  },
  {
    label: "Ouanga, Ouanga - Gabon (OUU)",
    value: "OUU",
  },
  {
    label: "Owendo, Owendo - Gabon (OWE)",
    value: "OWE",
  },
  {
    label: "Oyem, Oyem - Gabon (OYE)",
    value: "OYE",
  },
  {
    label: "Port Gentil, International - Gabon (POG)",
    value: "POG",
  },
  {
    label: "Sette Cama, Sette Cama - Gabon (ZKM)",
    value: "ZKM",
  },
  {
    label: "Tchibanga, Tchibanga - Gabon (TCH)",
    value: "TCH",
  },
  {
    label: "Wagny, Wagny - Gabon (WGY)",
    value: "WGY",
  },
  {
    label: "Wora Na Yeno, Wora Na Yeno - Gabon (WNE)",
    value: "WNE",
  },
  {
    label: "Banjul, International - Gambia (BJL)",
    value: "BJL",
  },
  {
    label: "Tbilisi - Georgia (TBS)",
    value: "TBS",
  },
  {
    label: "Tbilisi, International - Georgia (TBS)",
    value: "TBS",
  },
  {
    label: "Batumi - Georgia (BUS)",
    value: "BUS",
  },
  {
    label: "Batumi, Batumi - Georgia (BUS)",
    value: "BUS",
  },
  {
    label: "Kutaisi - Georgia (KUT)",
    value: "KUT",
  },
  {
    label: "Kutaisi, Kopitnari - Georgia (KUT)",
    value: "KUT",
  },
  {
    label: "Sokhumi - Georgia (SUI)",
    value: "SUI",
  },
  {
    label: "Sokhumi, Dranda - Georgia (SUI)",
    value: "SUI",
  },
  {
    label: "Memmingen - Germany (FMM)",
    value: "FMM",
  },
  {
    label: "Memmingen, Allgaeu - Germany (FMM)",
    value: "FMM",
  },
  {
    label: "Berlin - Germany (BER)",
    value: "BER",
  },
  {
    label: "Berlin, Brandenburg - Germany (BER)",
    value: "BER",
  },
  {
    label: "Berlin, Schoenefeld - Germany (SXF)",
    value: "SXF",
  },
  {
    label: "Berlin, Tegel - Germany (TXL)",
    value: "TXL",
  },
  {
    label: "Bremen - Germany (BRE)",
    value: "BRE",
  },
  {
    label: "Bremen, Bremen - Germany (BRE)",
    value: "BRE",
  },
  {
    label: "Cologne/Bonn - Germany (CGN)",
    value: "CGN",
  },
  {
    label: "Cologne/Bonn, Cologne - Germany (CGN)",
    value: "CGN",
  },
  {
    label: "Dortmund - Germany (DTM)",
    value: "DTM",
  },
  {
    label: "Dortmund, Dortmund - Germany (DTM)",
    value: "DTM",
  },
  {
    label: "Dresden - Germany (DRS)",
    value: "DRS",
  },
  {
    label: "Dresden, Dresden - Germany (DRS)",
    value: "DRS",
  },
  {
    label: "Duesseldorf - Germany (DUS)",
    value: "DUS",
  },
  {
    label: "Duesseldorf, International Airport - Germany (DUS)",
    value: "DUS",
  },
  {
    label: "Duesseldorf, Moenchengladbach Apt - Germany (MGL)",
    value: "MGL",
  },
  {
    label: "Duesseldorf, Weeze Airport - Germany (NRN)",
    value: "NRN",
  },
  {
    label: "Frankfurt - Germany (FRA)",
    value: "FRA",
  },
  {
    label: "Frankfurt, Frankfurt Intl - Germany (FRA)",
    value: "FRA",
  },
  {
    label: "Frankfurt, Hahn Airport - Germany (HHN)",
    value: "HHN",
  },
  {
    label: "Friedrichshafen - Germany (FDH)",
    value: "FDH",
  },
  {
    label: "Friedrichshafen, Friedrichshafen - Germany (FDH)",
    value: "FDH",
  },
  {
    label: "Hamburg - Germany (HAM)",
    value: "HAM",
  },
  {
    label: "Hamburg, Hamburg - Germany (HAM)",
    value: "HAM",
  },
  {
    label: "Hamburg, Luebeck-Blankensee - Germany (LBC)",
    value: "LBC",
  },
  {
    label: "Hannover - Germany (HAJ)",
    value: "HAJ",
  },
  {
    label: "Hannover, Hannover - Germany (HAJ)",
    value: "HAJ",
  },
  {
    label: "Karlsruhe/Baden Baden - Germany (FKB)",
    value: "FKB",
  },
  {
    label: "Karlsruhe/Baden Baden, Baden Airpark - Germany (FKB)",
    value: "FKB",
  },
  {
    label: "Leipzig/Halle - Germany (LEJ)",
    value: "LEJ",
  },
  {
    label: "Leipzig/Halle, Leipzig - Germany (LEJ)",
    value: "LEJ",
  },
  {
    label: "Munich - Germany (MUC)",
    value: "MUC",
  },
  {
    label: "Munich, Munich International - Germany (MUC)",
    value: "MUC",
  },
  {
    label: "Munich, Airport - Germany (AGB)",
    value: "AGB",
  },
  {
    label: "Muenster/Osnabrueck - Germany (FMO)",
    value: "FMO",
  },
  {
    label: "Muenster/Osnabrueck, Muenster - Germany (FMO)",
    value: "FMO",
  },
  {
    label: "Nuremberg - Germany (NUE)",
    value: "NUE",
  },
  {
    label: "Nuremberg, Nuremberg Airport - Germany (NUE)",
    value: "NUE",
  },
  {
    label: "Paderborn/Lippstadt - Germany (PAD)",
    value: "PAD",
  },
  {
    label: "Paderborn/Lippstadt, Paderborn - Germany (PAD)",
    value: "PAD",
  },
  {
    label: "Stuttgart - Germany (STR)",
    value: "STR",
  },
  {
    label: "Stuttgart, Stuttgart Airport - Germany (STR)",
    value: "STR",
  },
  {
    label: "Aachen - Germany (AAH)",
    value: "AAH",
  },
  {
    label: "Aachen, Merzbrueck - Germany (AAH)",
    value: "AAH",
  },
  {
    label: "Altenburg - Germany (AOC)",
    value: "AOC",
  },
  {
    label: "Altenburg, Altenburg Nobitz - Germany (AOC)",
    value: "AOC",
  },
  {
    label: "Baltrum - Germany (BMR)",
    value: "BMR",
  },
  {
    label: "Baltrum, Baltrum - Germany (BMR)",
    value: "BMR",
  },
  {
    label: "Bayreuth - Germany (BYU)",
    value: "BYU",
  },
  {
    label: "Bayreuth, Bindlacher Berg - Germany (BYU)",
    value: "BYU",
  },
  {
    label: "Bielefeld - Germany (BFE)",
    value: "BFE",
  },
  {
    label: "Bielefeld, Windelsbleiche - Germany (BFE)",
    value: "BFE",
  },
  {
    label: "Borkum - Germany (BMK)",
    value: "BMK",
  },
  {
    label: "Borkum, Borkum - Germany (BMK)",
    value: "BMK",
  },
  {
    label: "Braunschweig/Wolfsburg - Germany (BWE)",
    value: "BWE",
  },
  {
    label: "Braunschweig/Wolfsburg, Braunschweig - Germany (BWE)",
    value: "BWE",
  },
  {
    label: "Bremerhaven - Germany (BRV)",
    value: "BRV",
  },
  {
    label: "Bremerhaven, Bremerhaven - Germany (BRV)",
    value: "BRV",
  },
  {
    label: "Burg Feuerstein - Germany (URD)",
    value: "URD",
  },
  {
    label: "Burg Feuerstein, Burg Feuerstein - Germany (URD)",
    value: "URD",
  },
  {
    label: "Cottbus - Germany (CBU)",
    value: "CBU",
  },
  {
    label: "Cottbus, Drewitz Apt - Germany (CBU)",
    value: "CBU",
  },
  {
    label: "Cuxhaven - Germany (FCN)",
    value: "FCN",
  },
  {
    label: "Cuxhaven, Nordholz Airport - Germany (FCN)",
    value: "FCN",
  },
  {
    label: "Egelsbach - Germany (QEF)",
    value: "QEF",
  },
  {
    label: "Egelsbach, Egelsbach - Germany (QEF)",
    value: "QEF",
  },
  {
    label: "Eisenach - Germany (EIB)",
    value: "EIB",
  },
  {
    label: "Eisenach, Kindel Airport - Germany (EIB)",
    value: "EIB",
  },
  {
    label: "Emden - Germany (EME)",
    value: "EME",
  },
  {
    label: "Emden, Emden - Germany (EME)",
    value: "EME",
  },
  {
    label: "Erfurt - Germany (ERF)",
    value: "ERF",
  },
  {
    label: "Erfurt, Erfurt-Weimar - Germany (ERF)",
    value: "ERF",
  },
  {
    label: "Essen - Germany (ESS)",
    value: "ESS",
  },
  {
    label: "Essen, Muelheim - Germany (ESS)",
    value: "ESS",
  },
  {
    label: "Finkenwerder - Germany (XFW)",
    value: "XFW",
  },
  {
    label: "Finkenwerder, Finkenwerder - Germany (XFW)",
    value: "XFW",
  },
  {
    label: "Flensburg - Germany (FLF)",
    value: "FLF",
  },
  {
    label: "Flensburg, Schaeferhaus Airport - Germany (FLF)",
    value: "FLF",
  },
  {
    label: "Fritzlar - Germany (FRZ)",
    value: "FRZ",
  },
  {
    label: "Fritzlar, Fritzlar Apt - Germany (FRZ)",
    value: "FRZ",
  },
  {
    label: "Fuerstenfeldbruck - Germany (FEL)",
    value: "FEL",
  },
  {
    label: "Fuerstenfeldbruck, Fuerstenfeldbruck - Germany (FEL)",
    value: "FEL",
  },
  {
    label: "Geilenkirchen, Air Base - Germany (GKE)",
    value: "GKE",
  },
  {
    label: "Giebelstadt, Giebelstadt - Germany (GHF)",
    value: "GHF",
  },
  {
    label: "Guetersloh - Germany (GUT)",
    value: "GUT",
  },
  {
    label: "Guetersloh, Guetersloh - Germany (GUT)",
    value: "GUT",
  },
  {
    label: "Guettin - Germany (GTI)",
    value: "GTI",
  },
  {
    label: "Guettin, Guettin - Germany (GTI)",
    value: "GTI",
  },
  {
    label: "Heide/Buesum, Heide - Germany (HEI)",
    value: "HEI",
  },
  {
    label: "Heide/Buesum, Heide - Germany (HEI)",
    value: "HEI",
  },
  {
    label: "Heidelberg - Germany (HDB)",
    value: "HDB",
  },
  {
    label: "Heidelberg, Aaf Airport - Germany (HDB)",
    value: "HDB",
  },
  {
    label: "Helgoland - Germany (HGL)",
    value: "HGL",
  },
  {
    label: "Helgoland, Duene - Germany (HGL)",
    value: "HGL",
  },
  {
    label: "Heringsdorf - Germany (HDF)",
    value: "HDF",
  },
  {
    label: "Heringsdorf, Heringsdorf - Germany (HDF)",
    value: "HDF",
  },
  {
    label: "Hof - Germany (HOQ)",
    value: "HOQ",
  },
  {
    label: "Hof, Plauen - Germany (HOQ)",
    value: "HOQ",
  },
  {
    label: "Husum - Germany (QHU)",
    value: "QHU",
  },
  {
    label: "Husum, Schwesing - Germany (QHU)",
    value: "QHU",
  },
  {
    label: "Illesheim - Germany (ILH)",
    value: "ILH",
  },
  {
    label: "Illesheim, Illesheim - Germany (ILH)",
    value: "ILH",
  },
  {
    label: "Ingolstadt - Germany (IGS)",
    value: "IGS",
  },
  {
    label: "Ingolstadt, Manching - Germany (IGS)",
    value: "IGS",
  },
  {
    label: "Juist - Germany (JUI)",
    value: "JUI",
  },
  {
    label: "Juist, Juist - Germany (JUI)",
    value: "JUI",
  },
  {
    label: "Kassel - Germany (KSF)",
    value: "KSF",
  },
  {
    label: "Kassel, Calden - Germany (KSF)",
    value: "KSF",
  },
  {
    label: "Kehl - Germany (ZIW)",
    value: "ZIW",
  },
  {
    label: "Kehl, Kehl - Germany (ZIW)",
    value: "ZIW",
  },
  {
    label: "Kiel - Germany (KEL)",
    value: "KEL",
  },
  {
    label: "Kiel, Holtenau - Germany (KEL)",
    value: "KEL",
  },
  {
    label: "Kitzingen - Germany (KZG)",
    value: "KZG",
  },
  {
    label: "Kitzingen, Kitzingen - Germany (KZG)",
    value: "KZG",
  },
  {
    label: "Koethen De - Germany (KOQ)",
    value: "KOQ",
  },
  {
    label: "Koethen De, Koethen De - Germany (KOQ)",
    value: "KOQ",
  },
  {
    label: "Lahr - Germany (LHA)",
    value: "LHA",
  },
  {
    label: "Lahr, Black Forest - Germany (LHA)",
    value: "LHA",
  },
  {
    label: "Langeoog - Germany (LGO)",
    value: "LGO",
  },
  {
    label: "Langeoog, Langeoog - Germany (LGO)",
    value: "LGO",
  },
  {
    label: "Lemwerder - Germany (XLW)",
    value: "XLW",
  },
  {
    label: "Lemwerder, Lemwerder - Germany (XLW)",
    value: "XLW",
  },
  {
    label: "Magdeburg - Germany (CSO)",
    value: "CSO",
  },
  {
    label: "Magdeburg, Cochstedt - Germany (CSO)",
    value: "CSO",
  },
  {
    label: "Mannheim - Germany (MHG)",
    value: "MHG",
  },
  {
    label: "Mannheim, City Airport - Germany (MHG)",
    value: "MHG",
  },
  {
    label: "Neubrandenburg - Germany (FNB)",
    value: "FNB",
  },
  {
    label: "Neubrandenburg, Trollenhagen - Germany (FNB)",
    value: "FNB",
  },
  {
    label: "Neumuenster - Germany (EUM)",
    value: "EUM",
  },
  {
    label: "Neumuenster, Neumuenster - Germany (EUM)",
    value: "EUM",
  },
  {
    label: "Norden - Germany (NOD)",
    value: "NOD",
  },
  {
    label: "Norden, Norddeich - Germany (NOD)",
    value: "NOD",
  },
  {
    label: "Norderney - Germany (NRD)",
    value: "NRD",
  },
  {
    label: "Norderney, Norderney - Germany (NRD)",
    value: "NRD",
  },
  {
    label: "Oberpfaffenhofen - Germany (OBF)",
    value: "OBF",
  },
  {
    label: "Oberpfaffenhofen, Oberpfaffenhofen - Germany (OBF)",
    value: "OBF",
  },
  {
    label: "Peenemuende - Germany (PEF)",
    value: "PEF",
  },
  {
    label: "Peenemuende, Peenemuende - Germany (PEF)",
    value: "PEF",
  },
  {
    label: "Ramstein - Germany (RMS)",
    value: "RMS",
  },
  {
    label: "Ramstein, Air Base - Germany (RMS)",
    value: "RMS",
  },
  {
    label: "Rechlin - Germany (REB)",
    value: "REB",
  },
  {
    label: "Rechlin, Laerz - Germany (REB)",
    value: "REB",
  },
  {
    label: "Riesa - Germany (IES)",
    value: "IES",
  },
  {
    label: "Riesa, Goehlis - Germany (IES)",
    value: "IES",
  },
  {
    label: "Rostock - Germany (RLG)",
    value: "RLG",
  },
  {
    label: "Rostock, Laage - Germany (RLG)",
    value: "RLG",
  },
  {
    label: "Saarbruecken - Germany (SCN)",
    value: "SCN",
  },
  {
    label: "Saarbruecken, Saarbruecken Airport - Germany (SCN)",
    value: "SCN",
  },
  {
    label: "Saarbruecken, Zweibruecken - Germany (ZQW)",
    value: "ZQW",
  },
  {
    label: "Saint Peter Ording - Germany (PSH)",
    value: "PSH",
  },
  {
    label: "Saint Peter Ording, Saint Peter Ording - Germany (PSH)",
    value: "PSH",
  },
  {
    label: "Schleswig - Germany (WBG)",
    value: "WBG",
  },
  {
    label: "Schleswig, Jagel - Germany (WBG)",
    value: "WBG",
  },
  {
    label: "Schwerin - Germany (SZW)",
    value: "SZW",
  },
  {
    label: "Schwerin, Parchim - Germany (SZW)",
    value: "SZW",
  },
  {
    label: "Siegen - Germany (SGE)",
    value: "SGE",
  },
  {
    label: "Siegen, Siegerland - Germany (SGE)",
    value: "SGE",
  },
  {
    label: "Spangdahlem - Germany (SPM)",
    value: "SPM",
  },
  {
    label: "Spangdahlem, Air Base - Germany (SPM)",
    value: "SPM",
  },
  {
    label: "Stralsund - Germany (BBH)",
    value: "BBH",
  },
  {
    label: "Stralsund, Barth - Germany (BBH)",
    value: "BBH",
  },
  {
    label: "Straubing - Germany (RBM)",
    value: "RBM",
  },
  {
    label: "Straubing, Wallmuehle - Germany (RBM)",
    value: "RBM",
  },
  {
    label: "Strausberg - Germany (QPK)",
    value: "QPK",
  },
  {
    label: "Strausberg, Strausberg - Germany (QPK)",
    value: "QPK",
  },
  {
    label: "Varrelbusch - Germany (VAC)",
    value: "VAC",
  },
  {
    label: "Varrelbusch, Varrelbusch - Germany (VAC)",
    value: "VAC",
  },
  {
    label: "Wangerooge - Germany (AGE)",
    value: "AGE",
  },
  {
    label: "Wangerooge, Wangerooge - Germany (AGE)",
    value: "AGE",
  },
  {
    label: "Westerland - Germany (GWT)",
    value: "GWT",
  },
  {
    label: "Westerland, Sylt - Germany (GWT)",
    value: "GWT",
  },
  {
    label: "Wiesbaden - Germany (WIE)",
    value: "WIE",
  },
  {
    label: "Wiesbaden, Aaf - Germany (WIE)",
    value: "WIE",
  },
  {
    label: "Wilhelmshaven - Germany (WVN)",
    value: "WVN",
  },
  {
    label: "Wilhelmshaven, Mariensiel - Germany (WVN)",
    value: "WVN",
  },
  {
    label: "Wyk Auf Foehr - Germany (OHR)",
    value: "OHR",
  },
  {
    label: "Wyk Auf Foehr, Wyk Auf Foehr - Germany (OHR)",
    value: "OHR",
  },
  {
    label: "Accra, Kotoka Intl - Ghana (ACC)",
    value: "ACC",
  },
  {
    label: "Kumasi, Kumasi - Ghana (KMS)",
    value: "KMS",
  },
  {
    label: "Sunyani, Sunyani - Ghana (NYI)",
    value: "NYI",
  },
  {
    label: "Takoradi, Takoradi - Ghana (TKD)",
    value: "TKD",
  },
  {
    label: "Tamale, Tamale - Ghana (TML)",
    value: "TML",
  },
  {
    label: "Gibraltar, Gibraltar - Gibraltar (GIB)",
    value: "GIB",
  },
  {
    label: "Athens - Greece (ATH)",
    value: "ATH",
  },
  {
    label: "Athens, Athens Int E Venizelos - Greece (ATH)",
    value: "ATH",
  },
  {
    label: "Chania - Greece (CHQ)",
    value: "CHQ",
  },
  {
    label: "Chania, I Daskalogiannis - Greece (CHQ)",
    value: "CHQ",
  },
  {
    label: "Kerkyra - Greece (CFU)",
    value: "CFU",
  },
  {
    label: "Kerkyra, Ioannis Kapodistrias - Greece (CFU)",
    value: "CFU",
  },
  {
    label: "Heraklion - Greece (HER)",
    value: "HER",
  },
  {
    label: "Heraklion, Nikos Kazantzakis - Greece (HER)",
    value: "HER",
  },
  {
    label: "Kos - Greece (KGS)",
    value: "KGS",
  },
  {
    label: "Kos, Ippokratis - Greece (KGS)",
    value: "KGS",
  },
  {
    label: "Mykonos - Greece (JMK)",
    value: "JMK",
  },
  {
    label: "Mykonos, Mykonos - Greece (JMK)",
    value: "JMK",
  },
  {
    label: "Rhodes - Greece (RHO)",
    value: "RHO",
  },
  {
    label: "Rhodes, Diagoras Airport - Greece (RHO)",
    value: "RHO",
  },
  {
    label: "Thira - Greece (JTR)",
    value: "JTR",
  },
  {
    label: "Thira, Santorini - Greece (JTR)",
    value: "JTR",
  },
  {
    label: "Thessaloniki - Greece (SKG)",
    value: "SKG",
  },
  {
    label: "Thessaloniki, Makedonia Apt - Greece (SKG)",
    value: "SKG",
  },
  {
    label: "Zakinthos Island - Greece (ZTH)",
    value: "ZTH",
  },
  {
    label: "Zakinthos Island, Dionisios Salomos - Greece (ZTH)",
    value: "ZTH",
  },
  {
    label: "Agrinion - Greece (AGQ)",
    value: "AGQ",
  },
  {
    label: "Agrinion, G.Karaiskakis - Greece (AGQ)",
    value: "AGQ",
  },
  {
    label: "Alexandroupolis - Greece (AXD)",
    value: "AXD",
  },
  {
    label: "Alexandroupolis, Dimokritos - Greece (AXD)",
    value: "AXD",
  },
  {
    label: "Astypalea Island - Greece (JTY)",
    value: "JTY",
  },
  {
    label: "Astypalea Island, Astypalea Island - Greece (JTY)",
    value: "JTY",
  },
  {
    label: "Chios - Greece (JKH)",
    value: "JKH",
  },
  {
    label: "Chios, Omiros - Greece (JKH)",
    value: "JKH",
  },
  {
    label: "Ikaria Island - Greece (JIK)",
    value: "JIK",
  },
  {
    label: "Ikaria Island, Ikaria - Greece (JIK)",
    value: "JIK",
  },
  {
    label: "Ioannina - Greece (IOA)",
    value: "IOA",
  },
  {
    label: "Ioannina, King Pyrros - Greece (IOA)",
    value: "IOA",
  },
  {
    label: "Kalamata - Greece (KLX)",
    value: "KLX",
  },
  {
    label: "Kalamata, Kalamata - Greece (KLX)",
    value: "KLX",
  },
  {
    label: "Kalymnos Island - Greece (JKL)",
    value: "JKL",
  },
  {
    label: "Kalymnos Island, Kalymnos - Greece (JKL)",
    value: "JKL",
  },
  {
    label: "Karpathos - Greece (AOK)",
    value: "AOK",
  },
  {
    label: "Karpathos, Karpathos - Greece (AOK)",
    value: "AOK",
  },
  {
    label: "Kasos Island - Greece (KSJ)",
    value: "KSJ",
  },
  {
    label: "Kasos Island, Kasos Island - Greece (KSJ)",
    value: "KSJ",
  },
  {
    label: "Kastelorizo - Greece (KZS)",
    value: "KZS",
  },
  {
    label: "Kastelorizo, Megisti - Greece (KZS)",
    value: "KZS",
  },
  {
    label: "Kastoria - Greece (KSO)",
    value: "KSO",
  },
  {
    label: "Kastoria, Aristotelis Apt - Greece (KSO)",
    value: "KSO",
  },
  {
    label: "Kavala - Greece (KVA)",
    value: "KVA",
  },
  {
    label: "Kavala, Megas Alexandros Apt - Greece (KVA)",
    value: "KVA",
  },
  {
    label: "Kefallinia - Greece (EFL)",
    value: "EFL",
  },
  {
    label: "Kefallinia, Airport - Greece (EFL)",
    value: "EFL",
  },
  {
    label: "Kithira - Greece (KIT)",
    value: "KIT",
  },
  {
    label: "Kithira, Alexandros A.Onassis - Greece (KIT)",
    value: "KIT",
  },
  {
    label: "Kozani - Greece (KZI)",
    value: "KZI",
  },
  {
    label: "Kozani, Filippos Airport - Greece (KZI)",
    value: "KZI",
  },
  {
    label: "Larisa - Greece (LRA)",
    value: "LRA",
  },
  {
    label: "Larisa, Thessalia - Greece (LRA)",
    value: "LRA",
  },
  {
    label: "Leros - Greece (LRS)",
    value: "LRS",
  },
  {
    label: "Leros, Leros - Greece (LRS)",
    value: "LRS",
  },
  {
    label: "Limnos - Greece (LXS)",
    value: "LXS",
  },
  {
    label: "Limnos, Limnos - Greece (LXS)",
    value: "LXS",
  },
  {
    label: "Milos - Greece (MLO)",
    value: "MLO",
  },
  {
    label: "Milos, Milos - Greece (MLO)",
    value: "MLO",
  },
  {
    label: "Mytilini - Greece (MJT)",
    value: "MJT",
  },
  {
    label: "Mytilini, Odysseas Elytis - Greece (MJT)",
    value: "MJT",
  },
  {
    label: "Naxos - Greece (JNX)",
    value: "JNX",
  },
  {
    label: "Naxos, Apt - Greece (JNX)",
    value: "JNX",
  },
  {
    label: "Paros - Greece (PAS)",
    value: "PAS",
  },
  {
    label: "Paros, Paros - Greece (PAS)",
    value: "PAS",
  },
  {
    label: "Patrai - Greece (GPA)",
    value: "GPA",
  },
  {
    label: "Patrai, Araxos Airport - Greece (GPA)",
    value: "GPA",
  },
  {
    label: "Portochelion - Greece (PKH)",
    value: "PKH",
  },
  {
    label: "Portochelion, Kanaris - Greece (PKH)",
    value: "PKH",
  },
  {
    label: "Preveza Lefkada - Greece (PVK)",
    value: "PVK",
  },
  {
    label: "Preveza Lefkada, Aktion - Greece (PVK)",
    value: "PVK",
  },
  {
    label: "Pyrgos - Greece (PYR)",
    value: "PYR",
  },
  {
    label: "Pyrgos, Andravida Ab - Greece (PYR)",
    value: "PYR",
  },
  {
    label: "Samos - Greece (SMI)",
    value: "SMI",
  },
  {
    label: "Samos, Aristarchos Of Samos - Greece (SMI)",
    value: "SMI",
  },
  {
    label: "Siteia - Greece (JSH)",
    value: "JSH",
  },
  {
    label: "Siteia, Siteia - Greece (JSH)",
    value: "JSH",
  },
  {
    label: "Skiathos - Greece (JSI)",
    value: "JSI",
  },
  {
    label: "Skiathos, Alex Papadiamantis - Greece (JSI)",
    value: "JSI",
  },
  {
    label: "Skyros - Greece (SKU)",
    value: "SKU",
  },
  {
    label: "Skyros, Skyros - Greece (SKU)",
    value: "SKU",
  },
  {
    label: "Sparta - Greece (SPJ)",
    value: "SPJ",
  },
  {
    label: "Sparta, Sparta - Greece (SPJ)",
    value: "SPJ",
  },
  {
    label: "Syros Island - Greece (JSY)",
    value: "JSY",
  },
  {
    label: "Syros Island, Dimitrios Vikelas - Greece (JSY)",
    value: "JSY",
  },
  {
    label: "Volos - Greece (VOL)",
    value: "VOL",
  },
  {
    label: "Volos, Nea Anchialos - Greece (VOL)",
    value: "VOL",
  },
  {
    label: "Aappilattoq, Heliport - Greenland (QUV)",
    value: "QUV",
  },
  {
    label: "Aasiaat, Aasiaat - Greenland (JEG)",
    value: "JEG",
  },
  {
    label: "Akunnaaq, Akunnaaq - Greenland (QCU)",
    value: "QCU",
  },
  {
    label: "Arsuk, Heliport - Greenland (JRK)",
    value: "JRK",
  },
  {
    label: "Attu, Attu - Greenland (QGQ)",
    value: "QGQ",
  },
  {
    label: "Iginniarfik, Iginniarfik - Greenland (QFI)",
    value: "QFI",
  },
  {
    label: "Ikamiut, Ikamiut - Greenland (QJI)",
    value: "QJI",
  },
  {
    label: "Ikerasaarsuk, Ikerasaarsuk - Greenland (QRY)",
    value: "QRY",
  },
  {
    label: "Ilulissat, Ilulissat - Greenland (JAV)",
    value: "JAV",
  },
  {
    label: "Innarsuit, Heliport - Greenland (IUI)",
    value: "IUI",
  },
  {
    label: "Kangaamiut, Kangaamiut - Greenland (QKT)",
    value: "QKT",
  },
  {
    label: "Kangaatsiaq, Kangaatsiaq - Greenland (QPW)",
    value: "QPW",
  },
  {
    label: "Kangerlussuaq, Kangerlussuaq - Greenland (SFJ)",
    value: "SFJ",
  },
  {
    label: "Kitsissuarsuit, Kitsissuarsuit - Greenland (QJE)",
    value: "QJE",
  },
  {
    label: "Kulusuk, Kulusuk - Greenland (KUS)",
    value: "KUS",
  },
  {
    label: "Maniitsoq, Maniitsoq - Greenland (JSU)",
    value: "JSU",
  },
  {
    label: "Narsarsuaq, Narsarsuaq - Greenland (UAK)",
    value: "UAK",
  },
  {
    label: "Neerlerit Inaat, Neerlerit Inaat - Greenland (CNP)",
    value: "CNP",
  },
  {
    label: "Niaqornaarsuk, Niaqornaarsuk - Greenland (QMK)",
    value: "QMK",
  },
  {
    label: "Nuuk, Nuuk - Greenland (GOH)",
    value: "GOH",
  },
  {
    label: "Nuussuaq, Heliport - Greenland (NSQ)",
    value: "NSQ",
  },
  {
    label: "Oqatsut Harbour, Oqaitsut Harbour - Greenland (XEO)",
    value: "XEO",
  },
  {
    label: "Paamiut, Paamiut - Greenland (JFR)",
    value: "JFR",
  },
  {
    label: "Qaanaaq, Qaanaaq - Greenland (NAQ)",
    value: "NAQ",
  },
  {
    label: "Qaarsut, Qaarsut - Greenland (JQA)",
    value: "JQA",
  },
  {
    label: "Qeqertarsuatsiaat, Qeqertarsuatsiaat - Greenland (QEY)",
    value: "QEY",
  },
  {
    label: "Qullissat, Qullissat - Greenland (QUE)",
    value: "QUE",
  },
  {
    label: "Saqqaq, Saqqaq - Greenland (QUP)",
    value: "QUP",
  },
  {
    label: "Sisimiut, Sisimiut - Greenland (JHS)",
    value: "JHS",
  },
  {
    label: "Thule, Thule Air Base - Greenland (THU)",
    value: "THU",
  },
  {
    label: "Upernavik, Upernavik - Greenland (JUV)",
    value: "JUV",
  },
  {
    label: "Carriacou Is, Carriacou - Grenada (CRU)",
    value: "CRU",
  },
  {
    label: "Grenada, Maurice Bishop Intl - Grenada (GND)",
    value: "GND",
  },
  {
    label: "Basse Terre, Baillif - Guadeloupe (BBR)",
    value: "BBR",
  },
  {
    label: "La Desirade, La Grande Anse - Guadeloupe (DSD)",
    value: "DSD",
  },
  {
    label: "Marie Galante, Marie Galante - Guadeloupe (GBJ)",
    value: "GBJ",
  },
  {
    label: "Pointe A Pitre, Pole Caraibes - Guadeloupe (PTP)",
    value: "PTP",
  },
  {
    label: "Saint Barthelemy, Gustaf Iii - Guadeloupe (SBH)",
    value: "SBH",
  },
  {
    label: "Saint Francois, Saint Francois - Guadeloupe (SFC)",
    value: "SFC",
  },
  {
    label: "Saint Martin, Grand Case - Guadeloupe (CCE)",
    value: "CCE",
  },
  {
    label: "Saint Martin, Grand Case L Esperance - Guadeloupe (SFG)",
    value: "SFG",
  },
  {
    label: "Terre De Bas, Terre De Bas - Guadeloupe (HTB)",
    value: "HTB",
  },
  {
    label: "Terre De Haut, Les Saintes - Guadeloupe (LSS)",
    value: "LSS",
  },
  {
    label: "Guam, A.B Won Pat Intl - Guam (GUM)",
    value: "GUM",
  },
  {
    label: "Guam, Andersen Afb - Guam (UAM)",
    value: "UAM",
  },
  {
    label: "Carmelita, Carmelita - Guatemala (CMM)",
    value: "CMM",
  },
  {
    label: "Chiquimula, Chiquimula - Guatemala (CIQ)",
    value: "CIQ",
  },
  {
    label: "Coatepeque, Coatepeque - Guatemala (CTF)",
    value: "CTF",
  },
  {
    label: "Coban, Coban - Guatemala (CBV)",
    value: "CBV",
  },
  {
    label: "Dos Lagunas, Dos Lagunas - Guatemala (DON)",
    value: "DON",
  },
  {
    label: "El Naranjo, El Naranjo - Guatemala (ENJ)",
    value: "ENJ",
  },
  {
    label: "Flores, Santa Elena - Guatemala (FRS)",
    value: "FRS",
  },
  {
    label: "Guatemala City, La Aurora Intl - Guatemala (GUA)",
    value: "GUA",
  },
  {
    label: "Huehuetenango, Huehuetenango - Guatemala (HUG)",
    value: "HUG",
  },
  {
    label: "Los Tablones, Los Tablones - Guatemala (LOX)",
    value: "LOX",
  },
  {
    label: "Paso Caballos, Paso Caballos - Guatemala (PCG)",
    value: "PCG",
  },
  {
    label: "Playa Grande, Playa Grand Airport - Guatemala (PKJ)",
    value: "PKJ",
  },
  {
    label: "Poptun, Poptun - Guatemala (PON)",
    value: "PON",
  },
  {
    label: "Puerto Barrios, Puerto Barrios - Guatemala (PBR)",
    value: "PBR",
  },
  {
    label: "Puerto San Jose, Puerto San Jose - Guatemala (GSJ)",
    value: "GSJ",
  },
  {
    label: "Quetzaltenango, Quetzaltenango - Guatemala (AAZ)",
    value: "AAZ",
  },
  {
    label: "Quiche, Quiche - Guatemala (AQB)",
    value: "AQB",
  },
  {
    label: "Retalhuleu, Retalhuleu - Guatemala (RER)",
    value: "RER",
  },
  {
    label: "Rio Dulce, Las Vegas Airport - Guatemala (LCF)",
    value: "LCF",
  },
  {
    label: "Rubelsanto, Rubelsanto - Guatemala (RUV)",
    value: "RUV",
  },
  {
    label: "Tikal, El Peten - Guatemala (TKM)",
    value: "TKM",
  },
  {
    label: "Uaxactun, Uaxactun - Guatemala (UAX)",
    value: "UAX",
  },
  {
    label: "Boke, Baralande - Guinea (BKJ)",
    value: "BKJ",
  },
  {
    label: "Conakry, International - Guinea (CKY)",
    value: "CKY",
  },
  {
    label: "Faranah, Badala - Guinea (FAA)",
    value: "FAA",
  },
  {
    label: "Fria, Katourou - Guinea (FIG)",
    value: "FIG",
  },
  {
    label: "Kankan, Kankan - Guinea (KNN)",
    value: "KNN",
  },
  {
    label: "Kissidougou, Kissidougou - Guinea (KSI)",
    value: "KSI",
  },
  {
    label: "Koundara, Sambailo - Guinea (SBI)",
    value: "SBI",
  },
  {
    label: "Labe, Tata - Guinea (LEK)",
    value: "LEK",
  },
  {
    label: "Macenta, Macenta - Guinea (MCA)",
    value: "MCA",
  },
  {
    label: "Nzerekore, Nzerekore - Guinea (NZE)",
    value: "NZE",
  },
  {
    label: "Siguiri, Siguiri - Guinea (GII)",
    value: "GII",
  },
  {
    label: "Bissau, Osvaldo Vieira Intl - Guinea Bissau (OXB)",
    value: "OXB",
  },
  {
    label: "Bubaque, Bubaque - Guinea Bissau (BQE)",
    value: "BQE",
  },
  {
    label: "Aishalton, Aishalton - Guyana (AHL)",
    value: "AHL",
  },
  {
    label: "Annai, Annai - Guyana (NAI)",
    value: "NAI",
  },
  {
    label: "Baramita, Baramita - Guyana (BMJ)",
    value: "BMJ",
  },
  {
    label: "Bartica, Bartica - Guyana (GFO)",
    value: "GFO",
  },
  {
    label: "Bemichi, Bemichi - Guyana (BCG)",
    value: "BCG",
  },
  {
    label: "Ekereku, Ekereku - Guyana (EKE)",
    value: "EKE",
  },
  {
    label: "Georgetown, Cheddi Jagan Int - Guyana (GEO)",
    value: "GEO",
  },
  {
    label: "Georgetown, Ogle - Guyana (OGL)",
    value: "OGL",
  },
  {
    label: "Imbaimadai, Imbaimadai - Guyana (IMB)",
    value: "IMB",
  },
  {
    label: "Kaieteur, Kaieteur - Guyana (KAI)",
    value: "KAI",
  },
  {
    label: "Kamarang, Kamarang - Guyana (KAR)",
    value: "KAR",
  },
  {
    label: "Karanambo, Karanambo - Guyana (KRM)",
    value: "KRM",
  },
  {
    label: "Karasabai, Karasabai - Guyana (KRG)",
    value: "KRG",
  },
  {
    label: "Kato, Kato - Guyana (KTO)",
    value: "KTO",
  },
  {
    label: "Konawaruk, Konawaruk - Guyana (KKG)",
    value: "KKG",
  },
  {
    label: "Kurupung, Kurupung - Guyana (KPG)",
    value: "KPG",
  },
  {
    label: "Lethem, Lethem - Guyana (LTM)",
    value: "LTM",
  },
  {
    label: "Lumid Pau, Lumidpau - Guyana (LUB)",
    value: "LUB",
  },
  {
    label: "Mabaruma, Mabaruma - Guyana (USI)",
    value: "USI",
  },
  {
    label: "Mahdia, Mahdia - Guyana (MHA)",
    value: "MHA",
  },
  {
    label: "Maikwak, Maikwak - Guyana (VEG)",
    value: "VEG",
  },
  {
    label: "Matthews Ridge, Matthews Ridge - Guyana (MWJ)",
    value: "MWJ",
  },
  {
    label: "Monkey Mountain, Monkey Mountain - Guyana (MYM)",
    value: "MYM",
  },
  {
    label: "Orinduik, Orinduik - Guyana (ORJ)",
    value: "ORJ",
  },
  {
    label: "Paramakotoi, Paramakatoi - Guyana (PMT)",
    value: "PMT",
  },
  {
    label: "Paruima, Paruima - Guyana (PRR)",
    value: "PRR",
  },
  {
    label: "Pipillipai, Pipillipai - Guyana (PIQ)",
    value: "PIQ",
  },
  {
    label: "Port Kaituma, Port Kaituma - Guyana (PKM)",
    value: "PKM",
  },
  {
    label: "Sandcreek, Sand Creek - Guyana (SDC)",
    value: "SDC",
  },
  {
    label: "Skeldon, Skeldon - Guyana (SKM)",
    value: "SKM",
  },
  {
    label: "Cap Haitien, Hugo Chavez Intl - Haiti (CAP)",
    value: "CAP",
  },
  {
    label: "Jacmel, Jacmel - Haiti (JAK)",
    value: "JAK",
  },
  {
    label: "Jeremie, Jeremie - Haiti (JEE)",
    value: "JEE",
  },
  {
    label: "Les Cayes, Antoine Simon - Haiti (CYA)",
    value: "CYA",
  },
  {
    label: "Port Au Prince, Toussaint Louverture - Haiti (PAP)",
    value: "PAP",
  },
  {
    label: "Port De Paix, Port De Paix - Haiti (PAX)",
    value: "PAX",
  },
  {
    label: "Ahuas, Ahuas - Honduras (AHS)",
    value: "AHS",
  },
  {
    label: "Brus Laguna, Brus Laguna - Honduras (BHG)",
    value: "BHG",
  },
  {
    label: "Catacamas, El Aguacate - Honduras (CAA)",
    value: "CAA",
  },
  {
    label: "Cauquira, Cauquira Airport - Honduras (CDD)",
    value: "CDD",
  },
  {
    label: "Comayagua, Enrique Soto Cano - Honduras (XPL)",
    value: "XPL",
  },
  {
    label: "Copan, Ruinas De Copan - Honduras (RUY)",
    value: "RUY",
  },
  {
    label: "Coronel E Soto A, Coronel E Soto A - Honduras (ENQ)",
    value: "ENQ",
  },
  {
    label: "Coyoles, Coyoles - Honduras (CYL)",
    value: "CYL",
  },
  {
    label: "Erandique, Erandique - Honduras (EDQ)",
    value: "EDQ",
  },
  {
    label: "Gracias, Gracias - Honduras (GAC)",
    value: "GAC",
  },
  {
    label: "Gualaco, Gualaco - Honduras (GUO)",
    value: "GUO",
  },
  {
    label: "Guanaja, Guanaja - Honduras (GJA)",
    value: "GJA",
  },
  {
    label: "Iriona, Iriona - Honduras (IRN)",
    value: "IRN",
  },
  {
    label: "Juticalpa, Juticalpa - Honduras (JUT)",
    value: "JUT",
  },
  {
    label: "La Ceiba, Goloson Intl - Honduras (LCE)",
    value: "LCE",
  },
  {
    label: "La Esperanza, La Esperanza - Honduras (LEZ)",
    value: "LEZ",
  },
  {
    label: "La Union, La Union - Honduras (LUI)",
    value: "LUI",
  },
  {
    label: "Las Limas, Reginaldo Hammer - Honduras (LLH)",
    value: "LLH",
  },
  {
    label: "Limon, Limon - Honduras (LMH)",
    value: "LMH",
  },
  {
    label: "Marcala, Marcala - Honduras (MRJ)",
    value: "MRJ",
  },
  {
    label: "Olanchito, El Arrayan - Honduras (OAN)",
    value: "OAN",
  },
  {
    label: "Palacios, Palacios - Honduras (PCH)",
    value: "PCH",
  },
  {
    label: "Puerto Lempira, Puerto Lempira - Honduras (PEU)",
    value: "PEU",
  },
  {
    label: "Roatan, Juan Manuel Galvez - Honduras (RTB)",
    value: "RTB",
  },
  {
    label: "San Pedro Sula, Ramon V.Morales Intl - Honduras (SAP)",
    value: "SAP",
  },
  {
    label: "Santa Rosa Copan, Santa Rosa De Copan - Honduras (SDH)",
    value: "SDH",
  },
  {
    label: "Sulaco, Sulaco - Honduras (SCD)",
    value: "SCD",
  },
  {
    label: "Tegucigalpa, Toncontin Intl - Honduras (TGU)",
    value: "TGU",
  },
  {
    label: "Tela, Tela - Honduras (TEA)",
    value: "TEA",
  },
  {
    label: "Tocoa, Tocoa - Honduras (TCF)",
    value: "TCF",
  },
  {
    label: "Trujillo, Capiro - Honduras (TJI)",
    value: "TJI",
  },
  {
    label: "Utila, Utila - Honduras (UII)",
    value: "UII",
  },
  {
    label: "Victoria, Victoria - Honduras (VTA)",
    value: "VTA",
  },
  {
    label: "Yoro, Yoro - Honduras (ORO)",
    value: "ORO",
  },
  {
    label: "Shek Mun, Shek Mun - Hong Kong (QDM)",
    value: "QDM",
  },
  {
    label: "Budapest - Hungary (BUD)",
    value: "BUD",
  },
  {
    label: "Budapest, Liszt Ferenc Intl - Hungary (BUD)",
    value: "BUD",
  },
  {
    label: "Debrecen - Hungary (DEB)",
    value: "DEB",
  },
  {
    label: "Debrecen, Debrecen - Hungary (DEB)",
    value: "DEB",
  },
  {
    label: "Balaton, Balaton - Hungary (SOB)",
    value: "SOB",
  },
  {
    label: "Balaton, Balaton - Hungary (SOB)",
    value: "SOB",
  },
  {
    label: "Gyor - Hungary (QGY)",
    value: "QGY",
  },
  {
    label: "Miskolc - Hungary (MCQ)",
    value: "MCQ",
  },
  {
    label: "Miskolc, Miskolc - Hungary (MCQ)",
    value: "MCQ",
  },
  {
    label: "Pecs - Hungary (QPJ)",
    value: "QPJ",
  },
  {
    label: "Pecs - Hungary (PEV)",
    value: "PEV",
  },
  {
    label: "Pecs, Pogany - Hungary (PEV)",
    value: "PEV",
  },
  {
    label: "Szeged - Hungary (QZD)",
    value: "QZD",
  },
  {
    label: "Reykjavik - Iceland (REF)",
    value: "REF",
  },
  {
    label: "Reykjavik, Keflavik Intl - Iceland (KEF)",
    value: "KEF",
  },
  {
    label: "Reykjavik, Reykjavik Dom - Iceland (RKV)",
    value: "RKV",
  },
  {
    label: "Akureyri - Iceland (AEY)",
    value: "AEY",
  },
  {
    label: "Akureyri, Akureyri - Iceland (AEY)",
    value: "AEY",
  },
  {
    label: "Bakkafjordur - Iceland (BJD)",
    value: "BJD",
  },
  {
    label: "Bakkafjordur, Bakkafjordur - Iceland (BJD)",
    value: "BJD",
  },
  {
    label: "Bildudalur - Iceland (BIU)",
    value: "BIU",
  },
  {
    label: "Bildudalur, Bildudalur - Iceland (BIU)",
    value: "BIU",
  },
  {
    label: "Blonduos - Iceland (BLO)",
    value: "BLO",
  },
  {
    label: "Blonduos, Blonduos - Iceland (BLO)",
    value: "BLO",
  },
  {
    label: "Borgarfjordur Eystri - Iceland (BGJ)",
    value: "BGJ",
  },
  {
    label: "Borgarfjordur Eystri, Borgarfjordur Eystri - Iceland (BGJ)",
    value: "BGJ",
  },
  {
    label: "Breiddalsvik - Iceland (BXV)",
    value: "BXV",
  },
  {
    label: "Breiddalsvik, Breiddalsvik - Iceland (BXV)",
    value: "BXV",
  },
  {
    label: "Djupivogur, Djupivogur - Iceland (DJU)",
    value: "DJU",
  },
  {
    label: "Egilsstadir, Egilsstadir - Iceland (EGS)",
    value: "EGS",
  },
  {
    label: "Fagurholsmyri, Fagurholsmyri - Iceland (FAG)",
    value: "FAG",
  },
  {
    label: "Faskrudsfjordur, Faskrudsfjordur - Iceland (FAS)",
    value: "FAS",
  },
  {
    label: "Flateyri, Holt - Iceland (FLI)",
    value: "FLI",
  },
  {
    label: "Gjogur, Gjogur - Iceland (GJR)",
    value: "GJR",
  },
  {
    label: "Grimsey, Grimsey - Iceland (GRY)",
    value: "GRY",
  },
  {
    label: "Grundarfjordur, Grundarfjordur - Iceland (GUU)",
    value: "GUU",
  },
  {
    label: "Holmavik, Holmavik - Iceland (HVK)",
    value: "HVK",
  },
  {
    label: "Hornafjordur, Hofn - Iceland (HFN)",
    value: "HFN",
  },
  {
    label: "Husavik, Husavik - Iceland (HZK)",
    value: "HZK",
  },
  {
    label: "Isafjordur, Isafjordur - Iceland (IFJ)",
    value: "IFJ",
  },
  {
    label: "Kopasker, Kopasker - Iceland (OPA)",
    value: "OPA",
  },
  {
    label: "Myvatn, Reykjahlid - Iceland (MVA)",
    value: "MVA",
  },
  {
    label: "Nordfjordur, Nordfjordur - Iceland (NOR)",
    value: "NOR",
  },
  {
    label: "Olafsfjordur, Olafsfjordur - Iceland (OFJ)",
    value: "OFJ",
  },
  {
    label: "Olafsvik, Rif - Iceland (OLI)",
    value: "OLI",
  },
  {
    label: "Patreksfjordur, Patreksfjordur - Iceland (PFJ)",
    value: "PFJ",
  },
  {
    label: "Raufarhofn, Raufarhofn - Iceland (RFN)",
    value: "RFN",
  },
  {
    label: "Reykholar, Reykholar - Iceland (RHA)",
    value: "RHA",
  },
  {
    label: "Saudarkrokur, Saudarkrokur - Iceland (SAK)",
    value: "SAK",
  },
  {
    label: "Siglufjordur, Siglufjordur - Iceland (SIJ)",
    value: "SIJ",
  },
  {
    label: "Stykkisholmur, Stykkisholmur - Iceland (SYK)",
    value: "SYK",
  },
  {
    label: "Thingeyri, Selardalur - Iceland (TEY)",
    value: "TEY",
  },
  {
    label: "Thorshofn, Thorshofn - Iceland (THO)",
    value: "THO",
  },
  {
    label: "Vestmannaeyjar, Vestmannaeyjar - Iceland (VEY)",
    value: "VEY",
  },
  {
    label: "Vopnafjordur, Vopnafjordur - Iceland (VPN)",
    value: "VPN",
  },
  {
    label: "Agartala, Agartala - India (IXA)",
    value: "IXA",
  },
  {
    label: "Agatti Island, Agatti Island - India (AGX)",
    value: "AGX",
  },
  {
    label: "Agra, Agra - India (AGR)",
    value: "AGR",
  },
  {
    label: "Ahmedabad, S.Vallabhbhai Patel - India (AMD)",
    value: "AMD",
  },
  {
    label: "Aizawl, Lengpui - India (AJL)",
    value: "AJL",
  },
  {
    label: "Akola, Akola - India (AKD)",
    value: "AKD",
  },
  {
    label: "Allahabad, Allahabad - India (IXD)",
    value: "IXD",
  },
  {
    label: "Along, Along - India (IXV)",
    value: "IXV",
  },
  {
    label: "Amritsar, Sri Guru Ram Dass Jee - India (ATQ)",
    value: "ATQ",
  },
  {
    label: "Aurangabad, Aurangabad - India (IXU)",
    value: "IXU",
  },
  {
    label: "Bagdogra, Bagdogra - India (IXB)",
    value: "IXB",
  },
  {
    label: "Balurghat, Balurghat - India (RGH)",
    value: "RGH",
  },
  {
    label: "Bareilly, Bareilly - India (BEK)",
    value: "BEK",
  },
  {
    label: "Belgaum, Sambre - India (IXG)",
    value: "IXG",
  },
  {
    label: "Bellary, Bellary - India (BEP)",
    value: "BEP",
  },
  {
    label: "Bengaluru, Kempegowda Intl - India (BLR)",
    value: "BLR",
  },
  {
    label: "Bhatinda, Bhatinda - India (BUP)",
    value: "BUP",
  },
  {
    label: "Bhavnagar, Bhavnagar - India (BHU)",
    value: "BHU",
  },
  {
    label: "Bhopal, Raja Bhoj - India (BHO)",
    value: "BHO",
  },
  {
    label: "Bhubaneshwar, Biju Patnaik - India (BBI)",
    value: "BBI",
  },
  {
    label: "Bhuj, Shyamji Krishna Verma - India (BHJ)",
    value: "BHJ",
  },
  {
    label: "Bikaner, Nal - India (BKB)",
    value: "BKB",
  },
  {
    label: "Bilaspur, Bilaspur - India (PAB)",
    value: "PAB",
  },
  {
    label: "Calcutta, Subhas Chandra Bose - India (CCU)",
    value: "CCU",
  },
  {
    label: "Car Nicobar Island, Afb - India (CBD)",
    value: "CBD",
  },
  {
    label: "Chandigarh, Chandigarh - India (IXC)",
    value: "IXC",
  },
  {
    label: "Chennai, Chennai International - India (MAA)",
    value: "MAA",
  },
  {
    label: "Coimbatore, International - India (CJB)",
    value: "CJB",
  },
  {
    label: "Cooch Behar, Cooch Behar - India (COH)",
    value: "COH",
  },
  {
    label: "Cuddapah, Cuddapah - India (CDP)",
    value: "CDP",
  },
  {
    label: "Daman, Daman - India (NMB)",
    value: "NMB",
  },
  {
    label: "Dehra Dun, Jolly Grant Airport - India (DED)",
    value: "DED",
  },
  {
    label: "Delhi, Indira Gandhi Intl - India (DEL)",
    value: "DEL",
  },
  {
    label: "Deparijo, Deparijo - India (DEP)",
    value: "DEP",
  },
  {
    label: "Dhanbad, Dhanbad - India (DBD)",
    value: "DBD",
  },
  {
    label: "Dharamsala, Kangra - India (DHM)",
    value: "DHM",
  },
  {
    label: "Dibrugarh, Mohanbari - India (DIB)",
    value: "DIB",
  },
  {
    label: "Dimapur, Dimapur - India (DMU)",
    value: "DMU",
  },
  {
    label: "Diu, Diu - India (DIU)",
    value: "DIU",
  },
  {
    label: "Durgapur, Kazi Nazrul Islam - India (RDP)",
    value: "RDP",
  },
  {
    label: "Gaya, Gaya - India (GAY)",
    value: "GAY",
  },
  {
    label: "Goa, Dabolim - India (GOI)",
    value: "GOI",
  },
  {
    label: "Gorakhpur, Gorakhpur - India (GOP)",
    value: "GOP",
  },
  {
    label: "Guna, Guna - India (GUX)",
    value: "GUX",
  },
  {
    label: "Guwahati, Gopinath Bordoloi Intl - India (GAU)",
    value: "GAU",
  },
  {
    label: "Gwalior, Gwalior - India (GWL)",
    value: "GWL",
  },
  {
    label: "Hisar, Hisar - India (HSS)",
    value: "HSS",
  },
  {
    label: "Hubli, Hubli - India (HBX)",
    value: "HBX",
  },
  {
    label: "Hyderabad, Begumpet Airport - India (BPM)",
    value: "BPM",
  },
  {
    label: "Hyderabad, Rajiv Gandhi Intl - India (HYD)",
    value: "HYD",
  },
  {
    label: "Imphal, Imphal - India (IMF)",
    value: "IMF",
  },
  {
    label: "Indore, Devi Ahilya Bai Holkar - India (IDR)",
    value: "IDR",
  },
  {
    label: "Jabalpur, Jabalpur - India (JLR)",
    value: "JLR",
  },
  {
    label: "Jagdalpur, Jagdalpur - India (JGB)",
    value: "JGB",
  },
  {
    label: "Jaipur, International - India (JAI)",
    value: "JAI",
  },
  {
    label: "Jaisalmer, Jaisalmer - India (JSA)",
    value: "JSA",
  },
  {
    label: "Jammu, Satwari - India (IXJ)",
    value: "IXJ",
  },
  {
    label: "Jamnagar, Govardhanpur - India (JGA)",
    value: "JGA",
  },
  {
    label: "Jamshedpur, Sonari - India (IXW)",
    value: "IXW",
  },
  {
    label: "Jeypore, Jeypore - India (PYB)",
    value: "PYB",
  },
  {
    label: "Jodhpur, Jodhpur - India (JDH)",
    value: "JDH",
  },
  {
    label: "Jorhat, Rowriah - India (JRH)",
    value: "JRH",
  },
  {
    label: "Kailashahar, Kailashahar - India (IXH)",
    value: "IXH",
  },
  {
    label: "Kamalpur, Kamalpur - India (IXQ)",
    value: "IXQ",
  },
  {
    label: "Kandla, Kandla - India (IXY)",
    value: "IXY",
  },
  {
    label: "Kanpur, Kanpur - India (KNU)",
    value: "KNU",
  },
  {
    label: "Keshod, Keshod - India (IXK)",
    value: "IXK",
  },
  {
    label: "Khajuraho, Khajuraho - India (HJR)",
    value: "HJR",
  },
  {
    label: "Khowai, Khowai - India (IXN)",
    value: "IXN",
  },
  {
    label: "Kochi, Kochi International - India (COK)",
    value: "COK",
  },
  {
    label: "Kolhapur, Kolhapur - India (KLH)",
    value: "KLH",
  },
  {
    label: "Kota, Kota - India (KTU)",
    value: "KTU",
  },
  {
    label: "Kozhikode, International - India (CCJ)",
    value: "CCJ",
  },
  {
    label: "Kullu, Bhuntar - India (KUU)",
    value: "KUU",
  },
  {
    label: "Latur, Latur - India (LTU)",
    value: "LTU",
  },
  {
    label: "Leh, Kushok Bakula Rimpoche - India (IXL)",
    value: "IXL",
  },
  {
    label: "Lilabari, Lilabari - India (IXI)",
    value: "IXI",
  },
  {
    label: "Lucknow, Chaudhary Charan Singh - India (LKO)",
    value: "LKO",
  },
  {
    label: "Ludhiana, Ludhiana - India (LUH)",
    value: "LUH",
  },
  {
    label: "Madurai, Madurai - India (IXM)",
    value: "IXM",
  },
  {
    label: "Malda, Malda - India (LDA)",
    value: "LDA",
  },
  {
    label: "Mangalore, Mangalore - India (IXE)",
    value: "IXE",
  },
  {
    label: "Mumbai, Chhatrapati Shivaji - India (BOM)",
    value: "BOM",
  },
  {
    label: "Muzaffarpur, Muzaffarpur - India (MZU)",
    value: "MZU",
  },
  {
    label: "Mysore, Mandakalli - India (MYQ)",
    value: "MYQ",
  },
  {
    label: "Nagpur, Dr.Ambedkar Intl - India (NAG)",
    value: "NAG",
  },
  {
    label: "Nanded, Nanded - India (NDC)",
    value: "NDC",
  },
  {
    label: "Nasik, Ozar - India (ISK)",
    value: "ISK",
  },
  {
    label: "Neyveli, Neyveli - India (NVY)",
    value: "NVY",
  },
  {
    label: "Nilgris India, Nilgris India - India (VWY)",
    value: "VWY",
  },
  {
    label: "Pantnagar, Pantnagar - India (PGH)",
    value: "PGH",
  },
  {
    label: "Pasighat, Pasighat - India (IXT)",
    value: "IXT",
  },
  {
    label: "Pathankot, Pathankot - India (IXP)",
    value: "IXP",
  },
  {
    label: "Patna, Jayaprakash Narayan - India (PAT)",
    value: "PAT",
  },
  {
    label: "Pondicherry, Pondicherry - India (PNY)",
    value: "PNY",
  },
  {
    label: "Porbandar, Porbandar - India (PBD)",
    value: "PBD",
  },
  {
    label: "Port Blair, Veer Savarkar Intl - India (IXZ)",
    value: "IXZ",
  },
  {
    label: "Pune, Lohegaon - India (PNQ)",
    value: "PNQ",
  },
  {
    label: "Puttaparthi, Sri Sathya Sai - India (PUT)",
    value: "PUT",
  },
  {
    label: "Raipur, Swami Vivekananda - India (RPR)",
    value: "RPR",
  },
  {
    label: "Rajahmundry, Rajahmundry - India (RJA)",
    value: "RJA",
  },
  {
    label: "Rajauri, Rajauri - India (RJI)",
    value: "RJI",
  },
  {
    label: "Rajkot, Rajkot - India (RAJ)",
    value: "RAJ",
  },
  {
    label: "Ramagundam, Basantnagar - India (RMD)",
    value: "RMD",
  },
  {
    label: "Ranchi, Birsa Munda - India (IXR)",
    value: "IXR",
  },
  {
    label: "Ratnagiri, Ratnagiri - India (RTC)",
    value: "RTC",
  },
  {
    label: "Rewa, Chorhata - India (REW)",
    value: "REW",
  },
  {
    label: "Rourkela, Rourkela - India (RRK)",
    value: "RRK",
  },
  {
    label: "Rupsi, Rupsi - India (RUP)",
    value: "RUP",
  },
  {
    label: "Salem, Salem - India (SXV)",
    value: "SXV",
  },
  {
    label: "Satna, Satna - India (TNI)",
    value: "TNI",
  },
  {
    label: "Shillong, Barapani - India (SHL)",
    value: "SHL",
  },
  {
    label: "Shimla, Shimla - India (SLV)",
    value: "SLV",
  },
  {
    label: "Silchar, Kumbhirgram - India (IXS)",
    value: "IXS",
  },
  {
    label: "Solapur, Solapur - India (SSE)",
    value: "SSE",
  },
  {
    label: "Srinagar, Sheikh Ul Alam - India (SXR)",
    value: "SXR",
  },
  {
    label: "Surat, Surat - India (STV)",
    value: "STV",
  },
  {
    label: "Tezpur, Tezpur - India (TEZ)",
    value: "TEZ",
  },
  {
    label: "Tezu, Tezu - India (TEI)",
    value: "TEI",
  },
  {
    label: "Thanjavur, Thanjavur - India (TJV)",
    value: "TJV",
  },
  {
    label: "Thiruvananthapuram, Thiruvananthapuram - India (TRV)",
    value: "TRV",
  },
  {
    label: "Tiruchchirappalli, Tiruchchirappalli - India (TRZ)",
    value: "TRZ",
  },
  {
    label: "Tirupati, Tirupati - India (TIR)",
    value: "TIR",
  },
  {
    label: "Tuticorin, Tuticorin - India (TCR)",
    value: "TCR",
  },
  {
    label: "Udaipur, Maharana Pratap - India (UDR)",
    value: "UDR",
  },
  {
    label: "Vadodara, Vadodara - India (BDQ)",
    value: "BDQ",
  },
  {
    label: "Varanasi, Lal Bahadur Shastri - India (VNS)",
    value: "VNS",
  },
  {
    label: "Vidyanagar, Jindal - India (VDY)",
    value: "VDY",
  },
  {
    label: "Vijayawada, Vijayawada - India (VGA)",
    value: "VGA",
  },
  {
    label: "Vishakhapatnam, Vishakhapatnam - India (VTZ)",
    value: "VTZ",
  },
  {
    label: "Warangal, Warangal - India (WGC)",
    value: "WGC",
  },
  {
    label: "Ziro, Ziro - India (ZER)",
    value: "ZER",
  },
  {
    label: "Alor, Mali - Indonesia (ARD)",
    value: "ARD",
  },
  {
    label: "Amahai, Amahai - Indonesia (AHI)",
    value: "AHI",
  },
  {
    label: "Ambon, Pattimura - Indonesia (AMQ)",
    value: "AMQ",
  },
  {
    label: "Anggi, Anggi - Indonesia (AGD)",
    value: "AGD",
  },
  {
    label: "Apalapsili, Apalapsili - Indonesia (AAS)",
    value: "AAS",
  },
  {
    label: "Arso, Arso - Indonesia (ARJ)",
    value: "ARJ",
  },
  {
    label: "Atambua, Haliwen - Indonesia (ABU)",
    value: "ABU",
  },
  {
    label: "Ayamaru, Kambuaya - Indonesia (KBX)",
    value: "KBX",
  },
  {
    label: "Ayawasi, Ayawasi - Indonesia (AYW)",
    value: "AYW",
  },
  {
    label: "Bade, Bade - Indonesia (BXD)",
    value: "BXD",
  },
  {
    label: "Bajawa, Soa - Indonesia (BJW)",
    value: "BJW",
  },
  {
    label: "Balikpapan, Sepinggan - Indonesia (BPN)",
    value: "BPN",
  },
  {
    label: "Banaina, Banaina - Indonesia (NAF)",
    value: "NAF",
  },
  {
    label: "Banda Aceh, Sultan Iskandar Muda - Indonesia (BTJ)",
    value: "BTJ",
  },
  {
    label: "Banda Island, Bandaneira - Indonesia (NDA)",
    value: "NDA",
  },
  {
    label: "Bandar Lampung, Radin Inten Ii - Indonesia (TKG)",
    value: "TKG",
  },
  {
    label: "Bandung, Husein Sastranegara - Indonesia (BDO)",
    value: "BDO",
  },
  {
    label: "Banjarmasin, Syamsudin Noor - Indonesia (BDJ)",
    value: "BDJ",
  },
  {
    label: "Banyuwangi, Blimbingsari - Indonesia (BWX)",
    value: "BWX",
  },
  {
    label: "Batom, Batom - Indonesia (BXM)",
    value: "BXM",
  },
  {
    label: "Batu Besar, Hang Nadim - Indonesia (BTH)",
    value: "BTH",
  },
  {
    label: "Batu Licin, Batu Licin - Indonesia (BTW)",
    value: "BTW",
  },
  {
    label: "Baubau, Betoambari - Indonesia (BUW)",
    value: "BUW",
  },
  {
    label: "Bawean, Bawean - Indonesia (BXW)",
    value: "BXW",
  },
  {
    label: "Bengkalis, Sei Pakning - Indonesia (SEQ)",
    value: "SEQ",
  },
  {
    label: "Bengkulu, Fatmawati Soekarno - Indonesia (BKS)",
    value: "BKS",
  },
  {
    label: "Biak, Frans Kaisiepo - Indonesia (BIK)",
    value: "BIK",
  },
  {
    label: "Bilogai, Bilogai - Indonesia (UGU)",
    value: "UGU",
  },
  {
    label: "Bima, Sultan M.Salahuddin - Indonesia (BMU)",
    value: "BMU",
  },
  {
    label: "Bintuni, Stenkol - Indonesia (NTI)",
    value: "NTI",
  },
  {
    label: "Bokondini, Bokondini - Indonesia (BUI)",
    value: "BUI",
  },
  {
    label: "Bontang, Bontang - Indonesia (BXT)",
    value: "BXT",
  },
  {
    label: "Bunyu, Bunyu - Indonesia (BYQ)",
    value: "BYQ",
  },
  {
    label: "Buol, Pogogul - Indonesia (UOL)",
    value: "UOL",
  },
  {
    label: "Cepu, Nglonram - Indonesia (CPF)",
    value: "CPF",
  },
  {
    label: "Cilacap, Tunggul Wulung - Indonesia (CXP)",
    value: "CXP",
  },
  {
    label: "Cirebon, Cakrabhuwana - Indonesia (CBN)",
    value: "CBN",
  },
  {
    label: "Cut Nyak Dien, Nagan Raya - Indonesia (MEQ)",
    value: "MEQ",
  },
  {
    label: "Dabra, Dabra - Indonesia (DRH)",
    value: "DRH",
  },
  {
    label: "Datadawai, Datadawai - Indonesia (DTD)",
    value: "DTD",
  },
  {
    label: "Denpasar-Bali, Ngurah Rai - Indonesia (DPS)",
    value: "DPS",
  },
  {
    label: "Dobo, Rar Gwamar - Indonesia (DOB)",
    value: "DOB",
  },
  {
    label: "Dumai, Pinang Kampai - Indonesia (DUM)",
    value: "DUM",
  },
  {
    label: "Elelim, Elelim - Indonesia (ELR)",
    value: "ELR",
  },
  {
    label: "Enarotali, Enarotali - Indonesia (EWI)",
    value: "EWI",
  },
  {
    label: "Ende, H Hasan Aroeboesman - Indonesia (ENE)",
    value: "ENE",
  },
  {
    label: "Ewer, Ewer - Indonesia (EWE)",
    value: "EWE",
  },
  {
    label: "Fak Fak, Torea - Indonesia (FKQ)",
    value: "FKQ",
  },
  {
    label: "Gag Island, Gag Island - Indonesia (GAV)",
    value: "GAV",
  },
  {
    label: "Galela, Gamarmalamo - Indonesia (GLX)",
    value: "GLX",
  },
  {
    label: "Gebe, Gebe - Indonesia (GEB)",
    value: "GEB",
  },
  {
    label: "Gorontalo, Djalaluddin - Indonesia (GTO)",
    value: "GTO",
  },
  {
    label: "Gunung Sitoli, Binaka - Indonesia (GNS)",
    value: "GNS",
  },
  {
    label: "Illaga, Illaga - Indonesia (ILA)",
    value: "ILA",
  },
  {
    label: "Ilu, Ilu - Indonesia (IUL)",
    value: "IUL",
  },
  {
    label: "Inanwatan, Inanwatan - Indonesia (INX)",
    value: "INX",
  },
  {
    label: "Irarutu, Babo - Indonesia (BXB)",
    value: "BXB",
  },
  {
    label: "Jakarta, Halim Perdanakusama - Indonesia (HLP)",
    value: "HLP",
  },
  {
    label: "Jakarta, Soekarno Hatta Intl - Indonesia (CGK)",
    value: "CGK",
  },
  {
    label: "Jambi, Sultan Thaha - Indonesia (DJB)",
    value: "DJB",
  },
  {
    label: "Jayapura, Sentani - Indonesia (DJJ)",
    value: "DJJ",
  },
  {
    label: "Jember, Noto Hadinegoro - Indonesia (JBB)",
    value: "JBB",
  },
  {
    label: "Kamur, Kamur - Indonesia (KCD)",
    value: "KCD",
  },
  {
    label: "Karimunjawa, Karimunjawa - Indonesia (KWB)",
    value: "KWB",
  },
  {
    label: "Karubaga, Karubaga - Indonesia (KBF)",
    value: "KBF",
  },
  {
    label: "Kau, Kaubang - Indonesia (KAZ)",
    value: "KAZ",
  },
  {
    label: "Kebar, Kebar - Indonesia (KEQ)",
    value: "KEQ",
  },
  {
    label: "Kelila, Kelila - Indonesia (LLN)",
    value: "LLN",
  },
  {
    label: "Keluang, Keluang - Indonesia (KLQ)",
    value: "KLQ",
  },
  {
    label: "Kendari, Halu Oleo - Indonesia (KDI)",
    value: "KDI",
  },
  {
    label: "Kepi, Kepi - Indonesia (KEI)",
    value: "KEI",
  },
  {
    label: "Kerinci, Depati Parbo - Indonesia (KRC)",
    value: "KRC",
  },
  {
    label: "Ketapang, Rahadi Osman - Indonesia (KTG)",
    value: "KTG",
  },
  {
    label: "Kimam, Kimam - Indonesia (KMM)",
    value: "KMM",
  },
  {
    label: "Kokonao, Kokonao - Indonesia (KOX)",
    value: "KOX",
  },
  {
    label: "Kolaka, Pomala - Indonesia (PUM)",
    value: "PUM",
  },
  {
    label: "Kota Mobagu, Mopait - Indonesia (BJG)",
    value: "BJG",
  },
  {
    label: "Kotabangun, Kotabangun - Indonesia (KOD)",
    value: "KOD",
  },
  {
    label: "Kotabaru, Gusti Sjamsir Alam - Indonesia (KBU)",
    value: "KBU",
  },
  {
    label: "Kupang, Eltari - Indonesia (KOE)",
    value: "KOE",
  },
  {
    label: "Labuan Bajo, Komodo - Indonesia (LBJ)",
    value: "LBJ",
  },
  {
    label: "Labuha, Oesman Sadik - Indonesia (LAH)",
    value: "LAH",
  },
  {
    label: "Lahat, Pagar Alam - Indonesia (PXA)",
    value: "PXA",
  },
  {
    label: "Langgur, Dumatubun - Indonesia (LUV)",
    value: "LUV",
  },
  {
    label: "Larantuka, Gewayantana - Indonesia (LKA)",
    value: "LKA",
  },
  {
    label: "Lereh, Lereh - Indonesia (LHI)",
    value: "LHI",
  },
  {
    label: "Lewoleba, Wunopito - Indonesia (LWE)",
    value: "LWE",
  },
  {
    label: "Lhok Seumawe, Malikussaleh - Indonesia (LSW)",
    value: "LSW",
  },
  {
    label: "Lhok Sukon, Lhok Sukon - Indonesia (LSX)",
    value: "LSX",
  },
  {
    label: "Long Apung, Long Apung - Indonesia (LPU)",
    value: "LPU",
  },
  {
    label: "Long Bawan, Yuvai Semaring - Indonesia (LBW)",
    value: "LBW",
  },
  {
    label: "Luwuk, S.Aminuddin Amir - Indonesia (LUW)",
    value: "LUW",
  },
  {
    label: "Makassar, Sultan Hasanuddin - Indonesia (UPG)",
    value: "UPG",
  },
  {
    label: "Malang, Abdul Rachman Saleh - Indonesia (MLG)",
    value: "MLG",
  },
  {
    label: "Mamuju, Tampa Padang - Indonesia (MJU)",
    value: "MJU",
  },
  {
    label: "Manado, Sam Ratulangi - Indonesia (MDC)",
    value: "MDC",
  },
  {
    label: "Mangole, Falabisahaya - Indonesia (MAL)",
    value: "MAL",
  },
  {
    label: "Manokwari, Rendani - Indonesia (MKW)",
    value: "MKW",
  },
  {
    label: "Masalembo, Masalembo - Indonesia (MSI)",
    value: "MSI",
  },
  {
    label: "Masamba, Andi Jemma - Indonesia (MXB)",
    value: "MXB",
  },
  {
    label: "Matak, Tarempa - Indonesia (MWK)",
    value: "MWK",
  },
  {
    label: "Mataram, Selaparang - Indonesia (AMI)",
    value: "AMI",
  },
  {
    label: "Maumere, Fransiskus X Seda - Indonesia (MOF)",
    value: "MOF",
  },
  {
    label: "Medan, Kuala Namu - Indonesia (KNO)",
    value: "KNO",
  },
  {
    label: "Medan, Polonia - Indonesia (MES)",
    value: "MES",
  },
  {
    label: "Melangguane, Melangguane - Indonesia (MNA)",
    value: "MNA",
  },
  {
    label: "Menggala, Astra Ksetra - Indonesia (AKQ)",
    value: "AKQ",
  },
  {
    label: "Merauke, Mopah - Indonesia (MKQ)",
    value: "MKQ",
  },
  {
    label: "Merdey, Jahabra - Indonesia (RDE)",
    value: "RDE",
  },
  {
    label: "Mindiptana, Mindiptana - Indonesia (MDP)",
    value: "MDP",
  },
  {
    label: "Moanamani, Moanamani - Indonesia (ONI)",
    value: "ONI",
  },
  {
    label: "Morotai Island, Pitu - Indonesia (OTI)",
    value: "OTI",
  },
  {
    label: "Muko Muko, Muko Muko - Indonesia (MPC)",
    value: "MPC",
  },
  {
    label: "Mulia, Mulia - Indonesia (LII)",
    value: "LII",
  },
  {
    label: "Muna, Sugimanuru - Indonesia (RAQ)",
    value: "RAQ",
  },
  {
    label: "Muting, Muting - Indonesia (MUF)",
    value: "MUF",
  },
  {
    label: "Nabire, Nabire - Indonesia (NBX)",
    value: "NBX",
  },
  {
    label: "Namlea, Namlea - Indonesia (NAM)",
    value: "NAM",
  },
  {
    label: "Namrole, Namrole - Indonesia (NRE)",
    value: "NRE",
  },
  {
    label: "Nangapinoh, Nangapinoh - Indonesia (NPO)",
    value: "NPO",
  },
  {
    label: "Nangasuri Aru Island, Benjina - Indonesia (BJK)",
    value: "BJK",
  },
  {
    label: "Natuna, Ranai - Indonesia (NTX)",
    value: "NTX",
  },
  {
    label: "Numfoor, Numfoor - Indonesia (FOO)",
    value: "FOO",
  },
  {
    label: "Nunukan, Nunukan - Indonesia (NNX)",
    value: "NNX",
  },
  {
    label: "Obano, Obano - Indonesia (OBD)",
    value: "OBD",
  },
  {
    label: "Okaba, Okaba - Indonesia (OKQ)",
    value: "OKQ",
  },
  {
    label: "Oksibil, Gunung Bintang - Indonesia (OKL)",
    value: "OKL",
  },
  {
    label: "Padang, Minangkabau - Indonesia (PDG)",
    value: "PDG",
  },
  {
    label: "Padangsidempuan, Aekgodang - Indonesia (AEG)",
    value: "AEG",
  },
  {
    label: "Palangkaraya, Tjilik Riwut - Indonesia (PKY)",
    value: "PKY",
  },
  {
    label: "Palembang, S.M.Badaruddin Ii - Indonesia (PLM)",
    value: "PLM",
  },
  {
    label: "Palopo, Lagaligo - Indonesia (LLO)",
    value: "LLO",
  },
  {
    label: "Palu, Mutiara - Indonesia (PLW)",
    value: "PLW",
  },
  {
    label: "Pangkalanbuun, Iskandar - Indonesia (PKN)",
    value: "PKN",
  },
  {
    label: "Pangkalpinang, Depati Amir - Indonesia (PGK)",
    value: "PGK",
  },
  {
    label: "Panjang Island, Panjang Island - Indonesia (PPJ)",
    value: "PPJ",
  },
  {
    label: "Parapat, Sibisa - Indonesia (SIW)",
    value: "SIW",
  },
  {
    label: "Pasir Pangarayan, Pasir Pangarayan - Indonesia (PPR)",
    value: "PPR",
  },
  {
    label: "Pekanbaru, Sultan Syarif Kasim Ii - Indonesia (PKU)",
    value: "PKU",
  },
  {
    label: "Pendopo, Pendopo - Indonesia (PDO)",
    value: "PDO",
  },
  {
    label: "Pondok Cabe, Pondok Cabe - Indonesia (PCB)",
    value: "PCB",
  },
  {
    label: "Pontianak, Supadio - Indonesia (PNK)",
    value: "PNK",
  },
  {
    label: "Poso, Kasinguncu - Indonesia (PSJ)",
    value: "PSJ",
  },
  {
    label: "Praya, Lombok International - Indonesia (LOP)",
    value: "LOP",
  },
  {
    label: "Purwokerto, Wirasaba - Indonesia (PWL)",
    value: "PWL",
  },
  {
    label: "Putussibau, Pangsuma - Indonesia (PSU)",
    value: "PSU",
  },
  {
    label: "Ransiki, Abresso - Indonesia (RSK)",
    value: "RSK",
  },
  {
    label: "Rengat, Japura - Indonesia (RGT)",
    value: "RGT",
  },
  {
    label: "Rokot, Sipora - Indonesia (RKI)",
    value: "RKI",
  },
  {
    label: "Roti, David C. Saudale - Indonesia (RTI)",
    value: "RTI",
  },
  {
    label: "Ruteng, Frans Sales Lega - Indonesia (RTG)",
    value: "RTG",
  },
  {
    label: "Sabang, Maimun Saleh - Indonesia (SBG)",
    value: "SBG",
  },
  {
    label: "Samarinda, Temindung - Indonesia (SRI)",
    value: "SRI",
  },
  {
    label: "Sampit, H.Asan - Indonesia (SMQ)",
    value: "SMQ",
  },
  {
    label: "Sanana, Emalamo - Indonesia (SQN)",
    value: "SQN",
  },
  {
    label: "Sangata, Sangkimah - Indonesia (SGQ)",
    value: "SGQ",
  },
  {
    label: "Sarmi, Orai - Indonesia (ZRM)",
    value: "ZRM",
  },
  {
    label: "Saumlaki, Olilit - Indonesia (SXK)",
    value: "SXK",
  },
  {
    label: "Sawu, Tardamu - Indonesia (SAU)",
    value: "SAU",
  },
  {
    label: "Semarang, Ahmad Yani - Indonesia (SRG)",
    value: "SRG",
  },
  {
    label: "Senggeh, Senggeh - Indonesia (SEH)",
    value: "SEH",
  },
  {
    label: "Senggo, Senggo - Indonesia (ZEG)",
    value: "ZEG",
  },
  {
    label: "Seram Island, Wahai - Indonesia (WBA)",
    value: "WBA",
  },
  {
    label: "Serui, S.Condronegoro - Indonesia (ZRI)",
    value: "ZRI",
  },
  {
    label: "Sibolga, Ferdinand - Indonesia (FLZ)",
    value: "FLZ",
  },
  {
    label: "Siborong Borong, Silangit - Indonesia (DTB)",
    value: "DTB",
  },
  {
    label: "Sinak, Sinak - Indonesia (NKD)",
    value: "NKD",
  },
  {
    label: "Singkep, Dabo - Indonesia (SIQ)",
    value: "SIQ",
  },
  {
    label: "Sintang, Susilo - Indonesia (SQG)",
    value: "SQG",
  },
  {
    label: "Soroako, Soroako - Indonesia (SQR)",
    value: "SQR",
  },
  {
    label: "Sorong, Domine Eduard Osok - Indonesia (SOQ)",
    value: "SOQ",
  },
  {
    label: "Sumbawa, Brangbiji - Indonesia (SWQ)",
    value: "SWQ",
  },
  {
    label: "Sumbawa, Lunyuk - Indonesia (LYK)",
    value: "LYK",
  },
  {
    label: "Sumenep, Trunojoyo - Indonesia (SUP)",
    value: "SUP",
  },
  {
    label: "Surabaya, Juanda - Indonesia (SUB)",
    value: "SUB",
  },
  {
    label: "Surakarta  Solo), Adi Sumarmo - Indonesia (SOC)",
    value: "SOC",
  },
  {
    label: "Tahuna, Naha - Indonesia (NAH)",
    value: "NAH",
  },
  {
    label: "Takengon, Pagar Alam - Indonesia (TXE)",
    value: "TXE",
  },
  {
    label: "Taliabu, Taliabu - Indonesia (TAX)",
    value: "TAX",
  },
  {
    label: "Tambolaka, Waikabubak - Indonesia (TMC)",
    value: "TMC",
  },
  {
    label: "Tana Toraja, Pongtiku - Indonesia (TTR)",
    value: "TTR",
  },
  {
    label: "Tanah Grogot, Tanah Grogot - Indonesia (TNB)",
    value: "TNB",
  },
  {
    label: "Tanahmerah, Tanahmerah - Indonesia (TMH)",
    value: "TMH",
  },
  {
    label: "Tanjung Balai, Sei Bati - Indonesia (TJB)",
    value: "TJB",
  },
  {
    label: "Tanjung Pendan, H.A.S. Hanandjoeddin - Indonesia (TJQ)",
    value: "TJQ",
  },
  {
    label: "Tanjung Pinang, Raja Haji Fisabilillah - Indonesia (TNJ)",
    value: "TNJ",
  },
  {
    label: "Tanjung Reded, Kalimarau - Indonesia (BEJ)",
    value: "BEJ",
  },
  {
    label: "Tanjung Santan, Tanjung Santan - Indonesia (TSX)",
    value: "TSX",
  },
  {
    label: "Tanjung Selor, Tanjung Harapan - Indonesia (TJS)",
    value: "TJS",
  },
  {
    label: "Tanjung Warukin, Tanjung Warukin - Indonesia (TJG)",
    value: "TJG",
  },
  {
    label: "Tapaktuan, Teuku Cut Ali - Indonesia (TPK)",
    value: "TPK",
  },
  {
    label: "Tarakan, Juwata - Indonesia (TRK)",
    value: "TRK",
  },
  {
    label: "Tasikmalaya, Wiriadinata - Indonesia (TSY)",
    value: "TSY",
  },
  {
    label: "Tembagapura/Timika, Moses Kilangin - Indonesia (TIM)",
    value: "TIM",
  },
  {
    label: "Teminabuan, Teminabuan - Indonesia (TXM)",
    value: "TXM",
  },
  {
    label: "Ternate, Sultan Babullah - Indonesia (TTE)",
    value: "TTE",
  },
  {
    label: "Tiakur, Jos Orno Imsula - Indonesia (JIO)",
    value: "JIO",
  },
  {
    label: "Tiom, Tiom - Indonesia (TMY)",
    value: "TMY",
  },
  {
    label: "Tolitoli, Lalos - Indonesia (TLI)",
    value: "TLI",
  },
  {
    label: "Tomia, Maranggo - Indonesia (TQQ)",
    value: "TQQ",
  },
  {
    label: "Tumbang Samba, Tumbang Samba - Indonesia (TBM)",
    value: "TBM",
  },
  {
    label: "Ubrub, Ubrub - Indonesia (UBR)",
    value: "UBR",
  },
  {
    label: "Utarom, Kaimana - Indonesia (KNG)",
    value: "KNG",
  },
  {
    label: "Waghete, Waghete - Indonesia (WET)",
    value: "WET",
  },
  {
    label: "Waingapu, Umbu Mehang Kunda - Indonesia (WGP)",
    value: "WGP",
  },
  {
    label: "Waisai, Marinda - Indonesia (RJM)",
    value: "RJM",
  },
  {
    label: "Wamena, Wamena - Indonesia (WMX)",
    value: "WMX",
  },
  {
    label: "Waris, Waris - Indonesia (WAR)",
    value: "WAR",
  },
  {
    label: "Wasior, Wasior - Indonesia (WSR)",
    value: "WSR",
  },
  {
    label: "Yahukimo, Nop Goliath - Indonesia (DEX)",
    value: "DEX",
  },
  {
    label: "Yogyakarta, Adisutjipto - Indonesia (JOG)",
    value: "JOG",
  },
  {
    label: "Yuruf, Yuruf - Indonesia (RUF)",
    value: "RUF",
  },
  {
    label: "Abadan, Abadan - Iran (ABD)",
    value: "ABD",
  },
  {
    label: "Abu Musa Island, Abu Musa Island - Iran (AEU)",
    value: "AEU",
  },
  {
    label: "Aghajari, Aghajari - Iran (AKW)",
    value: "AKW",
  },
  {
    label: "Ahwaz, Ahwaz - Iran (AWZ)",
    value: "AWZ",
  },
  {
    label: "Araak, Araak - Iran (AJK)",
    value: "AJK",
  },
  {
    label: "Ardabil, Ardabil - Iran (ADU)",
    value: "ADU",
  },
  {
    label: "Asaloyeh, Persian Gulf Intl - Iran (PGU)",
    value: "PGU",
  },
  {
    label: "Bahregan, Bahregan - Iran (IAQ)",
    value: "IAQ",
  },
  {
    label: "Bam, Bam - Iran (BXR)",
    value: "BXR",
  },
  {
    label: "Bandar Abbas, Bandar Abbas Intl - Iran (BND)",
    value: "BND",
  },
  {
    label: "Bandar Lengeh, Bandar Lengeh - Iran (BDH)",
    value: "BDH",
  },
  {
    label: "Bandar Mahshahr, Bandar Mahshahr - Iran (MRX)",
    value: "MRX",
  },
  {
    label: "Birjand, Birjand - Iran (XBJ)",
    value: "XBJ",
  },
  {
    label: "Bishen Kola, Bishen Kola - Iran (BSM)",
    value: "BSM",
  },
  {
    label: "Bojnurd, Bojnurd - Iran (BJB)",
    value: "BJB",
  },
  {
    label: "Bushehr, Bushehr - Iran (BUZ)",
    value: "BUZ",
  },
  {
    label: "Chah Bahar, Konarak - Iran (ZBR)",
    value: "ZBR",
  },
  {
    label: "Dezful, Dezful - Iran (DEF)",
    value: "DEF",
  },
  {
    label: "Esfahan, Shahid Beheshti Intl - Iran (IFN)",
    value: "IFN",
  },
  {
    label: "Fasa, Fasa Airport - Iran (FAZ)",
    value: "FAZ",
  },
  {
    label: "Gachsaran, Gachsaran - Iran (GCH)",
    value: "GCH",
  },
  {
    label: "Ghazvin, Ghazvin - Iran (GZW)",
    value: "GZW",
  },
  {
    label: "Gheshm Island, Dayrestan - Iran (GSM)",
    value: "GSM",
  },
  {
    label: "Gorgan, Gorgan Airport - Iran (GBT)",
    value: "GBT",
  },
  {
    label: "Hamadan, Hamadan - Iran (HDM)",
    value: "HDM",
  },
  {
    label: "Havadarya, Havadarya - Iran (HDR)",
    value: "HDR",
  },
  {
    label: "Hesa, Hesa - Iran (IFH)",
    value: "IFH",
  },
  {
    label: "Ilam, Ilam - Iran (IIL)",
    value: "IIL",
  },
  {
    label: "Iranshahr, Iranshahr Airport - Iran (IHR)",
    value: "IHR",
  },
  {
    label: "Jahrom, Jahrom Airport - Iran (JAR)",
    value: "JAR",
  },
  {
    label: "Jiroft, Jiroft - Iran (JYR)",
    value: "JYR",
  },
  {
    label: "Kalaleh, Kalaleh - Iran (KLM)",
    value: "KLM",
  },
  {
    label: "Kangan, Jam - Iran (KNR)",
    value: "KNR",
  },
  {
    label: "Karaj, Payam - Iran (PYK)",
    value: "PYK",
  },
  {
    label: "Kerman, Kerman - Iran (KER)",
    value: "KER",
  },
  {
    label: "Kermanshah, Shahid Ashrafiesfahani - Iran (KSH)",
    value: "KSH",
  },
  {
    label: "Khaneh, Khaneh - Iran (KHA)",
    value: "KHA",
  },
  {
    label: "Khark Island, Khark Island - Iran (KHK)",
    value: "KHK",
  },
  {
    label: "Khorramabad, Khorramabad - Iran (KHD)",
    value: "KHD",
  },
  {
    label: "Khoy, Khoy - Iran (KHY)",
    value: "KHY",
  },
  {
    label: "Kish Island, Kish Island - Iran (KIH)",
    value: "KIH",
  },
  {
    label: "Lamerd, Lamerd Airport - Iran (LFM)",
    value: "LFM",
  },
  {
    label: "Lar, Lar - Iran (LRR)",
    value: "LRR",
  },
  {
    label: "Lavan Island, Lavan Island - Iran (LVP)",
    value: "LVP",
  },
  {
    label: "Maragheh, Sahand Airport - Iran (ACP)",
    value: "ACP",
  },
  {
    label: "Mashhad, Shahid Hashemi Nejad - Iran (MHD)",
    value: "MHD",
  },
  {
    label: "Nogeh, Nogeh - Iran (NUJ)",
    value: "NUJ",
  },
  {
    label: "Now Shahr, Now Shahr - Iran (NSH)",
    value: "NSH",
  },
  {
    label: "Omidiyeh, Omidiyeh - Iran (OMI)",
    value: "OMI",
  },
  {
    label: "Parsabad, Moghan - Iran (PFQ)",
    value: "PFQ",
  },
  {
    label: "Rafsanjan, Rafsanjan - Iran (RJN)",
    value: "RJN",
  },
  {
    label: "Ramsar, Ramsar - Iran (RZR)",
    value: "RZR",
  },
  {
    label: "Rasht, Sandra-E-Jangal - Iran (RAS)",
    value: "RAS",
  },
  {
    label: "Sabzevar, Sabzevar - Iran (AFZ)",
    value: "AFZ",
  },
  {
    label: "Sanandaj, Sanandaj - Iran (SDG)",
    value: "SDG",
  },
  {
    label: "Sarakhs, Sarakhs - Iran (CKT)",
    value: "CKT",
  },
  {
    label: "Sari, Dasht-E-Naz - Iran (SRY)",
    value: "SRY",
  },
  {
    label: "Shahre Kord, Shahre Kord - Iran (CQD)",
    value: "CQD",
  },
  {
    label: "Shahrud, Shahrud - Iran (RUD)",
    value: "RUD",
  },
  {
    label: "Shiraz, Shahid Dastghaib Intl - Iran (SYZ)",
    value: "SYZ",
  },
  {
    label: "Sirjan, Sirjan - Iran (SYJ)",
    value: "SYJ",
  },
  {
    label: "Sirri Island, Sirri Island - Iran (SXI)",
    value: "SXI",
  },
  {
    label: "Tabas, Tabas - Iran (TCX)",
    value: "TCX",
  },
  {
    label: "Tabriz, Tabriz International - Iran (TBZ)",
    value: "TBZ",
  },
  {
    label: "Tehran, Imam Khomeini Intl - Iran (IKA)",
    value: "IKA",
  },
  {
    label: "Tehran, Mehrabad Intl - Iran (THR)",
    value: "THR",
  },
  {
    label: "Tohid, Tohid Airport - Iran (TEW)",
    value: "TEW",
  },
  {
    label: "Urumiyeh, Urumiyeh - Iran (OMH)",
    value: "OMH",
  },
  {
    label: "Yasouj, Yasouj - Iran (YES)",
    value: "YES",
  },
  {
    label: "Yazd, Shahid Sadooghi - Iran (AZD)",
    value: "AZD",
  },
  {
    label: "Zabol, Zabol Airport - Iran (ACZ)",
    value: "ACZ",
  },
  {
    label: "Zahedan, Zahedan International - Iran (ZAH)",
    value: "ZAH",
  },
  {
    label: "Zanjan, Zanjan - Iran (JWN)",
    value: "JWN",
  },
  {
    label: "Al Asad, Air Base - Iraq (IQA)",
    value: "IQA",
  },
  {
    label: "Al Fallujah, Al Taqaddum Ab - Iraq (TQD)",
    value: "TQD",
  },
  {
    label: "Al Najaf, Al Ashraf Intl - Iraq (NJF)",
    value: "NJF",
  },
  {
    label: "Baghdad, Baghdad Intl - Iraq (BGW)",
    value: "BGW",
  },
  {
    label: "Bamarni, Bamarni - Iraq (BMN)",
    value: "BMN",
  },
  {
    label: "Basrah, Basrah International - Iraq (BSR)",
    value: "BSR",
  },
  {
    label: "Erbil, International - Iraq (EBL)",
    value: "EBL",
  },
  {
    label: "Kirkuk, Air Base - Iraq (KIK)",
    value: "KIK",
  },
  {
    label: "Mosul, International - Iraq (OSM)",
    value: "OSM",
  },
  {
    label: "Qayyarah, West - Iraq (RQW)",
    value: "RQW",
  },
  {
    label: "Sulaymaniyah, International - Iraq (ISU)",
    value: "ISU",
  },
  {
    label: "Cork - Ireland-Republic Of (ORK)",
    value: "ORK",
  },
  {
    label: "Cork, Cork International - Ireland-Republic Of (ORK)",
    value: "ORK",
  },
  {
    label: "Dublin - Ireland-Republic Of (DUB)",
    value: "DUB",
  },
  {
    label: "Dublin, Dublin International - Ireland-Republic Of (DUB)",
    value: "DUB",
  },
  {
    label: "Knock - Ireland-Republic Of (NOC)",
    value: "NOC",
  },
  {
    label: "Knock, Ireland West - Ireland-Republic Of (NOC)",
    value: "NOC",
  },
  {
    label: "Kerry County - Ireland-Republic Of (KIR)",
    value: "KIR",
  },
  {
    label: "Kerry County, Kerry County - Ireland-Republic Of (KIR)",
    value: "KIR",
  },
  {
    label: "Shannon - Ireland-Republic Of (SNN)",
    value: "SNN",
  },
  {
    label: "Shannon, Shannon - Ireland-Republic Of (SNN)",
    value: "SNN",
  },
  {
    label: "Bantry, Bantry - Ireland-Republic Of (BYT)",
    value: "BYT",
  },
  {
    label: "Belmullet, Belmullet - Ireland-Republic Of (BLY)",
    value: "BLY",
  },
  {
    label: "Donegal, Donegal - Ireland-Republic Of (CFN)",
    value: "CFN",
  },
  {
    label: "Galway, Galway - Ireland-Republic Of (GWY)",
    value: "GWY",
  },
  {
    label: "Inisheer, Inisheer - Ireland-Republic Of (INQ)",
    value: "INQ",
  },
  {
    label: "Inishmaan, Inishmaan - Ireland-Republic Of (IIA)",
    value: "IIA",
  },
  {
    label: "Inishmore, Kilronan - Ireland-Republic Of (IOR)",
    value: "IOR",
  },
  {
    label: "Kilkenny, Kilkenny - Ireland-Republic Of (KKY)",
    value: "KKY",
  },
  {
    label: "Letterkenny, Letterkenny - Ireland-Republic Of (LTR)",
    value: "LTR",
  },
  {
    label: "Sligo, Sligo - Ireland-Republic Of (SXL)",
    value: "SXL",
  },
  {
    label: "Spiddal, Connemara - Ireland-Republic Of (NNR)",
    value: "NNR",
  },
  {
    label: "Waterford, Waterford - Ireland-Republic Of (WAT)",
    value: "WAT",
  },
  {
    label: "Beersheba, Beersheba - Israel (BEV)",
    value: "BEV",
  },
  {
    label: "Eilat, Eilat - Israel (ETH)",
    value: "ETH",
  },
  {
    label: "Eilat, Ovda - Israel (VDA)",
    value: "VDA",
  },
  {
    label: "Ein Yahav, Ein Yahav - Israel (EIY)",
    value: "EIY",
  },
  {
    label: "Haifa, Haifa - Israel (HFA)",
    value: "HFA",
  },
  {
    label: "Jerusalem, Jerusalem - Israel (JRS)",
    value: "JRS",
  },
  {
    label: "Kiryat Shmona, Kiryat Shmona - Israel (KSW)",
    value: "KSW",
  },
  {
    label: "Masada, Bar Yehuda - Israel (MTZ)",
    value: "MTZ",
  },
  {
    label: "Mitspeh Ramon, Mitspeh Ramon - Israel (MIP)",
    value: "MIP",
  },
  {
    label: "Nevatim, Nevatim Afb - Israel (VTM)",
    value: "VTM",
  },
  {
    label: "Rosh Pina, Ben Ya Akov - Israel (RPN)",
    value: "RPN",
  },
  {
    label: "Sedom, Min'hat Hashnayim - Israel (SED)",
    value: "SED",
  },
  {
    label: "Tel Aviv Yafo, Ben Gurion Intl - Israel (TLV)",
    value: "TLV",
  },
  {
    label: "Tel Aviv Yafo, Sde Dov - Israel (SDV)",
    value: "SDV",
  },
  {
    label: "Yotvata, Yotvata - Israel (YOT)",
    value: "YOT",
  },
  {
    label: "Alghero - Italy (AHO)",
    value: "AHO",
  },
  {
    label: "Alghero, Fertilia - Italy (AHO)",
    value: "AHO",
  },
  {
    label: "Ancona - Italy (AOI)",
    value: "AOI",
  },
  {
    label: "Ancona, Falconara - Italy (AOI)",
    value: "AOI",
  },
  {
    label: "Bari - Italy (BRI)",
    value: "BRI",
  },
  {
    label: "Bari, Palese - Italy (BRI)",
    value: "BRI",
  },
  {
    label: "Milan - Italy (MIL)",
    value: "MIL",
  },
  {
    label: "Milan, Malpensa - Italy (MXP)",
    value: "MXP",
  },
  {
    label: "Milan, Bergamo Orio Al Serio - Italy (BGY)",
    value: "BGY",
  },
  {
    label: "Milan, Linate - Italy (LIN)",
    value: "LIN",
  },
  {
    label: "Milan, Parma - Italy (PMF)",
    value: "PMF",
  },
  {
    label: "Bologna - Italy (BLQ)",
    value: "BLQ",
  },
  {
    label: "Bologna, Guglielmo Marconi - Italy (BLQ)",
    value: "BLQ",
  },
  {
    label: "Bolzano Bozen - Italy (BZO)",
    value: "BZO",
  },
  {
    label: "Bolzano Bozen, Dolomiti - Italy (BZO)",
    value: "BZO",
  },
  {
    label: "Brindisi - Italy (BDS)",
    value: "BDS",
  },
  {
    label: "Brindisi, Casale - Italy (BDS)",
    value: "BDS",
  },
  {
    label: "Cagliari - Italy (CAG)",
    value: "CAG",
  },
  {
    label: "Cagliari, Elmas - Italy (CAG)",
    value: "CAG",
  },
  {
    label: "Catania - Italy (CTA)",
    value: "CTA",
  },
  {
    label: "Catania, Fontanarossa - Italy (CTA)",
    value: "CTA",
  },
  {
    label: "Comiso - Italy (CIY)",
    value: "CIY",
  },
  {
    label: "Comiso, Vincenzo Magliocco - Italy (CIY)",
    value: "CIY",
  },
  {
    label: "Florence - Italy (FLR)",
    value: "FLR",
  },
  {
    label: "Florence, Peretola - Italy (FLR)",
    value: "FLR",
  },
  {
    label: "Genoa - Italy (GOA)",
    value: "GOA",
  },
  {
    label: "Genoa, Cristoforo Colombo - Italy (GOA)",
    value: "GOA",
  },
  {
    label: "Lamezia Terme - Italy (SUF)",
    value: "SUF",
  },
  {
    label: "Lamezia Terme, Lamezia Terme - Italy (SUF)",
    value: "SUF",
  },
  {
    label: "Naples - Italy (NAP)",
    value: "NAP",
  },
  {
    label: "Naples, Capodichino Airport - Italy (NAP)",
    value: "NAP",
  },
  {
    label: "Olbia - Italy (OLB)",
    value: "OLB",
  },
  {
    label: "Olbia, Costa Smeralda - Italy (OLB)",
    value: "OLB",
  },
  {
    label: "Palermo - Italy (PMO)",
    value: "PMO",
  },
  {
    label: "Palermo, Punta Raisi - Italy (PMO)",
    value: "PMO",
  },
  {
    label: "Perugia - Italy (PEG)",
    value: "PEG",
  },
  {
    label: "Perugia, St. Francis Of Assisi - Italy (PEG)",
    value: "PEG",
  },
  {
    label: "Pescara - Italy (PSR)",
    value: "PSR",
  },
  {
    label: "Pescara, Abruzzo - Italy (PSR)",
    value: "PSR",
  },
  {
    label: "Pisa - Italy (PSA)",
    value: "PSA",
  },
  {
    label: "Pisa, Galileo Galilei - Italy (PSA)",
    value: "PSA",
  },
  {
    label: "Rome - Italy (ROM)",
    value: "ROM",
  },
  {
    label: "Rome, Fiumicino - Italy (FCO)",
    value: "FCO",
  },
  {
    label: "Rome, Ciampino - Italy (CIA)",
    value: "CIA",
  },
  {
    label: "Trapani - Italy (TPS)",
    value: "TPS",
  },
  {
    label: "Trapani, Birgi - Italy (TPS)",
    value: "TPS",
  },
  {
    label: "Venice - Italy (VCE)",
    value: "VCE",
  },
  {
    label: "Venice, Marco Polo - Italy (VCE)",
    value: "VCE",
  },
  {
    label: "Venice, Treviso Sant Angelo - Italy (TSF)",
    value: "TSF",
  },
  {
    label: "Turin - Italy (TRN)",
    value: "TRN",
  },
  {
    label: "Turin, Caselle - Italy (TRN)",
    value: "TRN",
  },
  {
    label: "Verona - Italy (VRN)",
    value: "VRN",
  },
  {
    label: "Verona, Villafranca - Italy (VRN)",
    value: "VRN",
  },
  {
    label: "Verona, Brescia Montechiari - Italy (VBS)",
    value: "VBS",
  },
  {
    label: "Albenga, Albenga - Italy (ALL)",
    value: "ALL",
  },
  {
    label: "Aosta, Corrado Gex Airport - Italy (AOT)",
    value: "AOT",
  },
  {
    label: "Aviano, Air Base - Italy (AVB)",
    value: "AVB",
  },
  {
    label: "Belluno, Belluno - Italy (BLX)",
    value: "BLX",
  },
  {
    label: "Capri, Harbour - Italy (PRJ)",
    value: "PRJ",
  },
  {
    label: "Chiusa Klausen, Chiusa Klausen Bus - Italy (ZAK)",
    value: "ZAK",
  },
  {
    label: "Crotone, Crotone - Italy (CRV)",
    value: "CRV",
  },
  {
    label: "Cuneo, Levaldigi - Italy (CUF)",
    value: "CUF",
  },
  {
    label: "Decimomannu, Air Base - Italy (DCI)",
    value: "DCI",
  },
  {
    label: "Elba Island, Marina Di Campo - Italy (EBA)",
    value: "EBA",
  },
  {
    label: "Foggia, Gino Lisa - Italy (FOG)",
    value: "FOG",
  },
  {
    label: "Forli, Luigi Ridolfi - Italy (FRL)",
    value: "FRL",
  },
  {
    label: "Grosseto, Corrado Baccarini - Italy (GRS)",
    value: "GRS",
  },
  {
    label: "Ischia, Harbour - Italy (ISH)",
    value: "ISH",
  },
  {
    label: "Lampedusa, Lampedusa - Italy (LMP)",
    value: "LMP",
  },
  {
    label: "L'aquila, L'aquila - Italy (QAQ)",
    value: "QAQ",
  },
  {
    label: "Lecce, Galatina - Italy (LCC)",
    value: "LCC",
  },
  {
    label: "Lucca, Tassignano - Italy (LCV)",
    value: "LCV",
  },
  {
    label: "Merano Italy, Merano Italy - Italy (VKB)",
    value: "VKB",
  },
  {
    label: "Oristano, Fenosu - Italy (FNU)",
    value: "FNU",
  },
  {
    label: "Pantelleria, Pantelleria - Italy (PNL)",
    value: "PNL",
  },
  {
    label: "Ravenna, Ravenna - Italy (RAN)",
    value: "RAN",
  },
  {
    label: "Reggio Di Calabria, Reggio Di Calabria - Italy (REG)",
    value: "REG",
  },
  {
    label: "Reggio Nell Emilia, Mediopadana Rail Stn - Italy (XRL)",
    value: "XRL",
  },
  {
    label: "Rimini, Miramare - Italy (RMI)",
    value: "RMI",
  },
  {
    label: "Rome, Fiumicino Harbour - Italy (ZRR)",
    value: "ZRR",
  },
  {
    label: "Salerno, Pontecagnano - Italy (QSR)",
    value: "QSR",
  },
  {
    label: "San Domino  Tremiti Isla, San Domino Island - Italy (TQR)",
    value: "TQR",
  },
  {
    label: "Siena, Ampugnano - Italy (SAY)",
    value: "SAY",
  },
  {
    label: "Sigonella, Nas - Italy (NSY)",
    value: "NSY",
  },
  {
    label: "Sorrento, Harbour - Italy (RRO)",
    value: "RRO",
  },
  {
    label: "Taranto, Grottaglie - Italy (TAR)",
    value: "TAR",
  },
  {
    label: "Tortoli, Arbatax - Italy (TTB)",
    value: "TTB",
  },
  {
    label: "Trieste, Ronchi Dei Legionari - Italy (TRS)",
    value: "TRS",
  },
  {
    label: "Udine, Campoformido - Italy (UDN)",
    value: "UDN",
  },
  {
    label: "Vicenza, Vicenza - Italy (VIC)",
    value: "VIC",
  },
  {
    label: "Vieste, Vieste Heliport - Italy (VIF)",
    value: "VIF",
  },
  {
    label: "Kingston, Norman Manley Intl - Jamaica (KIN)",
    value: "KIN",
  },
  {
    label: "Kingston, Tinson Pen - Jamaica (KTP)",
    value: "KTP",
  },
  {
    label: "Mandeville, Marlboro - Jamaica (MVJ)",
    value: "MVJ",
  },
  {
    label: "Montego Bay, Sangster Intl - Jamaica (MBJ)",
    value: "MBJ",
  },
  {
    label: "Negril, Negril - Jamaica (NEG)",
    value: "NEG",
  },
  {
    label: "Ocho Rios, Ian Fleming Intl - Jamaica (OCJ)",
    value: "OCJ",
  },
  {
    label: "Port Antonio, Ken Jones - Jamaica (POT)",
    value: "POT",
  },
  {
    label: "Aguni, Aguni - Japan (AGJ)",
    value: "AGJ",
  },
  {
    label: "Akita, Akita - Japan (AXT)",
    value: "AXT",
  },
  {
    label: "Amakusa, Amakusa - Japan (AXJ)",
    value: "AXJ",
  },
  {
    label: "Amami, Amami - Japan (ASJ)",
    value: "ASJ",
  },
  {
    label: "Aomori, Aomori - Japan (AOJ)",
    value: "AOJ",
  },
  {
    label: "Asahikawa, Asahikawa - Japan (AKJ)",
    value: "AKJ",
  },
  {
    label: "Atsugi, Naf - Japan (NJA)",
    value: "NJA",
  },
  {
    label: "Fukue, Goto-Fukue - Japan (FUJ)",
    value: "FUJ",
  },
  {
    label: "Fukui, Fukui - Japan (FKJ)",
    value: "FKJ",
  },
  {
    label: "Fukuoka, Fukuoka - Japan (FUK)",
    value: "FUK",
  },
  {
    label: "Fukushima, Fukushima - Japan (FKS)",
    value: "FKS",
  },
  {
    label: "Fukuyama, Fukuyama - Japan (QFY)",
    value: "QFY",
  },
  {
    label: "Hachijojima, Hachijojima - Japan (HAC)",
    value: "HAC",
  },
  {
    label: "Hachinohe, Air Base - Japan (HHE)",
    value: "HHE",
  },
  {
    label: "Hakodate, Hakodate - Japan (HKD)",
    value: "HKD",
  },
  {
    label: "Hateruma, Hateruma - Japan (HTR)",
    value: "HTR",
  },
  {
    label: "Hiroshima, Hiroshima Airport - Japan (HIJ)",
    value: "HIJ",
  },
  {
    label: "Hiroshima, Hiroshimanishi - Japan (HIW)",
    value: "HIW",
  },
  {
    label: "Ibaraki, Ibaraki - Japan (IBR)",
    value: "IBR",
  },
  {
    label: "Iejima, Iejima - Japan (IEJ)",
    value: "IEJ",
  },
  {
    label: "Iki, Iki - Japan (IKI)",
    value: "IKI",
  },
  {
    label: "Ishigaki, New Ishigaki - Japan (ISG)",
    value: "ISG",
  },
  {
    label: "Iwakuni, Iwakuni Airport - Japan (IWK)",
    value: "IWK",
  },
  {
    label: "Iwoto  Iwa Jima), Iwoto  Iwa Jima) - Japan (IWO)",
    value: "IWO",
  },
  {
    label: "Izumo, Izumo - Japan (IZO)",
    value: "IZO",
  },
  {
    label: "Kagoshima, Kagoshima - Japan (KOJ)",
    value: "KOJ",
  },
  {
    label: "Kerama, Kerama - Japan (KJP)",
    value: "KJP",
  },
  {
    label: "Kikai, Kikai - Japan (KKX)",
    value: "KKX",
  },
  {
    label: "Kitadaito, Kitadaito - Japan (KTD)",
    value: "KTD",
  },
  {
    label: "Kitakyushu, Kitakyushu - Japan (KKJ)",
    value: "KKJ",
  },
  {
    label: "Kochi, Kochi - Japan (KCZ)",
    value: "KCZ",
  },
  {
    label: "Komatsu, Komatsu - Japan (KMQ)",
    value: "KMQ",
  },
  {
    label: "Kumamoto, Kumamoto - Japan (KMJ)",
    value: "KMJ",
  },
  {
    label: "Kumejima, Kumejima - Japan (UEO)",
    value: "UEO",
  },
  {
    label: "Kushiro, Kushiro - Japan (KUH)",
    value: "KUH",
  },
  {
    label: "Kyoto, Downtown Heliport - Japan (UKY)",
    value: "UKY",
  },
  {
    label: "Masuda, Iwami - Japan (IWJ)",
    value: "IWJ",
  },
  {
    label: "Matsumoto, Matsumoto - Japan (MMJ)",
    value: "MMJ",
  },
  {
    label: "Matsuyama, Matsuyama - Japan (MYJ)",
    value: "MYJ",
  },
  {
    label: "Memanbetsu, Memanbetsu - Japan (MMB)",
    value: "MMB",
  },
  {
    label: "Minamidaito, Minamidaito - Japan (MMD)",
    value: "MMD",
  },
  {
    label: "Minamitorishima, Minamitorishima - Japan (MUS)",
    value: "MUS",
  },
  {
    label: "Misawa, Misawa - Japan (MSJ)",
    value: "MSJ",
  },
  {
    label: "Miyakejima, Miyakejima - Japan (MYE)",
    value: "MYE",
  },
  {
    label: "Miyako, Miyako - Japan (MMY)",
    value: "MMY",
  },
  {
    label: "Miyazaki, Miyazaki - Japan (KMI)",
    value: "KMI",
  },
  {
    label: "Monbetsu, Monbetsu - Japan (MBE)",
    value: "MBE",
  },
  {
    label: "Morioka, Hanamaki - Japan (HNA)",
    value: "HNA",
  },
  {
    label: "Nagasaki, Nagasaki Airport - Japan (NGS)",
    value: "NGS",
  },
  {
    label: "Nagasaki, Omura - Japan (OMJ)",
    value: "OMJ",
  },
  {
    label: "Nagoya, Chubu Centrair Intl - Japan (NGO)",
    value: "NGO",
  },
  {
    label: "Nagoya, Nagoya Komaki) - Japan (NKM)",
    value: "NKM",
  },
  {
    label: "Nakashibetsu, Nakashibetsu - Japan (SHB)",
    value: "SHB",
  },
  {
    label: "Niigata, Niigata - Japan (KIJ)",
    value: "KIJ",
  },
  {
    label: "Obihiro, Obihiro - Japan (OBO)",
    value: "OBO",
  },
  {
    label: "Odate/Noshiro, Odate - Japan (ONJ)",
    value: "ONJ",
  },
  {
    label: "Oita, Oita - Japan (OIT)",
    value: "OIT",
  },
  {
    label: "Okayama, Okayama - Japan (OKJ)",
    value: "OKJ",
  },
  {
    label: "Oki, Oki - Japan (OKI)",
    value: "OKI",
  },
  {
    label: "Okierabu, Okierabu - Japan (OKE)",
    value: "OKE",
  },
  {
    label: "Okinawa, Kadena Ab - Japan (DNA)",
    value: "DNA",
  },
  {
    label: "Okinawa, Naha - Japan (OKA)",
    value: "OKA",
  },
  {
    label: "Okushiri, Okushiri - Japan (OIR)",
    value: "OIR",
  },
  {
    label: "Osaka, Kansai International - Japan (KIX)",
    value: "KIX",
  },
  {
    label: "Osaka, Kobe - Japan (UKB)",
    value: "UKB",
  },
  {
    label: "Osaka, Osaka Intl  Itami) - Japan (ITM)",
    value: "ITM",
  },
  {
    label: "Oshima, Oshima - Japan (OIM)",
    value: "OIM",
  },
  {
    label: "Rail Generic, Rail Generic - Japan (XZJ)",
    value: "XZJ",
  },
  {
    label: "Rebun, Rebun - Japan (RBJ)",
    value: "RBJ",
  },
  {
    label: "Rishiri, Rishiri - Japan (RIS)",
    value: "RIS",
  },
  {
    label: "Sado, Sado - Japan (SDS)",
    value: "SDS",
  },
  {
    label: "Saga, Saga Airport - Japan (HSG)",
    value: "HSG",
  },
  {
    label: "Sapporo, New Chitose - Japan (CTS)",
    value: "CTS",
  },
  {
    label: "Sapporo, Okadama - Japan (OKD)",
    value: "OKD",
  },
  {
    label: "Sendai, Sendai - Japan (SDJ)",
    value: "SDJ",
  },
  {
    label: "Shimojishima, Shimojishima - Japan (SHI)",
    value: "SHI",
  },
  {
    label: "Shirahama, Nanki-Shirahama - Japan (SHM)",
    value: "SHM",
  },
  {
    label: "Shizuoka, Mount Fuji - Japan (FSZ)",
    value: "FSZ",
  },
  {
    label: "Shonai, Shonai - Japan (SYO)",
    value: "SYO",
  },
  {
    label: "Takamatsu, Takamatsu - Japan (TAK)",
    value: "TAK",
  },
  {
    label: "Tanegashima, Tanegashima - Japan (TNE)",
    value: "TNE",
  },
  {
    label: "Tarama, Tarama - Japan (TRA)",
    value: "TRA",
  },
  {
    label: "Tokunoshima, Tokunoshima - Japan (TKN)",
    value: "TKN",
  },
  {
    label: "Tokushima, Tokushima - Japan (TKS)",
    value: "TKS",
  },
  {
    label: "Tokyo, Narita Intl - Japan (NRT)",
    value: "NRT",
  },
  {
    label: "Tokyo, Tokyo Intl Haneda - Japan (HND)",
    value: "HND",
  },
  {
    label: "Tokyo, Yokota Ab - Japan (OKO)",
    value: "OKO",
  },
  {
    label: "Tottori, Tottori - Japan (TTJ)",
    value: "TTJ",
  },
  {
    label: "Toyama, Toyama - Japan (TOY)",
    value: "TOY",
  },
  {
    label: "Toyooka, Tajima - Japan (TJH)",
    value: "TJH",
  },
  {
    label: "Tsushima, Tsushima - Japan (TSJ)",
    value: "TSJ",
  },
  {
    label: "Ube, Yamaguchi-Ube - Japan (UBJ)",
    value: "UBJ",
  },
  {
    label: "Wajima, Noto Airport - Japan (NTQ)",
    value: "NTQ",
  },
  {
    label: "Wakkanai, Wakkanai - Japan (WKJ)",
    value: "WKJ",
  },
  {
    label: "Yakushima, Yakushima - Japan (KUM)",
    value: "KUM",
  },
  {
    label: "Yamagata, Yamagata - Japan (GAJ)",
    value: "GAJ",
  },
  {
    label: "Yonago, Miho - Japan (YGJ)",
    value: "YGJ",
  },
  {
    label: "Yonaguni, Yonaguni - Japan (OGN)",
    value: "OGN",
  },
  {
    label: "Yoron, Yoron - Japan (RNJ)",
    value: "RNJ",
  },
  {
    label: "Amman, Marka Intl - Jordan (ADJ)",
    value: "ADJ",
  },
  {
    label: "Amman, Queen Alia - Jordan (AMM)",
    value: "AMM",
  },
  {
    label: "Aqaba, King Hussein Intl - Jordan (AQJ)",
    value: "AQJ",
  },
  {
    label: "Maan, Maan - Jordan (MPQ)",
    value: "MPQ",
  },
  {
    label: "Mafraq, King Hussein - Jordan (OMF)",
    value: "OMF",
  },
  {
    label: "Almaty - Kazakhstan (ALA)",
    value: "ALA",
  },
  {
    label: "Almaty, International - Kazakhstan (ALA)",
    value: "ALA",
  },
  {
    label: "Almaty, Borolday - Kazakhstan (BXJ)",
    value: "BXJ",
  },
  {
    label: "Astana - Kazakhstan (TSE)",
    value: "TSE",
  },
  {
    label: "Astana, International - Kazakhstan (TSE)",
    value: "TSE",
  },
  {
    label: "Aktau, Aktau - Kazakhstan (SCO)",
    value: "SCO",
  },
  {
    label: "Aktobe, Aktobe - Kazakhstan (AKX)",
    value: "AKX",
  },
  {
    label: "Arkalyk, Arkalyk - Kazakhstan (AYK)",
    value: "AYK",
  },
  {
    label: "Atbasar, Atbasar - Kazakhstan (ATX)",
    value: "ATX",
  },
  {
    label: "Atyrau, Atyrau - Kazakhstan (GUW)",
    value: "GUW",
  },
  {
    label: "Balhash, Balhash - Kazakhstan (BXH)",
    value: "BXH",
  },
  {
    label: "Ekibastuz, Ekibastuz - Kazakhstan (EKB)",
    value: "EKB",
  },
  {
    label: "Karaganda, Sary-Arka - Kazakhstan (KGF)",
    value: "KGF",
  },
  {
    label: "Kokshetau, Kokshetau - Kazakhstan (KOV)",
    value: "KOV",
  },
  {
    label: "Kostanay, Kostanay - Kazakhstan (KSN)",
    value: "KSN",
  },
  {
    label: "Kyzylorda, Kyzylorda - Kazakhstan (KZO)",
    value: "KZO",
  },
  {
    label: "Pavlodar, Pavlodar - Kazakhstan (PWQ)",
    value: "PWQ",
  },
  {
    label: "Petropavlovsk, Petropavlovsk - Kazakhstan (PPK)",
    value: "PPK",
  },
  {
    label: "Semey, Semey - Kazakhstan (PLX)",
    value: "PLX",
  },
  {
    label: "Shymkent, International - Kazakhstan (CIT)",
    value: "CIT",
  },
  {
    label: "Taldykorgan, Taldykorgan - Kazakhstan (TDK)",
    value: "TDK",
  },
  {
    label: "Taraz, Taraz - Kazakhstan (DMB)",
    value: "DMB",
  },
  {
    label: "Uralsk, Ak Zhol - Kazakhstan (URA)",
    value: "URA",
  },
  {
    label: "Urdzhar, Urdzhar - Kazakhstan (UZR)",
    value: "UZR",
  },
  {
    label: "Ust Kamenogorsk, Ust Kamenogorsk - Kazakhstan (UKK)",
    value: "UKK",
  },
  {
    label: "Zaysan, Zaysan - Kazakhstan (SZI)",
    value: "SZI",
  },
  {
    label: "Zhayrem, Sary Su - Kazakhstan (HRC)",
    value: "HRC",
  },
  {
    label: "Zhezkazgan, Zhezkazgan - Kazakhstan (DZN)",
    value: "DZN",
  },
  {
    label: "Amboseli, Amboseli - Kenya (ASV)",
    value: "ASV",
  },
  {
    label: "Bamburi, Bamburi - Kenya (BMQ)",
    value: "BMQ",
  },
  {
    label: "Eldoret, International - Kenya (EDL)",
    value: "EDL",
  },
  {
    label: "Eliye Springs, Eliye Springs - Kenya (EYS)",
    value: "EYS",
  },
  {
    label: "Garissa, Garissa - Kenya (GAS)",
    value: "GAS",
  },
  {
    label: "Hola, Hola - Kenya (HOA)",
    value: "HOA",
  },
  {
    label: "Kakamega, Kakamega - Kenya (GGM)",
    value: "GGM",
  },
  {
    label: "Kalokol, Fergusons Gulf - Kenya (KLK)",
    value: "KLK",
  },
  {
    label: "Kericho, Kericho - Kenya (KEY)",
    value: "KEY",
  },
  {
    label: "Kilaguni, Kilaguni - Kenya (ILU)",
    value: "ILU",
  },
  {
    label: "Kimwarer, Kerio Valley - Kenya (KRV)",
    value: "KRV",
  },
  {
    label: "Kisumu, Kisumu - Kenya (KIS)",
    value: "KIS",
  },
  {
    label: "Kitale, Kitale - Kenya (KTL)",
    value: "KTL",
  },
  {
    label: "Kiunga, Kiunga - Kenya (KIU)",
    value: "KIU",
  },
  {
    label: "Kiwayu, Kiwayu - Kenya (KWY)",
    value: "KWY",
  },
  {
    label: "Lake Baringo, Lake Baringo - Kenya (LBN)",
    value: "LBN",
  },
  {
    label: "Lake Turkana, Lake Turkana - Kenya (LKU)",
    value: "LKU",
  },
  {
    label: "Lamu, Manda - Kenya (LAU)",
    value: "LAU",
  },
  {
    label: "Liboi, Liboi - Kenya (LBK)",
    value: "LBK",
  },
  {
    label: "Lodwar, Lodwar - Kenya (LOK)",
    value: "LOK",
  },
  {
    label: "Lokichogio, Lokichogio - Kenya (LKG)",
    value: "LKG",
  },
  {
    label: "Loyengalani, Loyengalani - Kenya (LOY)",
    value: "LOY",
  },
  {
    label: "Maasai Mara, Kichwa Tembo - Kenya (KTJ)",
    value: "KTJ",
  },
  {
    label: "Malindi, Malindi - Kenya (MYD)",
    value: "MYD",
  },
  {
    label: "Mandera, Mandera - Kenya (NDE)",
    value: "NDE",
  },
  {
    label: "Marsabit, Marsabit - Kenya (RBT)",
    value: "RBT",
  },
  {
    label: "Masai Mara, Mara Serena - Kenya (MRE)",
    value: "MRE",
  },
  {
    label: "Meru Kinna, Mulika Lodge - Kenya (JJM)",
    value: "JJM",
  },
  {
    label: "Mombasa, Moi Intl - Kenya (MBA)",
    value: "MBA",
  },
  {
    label: "Moyale, Moyale - Kenya (OYL)",
    value: "OYL",
  },
  {
    label: "Nairobi, Jomo Kenyatta Intl - Kenya (NBO)",
    value: "NBO",
  },
  {
    label: "Nairobi, Wilson - Kenya (WIL)",
    value: "WIL",
  },
  {
    label: "Nakuru, Nakuru - Kenya (NUU)",
    value: "NUU",
  },
  {
    label: "Nanyuki, Nanyuki - Kenya (NYK)",
    value: "NYK",
  },
  {
    label: "Nyeri, Nyeri - Kenya (NYE)",
    value: "NYE",
  },
  {
    label: "Nzoia, Nzoia - Kenya (NZO)",
    value: "NZO",
  },
  {
    label: "Olkiombo, Olkiombo - Kenya (OLX)",
    value: "OLX",
  },
  {
    label: "Samburu, Buffalo Spring - Kenya (UAS)",
    value: "UAS",
  },
  {
    label: "Ukunda, Diani - Kenya (UKA)",
    value: "UKA",
  },
  {
    label: "Vipingo, Vipingo - Kenya (VPG)",
    value: "VPG",
  },
  {
    label: "Wajir, Wajir - Kenya (WJR)",
    value: "WJR",
  },
  {
    label: "Abaiang, Abaiang - Kiribati (ABF)",
    value: "ABF",
  },
  {
    label: "Abemama, Abemama - Kiribati (AEA)",
    value: "AEA",
  },
  {
    label: "Aranuka, Aranuka - Kiribati (AAK)",
    value: "AAK",
  },
  {
    label: "Arorae, Arorae - Kiribati (AIS)",
    value: "AIS",
  },
  {
    label: "Beru, Beru - Kiribati (BEZ)",
    value: "BEZ",
  },
  {
    label: "Butaritari, Butaritari - Kiribati (BBG)",
    value: "BBG",
  },
  {
    label: "Canton Island, Canton Island - Kiribati (CIS)",
    value: "CIS",
  },
  {
    label: "Kiritimati, Cassidy Intl - Kiribati (CXI)",
    value: "CXI",
  },
  {
    label: "Kuria, Kuria - Kiribati (KUC)",
    value: "KUC",
  },
  {
    label: "Maiana, Maiana - Kiribati (MNK)",
    value: "MNK",
  },
  {
    label: "Makin, Makin - Kiribati (MTK)",
    value: "MTK",
  },
  {
    label: "Marakei, Marakei - Kiribati (MZK)",
    value: "MZK",
  },
  {
    label: "Nikunau, Nikunau - Kiribati (NIG)",
    value: "NIG",
  },
  {
    label: "Nonouti, Nonouti - Kiribati (NON)",
    value: "NON",
  },
  {
    label: "Onotoa, Onotoa - Kiribati (OOT)",
    value: "OOT",
  },
  {
    label: "Tabiteuea North, Tabiteuea North - Kiribati (TBF)",
    value: "TBF",
  },
  {
    label: "Tabiteuea South, Tabiteuea South - Kiribati (TSU)",
    value: "TSU",
  },
  {
    label: "Tabuaeran, Tabuaeran - Kiribati (TNV)",
    value: "TNV",
  },
  {
    label: "Tamana, Tamana - Kiribati (TMN)",
    value: "TMN",
  },
  {
    label: "Tarawa, Bonriki Intl - Kiribati (TRW)",
    value: "TRW",
  },
  {
    label: "Teraina, Teraina - Kiribati (TNQ)",
    value: "TNQ",
  },
  {
    label: "Chongjin, Orang - Korea Dem Peoples Rep Of (RGO)",
    value: "RGO",
  },
  {
    label: "Pyongyang, Sunan Intl - Korea Dem Peoples Rep Of (FNJ)",
    value: "FNJ",
  },
  {
    label: "Samjiyon, Samjiyon - Korea Dem Peoples Rep Of (YJS)",
    value: "YJS",
  },
  {
    label: "Sondok, Sondok - Korea Dem Peoples Rep Of (DSO)",
    value: "DSO",
  },
  {
    label: "Uiju , Uiju - Korea Dem Peoples Rep Of (UJU)",
    value: "UJU",
  },
  {
    label: "Wonson, Wonson - Korea Dem Peoples Rep Of (WOS)",
    value: "WOS",
  },
  {
    label: "Busan, Gimhae Intl - Korea Republic Of (PUS)",
    value: "PUS",
  },
  {
    label: "Cheongju, International - Korea Republic Of (CJJ)",
    value: "CJJ",
  },
  {
    label: "Chuja, Heliport - Korea Republic Of (JCJ)",
    value: "JCJ",
  },
  {
    label: "Chungju, Jungwon Ab - Korea Republic Of (JWO)",
    value: "JWO",
  },
  {
    label: "Daegu, International - Korea Republic Of (TAE)",
    value: "TAE",
  },
  {
    label: "Gangneung, Air Base - Korea Republic Of (KAG)",
    value: "KAG",
  },
  {
    label: "Geoje, Heliport - Korea Republic Of (JGE)",
    value: "JGE",
  },
  {
    label: "Gunsan, Gunsan - Korea Republic Of (KUV)",
    value: "KUV",
  },
  {
    label: "Gwangju, Gwangju - Korea Republic Of (KWJ)",
    value: "KWJ",
  },
  {
    label: "Incheon, Heliport - Korea Republic Of (JCN)",
    value: "JCN",
  },
  {
    label: "Jeju, Jeju International - Korea Republic Of (CJU)",
    value: "CJU",
  },
  {
    label: "Jeongseok, Jeongseok - Korea Republic Of (JDG)",
    value: "JDG",
  },
  {
    label: "Jeonju, Jeonju - Korea Republic Of (CHN)",
    value: "CHN",
  },
  {
    label: "Jinhae, Jinhae - Korea Republic Of (CHF)",
    value: "CHF",
  },
  {
    label: "Jinju, Sacheon - Korea Republic Of (HIN)",
    value: "HIN",
  },
  {
    label: "Muan, International - Korea Republic Of (MWX)",
    value: "MWX",
  },
  {
    label: "Osan, Air Base - Korea Republic Of (OSN)",
    value: "OSN",
  },
  {
    label: "Pohang, Pohang - Korea Republic Of (KPO)",
    value: "KPO",
  },
  {
    label: "Seogwipo, Heliport - Korea Republic Of (JSP)",
    value: "JSP",
  },
  {
    label: "Seosan, Seosan - Korea Republic Of (HMY)",
    value: "HMY",
  },
  {
    label: "Seoul, Air Base - Korea Republic Of (SSN)",
    value: "SSN",
  },
  {
    label: "Seoul, Gimpo International - Korea Republic Of (GMP)",
    value: "GMP",
  },
  {
    label: "Seoul, Incheon International - Korea Republic Of (ICN)",
    value: "ICN",
  },
  {
    label: "Suwon, Suwon Air Base - Korea Republic Of (SWU)",
    value: "SWU",
  },
  {
    label: "Ulsan, Ulsan - Korea Republic Of (USN)",
    value: "USN",
  },
  {
    label: "Won Ju, Won Ju - Korea Republic Of (WBJ)",
    value: "WBJ",
  },
  {
    label: "Wonju, Wonju - Korea Republic Of (WJU)",
    value: "WJU",
  },
  {
    label: "Yangyang, International - Korea Republic Of (YNY)",
    value: "YNY",
  },
  {
    label: "Yecheon, Yecheon - Korea Republic Of (YEC)",
    value: "YEC",
  },
  {
    label: "Yeosu/Suncheon, Yeosu - Korea Republic Of (RSU)",
    value: "RSU",
  },
  {
    label: "Kuwait - Kuwait (KWI)",
    value: "KWI",
  },
  {
    label: "Kuwait, Kuwait Intl - Kuwait (KWI)",
    value: "KWI",
  },
  {
    label: "Bishkek, Manas Intl - Kyrgyzstan (FRU)",
    value: "FRU",
  },
  {
    label: "Osh, Osh - Kyrgyzstan (OSS)",
    value: "OSS",
  },
  {
    label: "Attapeu, Attapeu - Lao People's Dem Republic (AOU)",
    value: "AOU",
  },
  {
    label: "Houeisay, Houeisay - Lao People's Dem Republic (HOE)",
    value: "HOE",
  },
  {
    label: "Khong, Khong - Lao People's Dem Republic (KOG)",
    value: "KOG",
  },
  {
    label: "Luang Namtha, Luang Namtha - Lao People's Dem Republic (LXG)",
    value: "LXG",
  },
  {
    label: "Luang Prabang, International - Lao People's Dem Republic (LPQ)",
    value: "LPQ",
  },
  {
    label: "Muang Xay, Oudomxai - Lao People's Dem Republic (ODY)",
    value: "ODY",
  },
  {
    label: "Pakse, International - Lao People's Dem Republic (PKZ)",
    value: "PKZ",
  },
  {
    label: "Phongsaly, Boun Neua - Lao People's Dem Republic (PCQ)",
    value: "PCQ",
  },
  {
    label: "Phonsavan, Xieng Khouang - Lao People's Dem Republic (XKH)",
    value: "XKH",
  },
  {
    label: "Salavan, Salavan - Lao People's Dem Republic (VNA)",
    value: "VNA",
  },
  {
    label: "Sam Neua, Sam Neua - Lao People's Dem Republic (NEU)",
    value: "NEU",
  },
  {
    label: "Savannakhet, Savannakhet - Lao People's Dem Republic (ZVK)",
    value: "ZVK",
  },
  {
    label: "Sayaboury, Sayaboury - Lao People's Dem Republic (ZBY)",
    value: "ZBY",
  },
  {
    label: "Thakhek, Thakhek - Lao People's Dem Republic (THK)",
    value: "THK",
  },
  {
    label: "Vientiane, Wattay Intl - Lao People's Dem Republic (VTE)",
    value: "VTE",
  },
  {
    label: "Xienglom, Xienglom - Lao People's Dem Republic (XIE)",
    value: "XIE",
  },
  {
    label: "Riga - Latvia (RIX)",
    value: "RIX",
  },
  {
    label: "Riga, Riga Intl - Latvia (RIX)",
    value: "RIX",
  },
  {
    label: "Riga, Skulte - Latvia (RSC)",
    value: "RSC",
  },
  {
    label: "Daugavpils - Latvia (DGP)",
    value: "DGP",
  },
  {
    label: "Daugavpils, Daugavpils Intl - Latvia (DGP)",
    value: "DGP",
  },
  {
    label: "Liepaja - Latvia (LPX)",
    value: "LPX",
  },
  {
    label: "Liepaja, Liepaja Intl - Latvia (LPX)",
    value: "LPX",
  },
  {
    label: "Ventspils - Latvia (VNT)",
    value: "VNT",
  },
  {
    label: "Ventspils, Ventspils - Latvia (VNT)",
    value: "VNT",
  },
  {
    label: "Beirut, Rafic Hariri Intl - Lebanon (BEY)",
    value: "BEY",
  },
  {
    label: "Tripoli, Rene Mouawad Ab - Lebanon (KYE)",
    value: "KYE",
  },
  {
    label: "Lebakeng, Lebakeng - Lesotho (LEF)",
    value: "LEF",
  },
  {
    label: "Leribe, Leribe - Lesotho (LRB)",
    value: "LRB",
  },
  {
    label: "Lesobeng, Lesobeng - Lesotho (LES)",
    value: "LES",
  },
  {
    label: "Mafeteng, Mafeteng - Lesotho (MFC)",
    value: "MFC",
  },
  {
    label: "Maseru, Moshoeshoe I Intl - Lesotho (MSU)",
    value: "MSU",
  },
  {
    label: "Matsaile, Matsaile - Lesotho (MSG)",
    value: "MSG",
  },
  {
    label: "Mokhotlong, Mokhotlong - Lesotho (MKH)",
    value: "MKH",
  },
  {
    label: "Nkau, Nkau - Lesotho (NKU)",
    value: "NKU",
  },
  {
    label: "Pelaneng, Pelaneng - Lesotho (PEL)",
    value: "PEL",
  },
  {
    label: "Qacha's Nek, Qacha's Nek - Lesotho (UNE)",
    value: "UNE",
  },
  {
    label: "Quthing, Quthing - Lesotho (UTG)",
    value: "UTG",
  },
  {
    label: "Sehonghong, Sehonghong - Lesotho (SHK)",
    value: "SHK",
  },
  {
    label: "Sekake, Sekake - Lesotho (SKQ)",
    value: "SKQ",
  },
  {
    label: "Semongkong, Semongkong - Lesotho (SOK)",
    value: "SOK",
  },
  {
    label: "Seshote, Seshote - Lesotho (SHZ)",
    value: "SHZ",
  },
  {
    label: "Thaba Tseka, Thaba Tseka - Lesotho (THB)",
    value: "THB",
  },
  {
    label: "Tlokoeng, Tlokoeng - Lesotho (TKO)",
    value: "TKO",
  },
  {
    label: "Belle Yella, Belle Yella - Liberia (BYL)",
    value: "BYL",
  },
  {
    label: "Buchanan, Buchanan - Liberia (UCN)",
    value: "UCN",
  },
  {
    label: "Foya, Foya - Liberia (FOY)",
    value: "FOY",
  },
  {
    label: "Grand Cess, Grand Cess - Liberia (GRC)",
    value: "GRC",
  },
  {
    label: "Harper, Cape Palmas - Liberia (CPA)",
    value: "CPA",
  },
  {
    label: "Monrovia, Roberts Intl - Liberia (ROB)",
    value: "ROB",
  },
  {
    label: "Monrovia, Spriggs Payne - Liberia (MLW)",
    value: "MLW",
  },
  {
    label: "Nimba, Nimba - Liberia (NIA)",
    value: "NIA",
  },
  {
    label: "River Cess, River Cess - Liberia (RVC)",
    value: "RVC",
  },
  {
    label: "Sasstown, Sasstown - Liberia (SAZ)",
    value: "SAZ",
  },
  {
    label: "Sinoe, R E Murray - Liberia (SNI)",
    value: "SNI",
  },
  {
    label: "Tapeta, Tapeta - Liberia (TPT)",
    value: "TPT",
  },
  {
    label: "Tchien, Tchien - Liberia (THC)",
    value: "THC",
  },
  {
    label: "Voinjama, Tenebu - Liberia (VOI)",
    value: "VOI",
  },
  {
    label: "Weasua, Weasua - Liberia (WES)",
    value: "WES",
  },
  {
    label: "Beida, Labraq - Libya (LAQ)",
    value: "LAQ",
  },
  {
    label: "Benghazi, Benina Intl - Libya (BEN)",
    value: "BEN",
  },
  {
    label: "Brach, Brach - Libya (BCQ)",
    value: "BCQ",
  },
  {
    label: "Derna, Martuba - Libya (DNF)",
    value: "DNF",
  },
  {
    label: "Ghadames, Ghadames - Libya (LTD)",
    value: "LTD",
  },
  {
    label: "Ghat, Ghat - Libya (GHT)",
    value: "GHT",
  },
  {
    label: "Hon, Hon - Libya (HUQ)",
    value: "HUQ",
  },
  {
    label: "Kufra, Kufra - Libya (AKF)",
    value: "AKF",
  },
  {
    label: "Marsa Brega, Marsa Brega - Libya (LMQ)",
    value: "LMQ",
  },
  {
    label: "Misurata, Misurata - Libya (MRA)",
    value: "MRA",
  },
  {
    label: "Mitiga, Mitiga - Libya (MJI)",
    value: "MJI",
  },
  {
    label: "Nafoora, Nafoora - Libya (NFR)",
    value: "NFR",
  },
  {
    label: "Sebha, Sebha - Libya (SEB)",
    value: "SEB",
  },
  {
    label: "Sirte, Gardabya - Libya (SRX)",
    value: "SRX",
  },
  {
    label: "Tobruk, Tobruk - Libya (TOB)",
    value: "TOB",
  },
  {
    label: "Tripoli, Tripoli Intl - Libya (TIP)",
    value: "TIP",
  },
  {
    label: "Ubari, Ubari - Libya (QUB)",
    value: "QUB",
  },
  {
    label: "Zuara, Zuara - Libya (WAX)",
    value: "WAX",
  },
  {
    label: "Kaunas - Lithuania (KUN)",
    value: "KUN",
  },
  {
    label: "Kaunas, Kaunas Intl - Lithuania (KUN)",
    value: "KUN",
  },
  {
    label: "Vilnius - Lithuania (VNO)",
    value: "VNO",
  },
  {
    label: "Vilnius, Vilnius Intl - Lithuania (VNO)",
    value: "VNO",
  },
  {
    label: "Klaipeda/Palanga - Lithuania (PLQ)",
    value: "PLQ",
  },
  {
    label: "Klaipeda/Palanga, Palanga International - Lithuania (PLQ)",
    value: "PLQ",
  },
  {
    label: "Panevezys - Lithuania (PNV)",
    value: "PNV",
  },
  {
    label: "Panevezys, Pajuostis - Lithuania (PNV)",
    value: "PNV",
  },
  {
    label: "Siauliai - Lithuania (SQQ)",
    value: "SQQ",
  },
  {
    label: "Siauliai, Siauliai Intl - Lithuania (SQQ)",
    value: "SQQ",
  },
  {
    label: "Luxembourg - Luxembourg (LUX)",
    value: "LUX",
  },
  {
    label: "Luxembourg, Luxembourg - Luxembourg (LUX)",
    value: "LUX",
  },
  {
    label: "Macau, Macau Ferry Terminal - Macao -Sar Of China- (XZM)",
    value: "XZM",
  },
  {
    label: "Macau, Taipa Ferry Terminal - Macao -Sar Of China- (YFT)",
    value: "YFT",
  },
  {
    label: "Skopje - Macedonia -Fyrom- (SKP)",
    value: "SKP",
  },
  {
    label: "Skopje, Alexander The Great - Macedonia -Fyrom- (SKP)",
    value: "SKP",
  },
  {
    label: "Ohrid, Saint Paul The Apostle - Macedonia -Fyrom- (OHD)",
    value: "OHD",
  },
  {
    label: "Ambanja, Ampampamena - Madagascar (IVA)",
    value: "IVA",
  },
  {
    label: "Ambatolahy, Ambatolahy - Madagascar (AHY)",
    value: "AHY",
  },
  {
    label: "Ambatomainty, Ambatomainty - Madagascar (AMY)",
    value: "AMY",
  },
  {
    label: "Ambatondrazaka, Ambatondrazaka - Madagascar (WAM)",
    value: "WAM",
  },
  {
    label: "Ambilobe, Ambilobe - Madagascar (AMB)",
    value: "AMB",
  },
  {
    label: "Ampanihy, Ampanihy - Madagascar (AMP)",
    value: "AMP",
  },
  {
    label: "Analalava, Analalava - Madagascar (HVA)",
    value: "HVA",
  },
  {
    label: "Andapa, Andapa - Madagascar (ZWA)",
    value: "ZWA",
  },
  {
    label: "Andavadoaka, Andavadoaka - Madagascar (DVD)",
    value: "DVD",
  },
  {
    label: "Andriamena, Andriamena - Madagascar (WAD)",
    value: "WAD",
  },
  {
    label: "Ankavandra, Ankavandra - Madagascar (JVA)",
    value: "JVA",
  },
  {
    label: "Ankazoabo, Ankazoabo - Madagascar (WAK)",
    value: "WAK",
  },
  {
    label: "Ankisatra, Tsimiroro - Madagascar (TZO)",
    value: "TZO",
  },
  {
    label: "Ankokoambo, Ankokoambo - Madagascar (NKO)",
    value: "NKO",
  },
  {
    label: "Ankorefo, Moramba Airport - Madagascar (WOR)",
    value: "WOR",
  },
  {
    label: "Antalaha, Antsirabato - Madagascar (ANM)",
    value: "ANM",
  },
  {
    label: "Antananarivo, Ivato Intl - Madagascar (TNR)",
    value: "TNR",
  },
  {
    label: "Antsalova, Antsalova - Madagascar (WAQ)",
    value: "WAQ",
  },
  {
    label: "Antsirabe, Antsirabe - Madagascar (ATJ)",
    value: "ATJ",
  },
  {
    label: "Antsiranana, Arrachart - Madagascar (DIE)",
    value: "DIE",
  },
  {
    label: "Antsohihy, Ambalabe - Madagascar (WAI)",
    value: "WAI",
  },
  {
    label: "Bealanana, Ankaizina - Madagascar (WBE)",
    value: "WBE",
  },
  {
    label: "Befandriana, Befandriana - Madagascar (WBD)",
    value: "WBD",
  },
  {
    label: "Bekily, Bekily - Madagascar (OVA)",
    value: "OVA",
  },
  {
    label: "Belo Tsiribihina, Belo Tsiribihina - Madagascar (BMD)",
    value: "BMD",
  },
  {
    label: "Beroroha, Antsoa - Madagascar (WBO)",
    value: "WBO",
  },
  {
    label: "Besakoa, Besakoa - Madagascar (BSV)",
    value: "BSV",
  },
  {
    label: "Besalampy, Besalampy - Madagascar (BPY)",
    value: "BPY",
  },
  {
    label: "Betioky, Betioky - Madagascar (BKU)",
    value: "BKU",
  },
  {
    label: "Doany, Doany - Madagascar (DOA)",
    value: "DOA",
  },
  {
    label: "Farafangana, Farafangana - Madagascar (RVA)",
    value: "RVA",
  },
  {
    label: "Fianarantsoa, Fianarantsoa - Madagascar (WFI)",
    value: "WFI",
  },
  {
    label: "Ihosy, Ihosy - Madagascar (IHO)",
    value: "IHO",
  },
  {
    label: "Ilaka Atsinanana, Ilaka Atsinanana - Madagascar (ILK)",
    value: "ILK",
  },
  {
    label: "Isalo, Relais De La Reine - Madagascar (RLR)",
    value: "RLR",
  },
  {
    label: "Madirovalo, Madirovalo - Madagascar (WMV)",
    value: "WMV",
  },
  {
    label: "Mahajanga, Philibert Tsiranana - Madagascar (MJN)",
    value: "MJN",
  },
  {
    label: "Mahanoro, Mahanoro - Madagascar (VVB)",
    value: "VVB",
  },
  {
    label: "Maintirano, Maintirano - Madagascar (MXT)",
    value: "MXT",
  },
  {
    label: "Malaimbandy, Malaimbandy - Madagascar (WML)",
    value: "WML",
  },
  {
    label: "Mampikony, Mampikony Airport - Madagascar (WMP)",
    value: "WMP",
  },
  {
    label: "Manakara, Manakara - Madagascar (WVK)",
    value: "WVK",
  },
  {
    label: "Mananara Avaratra, Mananara - Madagascar (WMR)",
    value: "WMR",
  },
  {
    label: "Mananjary, Mananjary - Madagascar (MNJ)",
    value: "MNJ",
  },
  {
    label: "Mandabe, Mandabe - Madagascar (WMD)",
    value: "WMD",
  },
  {
    label: "Mandritsara, Mandritsara - Madagascar (WMA)",
    value: "WMA",
  },
  {
    label: "Manja, Manja - Madagascar (MJA)",
    value: "MJA",
  },
  {
    label: "Maroantsetra, Maroantsetra - Madagascar (WMN)",
    value: "WMN",
  },
  {
    label: "Miandrivazo, Miandrivazo - Madagascar (ZVA)",
    value: "ZVA",
  },
  {
    label: "Morafenobe, Morafenobe - Madagascar (TVA)",
    value: "TVA",
  },
  {
    label: "Moramanga, Ambohibary - Madagascar (OHB)",
    value: "OHB",
  },
  {
    label: "Morombe, Morombe - Madagascar (MXM)",
    value: "MXM",
  },
  {
    label: "Morondava, Morondava - Madagascar (MOQ)",
    value: "MOQ",
  },
  {
    label: "Nosy Be, Fascene - Madagascar (NOS)",
    value: "NOS",
  },
  {
    label: "Port Berge Vaovao, Port Berge Vaovao - Madagascar (WPB)",
    value: "WPB",
  },
  {
    label: "Sainte Marie, Sainte Marie - Madagascar (SMS)",
    value: "SMS",
  },
  {
    label: "Sambava, Sambava - Madagascar (SVB)",
    value: "SVB",
  },
  {
    label: "Soalala, Soalala - Madagascar (DWB)",
    value: "DWB",
  },
  {
    label: "Tambohorano, Tambohorano - Madagascar (WTA)",
    value: "WTA",
  },
  {
    label: "Tanandava, Samangoky - Madagascar (TDV)",
    value: "TDV",
  },
  {
    label: "Toamasina, Toamasina - Madagascar (TMM)",
    value: "TMM",
  },
  {
    label: "Tolanaro, Marillac - Madagascar (FTU)",
    value: "FTU",
  },
  {
    label: "Toliara, Toliara - Madagascar (TLE)",
    value: "TLE",
  },
  {
    label: "Tsaratanana, Tsaratanana - Madagascar (TTS)",
    value: "TTS",
  },
  {
    label: "Tsiroanomandidy, Tsiroanomandidy - Madagascar (WTS)",
    value: "WTS",
  },
  {
    label: "Vangaindrano, Vangaindrano - Madagascar (VND)",
    value: "VND",
  },
  {
    label: "Vatomandry, Vatomandry - Madagascar (VAT)",
    value: "VAT",
  },
  {
    label: "Vohimarina, Vohimarina - Madagascar (VOH)",
    value: "VOH",
  },
  {
    label: "Blantyre, Chileka Intl - Malawi (BLZ)",
    value: "BLZ",
  },
  {
    label: "Blantyre, Mvuu Camp - Malawi (VUU)",
    value: "VUU",
  },
  {
    label: "Chelinda, Chelinda - Malawi (CEH)",
    value: "CEH",
  },
  {
    label: "Club Makokola, Club Makokola - Malawi (CMK)",
    value: "CMK",
  },
  {
    label: "Dwangwa, Dwangwa - Malawi (DWA)",
    value: "DWA",
  },
  {
    label: "Karonga, Karonga - Malawi (KGJ)",
    value: "KGJ",
  },
  {
    label: "Kasungu, Kasungu - Malawi (KBQ)",
    value: "KBQ",
  },
  {
    label: "Likoma Island, Likoma - Malawi (LIX)",
    value: "LIX",
  },
  {
    label: "Lilongwe, Kamuzu Intl - Malawi (LLW)",
    value: "LLW",
  },
  {
    label: "Mangochi, Mangochi - Malawi (MAI)",
    value: "MAI",
  },
  {
    label: "Monkey Bay, Monkey Bay - Malawi (MYZ)",
    value: "MYZ",
  },
  {
    label: "Salima, Salima - Malawi (LMB)",
    value: "LMB",
  },
  {
    label: "Alor Setar, Sultan Abdul Halim - Malaysia (AOR)",
    value: "AOR",
  },
  {
    label: "Bakelalan, Bakelalan - Malaysia (BKM)",
    value: "BKM",
  },
  {
    label: "Bario, Bario - Malaysia (BBN)",
    value: "BBN",
  },
  {
    label: "Belaga, Belaga - Malaysia (BLG)",
    value: "BLG",
  },
  {
    label: "Bintulu, Bintulu Airport - Malaysia (BTU)",
    value: "BTU",
  },
  {
    label: "Ipoh, Sultan Azlan Shah - Malaysia (IPH)",
    value: "IPH",
  },
  {
    label: "Johor Bahru, Senai International - Malaysia (JHB)",
    value: "JHB",
  },
  {
    label: "Kapit, Kapit - Malaysia (KPI)",
    value: "KPI",
  },
  {
    label: "Keningau, Keningau - Malaysia (KGU)",
    value: "KGU",
  },
  {
    label: "Kerteh, Kerteh - Malaysia (KTE)",
    value: "KTE",
  },
  {
    label: "Kota Bharu, Sultan Ismail Petra - Malaysia (KBR)",
    value: "KBR",
  },
  {
    label: "Kota Kinabalu, International - Malaysia (BKI)",
    value: "BKI",
  },
  {
    label: "Kuala Lumpur, Kuala Lumpur Intl - Malaysia (KUL)",
    value: "KUL",
  },
  {
    label: "Kuala Lumpur, Sultan Abdul Aziz Shah - Malaysia (SZB)",
    value: "SZB",
  },
  {
    label: "Kuala Terengganu, Sultan Mahmud - Malaysia (TGG)",
    value: "TGG",
  },
  {
    label: "Kuantan, Sultan Haji Ahmad Shah - Malaysia (KUA)",
    value: "KUA",
  },
  {
    label: "Kuching, International - Malaysia (KCH)",
    value: "KCH",
  },
  {
    label: "Kudat, Kudat - Malaysia (KUD)",
    value: "KUD",
  },
  {
    label: "Labuan, Labuan - Malaysia (LBU)",
    value: "LBU",
  },
  {
    label: "Lahad Datu, Lahad Datu - Malaysia (LDU)",
    value: "LDU",
  },
  {
    label: "Langkawi, International - Malaysia (LGK)",
    value: "LGK",
  },
  {
    label: "Lawas, Lawas - Malaysia (LWY)",
    value: "LWY",
  },
  {
    label: "Layang-Layang Island, Layang-Layang Island - Malaysia (LAC)",
    value: "LAC",
  },
  {
    label: "Limbang, Limbang - Malaysia (LMN)",
    value: "LMN",
  },
  {
    label: "Long Akah, Long Akah - Malaysia (LKH)",
    value: "LKH",
  },
  {
    label: "Long Banga, Long Banga - Malaysia (LBP)",
    value: "LBP",
  },
  {
    label: "Long Lellang, Long Lellang - Malaysia (LGL)",
    value: "LGL",
  },
  {
    label: "Long Pasia, Long Pasia - Malaysia (GSA)",
    value: "GSA",
  },
  {
    label: "Long Semado, Long Semado - Malaysia (LSM)",
    value: "LSM",
  },
  {
    label: "Long Seridan, Long Seridan - Malaysia (ODN)",
    value: "ODN",
  },
  {
    label: "Long Sukang, Long Sukang - Malaysia (LSU)",
    value: "LSU",
  },
  {
    label: "Malacca, Malacca - Malaysia (MKZ)",
    value: "MKZ",
  },
  {
    label: "Marudi, Marudi - Malaysia (MUR)",
    value: "MUR",
  },
  {
    label: "Mersing, Mersing - Malaysia (MEP)",
    value: "MEP",
  },
  {
    label: "Miri, Miri - Malaysia (MYY)",
    value: "MYY",
  },
  {
    label: "Mukah, Mukah - Malaysia (MKM)",
    value: "MKM",
  },
  {
    label: "Mulu, Mulu - Malaysia (MZV)",
    value: "MZV",
  },
  {
    label: "Pamol, Pamol - Malaysia (PAY)",
    value: "PAY",
  },
  {
    label: "Pangkor Perak, Pangkor Perak - Malaysia (PKG)",
    value: "PKG",
  },
  {
    label: "Penang, Butterworth - Malaysia (BWH)",
    value: "BWH",
  },
  {
    label: "Penang, Penang Intl - Malaysia (PEN)",
    value: "PEN",
  },
  {
    label: "Port Klang, Ferry Port - Malaysia (XPQ)",
    value: "XPQ",
  },
  {
    label: "Ranau, Ranau - Malaysia (RNU)",
    value: "RNU",
  },
  {
    label: "Redang Island, Redang Island - Malaysia (RDN)",
    value: "RDN",
  },
  {
    label: "Sahabat, Sahabat - Malaysia (SXS)",
    value: "SXS",
  },
  {
    label: "Sandakan, Sandakan - Malaysia (SDK)",
    value: "SDK",
  },
  {
    label: "Sematan, Sematan - Malaysia (BSE)",
    value: "BSE",
  },
  {
    label: "Semporna, Semporna - Malaysia (SMM)",
    value: "SMM",
  },
  {
    label: "Sepulot, Sepulot - Malaysia (SPE)",
    value: "SPE",
  },
  {
    label: "Sibu, Sibu - Malaysia (SBW)",
    value: "SBW",
  },
  {
    label: "Sipitang, Sipitang - Malaysia (SPT)",
    value: "SPT",
  },
  {
    label: "Sitiawan, Sitiawan - Malaysia (SWY)",
    value: "SWY",
  },
  {
    label: "Taiping, Taiping - Malaysia (TPG)",
    value: "TPG",
  },
  {
    label: "Taman Negara, Sungai Tiang - Malaysia (SXT)",
    value: "SXT",
  },
  {
    label: "Tanjung Manis, Tanjung Manis Apt - Malaysia (TGC)",
    value: "TGC",
  },
  {
    label: "Tawau, Tawau - Malaysia (TWU)",
    value: "TWU",
  },
  {
    label: "Telupid, Telupid - Malaysia (TEL)",
    value: "TEL",
  },
  {
    label: "Tioman Island, Tioman Island - Malaysia (TOD)",
    value: "TOD",
  },
  {
    label: "Tomanggong, Tomanggong - Malaysia (TMG)",
    value: "TMG",
  },
  {
    label: "Dharavandhoo Island, Dharavandhoo Island - Maldives Island (DRV)",
    value: "DRV",
  },
  {
    label: "Fuvahmulak Island, Fuvahmulak Island - Maldives Island (FVM)",
    value: "FVM",
  },
  {
    label: "Gan Island, Seenu - Maldives Island (GAN)",
    value: "GAN",
  },
  {
    label: "Hanimaadhoo Island, Hanimaadhoo Island - Maldives Island (HAQ)",
    value: "HAQ",
  },
  {
    label: "Kaadedhdhoo Island, Kaadedhdhoo Island - Maldives Island (KDM)",
    value: "KDM",
  },
  {
    label: "Kadhdhoo Island, Kadhdhoo Island - Maldives Island (KDO)",
    value: "KDO",
  },
  {
    label: "Kooddoo Island, Kooddoo Island - Maldives Island (GKK)",
    value: "GKK",
  },
  {
    label: "Maamigili Island, Maamigili Island - Maldives Island (VAM)",
    value: "VAM",
  },
  {
    label: "Male, Ibrahim Nasir Intl - Maldives Island (MLE)",
    value: "MLE",
  },
  {
    label: "Thimarafushi, Thimarafushi - Maldives Island (TMF)",
    value: "TMF",
  },
  {
    label: "Bamako, Senou Intl - Mali (BKO)",
    value: "BKO",
  },
  {
    label: "Gao, Korogoussou - Mali (GAQ)",
    value: "GAQ",
  },
  {
    label: "Goundam, Goundam - Mali (GUD)",
    value: "GUD",
  },
  {
    label: "Kayes, Dag Dag - Mali (KYS)",
    value: "KYS",
  },
  {
    label: "Kenieba, Kenieba - Mali (KNZ)",
    value: "KNZ",
  },
  {
    label: "Koutiala, Koutiala - Mali (KTX)",
    value: "KTX",
  },
  {
    label: "Mopti, Ambodedjo - Mali (MZI)",
    value: "MZI",
  },
  {
    label: "Nara, Keibane - Mali (NRM)",
    value: "NRM",
  },
  {
    label: "Nioro Du Sahel, Nioro Du Sahel - Mali (NIX)",
    value: "NIX",
  },
  {
    label: "Segou, Segou - Mali (SZU)",
    value: "SZU",
  },
  {
    label: "Sikasso, Dignangan - Mali (KSS)",
    value: "KSS",
  },
  {
    label: "Tombouctou, Tombouctou - Mali (TOM)",
    value: "TOM",
  },
  {
    label: "Yelimane, Yelimane - Mali (EYL)",
    value: "EYL",
  },
  {
    label: "Malta - Malta (MLA)",
    value: "MLA",
  },
  {
    label: "Malta, Luqa International - Malta (MLA)",
    value: "MLA",
  },
  {
    label: "Gozo - Malta (GZM)",
    value: "GZM",
  },
  {
    label: "Gozo, Gozo - Malta (GZM)",
    value: "GZM",
  },
  {
    label: "Ailuk Island, Ailuk Island - Marshall Islands (AIM)",
    value: "AIM",
  },
  {
    label: "Airok, Ailinglapalap - Marshall Islands (AIC)",
    value: "AIC",
  },
  {
    label: "Aur Island, Aur Island - Marshall Islands (AUL)",
    value: "AUL",
  },
  {
    label: "Bikini Atoll, Enyu - Marshall Islands (BII)",
    value: "BII",
  },
  {
    label: "Ebadon Island, Ebadon Island - Marshall Islands (EBN)",
    value: "EBN",
  },
  {
    label: "Ebon Island, Ebon Island - Marshall Islands (EBO)",
    value: "EBO",
  },
  {
    label: "Elenak, Elenak - Marshall Islands (EAL)",
    value: "EAL",
  },
  {
    label: "Enejet Island, Enejet Island - Marshall Islands (EJT)",
    value: "EJT",
  },
  {
    label: "Enewetak Island, Enewetak - Marshall Islands (ENT)",
    value: "ENT",
  },
  {
    label: "Ine Island, Ine Island - Marshall Islands (IMI)",
    value: "IMI",
  },
  {
    label: "Jabot Island, Jabot Island - Marshall Islands (JAT)",
    value: "JAT",
  },
  {
    label: "Jaluit Island, Jaluit Island - Marshall Islands (UIT)",
    value: "UIT",
  },
  {
    label: "Jeh Island, Jeh Island - Marshall Islands (JEJ)",
    value: "JEJ",
  },
  {
    label: "Kaben Island, Kaben Island - Marshall Islands (KBT)",
    value: "KBT",
  },
  {
    label: "Kili Island, Kili Island - Marshall Islands (KIO)",
    value: "KIO",
  },
  {
    label: "Kwajalein Island, Bucholz Aaf - Marshall Islands (KWA)",
    value: "KWA",
  },
  {
    label: "Lae Island, Lae Island - Marshall Islands (LML)",
    value: "LML",
  },
  {
    label: "Likiep Island, Likiep Island - Marshall Islands (LIK)",
    value: "LIK",
  },
  {
    label: "Loen Island, Loen Island - Marshall Islands (LOF)",
    value: "LOF",
  },
  {
    label: "Majkin, Majkin - Marshall Islands (MJE)",
    value: "MJE",
  },
  {
    label: "Majuro, Amata Kabua Intl - Marshall Islands (MAJ)",
    value: "MAJ",
  },
  {
    label: "Maloelap Atoll, Taroa Island - Marshall Islands (MAV)",
    value: "MAV",
  },
  {
    label: "Mejit Island, Mejit Island - Marshall Islands (MJB)",
    value: "MJB",
  },
  {
    label: "Mili Island, Mili Island - Marshall Islands (MIJ)",
    value: "MIJ",
  },
  {
    label: "Namdrik Island, Namdrik - Marshall Islands (NDK)",
    value: "NDK",
  },
  {
    label: "Rongelap Island, Rongelap Island - Marshall Islands (RNP)",
    value: "RNP",
  },
  {
    label: "Tabal Island, Tabal Island - Marshall Islands (TBV)",
    value: "TBV",
  },
  {
    label: "Tinak Island, Tinak Island - Marshall Islands (TIC)",
    value: "TIC",
  },
  {
    label: "Ujae Island, Ujae Island - Marshall Islands (UJE)",
    value: "UJE",
  },
  {
    label: "Utirik Island, Utirik Island - Marshall Islands (UTK)",
    value: "UTK",
  },
  {
    label: "Woja Island, Woja Island - Marshall Islands (WJA)",
    value: "WJA",
  },
  {
    label: "Wotho Island, Wotho Island - Marshall Islands (WTO)",
    value: "WTO",
  },
  {
    label: "Wotje Island, Wotje Island - Marshall Islands (WTE)",
    value: "WTE",
  },
  {
    label: "Fort De France, Martinique A.Cesaire - Martinique (FDF)",
    value: "FDF",
  },
  {
    label: "Aioun El Atrouss, Aioun El Atrouss - Mauritania (AEO)",
    value: "AEO",
  },
  {
    label: "Akjoujt, Akjoujt - Mauritania (AJJ)",
    value: "AJJ",
  },
  {
    label: "Aleg, Aleg - Mauritania (LEG)",
    value: "LEG",
  },
  {
    label: "Atar, Mouakchott Intl - Mauritania (ATR)",
    value: "ATR",
  },
  {
    label: "Bogue, Abbaye - Mauritania (BGH)",
    value: "BGH",
  },
  {
    label: "Boutilimit, Boutilimit - Mauritania (OTL)",
    value: "OTL",
  },
  {
    label: "Chinguetti, Chinguetti - Mauritania (CGT)",
    value: "CGT",
  },
  {
    label: "Fderik, Fderik - Mauritania (FGD)",
    value: "FGD",
  },
  {
    label: "Kaedi, Kaedi - Mauritania (KED)",
    value: "KED",
  },
  {
    label: "Kiffa, Kiffa - Mauritania (KFA)",
    value: "KFA",
  },
  {
    label: "Mbout, Mbout - Mauritania (MBR)",
    value: "MBR",
  },
  {
    label: "Moudjeria, Letfotar - Mauritania (MOM)",
    value: "MOM",
  },
  {
    label: "Nema, Nema - Mauritania (EMN)",
    value: "EMN",
  },
  {
    label: "Nouadhibou, Nouadhibou - Mauritania (NDB)",
    value: "NDB",
  },
  {
    label: "Nouakchott, Nouakchott - Mauritania (NKC)",
    value: "NKC",
  },
  {
    label: "Selibabi, Selibabi - Mauritania (SEY)",
    value: "SEY",
  },
  {
    label: "Tamchakett, Tamchakett - Mauritania (THT)",
    value: "THT",
  },
  {
    label: "Tichit, Tichit - Mauritania (THI)",
    value: "THI",
  },
  {
    label: "Tidjikja, Tidjikja - Mauritania (TIY)",
    value: "TIY",
  },
  {
    label: "Timbedra, Timbedra - Mauritania (TMD)",
    value: "TMD",
  },
  {
    label: "Zouerate, Tazadit - Mauritania (OUZ)",
    value: "OUZ",
  },
  {
    label: "Mauritius, Sir S.Ramgoolam Intl - Mauritius Island (MRU)",
    value: "MRU",
  },
  {
    label: "Rodrigues Is, Sir Gaetan Duval - Mauritius Island (RRG)",
    value: "RRG",
  },
  {
    label: "Dzaoudzi, Pamandzi Intl - Mayotte (DZA)",
    value: "DZA",
  },
  {
    label: "Abreojos, Punta Abreojos - Mexico (AJS)",
    value: "AJS",
  },
  {
    label: "Acapulco, Juan N. Alvarez Intl - Mexico (ACA)",
    value: "ACA",
  },
  {
    label: "Aguascalientes, Jesus Teran Peredo Int - Mexico (AGU)",
    value: "AGU",
  },
  {
    label: "Alamos, Alamos - Mexico (XAL)",
    value: "XAL",
  },
  {
    label: "Apatzingan, Pablo L. Sidar - Mexico (AZG)",
    value: "AZG",
  },
  {
    label: "Bahia Angeles, Bahia De Los Angeles - Mexico (BHL)",
    value: "BHL",
  },
  {
    label: "Campeche, A.Acuna Ongay Intl - Mexico (CPE)",
    value: "CPE",
  },
  {
    label: "Cananea, Cananea - Mexico (CNA)",
    value: "CNA",
  },
  {
    label: "Cancun, International - Mexico (CUN)",
    value: "CUN",
  },
  {
    label: "Celaya, Capt. Rogelio Castillo - Mexico (CYW)",
    value: "CYW",
  },
  {
    label: "Chetumal, International - Mexico (CTM)",
    value: "CTM",
  },
  {
    label: "Chichen Itza, International - Mexico (CZA)",
    value: "CZA",
  },
  {
    label: "Chihuahua, R. Fierro Villalobos - Mexico (CUU)",
    value: "CUU",
  },
  {
    label: "Ciudad Acuna, Ciudad Acuna Intl - Mexico (ACN)",
    value: "ACN",
  },
  {
    label: "Ciudad Constitucion, Ciudad Constitucion - Mexico (CUA)",
    value: "CUA",
  },
  {
    label: "Ciudad Del Carmen, International - Mexico (CME)",
    value: "CME",
  },
  {
    label: "Ciudad Juarez, Abraham Gonzalez Intl - Mexico (CJS)",
    value: "CJS",
  },
  {
    label: "Ciudad Mante, Ciudad Mante - Mexico (MMC)",
    value: "MMC",
  },
  {
    label: "Ciudad Obregon, International - Mexico (CEN)",
    value: "CEN",
  },
  {
    label: "Ciudad Victoria, Pedro J. Mendez Intl - Mexico (CVM)",
    value: "CVM",
  },
  {
    label: "Colima, Miguel De La Madrid - Mexico (CLQ)",
    value: "CLQ",
  },
  {
    label: "Comitan, Copalar - Mexico (CJT)",
    value: "CJT",
  },
  {
    label: "Cozumel, International - Mexico (CZM)",
    value: "CZM",
  },
  {
    label: "Cuernavaca, Mariano Matamoros - Mexico (CVJ)",
    value: "CVJ",
  },
  {
    label: "Culiacan, Fed. De Bachigualato - Mexico (CUL)",
    value: "CUL",
  },
  {
    label: "Durango, Guadalupe Victoria Int - Mexico (DGO)",
    value: "DGO",
  },
  {
    label: "Ensenada, Ensenada - Mexico (ESE)",
    value: "ESE",
  },
  {
    label: "Guadalajara, Miguel Hidalgo Intl - Mexico (GDL)",
    value: "GDL",
  },
  {
    label: "Guaymas, Jose Maria Yanez Intl - Mexico (GYM)",
    value: "GYM",
  },
  {
    label: "Guerrero Negro, Guerrero Negro - Mexico (GUB)",
    value: "GUB",
  },
  {
    label: "Hermosillo, I.Pesqueira Garcia - Mexico (HMO)",
    value: "HMO",
  },
  {
    label: "Huatulco, Bahias De Huatulco Int - Mexico (HUX)",
    value: "HUX",
  },
  {
    label: "Isla Mujeres, Isla Mujeres - Mexico (ISJ)",
    value: "ISJ",
  },
  {
    label: "Ixtapa Zihuatanej, International - Mexico (ZIH)",
    value: "ZIH",
  },
  {
    label: "Ixtepec, Ixtepec - Mexico (IZT)",
    value: "IZT",
  },
  {
    label: "Jalapa, El Lencero - Mexico (JAL)",
    value: "JAL",
  },
  {
    label: "La Paz, Manuel Marquez De Leon - Mexico (LAP)",
    value: "LAP",
  },
  {
    label: "Lagos De Moreno, F. Primo De Verdad - Mexico (LOM)",
    value: "LOM",
  },
  {
    label: "Lazaro Cardenas, Lazaro Cardenas - Mexico (LZC)",
    value: "LZC",
  },
  {
    label: "Leon, Del Bajio Intl - Mexico (BJX)",
    value: "BJX",
  },
  {
    label: "Loreto, International - Mexico (LTO)",
    value: "LTO",
  },
  {
    label: "Los Mochis, Fed. Valle Del Fuerte - Mexico (LMM)",
    value: "LMM",
  },
  {
    label: "Manzanillo, Playa De Oro Intl - Mexico (ZLO)",
    value: "ZLO",
  },
  {
    label: "Matamoros, Servando Canales Intl - Mexico (MAM)",
    value: "MAM",
  },
  {
    label: "Mazatlan, Rafael Buelna Intl - Mexico (MZT)",
    value: "MZT",
  },
  {
    label: "Merida, M. Crescencio Rejon - Mexico (MID)",
    value: "MID",
  },
  {
    label: "Mexicali, R.Sanchez Taboada Int - Mexico (MXL)",
    value: "MXL",
  },
  {
    label: "Mexico City, Atizapan-Jimenez Cantu - Mexico (AZP)",
    value: "AZP",
  },
  {
    label: "Mexico City, Benito Juarez Intl - Mexico (MEX)",
    value: "MEX",
  },
  {
    label: "Mexico City, Santa Lucia Afb - Mexico (NLU)",
    value: "NLU",
  },
  {
    label: "Mexico City, Toluca-A.Lopez Mateos - Mexico (TLC)",
    value: "TLC",
  },
  {
    label: "Minatitlan, Coatzacoalcos - Mexico (MTT)",
    value: "MTT",
  },
  {
    label: "Monclova, V.Carranza Intl - Mexico (LOV)",
    value: "LOV",
  },
  {
    label: "Monterrey, Del Norte Intl - Mexico (NTR)",
    value: "NTR",
  },
  {
    label: "Monterrey, Mariano Escobedo Intl - Mexico (MTY)",
    value: "MTY",
  },
  {
    label: "Morelia, F.J. Mujica Intl - Mexico (MLM)",
    value: "MLM",
  },
  {
    label: "Mulege, Mulege - Mexico (MUG)",
    value: "MUG",
  },
  {
    label: "Nogales, International - Mexico (NOG)",
    value: "NOG",
  },
  {
    label: "Nueva Casas Grandes, Nuevo Casas Grandes - Mexico (NCG)",
    value: "NCG",
  },
  {
    label: "Nuevo Laredo, Quetzalcoatl Intl - Mexico (NLD)",
    value: "NLD",
  },
  {
    label: "Oaxaca, Xoxocotlan Intl - Mexico (OAX)",
    value: "OAX",
  },
  {
    label: "Palenque, International - Mexico (PQM)",
    value: "PQM",
  },
  {
    label: "Piedras Negras, International - Mexico (PDS)",
    value: "PDS",
  },
  {
    label: "Pinotepa Nacional, Pinotepa Nacional - Mexico (PNO)",
    value: "PNO",
  },
  {
    label: "Playa Del Carmen, Playa Del Carmen - Mexico (PCM)",
    value: "PCM",
  },
  {
    label: "Pochutla, Pochutla - Mexico (PUH)",
    value: "PUH",
  },
  {
    label: "Poza Rica, El Tajin - Mexico (PAZ)",
    value: "PAZ",
  },
  {
    label: "Puebla, Hermanos Serdan Intl - Mexico (PBC)",
    value: "PBC",
  },
  {
    label: "Puerto Escondido, International - Mexico (PXM)",
    value: "PXM",
  },
  {
    label: "Puerto Juarez, Puerto Juarez - Mexico (PJZ)",
    value: "PJZ",
  },
  {
    label: "Puerto Penasco, International - Mexico (PPE)",
    value: "PPE",
  },
  {
    label: "Puerto Vallarta, G.Diaz Ordaz Intl - Mexico (PVR)",
    value: "PVR",
  },
  {
    label: "Punta Chivato, Punta Chivato - Mexico (PCV)",
    value: "PCV",
  },
  {
    label: "Punta Colorada, Punta Colorada - Mexico (PCO)",
    value: "PCO",
  },
  {
    label: "Queretaro, Intercontinental - Mexico (QRO)",
    value: "QRO",
  },
  {
    label: "Reynosa, Lucio Blanco Intl - Mexico (REX)",
    value: "REX",
  },
  {
    label: "Salina Cruz, Salina Cruz - Mexico (SCX)",
    value: "SCX",
  },
  {
    label: "Saltillo, Plan De Guadalupe Intl - Mexico (SLW)",
    value: "SLW",
  },
  {
    label: "San Cristobal De Las Cas, San Cristobal Airport - Mexico (SZT)",
    value: "SZT",
  },
  {
    label: "San Felipe, International - Mexico (SFH)",
    value: "SFH",
  },
  {
    label: "San Ignacio, San Ignacio - Mexico (SGM)",
    value: "SGM",
  },
  {
    label: "San Jose Cabo, Los Cabos Intl - Mexico (SJD)",
    value: "SJD",
  },
  {
    label: "San Luis Potosi, Ponciano Arriaga Intl - Mexico (SLP)",
    value: "SLP",
  },
  {
    label: "San Luis Rio Colorado, San Luis Rio Colorado - Mexico (UAC)",
    value: "UAC",
  },
  {
    label: "San Quintin, San Quintin - Mexico (SNQ)",
    value: "SNQ",
  },
  {
    label: "Santa Rosalia, Palo Verde - Mexico (SRL)",
    value: "SRL",
  },
  {
    label: "Tampico, F. Javier Mina Intl - Mexico (TAM)",
    value: "TAM",
  },
  {
    label: "Tamuin, Tamuin - Mexico (TSL)",
    value: "TSL",
  },
  {
    label: "Tapachula, Tapachula Intl - Mexico (TAP)",
    value: "TAP",
  },
  {
    label: "Tehuacan, Tehuacan - Mexico (TCN)",
    value: "TCN",
  },
  {
    label: "Tepic, Amado Nervo - Mexico (TPQ)",
    value: "TPQ",
  },
  {
    label: "Tijuana, A.L. Rodriguez Intl - Mexico (TIJ)",
    value: "TIJ",
  },
  {
    label: "Tizimin, Cupul - Mexico (TZM)",
    value: "TZM",
  },
  {
    label: "Torreon, Francisco Sarabia Intl - Mexico (TRC)",
    value: "TRC",
  },
  {
    label: "Tulum, Tulum - Mexico (TUY)",
    value: "TUY",
  },
  {
    label: "Tuxtla Gutierrez, Angel Albino Corzo Int - Mexico (TGZ)",
    value: "TGZ",
  },
  {
    label: "Uruapan, Ignacio Lopez Rayon - Mexico (UPN)",
    value: "UPN",
  },
  {
    label: "Veracruz, Heriberto Jara Intl - Mexico (VER)",
    value: "VER",
  },
  {
    label: "Villa Constitucion, Villa Constitucion - Mexico (VIB)",
    value: "VIB",
  },
  {
    label: "Villahermosa, C.Rovirosa Perez Intl - Mexico (VSA)",
    value: "VSA",
  },
  {
    label: "Zacatecas, Leobardo C. Ruiz Intl - Mexico (ZCL)",
    value: "ZCL",
  },
  {
    label: "Zamora, Zamora - Mexico (ZMM)",
    value: "ZMM",
  },
  {
    label: "Kosrae, International - Micronesia (KSA)",
    value: "KSA",
  },
  {
    label: "Pohnpei, International - Micronesia (PNI)",
    value: "PNI",
  },
  {
    label: "Truk, International - Micronesia (TKK)",
    value: "TKK",
  },
  {
    label: "Ulithi, Ulithi - Micronesia (ULI)",
    value: "ULI",
  },
  {
    label: "Yap, International - Micronesia (YAP)",
    value: "YAP",
  },
  {
    label: "Chisinau - Moldova (KIV)",
    value: "KIV",
  },
  {
    label: "Chisinau, International - Moldova (KIV)",
    value: "KIV",
  },
  {
    label: "Balti - Moldova (BZY)",
    value: "BZY",
  },
  {
    label: "Balti, International - Moldova (BZY)",
    value: "BZY",
  },
  {
    label: "Monte Carlo, Monaco - Monaco (MCM)",
    value: "MCM",
  },
  {
    label: "Altai, Altai - Mongolia (LTI)",
    value: "LTI",
  },
  {
    label: "Arvaikheer, Arvaikheer - Mongolia (AVK)",
    value: "AVK",
  },
  {
    label: "Baruun Urt, Baruun Urt - Mongolia (UUN)",
    value: "UUN",
  },
  {
    label: "Bayankhongor, Bayankhongor - Mongolia (BYN)",
    value: "BYN",
  },
  {
    label: "Bulgan, Bulgan - Mongolia (UGA)",
    value: "UGA",
  },
  {
    label: "Bulgan Khovd, Bulgan Khovd - Mongolia (HBU)",
    value: "HBU",
  },
  {
    label: "Choibalsan, Choibalsan - Mongolia (COQ)",
    value: "COQ",
  },
  {
    label: "Dalanzadgad, Gurvan Saikhan - Mongolia (DLZ)",
    value: "DLZ",
  },
  {
    label: "Erdenet, Erdenet - Mongolia (ERT)",
    value: "ERT",
  },
  {
    label: "Kharkhorin, Kharkhorin - Mongolia (KHR)",
    value: "KHR",
  },
  {
    label: "Khatgal, Khatgal - Mongolia (HTM)",
    value: "HTM",
  },
  {
    label: "Khovd, Khovd - Mongolia (HVD)",
    value: "HVD",
  },
  {
    label: "Khujirt, Khujirt - Mongolia (HJT)",
    value: "HJT",
  },
  {
    label: "Mandalgobi, Mandalgovi - Mongolia (MXW)",
    value: "MXW",
  },
  {
    label: "Moron, Moron - Mongolia (MXV)",
    value: "MXV",
  },
  {
    label: "Tosontsengel, Tosontsengel - Mongolia (TNZ)",
    value: "TNZ",
  },
  {
    label: "Tsetserleg, Tsetserleg - Mongolia (TSZ)",
    value: "TSZ",
  },
  {
    label: "Ulaanbaatar, Chinggis Khaan Intl - Mongolia (ULN)",
    value: "ULN",
  },
  {
    label: "Ulaangom, Ulaangom - Mongolia (ULO)",
    value: "ULO",
  },
  {
    label: "Ulgit, Olgii - Mongolia (ULG)",
    value: "ULG",
  },
  {
    label: "Uliastai, Donoi - Mongolia (ULZ)",
    value: "ULZ",
  },
  {
    label: "Umnugobitour, Bulagtai - Mongolia (UGT)",
    value: "UGT",
  },
  {
    label: "Underkhaan, Ondorkhaan - Mongolia (UNR)",
    value: "UNR",
  },
  {
    label: "Podgorica - Montenegro (TGD)",
    value: "TGD",
  },
  {
    label: "Podgorica, Podgorica - Montenegro (TGD)",
    value: "TGD",
  },
  {
    label: "Tivat - Montenegro (TIV)",
    value: "TIV",
  },
  {
    label: "Tivat, Tivat - Montenegro (TIV)",
    value: "TIV",
  },
  {
    label: "Berane - Montenegro (IVG)",
    value: "IVG",
  },
  {
    label: "Berane, Dolac - Montenegro (IVG)",
    value: "IVG",
  },
  {
    label: "Zabljak - Montenegro (ZBK)",
    value: "ZBK",
  },
  {
    label: "Zabljak, Zabljak - Montenegro (ZBK)",
    value: "ZBK",
  },
  {
    label: "Montserrat, John S.Osborne - Montserrat (MNI)",
    value: "MNI",
  },
  {
    label: "Agadir, Al Massira - Morocco (AGA)",
    value: "AGA",
  },
  {
    label: "Al Hoceima, Cherif Al Idriss - Morocco (AHU)",
    value: "AHU",
  },
  {
    label: "Ben Slimane, Ben Slimane - Morocco (GMD)",
    value: "GMD",
  },
  {
    label: "Beni Mellal, Beni Mellal National - Morocco (BEM)",
    value: "BEM",
  },
  {
    label: "Bouarfa, Bouarfa - Morocco (UAR)",
    value: "UAR",
  },
  {
    label: "Casablanca, Anfa - Morocco (CAS)",
    value: "CAS",
  },
  {
    label: "Casablanca, Mohammed V - Morocco (CMN)",
    value: "CMN",
  },
  {
    label: "Dakhla, Dakhla - Morocco (VIL)",
    value: "VIL",
  },
  {
    label: "Errachidia, Moulay Ali Cherif - Morocco (ERH)",
    value: "ERH",
  },
  {
    label: "Essaouira, Mogador - Morocco (ESU)",
    value: "ESU",
  },
  {
    label: "Fes, Saiss - Morocco (FEZ)",
    value: "FEZ",
  },
  {
    label: "Guelmime, Guelmime - Morocco (GLN)",
    value: "GLN",
  },
  {
    label: "Kenitra, Royal Afb - Morocco (NNA)",
    value: "NNA",
  },
  {
    label: "Laayoune, Hassan I - Morocco (EUN)",
    value: "EUN",
  },
  {
    label: "Marrakech, Menara - Morocco (RAK)",
    value: "RAK",
  },
  {
    label: "Meknes, Royal Afb - Morocco (MEK)",
    value: "MEK",
  },
  {
    label: "Nador, El Aroui - Morocco (NDR)",
    value: "NDR",
  },
  {
    label: "Ouarzazate, Ouarzazate - Morocco (OZZ)",
    value: "OZZ",
  },
  {
    label: "Oujda, Angads - Morocco (OUD)",
    value: "OUD",
  },
  {
    label: "Rabat, Sale - Morocco (RBA)",
    value: "RBA",
  },
  {
    label: "Sidi Ifni, Sania Ramel - Morocco (SII)",
    value: "SII",
  },
  {
    label: "Smara, Smara - Morocco (SMW)",
    value: "SMW",
  },
  {
    label: "Tan Tan, Plage Blanche - Morocco (TTA)",
    value: "TTA",
  },
  {
    label: "Tangier, Ibn Batouta - Morocco (TNG)",
    value: "TNG",
  },
  {
    label: "Tetouan, Saniat R Mel - Morocco (TTU)",
    value: "TTU",
  },
  {
    label: "Zagora, Zagora - Morocco (OZG)",
    value: "OZG",
  },
  {
    label: "Alto Molocue, Alto Molocue - Mozambique (AME)",
    value: "AME",
  },
  {
    label: "Angoche, Angoche - Mozambique (ANO)",
    value: "ANO",
  },
  {
    label: "Bajone, Bajone - Mozambique (BJN)",
    value: "BJN",
  },
  {
    label: "Bazaruto Island, Bazaruto Island - Mozambique (BZB)",
    value: "BZB",
  },
  {
    label: "Beira, Beira - Mozambique (BEW)",
    value: "BEW",
  },
  {
    label: "Benguerra Island, Benguerra Island - Mozambique (BCW)",
    value: "BCW",
  },
  {
    label: "Caia, Caia - Mozambique (CMZ)",
    value: "CMZ",
  },
  {
    label: "Chilonzuine Island, Chilonzuine Island - Mozambique (IDC)",
    value: "IDC",
  },
  {
    label: "Chimoio, Chimoio - Mozambique (VPY)",
    value: "VPY",
  },
  {
    label: "Chinde, Chinde - Mozambique (INE)",
    value: "INE",
  },
  {
    label: "Chokwe, Chokwe - Mozambique (TGS)",
    value: "TGS",
  },
  {
    label: "Cuamba, Cuamba - Mozambique (FXO)",
    value: "FXO",
  },
  {
    label: "Dugong Beach Lodge, Dugong Beach Lodge - Mozambique (DGK)",
    value: "DGK",
  },
  {
    label: "Gurue, Gurue - Mozambique (VJQ)",
    value: "VJQ",
  },
  {
    label: "Ibo, Ibo - Mozambique (IBO)",
    value: "IBO",
  },
  {
    label: "Indigo Bay Lodge, Indigo Bay Lodge - Mozambique (IBL)",
    value: "IBL",
  },
  {
    label: "Inhaca, Inhaca - Mozambique (IHC)",
    value: "IHC",
  },
  {
    label: "Inhambane, Inhambane - Mozambique (INH)",
    value: "INH",
  },
  {
    label: "Inhaminga, Inhaminga - Mozambique (IMG)",
    value: "IMG",
  },
  {
    label: "Lichinga, Lichinga - Mozambique (VXC)",
    value: "VXC",
  },
  {
    label: "Luabo, Luabo - Mozambique (LBM)",
    value: "LBM",
  },
  {
    label: "Lumbo, Lumbo Apt - Mozambique (LFB)",
    value: "LFB",
  },
  {
    label: "Maganja Da Costa, Maganja Da Costa - Mozambique (MJS)",
    value: "MJS",
  },
  {
    label: "Magaruque Island, Magaruque Island - Mozambique (MFW)",
    value: "MFW",
  },
  {
    label: "Maputo, Maputo Intl - Mozambique (MPM)",
    value: "MPM",
  },
  {
    label: "Marromeu, Marromeu - Mozambique (RRM)",
    value: "RRM",
  },
  {
    label: "Mocimboa Da Praia, Mocimboa Da Praia - Mozambique (MZB)",
    value: "MZB",
  },
  {
    label: "Moma, Moma - Mozambique (MMW)",
    value: "MMW",
  },
  {
    label: "Montepuez, Montepuez - Mozambique (MTU)",
    value: "MTU",
  },
  {
    label: "Mueda, Mueda - Mozambique (MUD)",
    value: "MUD",
  },
  {
    label: "Nacala, Nacala - Mozambique (MNC)",
    value: "MNC",
  },
  {
    label: "Nampula, Nampula - Mozambique (APL)",
    value: "APL",
  },
  {
    label: "Nangade, Nangade - Mozambique (NND)",
    value: "NND",
  },
  {
    label: "Palma, Palma - Mozambique (LMZ)",
    value: "LMZ",
  },
  {
    label: "Pebane, Pebane - Mozambique (PEB)",
    value: "PEB",
  },
  {
    label: "Pemba, Pemba - Mozambique (POL)",
    value: "POL",
  },
  {
    label: "Ponta Do Ouro, Ponta Do Ouro - Mozambique (PDD)",
    value: "PDD",
  },
  {
    label: "Quelimane, Quelimane - Mozambique (UEL)",
    value: "UEL",
  },
  {
    label: "Santa Carolina, Santa Carolina - Mozambique (NTC)",
    value: "NTC",
  },
  {
    label: "Tete, Chingozi - Mozambique (TET)",
    value: "TET",
  },
  {
    label: "Tete, Tete Airport - Mozambique (TCV)",
    value: "TCV",
  },
  {
    label: "Vilankulos, Vilankulos - Mozambique (VNX)",
    value: "VNX",
  },
  {
    label: "Xai-Xai, Xai-Xai - Mozambique (VJB)",
    value: "VJB",
  },
  {
    label: "Ann, Ann - Myanmar (VBA)",
    value: "VBA",
  },
  {
    label: "Bassein, Pathein - Myanmar (BSX)",
    value: "BSX",
  },
  {
    label: "Bhamo, Bhamo - Myanmar (BMO)",
    value: "BMO",
  },
  {
    label: "Bokpyin , Bokpyin - Myanmar (VBP)",
    value: "VBP",
  },
  {
    label: "Dawe, Dawei - Myanmar (TVY)",
    value: "TVY",
  },
  {
    label: "Gangaw, Gangaw - Myanmar (GAW)",
    value: "GAW",
  },
  {
    label: "Gwa, Gwa - Myanmar (GWA)",
    value: "GWA",
  },
  {
    label: "Heho, Heho - Myanmar (HEH)",
    value: "HEH",
  },
  {
    label: "Henzada, Hinthada - Myanmar (HEB)",
    value: "HEB",
  },
  {
    label: "Homalin, Homalin - Myanmar (HOX)",
    value: "HOX",
  },
  {
    label: "Kalemyo, Kalemyo - Myanmar (KMV)",
    value: "KMV",
  },
  {
    label: "Kawthaung, Kawthaung - Myanmar (KAW)",
    value: "KAW",
  },
  {
    label: "Keng Tung, Kengtung - Myanmar (KET)",
    value: "KET",
  },
  {
    label: "Khamti, Khamti - Myanmar (KHM)",
    value: "KHM",
  },
  {
    label: "Kyaukpyu, Kyaukpyu - Myanmar (KYP)",
    value: "KYP",
  },
  {
    label: "Kyauktaw, Kyauktu - Myanmar (KYT)",
    value: "KYT",
  },
  {
    label: "Lashio, Lashio - Myanmar (LSH)",
    value: "LSH",
  },
  {
    label: "Loikaw, Loikaw - Myanmar (LIW)",
    value: "LIW",
  },
  {
    label: "Magwe, Magway - Myanmar (MWQ)",
    value: "MWQ",
  },
  {
    label: "Manaung, Manaung - Myanmar (MGU)",
    value: "MGU",
  },
  {
    label: "Mandalay, Chan Mya Thazi - Myanmar (VBC)",
    value: "VBC",
  },
  {
    label: "Mandalay, Mandalay International - Myanmar (MDL)",
    value: "MDL",
  },
  {
    label: "Maulmyine, Mawlamyine - Myanmar (MNU)",
    value: "MNU",
  },
  {
    label: "Momeik, Momeik - Myanmar (MOE)",
    value: "MOE",
  },
  {
    label: "Mong Hsat, Mong Hsat - Myanmar (MOG)",
    value: "MOG",
  },
  {
    label: "Mong Ton, Mong Ton - Myanmar (MGK)",
    value: "MGK",
  },
  {
    label: "Monywa, Monywa - Myanmar (NYW)",
    value: "NYW",
  },
  {
    label: "Myeik, Myeik - Myanmar (MGZ)",
    value: "MGZ",
  },
  {
    label: "Myitkyina, Myitkyina - Myanmar (MYT)",
    value: "MYT",
  },
  {
    label: "Namsang, Nansang - Myanmar (NMS)",
    value: "NMS",
  },
  {
    label: "Namtu, Namtu - Myanmar (NMT)",
    value: "NMT",
  },
  {
    label: "Nay Pyi Taw, International - Myanmar (NYT)",
    value: "NYT",
  },
  {
    label: "Nyaung U, Bagan Nyaung U - Myanmar (NYU)",
    value: "NYU",
  },
  {
    label: "Pa An, Pa An - Myanmar (PAA)",
    value: "PAA",
  },
  {
    label: "Pakokku, Pakokku - Myanmar (PKK)",
    value: "PKK",
  },
  {
    label: "Papun, Papun - Myanmar (PPU)",
    value: "PPU",
  },
  {
    label: "Pauk, Pauk - Myanmar (PAU)",
    value: "PAU",
  },
  {
    label: "Prome, Pyay - Myanmar (PRU)",
    value: "PRU",
  },
  {
    label: "Putao, Putao - Myanmar (PBU)",
    value: "PBU",
  },
  {
    label: "Sittwe, Sittwe - Myanmar (AKY)",
    value: "AKY",
  },
  {
    label: "Tachilek, Tachilek - Myanmar (THL)",
    value: "THL",
  },
  {
    label: "Thandwe, Thandwe - Myanmar (SNW)",
    value: "SNW",
  },
  {
    label: "Tilin, Tilin - Myanmar (TIO)",
    value: "TIO",
  },
  {
    label: "Yangon, Mingaladon - Myanmar (RGN)",
    value: "RGN",
  },
  {
    label: "Ye, Ye - Myanmar (XYE)",
    value: "XYE",
  },
  {
    label: "Ai Ais, Ai Ais - Namibia (AIW)",
    value: "AIW",
  },
  {
    label: "Arandis, Arandis - Namibia (ADI)",
    value: "ADI",
  },
  {
    label: "Bagani, Bagani - Namibia (BQI)",
    value: "BQI",
  },
  {
    label: "Gobabis, Gobabis - Namibia (GOG)",
    value: "GOG",
  },
  {
    label: "Grootfontein, Grootfontein - Namibia (GFY)",
    value: "GFY",
  },
  {
    label: "Halali, Halali - Namibia (HAL)",
    value: "HAL",
  },
  {
    label: "Karasburg, Karasburg - Namibia (KAS)",
    value: "KAS",
  },
  {
    label: "Katima Mulilo, Mpacha - Namibia (MPA)",
    value: "MPA",
  },
  {
    label: "Keetmanshoop, Keetmanshoop - Namibia (KMP)",
    value: "KMP",
  },
  {
    label: "Lianshulu, Lianshulu - Namibia (LHU)",
    value: "LHU",
  },
  {
    label: "Luderitz, Luderitz - Namibia (LUD)",
    value: "LUD",
  },
  {
    label: "Midgard, Midgard - Namibia (MQG)",
    value: "MQG",
  },
  {
    label: "Mokuti Lodge, Mokuti Lodge - Namibia (OKU)",
    value: "OKU",
  },
  {
    label: "Mount Etjo, Mount Etjo - Namibia (MJO)",
    value: "MJO",
  },
  {
    label: "Namutoni, Namutoni - Namibia (NNI)",
    value: "NNI",
  },
  {
    label: "Okaukuejo, Okaukuejo - Namibia (OKF)",
    value: "OKF",
  },
  {
    label: "Omega, Omega - Namibia (OMG)",
    value: "OMG",
  },
  {
    label: "Ondangwa, Ondangwa - Namibia (OND)",
    value: "OND",
  },
  {
    label: "Ongava, Ongava - Namibia (OGV)",
    value: "OGV",
  },
  {
    label: "Opuwo, Opuwo - Namibia (OPW)",
    value: "OPW",
  },
  {
    label: "Oranjemund, Oranjemund - Namibia (OMD)",
    value: "OMD",
  },
  {
    label: "Oshakati, Oshakati - Namibia (OHI)",
    value: "OHI",
  },
  {
    label: "Otjiwarongo, Otjiwarongo - Namibia (OTJ)",
    value: "OTJ",
  },
  {
    label: "Rosh Pinah, Skorpion Mine - Namibia (RHN)",
    value: "RHN",
  },
  {
    label: "Rundu, Rundu - Namibia (NDU)",
    value: "NDU",
  },
  {
    label: "Sesriem, Sesriem - Namibia (SZM)",
    value: "SZM",
  },
  {
    label: "Swakopmund, Swakopmund Airport - Namibia (SWP)",
    value: "SWP",
  },
  {
    label: "Terrace Bay, Terrace Bay - Namibia (TCY)",
    value: "TCY",
  },
  {
    label: "Tsumeb, Tsumeb - Namibia (TSB)",
    value: "TSB",
  },
  {
    label: "Walvis Bay, Walvis Bay - Namibia (WVB)",
    value: "WVB",
  },
  {
    label: "Windhoek, Eros - Namibia (ERS)",
    value: "ERS",
  },
  {
    label: "Windhoek, Hosea Kutako Int'l - Namibia (WDH)",
    value: "WDH",
  },
  {
    label: "Nauru Island, Nauru Island Intl - Nauru (INU)",
    value: "INU",
  },
  {
    label: "Baglung, Balewa - Nepal (BGL)",
    value: "BGL",
  },
  {
    label: "Baitadi, Patan - Nepal (BIT)",
    value: "BIT",
  },
  {
    label: "Bajhang, Bajhang - Nepal (BJH)",
    value: "BJH",
  },
  {
    label: "Bajura, Bajura Arpt - Nepal (BJU)",
    value: "BJU",
  },
  {
    label: "Bhadrapur, Chandragadhi - Nepal (BDP)",
    value: "BDP",
  },
  {
    label: "Bhairawa, Gautam Buddha - Nepal (BWA)",
    value: "BWA",
  },
  {
    label: "Bharatpur, Bharatpur - Nepal (BHR)",
    value: "BHR",
  },
  {
    label: "Bhojpur, Bhojpur - Nepal (BHP)",
    value: "BHP",
  },
  {
    label: "Biratnagar, Biratnagar - Nepal (BIR)",
    value: "BIR",
  },
  {
    label: "Dang, Tribhuvannagar - Nepal (DNP)",
    value: "DNP",
  },
  {
    label: "Darchula, Darchula - Nepal (DAP)",
    value: "DAP",
  },
  {
    label: "Dhangarhi, Dhangarhi - Nepal (DHI)",
    value: "DHI",
  },
  {
    label: "Dolpa, Dolpa - Nepal (DOP)",
    value: "DOP",
  },
  {
    label: "Gorkha, Palungtar - Nepal (GKH)",
    value: "GKH",
  },
  {
    label: "Janakpur, Janakpur - Nepal (JKR)",
    value: "JKR",
  },
  {
    label: "Jiri, Jiri - Nepal (JIR)",
    value: "JIR",
  },
  {
    label: "Jomsom, Jomsom - Nepal (JMO)",
    value: "JMO",
  },
  {
    label: "Jumla, Jumla - Nepal (JUM)",
    value: "JUM",
  },
  {
    label: "Kathmandu, Tribhuvan Intl - Nepal (KTM)",
    value: "KTM",
  },
  {
    label: "Lamidanda, Lamidanda - Nepal (LDN)",
    value: "LDN",
  },
  {
    label: "Langtang, Langtang - Nepal (LTG)",
    value: "LTG",
  },
  {
    label: "Lukla, Tenzing-Hillary - Nepal (LUA)",
    value: "LUA",
  },
  {
    label: "Mahendranagar, Mahendranagar - Nepal (XMG)",
    value: "XMG",
  },
  {
    label: "Manang, Manang - Nepal (NGX)",
    value: "NGX",
  },
  {
    label: "Meghauli, Meghauli - Nepal (MEY)",
    value: "MEY",
  },
  {
    label: "Nepalganj, Nepalganj - Nepal (KEP)",
    value: "KEP",
  },
  {
    label: "Phaplu, Phaplu - Nepal (PPL)",
    value: "PPL",
  },
  {
    label: "Pokhara, Pokhara - Nepal (PKR)",
    value: "PKR",
  },
  {
    label: "Rajbiraj, Rajbiraj - Nepal (RJB)",
    value: "RJB",
  },
  {
    label: "Ramechhap, Ramechhap - Nepal (RHP)",
    value: "RHP",
  },
  {
    label: "Rolpa, Rolpa - Nepal (RPA)",
    value: "RPA",
  },
  {
    label: "Rukumkot, Chaurjahari - Nepal (RUK)",
    value: "RUK",
  },
  {
    label: "Rumjatar, Rumjatar - Nepal (RUM)",
    value: "RUM",
  },
  {
    label: "Sanfebagar, Sanfebagar - Nepal (FEB)",
    value: "FEB",
  },
  {
    label: "Silgadi Doti, Doti - Nepal (SIH)",
    value: "SIH",
  },
  {
    label: "Simara, Simara - Nepal (SIF)",
    value: "SIF",
  },
  {
    label: "Simikot, Simikot - Nepal (IMK)",
    value: "IMK",
  },
  {
    label: "Surkhet, Surkhet - Nepal (SKH)",
    value: "SKH",
  },
  {
    label: "Syangboche, Syangboche - Nepal (SYH)",
    value: "SYH",
  },
  {
    label: "Taplejung, Taplejung - Nepal (TPJ)",
    value: "TPJ",
  },
  {
    label: "Tikapur, Tikapur - Nepal (TPU)",
    value: "TPU",
  },
  {
    label: "Tumling Tar, Tumlingtar - Nepal (TMI)",
    value: "TMI",
  },
  {
    label: "Amsterdam - Netherlands (AMS)",
    value: "AMS",
  },
  {
    label: "Amsterdam, Schiphol Airport - Netherlands (AMS)",
    value: "AMS",
  },
  {
    label: "Eindhoven - Netherlands (EIN)",
    value: "EIN",
  },
  {
    label: "Eindhoven, Eindhoven - Netherlands (EIN)",
    value: "EIN",
  },
  {
    label: "Groningen - Netherlands (GRQ)",
    value: "GRQ",
  },
  {
    label: "Groningen, Eelde - Netherlands (GRQ)",
    value: "GRQ",
  },
  {
    label: "Maastricht - Netherlands (MST)",
    value: "MST",
  },
  {
    label: "Maastricht, Maastricht - Netherlands (MST)",
    value: "MST",
  },
  {
    label: "Rotterdam - Netherlands (RTM)",
    value: "RTM",
  },
  {
    label: "Rotterdam, Rotterdam - Netherlands (RTM)",
    value: "RTM",
  },
  {
    label: "Bergen Op Zoom, Woensdrecht Ab - Netherlands (WOE)",
    value: "WOE",
  },
  {
    label: "Breda, Gilze Rijen Ab - Netherlands (GLZ)",
    value: "GLZ",
  },
  {
    label: "Den Helder, De Kooy - Netherlands (DHR)",
    value: "DHR",
  },
  {
    label: "Enschede, Twenthe - Netherlands (ENS)",
    value: "ENS",
  },
  {
    label: "Leeuwarden, Air Base - Netherlands (LWR)",
    value: "LWR",
  },
  {
    label: "Lelystad, Lelystad - Netherlands (LEY)",
    value: "LEY",
  },
  {
    label: "The Hague, The Hague - Netherlands (HAG)",
    value: "HAG",
  },
  {
    label: "Uden, Volkel Ab - Netherlands (UDE)",
    value: "UDE",
  },
  {
    label: "Utrecht, Soesterberg - Netherlands (UTC)",
    value: "UTC",
  },
  {
    label: "Belep Island, Waala - New Caledonia (BMY)",
    value: "BMY",
  },
  {
    label: "Ile Des Pins, Moue - New Caledonia (ILP)",
    value: "ILP",
  },
  {
    label: "Kone, Kone - New Caledonia (KNQ)",
    value: "KNQ",
  },
  {
    label: "Koumac, Koumac - New Caledonia (KOC)",
    value: "KOC",
  },
  {
    label: "Lifou, Ouanaham - New Caledonia (LIF)",
    value: "LIF",
  },
  {
    label: "Mare, La Roche - New Caledonia (MEE)",
    value: "MEE",
  },
  {
    label: "Mueo, Mueo - New Caledonia (PDC)",
    value: "PDC",
  },
  {
    label: "Noumea, La Tontouta - New Caledonia (NOU)",
    value: "NOU",
  },
  {
    label: "Noumea, Magenta - New Caledonia (GEA)",
    value: "GEA",
  },
  {
    label: "Ouvea, Ouloup - New Caledonia (UVE)",
    value: "UVE",
  },
  {
    label: "Poum, Malabou - New Caledonia (PUV)",
    value: "PUV",
  },
  {
    label: "Tiga, Tiga - New Caledonia (TGJ)",
    value: "TGJ",
  },
  {
    label: "Touho, Touho - New Caledonia (TOU)",
    value: "TOU",
  },
  {
    label: "Alexandra, Alexandra - New Zealand (ALR)",
    value: "ALR",
  },
  {
    label: "Ardmore, Ardmore - New Zealand (AMZ)",
    value: "AMZ",
  },
  {
    label: "Ashburton, Ashburton - New Zealand (ASG)",
    value: "ASG",
  },
  {
    label: "Auckland, Auckland International - New Zealand (AKL)",
    value: "AKL",
  },
  {
    label: "Auckland, Mechanics Bay Heliport - New Zealand (MHB)",
    value: "MHB",
  },
  {
    label: "Blenheim, Woodbourne - New Zealand (BHE)",
    value: "BHE",
  },
  {
    label: "Chatham Island, Tuuta - New Zealand (CHT)",
    value: "CHT",
  },
  {
    label: "Christchurch, Christchurch - New Zealand (CHC)",
    value: "CHC",
  },
  {
    label: "Coromandel, Coromandel - New Zealand (CMV)",
    value: "CMV",
  },
  {
    label: "Dargaville, Dargaville - New Zealand (DGR)",
    value: "DGR",
  },
  {
    label: "Dunedin, International - New Zealand (DUD)",
    value: "DUD",
  },
  {
    label: "Fox Glacier, Fox Heliport - New Zealand (FGL)",
    value: "FGL",
  },
  {
    label: "Franz Josef, Franz Josef Glacier - New Zealand (WHO)",
    value: "WHO",
  },
  {
    label: "Gisborne, Gisborne - New Zealand (GIS)",
    value: "GIS",
  },
  {
    label: "Great Barrier Isl, Great Barrier Isl - New Zealand (GBZ)",
    value: "GBZ",
  },
  {
    label: "Greymouth, Greymouth - New Zealand (GMN)",
    value: "GMN",
  },
  {
    label: "Hamilton, International - New Zealand (HLZ)",
    value: "HLZ",
  },
  {
    label: "Helicopter Scenic, Helicopter Scenic - New Zealand (XXH)",
    value: "XXH",
  },
  {
    label: "Hokitika, Hokitika - New Zealand (HKK)",
    value: "HKK",
  },
  {
    label: "Invercargill, Invercargill - New Zealand (IVC)",
    value: "IVC",
  },
  {
    label: "Kaikohe, Kaikohe - New Zealand (KKO)",
    value: "KKO",
  },
  {
    label: "Kaikoura, Kaikoura - New Zealand (KBZ)",
    value: "KBZ",
  },
  {
    label: "Kaitaia, Kaitaia - New Zealand (KAT)",
    value: "KAT",
  },
  {
    label: "Kerikeri, Bay Of Islands - New Zealand (KKE)",
    value: "KKE",
  },
  {
    label: "Mansion House, Mansion House - New Zealand (KWU)",
    value: "KWU",
  },
  {
    label: "Masterton, Masterton - New Zealand (MRO)",
    value: "MRO",
  },
  {
    label: "Matamata, Matamata - New Zealand (MTA)",
    value: "MTA",
  },
  {
    label: "Milford Sound, Milford Sound - New Zealand (MFN)",
    value: "MFN",
  },
  {
    label: "Motueka, Motueka - New Zealand (MZP)",
    value: "MZP",
  },
  {
    label: "Mount Cook, Glentanner - New Zealand (GTN)",
    value: "GTN",
  },
  {
    label: "Mount Cook, Mount Cook Airport - New Zealand (MON)",
    value: "MON",
  },
  {
    label: "Mount Cook, Pukaki Twizel - New Zealand (TWZ)",
    value: "TWZ",
  },
  {
    label: "Mystery Break, Mystery Break - New Zealand (ENZ)",
    value: "ENZ",
  },
  {
    label: "Mystery Explorer, Mystery Explorer - New Zealand (ZUF)",
    value: "ZUF",
  },
  {
    label: "Napier, Hawkes Bay - New Zealand (NPE)",
    value: "NPE",
  },
  {
    label: "Nelson, Nelson - New Zealand (NSN)",
    value: "NSN",
  },
  {
    label: "New Plymouth, New Plymouth - New Zealand (NPL)",
    value: "NPL",
  },
  {
    label: "Oamaru, Oamaru - New Zealand (OAM)",
    value: "OAM",
  },
  {
    label: "Ohakea, Rnzaf Base - New Zealand (OHA)",
    value: "OHA",
  },
  {
    label: "Palmerston North, Palmerston North - New Zealand (PMR)",
    value: "PMR",
  },
  {
    label: "Paraparaumu, Kapiti Coast - New Zealand (PPQ)",
    value: "PPQ",
  },
  {
    label: "Picton, Picton - New Zealand (PCN)",
    value: "PCN",
  },
  {
    label: "Queenstown, Queenstown - New Zealand (ZQN)",
    value: "ZQN",
  },
  {
    label: "Raglan, Raglan - New Zealand (RAG)",
    value: "RAG",
  },
  {
    label: "Rotorua, Rotorua International - New Zealand (ROT)",
    value: "ROT",
  },
  {
    label: "Stewart Island, Ryans Creek - New Zealand (SZS)",
    value: "SZS",
  },
  {
    label: "Surfdale, Waiheke Island - New Zealand (WIK)",
    value: "WIK",
  },
  {
    label: "Taharoa, Taharoa - New Zealand (THH)",
    value: "THH",
  },
  {
    label: "Takaka, Takaka - New Zealand (KTF)",
    value: "KTF",
  },
  {
    label: "Taupo, Taupo - New Zealand (TUO)",
    value: "TUO",
  },
  {
    label: "Tauranga, Tauranga - New Zealand (TRG)",
    value: "TRG",
  },
  {
    label: "Te Anau, Manapouri - New Zealand (TEU)",
    value: "TEU",
  },
  {
    label: "Thames, Thames - New Zealand (TMZ)",
    value: "TMZ",
  },
  {
    label: "Timaru, Richard Pearse - New Zealand (TIU)",
    value: "TIU",
  },
  {
    label: "Tokoroa, Tokoroa - New Zealand (TKZ)",
    value: "TKZ",
  },
  {
    label: "Wairoa, Wairoa - New Zealand (WIR)",
    value: "WIR",
  },
  {
    label: "Wanaka, Wanaka - New Zealand (WKA)",
    value: "WKA",
  },
  {
    label: "Wanganui, Wanganui - New Zealand (WAG)",
    value: "WAG",
  },
  {
    label: "Wellington, Wellington - New Zealand (WLG)",
    value: "WLG",
  },
  {
    label: "Westport, Westport - New Zealand (WSZ)",
    value: "WSZ",
  },
  {
    label: "Whakatane, Whakatane - New Zealand (WHK)",
    value: "WHK",
  },
  {
    label: "Whangarei, Whangarei - New Zealand (WRE)",
    value: "WRE",
  },
  {
    label: "Whitianga, Whitianga - New Zealand (WTZ)",
    value: "WTZ",
  },
  {
    label: "Bluefields, Bluefields - Nicaragua (BEF)",
    value: "BEF",
  },
  {
    label: "Bonanza, San Pedro - Nicaragua (BZA)",
    value: "BZA",
  },
  {
    label: "Corn Island, Corn Island - Nicaragua (RNI)",
    value: "RNI",
  },
  {
    label: "Managua, Augusto C.Sandino Int - Nicaragua (MGA)",
    value: "MGA",
  },
  {
    label: "Nueva Guinea, Nueva Guinea - Nicaragua (NVG)",
    value: "NVG",
  },
  {
    label: "Puerto Cabezas, Puerto Cabezas - Nicaragua (PUZ)",
    value: "PUZ",
  },
  {
    label: "Rivas, Costa Esmeralda - Nicaragua (ECI)",
    value: "ECI",
  },
  {
    label: "Rosita, Rosita - Nicaragua (RFS)",
    value: "RFS",
  },
  {
    label: "San Carlos, San Carlos - Nicaragua (NCR)",
    value: "NCR",
  },
  {
    label: "Siuna, Siuna - Nicaragua (SIU)",
    value: "SIU",
  },
  {
    label: "Waspam, Waspam - Nicaragua (WSP)",
    value: "WSP",
  },
  {
    label: "Agadez, Manu Dayak Intl - Niger (AJY)",
    value: "AJY",
  },
  {
    label: "Arlit, Arlit - Niger (RLT)",
    value: "RLT",
  },
  {
    label: "Maradi, Maradi - Niger (MFQ)",
    value: "MFQ",
  },
  {
    label: "Niamey, Diori Hamani Intl - Niger (NIM)",
    value: "NIM",
  },
  {
    label: "Tahoua, Tahoua - Niger (THZ)",
    value: "THZ",
  },
  {
    label: "Zinder, Zinder - Niger (ZND)",
    value: "ZND",
  },
  {
    label: "Abuja, Nnamdi Azikiwe Intl - Nigeria (ABV)",
    value: "ABV",
  },
  {
    label: "Akure, Akure - Nigeria (AKR)",
    value: "AKR",
  },
  {
    label: "Asaba, International - Nigeria (ABB)",
    value: "ABB",
  },
  {
    label: "Bauchi, Bauchi - Nigeria (BCU)",
    value: "BCU",
  },
  {
    label: "Benin City, Benin City - Nigeria (BNI)",
    value: "BNI",
  },
  {
    label: "Calabar, Margaret Ekpo Intl - Nigeria (CBQ)",
    value: "CBQ",
  },
  {
    label: "Enugu, Akanu Ibiam Intl - Nigeria (ENU)",
    value: "ENU",
  },
  {
    label: "Gombe, Lawanti - Nigeria (GMO)",
    value: "GMO",
  },
  {
    label: "Ibadan, Ibadan - Nigeria (IBA)",
    value: "IBA",
  },
  {
    label: "Ilorin, Ilorin International - Nigeria (ILR)",
    value: "ILR",
  },
  {
    label: "Jos, Yakubu Gowon - Nigeria (JOS)",
    value: "JOS",
  },
  {
    label: "Kaduna, Kaduna - Nigeria (KAD)",
    value: "KAD",
  },
  {
    label: "Kano, Mallam Aminu Kano Intl - Nigeria (KAN)",
    value: "KAN",
  },
  {
    label: "Katsina, Katsina - Nigeria (DKA)",
    value: "DKA",
  },
  {
    label: "Lagos, Murtala Muhammed Intl - Nigeria (LOS)",
    value: "LOS",
  },
  {
    label: "Maiduguri, Maiduguri Intl - Nigeria (MIU)",
    value: "MIU",
  },
  {
    label: "Makurdi, Makurdi - Nigeria (MDI)",
    value: "MDI",
  },
  {
    label: "Minna, Minna - Nigeria (MXJ)",
    value: "MXJ",
  },
  {
    label: "Owerri, Sam Mbakwe Intl - Nigeria (QOW)",
    value: "QOW",
  },
  {
    label: "Port Harcourt, Port Harcourt City Apt - Nigeria (PHG)",
    value: "PHG",
  },
  {
    label: "Port Harcourt, Port Harcourt Intl - Nigeria (PHC)",
    value: "PHC",
  },
  {
    label: "Sokoto, Sadiq Abubakar Iii - Nigeria (SKO)",
    value: "SKO",
  },
  {
    label: "Uyo, Akwa Ibom Intl - Nigeria (QUO)",
    value: "QUO",
  },
  {
    label: "Warri, Qsubi - Nigeria (QRW)",
    value: "QRW",
  },
  {
    label: "Yola, Yola - Nigeria (YOL)",
    value: "YOL",
  },
  {
    label: "Zaria, Zaria - Nigeria (ZAR)",
    value: "ZAR",
  },
  {
    label: "Niue Island, International - Niue (IUE)",
    value: "IUE",
  },
  {
    label: "Rota, International - Northern Mariana Islands (ROP)",
    value: "ROP",
  },
  {
    label: "Saipan, Francisco C Ada Intl - Northern Mariana Islands (SPN)",
    value: "SPN",
  },
  {
    label: "Tinian, International - Northern Mariana Islands (TIQ)",
    value: "TIQ",
  },
  {
    label: "Alesund - Norway (AES)",
    value: "AES",
  },
  {
    label: "Alesund, Vigra - Norway (AES)",
    value: "AES",
  },
  {
    label: "Bergen - Norway (BGO)",
    value: "BGO",
  },
  {
    label: "Bergen, Flesland - Norway (BGO)",
    value: "BGO",
  },
  {
    label: "Bodo - Norway (BOO)",
    value: "BOO",
  },
  {
    label: "Bodo, Bodo - Norway (BOO)",
    value: "BOO",
  },
  {
    label: "Alta - Norway (ALF)",
    value: "ALF",
  },
  {
    label: "Alta, Alta - Norway (ALF)",
    value: "ALF",
  },
  {
    label: "Andenes - Norway (ANX)",
    value: "ANX",
  },
  {
    label: "Andenes, Andoya - Norway (ANX)",
    value: "ANX",
  },
  {
    label: "Bardufoss - Norway (BDU)",
    value: "BDU",
  },
  {
    label: "Bardufoss, Bardufoss - Norway (BDU)",
    value: "BDU",
  },
  {
    label: "Batsfjord - Norway (BJF)",
    value: "BJF",
  },
  {
    label: "Batsfjord, Batsfjord - Norway (BJF)",
    value: "BJF",
  },
  {
    label: "Berlevag - Norway (BVG)",
    value: "BVG",
  },
  {
    label: "Berlevag, Berlevag - Norway (BVG)",
    value: "BVG",
  },
  {
    label: "Bronnoysund - Norway (BNN)",
    value: "BNN",
  },
  {
    label: "Bronnoysund, Bronnoy - Norway (BNN)",
    value: "BNN",
  },
  {
    label: "Haugesund - Norway (HAU)",
    value: "HAU",
  },
  {
    label: "Haugesund, Karmoy - Norway (HAU)",
    value: "HAU",
  },
  {
    label: "Kristiansand - Norway (KRS)",
    value: "KRS",
  },
  {
    label: "Kristiansand, Kjevik - Norway (KRS)",
    value: "KRS",
  },
  {
    label: "Oslo - Norway (OSL)",
    value: "OSL",
  },
  {
    label: "Oslo, Gardermoen - Norway (OSL)",
    value: "OSL",
  },
  {
    label: "Oslo, Moss Rygge - Norway (RYG)",
    value: "RYG",
  },
  {
    label: "Oslo, Sandefjord Torp - Norway (TRF)",
    value: "TRF",
  },
  {
    label: "Stavanger - Norway (SVG)",
    value: "SVG",
  },
  {
    label: "Stavanger, Sola - Norway (SVG)",
    value: "SVG",
  },
  {
    label: "Tromso - Norway (TOS)",
    value: "TOS",
  },
  {
    label: "Tromso, Langnes - Norway (TOS)",
    value: "TOS",
  },
  {
    label: "Trondheim - Norway (TRD)",
    value: "TRD",
  },
  {
    label: "Trondheim, Vaernes - Norway (TRD)",
    value: "TRD",
  },
  {
    label: "Fagernes, Leirin - Norway (VDB)",
    value: "VDB",
  },
  {
    label: "Floro, Floro - Norway (FRO)",
    value: "FRO",
  },
  {
    label: "Forde, Bringeland - Norway (FDE)",
    value: "FDE",
  },
  {
    label: "Gol, Klanten Arpt - Norway (GLL)",
    value: "GLL",
  },
  {
    label: "Hamar, Stafsberg - Norway (HMR)",
    value: "HMR",
  },
  {
    label: "Hammerfest, Hammerfest - Norway (HFT)",
    value: "HFT",
  },
  {
    label: "Harstad-Narvik, Evenes Apt - Norway (EVE)",
    value: "EVE",
  },
  {
    label: "Hasvik, Hasvik - Norway (HAA)",
    value: "HAA",
  },
  {
    label: "Honningsvag, Valan - Norway (HVG)",
    value: "HVG",
  },
  {
    label: "Kirkenes, Hoybuktmoen - Norway (KKN)",
    value: "KKN",
  },
  {
    label: "Lakselv, Banak - Norway (LKL)",
    value: "LKL",
  },
  {
    label: "Leknes, Leknes - Norway (LKN)",
    value: "LKN",
  },
  {
    label: "Longyearbyen, Svalbard - Norway (LYR)",
    value: "LYR",
  },
  {
    label: "Maloy Harbour, Maloy Harbour - Norway (QFQ)",
    value: "QFQ",
  },
  {
    label: "Mehamn, Mehamn - Norway (MEH)",
    value: "MEH",
  },
  {
    label: "Mo I Rana, Rossvoll - Norway (MQN)",
    value: "MQN",
  },
  {
    label: "Molde, Aro - Norway (MOL)",
    value: "MOL",
  },
  {
    label: "Mosjoen, Kjaerstad - Norway (MJF)",
    value: "MJF",
  },
  {
    label: "Namsos, Namsos - Norway (OSY)",
    value: "OSY",
  },
  {
    label: "Narvik, Framnes - Norway (NVK)",
    value: "NVK",
  },
  {
    label: "Notodden, Notodden - Norway (NTB)",
    value: "NTB",
  },
  {
    label: "Orland, Orland - Norway (OLA)",
    value: "OLA",
  },
  {
    label: "Orsta Volda, Hovden - Norway (HOV)",
    value: "HOV",
  },
  {
    label: "Roros, Roros - Norway (RRS)",
    value: "RRS",
  },
  {
    label: "Rorvik, Ryum - Norway (RVK)",
    value: "RVK",
  },
  {
    label: "Rost, Rost - Norway (RET)",
    value: "RET",
  },
  {
    label: "Sandane, Anda - Norway (SDN)",
    value: "SDN",
  },
  {
    label: "Sandnessjoen, Stokka - Norway (SSJ)",
    value: "SSJ",
  },
  {
    label: "Selje Harbour, Selje Harbour - Norway (QFK)",
    value: "QFK",
  },
  {
    label: "Skien, Geiteryggen - Norway (SKE)",
    value: "SKE",
  },
  {
    label: "Sogndal, Haukasen - Norway (SOG)",
    value: "SOG",
  },
  {
    label: "Sorkjosen, Sorkjosen - Norway (SOJ)",
    value: "SOJ",
  },
  {
    label: "Stokmarknes, Skagen - Norway (SKN)",
    value: "SKN",
  },
  {
    label: "Stord, Sorstokken - Norway (SRP)",
    value: "SRP",
  },
  {
    label: "Svolvaer, Helle - Norway (SVJ)",
    value: "SVJ",
  },
  {
    label: "Vadso, Vadso - Norway (VDS)",
    value: "VDS",
  },
  {
    label: "Vaeroy, Heliport - Norway (VRY)",
    value: "VRY",
  },
  {
    label: "Vardo, Svartnes - Norway (VAW)",
    value: "VAW",
  },
  {
    label: "Bahja, Bahja - Oman (BJQ)",
    value: "BJQ",
  },
  {
    label: "Diba Al Bayah, Diba Al Bayah - Oman (BYB)",
    value: "BYB",
  },
  {
    label: "Duqm, Jaaluni - Oman (JNJ)",
    value: "JNJ",
  },
  {
    label: "Fahud, Fahud - Oman (FAU)",
    value: "FAU",
  },
  {
    label: "Khasab, Khasab - Oman (KHS)",
    value: "KHS",
  },
  {
    label: "Lekhwair, Lekhwair - Oman (LKW)",
    value: "LKW",
  },
  {
    label: "Marmul, Marmul - Oman (OMM)",
    value: "OMM",
  },
  {
    label: "Masirah, Air Base - Oman (MSH)",
    value: "MSH",
  },
  {
    label: "Qarn Alam, Qarn Alam - Oman (RNM)",
    value: "RNM",
  },
  {
    label: "Saih Rawl, Saih Rawl - Oman (AHW)",
    value: "AHW",
  },
  {
    label: "Salalah - Oman (SLL)",
    value: "SLL",
  },
  {
    label: "Salalah, Salalah - Oman (SLL)",
    value: "SLL",
  },
  {
    label: "Thumrait - Oman (TTH)",
    value: "TTH",
  },
  {
    label: "Thumrait, Air Base - Oman (TTH)",
    value: "TTH",
  },
  {
    label: "Sur - Oman (SUH)",
    value: "SUH",
  },
  {
    label: "Sur, Sur - Oman (SUH)",
    value: "SUH",
  },
  {
    label: "Sohar - Oman (OHS)",
    value: "OHS",
  },
  {
    label: "Sohar, Sohar - Oman (OHS)",
    value: "OHS",
  },
  {
    label: "Muscat - Oman (MCT)",
    value: "MCT",
  },
  {
    label: "Muscat, Muscat International - Oman (MCT)",
    value: "MCT",
  },
  {
    label: "Mukhaizna - Oman (UKH)",
    value: "UKH",
  },
  {
    label: "Mukhaizna, Mukhaizna - Oman (UKH)",
    value: "UKH",
  },
  {
    label: "Duqm - Oman (DQM)",
    value: "DQM",
  },
  {
    label: "Duqm, International - Oman (DQM)",
    value: "DQM",
  },
  {
    label: "Buraimi - Oman (RMB)",
    value: "RMB",
  },
  {
    label: "Buraimi, Buraimi - Oman (RMB)",
    value: "RMB",
  },
  {
    label: "Adam - Oman (AOM)",
    value: "AOM",
  },
  {
    label: "Adam, Adam - Oman (AOM)",
    value: "AOM",
  },
  {
    label: "Attock, Minhas - Pakistan (ATG)",
    value: "ATG",
  },
  {
    label: "Badin, Talhar - Pakistan (BDN)",
    value: "BDN",
  },
  {
    label: "Bahawalpur, Bahawalpur - Pakistan (BHV)",
    value: "BHV",
  },
  {
    label: "Bannu, Bannu - Pakistan (BNP)",
    value: "BNP",
  },
  {
    label: "Bhurban, Heliport - Pakistan (BHC)",
    value: "BHC",
  },
  {
    label: "Chilas, Chilas - Pakistan (CHB)",
    value: "CHB",
  },
  {
    label: "Chitral, Chitral - Pakistan (CJL)",
    value: "CJL",
  },
  {
    label: "Dadu, Dadu - Pakistan (DDU)",
    value: "DDU",
  },
  {
    label: "Dalbandin, Dalbandin - Pakistan (DBA)",
    value: "DBA",
  },
  {
    label: "Dera Ghazi Khan, International - Pakistan (DEA)",
    value: "DEA",
  },
  {
    label: "Dera Ismail Khan, Dera Ismail Khan - Pakistan (DSK)",
    value: "DSK",
  },
  {
    label: "Faisalabad, International - Pakistan (LYP)",
    value: "LYP",
  },
  {
    label: "Gilgit, Gilgit - Pakistan (GIL)",
    value: "GIL",
  },
  {
    label: "Gujrat, Gujrat - Pakistan (GRT)",
    value: "GRT",
  },
  {
    label: "Gwadar, International - Pakistan (GWD)",
    value: "GWD",
  },
  {
    label: "Hyderabad, Hyderabad - Pakistan (HDD)",
    value: "HDD",
  },
  {
    label: "Islamabad, Benazir Bhutto Intl - Pakistan (ISB)",
    value: "ISB",
  },
  {
    label: "Jacobabad, Jacobabad - Pakistan (JAG)",
    value: "JAG",
  },
  {
    label: "Jiwani, Jiwani - Pakistan (JIW)",
    value: "JIW",
  },
  {
    label: "Kadanwari, Thar - Pakistan (KCF)",
    value: "KCF",
  },
  {
    label: "Karachi, Jinnah Intl - Pakistan (KHI)",
    value: "KHI",
  },
  {
    label: "Khuzdar, Khuzdar - Pakistan (KDD)",
    value: "KDD",
  },
  {
    label: "Kohat, Kohat - Pakistan (OHT)",
    value: "OHT",
  },
  {
    label: "Lahore, Allama Iqbal Intl - Pakistan (LHE)",
    value: "LHE",
  },
  {
    label: "Lora Lai, Lora Lai - Pakistan (LRG)",
    value: "LRG",
  },
  {
    label: "Mianwali, Mianwali - Pakistan (MWD)",
    value: "MWD",
  },
  {
    label: "Mirpur Khas, Sinhri - Pakistan (MPD)",
    value: "MPD",
  },
  {
    label: "Mohenjodaro, Mohenjodaro - Pakistan (MJD)",
    value: "MJD",
  },
  {
    label: "Multan, International - Pakistan (MUX)",
    value: "MUX",
  },
  {
    label: "Muzaffarabad, Muzaffarabad - Pakistan (MFG)",
    value: "MFG",
  },
  {
    label: "Nawabshah, Nawabshah - Pakistan (WNS)",
    value: "WNS",
  },
  {
    label: "Nushki, Nushki - Pakistan (NHS)",
    value: "NHS",
  },
  {
    label: "Off-Line Point, Mangla - Pakistan (XJM)",
    value: "XJM",
  },
  {
    label: "Ormara, Ormara - Pakistan (ORW)",
    value: "ORW",
  },
  {
    label: "Panjgur, Panjgur - Pakistan (PJG)",
    value: "PJG",
  },
  {
    label: "Para Chinar, Parachinar - Pakistan (PAJ)",
    value: "PAJ",
  },
  {
    label: "Pasni, Pasni - Pakistan (PSI)",
    value: "PSI",
  },
  {
    label: "Peshawar, Bacha Khan Intl - Pakistan (PEW)",
    value: "PEW",
  },
  {
    label: "Quetta, International - Pakistan (UET)",
    value: "UET",
  },
  {
    label: "Rahim Yar Khan, Shaikh Zayed Intl - Pakistan (RYK)",
    value: "RYK",
  },
  {
    label: "Rawala Kot, Rawala Kot - Pakistan (RAZ)",
    value: "RAZ",
  },
  {
    label: "Sahiwal, Sahiwal - Pakistan (SWN)",
    value: "SWN",
  },
  {
    label: "Saidu Sharif, Saidu Sharif - Pakistan (SDT)",
    value: "SDT",
  },
  {
    label: "Sargodha, Bhagatanwala - Pakistan (BHW)",
    value: "BHW",
  },
  {
    label: "Sargodha, Mushaf Ab - Pakistan (SGI)",
    value: "SGI",
  },
  {
    label: "Sawan, Sawan - Pakistan (RZS)",
    value: "RZS",
  },
  {
    label: "Sehwen Sharif, Sehwan Sharif - Pakistan (SYW)",
    value: "SYW",
  },
  {
    label: "Sialkot, International - Pakistan (SKT)",
    value: "SKT",
  },
  {
    label: "Sibi, Sibi - Pakistan (SBQ)",
    value: "SBQ",
  },
  {
    label: "Skardu, Skardu - Pakistan (KDU)",
    value: "KDU",
  },
  {
    label: "Sui, Sui - Pakistan (SUL)",
    value: "SUL",
  },
  {
    label: "Sukkur, Sukkur - Pakistan (SKZ)",
    value: "SKZ",
  },
  {
    label: "Taftan, Taftan - Pakistan (TFT)",
    value: "TFT",
  },
  {
    label: "Tarbela, Tarbela Dam - Pakistan (TLB)",
    value: "TLB",
  },
  {
    label: "Turbat, International - Pakistan (TUK)",
    value: "TUK",
  },
  {
    label: "Wana, Wana - Pakistan (WAF)",
    value: "WAF",
  },
  {
    label: "Zamzama, Zamzama Airport - Pakistan (ZIZ)",
    value: "ZIZ",
  },
  {
    label: "Zhob, Zhob - Pakistan (PZH)",
    value: "PZH",
  },
  {
    label: "Koror, Palau International - Palau Islands (ROR)",
    value: "ROR",
  },
  {
    label: "Gaza, Yasser Arafat Intl - Palestine - State Of (GZA)",
    value: "GZA",
  },
  {
    label: "Achutupo, Achutupo - Panama (ACU)",
    value: "ACU",
  },
  {
    label: "Ailigandi, Ailigandi - Panama (AIL)",
    value: "AIL",
  },
  {
    label: "Bahia Pinas, Bahia Pina - Panama (BFQ)",
    value: "BFQ",
  },
  {
    label: "Balboa, Panama Pacifico - Panama (BLB)",
    value: "BLB",
  },
  {
    label: "Bocas Del Toro, Isla Colon Intl - Panama (BOC)",
    value: "BOC",
  },
  {
    label: "Caledonia, Caledonia - Panama (CDE)",
    value: "CDE",
  },
  {
    label: "Carti, Carti - Panama (CTE)",
    value: "CTE",
  },
  {
    label: "Changuinola, Manuel Nino Intl - Panama (CHX)",
    value: "CHX",
  },
  {
    label: "Chitre, Alonso Valderrama - Panama (CTD)",
    value: "CTD",
  },
  {
    label: "Colon, Enrique Adolfo Jimenez - Panama (ONX)",
    value: "ONX",
  },
  {
    label: "Contadora, Contadora Island - Panama (OTD)",
    value: "OTD",
  },
  {
    label: "Corazon De Jesus, Corazon De Jesus - Panama (CZJ)",
    value: "CZJ",
  },
  {
    label: "David, Enrique Malek Intl - Panama (DAV)",
    value: "DAV",
  },
  {
    label: "El Porvenir, El Porvenir - Panama (PVE)",
    value: "PVE",
  },
  {
    label: "El Real, El Real - Panama (ELE)",
    value: "ELE",
  },
  {
    label: "Fort Kobbe, Howard Afb - Panama (HOW)",
    value: "HOW",
  },
  {
    label: "Garachine, Garachine - Panama (GHE)",
    value: "GHE",
  },
  {
    label: "Isla Viveros, Viveros Island - Panama (IVI)",
    value: "IVI",
  },
  {
    label: "Jaque, Jaque - Panama (JQE)",
    value: "JQE",
  },
  {
    label: "La Palma, Ramon Xatruch - Panama (PLP)",
    value: "PLP",
  },
  {
    label: "Mamitupo, Mamitupo - Panama (MPI)",
    value: "MPI",
  },
  {
    label: "Mulatupo, Mulatupo - Panama (MPP)",
    value: "MPP",
  },
  {
    label: "Nargana, Nargana - Panama (NGN)",
    value: "NGN",
  },
  {
    label: "Panama City, Tocumen Intl - Panama (PTY)",
    value: "PTY",
  },
  {
    label: "Panama City, Tocumen Intl - Panama (PTY)",
    value: "PTY",
  },
  {
    label: "Panama City, Marcos A Gelabert Intl - Panama (PAC)",
    value: "PAC",
  },
  {
    label: "Pedasi, Capt.J.Montenegro - Panama (PDM)",
    value: "PDM",
  },
  {
    label: "Playon Chico, Playon Chico - Panama (PYC)",
    value: "PYC",
  },
  {
    label: "Puerto Armuellas, Puerto Armuelles - Panama (AML)",
    value: "AML",
  },
  {
    label: "Puerto Obaldia, Puerto Obaldia - Panama (PUE)",
    value: "PUE",
  },
  {
    label: "Rio Hato, Scarlett Martinez Intl - Panama (RIH)",
    value: "RIH",
  },
  {
    label: "Rio Sidra, Rio Sidra - Panama (RSI)",
    value: "RSI",
  },
  {
    label: "Rio Tigre, Rio Tigre - Panama (RIT)",
    value: "RIT",
  },
  {
    label: "Sambu, Sambu - Panama (SAX)",
    value: "SAX",
  },
  {
    label: "San Blas, Wannukandi - Panama (NBL)",
    value: "NBL",
  },
  {
    label: "San Jose Island , San Jose Island - Panama (SIC)",
    value: "SIC",
  },
  {
    label: "San Miguel, San Miguel - Panama (NMG)",
    value: "NMG",
  },
  {
    label: "Santa Fe, Santa Fe - Panama (SFW)",
    value: "SFW",
  },
  {
    label: "Santiago, Ruben Cantu - Panama (SYP)",
    value: "SYP",
  },
  {
    label: "Ticantiki, Ticantiqui - Panama (TJC)",
    value: "TJC",
  },
  {
    label: "Tubala, Tubuala - Panama (TUW)",
    value: "TUW",
  },
  {
    label: "Tupile, Tupile - Panama (TUE)",
    value: "TUE",
  },
  {
    label: "Ustupo, Ustupo - Panama (UTU)",
    value: "UTU",
  },
  {
    label: "Ustupu Island, Ustupo Ogobsucum - Panama (OGM)",
    value: "OGM",
  },
  {
    label: "Yaviza, Yaviza - Panama (PYV)",
    value: "PYV",
  },
  {
    label: "Abau, Abau - Papua New Guinea (ABW)",
    value: "ABW",
  },
  {
    label: "Afore, Afore - Papua New Guinea (AFR)",
    value: "AFR",
  },
  {
    label: "Agaun, Agaun - Papua New Guinea (AUP)",
    value: "AUP",
  },
  {
    label: "Aiome, Aiome - Papua New Guinea (AIE)",
    value: "AIE",
  },
  {
    label: "Aitape, Aitape Airstrip - Papua New Guinea (ATP)",
    value: "ATP",
  },
  {
    label: "Aiyura, Aiyura - Papua New Guinea (AYU)",
    value: "AYU",
  },
  {
    label: "Alotau, Gurney - Papua New Guinea (GUR)",
    value: "GUR",
  },
  {
    label: "Ama, Ama - Papua New Guinea (AMF)",
    value: "AMF",
  },
  {
    label: "Amanab, Amanab - Papua New Guinea (AMU)",
    value: "AMU",
  },
  {
    label: "Amazon Bay, Amazon Bay - Papua New Guinea (AZB)",
    value: "AZB",
  },
  {
    label: "Amboin, Amboin - Papua New Guinea (AMG)",
    value: "AMG",
  },
  {
    label: "Ambunti, Ambunti - Papua New Guinea (AUJ)",
    value: "AUJ",
  },
  {
    label: "Angoram, Angoram - Papua New Guinea (AGG)",
    value: "AGG",
  },
  {
    label: "Anguganak, Anguganak - Papua New Guinea (AKG)",
    value: "AKG",
  },
  {
    label: "Annanberg, Annanberg - Papua New Guinea (AOB)",
    value: "AOB",
  },
  {
    label: "April River, April River - Papua New Guinea (APR)",
    value: "APR",
  },
  {
    label: "Aragip, Aragip - Papua New Guinea (ARP)",
    value: "ARP",
  },
  {
    label: "Arawa, Arawa - Papua New Guinea (RAW)",
    value: "RAW",
  },
  {
    label: "Aroa, Aroa - Papua New Guinea (AOA)",
    value: "AOA",
  },
  {
    label: "Arona, Arona - Papua New Guinea (AON)",
    value: "AON",
  },
  {
    label: "Asapa, Asapa - Papua New Guinea (APP)",
    value: "APP",
  },
  {
    label: "Aseki, Aseki - Papua New Guinea (AEK)",
    value: "AEK",
  },
  {
    label: "Asirim, Asirim - Papua New Guinea (ASZ)",
    value: "ASZ",
  },
  {
    label: "Atkamba, Atkamba - Papua New Guinea (ABP)",
    value: "ABP",
  },
  {
    label: "Aua Island, Aua Island - Papua New Guinea (AUI)",
    value: "AUI",
  },
  {
    label: "Aumo, Aumo - Papua New Guinea (AUV)",
    value: "AUV",
  },
  {
    label: "Awaba, Awaba - Papua New Guinea (AWB)",
    value: "AWB",
  },
  {
    label: "Awar, Awar - Papua New Guinea (AWR)",
    value: "AWR",
  },
  {
    label: "Baibara, Baibara - Papua New Guinea (BAP)",
    value: "BAP",
  },
  {
    label: "Baimuru, Baimuru - Papua New Guinea (VMU)",
    value: "VMU",
  },
  {
    label: "Baindoung, Baindoung - Papua New Guinea (BDZ)",
    value: "BDZ",
  },
  {
    label: "Bali, Bali - Papua New Guinea (BAJ)",
    value: "BAJ",
  },
  {
    label: "Balimo, Balimo - Papua New Guinea (OPU)",
    value: "OPU",
  },
  {
    label: "Bambu, Bambu - Papua New Guinea (BCP)",
    value: "BCP",
  },
  {
    label: "Bamu, Bamu - Papua New Guinea (BMZ)",
    value: "BMZ",
  },
  {
    label: "Banz, Banz - Papua New Guinea (BNZ)",
    value: "BNZ",
  },
  {
    label: "Bapi, Bapi - Papua New Guinea (BPD)",
    value: "BPD",
  },
  {
    label: "Bawan, Bawan - Papua New Guinea (BWJ)",
    value: "BWJ",
  },
  {
    label: "Bensbach, Bensbach - Papua New Guinea (BSP)",
    value: "BSP",
  },
  {
    label: "Bereina, Bereina - Papua New Guinea (BEA)",
    value: "BEA",
  },
  {
    label: "Bewani, Bewani - Papua New Guinea (BWP)",
    value: "BWP",
  },
  {
    label: "Bialla, Bialla - Papua New Guinea (BAA)",
    value: "BAA",
  },
  {
    label: "Biaru, Biaru - Papua New Guinea (BRP)",
    value: "BRP",
  },
  {
    label: "Biliau, Biliau - Papua New Guinea (BIJ)",
    value: "BIJ",
  },
  {
    label: "Bimin, Bimin - Papua New Guinea (BIZ)",
    value: "BIZ",
  },
  {
    label: "Biniguni, Biniguni - Papua New Guinea (XBN)",
    value: "XBN",
  },
  {
    label: "Boana, Boana - Papua New Guinea (BNV)",
    value: "BNV",
  },
  {
    label: "Boang, Boang Island - Papua New Guinea (BOV)",
    value: "BOV",
  },
  {
    label: "Bodinumu, Bodinumu - Papua New Guinea (BNM)",
    value: "BNM",
  },
  {
    label: "Boku, Boku - Papua New Guinea (BOQ)",
    value: "BOQ",
  },
  {
    label: "Bolovip, Bolovip - Papua New Guinea (BVP)",
    value: "BVP",
  },
  {
    label: "Bomai, Bomai - Papua New Guinea (BMH)",
    value: "BMH",
  },
  {
    label: "Boridi, Boridi - Papua New Guinea (BPB)",
    value: "BPB",
  },
  {
    label: "Boset, Boset - Papua New Guinea (BOT)",
    value: "BOT",
  },
  {
    label: "Brahman, Braham - Papua New Guinea (BRH)",
    value: "BRH",
  },
  {
    label: "Buin, Buin - Papua New Guinea (UBI)",
    value: "UBI",
  },
  {
    label: "Buka, Buka - Papua New Guinea (BUA)",
    value: "BUA",
  },
  {
    label: "Bulolo, Bulolo - Papua New Guinea (BUL)",
    value: "BUL",
  },
  {
    label: "Bundi, Bundi - Papua New Guinea (BNT)",
    value: "BNT",
  },
  {
    label: "Bunsil, Bunsil - Papua New Guinea (BXZ)",
    value: "BXZ",
  },
  {
    label: "Cape Gloucester, Cape Gloucester - Papua New Guinea (CGC)",
    value: "CGC",
  },
  {
    label: "Cape Orford, Cape Orford - Papua New Guinea (CPI)",
    value: "CPI",
  },
  {
    label: "Cape Rodney, Cape Rodney - Papua New Guinea (CPN)",
    value: "CPN",
  },
  {
    label: "Cape Vogel, Cape Vogel - Papua New Guinea (CVL)",
    value: "CVL",
  },
  {
    label: "Chungribu, Chungrebu - Papua New Guinea (CVB)",
    value: "CVB",
  },
  {
    label: "Dalbertis, D'albertis - Papua New Guinea (DLB)",
    value: "DLB",
  },
  {
    label: "Daru, Daru - Papua New Guinea (DAU)",
    value: "DAU",
  },
  {
    label: "Daugo, Fishermans Heliport - Papua New Guinea (DGG)",
    value: "DGG",
  },
  {
    label: "Daup, Daup - Papua New Guinea (DAF)",
    value: "DAF",
  },
  {
    label: "Debepare, Debepare - Papua New Guinea (DBP)",
    value: "DBP",
  },
  {
    label: "Derim, Derim - Papua New Guinea (DER)",
    value: "DER",
  },
  {
    label: "Dinangat, Dinangat - Papua New Guinea (DNU)",
    value: "DNU",
  },
  {
    label: "Dios, Dios - Papua New Guinea (DOS)",
    value: "DOS",
  },
  {
    label: "Dodoima, Dodoima - Papua New Guinea (DDM)",
    value: "DDM",
  },
  {
    label: "Doini, Doini Island - Papua New Guinea (DOI)",
    value: "DOI",
  },
  {
    label: "Dorobisoro, Dorobisoro - Papua New Guinea (DOO)",
    value: "DOO",
  },
  {
    label: "Dumpu, Dumpu - Papua New Guinea (DPU)",
    value: "DPU",
  },
  {
    label: "Efogi, Efogi - Papua New Guinea (EFG)",
    value: "EFG",
  },
  {
    label: "Eia, Popondetta - Papua New Guinea (EIA)",
    value: "EIA",
  },
  {
    label: "Eliptamin, Eliptamin - Papua New Guinea (EPT)",
    value: "EPT",
  },
  {
    label: "Embessa, Embessa - Papua New Guinea (EMS)",
    value: "EMS",
  },
  {
    label: "Emirau, Emirau Island - Papua New Guinea (EMI)",
    value: "EMI",
  },
  {
    label: "Emo, Emo - Papua New Guinea (EMO)",
    value: "EMO",
  },
  {
    label: "Engati, Engati - Papua New Guinea (EGA)",
    value: "EGA",
  },
  {
    label: "Erave, Erave - Papua New Guinea (ERE)",
    value: "ERE",
  },
  {
    label: "Erume, Erume - Papua New Guinea (ERU)",
    value: "ERU",
  },
  {
    label: "Esa'ala, Esa'ala - Papua New Guinea (ESA)",
    value: "ESA",
  },
  {
    label: "Fane, Fane - Papua New Guinea (FNE)",
    value: "FNE",
  },
  {
    label: "Feramin, Feramin - Papua New Guinea (FRQ)",
    value: "FRQ",
  },
  {
    label: "Finschhafen, Finschhafen - Papua New Guinea (FIN)",
    value: "FIN",
  },
  {
    label: "Freida River, Freida River - Papua New Guinea (FAQ)",
    value: "FAQ",
  },
  {
    label: "Fulleborn, Fulleborn - Papua New Guinea (FUB)",
    value: "FUB",
  },
  {
    label: "Fuma, Fuma - Papua New Guinea (FUM)",
    value: "FUM",
  },
  {
    label: "Garaina, Garaina - Papua New Guinea (GAR)",
    value: "GAR",
  },
  {
    label: "Garasa, Garasa - Papua New Guinea (GRL)",
    value: "GRL",
  },
  {
    label: "Garuahi, Garuahi - Papua New Guinea (GRH)",
    value: "GRH",
  },
  {
    label: "Gasmata Island, Gasmata - Papua New Guinea (GMI)",
    value: "GMI",
  },
  {
    label: "Gasuke, Gasuke - Papua New Guinea (GBC)",
    value: "GBC",
  },
  {
    label: "Gewoia, Gewoia - Papua New Guinea (GEW)",
    value: "GEW",
  },
  {
    label: "Gnarowein, Gnarowein - Papua New Guinea (GWN)",
    value: "GWN",
  },
  {
    label: "Gonalia, Gonaili - Papua New Guinea (GOE)",
    value: "GOE",
  },
  {
    label: "Gora, Gora - Papua New Guinea (GOC)",
    value: "GOC",
  },
  {
    label: "Goroka, Goroka - Papua New Guinea (GKA)",
    value: "GKA",
  },
  {
    label: "Green River, Green River - Papua New Guinea (GVI)",
    value: "GVI",
  },
  {
    label: "Guari, Guari - Papua New Guinea (GUG)",
    value: "GUG",
  },
  {
    label: "Guasopa, Guasopa - Papua New Guinea (GAZ)",
    value: "GAZ",
  },
  {
    label: "Gulgubip, Gulgubip - Papua New Guinea (GLP)",
    value: "GLP",
  },
  {
    label: "Guriaso, Guriaso - Papua New Guinea (GUE)",
    value: "GUE",
  },
  {
    label: "Gusap, Gusap - Papua New Guinea (GAP)",
    value: "GAP",
  },
  {
    label: "Haelogo, Haelogo - Papua New Guinea (HEO)",
    value: "HEO",
  },
  {
    label: "Hatzfeldthaven, Hatzfeldthaven - Papua New Guinea (HAZ)",
    value: "HAZ",
  },
  {
    label: "Hawabango, Hawabango - Papua New Guinea (HWA)",
    value: "HWA",
  },
  {
    label: "Hayfields, Hayfield - Papua New Guinea (HYF)",
    value: "HYF",
  },
  {
    label: "Heiweni, Heiweni - Papua New Guinea (HNI)",
    value: "HNI",
  },
  {
    label: "Hivaro, Haivaro - Papua New Guinea (HIT)",
    value: "HIT",
  },
  {
    label: "Honinabi, Honinabi - Papua New Guinea (HNN)",
    value: "HNN",
  },
  {
    label: "Hoskins, Hoskins - Papua New Guinea (HKN)",
    value: "HKN",
  },
  {
    label: "Iamalele, Iamalele - Papua New Guinea (IMA)",
    value: "IMA",
  },
  {
    label: "Iaura, Iaura - Papua New Guinea (IAU)",
    value: "IAU",
  },
  {
    label: "Iboki, Iboki - Papua New Guinea (IBI)",
    value: "IBI",
  },
  {
    label: "Ihu, Ihu - Papua New Guinea (IHU)",
    value: "IHU",
  },
  {
    label: "Ileg, Ileg - Papua New Guinea (ILX)",
    value: "ILX",
  },
  {
    label: "Imane, Imane - Papua New Guinea (IMN)",
    value: "IMN",
  },
  {
    label: "Imonda, Imonda - Papua New Guinea (IMD)",
    value: "IMD",
  },
  {
    label: "Indagen, Indagen - Papua New Guinea (IDN)",
    value: "IDN",
  },
  {
    label: "Inus, Inus - Papua New Guinea (IUS)",
    value: "IUS",
  },
  {
    label: "Iokea, Iokea - Papua New Guinea (IOK)",
    value: "IOK",
  },
  {
    label: "Ioma, Ioma - Papua New Guinea (IOP)",
    value: "IOP",
  },
  {
    label: "Itokama, Itokama - Papua New Guinea (ITK)",
    value: "ITK",
  },
  {
    label: "Jacquinot Bay, Jacquinot Bay - Papua New Guinea (JAQ)",
    value: "JAQ",
  },
  {
    label: "Josephstaal, Josephstaal - Papua New Guinea (JOP)",
    value: "JOP",
  },
  {
    label: "Kabwum, Kabwum - Papua New Guinea (KBM)",
    value: "KBM",
  },
  {
    label: "Kagi, Kagi - Papua New Guinea (KGW)",
    value: "KGW",
  },
  {
    label: "Kagua, Kagua - Papua New Guinea (AGK)",
    value: "AGK",
  },
  {
    label: "Kaiapit, Kaiapit - Papua New Guinea (KIA)",
    value: "KIA",
  },
  {
    label: "Kaintiba, Kaintiba - Papua New Guinea (KZF)",
    value: "KZF",
  },
  {
    label: "Kamberatoro, Kamberatoro - Papua New Guinea (KDQ)",
    value: "KDQ",
  },
  {
    label: "Kamina, Kamina - Papua New Guinea (KMF)",
    value: "KMF",
  },
  {
    label: "Kamiraba, Kamiraba - Papua New Guinea (KJU)",
    value: "KJU",
  },
  {
    label: "Kamulai, Kamulai - Papua New Guinea (KAQ)",
    value: "KAQ",
  },
  {
    label: "Kamusi, Kamusi - Papua New Guinea (KUY)",
    value: "KUY",
  },
  {
    label: "Kanabea, Kanabea - Papua New Guinea (KEX)",
    value: "KEX",
  },
  {
    label: "Kanainj, Kanainj - Papua New Guinea (KNE)",
    value: "KNE",
  },
  {
    label: "Kandep, Kandep - Papua New Guinea (KDP)",
    value: "KDP",
  },
  {
    label: "Kandrian, Kandrian - Papua New Guinea (KDR)",
    value: "KDR",
  },
  {
    label: "Kanua, Kunua - Papua New Guinea (KTK)",
    value: "KTK",
  },
  {
    label: "Kapal, Kapal - Papua New Guinea (KPL)",
    value: "KPL",
  },
  {
    label: "Kar, Kar - Papua New Guinea (KAK)",
    value: "KAK",
  },
  {
    label: "Kar Kar, Karkar Island - Papua New Guinea (KRX)",
    value: "KRX",
  },
  {
    label: "Karato, Karato - Papua New Guinea (KAF)",
    value: "KAF",
  },
  {
    label: "Karawari, Karawari - Papua New Guinea (KRJ)",
    value: "KRJ",
  },
  {
    label: "Karimui, Karimui - Papua New Guinea (KMR)",
    value: "KMR",
  },
  {
    label: "Karoola, Karoola - Papua New Guinea (KXR)",
    value: "KXR",
  },
  {
    label: "Kasanombe, Kasanombe - Papua New Guinea (KSB)",
    value: "KSB",
  },
  {
    label: "Kavieng, Kavieng - Papua New Guinea (KVG)",
    value: "KVG",
  },
  {
    label: "Kawito, Kawito - Papua New Guinea (KWO)",
    value: "KWO",
  },
  {
    label: "Keglsugl, Keglsugl - Papua New Guinea (KEG)",
    value: "KEG",
  },
  {
    label: "Kelanoa, Kelanoa - Papua New Guinea (KNL)",
    value: "KNL",
  },
  {
    label: "Kerau, Kerau - Papua New Guinea (KRU)",
    value: "KRU",
  },
  {
    label: "Kerema, Kerema - Papua New Guinea (KMA)",
    value: "KMA",
  },
  {
    label: "Kieta, Aropa - Papua New Guinea (KIE)",
    value: "KIE",
  },
  {
    label: "Kikinonda, Kikinonda - Papua New Guinea (KIZ)",
    value: "KIZ",
  },
  {
    label: "Kikori, Kikori - Papua New Guinea (KRI)",
    value: "KRI",
  },
  {
    label: "Kira, Kira - Papua New Guinea (KIQ)",
    value: "KIQ",
  },
  {
    label: "Kisengan, Kisengam - Papua New Guinea (KSG)",
    value: "KSG",
  },
  {
    label: "Kitava, Kitava Island - Papua New Guinea (KVE)",
    value: "KVE",
  },
  {
    label: "Kiunga, Kiunga - Papua New Guinea (UNG)",
    value: "UNG",
  },
  {
    label: "Kiwai Island, Kiwai Island - Papua New Guinea (KWX)",
    value: "KWX",
  },
  {
    label: "Koinambe, Koinambe - Papua New Guinea (KMB)",
    value: "KMB",
  },
  {
    label: "Kokoda, Kokoda - Papua New Guinea (KKD)",
    value: "KKD",
  },
  {
    label: "Kokoro, Kakoro - Papua New Guinea (KOR)",
    value: "KOR",
  },
  {
    label: "Kol, Kol - Papua New Guinea (KQL)",
    value: "KQL",
  },
  {
    label: "Komaio, Komaio - Papua New Guinea (KCJ)",
    value: "KCJ",
  },
  {
    label: "Komako, Komako - Papua New Guinea (HOC)",
    value: "HOC",
  },
  {
    label: "Komo Manda, Komo - Papua New Guinea (KOM)",
    value: "KOM",
  },
  {
    label: "Kompiam, Kompiam - Papua New Guinea (KPM)",
    value: "KPM",
  },
  {
    label: "Konge, Konge - Papua New Guinea (KGB)",
    value: "KGB",
  },
  {
    label: "Kopiago, Kopiago - Papua New Guinea (KPA)",
    value: "KPA",
  },
  {
    label: "Koroba, Koroba - Papua New Guinea (KDE)",
    value: "KDE",
  },
  {
    label: "Kosipe, Kosipe - Papua New Guinea (KSP)",
    value: "KSP",
  },
  {
    label: "Kundiawa, Chimbu - Papua New Guinea (CMU)",
    value: "CMU",
  },
  {
    label: "Kungum, Kungim - Papua New Guinea (KGM)",
    value: "KGM",
  },
  {
    label: "Kupiano, Kupiano - Papua New Guinea (KUP)",
    value: "KUP",
  },
  {
    label: "Kuri, Kuri - Papua New Guinea (KUQ)",
    value: "KUQ",
  },
  {
    label: "Kurwina, Kurwina - Papua New Guinea (KWV)",
    value: "KWV",
  },
  {
    label: "Kuyol, Kuyol - Papua New Guinea (KUX)",
    value: "KUX",
  },
  {
    label: "Lablab, Lab Lab - Papua New Guinea (LAB)",
    value: "LAB",
  },
  {
    label: "Lae, Nadzab - Papua New Guinea (LAE)",
    value: "LAE",
  },
  {
    label: "Laiagam, Laiagam - Papua New Guinea (LGM)",
    value: "LGM",
  },
  {
    label: "Lake Murray, Lake Murray - Papua New Guinea (LMY)",
    value: "LMY",
  },
  {
    label: "Lamassa, Lamassa - Papua New Guinea (LMG)",
    value: "LMG",
  },
  {
    label: "Langimar, Langimar - Papua New Guinea (LNM)",
    value: "LNM",
  },
  {
    label: "Lehu, Lehu - Papua New Guinea (LHP)",
    value: "LHP",
  },
  {
    label: "Leitre, Leitre - Papua New Guinea (LTF)",
    value: "LTF",
  },
  {
    label: "Lengbati, Lengbati - Papua New Guinea (LNC)",
    value: "LNC",
  },
  {
    label: "Leron Plains, Leron Plains - Papua New Guinea (LPN)",
    value: "LPN",
  },
  {
    label: "Lese, Lese - Papua New Guinea (LNG)",
    value: "LNG",
  },
  {
    label: "Lihir Island, Kunaye - Papua New Guinea (LNV)",
    value: "LNV",
  },
  {
    label: "Linga Linga, Linga Linga - Papua New Guinea (LGN)",
    value: "LGN",
  },
  {
    label: "Loani, Loani - Papua New Guinea (LNQ)",
    value: "LNQ",
  },
  {
    label: "Long Island, Long Island - Papua New Guinea (LSJ)",
    value: "LSJ",
  },
  {
    label: "Losuia, Lousia - Papua New Guinea (LSA)",
    value: "LSA",
  },
  {
    label: "Lowai, Lowai - Papua New Guinea (LWI)",
    value: "LWI",
  },
  {
    label: "Lumi, Lumi - Papua New Guinea (LMI)",
    value: "LMI",
  },
  {
    label: "Madang, Madang - Papua New Guinea (MAG)",
    value: "MAG",
  },
  {
    label: "Makini, Makini - Papua New Guinea (MPG)",
    value: "MPG",
  },
  {
    label: "Mal, Mal Island - Papua New Guinea (MMV)",
    value: "MMV",
  },
  {
    label: "Malalaua, Malalaua - Papua New Guinea (MLQ)",
    value: "MLQ",
  },
  {
    label: "Malam, Malam - Papua New Guinea (MQO)",
    value: "MQO",
  },
  {
    label: "Malekolon, Malekolon - Papua New Guinea (MKN)",
    value: "MKN",
  },
  {
    label: "Mamai, Mamai - Papua New Guinea (MAP)",
    value: "MAP",
  },
  {
    label: "Manare, Manari - Papua New Guinea (MRM)",
    value: "MRM",
  },
  {
    label: "Manetai, Manetai - Papua New Guinea (MVI)",
    value: "MVI",
  },
  {
    label: "Manga, Manga - Papua New Guinea (MGP)",
    value: "MGP",
  },
  {
    label: "Manguna, Manguna - Papua New Guinea (MFO)",
    value: "MFO",
  },
  {
    label: "Manumu, Manumu - Papua New Guinea (UUU)",
    value: "UUU",
  },
  {
    label: "Manus Island, Momote - Papua New Guinea (MAS)",
    value: "MAS",
  },
  {
    label: "Mapoda, Mapoda - Papua New Guinea (MPF)",
    value: "MPF",
  },
  {
    label: "Mapua, Mabua - Papua New Guinea (MPU)",
    value: "MPU",
  },
  {
    label: "Maramuni, Maramuni - Papua New Guinea (MWI)",
    value: "MWI",
  },
  {
    label: "Marawaka, Marawaka - Papua New Guinea (MWG)",
    value: "MWG",
  },
  {
    label: "Margarima, Margarima - Papua New Guinea (MGG)",
    value: "MGG",
  },
  {
    label: "Maron, Maron Island - Papua New Guinea (MNP)",
    value: "MNP",
  },
  {
    label: "Masa, Masa - Papua New Guinea (MBV)",
    value: "MBV",
  },
  {
    label: "May River, May River - Papua New Guinea (MRH)",
    value: "MRH",
  },
  {
    label: "Mendi, Mendi - Papua New Guinea (MDU)",
    value: "MDU",
  },
  {
    label: "Menyamya, Menyamya - Papua New Guinea (MYX)",
    value: "MYX",
  },
  {
    label: "Meselia, Meselia - Papua New Guinea (MFZ)",
    value: "MFZ",
  },
  {
    label: "Mindik, Mindik - Papua New Guinea (MXK)",
    value: "MXK",
  },
  {
    label: "Minj, Minj - Papua New Guinea (MZN)",
    value: "MZN",
  },
  {
    label: "Misima Island, Misima Island - Papua New Guinea (MIS)",
    value: "MIS",
  },
  {
    label: "Miyanmin, Miyanmin - Papua New Guinea (MPX)",
    value: "MPX",
  },
  {
    label: "Moki, Moki - Papua New Guinea (MJJ)",
    value: "MJJ",
  },
  {
    label: "Morehead, Morehead - Papua New Guinea (MHY)",
    value: "MHY",
  },
  {
    label: "Moro, Moro - Papua New Guinea (MXH)",
    value: "MXH",
  },
  {
    label: "Morobe, Morobe - Papua New Guinea (OBM)",
    value: "OBM",
  },
  {
    label: "Mougulu, Mougulu - Papua New Guinea (GUV)",
    value: "GUV",
  },
  {
    label: "Mount Aue, Mount Aue - Papua New Guinea (UAE)",
    value: "UAE",
  },
  {
    label: "Mount Hagen, Kagamuga - Papua New Guinea (HGU)",
    value: "HGU",
  },
  {
    label: "Munbil, Munbil - Papua New Guinea (LNF)",
    value: "LNF",
  },
  {
    label: "Munduku, Munduku - Papua New Guinea (MDM)",
    value: "MDM",
  },
  {
    label: "Mussau, Mussau Island - Papua New Guinea (MWU)",
    value: "MWU",
  },
  {
    label: "Nadunumu, Nadunumu - Papua New Guinea (NDN)",
    value: "NDN",
  },
  {
    label: "Namatanai, Namatanai - Papua New Guinea (ATN)",
    value: "ATN",
  },
  {
    label: "Nambaiyufa, Nambaiyufa - Papua New Guinea (NBA)",
    value: "NBA",
  },
  {
    label: "Namudi, Namudi - Papua New Guinea (NDI)",
    value: "NDI",
  },
  {
    label: "Nankina, Nankina - Papua New Guinea (NKN)",
    value: "NKN",
  },
  {
    label: "Naoro, Naoro - Papua New Guinea (NOO)",
    value: "NOO",
  },
  {
    label: "Negarbo, Negarbo - Papua New Guinea (GBF)",
    value: "GBF",
  },
  {
    label: "Ningerum, Ningerum - Papua New Guinea (NGR)",
    value: "NGR",
  },
  {
    label: "Nipa, Nipa - Papua New Guinea (NPG)",
    value: "NPG",
  },
  {
    label: "Nissan Island, Nissan Island - Papua New Guinea (IIS)",
    value: "IIS",
  },
  {
    label: "Nomad River, Nomad River - Papua New Guinea (NOM)",
    value: "NOM",
  },
  {
    label: "Nomane, Nomane - Papua New Guinea (NMN)",
    value: "NMN",
  },
  {
    label: "Nowata, Nowata - Papua New Guinea (NWT)",
    value: "NWT",
  },
  {
    label: "Nuguria, Nuguria Islands - Papua New Guinea (NUG)",
    value: "NUG",
  },
  {
    label: "Nuku, Nuku - Papua New Guinea (UKU)",
    value: "UKU",
  },
  {
    label: "Nutuve, Nutuve - Papua New Guinea (NUT)",
    value: "NUT",
  },
  {
    label: "Obo, Obo - Papua New Guinea (OBX)",
    value: "OBX",
  },
  {
    label: "Ogeranang, Ogeramnang - Papua New Guinea (OGE)",
    value: "OGE",
  },
  {
    label: "Okao, Okao - Papua New Guinea (OKV)",
    value: "OKV",
  },
  {
    label: "Oksapmin, Oksapmin - Papua New Guinea (OKP)",
    value: "OKP",
  },
  {
    label: "Olsobip, Olsobip - Papua New Guinea (OLQ)",
    value: "OLQ",
  },
  {
    label: "Omkalai, Omkalai - Papua New Guinea (OML)",
    value: "OML",
  },
  {
    label: "Omora, Omora - Papua New Guinea (OSE)",
    value: "OSE",
  },
  {
    label: "Ononge, Ononge - Papua New Guinea (ONB)",
    value: "ONB",
  },
  {
    label: "Open Bay, Open Bay - Papua New Guinea (OPB)",
    value: "OPB",
  },
  {
    label: "Oram, Oram - Papua New Guinea (RAX)",
    value: "RAX",
  },
  {
    label: "Oria, Oria - Papua New Guinea (OTY)",
    value: "OTY",
  },
  {
    label: "Ossima, Ossima - Papua New Guinea (OSG)",
    value: "OSG",
  },
  {
    label: "Paiela, Paiela - Papua New Guinea (PLE)",
    value: "PLE",
  },
  {
    label: "Pambwa, Pambwa - Papua New Guinea (PAW)",
    value: "PAW",
  },
  {
    label: "Pangia, Pangia - Papua New Guinea (PGN)",
    value: "PGN",
  },
  {
    label: "Pangoa, Pangoa - Papua New Guinea (PGB)",
    value: "PGB",
  },
  {
    label: "Param, Param - Papua New Guinea (PPX)",
    value: "PPX",
  },
  {
    label: "Pimaga, Pimaga - Papua New Guinea (PMP)",
    value: "PMP",
  },
  {
    label: "Pindiu, Pindiu - Papua New Guinea (PDI)",
    value: "PDI",
  },
  {
    label: "Popondetta, Girua - Papua New Guinea (PNP)",
    value: "PNP",
  },
  {
    label: "Porgera, Porgera - Papua New Guinea (RGE)",
    value: "RGE",
  },
  {
    label: "Port Moresby, Jacksons International - Papua New Guinea (POM)",
    value: "POM",
  },
  {
    label: "Puas, Puas - Papua New Guinea (PUA)",
    value: "PUA",
  },
  {
    label: "Pumani, Pumani - Papua New Guinea (PMN)",
    value: "PMN",
  },
  {
    label: "Pureni, Pureni - Papua New Guinea (PUI)",
    value: "PUI",
  },
  {
    label: "Rabaraba, Raba Raba - Papua New Guinea (RBP)",
    value: "RBP",
  },
  {
    label: "Rabaul, Tokua - Papua New Guinea (RAB)",
    value: "RAB",
  },
  {
    label: "Rakanda, Rakanda - Papua New Guinea (RAA)",
    value: "RAA",
  },
  {
    label: "Robinson River, Robinson River - Papua New Guinea (RNR)",
    value: "RNR",
  },
  {
    label: "Rumginae, Rumginae - Papua New Guinea (RMN)",
    value: "RMN",
  },
  {
    label: "Ruti, Ruti - Papua New Guinea (RUU)",
    value: "RUU",
  },
  {
    label: "Sabah, Sabah - Papua New Guinea (SBV)",
    value: "SBV",
  },
  {
    label: "Safia, Safia - Papua New Guinea (SFU)",
    value: "SFU",
  },
  {
    label: "Sagarai, Sagarai - Papua New Guinea (SGJ)",
    value: "SGJ",
  },
  {
    label: "Saidor, Saidor - Papua New Guinea (SDI)",
    value: "SDI",
  },
  {
    label: "Salamo, Salamo - Papua New Guinea (SAM)",
    value: "SAM",
  },
  {
    label: "Samarai Isl, China Strait - Papua New Guinea (SQT)",
    value: "SQT",
  },
  {
    label: "Sangapi, Sangapi - Papua New Guinea (SGK)",
    value: "SGK",
  },
  {
    label: "Sapmanga, Sapmanga - Papua New Guinea (SMH)",
    value: "SMH",
  },
  {
    label: "Satwag, Satwag - Papua New Guinea (SWG)",
    value: "SWG",
  },
  {
    label: "Sauren, Sauren - Papua New Guinea (SXW)",
    value: "SXW",
  },
  {
    label: "Sehulea, Sehulea - Papua New Guinea (SXH)",
    value: "SXH",
  },
  {
    label: "Selbang, Selbang - Papua New Guinea (SBC)",
    value: "SBC",
  },
  {
    label: "Sepik Plains, Sepik Plains - Papua New Guinea (SPV)",
    value: "SPV",
  },
  {
    label: "Sialum, Sialum - Papua New Guinea (SXA)",
    value: "SXA",
  },
  {
    label: "Siassi, Siassi - Papua New Guinea (SSS)",
    value: "SSS",
  },
  {
    label: "Sila, Sila - Papua New Guinea (SIL)",
    value: "SIL",
  },
  {
    label: "Silur, Silur - Papua New Guinea (SWR)",
    value: "SWR",
  },
  {
    label: "Sim, Sim - Papua New Guinea (SMJ)",
    value: "SMJ",
  },
  {
    label: "Simbai, Simbai - Papua New Guinea (SIM)",
    value: "SIM",
  },
  {
    label: "Simberi Island, Simberi Island - Papua New Guinea (NIS)",
    value: "NIS",
  },
  {
    label: "Singaua, Singaua - Papua New Guinea (SGB)",
    value: "SGB",
  },
  {
    label: "Sissano, Sissano - Papua New Guinea (SIZ)",
    value: "SIZ",
  },
  {
    label: "Siwea, Siwea - Papua New Guinea (SWE)",
    value: "SWE",
  },
  {
    label: "Sopu, Sopu - Papua New Guinea (SPH)",
    value: "SPH",
  },
  {
    label: "Stockholm, Stockholm - Papua New Guinea (SMP)",
    value: "SMP",
  },
  {
    label: "Suabi, Suabi - Papua New Guinea (SBE)",
    value: "SBE",
  },
  {
    label: "Suki, Suki - Papua New Guinea (SKC)",
    value: "SKC",
  },
  {
    label: "Sule, Sule - Papua New Guinea (ULE)",
    value: "ULE",
  },
  {
    label: "Suria, Suria - Papua New Guinea (SUZ)",
    value: "SUZ",
  },
  {
    label: "Tabibuga, Tabibuga - Papua New Guinea (TBA)",
    value: "TBA",
  },
  {
    label: "Tabubil, Tabubil - Papua New Guinea (TBG)",
    value: "TBG",
  },
  {
    label: "Tadji, Tadji - Papua New Guinea (TAJ)",
    value: "TAJ",
  },
  {
    label: "Tagula, Tagula - Papua New Guinea (TGL)",
    value: "TGL",
  },
  {
    label: "Talasea, Talasea - Papua New Guinea (TLW)",
    value: "TLW",
  },
  {
    label: "Tapini, Tapini - Papua New Guinea (TPI)",
    value: "TPI",
  },
  {
    label: "Tarabo, Tarabo - Papua New Guinea (TBQ)",
    value: "TBQ",
  },
  {
    label: "Tarakbits, Tarakbits - Papua New Guinea (TRJ)",
    value: "TRJ",
  },
  {
    label: "Tari, Tari - Papua New Guinea (TIZ)",
    value: "TIZ",
  },
  {
    label: "Taskul, Taskul - Papua New Guinea (TSK)",
    value: "TSK",
  },
  {
    label: "Tauta, Tauta - Papua New Guinea (TUT)",
    value: "TUT",
  },
  {
    label: "Tawa, Tawa - Papua New Guinea (TWY)",
    value: "TWY",
  },
  {
    label: "Tekadu, Tekadu - Papua New Guinea (TKB)",
    value: "TKB",
  },
  {
    label: "Tekin, Tekin - Papua New Guinea (TKW)",
    value: "TKW",
  },
  {
    label: "Telefomin, Telefomin - Papua New Guinea (TFM)",
    value: "TFM",
  },
  {
    label: "Teptep, Teptep - Papua New Guinea (TEP)",
    value: "TEP",
  },
  {
    label: "Terapo, Terapo - Papua New Guinea (TEO)",
    value: "TEO",
  },
  {
    label: "Tetabedi, Tetebedi - Papua New Guinea (TDB)",
    value: "TDB",
  },
  {
    label: "Tifalmin, Tifalmin - Papua New Guinea (TFB)",
    value: "TFB",
  },
  {
    label: "Timbunke, Timbunke - Papua New Guinea (TBE)",
    value: "TBE",
  },
  {
    label: "Tinboli, Tinboli - Papua New Guinea (TCK)",
    value: "TCK",
  },
  {
    label: "Tingwon, Tingwon - Papua New Guinea (TIG)",
    value: "TIG",
  },
  {
    label: "Tol, Tol - Papua New Guinea (TLO)",
    value: "TLO",
  },
  {
    label: "Tonu, Tonu - Papua New Guinea (TON)",
    value: "TON",
  },
  {
    label: "Torembi, Torembi Airport - Papua New Guinea (TCJ)",
    value: "TCJ",
  },
  {
    label: "Torokina, Torokina - Papua New Guinea (TOK)",
    value: "TOK",
  },
  {
    label: "Tsewi, Tsewi - Papua New Guinea (TSW)",
    value: "TSW",
  },
  {
    label: "Tsili Tsili, Tsile Tsile - Papua New Guinea (TSI)",
    value: "TSI",
  },
  {
    label: "Tufi, Tufi - Papua New Guinea (TFI)",
    value: "TFI",
  },
  {
    label: "Tumolbil, Tumolbil - Papua New Guinea (TLP)",
    value: "TLP",
  },
  {
    label: "Umba, Umba - Papua New Guinea (UMC)",
    value: "UMC",
  },
  {
    label: "Upiara, Upiara - Papua New Guinea (UPR)",
    value: "UPR",
  },
  {
    label: "Uroubi, Uroubi - Papua New Guinea (URU)",
    value: "URU",
  },
  {
    label: "Usino, Usino - Papua New Guinea (USO)",
    value: "USO",
  },
  {
    label: "Uvol, Uvol - Papua New Guinea (UVO)",
    value: "UVO",
  },
  {
    label: "Vanimo, Vanimo - Papua New Guinea (VAI)",
    value: "VAI",
  },
  {
    label: "Vivigani, Vivigani - Papua New Guinea (VIV)",
    value: "VIV",
  },
  {
    label: "Wabag, Wabag - Papua New Guinea (WAB)",
    value: "WAB",
  },
  {
    label: "Wabo, Wabo - Papua New Guinea (WAO)",
    value: "WAO",
  },
  {
    label: "Wagau, Wagau - Papua New Guinea (WGU)",
    value: "WGU",
  },
  {
    label: "Wakunai, Wakunai - Papua New Guinea (WKN)",
    value: "WKN",
  },
  {
    label: "Wanigela, Wanigela - Papua New Guinea (AGL)",
    value: "AGL",
  },
  {
    label: "Wantoat, Wantoat - Papua New Guinea (WTT)",
    value: "WTT",
  },
  {
    label: "Wanuma, Wanuma - Papua New Guinea (WNU)",
    value: "WNU",
  },
  {
    label: "Wapenamanda, Wapenamanda - Papua New Guinea (WBM)",
    value: "WBM",
  },
  {
    label: "Wapolu, Wapolu - Papua New Guinea (WBC)",
    value: "WBC",
  },
  {
    label: "Wasu, Wasu - Papua New Guinea (WSU)",
    value: "WSU",
  },
  {
    label: "Wasua, Wasua - Papua New Guinea (WSA)",
    value: "WSA",
  },
  {
    label: "Wasum, Wasum - Papua New Guinea (WUM)",
    value: "WUM",
  },
  {
    label: "Wau, Wau - Papua New Guinea (WUG)",
    value: "WUG",
  },
  {
    label: "Wawoi Falls, Wawoi Falls - Papua New Guinea (WAJ)",
    value: "WAJ",
  },
  {
    label: "Weam, Weam - Papua New Guinea (WEP)",
    value: "WEP",
  },
  {
    label: "Wedau, Wedau - Papua New Guinea (WED)",
    value: "WED",
  },
  {
    label: "Wewak, Wewak - Papua New Guinea (WWK)",
    value: "WWK",
  },
  {
    label: "Wipim, Wipim - Papua New Guinea (WPM)",
    value: "WPM",
  },
  {
    label: "Witu, Witu - Papua New Guinea (WIU)",
    value: "WIU",
  },
  {
    label: "Woitape, Woitape - Papua New Guinea (WTP)",
    value: "WTP",
  },
  {
    label: "Wonenara, Wonenara - Papua New Guinea (WOA)",
    value: "WOA",
  },
  {
    label: "Wuvulu Is, Wuvulu Island - Papua New Guinea (WUV)",
    value: "WUV",
  },
  {
    label: "Yalumet, Yalumet - Papua New Guinea (KYX)",
    value: "KYX",
  },
  {
    label: "Yapsiei, Yapsiei - Papua New Guinea (KPE)",
    value: "KPE",
  },
  {
    label: "Yasuru, Yasuru - Papua New Guinea (KSX)",
    value: "KSX",
  },
  {
    label: "Yegepa, Yegepa - Papua New Guinea (PGE)",
    value: "PGE",
  },
  {
    label: "Yellow River, Edwaki - Papua New Guinea (XYR)",
    value: "XYR",
  },
  {
    label: "Yenkis, Yankisa - Papua New Guinea (YEQ)",
    value: "YEQ",
  },
  {
    label: "Yeva, Yeva - Papua New Guinea (YVD)",
    value: "YVD",
  },
  {
    label: "Yongai, Yongai - Papua New Guinea (KGH)",
    value: "KGH",
  },
  {
    label: "Yule Island, Kairuku - Papua New Guinea (RKU)",
    value: "RKU",
  },
  {
    label: "Zenag, Zenag - Papua New Guinea (ZEN)",
    value: "ZEN",
  },
  {
    label: "Asuncion, Silvio Pettirossi Intl - Paraguay (ASU)",
    value: "ASU",
  },
  {
    label: "Ayolas, Juan De Ayolas - Paraguay (AYO)",
    value: "AYO",
  },
  {
    label: "Bahia Negra, Bahia Negra - Paraguay (BFA)",
    value: "BFA",
  },
  {
    label: "Ciudad Del Este, Guarani Intl - Paraguay (AGT)",
    value: "AGT",
  },
  {
    label: "Concepcion, Carmelo Peralta - Paraguay (CIO)",
    value: "CIO",
  },
  {
    label: "Encarnacion, R Amin Ayub Gonzalez - Paraguay (ENO)",
    value: "ENO",
  },
  {
    label: "Filadelfia, Filadelfia - Paraguay (FLM)",
    value: "FLM",
  },
  {
    label: "Fuerte Olimpo, Fuerte Olimpo - Paraguay (OLK)",
    value: "OLK",
  },
  {
    label: "Mariscal Estigarr, Luis Maria Argana Intl - Paraguay (ESG)",
    value: "ESG",
  },
  {
    label: "Pedro Juan Caball, Augusto Roberto Fuster - Paraguay (PJC)",
    value: "PJC",
  },
  {
    label: "Pilar, Carlos Miguel Jimenez - Paraguay (PIL)",
    value: "PIL",
  },
  {
    label: "Puerto La Victoria, Puerto La Victoria - Paraguay (PCJ)",
    value: "PCJ",
  },
  {
    label: "Puerto Leda, Puerto Leda - Paraguay (PBT)",
    value: "PBT",
  },
  {
    label: "Vallemi, Dr Juan Plate - Paraguay (VMI)",
    value: "VMI",
  },
  {
    label: "Alerta, Alerta - Peru (ALD)",
    value: "ALD",
  },
  {
    label: "Andahuaylas, Andahuaylas - Peru (ANS)",
    value: "ANS",
  },
  {
    label: "Anta, German Arias Graziani - Peru (ATA)",
    value: "ATA",
  },
  {
    label: "Arequipa, Rodriguez Ballon Intl - Peru (AQP)",
    value: "AQP",
  },
  {
    label: "Ayacucho, Alfredo M Duarte - Peru (AYP)",
    value: "AYP",
  },
  {
    label: "Bellavista, Huallaga - Peru (BLP)",
    value: "BLP",
  },
  {
    label: "Cajamarca, Armando R Iglesias - Peru (CJA)",
    value: "CJA",
  },
  {
    label: "Chachapoyas, Chachapoyas - Peru (CHH)",
    value: "CHH",
  },
  {
    label: "Chiclayo, J A Quinones Gonzalez - Peru (CIX)",
    value: "CIX",
  },
  {
    label: "Chimbote, J Montreuil Morales - Peru (CHM)",
    value: "CHM",
  },
  {
    label: "Cuzco, A Velasco Astete Intl - Peru (CUZ)",
    value: "CUZ",
  },
  {
    label: "Huanuco, Huanuco - Peru (HUU)",
    value: "HUU",
  },
  {
    label: "Iberia, Iberia - Peru (IBP)",
    value: "IBP",
  },
  {
    label: "Ilo, Ilo - Peru (ILQ)",
    value: "ILQ",
  },
  {
    label: "Iquitos, F Secada Vignetta - Peru (IQT)",
    value: "IQT",
  },
  {
    label: "Jaen, Aeropuerto De Shumba - Peru (JAE)",
    value: "JAE",
  },
  {
    label: "Jauja, Jauja - Peru (JAU)",
    value: "JAU",
  },
  {
    label: "Juanjui, Juanjui - Peru (JJI)",
    value: "JJI",
  },
  {
    label: "Juliaca, Inco Mnco Capac Intl - Peru (JUL)",
    value: "JUL",
  },
  {
    label: "Lima - Peru (LIM)",
    value: "LIM",
  },
  {
    label: "Lima, Jorge Chavez Intl - Peru (LIM)",
    value: "LIM",
  },
  {
    label: "Machu Picchu, Machu Picchu - Peru (MFT)",
    value: "MFT",
  },
  {
    label: "Moyobamba, Moyobamba - Peru (MBP)",
    value: "MBP",
  },
  {
    label: "Nazca, Maria Reiche Neuman - Peru (NZC)",
    value: "NZC",
  },
  {
    label: "Pisco, Renan Elias Olivera - Peru (PIO)",
    value: "PIO",
  },
  {
    label: "Piura, G Concha Iberico Intl - Peru (PIU)",
    value: "PIU",
  },
  {
    label: "Pucallpa, D Abenzur Rengifo Intl - Peru (PCL)",
    value: "PCL",
  },
  {
    label: "Puerto Maldonado, Padre Aldamiz Intl - Peru (PEM)",
    value: "PEM",
  },
  {
    label: "Quincemil, Quince Mil - Peru (UMI)",
    value: "UMI",
  },
  {
    label: "Rioja, Juan Simons Vela - Peru (RIJ)",
    value: "RIJ",
  },
  {
    label: "Rodriguez De Men, San Nicolas - Peru (RIM)",
    value: "RIM",
  },
  {
    label: "San Juan, San Juan - Peru (SJA)",
    value: "SJA",
  },
  {
    label: "San Juan Aposento, San Juan Aposento - Peru (APE)",
    value: "APE",
  },
  {
    label: "Santa Maria, Santa Maria - Peru (SMG)",
    value: "SMG",
  },
  {
    label: "Saposoa, Saposoa - Peru (SQU)",
    value: "SQU",
  },
  {
    label: "Shiringayoc, Shiringayoc - Peru (SYC)",
    value: "SYC",
  },
  {
    label: "Tacna, C Ciriani Santa Rosa - Peru (TCQ)",
    value: "TCQ",
  },
  {
    label: "Talara, Victor Monteas Arias - Peru (TYL)",
    value: "TYL",
  },
  {
    label: "Tarapoto, G Del Castillo Paredes - Peru (TPP)",
    value: "TPP",
  },
  {
    label: "Tingo Maria, Tingo Maria - Peru (TGI)",
    value: "TGI",
  },
  {
    label: "Trujillo, C Martinez De Pinillos - Peru (TRU)",
    value: "TRU",
  },
  {
    label: "Tumbes, Pedro Canga Rodriguez - Peru (TBP)",
    value: "TBP",
  },
  {
    label: "Yurimaguas, M Benzaquen Rengifo - Peru (YMS)",
    value: "YMS",
  },
  {
    label: "Alah, Allah Valley - Philippines (AAV)",
    value: "AAV",
  },
  {
    label: "Angeles Mabalacat, Clark Intl - Philippines (CRK)",
    value: "CRK",
  },
  {
    label: "Antique, Evelio Javier - Philippines (EUQ)",
    value: "EUQ",
  },
  {
    label: "Bacolod, Silay International - Philippines (BCD)",
    value: "BCD",
  },
  {
    label: "Baganga, Baganga - Philippines (BNQ)",
    value: "BNQ",
  },
  {
    label: "Baguio, Loakan - Philippines (BAG)",
    value: "BAG",
  },
  {
    label: "Baler, Dr Juan C Angara - Philippines (BQA)",
    value: "BQA",
  },
  {
    label: "Basco, Basco - Philippines (BSO)",
    value: "BSO",
  },
  {
    label: "Bislig, Bislig - Philippines (BPH)",
    value: "BPH",
  },
  {
    label: "Busuanga, Francisco B Reyes - Philippines (USU)",
    value: "USU",
  },
  {
    label: "Butuan, Bancasi - Philippines (BXU)",
    value: "BXU",
  },
  {
    label: "Cagayan De Oro, Laguindingan Intl - Philippines (CGY)",
    value: "CGY",
  },
  {
    label: "Cagayan De Sulu, Cagayan De Sulu - Philippines (CDY)",
    value: "CDY",
  },
  {
    label: "Calbayog, Calbayog - Philippines (CYP)",
    value: "CYP",
  },
  {
    label: "Camiguin, Mambajao - Philippines (CGM)",
    value: "CGM",
  },
  {
    label: "Casiguran, Casiguran - Philippines (CGG)",
    value: "CGG",
  },
  {
    label: "Catarman, National - Philippines (CRM)",
    value: "CRM",
  },
  {
    label: "Caticlan, Godofredo P Ramos - Philippines (MPH)",
    value: "MPH",
  },
  {
    label: "Cauayan, Cauayan - Philippines (CYZ)",
    value: "CYZ",
  },
  {
    label: "Cebu, Mactan International - Philippines (CEB)",
    value: "CEB",
  },
  {
    label: "Coron, Coron - Philippines (XCN)",
    value: "XCN",
  },
  {
    label: "Cotabato, Awang - Philippines (CBO)",
    value: "CBO",
  },
  {
    label: "Culion, Culion - Philippines (CUJ)",
    value: "CUJ",
  },
  {
    label: "Cuyo, Cuyo - Philippines (CYU)",
    value: "CYU",
  },
  {
    label: "Daet, Bagasbas - Philippines (DTE)",
    value: "DTE",
  },
  {
    label: "Davao, Francisco Bangoy Intl - Philippines (DVO)",
    value: "DVO",
  },
  {
    label: "Del Carmen, Sayak - Philippines (IAO)",
    value: "IAO",
  },
  {
    label: "Dilasag, Dilasag - Philippines (DSG)",
    value: "DSG",
  },
  {
    label: "Dipolog, Dipolog - Philippines (DPL)",
    value: "DPL",
  },
  {
    label: "Dumaguete, Sibulan - Philippines (DGT)",
    value: "DGT",
  },
  {
    label: "El Nido, El Nido - Philippines (ENI)",
    value: "ENI",
  },
  {
    label: "General Santos, International - Philippines (GES)",
    value: "GES",
  },
  {
    label: "Iligan, Maria Cristina - Philippines (IGN)",
    value: "IGN",
  },
  {
    label: "Ilo Ilo, International - Philippines (ILO)",
    value: "ILO",
  },
  {
    label: "Ipil, Ipil - Philippines (IPE)",
    value: "IPE",
  },
  {
    label: "Jolo, Jolo - Philippines (JOL)",
    value: "JOL",
  },
  {
    label: "Kalibo, Kalibo International - Philippines (KLO)",
    value: "KLO",
  },
  {
    label: "Laoag, International - Philippines (LAO)",
    value: "LAO",
  },
  {
    label: "Legaspi, Legaspi - Philippines (LGP)",
    value: "LGP",
  },
  {
    label: "Lubang, Lubang - Philippines (LBX)",
    value: "LBX",
  },
  {
    label: "Lwbak, Lebak - Philippines (LWA)",
    value: "LWA",
  },
  {
    label: "Malabang, Malabang - Philippines (MLP)",
    value: "MLP",
  },
  {
    label: "Mamburao, Mamburao - Philippines (MBO)",
    value: "MBO",
  },
  {
    label: "Manila, Danilo Atienza Ab - Philippines (SGL)",
    value: "SGL",
  },
  {
    label: "Manila, Ninoy Aquino Intl - Philippines (MNL)",
    value: "MNL",
  },
  {
    label: "Maramag, Maramag - Philippines (XMA)",
    value: "XMA",
  },
  {
    label: "Marinduque, Marinduque Island - Philippines (MRQ)",
    value: "MRQ",
  },
  {
    label: "Masbate, Moises R Espinosa - Philippines (MBT)",
    value: "MBT",
  },
  {
    label: "Mati, Imelda R Marcos - Philippines (MXI)",
    value: "MXI",
  },
  {
    label: "Naga, Naga - Philippines (WNP)",
    value: "WNP",
  },
  {
    label: "Ormoc, Ormoc - Philippines (OMC)",
    value: "OMC",
  },
  {
    label: "Ozamis City, Labo - Philippines (OZC)",
    value: "OZC",
  },
  {
    label: "Pagadian, Pagadian - Philippines (PAG)",
    value: "PAG",
  },
  {
    label: "Puerto Princesa, International - Philippines (PPS)",
    value: "PPS",
  },
  {
    label: "Roxas City, Roxas - Philippines (RXS)",
    value: "RXS",
  },
  {
    label: "San Fernando, San Fernando - Philippines (SFE)",
    value: "SFE",
  },
  {
    label: "San Jose, San Jose - Philippines (SJI)",
    value: "SJI",
  },
  {
    label: "Sanga Sanga, Sanga Sanga - Philippines (SGS)",
    value: "SGS",
  },
  {
    label: "Siasi, Siasi - Philippines (SSV)",
    value: "SSV",
  },
  {
    label: "Sicogon Island, Sicogon Island - Philippines (ICO)",
    value: "ICO",
  },
  {
    label: "Siocon, Siocon - Philippines (XSO)",
    value: "XSO",
  },
  {
    label: "Subic Bay, Subic Bay Intl - Philippines (SFS)",
    value: "SFS",
  },
  {
    label: "Surigao, Surigao - Philippines (SUG)",
    value: "SUG",
  },
  {
    label: "Tablas, Tugdan - Philippines (TBH)",
    value: "TBH",
  },
  {
    label: "Tacloban, D Z Romualdez - Philippines (TAC)",
    value: "TAC",
  },
  {
    label: "Tagbilaran, Tagbilaran - Philippines (TAG)",
    value: "TAG",
  },
  {
    label: "Tagbita, Tagbita - Philippines (TGB)",
    value: "TGB",
  },
  {
    label: "Tandag, Tandag - Philippines (TDG)",
    value: "TDG",
  },
  {
    label: "Tawitawi, Sanga-Sanga - Philippines (TWT)",
    value: "TWT",
  },
  {
    label: "Taytay Sandoval, Cesar Lim Rodriguez - Philippines (RZP)",
    value: "RZP",
  },
  {
    label: "Tuguegarao, Tuguegarao - Philippines (TUG)",
    value: "TUG",
  },
  {
    label: "Virac, Virac - Philippines (VRC)",
    value: "VRC",
  },
  {
    label: "Zamboanga, International - Philippines (ZAM)",
    value: "ZAM",
  },
  {
    label: "Gdansk - Poland (GDN)",
    value: "GDN",
  },
  {
    label: "Gdansk, Lech Walesa - Poland (GDN)",
    value: "GDN",
  },
  {
    label: "Katowice - Poland (KTW)",
    value: "KTW",
  },
  {
    label: "Katowice, Pyrzowice - Poland (KTW)",
    value: "KTW",
  },
  {
    label: "Krakow - Poland (KRK)",
    value: "KRK",
  },
  {
    label: "Krakow, John Paul Ii Balice - Poland (KRK)",
    value: "KRK",
  },
  {
    label: "Nowy Dwor Mazowiecki - Poland (WMI)",
    value: "WMI",
  },
  {
    label: "Nowy Dwor Mazowiecki, Modlin - Poland (WMI)",
    value: "WMI",
  },
  {
    label: "Poznan - Poland (POZ)",
    value: "POZ",
  },
  {
    label: "Poznan, Lawica - Poland (POZ)",
    value: "POZ",
  },
  {
    label: "Warsaw - Poland (WAW)",
    value: "WAW",
  },
  {
    label: "Warsaw, Frederic Chopin - Poland (WAW)",
    value: "WAW",
  },
  {
    label: "Wroclaw - Poland (WRO)",
    value: "WRO",
  },
  {
    label: "Wroclaw, Nicolaus Copernicus - Poland (WRO)",
    value: "WRO",
  },
  {
    label: "Biala Podlaska, Biala Podlaska - Poland (BXP)",
    value: "BXP",
  },
  {
    label: "Bydgoszcz, Ignacy Jan Paderewski - Poland (BZG)",
    value: "BZG",
  },
  {
    label: "Czestochowa, Rudniki - Poland (CZW)",
    value: "CZW",
  },
  {
    label: "Elblag, Elblag - Poland (ZBG)",
    value: "ZBG",
  },
  {
    label: "Koszalin, Zegrze Pomorskie Ab - Poland (OSZ)",
    value: "OSZ",
  },
  {
    label: "Lodz, Wladyslaw Reymont - Poland (LCJ)",
    value: "LCJ",
  },
  {
    label: "Lublin, Lublin - Poland (LUZ)",
    value: "LUZ",
  },
  {
    label: "Radom, Radom - Poland (RDO)",
    value: "RDO",
  },
  {
    label: "Rail Generic, Rail Generic - Poland (XXW)",
    value: "XXW",
  },
  {
    label: "Rzeszow, Jasionka - Poland (RZE)",
    value: "RZE",
  },
  {
    label: "Suwalki, Suwalki - Poland (ZWK)",
    value: "ZWK",
  },
  {
    label: "Szczecin, Goleniow - Poland (SZZ)",
    value: "SZZ",
  },
  {
    label: "Szczytno, Szymany - Poland (SZY)",
    value: "SZY",
  },
  {
    label: "Zielona Gora, Babimost - Poland (IEG)",
    value: "IEG",
  },
  {
    label: "Faro - Portugal (FAO)",
    value: "FAO",
  },
  {
    label: "Faro, Airport - Portugal (FAO)",
    value: "FAO",
  },
  {
    label: "Lisbon - Portugal (LIS)",
    value: "LIS",
  },
  {
    label: "Lisbon, Airport - Portugal (LIS)",
    value: "LIS",
  },
  {
    label: "Funchal - Portugal (FNC)",
    value: "FNC",
  },
  {
    label: "Funchal, Madeira - Portugal (FNC)",
    value: "FNC",
  },
  {
    label: "Ponta Delgada Azores - Portugal (PDL)",
    value: "PDL",
  },
  {
    label: "Ponta Delgada Azores, Joao Paulo Ii - Portugal (PDL)",
    value: "PDL",
  },
  {
    label: "Porto - Portugal (OPO)",
    value: "OPO",
  },
  {
    label: "Porto, Francisco Sa Carneiro - Portugal (OPO)",
    value: "OPO",
  },
  {
    label: "Alverca, Alverca - Portugal (AVR)",
    value: "AVR",
  },
  {
    label: "Beja, International - Portugal (BYJ)",
    value: "BYJ",
  },
  {
    label: "Braga, Braga - Portugal (BGZ)",
    value: "BGZ",
  },
  {
    label: "Braganca, Braganca - Portugal (BGC)",
    value: "BGC",
  },
  {
    label: "Chaves, Chaves - Portugal (CHV)",
    value: "CHV",
  },
  {
    label: "Coimbra, Coimbra - Portugal (CBP)",
    value: "CBP",
  },
  {
    label: "Corvo Island, Corvo Island - Portugal (CVU)",
    value: "CVU",
  },
  {
    label: "Covilha, Covilha - Portugal (COV)",
    value: "COV",
  },
  {
    label: "Flores Island, Flores Island - Portugal (FLW)",
    value: "FLW",
  },
  {
    label: "Graciosa Island, Graciosa Island - Portugal (GRW)",
    value: "GRW",
  },
  {
    label: "Horta, Horta - Portugal (HOR)",
    value: "HOR",
  },
  {
    label: "Pico Island, Pico Island - Portugal (PIX)",
    value: "PIX",
  },
  {
    label: "Portimao, Portimao - Portugal (PRM)",
    value: "PRM",
  },
  {
    label: "Porto Santo, Porto Santo - Portugal (PXO)",
    value: "PXO",
  },
  {
    label: "Ribeira Grande, Ribeira Grande - Portugal (QEG)",
    value: "QEG",
  },
  {
    label: "Santa Maria Island, Santa Maria Island - Portugal (SMA)",
    value: "SMA",
  },
  {
    label: "Sao Jorge Island, Sao Jorge Island - Portugal (SJZ)",
    value: "SJZ",
  },
  {
    label: "Setubal, Setubal - Portugal (XSZ)",
    value: "XSZ",
  },
  {
    label: "Sines, Sines - Portugal (SIE)",
    value: "SIE",
  },
  {
    label: "Terceira Island, Lajes - Portugal (TER)",
    value: "TER",
  },
  {
    label: "Vila Real, Vila Real - Portugal (VRL)",
    value: "VRL",
  },
  {
    label: "Viseu, Viseu - Portugal (VSE)",
    value: "VSE",
  },
  {
    label: "Aguadilla, Rafael Hernandez - Puerto Rico (BQN)",
    value: "BQN",
  },
  {
    label: "Arecibo, Antonio Juarbe Pol - Puerto Rico (ARE)",
    value: "ARE",
  },
  {
    label: "Culebra, Benjamin Riveranoriega - Puerto Rico (CPX)",
    value: "CPX",
  },
  {
    label: "Dorado, Dorado Beach - Puerto Rico (DDP)",
    value: "DDP",
  },
  {
    label: "Fajardo, Diego Jimenez Torres - Puerto Rico (FAJ)",
    value: "FAJ",
  },
  {
    label: "Humacao, Humacao Arpt - Puerto Rico (HUC)",
    value: "HUC",
  },
  {
    label: "Humacao, Palmas Del Mar - Puerto Rico (PPD)",
    value: "PPD",
  },
  {
    label: "Mayaguez, Eugenio M.De Hostos - Puerto Rico (MAZ)",
    value: "MAZ",
  },
  {
    label: "Ponce, Mercedita - Puerto Rico (PSE)",
    value: "PSE",
  },
  {
    label: "Roosevelt Roads, J.Aponte De La Torre - Puerto Rico (NRR)",
    value: "NRR",
  },
  {
    label: "San Juan, F.L. Ribas Dominicci - Puerto Rico (SIG)",
    value: "SIG",
  },
  {
    label: "San Juan, Luis Munoz Marin - Puerto Rico (SJU)",
    value: "SJU",
  },
  {
    label: "Vieques, A.Rivera Rodriguez - Puerto Rico (VQS)",
    value: "VQS",
  },
  {
    label: "Doha - Qatar (DOH)",
    value: "DOH",
  },
  {
    label: "Doha, Hamad International - Qatar (DOH)",
    value: "DOH",
  },
  {
    label: "Doha, Free Zone - Qatar (XOZ)",
    value: "XOZ",
  },
  {
    label: "Saint Denis, Roland Garros - Reunion Island (RUN)",
    value: "RUN",
  },
  {
    label: "Saint Pierre, Pierrefonds - Reunion Island (ZSE)",
    value: "ZSE",
  },
  {
    label: "Arad, Arad - Romania (ARW)",
    value: "ARW",
  },
  {
    label: "Bacau, Bacau - Romania (BCM)",
    value: "BCM",
  },
  {
    label: "Baia Mare, Baia Mare - Romania (BAY)",
    value: "BAY",
  },
  {
    label: "Bucharest, Baneasa Aurel Vlaicu - Romania (BBU)",
    value: "BBU",
  },
  {
    label: "Bucharest, Henri Coanda - Romania (OTP)",
    value: "OTP",
  },
  {
    label: "Caransebes, Caransebes - Romania (CSB)",
    value: "CSB",
  },
  {
    label: "Cluj Napoca, Cluj Napoca - Romania (CLJ)",
    value: "CLJ",
  },
  {
    label: "Constanta, Mihail Kogalniceanu - Romania (CND)",
    value: "CND",
  },
  {
    label: "Craiova, Craiova - Romania (CRA)",
    value: "CRA",
  },
  {
    label: "Iasi, Iasi - Romania (IAS)",
    value: "IAS",
  },
  {
    label: "Oradea, Oradea - Romania (OMR)",
    value: "OMR",
  },
  {
    label: "Satu Mare, Satu Mare - Romania (SUJ)",
    value: "SUJ",
  },
  {
    label: "Sibiu, Sibiu - Romania (SBZ)",
    value: "SBZ",
  },
  {
    label: "Suceava, Stefan Cel Mare - Romania (SCV)",
    value: "SCV",
  },
  {
    label: "Timisoara, Traian Vuia - Romania (TSR)",
    value: "TSR",
  },
  {
    label: "Tirgu Mures, Tirgu Mures - Romania (TGM)",
    value: "TGM",
  },
  {
    label: "Tulcea, Delta Dunarii - Romania (TCE)",
    value: "TCE",
  },
  {
    label: "Yekaterinburg - Russia (SVX)",
    value: "SVX",
  },
  {
    label: "Yekaterinburg, Koltsovo - Russia (SVX)",
    value: "SVX",
  },
  {
    label: "Moscow, Bykovo - Russia (MOW)",
    value: "MOW",
  },
  {
    label: "Moscow, Domodedovo - Russia (DME)",
    value: "DME",
  },
  {
    label: "Moscow, Sheremetyevo - Russia (SVO)",
    value: "SVO",
  },
  {
    label: "Moscow, Vnukovo Intl - Russia (VKO)",
    value: "VKO",
  },
  {
    label: "Moscow, Bykovo - Russia (BKA)",
    value: "BKA",
  },
  {
    label: "Moscow, Zhukovsky - Russia (ZIA)",
    value: "ZIA",
  },
  {
    label: "Saint Petersburg - Russia (LED)",
    value: "LED",
  },
  {
    label: "Saint Petersburg, Pulkovo - Russia (LED)",
    value: "LED",
  },
  {
    label: "Saint Petersburg, Rzhevka - Russia (RVH)",
    value: "RVH",
  },
  {
    label: "Sochi - Russia (AER)",
    value: "AER",
  },
  {
    label: "Sochi, Sochi Intl - Russia (AER)",
    value: "AER",
  },
  {
    label: "Abakan, Abakan - Russia (ABA)",
    value: "ABA",
  },
  {
    label: "Achinsk, Achinsk - Russia (ACS)",
    value: "ACS",
  },
  {
    label: "Aldan, Aldan - Russia (ADH)",
    value: "ADH",
  },
  {
    label: "Amderma, Amderma - Russia (AMV)",
    value: "AMV",
  },
  {
    label: "Anadyr, Ugolny - Russia (DYR)",
    value: "DYR",
  },
  {
    label: "Anapa, Vityazevo - Russia (AAQ)",
    value: "AAQ",
  },
  {
    label: "Arkhangelsk, Talagi - Russia (ARH)",
    value: "ARH",
  },
  {
    label: "Astrakhan, Astrakhan - Russia (ASF)",
    value: "ASF",
  },
  {
    label: "Balakovo, Balakovo - Russia (BWO)",
    value: "BWO",
  },
  {
    label: "Barnaul, Barnaul - Russia (BAX)",
    value: "BAX",
  },
  {
    label: "Batagay, Batagay - Russia (BQJ)",
    value: "BQJ",
  },
  {
    label: "Belgorod, Belgorod - Russia (EGO)",
    value: "EGO",
  },
  {
    label: "Beloretsk, Beloretsk - Russia (BCX)",
    value: "BCX",
  },
  {
    label: "Beloyarsky, Beloyarsky - Russia (EYK)",
    value: "EYK",
  },
  {
    label: "Berezovo, Berezovo - Russia (EZV)",
    value: "EZV",
  },
  {
    label: "Blagoveshchensk, Ignatyevo - Russia (BQS)",
    value: "BQS",
  },
  {
    label: "Bodaybo, Bodaybo - Russia (ODO)",
    value: "ODO",
  },
  {
    label: "Bogorodskoye, Bogorodskoye - Russia (BQG)",
    value: "BQG",
  },
  {
    label: "Bor, Podkamennaya Tungus - Russia (TGP)",
    value: "TGP",
  },
  {
    label: "Bratsk, Bratsk - Russia (BTK)",
    value: "BTK",
  },
  {
    label: "Bryansk, Bryansk - Russia (BZK)",
    value: "BZK",
  },
  {
    label: "Bugulma, Bugulma - Russia (UUA)",
    value: "UUA",
  },
  {
    label: "Cheboksary, Cheboksary - Russia (CSY)",
    value: "CSY",
  },
  {
    label: "Chelyabinsk, Balandino - Russia (CEK)",
    value: "CEK",
  },
  {
    label: "Cherepovets, Cherepovets - Russia (CEE)",
    value: "CEE",
  },
  {
    label: "Cherskiy, Cherskiy - Russia (CYX)",
    value: "CYX",
  },
  {
    label: "Chita, Kadala - Russia (HTA)",
    value: "HTA",
  },
  {
    label: "Chkalovskiy, Chkalovskiy Airport - Russia (CKL)",
    value: "CKL",
  },
  {
    label: "Chokurdah, Chokurdah - Russia (CKH)",
    value: "CKH",
  },
  {
    label: "Deputatsky, Deputatsky - Russia (DPT)",
    value: "DPT",
  },
  {
    label: "Dikson, Dikson - Russia (DKS)",
    value: "DKS",
  },
  {
    label: "Elista, Elista - Russia (ESL)",
    value: "ESL",
  },
  {
    label: "Gelendzhik, Gelendzhik - Russia (GDZ)",
    value: "GDZ",
  },
  {
    label: "Gorno Altaysk, Gorno Altaysk - Russia (RGK)",
    value: "RGK",
  },
  {
    label: "Grozny, Grozny - Russia (GRV)",
    value: "GRV",
  },
  {
    label: "Igarka, Igarka - Russia (IAA)",
    value: "IAA",
  },
  {
    label: "Igrim, Igrim - Russia (IRM)",
    value: "IRM",
  },
  {
    label: "Inta, Inta - Russia (INA)",
    value: "INA",
  },
  {
    label: "Irkutsk, Irkutsk - Russia (IKT)",
    value: "IKT",
  },
  {
    label: "Iturup Island, Burevestnik Afb - Russia (BVV)",
    value: "BVV",
  },
  {
    label: "Iturup Island, Iturup - Russia (ITU)",
    value: "ITU",
  },
  {
    label: "Ivanovo, Yuzhny - Russia (IWA)",
    value: "IWA",
  },
  {
    label: "Izhevsk, Izhevsk - Russia (IJK)",
    value: "IJK",
  },
  {
    label: "Kaliningrad, Khrabrovo - Russia (KGD)",
    value: "KGD",
  },
  {
    label: "Kaluga, Grabtsevo - Russia (KLF)",
    value: "KLF",
  },
  {
    label: "Kavalerovo, Kavalerovo - Russia (KVR)",
    value: "KVR",
  },
  {
    label: "Kazan, Kazan Intl - Russia (KZN)",
    value: "KZN",
  },
  {
    label: "Kemerovo, Kemerovo - Russia (KEJ)",
    value: "KEJ",
  },
  {
    label: "Keperveyem, Keperveyem - Russia (KPW)",
    value: "KPW",
  },
  {
    label: "Khabarovsk, Novyy - Russia (KHV)",
    value: "KHV",
  },
  {
    label: "Khanty-Mansiysk, Khanty-Mansiysk - Russia (HMA)",
    value: "HMA",
  },
  {
    label: "Khatanga, Khatanga - Russia (HTG)",
    value: "HTG",
  },
  {
    label: "Khonu, Moma - Russia (MQJ)",
    value: "MQJ",
  },
  {
    label: "Kirensk, Kirensk - Russia (KCK)",
    value: "KCK",
  },
  {
    label: "Kirov, Pobedilovo - Russia (KVX)",
    value: "KVX",
  },
  {
    label: "Kirovsk Apatity, Khibiny - Russia (KVK)",
    value: "KVK",
  },
  {
    label: "Kogalym, Kogalym - Russia (KGP)",
    value: "KGP",
  },
  {
    label: "Komsomolsk Na Amure, Komsomolsk Na Amure - Russia (KXK)",
    value: "KXK",
  },
  {
    label: "Kostroma, Kostroma - Russia (KMW)",
    value: "KMW",
  },
  {
    label: "Kotlas, Kotlas - Russia (KSZ)",
    value: "KSZ",
  },
  {
    label: "Krasnodar, Pashkovsky - Russia (KRR)",
    value: "KRR",
  },
  {
    label: "Krasnoselkup, Krasnoselkup - Russia (KKQ)",
    value: "KKQ",
  },
  {
    label: "Krasnoyarsk, Yemelyanovo - Russia (KJA)",
    value: "KJA",
  },
  {
    label: "Kurgan, Kurgan - Russia (KRO)",
    value: "KRO",
  },
  {
    label: "Kursk, Vostochny - Russia (URS)",
    value: "URS",
  },
  {
    label: "Kyzyl, Kyzyl - Russia (KYZ)",
    value: "KYZ",
  },
  {
    label: "Lensk, Lensk - Russia (ULK)",
    value: "ULK",
  },
  {
    label: "Leshukonskoye, Leshukonskoye - Russia (LDG)",
    value: "LDG",
  },
  {
    label: "Lipetsk, Lipetsk - Russia (LPK)",
    value: "LPK",
  },
  {
    label: "Magadan, Sokol - Russia (GDX)",
    value: "GDX",
  },
  {
    label: "Magan, Magan - Russia (GYG)",
    value: "GYG",
  },
  {
    label: "Magdagachi, Magdagachi - Russia (GDG)",
    value: "GDG",
  },
  {
    label: "Magnitogorsk, Magnitogorsk - Russia (MQF)",
    value: "MQF",
  },
  {
    label: "Makhachkala, Uytash - Russia (MCX)",
    value: "MCX",
  },
  {
    label: "Markovo, Markovo - Russia (KVM)",
    value: "KVM",
  },
  {
    label: "Mineralnye Vody, Mineralnye Vody - Russia (MRV)",
    value: "MRV",
  },
  {
    label: "Mirny, Mirny - Russia (MJZ)",
    value: "MJZ",
  },
  {
    label: "Murmansk, Murmansk - Russia (MMK)",
    value: "MMK",
  },
  {
    label: "Mys Kamenny, Mys Kamenny - Russia (YMK)",
    value: "YMK",
  },
  {
    label: "Nadym, Nadym - Russia (NYM)",
    value: "NYM",
  },
  {
    label: "Nalchik, Nalchik - Russia (NAL)",
    value: "NAL",
  },
  {
    label: "Naryan Mar, Naryan Mar - Russia (NNM)",
    value: "NNM",
  },
  {
    label: "Nazran, Sleptsovskaya - Russia (IGT)",
    value: "IGT",
  },
  {
    label: "Neftekamsk, Neftekamsk - Russia (NEF)",
    value: "NEF",
  },
  {
    label: "Nefteyugansk, Nefteyugansk - Russia (NFG)",
    value: "NFG",
  },
  {
    label: "Neryungri, Neryungri - Russia (NER)",
    value: "NER",
  },
  {
    label: "Nikolayevsk-Na-Amure, Nikolayevsk-Na-Amure - Russia (NLI)",
    value: "NLI",
  },
  {
    label: "Nizhnekamsk, Begishevo - Russia (NBC)",
    value: "NBC",
  },
  {
    label: "Nizhnevartovsk, Nizhnevartovsk - Russia (NJC)",
    value: "NJC",
  },
  {
    label: "Nizhny Novgorod, Strigino - Russia (GOJ)",
    value: "GOJ",
  },
  {
    label: "Nogliki, Nogliki - Russia (NGK)",
    value: "NGK",
  },
  {
    label: "Norilsk, Alykel - Russia (NSK)",
    value: "NSK",
  },
  {
    label: "Novgorod, Novgorod - Russia (NVR)",
    value: "NVR",
  },
  {
    label: "Novokuznetsk, Spichenkovo - Russia (NOZ)",
    value: "NOZ",
  },
  {
    label: "Novorossiysk, Krymska - Russia (NOI)",
    value: "NOI",
  },
  {
    label: "Novosibirsk, Tolmachevo - Russia (OVB)",
    value: "OVB",
  },
  {
    label: "Novy Urengoy, Novy Urengoy - Russia (NUX)",
    value: "NUX",
  },
  {
    label: "Noyabrsk, Noyabrsk - Russia (NOJ)",
    value: "NOJ",
  },
  {
    label: "Nyagan, Nyagan - Russia (NYA)",
    value: "NYA",
  },
  {
    label: "Nyurba, Nyurba - Russia (NYR)",
    value: "NYR",
  },
  {
    label: "Okha, Okha - Russia (OHH)",
    value: "OHH",
  },
  {
    label: "Okhotsk, Okhotsk - Russia (OHO)",
    value: "OHO",
  },
  {
    label: "Oktiabrsky, Oktiabrsky - Russia (OKT)",
    value: "OKT",
  },
  {
    label: "Olekminsk, Olekminsk - Russia (OLZ)",
    value: "OLZ",
  },
  {
    label: "Olenek, Olenek - Russia (ONK)",
    value: "ONK",
  },
  {
    label: "Omsk, Tsentralny - Russia (OMS)",
    value: "OMS",
  },
  {
    label: "Orel, Yuzhny - Russia (OEL)",
    value: "OEL",
  },
  {
    label: "Orenburg, Tsentralny - Russia (REN)",
    value: "REN",
  },
  {
    label: "Orsk, Orsk - Russia (OSW)",
    value: "OSW",
  },
  {
    label: "Pechora, Pechora - Russia (PEX)",
    value: "PEX",
  },
  {
    label: "Penza, Ternovka - Russia (PEZ)",
    value: "PEZ",
  },
  {
    label: "Perm, Bolshoye Savino - Russia (PEE)",
    value: "PEE",
  },
  {
    label: "Petropavlovsk, Yelizovo - Russia (PKC)",
    value: "PKC",
  },
  {
    label: "Petrozavodsk, Besovets - Russia (PES)",
    value: "PES",
  },
  {
    label: "Pevek, Pevek - Russia (PWE)",
    value: "PWE",
  },
  {
    label: "Plastun, Plastun - Russia (TLY)",
    value: "TLY",
  },
  {
    label: "Podolsk, Ostafyevo - Russia (OSF)",
    value: "OSF",
  },
  {
    label: "Polyarnyj, Polyarnyj - Russia (PYJ)",
    value: "PYJ",
  },
  {
    label: "Provideniya, Provideniya Bay - Russia (PVS)",
    value: "PVS",
  },
  {
    label: "Pskov, Pskov - Russia (PKV)",
    value: "PKV",
  },
  {
    label: "Raduzhny, Raduzhny - Russia (RAT)",
    value: "RAT",
  },
  {
    label: "Rostov, Rostov - Russia (ROV)",
    value: "ROV",
  },
  {
    label: "Ryazan, Turlatovo - Russia (RZN)",
    value: "RZN",
  },
  {
    label: "Rybinsk, Staroselye - Russia (RYB)",
    value: "RYB",
  },
  {
    label: "Salekhard, Salekhard - Russia (SLY)",
    value: "SLY",
  },
  {
    label: "Samara, Kurumoch Intl - Russia (KUF)",
    value: "KUF",
  },
  {
    label: "Saransk, Saransk - Russia (SKX)",
    value: "SKX",
  },
  {
    label: "Saratov, Tsentralny - Russia (RTW)",
    value: "RTW",
  },
  {
    label: "Shakhtersk, Shakhtersk - Russia (EKS)",
    value: "EKS",
  },
  {
    label: "Smolensk, Smolensk - Russia (LNX)",
    value: "LNX",
  },
  {
    label: "Solovetsky, Solovki - Russia (CSH)",
    value: "CSH",
  },
  {
    label: "Sovetskaya Gavan, Sovetskaya Gavan - Russia (GVN)",
    value: "GVN",
  },
  {
    label: "Sovetsky, Sovetsky - Russia (OVS)",
    value: "OVS",
  },
  {
    label: "Stavropol, Shpakovskoye - Russia (STW)",
    value: "STW",
  },
  {
    label: "Strezhevoy, Strezhevoy - Russia (SWT)",
    value: "SWT",
  },
  {
    label: "Surgut, Surgut - Russia (SGC)",
    value: "SGC",
  },
  {
    label: "Syktyvkar, Syktyvkar - Russia (SCW)",
    value: "SCW",
  },
  {
    label: "Taganrog, Taganrog - Russia (TGK)",
    value: "TGK",
  },
  {
    label: "Tambov, Donskoye - Russia (TBW)",
    value: "TBW",
  },
  {
    label: "Tarko-Sale, Tarko-Sale - Russia (TQL)",
    value: "TQL",
  },
  {
    label: "Tiksi, Tiksi - Russia (IKS)",
    value: "IKS",
  },
  {
    label: "Tobolsk, Tobolsk - Russia (TOX)",
    value: "TOX",
  },
  {
    label: "Tomsk, Bogashevo - Russia (TOF)",
    value: "TOF",
  },
  {
    label: "Tula, Klokovo - Russia (TYA)",
    value: "TYA",
  },
  {
    label: "Turukhansk, Turukhansk - Russia (THX)",
    value: "THX",
  },
  {
    label: "Tver, Migalovo - Russia (KLD)",
    value: "KLD",
  },
  {
    label: "Tynda, Tynda - Russia (TYD)",
    value: "TYD",
  },
  {
    label: "Tyumen, Roshchino - Russia (TJM)",
    value: "TJM",
  },
  {
    label: "Ufa, Ufa - Russia (UFA)",
    value: "UFA",
  },
  {
    label: "Ukhta, Ukhta - Russia (UCT)",
    value: "UCT",
  },
  {
    label: "Ulan Ude, Mukhino - Russia (UUD)",
    value: "UUD",
  },
  {
    label: "Ulyanovsk, Baratayevka - Russia (ULV)",
    value: "ULV",
  },
  {
    label: "Ulyanovsk, Vostochny - Russia (ULY)",
    value: "ULY",
  },
  {
    label: "Uray, Uray - Russia (URJ)",
    value: "URJ",
  },
  {
    label: "Urengoy, Urengoy - Russia (UEN)",
    value: "UEN",
  },
  {
    label: "Usinsk, Usinsk - Russia (USK)",
    value: "USK",
  },
  {
    label: "Ust Ilimsk, Ust Ilimsk - Russia (UIK)",
    value: "UIK",
  },
  {
    label: "Ust Kut, Ust Kut - Russia (UKX)",
    value: "UKX",
  },
  {
    label: "Ust Nera, Ust Nera - Russia (USR)",
    value: "USR",
  },
  {
    label: "Ust-Kuyga, Ust-Kuyga - Russia (UKG)",
    value: "UKG",
  },
  {
    label: "Ust-Maya, Ust-Maya - Russia (UMS)",
    value: "UMS",
  },
  {
    label: "Ust-Tsilma, Ust-Tsilma - Russia (UTS)",
    value: "UTS",
  },
  {
    label: "Varandey, Varandey - Russia (VRI)",
    value: "VRI",
  },
  {
    label: "Velikiye Luki, Velikiye Luki - Russia (VLU)",
    value: "VLU",
  },
  {
    label: "Veliky Ustyug, Veliky Ustyug - Russia (VUS)",
    value: "VUS",
  },
  {
    label: "Verkhnevilyuysk, Verkhnevilyuysk - Russia (VHV)",
    value: "VHV",
  },
  {
    label: "Vilyuysk, Vilyuysk - Russia (VYI)",
    value: "VYI",
  },
  {
    label: "Vladikavkaz, Beslan - Russia (OGZ)",
    value: "OGZ",
  },
  {
    label: "Vladivostok, Knevichi - Russia (VVO)",
    value: "VVO",
  },
  {
    label: "Volgodonsk, Volgodonsk - Russia (VLK)",
    value: "VLK",
  },
  {
    label: "Volgograd, Gumrak - Russia (VOG)",
    value: "VOG",
  },
  {
    label: "Vologda, Vologda - Russia (VGD)",
    value: "VGD",
  },
  {
    label: "Vorkuta, Vorkuta - Russia (VKT)",
    value: "VKT",
  },
  {
    label: "Voronezh, Chertovitskoye - Russia (VOZ)",
    value: "VOZ",
  },
  {
    label: "Yakutsk, Yakutsk - Russia (YKS)",
    value: "YKS",
  },
  {
    label: "Yaroslavl, Tunoshna - Russia (IAR)",
    value: "IAR",
  },
  {
    label: "Yeniseysk, Yeniseysk - Russia (EIE)",
    value: "EIE",
  },
  {
    label: "Yerbogachen, Yerbogachen - Russia (ERG)",
    value: "ERG",
  },
  {
    label: "Yeysk, Yeysk - Russia (EIK)",
    value: "EIK",
  },
  {
    label: "Yoshkar Ola, Yoshkar Ola - Russia (JOK)",
    value: "JOK",
  },
  {
    label: "Yuzhno Kurilsk, Mendeleevoo - Russia (DEE)",
    value: "DEE",
  },
  {
    label: "Yuzhno Sakhalinsk, Yuzhno Sakhalinsk - Russia (UUS)",
    value: "UUS",
  },
  {
    label: "Zhigansk, Zhigansk - Russia (ZIX)",
    value: "ZIX",
  },
  {
    label: "Butare, Butare - Rwanda (BTQ)",
    value: "BTQ",
  },
  {
    label: "Cyangugu, Kamembe - Rwanda (KME)",
    value: "KME",
  },
  {
    label: "Gisenyi, Gisenyi - Rwanda (GYI)",
    value: "GYI",
  },
  {
    label: "Kigali, International - Rwanda (KGL)",
    value: "KGL",
  },
  {
    label: "Ruhengeri, Ruhengeri - Rwanda (RHG)",
    value: "RHG",
  },
  {
    label: "Apia, Fagali I - Samoa-Independent State Of (FGI)",
    value: "FGI",
  },
  {
    label: "Apia, Faleolo Intl - Samoa-Independent State Of (APW)",
    value: "APW",
  },
  {
    label: "Asau, Asau - Samoa-Independent State Of (AAU)",
    value: "AAU",
  },
  {
    label: "Maota Savaii Is, Maota - Samoa-Independent State Of (MXS)",
    value: "MXS",
  },
  {
    label: "Porto Alegre, Heliport - Sao Tome And Principe Islands  (PGP)",
    value: "PGP",
  },
  {
    label:
      "Principe Island, Principe Island - Sao Tome And Principe Islands  (PCP)",
    value: "PCP",
  },
  {
    label: "Sao Tome, International - Sao Tome And Principe Islands  (TMS)",
    value: "TMS",
  },
  {
    label: "Yanbu Al Bahr - Saudi Arabia (YNB)",
    value: "YNB",
  },
  {
    label: "Yanbu Al Bahr, Yanbu Al Bahr - Saudi Arabia (YNB)",
    value: "YNB",
  },
  {
    label: "Wadi Al Dawaser - Saudi Arabia (WAE)",
    value: "WAE",
  },
  {
    label: "Wadi Al Dawaser, Wadi Al Dawaser - Saudi Arabia (WAE)",
    value: "WAE",
  },
  {
    label: "Turaif - Saudi Arabia (TUI)",
    value: "TUI",
  },
  {
    label: "Turaif, Turaif - Saudi Arabia (TUI)",
    value: "TUI",
  },
  {
    label: "Tabuk - Saudi Arabia (TUU)",
    value: "TUU",
  },
  {
    label: "Tabuk, Tabuk - Saudi Arabia (TUU)",
    value: "TUU",
  },
  {
    label: "Taif - Saudi Arabia (TIF)",
    value: "TIF",
  },
  {
    label: "Taif, Taif - Saudi Arabia (TIF)",
    value: "TIF",
  },
  {
    label: "Sharurah - Saudi Arabia (SHW)",
    value: "SHW",
  },
  {
    label: "Sharurah, Sharurah - Saudi Arabia (SHW)",
    value: "SHW",
  },
  {
    label: "Riyadh - Saudi Arabia (RUH)",
    value: "RUH",
  },
  {
    label: "Riyadh, King Khalid Intl - Saudi Arabia (RUH)",
    value: "RUH",
  },
  {
    label: "Rafha - Saudi Arabia (RAH)",
    value: "RAH",
  },
  {
    label: "Rafha, Rafha - Saudi Arabia (RAH)",
    value: "RAH",
  },
  {
    label: "Qaisumah - Saudi Arabia (AQI)",
    value: "AQI",
  },
  {
    label: "Qaisumah, Hafar Al Batin - Saudi Arabia (AQI)",
    value: "AQI",
  },
  {
    label: "Nejran - Saudi Arabia (EAM)",
    value: "EAM",
  },
  {
    label: "Nejran, Nejran - Saudi Arabia (EAM)",
    value: "EAM",
  },
  {
    label: "Madinah - Saudi Arabia (MED)",
    value: "MED",
  },
  {
    label: "Madinah, Mohammad Bin Abdulaziz - Saudi Arabia (MED)",
    value: "MED",
  },
  {
    label: "Jazan - Saudi Arabia (GIZ)",
    value: "GIZ",
  },
  {
    label: "Jazan, Gizan - Saudi Arabia (GIZ)",
    value: "GIZ",
  },
  {
    label: "Jeddah - Saudi Arabia (JED)",
    value: "JED",
  },
  {
    label: "Jeddah, King Abdulaziz Intl - Saudi Arabia (JED)",
    value: "JED",
  },
  {
    label: "King Khalid Mil City - Saudi Arabia (KMC)",
    value: "KMC",
  },
  {
    label: "King Khalid Mil City, King Khalid Mil City - Saudi Arabia (KMC)",
    value: "KMC",
  },
  {
    label: "Hail - Saudi Arabia (HAS)",
    value: "HAS",
  },
  {
    label: "Hail, Hail - Saudi Arabia (HAS)",
    value: "HAS",
  },
  {
    label: "Gurayat - Saudi Arabia (URY)",
    value: "URY",
  },
  {
    label: "Gurayat, Gurayat - Saudi Arabia (URY)",
    value: "URY",
  },
  {
    label: "Dawadmi - Saudi Arabia (DWD)",
    value: "DWD",
  },
  {
    label: "Dawadmi, King Salman Abdulaziz - Saudi Arabia (DWD)",
    value: "DWD",
  },
  {
    label: "Dammam - Saudi Arabia (DMM)",
    value: "DMM",
  },
  {
    label: "Dammam, King Fahd Intl - Saudi Arabia (DMM)",
    value: "DMM",
  },
  {
    label: "Gassim - Saudi Arabia (ELQ)",
    value: "ELQ",
  },
  {
    label: "Gassim, Gassim - Saudi Arabia (ELQ)",
    value: "ELQ",
  },
  {
    label: "Bisha - Saudi Arabia (BHH)",
    value: "BHH",
  },
  {
    label: "Bisha, Bisha - Saudi Arabia (BHH)",
    value: "BHH",
  },
  {
    label: "Arar - Saudi Arabia (RAE)",
    value: "RAE",
  },
  {
    label: "Arar, Arar - Saudi Arabia (RAE)",
    value: "RAE",
  },
  {
    label: "Jouf - Saudi Arabia (AJF)",
    value: "AJF",
  },
  {
    label: "Jouf, Jouf - Saudi Arabia (AJF)",
    value: "AJF",
  },
  {
    label: "Hofuf - Saudi Arabia (HOF)",
    value: "HOF",
  },
  {
    label: "Hofuf, Al-Ahsa - Saudi Arabia (HOF)",
    value: "HOF",
  },
  {
    label: "Al Ula - Saudi Arabia (ULH)",
    value: "ULH",
  },
  {
    label: "Al Ula, Majeed Bin Abdulaziz - Saudi Arabia (ULH)",
    value: "ULH",
  },
  {
    label: "Wedjh - Saudi Arabia (EJH)",
    value: "EJH",
  },
  {
    label: "Wedjh, Wedjh - Saudi Arabia (EJH)",
    value: "EJH",
  },
  {
    label: "Al Baha - Saudi Arabia (ABT)",
    value: "ABT",
  },
  {
    label: "Al Baha, Al-Aqiq - Saudi Arabia (ABT)",
    value: "ABT",
  },
  {
    label: "Abha - Saudi Arabia (AHB)",
    value: "AHB",
  },
  {
    label: "Abha, Abha - Saudi Arabia (AHB)",
    value: "AHB",
  },
  {
    label: "Al Kharj - Saudi Arabia (AKH)",
    value: "AKH",
  },
  {
    label: "Al Kharj, Prince Sultan Air Base - Saudi Arabia (AKH)",
    value: "AKH",
  },
  {
    label: "Khamis Mushait - Saudi Arabia (KMX)",
    value: "KMX",
  },
  {
    label: "Khamis Mushait, King Khalid Ab - Saudi Arabia (KMX)",
    value: "KMX",
  },
  {
    label: "Majma - Saudi Arabia (MJH)",
    value: "MJH",
  },
  {
    label: "Majma, Majma - Saudi Arabia (MJH)",
    value: "MJH",
  },
  {
    label: "Sulayel - Saudi Arabia (SLF)",
    value: "SLF",
  },
  {
    label: "Sulayel, Sulayel - Saudi Arabia (SLF)",
    value: "SLF",
  },
  {
    label: "Unayzah - Saudi Arabia (UZH)",
    value: "UZH",
  },
  {
    label: "Unayzah, Unayzah - Saudi Arabia (UZH)",
    value: "UZH",
  },
  {
    label: "Zilfi - Saudi Arabia (ZUL)",
    value: "ZUL",
  },
  {
    label: "Zilfi, Zilfi - Saudi Arabia (ZUL)",
    value: "ZUL",
  },
  {
    label: "Bakel, Bakel - Senegal (BXE)",
    value: "BXE",
  },
  {
    label: "Cap Skiring, Cap Skiring - Senegal (CSK)",
    value: "CSK",
  },
  {
    label: "Dakar, Blaise Diagne International - Senegal (DSS)",
    value: "DSS",
  },
  {
    label: "Dakar, Leopold Sedar Senghor - Senegal (DKR)",
    value: "DKR",
  },
  {
    label: "Kaolack, Kaolack - Senegal (KLC)",
    value: "KLC",
  },
  {
    label: "Kedougou, Kedougou - Senegal (KGG)",
    value: "KGG",
  },
  {
    label: "Kolda, Kolda - Senegal (KDA)",
    value: "KDA",
  },
  {
    label: "Matam, Matam - Senegal (MAX)",
    value: "MAX",
  },
  {
    label: "Niokolo-Koba, Niokolo-Koba - Senegal (NIK)",
    value: "NIK",
  },
  {
    label: "Podor, Podor - Senegal (POD)",
    value: "POD",
  },
  {
    label: "Richard-Toll, Richard-Toll - Senegal (RDT)",
    value: "RDT",
  },
  {
    label: "Simenti, Simenti - Senegal (SMY)",
    value: "SMY",
  },
  {
    label: "St-Louis, St-Louis - Senegal (XLS)",
    value: "XLS",
  },
  {
    label: "Tambacounda, Tambacounda - Senegal (TUD)",
    value: "TUD",
  },
  {
    label: "Ziguinchor, Ziguinchor - Senegal (ZIG)",
    value: "ZIG",
  },
  {
    label: "Belgrade, Batajnica - Serbia (BJY)",
    value: "BJY",
  },
  {
    label: "Belgrade, Nikola Tesla - Serbia (BEG)",
    value: "BEG",
  },
  {
    label: "Nis, Konstantin Veliki - Serbia (INI)",
    value: "INI",
  },
  {
    label: "Pristina, Pristina Intl - Serbia (PRN)",
    value: "PRN",
  },
  {
    label: "Uzice, Ponikve - Serbia (UZC)",
    value: "UZC",
  },
  {
    label: "Bird Island, Bird Island - Seychelles Islands (BDI)",
    value: "BDI",
  },
  {
    label: "Denis Island, Denis Island - Seychelles Islands (DEI)",
    value: "DEI",
  },
  {
    label: "Desroches Island, Desroches Island - Seychelles Islands (DES)",
    value: "DES",
  },
  {
    label: "Fregate Island, Fregate Island - Seychelles Islands (FRK)",
    value: "FRK",
  },
  {
    label: "Mahe Island, Seychelles Intl - Seychelles Islands (SEZ)",
    value: "SEZ",
  },
  {
    label: "Praslin Island, Praslin Island - Seychelles Islands (PRI)",
    value: "PRI",
  },
  {
    label: "Bonthe, Sherbro Intl - Sierra Leone (BTE)",
    value: "BTE",
  },
  {
    label: "Freetown, Hastings - Sierra Leone (HGS)",
    value: "HGS",
  },
  {
    label: "Freetown, Lungi Intl - Sierra Leone (FNA)",
    value: "FNA",
  },
  {
    label: "Freetown, Mammy Yoko Hpt - Sierra Leone (JMY)",
    value: "JMY",
  },
  {
    label: "Gbangbatok, Gbangbatok - Sierra Leone (GBK)",
    value: "GBK",
  },
  {
    label: "Kabala, Kabala - Sierra Leone (KBA)",
    value: "KBA",
  },
  {
    label: "Kenema, Kenema - Sierra Leone (KEN)",
    value: "KEN",
  },
  {
    label: "Yengema, Yengema - Sierra Leone (WYE)",
    value: "WYE",
  },
  {
    label: "Singapore, Changi - Singapore (SIN)",
    value: "SIN",
  },
  {
    label: "Singapore, Marina Bay Cruise Ctr - Singapore (MCR)",
    value: "MCR",
  },
  {
    label: "Singapore, Paya Lebar - Singapore (QPG)",
    value: "QPG",
  },
  {
    label: "Singapore, Seletar - Singapore (XSP)",
    value: "XSP",
  },
  {
    label: "Singapore, Tengah - Singapore (TGA)",
    value: "TGA",
  },
  {
    label: "Saint Maarten, Princess Juliana Intl - Sint Maarten (SXM)",
    value: "SXM",
  },
  {
    label: "Bratislava, M R Stefanik - Slovakia (BTS)",
    value: "BTS",
  },
  {
    label: "Kosice, Kosice - Slovakia (KSC)",
    value: "KSC",
  },
  {
    label: "Lucenec, Lucenec - Slovakia (LUE)",
    value: "LUE",
  },
  {
    label: "Piestany, Piestany - Slovakia (PZY)",
    value: "PZY",
  },
  {
    label: "Poprad, Tatry - Slovakia (TAT)",
    value: "TAT",
  },
  {
    label: "Presov, Presov - Slovakia (POV)",
    value: "POV",
  },
  {
    label: "Sliac, Sliac - Slovakia (SLD)",
    value: "SLD",
  },
  {
    label: "Zilina, Zilina - Slovakia (ILZ)",
    value: "ILZ",
  },
  {
    label: "Ljubljana, Joze Pucnik - Slovenia (LJU)",
    value: "LJU",
  },
  {
    label: "Maribor, Edvard Rusjan - Slovenia (MBX)",
    value: "MBX",
  },
  {
    label: "Portoroz, Portoroz - Slovenia (POW)",
    value: "POW",
  },
  {
    label: "Afutara, Afutara Aerodrome - Solomon Islands (AFT)",
    value: "AFT",
  },
  {
    label: "Arona, Ulawa - Solomon Islands (RNA)",
    value: "RNA",
  },
  {
    label: "Atoifi, Atoifi - Solomon Islands (ATD)",
    value: "ATD",
  },
  {
    label: "Auki, Gwaunaru'u - Solomon Islands (AKS)",
    value: "AKS",
  },
  {
    label: "Avu Avu, Avu Avu - Solomon Islands (AVU)",
    value: "AVU",
  },
  {
    label: "Balalae, Balalae - Solomon Islands (BAS)",
    value: "BAS",
  },
  {
    label: "Barakoma, Barakoma - Solomon Islands (VEV)",
    value: "VEV",
  },
  {
    label: "Barora, Barora - Solomon Islands (RRI)",
    value: "RRI",
  },
  {
    label: "Batuna, Batuna Aerodrome - Solomon Islands (BPF)",
    value: "BPF",
  },
  {
    label: "Bellona, Anua - Solomon Islands (BNY)",
    value: "BNY",
  },
  {
    label: "Choiseul Bay, Choiseul Bay - Solomon Islands (CHY)",
    value: "CHY",
  },
  {
    label: "Fera Island, Fera Island - Solomon Islands (FRE)",
    value: "FRE",
  },
  {
    label: "Gatokae, Gatokae Aerodrom - Solomon Islands (GTA)",
    value: "GTA",
  },
  {
    label: "Geva, Geva - Solomon Islands (GEF)",
    value: "GEF",
  },
  {
    label: "Gizo, Nusatupe - Solomon Islands (GZO)",
    value: "GZO",
  },
  {
    label: "Honiara, International - Solomon Islands (HIR)",
    value: "HIR",
  },
  {
    label: "Jajao, Jajao - Solomon Islands (JJA)",
    value: "JJA",
  },
  {
    label: "Kagau, Kaghau Island - Solomon Islands (KGE)",
    value: "KGE",
  },
  {
    label: "Kirakira, Kirakira - Solomon Islands (IRA)",
    value: "IRA",
  },
  {
    label: "Kukundu, Kukundu - Solomon Islands (KUE)",
    value: "KUE",
  },
  {
    label: "Kwai Harbour, Kwai Harbour - Solomon Islands (KWR)",
    value: "KWR",
  },
  {
    label: "Kwailabesi, Kwailibesi - Solomon Islands (KWS)",
    value: "KWS",
  },
  {
    label: "Marau Sound, Marau - Solomon Islands (RUS)",
    value: "RUS",
  },
  {
    label: "Mbambanakira, Mbambanakira - Solomon Islands (MBU)",
    value: "MBU",
  },
  {
    label: "Mono, Mono Island - Solomon Islands (MNY)",
    value: "MNY",
  },
  {
    label: "Munda, Munda - Solomon Islands (MUA)",
    value: "MUA",
  },
  {
    label: "Onepusu, Onepusu - Solomon Islands (ONE)",
    value: "ONE",
  },
  {
    label: "Otong Java, Ontong Java Atoll - Solomon Islands (OTV)",
    value: "OTV",
  },
  {
    label: "Parasi, Parasi - Solomon Islands (PRS)",
    value: "PRS",
  },
  {
    label: "Ramata, Ramata Island - Solomon Islands (RBV)",
    value: "RBV",
  },
  {
    label: "Rennell, Tigoa - Solomon Islands (RNL)",
    value: "RNL",
  },
  {
    label: "Ringi Cove, Ringgi Cove - Solomon Islands (RIN)",
    value: "RIN",
  },
  {
    label: "Santa Ana, Santa Ana Island - Solomon Islands (NNB)",
    value: "NNB",
  },
  {
    label: "Santa Cruz Is, Santa Cruz Island - Solomon Islands (SCZ)",
    value: "SCZ",
  },
  {
    label: "Savo, Savo Island - Solomon Islands (SVY)",
    value: "SVY",
  },
  {
    label: "Sege, Seghe - Solomon Islands (EGM)",
    value: "EGM",
  },
  {
    label: "Star Harbour, Nana - Solomon Islands (NAZ)",
    value: "NAZ",
  },
  {
    label: "Suavanao, Suavanao Airstrip - Solomon Islands (VAO)",
    value: "VAO",
  },
  {
    label: "Tarapaina, Tarapaina - Solomon Islands (TAA)",
    value: "TAA",
  },
  {
    label: "Tulagi Island, Heliport - Solomon Islands (TLG)",
    value: "TLG",
  },
  {
    label: "Viru, Viru Harbour - Solomon Islands (VIU)",
    value: "VIU",
  },
  {
    label: "Yandina, Yandina - Solomon Islands (XYA)",
    value: "XYA",
  },
  {
    label: "Alula, Alula - Somalia (ALU)",
    value: "ALU",
  },
  {
    label: "Baidoa, Baidoa - Somalia (BIB)",
    value: "BIB",
  },
  {
    label: "Bardera, Bardera - Somalia (BSY)",
    value: "BSY",
  },
  {
    label: "Berbera, Berbera - Somalia (BBO)",
    value: "BBO",
  },
  {
    label: "Borama, Borama - Somalia (BXX)",
    value: "BXX",
  },
  {
    label: "Bosaso, International - Somalia (BSA)",
    value: "BSA",
  },
  {
    label: "Burao, Burao - Somalia (BUO)",
    value: "BUO",
  },
  {
    label: "Candala, Candala - Somalia (CXN)",
    value: "CXN",
  },
  {
    label: "Eil, Eil - Somalia (HCM)",
    value: "HCM",
  },
  {
    label: "Erigavo, Erigavo - Somalia (ERA)",
    value: "ERA",
  },
  {
    label: "Galcaio, Galcaio - Somalia (GLK)",
    value: "GLK",
  },
  {
    label: "Garbaharey, Garbaharey - Somalia (GBM)",
    value: "GBM",
  },
  {
    label: "Gardo, Gardo - Somalia (GSR)",
    value: "GSR",
  },
  {
    label: "Garowe, Garowe - Somalia (GGR)",
    value: "GGR",
  },
  {
    label: "Hargeisa, Egal Intl - Somalia (HGA)",
    value: "HGA",
  },
  {
    label: "Kisimayu, Kisimayu - Somalia (KMU)",
    value: "KMU",
  },
  {
    label: "Las Khoreh, Las Khoreh - Somalia (LKR)",
    value: "LKR",
  },
  {
    label: "Lugh Ganane, Lugh Ganane - Somalia (LGX)",
    value: "LGX",
  },
  {
    label: "Mogadishu, Aden Adde Intl - Somalia (MGQ)",
    value: "MGQ",
  },
  {
    label: "Obbia, Obbia - Somalia (CMO)",
    value: "CMO",
  },
  {
    label: "Scusciuban, Scusciuban - Somalia (CMS)",
    value: "CMS",
  },
  {
    label: "Aggeneys, Aggeneys - South Africa (AGZ)",
    value: "AGZ",
  },
  {
    label: "Alexander Bay, Kortdoorn - South Africa (ALJ)",
    value: "ALJ",
  },
  {
    label: "Alldays, Alldays - South Africa (ADY)",
    value: "ADY",
  },
  {
    label: "Arathusa Safari Lodge, Arathusa Safari Lodge - South Africa (ASS)",
    value: "ASS",
  },
  {
    label: "Bhisho, Bhisho - South Africa (BIY)",
    value: "BIY",
  },
  {
    label: "Bloemfontein, Bloemfontein Intl - South Africa (BFN)",
    value: "BFN",
  },
  {
    label: "Cape Town, Cape Town Intl - South Africa (CPT)",
    value: "CPT",
  },
  {
    label: "Cradock, Cradock - South Africa (CDO)",
    value: "CDO",
  },
  {
    label: "Durban, King Shaka Intl - South Africa (DUR)",
    value: "DUR",
  },
  {
    label: "Durban, Virginia - South Africa (VIR)",
    value: "VIR",
  },
  {
    label: "East London, East London - South Africa (ELS)",
    value: "ELS",
  },
  {
    label: "Ellisras, Matimba - South Africa (ELL)",
    value: "ELL",
  },
  {
    label: "Empangeni, Empangeni - South Africa (EMG)",
    value: "EMG",
  },
  {
    label: "Ficksburg, Ficksburg - South Africa (FCB)",
    value: "FCB",
  },
  {
    label: "George, George - South Africa (GRJ)",
    value: "GRJ",
  },
  {
    label: "Giyani, Giyani - South Africa (GIY)",
    value: "GIY",
  },
  {
    label: "Harrismith, Harrismith - South Africa (HRS)",
    value: "HRS",
  },
  {
    label: "Hazyview, Hazyview - South Africa (HZV)",
    value: "HZV",
  },
  {
    label: "Hluhluwe, Hluhluwe - South Africa (HLW)",
    value: "HLW",
  },
  {
    label: "Hoedspruit, Afb - South Africa (HDS)",
    value: "HDS",
  },
  {
    label: "Inyati, Inyati - South Africa (INY)",
    value: "INY",
  },
  {
    label: "Johannesburg, Grand Central - South Africa (GCJ)",
    value: "GCJ",
  },
  {
    label: "Johannesburg, Lanseria Intl Apt - South Africa (HLA)",
    value: "HLA",
  },
  {
    label: "Johannesburg, O.R. Tambo Intl - South Africa (JNB)",
    value: "JNB",
  },
  {
    label: "Johannesburg, Rand - South Africa (QRA)",
    value: "QRA",
  },
  {
    label: "Kimberley, Kimberley - South Africa (KIM)",
    value: "KIM",
  },
  {
    label: "Kleinsee, Kleinsee - South Africa (KLZ)",
    value: "KLZ",
  },
  {
    label: "Klerksdorp, P.C Pelser - South Africa (KXE)",
    value: "KXE",
  },
  {
    label: "Koingnaas, Koingnaas - South Africa (KIG)",
    value: "KIG",
  },
  {
    label: "Komatipoort, Komatipoort - South Africa (KOF)",
    value: "KOF",
  },
  {
    label: "Kuruman, Johan Pienaar - South Africa (KMH)",
    value: "KMH",
  },
  {
    label: "Ladysmith, Ladysmith - South Africa (LAY)",
    value: "LAY",
  },
  {
    label: "Lime Acres, Finsch Mine - South Africa (LMR)",
    value: "LMR",
  },
  {
    label: "Londolozi, Londolozi - South Africa (LDZ)",
    value: "LDZ",
  },
  {
    label: "Louis Trichardt, Louis Trichardt - South Africa (LCD)",
    value: "LCD",
  },
  {
    label: "Mala Mala, Mala Mala - South Africa (AAM)",
    value: "AAM",
  },
  {
    label: "Malelane, Malelane - South Africa (LLE)",
    value: "LLE",
  },
  {
    label: "Manyeleti, Khoya Moya - South Africa (KHO)",
    value: "KHO",
  },
  {
    label: "Margate, Margate - South Africa (MGH)",
    value: "MGH",
  },
  {
    label: "Messina, Messina - South Africa (MEZ)",
    value: "MEZ",
  },
  {
    label: "Mkambati, Mkambati - South Africa (MBM)",
    value: "MBM",
  },
  {
    label: "Mkuze, Mkuze - South Africa (MZQ)",
    value: "MZQ",
  },
  {
    label: "Mmabatho Mafikeng, Mmabatho Intl - South Africa (MBD)",
    value: "MBD",
  },
  {
    label: "Mossel Bay, Mossel Bay - South Africa (MZY)",
    value: "MZY",
  },
  {
    label: "Motswari, Motswari - South Africa (MWR)",
    value: "MWR",
  },
  {
    label: "Mtubatuba, Dukuduku - South Africa (DUK)",
    value: "DUK",
  },
  {
    label: "Mzamba, Wild Cost Sun - South Africa (MZF)",
    value: "MZF",
  },
  {
    label: "Nelspruit, Kruger Mpumalanga Intl - South Africa (MQP)",
    value: "MQP",
  },
  {
    label: "Nelspruit, Nelspruit Airport - South Africa (NLP)",
    value: "NLP",
  },
  {
    label: "Newcastle, Newcastle - South Africa (NCS)",
    value: "NCS",
  },
  {
    label: "Ngala, Ngala - South Africa (NGL)",
    value: "NGL",
  },
  {
    label: "Oudtshoorn, Oudtshoorn - South Africa (OUH)",
    value: "OUH",
  },
  {
    label: "Overberg, Afb - South Africa (OVG)",
    value: "OVG",
  },
  {
    label: "Phalaborwa, Hendrik Van Eck - South Africa (PHW)",
    value: "PHW",
  },
  {
    label: "Pietermaritzburg, Pietermaritzburg - South Africa (PZB)",
    value: "PZB",
  },
  {
    label: "Plettenberg Bay, Plettenberg Bay - South Africa (PBZ)",
    value: "PBZ",
  },
  {
    label: "Polokwane, International - South Africa (PTG)",
    value: "PTG",
  },
  {
    label: "Port Alfred, Port Alfred - South Africa (AFD)",
    value: "AFD",
  },
  {
    label: "Port Elizabeth, Port Elizabeth - South Africa (PLZ)",
    value: "PLZ",
  },
  {
    label: "Port Saint John S, Port Saint John S - South Africa (JOH)",
    value: "JOH",
  },
  {
    label: "Potchefstroom, Potchefstroom - South Africa (PCF)",
    value: "PCF",
  },
  {
    label: "Pretoria, Waterkloof Afb - South Africa (WKF)",
    value: "WKF",
  },
  {
    label: "Pretoria, Wonderboom - South Africa (PRY)",
    value: "PRY",
  },
  {
    label: "Prieska, Prieska - South Africa (PRK)",
    value: "PRK",
  },
  {
    label: "Queenstown, Queenstown - South Africa (UTW)",
    value: "UTW",
  },
  {
    label: "Reivilo, Reivilo - South Africa (RVO)",
    value: "RVO",
  },
  {
    label: "Richards Bay, Richards Bay - South Africa (RCB)",
    value: "RCB",
  },
  {
    label: "Robertson, Robertson - South Africa (ROD)",
    value: "ROD",
  },
  {
    label: "Sabie, Sabi Sabi - South Africa (GSS)",
    value: "GSS",
  },
  {
    label: "Saldanha Bay, Langebaanweg Afb - South Africa (SDB)",
    value: "SDB",
  },
  {
    label: "Secunda, Secunda - South Africa (ZEC)",
    value: "ZEC",
  },
  {
    label: "Singita Safari Lodge, Singita Safari Lodge - South Africa (SSX)",
    value: "SSX",
  },
  {
    label: "Sishen, Sishen - South Africa (SIS)",
    value: "SIS",
  },
  {
    label: "Skukuza, Skukuza - South Africa (SZK)",
    value: "SZK",
  },
  {
    label: "Springbok, Springbok - South Africa (SBU)",
    value: "SBU",
  },
  {
    label: "Sun City, Pilanesberg Inlt - South Africa (NTY)",
    value: "NTY",
  },
  {
    label: "Thaba Nchu, Thaba Nchu - South Africa (TCU)",
    value: "TCU",
  },
  {
    label: "Thohoyandou, P.R Mphephu - South Africa (THY)",
    value: "THY",
  },
  {
    label: "Timbavati, Tanda Tula - South Africa (TDT)",
    value: "TDT",
  },
  {
    label: "Tshipise, Tshipise - South Africa (TSD)",
    value: "TSD",
  },
  {
    label: "Tzaneen, Tzaneen - South Africa (LTA)",
    value: "LTA",
  },
  {
    label: "Ulundi, Prince M Buthelezi - South Africa (ULD)",
    value: "ULD",
  },
  {
    label: "Ulusaba, Ulusaba - South Africa (ULX)",
    value: "ULX",
  },
  {
    label: "Umtata, K.D Matanzima - South Africa (UTT)",
    value: "UTT",
  },
  {
    label: "Upington, Upington Mnpl - South Africa (UTN)",
    value: "UTN",
  },
  {
    label: "Vredendal, Vredendal - South Africa (VRE)",
    value: "VRE",
  },
  {
    label: "Vryburg, Vryburg - South Africa (VRU)",
    value: "VRU",
  },
  {
    label: "Vryheid, Vryheid - South Africa (VYD)",
    value: "VYD",
  },
  {
    label: "Welkom, Welkom - South Africa (WEL)",
    value: "WEL",
  },
  {
    label: "Adareil, Adareil - South Sudan (AEE)",
    value: "AEE",
  },
  {
    label: "Juba, Juba - South Sudan (JUB)",
    value: "JUB",
  },
  {
    label: "Malakal, Malakal - South Sudan (MAK)",
    value: "MAK",
  },
  {
    label: "Rumbek, Rumbek - South Sudan (RBX)",
    value: "RBX",
  },
  {
    label: "Wau, Wau - South Sudan (WUU)",
    value: "WUU",
  },
  {
    label: "Alicante - Spain (ALC)",
    value: "ALC",
  },
  {
    label: "Alicante, Alicante Airport - Spain (ALC)",
    value: "ALC",
  },
  {
    label: "Almeria - Spain (LEI)",
    value: "LEI",
  },
  {
    label: "Almeria, Almeria - Spain (LEI)",
    value: "LEI",
  },
  {
    label: "Barcelona - Spain (BCN)",
    value: "BCN",
  },
  {
    label: "Barcelona, Airport - Spain (BCN)",
    value: "BCN",
  },
  {
    label: "Bilbao - Spain (BIO)",
    value: "BIO",
  },
  {
    label: "Bilbao, Bilbao Airport - Spain (BIO)",
    value: "BIO",
  },
  {
    label: "A Coruna, A Coruna Airport - Spain (LCG)",
    value: "LCG",
  },
  {
    label: "Albacete, Airport - Spain (ABC)",
    value: "ABC",
  },
  {
    label: "Badajoz, Badajoz - Spain (BJZ)",
    value: "BJZ",
  },
  {
    label: "Burgos, Burgos - Spain (RGS)",
    value: "RGS",
  },
  {
    label: "Castellon De La Plana, Castellon - Spain (CDT)",
    value: "CDT",
  },
  {
    label: "Ceuta, Heliport - Spain (JCU)",
    value: "JCU",
  },
  {
    label: "Ciudad Real, Central - Spain (CQM)",
    value: "CQM",
  },
  {
    label: "Cordoba, Cordoba Airport - Spain (ODB)",
    value: "ODB",
  },
  {
    label: "Corvera, International - Spain (RMU)",
    value: "RMU",
  },
  {
    label: "Fuerteventura, Fuerteventura - Spain (FUE)",
    value: "FUE",
  },
  {
    label: "Girona, Costa Brava - Spain (GRO)",
    value: "GRO",
  },
  {
    label: "Granada, Federico Garcia Lorca - Spain (GRX)",
    value: "GRX",
  },
  {
    label: "Huesca, Pirineos - Spain (HSK)",
    value: "HSK",
  },
  {
    label: "Ibiza, Ibiza - Spain (IBZ)",
    value: "IBZ",
  },
  {
    label: "Jerez, Jerez Airport - Spain (XRY)",
    value: "XRY",
  },
  {
    label: "La Graciosa, Ferry Port - Spain (GGA)",
    value: "GGA",
  },
  {
    label: "Lanzarote, Lanzarote - Spain (ACE)",
    value: "ACE",
  },
  {
    label: "Leon, Leon - Spain (LEN)",
    value: "LEN",
  },
  {
    label: "Lleida, Alguaire - Spain (ILD)",
    value: "ILD",
  },
  {
    label: "Logrono, Agoncillo - Spain (RJL)",
    value: "RJL",
  },
  {
    label: "Madrid, Adolfo Suarez Barajas - Spain (MAD)",
    value: "MAD",
  },
  {
    label: "Madrid, Torrejon - Spain (TOJ)",
    value: "TOJ",
  },
  {
    label: "Malaga, Malaga Airport - Spain (AGP)",
    value: "AGP",
  },
  {
    label: "Melilla, Melilla - Spain (MLN)",
    value: "MLN",
  },
  {
    label: "Menorca, Menorca - Spain (MAH)",
    value: "MAH",
  },
  {
    label: "Moron, Air Base - Spain (OZP)",
    value: "OZP",
  },
  {
    label: "Murcia, San Javier - Spain (MJV)",
    value: "MJV",
  },
  {
    label: "Orzola, Ferry Port - Spain (OZL)",
    value: "OZL",
  },
  {
    label: "Oviedo, Asturias - Spain (OVD)",
    value: "OVD",
  },
  {
    label: "Palma Mallorca, Palma Mallorca - Spain (PMI)",
    value: "PMI",
  },
  {
    label: "Pamplona, Pamplona - Spain (PNA)",
    value: "PNA",
  },
  {
    label: "Reus, Reus - Spain (REU)",
    value: "REU",
  },
  {
    label: "Rota, Us Naval Station - Spain (ROZ)",
    value: "ROZ",
  },
  {
    label: "Salamanca, Salamanca Airport - Spain (SLM)",
    value: "SLM",
  },
  {
    label: "San Pedro Alcantara, San Pedro Alcantara Bu - Spain (ZRC)",
    value: "ZRC",
  },
  {
    label: "San Sebastian, San Sebastian Airport - Spain (EAS)",
    value: "EAS",
  },
  {
    label: "San Sebastian Gomera, La Gomera - Spain (GMZ)",
    value: "GMZ",
  },
  {
    label: "Santa Cruz De La Palma, La Palma - Spain (SPC)",
    value: "SPC",
  },
  {
    label: "Santander, Santander Airport - Spain (SDR)",
    value: "SDR",
  },
  {
    label: "Santiago De Compostela, Santiago De Compostela - Spain (SCQ)",
    value: "SCQ",
  },
  {
    label: "Seo De Urgel, Seo De Urgel - Spain (LEU)",
    value: "LEU",
  },
  {
    label: "Sevilla, Sevilla Airport - Spain (SVQ)",
    value: "SVQ",
  },
  {
    label: "Tenerife, Tenerife Norte - Spain (TFN)",
    value: "TFN",
  },
  {
    label: "Tenerife, Tenerife Sur - Spain (TFS)",
    value: "TFS",
  },
  {
    label: "Teruel, Airport - Spain (TEV)",
    value: "TEV",
  },
  {
    label: "Valencia, Valencia Airport - Spain (VLC)",
    value: "VLC",
  },
  {
    label: "Valladolid, Airport - Spain (VLL)",
    value: "VLL",
  },
  {
    label: "Valladolid, Campo Grande Rail Stn - Spain (XIV)",
    value: "XIV",
  },
  {
    label: "Valverde, El Hierro - Spain (VDE)",
    value: "VDE",
  },
  {
    label: "Vigo, Vigo Airport - Spain (VGO)",
    value: "VGO",
  },
  {
    label: "Vitoria, Vitoria - Spain (VIT)",
    value: "VIT",
  },
  {
    label: "Zaragoza, Zaragoza - Spain (ZAZ)",
    value: "ZAZ",
  },
  {
    label: "Ampara, Gal Oya - Sri Lanka (ADP)",
    value: "ADP",
  },
  {
    label: "Ampara, Kondavattavan Tank Spb - Sri Lanka (AFK)",
    value: "AFK",
  },
  {
    label: "Anuradhapura, Anuradhapura - Sri Lanka (ACJ)",
    value: "ACJ",
  },
  {
    label: "Batticaloa, Airport - Sri Lanka (BTC)",
    value: "BTC",
  },
  {
    label: "Batticaloa, Passikudah Spb - Sri Lanka (PQD)",
    value: "PQD",
  },
  {
    label: "Bentota, Bentota River Spb - Sri Lanka (BJT)",
    value: "BJT",
  },
  {
    label: "Colombo, Bandaranaike Intl - Sri Lanka (CMB)",
    value: "CMB",
  },
  {
    label: "Colombo, Beira Lake - Sri Lanka (BYV)",
    value: "BYV",
  },
  {
    label: "Colombo, Dandugama - Sri Lanka (DGM)",
    value: "DGM",
  },
  {
    label: "Colombo, Kelani Peliyagooda Spb - Sri Lanka (KEZ)",
    value: "KEZ",
  },
  {
    label: "Colombo, Ratmalana - Sri Lanka (RML)",
    value: "RML",
  },
  {
    label: "Dambula, Dambulu Oya Tank Spb - Sri Lanka (DBU)",
    value: "DBU",
  },
  {
    label: "Dickwella, Mawella Lagoon Spb - Sri Lanka (DIW)",
    value: "DIW",
  },
  {
    label: "Hambantota, Mattala Rajapaksa Intl - Sri Lanka (HRI)",
    value: "HRI",
  },
  {
    label: "Hambantota, Spb - Sri Lanka (HBT)",
    value: "HBT",
  },
  {
    label: "Hatton, Castlereigh Resrvr Spb - Sri Lanka (NUF)",
    value: "NUF",
  },
  {
    label: "Hingurakgoda, Minneriya - Sri Lanka (HIM)",
    value: "HIM",
  },
  {
    label: "Iranamadu, Spb - Sri Lanka (IRU)",
    value: "IRU",
  },
  {
    label: "Jaffna, Kankesanturai - Sri Lanka (JAF)",
    value: "JAF",
  },
  {
    label: "Kalpitiya, Kalpitiya - Sri Lanka (DBK)",
    value: "DBK",
  },
  {
    label: "Kandy, Polgolla Reservoir Spb - Sri Lanka (KDZ)",
    value: "KDZ",
  },
  {
    label: "Kandy, Victoria Reservoir Spb - Sri Lanka (KDW)",
    value: "KDW",
  },
  {
    label: "Katukurunda, Katukurunda - Sri Lanka (KTY)",
    value: "KTY",
  },
  {
    label: "Koggala, Koggala - Sri Lanka (KCT)",
    value: "KCT",
  },
  {
    label: "Kotte, Diyawanna Oya - Sri Lanka (DWO)",
    value: "DWO",
  },
  {
    label: "Nuwara Eliya, Gregory Lake Spb - Sri Lanka (NUA)",
    value: "NUA",
  },
  {
    label: "Pothuvil, Arugam Bay Spb - Sri Lanka (AYY)",
    value: "AYY",
  },
  {
    label: "Sigiriya, Sigiriya - Sri Lanka (GIU)",
    value: "GIU",
  },
  {
    label: "Tissa Tank, Tissa Tank Spb - Sri Lanka (TTW)",
    value: "TTW",
  },
  {
    label: "Trincomalee, China Bay - Sri Lanka (TRR)",
    value: "TRR",
  },
  {
    label: "Trincomalee, Harbour Spb - Sri Lanka (THW)",
    value: "THW",
  },
  {
    label: "Weerawila, Weerawila - Sri Lanka (WRZ)",
    value: "WRZ",
  },
  {
    label: "Ascension Island, Raf Station - St. Helena Island (ASI)",
    value: "ASI",
  },
  {
    label: "Jamestown, Saint Helena - St. Helena Island (HLE)",
    value: "HLE",
  },
  {
    label: "Nevis, Vance W.Amory Intl - St. Kitts (NEV)",
    value: "NEV",
  },
  {
    label: "Saint Kitts, Robert L Bradshaw Int - St. Kitts (SKB)",
    value: "SKB",
  },
  {
    label: "Saint Lucia, George F.L.Charles - St. Lucia (SLU)",
    value: "SLU",
  },
  {
    label: "Saint Lucia, Hewanorra Intl - St. Lucia (UVF)",
    value: "UVF",
  },
  {
    label: "Miquelon, Miquelon - St. Pierre And Miquelon (MQC)",
    value: "MQC",
  },
  {
    label: "Saint Pierre, Saint Pierre - St. Pierre And Miquelon (FSP)",
    value: "FSP",
  },
  {
    label: "Canouan Island, Canouan - St. Vincent (CIW)",
    value: "CIW",
  },
  {
    label: "Mustique Island, Mustique - St. Vincent (MQS)",
    value: "MQS",
  },
  {
    label: "Palm Island, Palm Island - St. Vincent (PLI)",
    value: "PLI",
  },
  {
    label: "Port Elizabeth, Bequia - St. Vincent (BQU)",
    value: "BQU",
  },
  {
    label: "Saint Vincent, Saint Vincent - St. Vincent (SVD)",
    value: "SVD",
  },
  {
    label: "Union Island, Union Island - St. Vincent (UNI)",
    value: "UNI",
  },
  {
    label: "Atbara, Atbara - Sudan (ATB)",
    value: "ATB",
  },
  {
    label: "Baleela, Baleela - Sudan (BJE)",
    value: "BJE",
  },
  {
    label: "Damazin, Damazin - Sudan (RSS)",
    value: "RSS",
  },
  {
    label: "Dinder, Galegu - Sudan (DNX)",
    value: "DNX",
  },
  {
    label: "Dongola, Dongola - Sudan (DOG)",
    value: "DOG",
  },
  {
    label: "El Debba, El Debba - Sudan (EDB)",
    value: "EDB",
  },
  {
    label: "El Fasher, El Fasher - Sudan (ELF)",
    value: "ELF",
  },
  {
    label: "El Obeid, El Obeid - Sudan (EBD)",
    value: "EBD",
  },
  {
    label: "En Nahud, En Nahud - Sudan (NUD)",
    value: "NUD",
  },
  {
    label: "Gedaref, Gedaref - Sudan (GSU)",
    value: "GSU",
  },
  {
    label: "Geneina, Geneina - Sudan (EGN)",
    value: "EGN",
  },
  {
    label: "Kadugli, Kadugli - Sudan (KDX)",
    value: "KDX",
  },
  {
    label: "Kassala, Kassala - Sudan (KSL)",
    value: "KSL",
  },
  {
    label: "Khartoum, International - Sudan (KRT)",
    value: "KRT",
  },
  {
    label: "Khashm El Girba, Khashm El Girba - Sudan (GBU)",
    value: "GBU",
  },
  {
    label: "Kosti, Rabak - Sudan (KST)",
    value: "KST",
  },
  {
    label: "Merowe, Merowe - Sudan (MWE)",
    value: "MWE",
  },
  {
    label: "New Halfa, New Halfa - Sudan (NHF)",
    value: "NHF",
  },
  {
    label: "Nyala, Nyala - Sudan (UYL)",
    value: "UYL",
  },
  {
    label: "Port Sudan, New International - Sudan (PZU)",
    value: "PZU",
  },
  {
    label: "Wad Madani, Wad Madani - Sudan (DNI)",
    value: "DNI",
  },
  {
    label: "Wadi Halfa, Wadi Halfa - Sudan (WHF)",
    value: "WHF",
  },
  {
    label: "Zalengei, Zalengei - Sudan (ZLX)",
    value: "ZLX",
  },
  {
    label: "Albina, Albina - Suriname (ABN)",
    value: "ABN",
  },
  {
    label: "Awaradam, Cajana - Suriname (AAJ)",
    value: "AAJ",
  },
  {
    label: "Botopasie, Botopasi - Suriname (BTO)",
    value: "BTO",
  },
  {
    label: "Djoemoe, Djoemoe - Suriname (DOE)",
    value: "DOE",
  },
  {
    label: "Drietabbetje, Drietabbetje - Suriname (DRJ)",
    value: "DRJ",
  },
  {
    label: "Kasikasima, Tepoe - Suriname (KCB)",
    value: "KCB",
  },
  {
    label: "Ladouanie, Laduani - Suriname (LDO)",
    value: "LDO",
  },
  {
    label: "Moengo, Moengo - Suriname (MOJ)",
    value: "MOJ",
  },
  {
    label: "Nieuw Nickerie, Majoor H.Fernandes - Suriname (ICK)",
    value: "ICK",
  },
  {
    label: "Paloemeu, Vincent Fayks - Suriname (OEM)",
    value: "OEM",
  },
  {
    label: "Paramaribo, Johan A Pengel Intl - Suriname (PBM)",
    value: "PBM",
  },
  {
    label: "Paramaribo, Zorg En Hoop - Suriname (ORG)",
    value: "ORG",
  },
  {
    label: "Stoelmans Eiland, Stoelmanseiland - Suriname (SMZ)",
    value: "SMZ",
  },
  {
    label: "Totness, Totness - Suriname (TOT)",
    value: "TOT",
  },
  {
    label: "Wageningen, Wageningen - Suriname (AGI)",
    value: "AGI",
  },
  {
    label: "Washabo, Wasjabo - Suriname (WSO)",
    value: "WSO",
  },
  {
    label: "Manzini, King Mswati Iii Intl - Swaziland (SHO)",
    value: "SHO",
  },
  {
    label: "Manzini, Matsapha Intl - Swaziland (MTS)",
    value: "MTS",
  },
  {
    label: "Angelholm Helsingborg, Angelholm Airport - Sweden (AGH)",
    value: "AGH",
  },
  {
    label: "Angelholm Helsingborg, Helsingborg Hpt - Sweden (JHE)",
    value: "JHE",
  },
  {
    label: "Are Ostersund, Are Ostersund - Sweden (OSD)",
    value: "OSD",
  },
  {
    label: "Arvidsjaur, Arvidsjaur - Sweden (AJR)",
    value: "AJR",
  },
  {
    label: "Borlange/Falun, Dala Airport - Sweden (BLE)",
    value: "BLE",
  },
  {
    label: "Eskilstuna, Eskilstuna - Sweden (EKT)",
    value: "EKT",
  },
  {
    label: "Gallivare, Gallivare - Sweden (GEV)",
    value: "GEV",
  },
  {
    label: "Gavle, Sandviken - Sweden (GVX)",
    value: "GVX",
  },
  {
    label: "Goteborg, City Airport - Sweden (GSE)",
    value: "GSE",
  },
  {
    label: "Goteborg, Landvetter - Sweden (GOT)",
    value: "GOT",
  },
  {
    label: "Hagfors, Hagfors - Sweden (HFS)",
    value: "HFS",
  },
  {
    label: "Halmstad, Halmstad - Sweden (HAD)",
    value: "HAD",
  },
  {
    label: "Hemavan Tarnaby, Hemavan Tarnaby - Sweden (HMV)",
    value: "HMV",
  },
  {
    label: "Hudiksvall, Hudiksvall - Sweden (HUV)",
    value: "HUV",
  },
  {
    label: "Hultsfred/Vimmerby, Hultsfred - Sweden (HLF)",
    value: "HLF",
  },
  {
    label: "Idre, Idre - Sweden (IDB)",
    value: "IDB",
  },
  {
    label: "Jonkoping, Jonkoping - Sweden (JKG)",
    value: "JKG",
  },
  {
    label: "Kalmar, Kalmar - Sweden (KLR)",
    value: "KLR",
  },
  {
    label: "Karlskoga, Karlskoga - Sweden (KSK)",
    value: "KSK",
  },
  {
    label: "Karlstad, Karlstad - Sweden (KSD)",
    value: "KSD",
  },
  {
    label: "Kiruna, Kiruna - Sweden (KRN)",
    value: "KRN",
  },
  {
    label: "Kramfors Solleftea, Kramfors Solleftea - Sweden (KRF)",
    value: "KRF",
  },
  {
    label: "Kristianstad, Kristianstad - Sweden (KID)",
    value: "KID",
  },
  {
    label: "Landskrona, Heliport - Sweden (JLD)",
    value: "JLD",
  },
  {
    label: "Lidkoping, Hovby - Sweden (LDK)",
    value: "LDK",
  },
  {
    label: "Linkoping, City Airport - Sweden (LPI)",
    value: "LPI",
  },
  {
    label: "Lulea, Kallax - Sweden (LLA)",
    value: "LLA",
  },
  {
    label: "Lycksele, Lycksele - Sweden (LYC)",
    value: "LYC",
  },
  {
    label: "Malmo, Malmo - Sweden (MMX)",
    value: "MMX",
  },
  {
    label: "Malmo, Malmo Harbour Hpt - Sweden (JMM)",
    value: "JMM",
  },
  {
    label: "Mora, Siljan - Sweden (MXX)",
    value: "MXX",
  },
  {
    label: "Norrkoping, Kungsangen - Sweden (NRK)",
    value: "NRK",
  },
  {
    label: "Orebro, Orebro Airport - Sweden (ORB)",
    value: "ORB",
  },
  {
    label: "Ornskoldsvik, Ornskoldsvik - Sweden (OER)",
    value: "OER",
  },
  {
    label: "Oskarshamn, Oskarshamn - Sweden (OSK)",
    value: "OSK",
  },
  {
    label: "Pajala, Pajala - Sweden (PJA)",
    value: "PJA",
  },
  {
    label: "Pass Rail  Generic), Pass Rail  Generic) - Sweden (XZQ)",
    value: "XZQ",
  },
  {
    label: "Ronneby Karlskrona, Kallinge - Sweden (RNB)",
    value: "RNB",
  },
  {
    label: "Skelleftea, Skelleftea - Sweden (SFT)",
    value: "SFT",
  },
  {
    label: "Skovde, Skovde - Sweden (KVB)",
    value: "KVB",
  },
  {
    label: "Soderhamn, Soderhamn - Sweden (SOO)",
    value: "SOO",
  },
  {
    label: "Sodertalje, Heliport - Sweden (JSO)",
    value: "JSO",
  },
  {
    label: "Sodertalje, Sodertalje S Rail Stn - Sweden (XEZ)",
    value: "XEZ",
  },
  {
    label: "Stockholm, Arlanda - Sweden (ARN)",
    value: "ARN",
  },
  {
    label: "Stockholm, Bromma - Sweden (BMA)",
    value: "BMA",
  },
  {
    label: "Stockholm, Skavsta Intl - Sweden (NYO)",
    value: "NYO",
  },
  {
    label: "Stockholm, Vasteras - Sweden (VST)",
    value: "VST",
  },
  {
    label: "Storuman, Storuman - Sweden (SQO)",
    value: "SQO",
  },
  {
    label: "Sundsvall Harnosand, Sundsvall-Timra - Sweden (SDL)",
    value: "SDL",
  },
  {
    label: "Sveg, Sveg - Sweden (EVG)",
    value: "EVG",
  },
  {
    label: "Torsby, Torsby Apt - Sweden (TYF)",
    value: "TYF",
  },
  {
    label: "Trollhattan/Vanersborg, Trollhattan - Sweden (THN)",
    value: "THN",
  },
  {
    label: "Umea, Umea - Sweden (UME)",
    value: "UME",
  },
  {
    label: "Vastervik, Vastervik - Sweden (VVK)",
    value: "VVK",
  },
  {
    label: "Vaxjo, Smaland - Sweden (VXO)",
    value: "VXO",
  },
  {
    label: "Vilhelmina, Vilhelmina - Sweden (VHM)",
    value: "VHM",
  },
  {
    label: "Visby, Visby - Sweden (VBY)",
    value: "VBY",
  },
  {
    label: "Aarau, Aarau - Switzerland (ZDA)",
    value: "ZDA",
  },
  {
    label: "Aigle, Aigle - Switzerland (ZDC)",
    value: "ZDC",
  },
  {
    label: "Altenrhein, Saint Gallen-Altenrhein - Switzerland (ACH)",
    value: "ACH",
  },
  {
    label: "Arbon, Arbon - Switzerland (ZDD)",
    value: "ZDD",
  },
  {
    label: "Arosa, Arosa - Switzerland (ZDE)",
    value: "ZDE",
  },
  {
    label: "Baden, Baden - Switzerland (ZDG)",
    value: "ZDG",
  },
  {
    label: "Bellinzona, Bellinzona - Switzerland (ZDI)",
    value: "ZDI",
  },
  {
    label: "Bern, Belp - Switzerland (BRN)",
    value: "BRN",
  },
  {
    label: "Biel, Biel - Switzerland (ZDK)",
    value: "ZDK",
  },
  {
    label: "Brig, Brig - Switzerland (ZDL)",
    value: "ZDL",
  },
  {
    label: "Buchs Sg, Buchs Sg - Switzerland (ZDO)",
    value: "ZDO",
  },
  {
    label: "Buochs, Buochs - Switzerland (BXO)",
    value: "BXO",
  },
  {
    label: "Burgdorf, Burgdorf - Switzerland (ZDP)",
    value: "ZDP",
  },
  {
    label: "Champery, Champery - Switzerland (ZDQ)",
    value: "ZDQ",
  },
  {
    label: "Chateau D Oex, Chateau D Oex - Switzerland (ZDR)",
    value: "ZDR",
  },
  {
    label: "Chiasso, Chiasso - Switzerland (ZDS)",
    value: "ZDS",
  },
  {
    label: "Chur, Chur - Switzerland (ZDT)",
    value: "ZDT",
  },
  {
    label: "Delemont, Delemont - Switzerland (ZDW)",
    value: "ZDW",
  },
  {
    label: "Einsiedeln, Einsiedeln - Switzerland (ZDZ)",
    value: "ZDZ",
  },
  {
    label: "Emmen, Emmen - Switzerland (EML)",
    value: "EML",
  },
  {
    label: "Engelberg, Engelberg - Switzerland (ZHB)",
    value: "ZHB",
  },
  {
    label: "Fluelen, Fluelen - Switzerland (ZHD)",
    value: "ZHD",
  },
  {
    label: "Frauenfeld, Frauenfeld - Switzerland (ZHE)",
    value: "ZHE",
  },
  {
    label: "Fribourg, Fribourg - Switzerland (ZHF)",
    value: "ZHF",
  },
  {
    label: "Geneva, Geneva International - Switzerland (GVA)",
    value: "GVA",
  },
  {
    label: "Geneva Cornavin, Geneva Cornavin Railwa - Switzerland (ZHT)",
    value: "ZHT",
  },
  {
    label: "Glarus, Glarus - Switzerland (ZHG)",
    value: "ZHG",
  },
  {
    label: "Gossau Sg, Gossau Sg - Switzerland (ZHH)",
    value: "ZHH",
  },
  {
    label: "Grenchen, Grenchen Sued Rail Stn - Switzerland (ZHI)",
    value: "ZHI",
  },
  {
    label: "Grindelwald, Grindelwald - Switzerland (ZHJ)",
    value: "ZHJ",
  },
  {
    label: "Gstaad, Gstaad - Switzerland (ZHK)",
    value: "ZHK",
  },
  {
    label: "Heerbrugg, Heerbrugg - Switzerland (ZHL)",
    value: "ZHL",
  },
  {
    label: "Herzogenbuchsee, Herzogenbuchsee - Switzerland (ZHN)",
    value: "ZHN",
  },
  {
    label: "Interlaken Ost, Interlaken Ost Rail St - Switzerland (ZIN)",
    value: "ZIN",
  },
  {
    label: "Kandersteg, Kandersteg - Switzerland (ZHR)",
    value: "ZHR",
  },
  {
    label: "Klosters, Klosters - Switzerland (ZHS)",
    value: "ZHS",
  },
  {
    label: "Kreuzlingen, Kreuzlingen Rail St - Switzerland (ZHU)",
    value: "ZHU",
  },
  {
    label: "La Chaux De Fonds, La Chaux De Fonds - Switzerland (ZHV)",
    value: "ZHV",
  },
  {
    label: "Langenthal, Langenthal - Switzerland (ZHW)",
    value: "ZHW",
  },
  {
    label: "Lausanne, Lausanne Rail - Switzerland (QLS)",
    value: "QLS",
  },
  {
    label: "Le Locle, Le Locle - Switzerland (ZJA)",
    value: "ZJA",
  },
  {
    label: "Lenzburg, Lenzburg - Switzerland (ZJC)",
    value: "ZJC",
  },
  {
    label: "Lenzerheide Lai, Lenzerheide Lai Bus St - Switzerland (ZJD)",
    value: "ZJD",
  },
  {
    label: "Locarno, Locarno - Switzerland (ZJI)",
    value: "ZJI",
  },
  {
    label: "Lucerne, Lucerne Rail - Switzerland (QLJ)",
    value: "QLJ",
  },
  {
    label: "Lugano, Agno - Switzerland (LUG)",
    value: "LUG",
  },
  {
    label: "Lyss, Lyss - Switzerland (ZJL)",
    value: "ZJL",
  },
  {
    label: "Martigny, Martigny - Switzerland (ZJM)",
    value: "ZJM",
  },
  {
    label: "Montreux, Montreux - Switzerland (ZJP)",
    value: "ZJP",
  },
  {
    label: "Morges, Morges - Switzerland (ZJQ)",
    value: "ZJQ",
  },
  {
    label: "Neuchatel, Neuchatel - Switzerland (QNC)",
    value: "QNC",
  },
  {
    label: "Nyon, Nyon - Switzerland (ZRN)",
    value: "ZRN",
  },
  {
    label: "Olten, Olten - Switzerland (ZJU)",
    value: "ZJU",
  },
  {
    label: "Pontresina, Pontresina - Switzerland (ZJV)",
    value: "ZJV",
  },
  {
    label: "Rapperswil, Rapperswil - Switzerland (ZJW)",
    value: "ZJW",
  },
  {
    label: "Rorschach, Rorschach - Switzerland (ZJZ)",
    value: "ZJZ",
  },
  {
    label: "Saint Gallen, Saint Gallen - Switzerland (QGL)",
    value: "QGL",
  },
  {
    label: "Saint Margrethen, Saint Margrethen - Switzerland (ZKF)",
    value: "ZKF",
  },
  {
    label: "Saint Moritz, Engadin - Switzerland (SMV)",
    value: "SMV",
  },
  {
    label: "Saint Moritz, Saint Moritz - Switzerland (ZKH)",
    value: "ZKH",
  },
  {
    label: "Sarnen, Sarnen - Switzerland (ZKC)",
    value: "ZKC",
  },
  {
    label: "Schaffhausen, Schaffhausen - Switzerland (ZKJ)",
    value: "ZKJ",
  },
  {
    label: "Schwyz, Schwyz - Switzerland (ZKK)",
    value: "ZKK",
  },
  {
    label: "Sierre, Sierre - Switzerland (ZKO)",
    value: "ZKO",
  },
  {
    label: "Sion, Sion - Switzerland (SIR)",
    value: "SIR",
  },
  {
    label: "Solothurn, Solothurn - Switzerland (ZKS)",
    value: "ZKS",
  },
  {
    label: "Sursee, Sursee - Switzerland (ZKU)",
    value: "ZKU",
  },
  {
    label: "Thalwil, Thalwil - Switzerland (ZKV)",
    value: "ZKV",
  },
  {
    label: "Thun, Thun - Switzerland (ZTK)",
    value: "ZTK",
  },
  {
    label: "Uzwil, Uzwil - Switzerland (ZKX)",
    value: "ZKX",
  },
  {
    label: "Verbier, Verbier - Switzerland (ZKY)",
    value: "ZKY",
  },
  {
    label: "Villars Bvb, Villars Bvb - Switzerland (ZLA)",
    value: "ZLA",
  },
  {
    label: "Visp, Visp - Switzerland (ZLB)",
    value: "ZLB",
  },
  {
    label: "Waedenswil, Waedenswil - Switzerland (ZLC)",
    value: "ZLC",
  },
  {
    label: "Weinfelden, Weinfelden - Switzerland (ZLD)",
    value: "ZLD",
  },
  {
    label: "Zurich, Zurich Airport - Switzerland (ZRH)",
    value: "ZRH",
  },
  {
    label: "Al Thaurah, Al Thaurah - Syrian Arab Republic (SOR)",
    value: "SOR",
  },
  {
    label: "Aleppo, International - Syrian Arab Republic (ALP)",
    value: "ALP",
  },
  {
    label: "Damascus, Damascus Intl - Syrian Arab Republic (DAM)",
    value: "DAM",
  },
  {
    label: "Deir Ez Zor, Deir Ez Zor - Syrian Arab Republic (DEZ)",
    value: "DEZ",
  },
  {
    label: "Kameshly, Kameshly - Syrian Arab Republic (KAC)",
    value: "KAC",
  },
  {
    label: "Latakia, Bassel Al Assad Intl - Syrian Arab Republic (LTK)",
    value: "LTK",
  },
  {
    label: "Palmyra, Palmyra - Syrian Arab Republic (PMS)",
    value: "PMS",
  },
  {
    label: "Chi Mei, Qimei - Taiwan (CMJ)",
    value: "CMJ",
  },
  {
    label: "Chiayi, Chiayi - Taiwan (CYI)",
    value: "CYI",
  },
  {
    label: "Dongsha Island, Dongsha Island - Taiwan (DSX)",
    value: "DSX",
  },
  {
    label: "Green Island, Lyudao  Green Island) - Taiwan (GNI)",
    value: "GNI",
  },
  {
    label: "Hengchun, Hengchun - Taiwan (HCN)",
    value: "HCN",
  },
  {
    label: "Hsinchu, Hsinchu - Taiwan (HSZ)",
    value: "HSZ",
  },
  {
    label: "Hualien, Hualien - Taiwan (HUN)",
    value: "HUN",
  },
  {
    label: "Kaohsiung, Kaohsiung Intl - Taiwan (KHH)",
    value: "KHH",
  },
  {
    label: "Kinmen, Shangyi - Taiwan (KNH)",
    value: "KNH",
  },
  {
    label: "Makung, Magong - Taiwan (MZG)",
    value: "MZG",
  },
  {
    label: "Matsu, Matsu  Beigan) - Taiwan (MFK)",
    value: "MFK",
  },
  {
    label: "Orchid Island, Lanyu  Orchid Island) - Taiwan (KYD)",
    value: "KYD",
  },
  {
    label: "Pingtung, Pintung South - Taiwan (PIF)",
    value: "PIF",
  },
  {
    label: "Taichung, Cingcyuangang - Taiwan (RMQ)",
    value: "RMQ",
  },
  {
    label: "Taichung, Shuinan - Taiwan (TXG)",
    value: "TXG",
  },
  {
    label: "Tainan, Tainan - Taiwan (TNN)",
    value: "TNN",
  },
  {
    label: "Taipei, Songshan - Taiwan (TSA)",
    value: "TSA",
  },
  {
    label: "Taipei, Taiwan Taoyuan Intl - Taiwan (TPE)",
    value: "TPE",
  },
  {
    label: "Taitung, Fongnian - Taiwan (TTT)",
    value: "TTT",
  },
  {
    label: "Wonan, Wang'an - Taiwan (WOT)",
    value: "WOT",
  },
  {
    label: "Dushanbe, International - Tajikistan (DYU)",
    value: "DYU",
  },
  {
    label: "Khudzhand, International - Tajikistan (LBD)",
    value: "LBD",
  },
  {
    label: "Kulyab, Kulob - Tajikistan (TJU)",
    value: "TJU",
  },
  {
    label: "Qurghonteppa, International - Tajikistan (KQT)",
    value: "KQT",
  },
  {
    label: "Arusha, Arusha - Tanzania-United Republic (ARK)",
    value: "ARK",
  },
  {
    label: "Bukoba, Bukoba - Tanzania-United Republic (BKZ)",
    value: "BKZ",
  },
  {
    label:
      "Dar Es Salaam, Julius Nyerere Intl - Tanzania-United Republic (DAR)",
    value: "DAR",
  },
  {
    label: "Dodoma, Dodoma - Tanzania-United Republic (DOD)",
    value: "DOD",
  },
  {
    label: "Geita, Geita - Tanzania-United Republic (GIT)",
    value: "GIT",
  },
  {
    label: "Grumeti, Kirawira B - Tanzania-United Republic (GTZ)",
    value: "GTZ",
  },
  {
    label: "Iringa, Iringa - Tanzania-United Republic (IRI)",
    value: "IRI",
  },
  {
    label: "Kigoma, Kigoma - Tanzania-United Republic (TKQ)",
    value: "TKQ",
  },
  {
    label: "Kilimanjaro, Kilimanjaro Intl - Tanzania-United Republic (JRO)",
    value: "JRO",
  },
  {
    label: "Kilwa, Kilwa Masoko - Tanzania-United Republic (KIY)",
    value: "KIY",
  },
  {
    label: "Lake Manyara, Lake Manyara - Tanzania-United Republic (LKY)",
    value: "LKY",
  },
  {
    label: "Lindi, Kikwetu - Tanzania-United Republic (LDI)",
    value: "LDI",
  },
  {
    label: "Mafia Island, Mafia Island - Tanzania-United Republic (MFA)",
    value: "MFA",
  },
  {
    label: "Masasi, Masasi - Tanzania-United Republic (XMI)",
    value: "XMI",
  },
  {
    label: "Mbeya, Mbeya - Tanzania-United Republic (MBI)",
    value: "MBI",
  },
  {
    label: "Mtwara, Mtwara - Tanzania-United Republic (MYW)",
    value: "MYW",
  },
  {
    label: "Musoma, Musoma - Tanzania-United Republic (MUZ)",
    value: "MUZ",
  },
  {
    label: "Mwadui, Mwadui - Tanzania-United Republic (MWN)",
    value: "MWN",
  },
  {
    label: "Mwanza, Mwanza - Tanzania-United Republic (MWZ)",
    value: "MWZ",
  },
  {
    label: "Nachingwea, Nachingwea - Tanzania-United Republic (NCH)",
    value: "NCH",
  },
  {
    label: "Njombe, Njombe - Tanzania-United Republic (JOM)",
    value: "JOM",
  },
  {
    label: "Pemba, Pemba - Tanzania-United Republic (PMA)",
    value: "PMA",
  },
  {
    label: "Seronera, Seronera - Tanzania-United Republic (SEU)",
    value: "SEU",
  },
  {
    label: "Shinyanga, Ibadakuli - Tanzania-United Republic (SHY)",
    value: "SHY",
  },
  {
    label: "Songea, Songea - Tanzania-United Republic (SGX)",
    value: "SGX",
  },
  {
    label: "Sumbawanga, Sumbawanga - Tanzania-United Republic (SUT)",
    value: "SUT",
  },
  {
    label: "Tabora, Tabora - Tanzania-United Republic (TBO)",
    value: "TBO",
  },
  {
    label: "Tanga, Tanga - Tanzania-United Republic (TGT)",
    value: "TGT",
  },
  {
    label: "Zanzibar, Zanzibar Intl - Tanzania-United Republic (ZNZ)",
    value: "ZNZ",
  },
  {
    label: "Bangkok, Don Mueang Intl - Thailand (DMK)",
    value: "DMK",
  },
  {
    label: "Bangkok, Suvarnabhumi Intl - Thailand (BKK)",
    value: "BKK",
  },
  {
    label: "Buri Ram, Buriram - Thailand (BFV)",
    value: "BFV",
  },
  {
    label: "Chiang Mai, Chiang Mai Intl - Thailand (CNX)",
    value: "CNX",
  },
  {
    label: "Chiang Rai, Mae Fah Luang Intl - Thailand (CEI)",
    value: "CEI",
  },
  {
    label: "Chumphon, Chumphon Airport - Thailand (CJM)",
    value: "CJM",
  },
  {
    label: "Hat Yai, International - Thailand (HDY)",
    value: "HDY",
  },
  {
    label: "Hua Hin, Hua Hin Airport - Thailand (HHQ)",
    value: "HHQ",
  },
  {
    label: "Kamphangsaen, Kamphaeng Saen - Thailand (KDT)",
    value: "KDT",
  },
  {
    label: "Khon Kaen, Khon Kaen - Thailand (KKC)",
    value: "KKC",
  },
  {
    label: "Koh Samui, Ko Samui - Thailand (USM)",
    value: "USM",
  },
  {
    label: "Krabi, Krabi - Thailand (KBV)",
    value: "KBV",
  },
  {
    label: "Lampang, Lampang - Thailand (LPT)",
    value: "LPT",
  },
  {
    label: "Loei, Loei - Thailand (LOE)",
    value: "LOE",
  },
  {
    label: "Lop Buri, Khok Kathiam - Thailand (KKM)",
    value: "KKM",
  },
  {
    label: "Mae Hong Son, Mae Hong Son - Thailand (HGN)",
    value: "HGN",
  },
  {
    label: "Mae Sot, Mae Sot - Thailand (MAQ)",
    value: "MAQ",
  },
  {
    label: "Nakhon Phanom, Nakhon Phanom - Thailand (KOP)",
    value: "KOP",
  },
  {
    label: "Nakhon Ratchasima, Nakhon Ratchasima - Thailand (NAK)",
    value: "NAK",
  },
  {
    label: "Nakon Si Thammarat, Nakon Si Thammarat - Thailand (NST)",
    value: "NST",
  },
  {
    label: "Nan, Nan - Thailand (NNT)",
    value: "NNT",
  },
  {
    label: "Narathiwat, Narathiwat - Thailand (NAW)",
    value: "NAW",
  },
  {
    label: "Pai, Pai - Thailand (PYY)",
    value: "PYY",
  },
  {
    label: "Pattani, Pattani - Thailand (PAN)",
    value: "PAN",
  },
  {
    label: "Pattaya, Pattaya Airpark - Thailand (PYX)",
    value: "PYX",
  },
  {
    label: "Phanom Sarakham, Phanom Sarakham - Thailand (PMM)",
    value: "PMM",
  },
  {
    label: "Phetchabun, Phetchabun Apt - Thailand (PHY)",
    value: "PHY",
  },
  {
    label: "Phitsanulok, Phitsanulok - Thailand (PHS)",
    value: "PHS",
  },
  {
    label: "Phrae, Phrae - Thailand (PRH)",
    value: "PRH",
  },
  {
    label: "Phuket, Phuket Intl - Thailand (HKT)",
    value: "HKT",
  },
  {
    label: "Ranong, Ranong - Thailand (UNN)",
    value: "UNN",
  },
  {
    label: "Roi Et  Muang), Roi Et Airport - Thailand (ROI)",
    value: "ROI",
  },
  {
    label: "Sakon Nakhon, Sakon Nakhon - Thailand (SNO)",
    value: "SNO",
  },
  {
    label: "Songkhla, Songkhla - Thailand (SGZ)",
    value: "SGZ",
  },
  {
    label: "Sukhothai, Sukhothai - Thailand (THS)",
    value: "THS",
  },
  {
    label: "Surat Thani, Surat Thani - Thailand (URT)",
    value: "URT",
  },
  {
    label: "Surin, Surin - Thailand (PXR)",
    value: "PXR",
  },
  {
    label: "Tak, Tak - Thailand (TKT)",
    value: "TKT",
  },
  {
    label: "Takhli, Takhli - Thailand (TKH)",
    value: "TKH",
  },
  {
    label: "Trang, Trang - Thailand (TST)",
    value: "TST",
  },
  {
    label: "Trat, Trat Airport - Thailand (TDX)",
    value: "TDX",
  },
  {
    label: "Ubon Ratchathani, Ubon Ratchathani - Thailand (UBP)",
    value: "UBP",
  },
  {
    label: "Udon Thani, International - Thailand (UTH)",
    value: "UTH",
  },
  {
    label: "Utapao, Rayong Pattaya Intl - Thailand (UTP)",
    value: "UTP",
  },
  {
    label: "Uttaradit, Uttaradit - Thailand (UTR)",
    value: "UTR",
  },
  {
    label: "Atauro, Atauro - Timor Leste (AUT)",
    value: "AUT",
  },
  {
    label: "Baucau, Cakung - Timor Leste (BCH)",
    value: "BCH",
  },
  {
    label: "Dili, Pres Nicolau Lobato - Timor Leste (DIL)",
    value: "DIL",
  },
  {
    label: "Kun, Kun - Timor Leste (KCI)",
    value: "KCI",
  },
  {
    label: "Maliana, Maliana - Timor Leste (MPT)",
    value: "MPT",
  },
  {
    label: "Ocussi, Oecussi - Timor Leste (OEC)",
    value: "OEC",
  },
  {
    label: "Suai, Suai - Timor Leste (UAI)",
    value: "UAI",
  },
  {
    label: "Viqueque, Viqueque - Timor Leste (VIQ)",
    value: "VIQ",
  },
  {
    label: "Lome, G Eyadema Intl - Togo (LFW)",
    value: "LFW",
  },
  {
    label: "Niamtougou, International - Togo (LRL)",
    value: "LRL",
  },
  {
    label: "Eua, Kaufana - Tonga (EUA)",
    value: "EUA",
  },
  {
    label: "Ha'apai, Lifuka - Tonga (HPA)",
    value: "HPA",
  },
  {
    label: "Niuafo'ou, Mata'aho - Tonga (NFO)",
    value: "NFO",
  },
  {
    label: "Niuatoputapu, Kuini Lavenia - Tonga (NTT)",
    value: "NTT",
  },
  {
    label: "Tongatapu, Fua'amotu Intl - Tonga (TBU)",
    value: "TBU",
  },
  {
    label: "Vava'u, International - Tonga (VAV)",
    value: "VAV",
  },
  {
    label: "Port Of Spain, Piarco Intl - Trinidad And Tobago (POS)",
    value: "POS",
  },
  {
    label: "Tobago, A.N.R. Robinson Intl - Trinidad And Tobago (TAB)",
    value: "TAB",
  },
  {
    label: "Djerba, Zarzis - Tunisia (DJE)",
    value: "DJE",
  },
  {
    label: "El Borma, El Borma - Tunisia (EBM)",
    value: "EBM",
  },
  {
    label: "Enfidha, Hammamet - Tunisia (NBE)",
    value: "NBE",
  },
  {
    label: "Gabes, Matmata - Tunisia (GAE)",
    value: "GAE",
  },
  {
    label: "Gafsa, Ksar - Tunisia (GAF)",
    value: "GAF",
  },
  {
    label: "Monastir, Habib Bourguiba In - Tunisia (MIR)",
    value: "MIR",
  },
  {
    label: "Sfax, Thyna - Tunisia (SFA)",
    value: "SFA",
  },
  {
    label: "Tabarka, Ain Draham - Tunisia (TBJ)",
    value: "TBJ",
  },
  {
    label: "Tozeur, Nefta - Tunisia (TOE)",
    value: "TOE",
  },
  {
    label: "Tunis, Carthage - Tunisia (TUN)",
    value: "TUN",
  },
  {
    label: "Adana, Incirlik Ab - Turkey (UAB)",
    value: "UAB",
  },
  {
    label: "Adana, Sakirpasa - Turkey (ADA)",
    value: "ADA",
  },
  {
    label: "Adiyaman, Adiyaman - Turkey (ADF)",
    value: "ADF",
  },
  {
    label: "Afyon, Afyon - Turkey (AFY)",
    value: "AFY",
  },
  {
    label: "Agri, Agri - Turkey (AJI)",
    value: "AJI",
  },
  {
    label: "Amasya, Merzifon - Turkey (MZH)",
    value: "MZH",
  },
  {
    label: "Ankara, Esenboga - Turkey (ESB)",
    value: "ESB",
  },
  {
    label: "Ankara, Etimesgut - Turkey (ANK)",
    value: "ANK",
  },
  {
    label: "Antalya, Antalya - Turkey (AYT)",
    value: "AYT",
  },
  {
    label: "Aydin, Aydin - Turkey (AZW)",
    value: "AZW",
  },
  {
    label: "Aydin, Cildir - Turkey (CII)",
    value: "CII",
  },
  {
    label: "Balikesir, Balikesir - Turkey (BZI)",
    value: "BZI",
  },
  {
    label: "Bandirma, Bandirma - Turkey (BDM)",
    value: "BDM",
  },
  {
    label: "Batman, Batman - Turkey (BAL)",
    value: "BAL",
  },
  {
    label: "Bingol, Bingol - Turkey (BGG)",
    value: "BGG",
  },
  {
    label: "Bodrum, Imsik Airport - Turkey (BXN)",
    value: "BXN",
  },
  {
    label: "Bodrum, Milas - Turkey (BJV)",
    value: "BJV",
  },
  {
    label: "Bursa, Yenisehir - Turkey (YEI)",
    value: "YEI",
  },
  {
    label: "Canakkale, Canakkale - Turkey (CKZ)",
    value: "CKZ",
  },
  {
    label: "Dalaman, Dalaman - Turkey (DLM)",
    value: "DLM",
  },
  {
    label: "Denizli, Cardak - Turkey (DNZ)",
    value: "DNZ",
  },
  {
    label: "Diyarbakir, Diyarbakir - Turkey (DIY)",
    value: "DIY",
  },
  {
    label: "Edremit, Koca Seyit - Turkey (EDO)",
    value: "EDO",
  },
  {
    label: "Elazig, Elazig - Turkey (EZS)",
    value: "EZS",
  },
  {
    label: "Erzincan, Erzincan - Turkey (ERC)",
    value: "ERC",
  },
  {
    label: "Erzurum, Erzurum - Turkey (ERZ)",
    value: "ERZ",
  },
  {
    label: "Eskisehir, Anadolu - Turkey (AOE)",
    value: "AOE",
  },
  {
    label: "Eskisehir, Eskisehir Airport - Turkey (ESK)",
    value: "ESK",
  },
  {
    label: "Gaziantep, Oguzeli - Turkey (GZT)",
    value: "GZT",
  },
  {
    label: "Gazipasa, Gazipasa - Turkey (GZP)",
    value: "GZP",
  },
  {
    label: "Hakkari, Yuksekova - Turkey (YKO)",
    value: "YKO",
  },
  {
    label: "Hatay, Hatay - Turkey (HTY)",
    value: "HTY",
  },
  {
    label: "Igdir, Igdir Airport - Turkey (IGD)",
    value: "IGD",
  },
  {
    label: "Isparta, Suleyman Demirel - Turkey (ISE)",
    value: "ISE",
  },
  {
    label: "Istanbul, Ataturk - Turkey (IST)",
    value: "IST",
  },
  {
    label: "Istanbul, Sabiha Gokcen - Turkey (SAW)",
    value: "SAW",
  },
  {
    label: "Izmir, Adnan Menderes - Turkey (ADB)",
    value: "ADB",
  },
  {
    label: "Izmir, Cigli Ab - Turkey (IGL)",
    value: "IGL",
  },
  {
    label: "Kahramanmaras, Kahramanmaras - Turkey (KCM)",
    value: "KCM",
  },
  {
    label: "Kars, Kars - Turkey (KSY)",
    value: "KSY",
  },
  {
    label: "Kastamonu, Kastamonu - Turkey (KFS)",
    value: "KFS",
  },
  {
    label: "Kayseri, Erkilet - Turkey (ASR)",
    value: "ASR",
  },
  {
    label: "Kocaeli, Kocaeli - Turkey (KCO)",
    value: "KCO",
  },
  {
    label: "Konya, Konya - Turkey (KYA)",
    value: "KYA",
  },
  {
    label: "Kutahya, Zafer Airport - Turkey (KZR)",
    value: "KZR",
  },
  {
    label: "Malatya, Erhac - Turkey (MLX)",
    value: "MLX",
  },
  {
    label: "Mardin, Mardin - Turkey (MQM)",
    value: "MQM",
  },
  {
    label: "Mus, Mus - Turkey (MSR)",
    value: "MSR",
  },
  {
    label: "Nevsehir, Kapadokya - Turkey (NAV)",
    value: "NAV",
  },
  {
    label: "Ordugiresun, Ordu Giresun - Turkey (OGU)",
    value: "OGU",
  },
  {
    label: "Samsun, Carsamba Airport - Turkey (SZF)",
    value: "SZF",
  },
  {
    label: "Sanliurfa, Guney Anadolu - Turkey (GNY)",
    value: "GNY",
  },
  {
    label: "Sanliurfa, Sanliurfa Airport - Turkey (SFQ)",
    value: "SFQ",
  },
  {
    label: "Siirt, Siirt - Turkey (SXZ)",
    value: "SXZ",
  },
  {
    label: "Sinop, Sinop - Turkey (NOP)",
    value: "NOP",
  },
  {
    label: "Sirnak, Sirnak - Turkey (NKT)",
    value: "NKT",
  },
  {
    label: "Sivas, Sivas - Turkey (VAS)",
    value: "VAS",
  },
  {
    label: "Tekirdag, Corlu - Turkey (TEQ)",
    value: "TEQ",
  },
  {
    label: "Tokat, Tokat - Turkey (TJK)",
    value: "TJK",
  },
  {
    label: "Trabzon, Trabzon - Turkey (TZX)",
    value: "TZX",
  },
  {
    label: "Usak, Usak - Turkey (USQ)",
    value: "USQ",
  },
  {
    label: "Van, Ferit Melen - Turkey (VAN)",
    value: "VAN",
  },
  {
    label: "Zonguldak, Caycuma - Turkey (ONQ)",
    value: "ONQ",
  },
  {
    label: "Ashgabad, International - Turkmenistan (ASB)",
    value: "ASB",
  },
  {
    label: "Dashoguz, Dashoguz - Turkmenistan (TAZ)",
    value: "TAZ",
  },
  {
    label: "Mary, Mary - Turkmenistan (MYP)",
    value: "MYP",
  },
  {
    label: "Turkmenabad, Turkmenabat - Turkmenistan (CRZ)",
    value: "CRZ",
  },
  {
    label: "Turkmenbashi, Turkmanbashi - Turkmenistan (KRW)",
    value: "KRW",
  },
  {
    label:
      "Grand Turk Is, J.A.G.S. Mccartney Int - Turks And Caicos Islands (GDT)",
    value: "GDT",
  },
  {
    label: "Middle Caicos, Middle Caicos - Turks And Caicos Islands (MDS)",
    value: "MDS",
  },
  {
    label: "North Caicos, North Caicos - Turks And Caicos Islands (NCA)",
    value: "NCA",
  },
  {
    label: "Pine Cay, Pine Cay - Turks And Caicos Islands (PIC)",
    value: "PIC",
  },
  {
    label: "Providenciales, Providenciales - Turks And Caicos Islands (PLS)",
    value: "PLS",
  },
  {
    label: "Salt Cay, Salt Cay - Turks And Caicos Islands (SLX)",
    value: "SLX",
  },
  {
    label: "South Caicos, South Caicos Intl - Turks And Caicos Islands (XSC)",
    value: "XSC",
  },
  {
    label: "Funafuti Atol, International - Tuvalu (FUN)",
    value: "FUN",
  },
  {
    label: "Midway Island, Henderson Field - U.S. Minor Outlying Islands (MDY)",
    value: "MDY",
  },
  {
    label: "Wake Island, Wake Island - U.S. Minor Outlying Islands (AWK)",
    value: "AWK",
  },
  {
    label: "Arua, Arua - Uganda (RUA)",
    value: "RUA",
  },
  {
    label: "Entebbe, Entebbe Intl - Uganda (EBB)",
    value: "EBB",
  },
  {
    label: "Gulu, Gulu - Uganda (ULU)",
    value: "ULU",
  },
  {
    label: "Jinja, Jinja - Uganda (JIN)",
    value: "JIN",
  },
  {
    label: "Kabalega Falls, Kabalega Falls - Uganda (KBG)",
    value: "KBG",
  },
  {
    label: "Kasese, Kasese - Uganda (KSE)",
    value: "KSE",
  },
  {
    label: "Kihihi, Kihihi - Uganda (KHX)",
    value: "KHX",
  },
  {
    label: "Masindi, Masindi - Uganda (KCU)",
    value: "KCU",
  },
  {
    label: "Mbarara, Mbarara - Uganda (MBQ)",
    value: "MBQ",
  },
  {
    label: "Moyo, Moyo - Uganda (OYG)",
    value: "OYG",
  },
  {
    label: "Pakuba, Pakuba - Uganda (PAF)",
    value: "PAF",
  },
  {
    label: "Soroti, Soroti - Uganda (SRT)",
    value: "SRT",
  },
  {
    label: "Tororo, Tororo - Uganda (TRY)",
    value: "TRY",
  },
  {
    label: "Berdiansk, Berdiansk - Ukraine (ERD)",
    value: "ERD",
  },
  {
    label: "Cherkasy, International - Ukraine (CKC)",
    value: "CKC",
  },
  {
    label: "Chernivtsi, International - Ukraine (CWC)",
    value: "CWC",
  },
  {
    label: "Dnipropetrovsk, International - Ukraine (DNK)",
    value: "DNK",
  },
  {
    label: "Donetsk, International - Ukraine (DOK)",
    value: "DOK",
  },
  {
    label: "Ivano Frankivsk, International - Ukraine (IFO)",
    value: "IFO",
  },
  {
    label: "Kamianets Podilskiy, Kamianets Podilskiy - Ukraine (KCP)",
    value: "KCP",
  },
  {
    label: "Kerch, International - Ukraine (KHC)",
    value: "KHC",
  },
  {
    label: "Kharkiv, Osnova Intl - Ukraine (HRK)",
    value: "HRK",
  },
  {
    label: "Kherson, Kherson - Ukraine (KHE)",
    value: "KHE",
  },
  {
    label: "Khmelnytskyi, International - Ukraine (HMJ)",
    value: "HMJ",
  },
  {
    label: "Kiev, Boryspil Intl - Ukraine (KBP)",
    value: "KBP",
  },
  {
    label: "Kiev, Darnista Bus - Ukraine (QOF)",
    value: "QOF",
  },
  {
    label: "Kiev, Hotel Rus - Ukraine (QOH)",
    value: "QOH",
  },
  {
    label: "Kiev, Zhuliany Intl - Ukraine (IEV)",
    value: "IEV",
  },
  {
    label: "Kirovohrad, Kirovohrad - Ukraine (KGO)",
    value: "KGO",
  },
  {
    label: "Kramatorsk, Kramatorsk - Ukraine (KRQ)",
    value: "KRQ",
  },
  {
    label: "Kremenchuk, Kremenchuk - Ukraine (KHU)",
    value: "KHU",
  },
  {
    label: "Krivyi Rih, Lozuvatka Intl - Ukraine (KWG)",
    value: "KWG",
  },
  {
    label: "Luhansk, International - Ukraine (VSG)",
    value: "VSG",
  },
  {
    label: "Lutsk, Lutsk - Ukraine (UCK)",
    value: "UCK",
  },
  {
    label: "Lviv, International - Ukraine (LWO)",
    value: "LWO",
  },
  {
    label: "Mariupol, International - Ukraine (MPW)",
    value: "MPW",
  },
  {
    label: "Mykolaiv, Mykolaiv - Ukraine (NLV)",
    value: "NLV",
  },
  {
    label: "Myrhorod, Myrhorod - Ukraine (MXR)",
    value: "MXR",
  },
  {
    label: "Odesa, International - Ukraine (ODS)",
    value: "ODS",
  },
  {
    label: "Poltava, Poltava - Ukraine (PLV)",
    value: "PLV",
  },
  {
    label: "Rivne, International - Ukraine (RWN)",
    value: "RWN",
  },
  {
    label: "Sevastopol, Belbek - Ukraine (UKS)",
    value: "UKS",
  },
  {
    label: "Severodonetsk, Severodonetsk - Ukraine (SEV)",
    value: "SEV",
  },
  {
    label: "Simferopol, International - Ukraine (SIP)",
    value: "SIP",
  },
  {
    label: "Sumy, Sumy - Ukraine (UMY)",
    value: "UMY",
  },
  {
    label: "Ternopil, International - Ukraine (TNL)",
    value: "TNL",
  },
  {
    label: "Uzhhorod, International - Ukraine (UDJ)",
    value: "UDJ",
  },
  {
    label: "Vinnytsia, Gavryshivka Intl - Ukraine (VIN)",
    value: "VIN",
  },
  {
    label: "Zaporizhzhia, Mokraya Intl - Ukraine (OZH)",
    value: "OZH",
  },
  {
    label: "Zhytomyr, Zhytomyr - Ukraine (ZTR)",
    value: "ZTR",
  },
  {
    label: "Abu Dhabi - United Arab Emirates (AUH)",
    value: "AUH",
  },
  {
    label: "Abu Dhabi, International - United Arab Emirates (AUH)",
    value: "AUH",
  },
  {
    label: "Abu Dhabi, Al Dhafra Ab - United Arab Emirates (DHF)",
    value: "DHF",
  },
  {
    label: "Abu Dhabi, Bateen - United Arab Emirates (AZI)",
    value: "AZI",
  },
  {
    label: "Abu Dhabi, Yas Island Spb - United Arab Emirates (AYM)",
    value: "AYM",
  },
  {
    label: "Al Ain - United Arab Emirates (AAN)",
    value: "AAN",
  },
  {
    label: "Al Ain, International - United Arab Emirates (AAN)",
    value: "AAN",
  },
  {
    label: "Dubai - United Arab Emirates (DXB)",
    value: "DXB",
  },
  {
    label: "Dubai, Dubai Intl - United Arab Emirates (DXB)",
    value: "DXB",
  },
  {
    label: "Dubai, Al Maktoum Intl - United Arab Emirates (DWC)",
    value: "DWC",
  },
  {
    label: "Dubai, Dubai Creek Spb - United Arab Emirates (DCG)",
    value: "DCG",
  },
  {
    label: "Dubai, Jebel Ali Free Zone - United Arab Emirates (ZJF)",
    value: "ZJF",
  },
  {
    label: "Dubai, Jebel Ali Spb - United Arab Emirates (DJH)",
    value: "DJH",
  },
  {
    label: "Dubai, Minhad Ab - United Arab Emirates (NHD)",
    value: "NHD",
  },
  {
    label: "Fujairah - United Arab Emirates (FJR)",
    value: "FJR",
  },
  {
    label: "Fujairah, International - United Arab Emirates (FJR)",
    value: "FJR",
  },
  {
    label: "Ras Al Khaimah - United Arab Emirates (RKT)",
    value: "RKT",
  },
  {
    label: "Ras Al Khaimah, International - United Arab Emirates (RKT)",
    value: "RKT",
  },
  {
    label: "Ras Al Khaimah, Al Hamra Spb - United Arab Emirates (RHR)",
    value: "RHR",
  },
  {
    label: "Sharjah - United Arab Emirates (SHJ)",
    value: "SHJ",
  },
  {
    label: "Sharjah, Sharjah - United Arab Emirates (SHJ)",
    value: "SHJ",
  },
  {
    label: "Aberdeen, Dyce - United Kingdom (ABZ)",
    value: "ABZ",
  },
  {
    label: "Alderney, Alderney - United Kingdom (ACI)",
    value: "ACI",
  },
  {
    label: "Anglesey, Valley - United Kingdom (VLY)",
    value: "VLY",
  },
  {
    label: "Ballykelly, Ballykelly - United Kingdom (BOL)",
    value: "BOL",
  },
  {
    label: "Barra, Barra - United Kingdom (BRR)",
    value: "BRR",
  },
  {
    label: "Barrow In Furness, Barrow In Furness - United Kingdom (BWF)",
    value: "BWF",
  },
  {
    label: "Belfast, George Best City Apt - United Kingdom (BHD)",
    value: "BHD",
  },
  {
    label: "Belfast, International - United Kingdom (BFS)",
    value: "BFS",
  },
  {
    label: "Bembridge, Bembridge - United Kingdom (BBP)",
    value: "BBP",
  },
  {
    label: "Benbecula, Benbecula - United Kingdom (BEB)",
    value: "BEB",
  },
  {
    label: "Benson, Raf Station - United Kingdom (BEX)",
    value: "BEX",
  },
  {
    label: "Birmingham, Birmingham - United Kingdom (BHX)",
    value: "BHX",
  },
  {
    label: "Blackbushe, Blackbushe - United Kingdom (BBS)",
    value: "BBS",
  },
  {
    label: "Blackpool, Blackpool - United Kingdom (BLK)",
    value: "BLK",
  },
  {
    label: "Bournemouth, Bournemouth Intl - United Kingdom (BOH)",
    value: "BOH",
  },
  {
    label: "Bradford, Leeds - United Kingdom (BRF)",
    value: "BRF",
  },
  {
    label: "Braintree, Junction Rail Station - United Kingdom (WXF)",
    value: "WXF",
  },
  {
    label: "Brandon, Lakenheath Raf - United Kingdom (LKZ)",
    value: "LKZ",
  },
  {
    label: "Brighton, Brighton - United Kingdom (BSH)",
    value: "BSH",
  },
  {
    label: "Bristol, Bristol - United Kingdom (BRS)",
    value: "BRS",
  },
  {
    label: "Brize Norton, Raf Station - United Kingdom (BZZ)",
    value: "BZZ",
  },
  {
    label: "Bury St Edmunds, Honington Raf - United Kingdom (BEQ)",
    value: "BEQ",
  },
  {
    label: "Cambridge, Cambridge - United Kingdom (CBG)",
    value: "CBG",
  },
  {
    label: "Campbeltown, Campbeltown - United Kingdom (CAL)",
    value: "CAL",
  },
  {
    label: "Cardiff, Cardiff - United Kingdom (CWL)",
    value: "CWL",
  },
  {
    label: "Carlisle, Carlisle - United Kingdom (CAX)",
    value: "CAX",
  },
  {
    label: "Chester, Hawarden - United Kingdom (CEG)",
    value: "CEG",
  },
  {
    label: "Chichester, Goodwood - United Kingdom (QUG)",
    value: "QUG",
  },
  {
    label: "Coll Island, Coll Island - United Kingdom (COL)",
    value: "COL",
  },
  {
    label: "Colonsay, Colonsay - United Kingdom (CSA)",
    value: "CSA",
  },
  {
    label: "Coningsby, Raf Station - United Kingdom (QCY)",
    value: "QCY",
  },
  {
    label: "Coventry, Coventry - United Kingdom (CVT)",
    value: "CVT",
  },
  {
    label: "Derry, Eglinton - United Kingdom (LDY)",
    value: "LDY",
  },
  {
    label: "Dewsbury, Dewsbury Bus - United Kingdom (ZEQ)",
    value: "ZEQ",
  },
  {
    label: "Doncaster/Sheffield, Robin Hood - United Kingdom (DSA)",
    value: "DSA",
  },
  {
    label: "Dornoch, Dornoch - United Kingdom (DOC)",
    value: "DOC",
  },
  {
    label: "Dundee, Airport - United Kingdom (DND)",
    value: "DND",
  },
  {
    label: "Durham, Tees Valley - United Kingdom (MME)",
    value: "MME",
  },
  {
    label: "Eday, Eday - United Kingdom (EOI)",
    value: "EOI",
  },
  {
    label: "Edinburgh, Airport - United Kingdom (EDI)",
    value: "EDI",
  },
  {
    label: "Enniskillen, Saint Angelo - United Kingdom (ENK)",
    value: "ENK",
  },
  {
    label: "Exeter, Exeter - United Kingdom (EXT)",
    value: "EXT",
  },
  {
    label: "Fair Isle, Fair Isle - United Kingdom (FIE)",
    value: "FIE",
  },
  {
    label: "Fairford, Raf Station - United Kingdom (FFD)",
    value: "FFD",
  },
  {
    label: "Farnborough, Farnborough - United Kingdom (FAB)",
    value: "FAB",
  },
  {
    label: "Fetlar, Fetlar - United Kingdom (FEA)",
    value: "FEA",
  },
  {
    label: "Filton, Filton - United Kingdom (FZO)",
    value: "FZO",
  },
  {
    label: "Flotta, Flotta - United Kingdom (FLH)",
    value: "FLH",
  },
  {
    label: "Forres, Kinloss Raf - United Kingdom (FSS)",
    value: "FSS",
  },
  {
    label: "Fort William, Heliport - United Kingdom (FWM)",
    value: "FWM",
  },
  {
    label: "Foula, Foula - United Kingdom (FOA)",
    value: "FOA",
  },
  {
    label: "Glasgow, Glasgow Intl - United Kingdom (GLA)",
    value: "GLA",
  },
  {
    label: "Glasgow, Prestwick - United Kingdom (PIK)",
    value: "PIK",
  },
  {
    label: "Gloucester Cheltenham, Gloucestershire - United Kingdom (GLO)",
    value: "GLO",
  },
  {
    label: "Grimsby, Binbrook - United Kingdom (GSY)",
    value: "GSY",
  },
  {
    label: "Guernsey, Guernsey - United Kingdom (GCI)",
    value: "GCI",
  },
  {
    label: "Harrogate, Linton On Ouse Raf - United Kingdom (HRT)",
    value: "HRT",
  },
  {
    label: "Haverfordwest, Haverfordwest - United Kingdom (HAW)",
    value: "HAW",
  },
  {
    label: "High Wycombe, Air Parker Booker - United Kingdom (HYC)",
    value: "HYC",
  },
  {
    label: "Holyhead, Holyhead - United Kingdom (HLY)",
    value: "HLY",
  },
  {
    label: "Hoy, Longhope - United Kingdom (HOY)",
    value: "HOY",
  },
  {
    label: "Humberside, Humberside - United Kingdom (HUY)",
    value: "HUY",
  },
  {
    label: "Inverness, Airport - United Kingdom (INV)",
    value: "INV",
  },
  {
    label: "Ipswich, Ipswich - United Kingdom (IPW)",
    value: "IPW",
  },
  {
    label: "Islay, Islay - United Kingdom (ILY)",
    value: "ILY",
  },
  {
    label: "Isle Of Man, Ronaldsway - United Kingdom (IOM)",
    value: "IOM",
  },
  {
    label: "Isles Of Scilly, Saint Marys - United Kingdom (ISC)",
    value: "ISC",
  },
  {
    label: "Isles Of Scilly, Tresco Heliport - United Kingdom (TSO)",
    value: "TSO",
  },
  {
    label: "Jersey, Jersey - United Kingdom (JER)",
    value: "JER",
  },
  {
    label: "King's Lynn, Marham Raf - United Kingdom (KNF)",
    value: "KNF",
  },
  {
    label: "Kirkwall, Kirkwall - United Kingdom (KOI)",
    value: "KOI",
  },
  {
    label: "Land's End, Saint Just - United Kingdom (LEQ)",
    value: "LEQ",
  },
  {
    label: "Leeds Bradford, Airport - United Kingdom (LBA)",
    value: "LBA",
  },
  {
    label: "Leicester, Leicester - United Kingdom (QEW)",
    value: "QEW",
  },
  {
    label: "Liverpool, John Lennon - United Kingdom (LPL)",
    value: "LPL",
  },
  {
    label: "Lossiemouth, Raf Station - United Kingdom (LMO)",
    value: "LMO",
  },
  {
    label: "Lydd, London Ashford - United Kingdom (LYX)",
    value: "LYX",
  },
  {
    label: "Lyneham, Raf Station - United Kingdom (LYE)",
    value: "LYE",
  },
  {
    label: "Manchester, Manchester Airport - United Kingdom (MAN)",
    value: "MAN",
  },
  {
    label: "Manston-Kent Intl, Kent Intl - United Kingdom (MSE)",
    value: "MSE",
  },
  {
    label: "Mull, Mull - United Kingdom (ULL)",
    value: "ULL",
  },
  {
    label: "Newark, North Gate Rail Stn - United Kingdom (XNK)",
    value: "XNK",
  },
  {
    label: "Newcastle, International - United Kingdom (NCL)",
    value: "NCL",
  },
  {
    label: "Newcastle Under Lyme, Newcastle Under Lyme - United Kingdom (VLF)",
    value: "VLF",
  },
  {
    label: "Newquay, Cornwall - United Kingdom (NQY)",
    value: "NQY",
  },
  {
    label: "North Ronaldsay, North Ronaldsay - United Kingdom (NRL)",
    value: "NRL",
  },
  {
    label: "Northampton, Sywell - United Kingdom (ORM)",
    value: "ORM",
  },
  {
    label: "Northolt, Raf Station - United Kingdom (NHT)",
    value: "NHT",
  },
  {
    label: "Norwich, International - United Kingdom (NWI)",
    value: "NWI",
  },
  {
    label: "Nottingham, East Midlands - United Kingdom (EMA)",
    value: "EMA",
  },
  {
    label: "Nottingham, Nottimgham Airport - United Kingdom (NQT)",
    value: "NQT",
  },
  {
    label: "Oakham, Cottesmore Raf - United Kingdom (OKH)",
    value: "OKH",
  },
  {
    label: "Oban, North Connel - United Kingdom (OBN)",
    value: "OBN",
  },
  {
    label: "Odiham, Raf Station - United Kingdom (ODH)",
    value: "ODH",
  },
  {
    label: "Out Skerries, Out Skerries - United Kingdom (OUK)",
    value: "OUK",
  },
  {
    label: "Oxford, London Oxford - United Kingdom (OXF)",
    value: "OXF",
  },
  {
    label: "Papa Stour, Papa Stour - United Kingdom (PSV)",
    value: "PSV",
  },
  {
    label: "Papa Westray, Papa Westray - United Kingdom (PPW)",
    value: "PPW",
  },
  {
    label: "Penzance, Heliport - United Kingdom (PZE)",
    value: "PZE",
  },
  {
    label: "Perth, Scone - United Kingdom (PSL)",
    value: "PSL",
  },
  {
    label: "Plymouth, City Airport - United Kingdom (PLH)",
    value: "PLH",
  },
  {
    label: "Portsmouth, Fleetlands Heliport - United Kingdom (PME)",
    value: "PME",
  },
  {
    label: "Rochester, Rochester - United Kingdom (RCS)",
    value: "RCS",
  },
  {
    label: "Saint Andrews, Leuchars Raf - United Kingdom (ADX)",
    value: "ADX",
  },
  {
    label: "Sanday, Sanday - United Kingdom (NDY)",
    value: "NDY",
  },
  {
    label: "Scampton, Raf Station - United Kingdom (SQZ)",
    value: "SQZ",
  },
  {
    label: "Shetland Islands, Lerwick - United Kingdom (LWK)",
    value: "LWK",
  },
  {
    label: "Shetland Islands, Scatsta - United Kingdom (SCS)",
    value: "SCS",
  },
  {
    label: "Shetland Islands, Sumburgh - United Kingdom (LSI)",
    value: "LSI",
  },
  {
    label: "Shoreham By Sea, Shoreham By Sea - United Kingdom (ESH)",
    value: "ESH",
  },
  {
    label: "Skye, Broadford - United Kingdom (SKL)",
    value: "SKL",
  },
  {
    label: "Southampton, Southampton - United Kingdom (SOU)",
    value: "SOU",
  },
  {
    label: "Stornoway, Stornoway - United Kingdom (SYY)",
    value: "SYY",
  },
  {
    label: "Stronsay, Stronsay - United Kingdom (SOY)",
    value: "SOY",
  },
  {
    label: "Suttonheath, Woodbridge - United Kingdom (WOB)",
    value: "WOB",
  },
  {
    label: "Swansea, Swansea - United Kingdom (SWS)",
    value: "SWS",
  },
  {
    label: "Tiree, Tiree - United Kingdom (TRE)",
    value: "TRE",
  },
  {
    label: "Unst, Baltasound - United Kingdom (UNT)",
    value: "UNT",
  },
  {
    label: "Upavon, Upavon - United Kingdom (UPV)",
    value: "UPV",
  },
  {
    label: "Waddington, Raf Station - United Kingdom (WTN)",
    value: "WTN",
  },
  {
    label: "Warton, Warton - United Kingdom (WRT)",
    value: "WRT",
  },
  {
    label: "Westray, Westray - United Kingdom (WRY)",
    value: "WRY",
  },
  {
    label: "Whalsay, Whalsay - United Kingdom (WHS)",
    value: "WHS",
  },
  {
    label: "Wick, Wick - United Kingdom (WIC)",
    value: "WIC",
  },
  {
    label: "Woodford, Woodford - United Kingdom (WFD)",
    value: "WFD",
  },
  {
    label: "Wyton, Raf Wyton - United Kingdom (QUY)",
    value: "QUY",
  },
  {
    label: "Yeovilton, Royal Naval Air Stn - United Kingdom (YEO)",
    value: "YEO",
  },
  {
    label: "Aberdeen, United States Of America (HQM)",
    value: "HQM",
  },
  {
    label: "Aberdeen, Bowerman - United States Of America (HQM)",
    value: "HQM",
  },
  {
    label: "Aberdeen, United States Of America (APG)",
    value: "APG",
  },
  {
    label: "Aberdeen, Phillips Aaf - United States Of America (APG)",
    value: "APG",
  },
  {
    label: "Aberdeen, United States Of America (ABR)",
    value: "ABR",
  },
  {
    label: "Aberdeen, Regional - United States Of America (ABR)",
    value: "ABR",
  },
  {
    label: "Abilene, United States Of America (ABI)",
    value: "ABI",
  },
  {
    label: "Abilene, Regional - United States Of America (ABI)",
    value: "ABI",
  },
  {
    label: "Abilene, Dyess Afb - United States Of America (DYS)",
    value: "DYS",
  },
  {
    label: "Abingdon, United States Of America (VJI)",
    value: "VJI",
  },
  {
    label: "Abingdon, Virginia Highla - United States Of America (VJI)",
    value: "VJI",
  },
  {
    label: "Ada, United States Of America (ADT)",
    value: "ADT",
  },
  {
    label: "Ada, Minicipal - United States Of America (ADT)",
    value: "ADT",
  },
  {
    label: "Adak Island, United States Of America (ADK)",
    value: "ADK",
  },
  {
    label: "Adak Island, Adak Island - United States Of America (ADK)",
    value: "ADK",
  },
  {
    label: "Adrian, United States Of America (ADG)",
    value: "ADG",
  },
  {
    label: "Adrian, Lenawee County - United States Of America (ADG)",
    value: "ADG",
  },
  {
    label: "Afton, United States Of America (AFO)",
    value: "AFO",
  },
  {
    label: "Afton, Afton Mnpl - United States Of America (AFO)",
    value: "AFO",
  },
  {
    label: "Aiken, United States Of America (AIK)",
    value: "AIK",
  },
  {
    label: "Aiken, Aiken Mnpl - United States Of America (AIK)",
    value: "AIK",
  },
  {
    label: "Ainsworth, United States Of America (ANW)",
    value: "ANW",
  },
  {
    label: "Ainsworth, Regional - United States Of America (ANW)",
    value: "ANW",
  },
  {
    label: "Akhiok, United States Of America (AKK)",
    value: "AKK",
  },
  {
    label: "Akhiok, Akhiok - United States Of America (AKK)",
    value: "AKK",
  },
  {
    label: "Akiachak, United States Of America (KKI)",
    value: "KKI",
  },
  {
    label: "Akiachak, Akiachak - United States Of America (KKI)",
    value: "KKI",
  },
  {
    label: "Akiak, United States Of America (AKI)",
    value: "AKI",
  },
  {
    label: "Akiak, Akiak - United States Of America (AKI)",
    value: "AKI",
  },
  {
    label: "Akutan, United States Of America (KQA)",
    value: "KQA",
  },
  {
    label: "Akutan, Akutan - United States Of America (KQA)",
    value: "KQA",
  },
  {
    label: "Alakanuk, United States Of America (AUK)",
    value: "AUK",
  },
  {
    label: "Alakanuk, Alakanuk - United States Of America (AUK)",
    value: "AUK",
  },
  {
    label: "Alameda, United States Of America (NGZ)",
    value: "NGZ",
  },
  {
    label: "Alameda, Nas - United States Of America (NGZ)",
    value: "NGZ",
  },
  {
    label: "Alamogordo, United States Of America (ALM)",
    value: "ALM",
  },
  {
    label: "Alamogordo, White Sands Regional - United States Of America (ALM)",
    value: "ALM",
  },
  {
    label: "Alamogordo, Holloman Afb - United States Of America (HMN)",
    value: "HMN",
  },
  {
    label: "Alamosa, United States Of America (ALS)",
    value: "ALS",
  },
  {
    label: "Alamosa, San Luis Valley Rgnl - United States Of America (ALS)",
    value: "ALS",
  },
  {
    label: "Albany, United States Of America (ALB)",
    value: "ALB",
  },
  {
    label: "Albany, Albany Intl Apt - United States Of America (ALB)",
    value: "ALB",
  },
  {
    label: "Albany, United States Of America (ABY)",
    value: "ABY",
  },
  {
    label: "Albany, Southwest Georgia Rgnl - United States Of America (ABY)",
    value: "ABY",
  },
  {
    label: "Albany, Albany Nas - United States Of America (NAB)",
    value: "NAB",
  },
  {
    label: "Albert Lea, United States Of America (AEL)",
    value: "AEL",
  },
  {
    label: "Albert Lea, Municipal - United States Of America (AEL)",
    value: "AEL",
  },
  {
    label: "Albuquerque, United States Of America (ABQ)",
    value: "ABQ",
  },
  {
    label:
      "Albuquerque, International Sunport - United States Of America (ABQ)",
    value: "ABQ",
  },
  {
    label: "Aleknagik, United States Of America (WKK)",
    value: "WKK",
  },
  {
    label: "Aleknagik, Aleknagik - United States Of America (WKK)",
    value: "WKK",
  },
  {
    label: "Aleneva, United States Of America (AED)",
    value: "AED",
  },
  {
    label: "Aleneva, Aleneva - United States Of America (AED)",
    value: "AED",
  },
  {
    label: "Alexander City, United States Of America (ALX)",
    value: "ALX",
  },
  {
    label: "Alexander City, Tc Russel - United States Of America (ALX)",
    value: "ALX",
  },
  {
    label: "Alexandria, United States Of America (AEX)",
    value: "AEX",
  },
  {
    label: "Alexandria, Alexandria Intl - United States Of America (AEX)",
    value: "AEX",
  },
  {
    label: "Alexandria, Esler Regional - United States Of America (ESF)",
    value: "ESF",
  },
  {
    label: "Alexandria, United States Of America (AXN)",
    value: "AXN",
  },
  {
    label: "Alexandria, Chandler Field - United States Of America (AXN)",
    value: "AXN",
  },
  {
    label: "Alexandria Bay, United States Of America (AXB)",
    value: "AXB",
  },
  {
    label: "Alexandria Bay, Maxson Airfield - United States Of America (AXB)",
    value: "AXB",
  },
  {
    label: "Algona, United States Of America (AXG)",
    value: "AXG",
  },
  {
    label: "Algona, Municipal - United States Of America (AXG)",
    value: "AXG",
  },
  {
    label: "Alice, United States Of America (ALI)",
    value: "ALI",
  },
  {
    label: "Alice, Alice Intl - United States Of America (ALI)",
    value: "ALI",
  },
  {
    label: "Aliceville, United States Of America (AIV)",
    value: "AIV",
  },
  {
    label: "Aliceville, George Downer - United States Of America (AIV)",
    value: "AIV",
  },
  {
    label: "Alitak, United States Of America (ALZ)",
    value: "ALZ",
  },
  {
    label: "Alitak, Alitak Spb - United States Of America (ALZ)",
    value: "ALZ",
  },
  {
    label: "Allakaket, Allakaket - United States Of America (AET)",
    value: "AET",
  },
  {
    label:
      "Allentown Bthlehm, Allentown Bthlehm - United States Of America (ABE)",
    value: "ABE",
  },
  {
    label: "Alliance, Municipal - United States Of America (AIA)",
    value: "AIA",
  },
  {
    label: "Alma, Gratiot Community - United States Of America (AMN)",
    value: "AMN",
  },
  {
    label: "Alpena, Alpena County Regional - United States Of America (APN)",
    value: "APN",
  },
  {
    label: "Alpine, Casparis Municipal - United States Of America (ALE)",
    value: "ALE",
  },
  {
    label: "Alton, Saint Louis Regional - United States Of America (ALN)",
    value: "ALN",
  },
  {
    label: "Altoona, Blair County - United States Of America (AOO)",
    value: "AOO",
  },
  {
    label: "Altus, Altus Afb - United States Of America (LTS)",
    value: "LTS",
  },
  {
    label: "Altus, Quartz Mountain Rgnl - United States Of America (AXS)",
    value: "AXS",
  },
  {
    label: "Alyeska, Girdwood - United States Of America (AQY)",
    value: "AQY",
  },
  {
    label: "Amarillo, Rick Husband Intl - United States Of America (AMA)",
    value: "AMA",
  },
  {
    label: "Amarillo, Tradewind - United States Of America (TDW)",
    value: "TDW",
  },
  {
    label: "Ambler, Ambler - United States Of America (ABL)",
    value: "ABL",
  },
  {
    label: "Amchitka, Amchitka - United States Of America (AHT)",
    value: "AHT",
  },
  {
    label: "Amery, Amery Mnpl - United States Of America (AHH)",
    value: "AHH",
  },
  {
    label: "Ames, Municipal - United States Of America (AMW)",
    value: "AMW",
  },
  {
    label: "Amityville, Zahns - United States Of America (AYZ)",
    value: "AYZ",
  },
  {
    label: "Amook, Sea Plane Base - United States Of America (AOS)",
    value: "AOS",
  },
  {
    label: "Anacortes, Anacortes - United States Of America (OTS)",
    value: "OTS",
  },
  {
    label: "Anacostia, Usn Heliport - United States Of America (NDV)",
    value: "NDV",
  },
  {
    label: "Anaheim, Anaheim - United States Of America (ANA)",
    value: "ANA",
  },
  {
    label: "Anaktuvuk, Anaktuvuk Pass - United States Of America (AKP)",
    value: "AKP",
  },
  {
    label: "Anchorage, Elmendorf Afb - United States Of America (EDF)",
    value: "EDF",
  },
  {
    label: "Anchorage, Merrill Field - United States Of America (MRI)",
    value: "MRI",
  },
  {
    label: "Anchorage, Ted Stevens Intl - United States Of America (ANC)",
    value: "ANC",
  },
  {
    label: "Anderson, Anderson Mnpl - United States Of America (AID)",
    value: "AID",
  },
  {
    label: "Anderson, Regional - United States Of America (AND)",
    value: "AND",
  },
  {
    label: "Andrews, Robert F. Swinnie - United States Of America (ADR)",
    value: "ADR",
  },
  {
    label: "Angel Fire, Angel Fire - United States Of America (AXX)",
    value: "AXX",
  },
  {
    label: "Angola, Tri State Steuben Co - United States Of America (ANQ)",
    value: "ANQ",
  },
  {
    label: "Angoon, Sea Plane Base - United States Of America (AGN)",
    value: "AGN",
  },
  {
    label: "Anguilla, Rollang Field - United States Of America (RFK)",
    value: "RFK",
  },
  {
    label: "Aniak, Aniak - United States Of America (ANI)",
    value: "ANI",
  },
  {
    label: "Anita Bay, Anita Bay - United States Of America (AIB)",
    value: "AIB",
  },
  {
    label: "Ann Arbor, Ann Arbor Mnpl - United States Of America (ARB)",
    value: "ARB",
  },
  {
    label: "Annapolis, Lee - United States Of America (ANP)",
    value: "ANP",
  },
  {
    label: "Annette Island, Annette Island - United States Of America (ANN)",
    value: "ANN",
  },
  {
    label: "Anniston, Fort Mcclellan Bus Stn - United States Of America (QAW)",
    value: "QAW",
  },
  {
    label: "Anniston, Regional - United States Of America (ANB)",
    value: "ANB",
  },
  {
    label: "Anniston, Reilly Ahp - United States Of America (RLI)",
    value: "RLI",
  },
  {
    label: "Anthony, Municipal - United States Of America (ANY)",
    value: "ANY",
  },
  {
    label: "Antlers, Municipal - United States Of America (ATE)",
    value: "ATE",
  },
  {
    label: "Anvik, Anvik - United States Of America (ANV)",
    value: "ANV",
  },
  {
    label: "Apalachicola, Regional - United States Of America (AAF)",
    value: "AAF",
  },
  {
    label: "Apple Valley, Apple Valley - United States Of America (APV)",
    value: "APV",
  },
  {
    label: "Appleton, Outagamie County Rgnl - United States Of America (ATW)",
    value: "ATW",
  },
  {
    label: "Arapahoe, Arapahoe Mnpl - United States Of America (AHF)",
    value: "AHF",
  },
  {
    label: "Arcata, Arcata Apt - United States Of America (ACV)",
    value: "ACV",
  },
  {
    label: "Arcata, Eureka Murray Field - United States Of America (EKA)",
    value: "EKA",
  },
  {
    label: "Arcata, Kneeland - United States Of America (NLN)",
    value: "NLN",
  },
  {
    label: "Arctic Village, Arctic Village - United States Of America (ARC)",
    value: "ARC",
  },
  {
    label: "Ardmore, Downtown Executive - United States Of America (AHD)",
    value: "AHD",
  },
  {
    label: "Ardmore, Municipal - United States Of America (ADM)",
    value: "ADM",
  },
  {
    label: "Arlington Heights, Us Arm - United States Of America (JLH)",
    value: "JLH",
  },
  {
    label: "Artesia, Municipal - United States Of America (ATS)",
    value: "ATS",
  },
  {
    label: "Asbury Park, Asbury Park - United States Of America (ARX)",
    value: "ARX",
  },
  {
    label: "Asheville, Asheville Regional - United States Of America (AVL)",
    value: "AVL",
  },
  {
    label: "Ashland, John F. Kennedy Mem. - United States Of America (ASX)",
    value: "ASX",
  },
  {
    label: "Ashland, Municipal - United States Of America (AHM)",
    value: "AHM",
  },
  {
    label: "Ashley, Municipal - United States Of America (ASY)",
    value: "ASY",
  },
  {
    label: "Aspen, Pitkin County - United States Of America (ASE)",
    value: "ASE",
  },
  {
    label: "Astoria, Regional - United States Of America (AST)",
    value: "AST",
  },
  {
    label: "Athens, Ben Epps - United States Of America (AHN)",
    value: "AHN",
  },
  {
    label: "Athens, Mcminn County - United States Of America (MMI)",
    value: "MMI",
  },
  {
    label: "Athens, Ohio University - United States Of America (ATO)",
    value: "ATO",
  },
  {
    label: "Atka, Atka - United States Of America (AKB)",
    value: "AKB",
  },
  {
    label: "Atlanta, Hartsfield-Jackson Int - United States Of America (ATL)",
    value: "ATL",
  },
  {
    label: "Atlanta, Hartsfield-Jackson Int - United States Of America (ATL)",
    value: "ATL",
  },
  {
    label: "Atlanta, Perimeter Mall - United States Of America (JAJ)",
    value: "JAJ",
  },
  {
    label: "Atlanta, Galleria - United States Of America (JGL)",
    value: "JGL",
  },
  {
    label: "Atlanta, Beaver Ruin - United States Of America (JAO)",
    value: "JAO",
  },
  {
    label: "Atlanta, Dekalb-Peachtree - United States Of America (PDK)",
    value: "PDK",
  },
  {
    label: "Atlanta, Fulton County - United States Of America (FTY)",
    value: "FTY",
  },
  {
    label: "Atlantic, United States Of America (AIO)",
    value: "AIO",
  },
  {
    label: "Atlantic, Atlantic Mnpl - United States Of America (AIO)",
    value: "AIO",
  },
  {
    label: "Atlantic City, United States Of America (ACY)",
    value: "ACY",
  },
  {
    label: "Atlantic City, Atlantic City Intl - United States Of America (ACY)",
    value: "ACY",
  },
  {
    label: "Atlantic City, United States Of America (AIY)",
    value: "AIY",
  },
  {
    label: "Atlantic City, Bader Fiel - United States Of America (AIY)",
    value: "AIY",
  },
  {
    label: "Atmautluak, United States Of America (ATT)",
    value: "ATT",
  },
  {
    label: "Atmautluak, Atmautluak - United States Of America (ATT)",
    value: "ATT",
  },
  {
    label: "Atqasuk, E.Burnell Sr Memorial - United States Of America (ATK)",
    value: "ATK",
  },
  {
    label: "Attu Island, Casco Cove Cgs - United States Of America (ATU)",
    value: "ATU",
  },
  {
    label: "Auburn, Auburn University Rgnl - United States Of America (AUO)",
    value: "AUO",
  },
  {
    label: "Auburn, Municipal - United States Of America (AUN)",
    value: "AUN",
  },
  {
    label: "Augusta, Daniel - United States Of America (DNL)",
    value: "DNL",
  },
  {
    label: "Augusta, Regional  Bush Field) - United States Of America (AGS)",
    value: "AGS",
  },
  {
    label: "Augusta, State - United States Of America (AUG)",
    value: "AUG",
  },
  {
    label: "Aurora, Aurora Mnpl - United States Of America (AUZ)",
    value: "AUZ",
  },
  {
    label: "Austin, Austin - United States Of America (ASQ)",
    value: "ASQ",
  },
  {
    label: "Austin, Bergstrom Intl - United States Of America (AUS)",
    value: "AUS",
  },
  {
    label: "Austin, Mabry Arng Heliport - United States Of America (MMR)",
    value: "MMR",
  },
  {
    label: "Austin, Municipal - United States Of America (AUM)",
    value: "AUM",
  },
  {
    label: "Avon Park, Executive - United States Of America (AVO)",
    value: "AVO",
  },
  {
    label: "Baca Grande, Baca Grande - United States Of America (BCJ)",
    value: "BCJ",
  },
  {
    label: "Bagdad, Bagdad - United States Of America (BGT)",
    value: "BGT",
  },
  {
    label:
      "Bainbridge, Decatur Co Ind. Air Pk - United States Of America (BGE)",
    value: "BGE",
  },
  {
    label: "Baker, Municipal - United States Of America (BKE)",
    value: "BKE",
  },
  {
    label: "Bakersfield, Meadows Fld - United States Of America (BFL)",
    value: "BFL",
  },
  {
    label: "Bandon, State - United States Of America (BDY)",
    value: "BDY",
  },
  {
    label: "Bangor, Bangor Intl - United States Of America (BGR)",
    value: "BGR",
  },
  {
    label: "Banning, Banning - United States Of America (BNG)",
    value: "BNG",
  },
  {
    label: "Bar Harbor, Hancock County - United States Of America (BHB)",
    value: "BHB",
  },
  {
    label: "Baranof, Warm Spring Bay - United States Of America (BNF)",
    value: "BNF",
  },
  {
    label: "Barbers Point, Barbers Point - United States Of America (NAX)",
    value: "NAX",
  },
  {
    label: "Bardstown, Samuels Field - United States Of America (BRY)",
    value: "BRY",
  },
  {
    label: "Barnwell, Regional - United States Of America (BNL)",
    value: "BNL",
  },
  {
    label: "Barrow, Point Barrow - United States Of America (PBA)",
    value: "PBA",
  },
  {
    label: "Barrow, W.Post W.Rogers Mem - United States Of America (BRW)",
    value: "BRW",
  },
  {
    label: "Barter Island, Barter Island - United States Of America (BTI)",
    value: "BTI",
  },
  {
    label: "Bartlesville, Municipal - United States Of America (BVO)",
    value: "BVO",
  },
  {
    label: "Bartletts, Bartletts - United States Of America (BSZ)",
    value: "BSZ",
  },
  {
    label: "Bartow, Municipal - United States Of America (BOW)",
    value: "BOW",
  },
  {
    label: "Batesville, Batesville - United States Of America (HLB)",
    value: "HLB",
  },
  {
    label: "Batesville, Regional - United States Of America (BVX)",
    value: "BVX",
  },
  {
    label: "Baton Rouge, Downtown - United States Of America (EBR)",
    value: "EBR",
  },
  {
    label: "Baton Rouge, Metropolita Ryan Fld - United States Of America (BTR)",
    value: "BTR",
  },
  {
    label:
      "Battle Creek, West K Kellogg Regional - United States Of America (BTL)",
    value: "BTL",
  },
  {
    label: "Battle Mountain, Battle Mountain - United States Of America (BAM)",
    value: "BAM",
  },
  {
    label: "Baudette, International - United States Of America (BDE)",
    value: "BDE",
  },
  {
    label: "Bay City, Municipal - United States Of America (BBC)",
    value: "BBC",
  },
  {
    label: "Baytown, Baytown - United States Of America (HPY)",
    value: "HPY",
  },
  {
    label: "Bear Creek, Bear Creek 3 - United States Of America (BCC)",
    value: "BCC",
  },
  {
    label: "Beatrice, Municipal - United States Of America (BIE)",
    value: "BIE",
  },
  {
    label: "Beatty, Beatty - United States Of America (BTY)",
    value: "BTY",
  },
  {
    label: "Beaufort, Beaufort County - United States Of America (BFT)",
    value: "BFT",
  },
  {
    label: "Beaumont, Beaumont Municipal - United States Of America (BMT)",
    value: "BMT",
  },
  {
    label: "Beaumont, Jack Brooks Regional - United States Of America (BPT)",
    value: "BPT",
  },
  {
    label: "Beaver, Beaver - United States Of America (WBQ)",
    value: "WBQ",
  },
  {
    label:
      "Beaver Creek, Beaver Creek Bus Stn - United States Of America (ZBV)",
    value: "ZBV",
  },
  {
    label: "Beaver Falls, Beaver County - United States Of America (BFP)",
    value: "BFP",
  },
  {
    label: "Beaver Inlet, Sea Port - United States Of America (BVD)",
    value: "BVD",
  },
  {
    label: "Beckley, Raleigh County Mem - United States Of America (BKW)",
    value: "BKW",
  },
  {
    label: "Beckwourth, Nervino - United States Of America (NVN)",
    value: "NVN",
  },
  {
    label: "Bedford, Virgil I Grissom - United States Of America (BFR)",
    value: "BFR",
  },
  {
    label:
      "Bedford Hanscom, Laurence G Hanscom Fld - United States Of America (BED)",
    value: "BED",
  },
  {
    label: "Beeville, Chase Field Industrial - United States Of America (NIR)",
    value: "NIR",
  },
  {
    label: "Bell Island, Hot Springs - United States Of America (KBE)",
    value: "KBE",
  },
  {
    label: "Bellaire, Antrim County - United States Of America (ACB)",
    value: "ACB",
  },
  {
    label:
      "Belle Chasse, Southern Seaplane Apt - United States Of America (BCS)",
    value: "BCS",
  },
  {
    label: "Belleville, Midamerica St Louis - United States Of America (BLV)",
    value: "BLV",
  },
  {
    label: "Bellingham, International - United States Of America (BLI)",
    value: "BLI",
  },
  {
    label: "Belmar, Monmouth Executive - United States Of America (BLM)",
    value: "BLM",
  },
  {
    label: "Beluga, Beluga - United States Of America (BVU)",
    value: "BVU",
  },
  {
    label: "Bemidji, Regional - United States Of America (BJI)",
    value: "BJI",
  },
  {
    label:
      "Bennettsville, Marlboro Co. Jetport - United States Of America (BTN)",
    value: "BTN",
  },
  {
    label: "Benson, Benson Mnpl - United States Of America (BBB)",
    value: "BBB",
  },
  {
    label:
      "Benton Harbor, Southwest Michigan Rgl - United States Of America (BEH)",
    value: "BEH",
  },
  {
    label: "Berkeley, Berkeley - United States Of America (JBK)",
    value: "JBK",
  },
  {
    label: "Berlin, Regional - United States Of America (BML)",
    value: "BML",
  },
  {
    label: "Bethel, Bethel Airport - United States Of America (BET)",
    value: "BET",
  },
  {
    label: "Bethel, Sea Plane Base - United States Of America (JBT)",
    value: "JBT",
  },
  {
    label: "Bethpage, Grumman - United States Of America (BPA)",
    value: "BPA",
  },
  {
    label: "Bettles, Bettles - United States Of America (BTT)",
    value: "BTT",
  },
  {
    label: "Beverly, Beverly - United States Of America (BVY)",
    value: "BVY",
  },
  {
    label: "Big Bear, Big Bear City - United States Of America (RBF)",
    value: "RBF",
  },
  {
    label: "Big Creek, Big Creek - United States Of America (BIC)",
    value: "BIC",
  },
  {
    label: "Big Delta, Allen Aaf - United States Of America (BIG)",
    value: "BIG",
  },
  {
    label: "Big Lake, Big Lake - United States Of America (BGQ)",
    value: "BGQ",
  },
  {
    label: "Big Mountain, Big Mountain - United States Of America (BMX)",
    value: "BMX",
  },
  {
    label: "Big Piney, Miley Memorial Field - United States Of America (BPI)",
    value: "BPI",
  },
  {
    label: "Big Rapids, Roben Hood - United States Of America (WBR)",
    value: "WBR",
  },
  {
    label: "Big Spring, Mcmahon Wrinkle - United States Of America (HCA)",
    value: "HCA",
  },
  {
    label: "Big Spring, Webb Afb - United States Of America (BGS)",
    value: "BGS",
  },
  {
    label: "Billings, Logan Intl - United States Of America (BIL)",
    value: "BIL",
  },
  {
    label: "Biloxi, Keesler Afb - United States Of America (BIX)",
    value: "BIX",
  },
  {
    label: "Binghamton, Greater Binghamton - United States Of America (BGM)",
    value: "BGM",
  },
  {
    label: "Birch Creek, Birch Creek - United States Of America (KBC)",
    value: "KBC",
  },
  {
    label: "Birmingham, Shuttlesworth Intl - United States Of America (BHM)",
    value: "BHM",
  },
  {
    label: "Bishop, Eastern Sierra Rgnl - United States Of America (BIH)",
    value: "BIH",
  },
  {
    label: "Bismarck, Municipal - United States Of America (BIS)",
    value: "BIS",
  },
  {
    label: "Blacksburg, Virginia Tech - United States Of America (BCB)",
    value: "BCB",
  },
  {
    label: "Blackstone, Blackstone Aaf - United States Of America (BKT)",
    value: "BKT",
  },
  {
    label: "Blackwell, Blackwell Tonkawa Muni - United States Of America (BWL)",
    value: "BWL",
  },
  {
    label: "Blaine, Blaine - United States Of America (BWS)",
    value: "BWS",
  },
  {
    label: "Blairsville, Blairsville - United States Of America (BSI)",
    value: "BSI",
  },
  {
    label: "Blakely Island, Blakely Island - United States Of America (BYW)",
    value: "BYW",
  },
  {
    label: "Blanding, Municipal - United States Of America (BDG)",
    value: "BDG",
  },
  {
    label: "Block Island, State - United States Of America (BID)",
    value: "BID",
  },
  {
    label:
      "Bloomington, Central Illinois Rgnl - United States Of America (BMI)",
    value: "BMI",
  },
  {
    label: "Bloomington, Monroe County - United States Of America (BMG)",
    value: "BMG",
  },
  {
    label: "Blue Canyon, Blue Canyon Nyack - United States Of America (BLU)",
    value: "BLU",
  },
  {
    label: "Blue Fox Bay, Blue Fox Bay - United States Of America (BFB)",
    value: "BFB",
  },
  {
    label: "Blue Mountain, Blue Mountain - United States Of America (VBM)",
    value: "VBM",
  },
  {
    label: "Bluefield, Mercer County - United States Of America (BLF)",
    value: "BLF",
  },
  {
    label: "Blythe, Blythe - United States Of America (BLH)",
    value: "BLH",
  },
  {
    label: "Blytheville, Arkansas Intl - United States Of America (BYH)",
    value: "BYH",
  },
  {
    label: "Blytheville, Blytheville Mnpl - United States Of America (HKA)",
    value: "HKA",
  },
  {
    label: "Boca Raton, Boca Raton - United States Of America (BCT)",
    value: "BCT",
  },
  {
    label: "Bogalusa, George R Carr Mem - United States Of America (BXA)",
    value: "BXA",
  },
  {
    label: "Boise, Air Term Gowen Fld - United States Of America (BOI)",
    value: "BOI",
  },
  {
    label: "Boone, Municipal - United States Of America (BNW)",
    value: "BNW",
  },
  {
    label: "Borger, Hutchinson County - United States Of America (BGD)",
    value: "BGD",
  },
  {
    label: "Bornite, Bornite Upper - United States Of America (RLU)",
    value: "RLU",
  },
  {
    label: "Borrego Springs, Borrego Valley - United States Of America (BXS)",
    value: "BXS",
  },
  {
    label: "Boston, MA - United States Of America (BOS)",
    value: "BOS",
  },
  {
    label: "Boston, Edward L Logan Intl - United States Of America (BOS)",
    value: "BOS",
  },
  {
    label: "Boston, Portsmouth Pease Intl - United States Of America (PSM)",
    value: "PSM",
  },
  {
    label: "Boswell Bay, Boswell Bay - United States Of America (BSW)",
    value: "BSW",
  },
  {
    label: "Boulder, Boulder Mnpl - United States Of America (WBU)",
    value: "WBU",
  },
  {
    label: "Boulder, Broker Inn - United States Of America (WBI)",
    value: "WBI",
  },
  {
    label: "Boulder, Hilton Har H - United States Of America (WHH)",
    value: "WHH",
  },
  {
    label: "Boundary, Boundary - United States Of America (BYA)",
    value: "BYA",
  },
  {
    label: "Bountiful, Skypark - United States Of America (BTF)",
    value: "BTF",
  },
  {
    label: "Bowling Green, A.P. Hill Aaf - United States Of America (APH)",
    value: "APH",
  },
  {
    label:
      "Bowling Green, Warren County Regional - United States Of America (BWG)",
    value: "BWG",
  },
  {
    label: "Bowman, Municipal - United States Of America (BWM)",
    value: "BWM",
  },
  {
    label: "Boxborough, Boxborough - United States Of America (BXC)",
    value: "BXC",
  },
  {
    label: "Bozeman, Yellowstone Intl - United States Of America (BZN)",
    value: "BZN",
  },
  {
    label: "Bradford, Regional - United States Of America (BFD)",
    value: "BFD",
  },
  {
    label: "Bradford, Rinkenberger - United States Of America (BDF)",
    value: "BDF",
  },
  {
    label: "Brady, Curtis Field - United States Of America (BBD)",
    value: "BBD",
  },
  {
    label: "Brainerd, Brainerd Lakes Rgnl - United States Of America (BRD)",
    value: "BRD",
  },
  {
    label: "Branson, Branson - United States Of America (BKG)",
    value: "BKG",
  },
  {
    label: "Brawley, Municipal - United States Of America (BWC)",
    value: "BWC",
  },
  {
    label: "Brazoria, Eagle Air Park - United States Of America (BZT)",
    value: "BZT",
  },
  {
    label: "Breckenridge, Stephens C - United States Of America (BKD)",
    value: "BKD",
  },
  {
    label: "Bremerton, National - United States Of America (PWT)",
    value: "PWT",
  },
  {
    label: "Bridgeport, I Sikorsky - United States Of America (BDR)",
    value: "BDR",
  },
  {
    label: "Brigham City, Brigham City - United States Of America (BMC)",
    value: "BMC",
  },
  {
    label:
      "Bristol/Johnson City/Kin, Tri-Cities Rgnl Tn - United States Of America (TRI)",
    value: "TRI",
  },
  {
    label: "Britton, Britton Mnpl - United States Of America (TTO)",
    value: "TTO",
  },
  {
    label: "Broadus, Broadus - United States Of America (BDX)",
    value: "BDX",
  },
  {
    label: "Broken Bow, Municipal - United States Of America (BBW)",
    value: "BBW",
  },
  {
    label: "Brookings, Brookings - United States Of America (BOK)",
    value: "BOK",
  },
  {
    label: "Brookings, Regional - United States Of America (BKX)",
    value: "BKX",
  },
  {
    label: "Brooks Lake, Lake Brooks Spb - United States Of America (BKF)",
    value: "BKF",
  },
  {
    label: "Brooks Lodge, Brooks Lodge - United States Of America (RBH)",
    value: "RBH",
  },
  {
    label: "Brownsville, South Padre - United States Of America (BRO)",
    value: "BRO",
  },
  {
    label: "Brownwood, Regional - United States Of America (BWD)",
    value: "BWD",
  },
  {
    label: "Brunswick, Executive - United States Of America (NHZ)",
    value: "NHZ",
  },
  {
    label: "Brunswick, Golden Isles - United States Of America (BQK)",
    value: "BQK",
  },
  {
    label: "Brunswick, Mckinnon St Simons Is. - United States Of America (SSI)",
    value: "SSI",
  },
  {
    label: "Bryan, Coulter Field - United States Of America (CFD)",
    value: "CFD",
  },
  {
    label: "Bryce, Bryce Canyon - United States Of America (BCE)",
    value: "BCE",
  },
  {
    label: "Buckeye, Municipal - United States Of America (BXK)",
    value: "BXK",
  },
  {
    label: "Buckland, Buckland - United States Of America (BKC)",
    value: "BKC",
  },
  {
    label: "Buffalo, Buffalo Niagara Intl - United States Of America (BUF)",
    value: "BUF",
  },
  {
    label: "Buffalo, Johnson County - United States Of America (BYG)",
    value: "BYG",
  },
  {
    label: "Bullfrog Basin, Bullfrog Basin - United States Of America (BFG)",
    value: "BFG",
  },
  {
    label:
      "Bullhead City, Laughlin Bullhead Intl - United States Of America (IFP)",
    value: "IFP",
  },
  {
    label: "Burley, Burley Municipal - United States Of America (BYI)",
    value: "BYI",
  },
  {
    label: "Burlington, Burlington - United States Of America (BBF)",
    value: "BBF",
  },
  {
    label: "Burlington, Burlington Intl - United States Of America (BTV)",
    value: "BTV",
  },
  {
    label: "Burlington, Southeast Iowa Rgnl - United States Of America (BRL)",
    value: "BRL",
  },
  {
    label: "Burns, Municipal - United States Of America (BNO)",
    value: "BNO",
  },
  {
    label: "Burwell, Cram Field - United States Of America (BUB)",
    value: "BUB",
  },
  {
    label: "Butler, Butler County - United States Of America (BTP)",
    value: "BTP",
  },
  {
    label: "Butler, Memorial - United States Of America (BUM)",
    value: "BUM",
  },
  {
    label: "Butte, Bert Mooney - United States Of America (BTM)",
    value: "BTM",
  },
  {
    label: "Cabin Creek, Cabin Creek - United States Of America (CBZ)",
    value: "CBZ",
  },
  {
    label: "Cadillac, Wexford County - United States Of America (CAD)",
    value: "CAD",
  },
  {
    label: "Cairo, Cairo - United States Of America (CIR)",
    value: "CIR",
  },
  {
    label: "Caldwell, Essex County - United States Of America (CDW)",
    value: "CDW",
  },
  {
    label: "Calexico, Calexico Intl - United States Of America (CXL)",
    value: "CXL",
  },
  {
    label: "Calipatria, Cliff Hatfield Mem - United States Of America (CLR)",
    value: "CLR",
  },
  {
    label:
      "Callaway Gardens, Callaway Gardens - United States Of America (CWG)",
    value: "CWG",
  },
  {
    label: "Calverton, Executive Airpark - United States Of America (CTO)",
    value: "CTO",
  },
  {
    label: "Cambridge, Cambridge-Dorchester - United States Of America (CGE)",
    value: "CGE",
  },
  {
    label: "Cambridge, Hyatt Reg Hpt - United States Of America (JHY)",
    value: "JHY",
  },
  {
    label: "Camden, Harrell Fld - United States Of America (CDH)",
    value: "CDH",
  },
  {
    label: "Camden, Woodward Fld - United States Of America (CDN)",
    value: "CDN",
  },
  {
    label: "Camp Douglas, Volk Field - United States Of America (VOK)",
    value: "VOK",
  },
  {
    label: "Camp Springs, Andrews Naf - United States Of America (NSF)",
    value: "NSF",
  },
  {
    label: "Camp Springs, Joint Base Andrews - United States Of America (ADW)",
    value: "ADW",
  },
  {
    label: "Campo, Campo - United States Of America (CZZ)",
    value: "CZZ",
  },
  {
    label: "Candle, Candle - United States Of America (CDL)",
    value: "CDL",
  },
  {
    label: "Canon City, Canon City - United States Of America (CNE)",
    value: "CNE",
  },
  {
    label: "Canton, Municipal - United States Of America (CTK)",
    value: "CTK",
  },
  {
    label: "Canton Akron, Akron Fulton Intl - United States Of America (AKC)",
    value: "AKC",
  },
  {
    label: "Canton Akron, Regional - United States Of America (CAK)",
    value: "CAK",
  },
  {
    label: "Cape Girardeau, Regional - United States Of America (CGI)",
    value: "CGI",
  },
  {
    label: "Cape Lisburne, Cape Lisburne - United States Of America (LUR)",
    value: "LUR",
  },
  {
    label: "Cape Newenham, Cape Newenham - United States Of America (EHM)",
    value: "EHM",
  },
  {
    label: "Cape Pole, Sea Plane Base - United States Of America (CZP)",
    value: "CZP",
  },
  {
    label: "Cape Romanzof, Cape Romanzof - United States Of America (CZF)",
    value: "CZF",
  },
  {
    label: "Cape Spencer, Coast Guard - United States Of America (CSP)",
    value: "CSP",
  },
  {
    label: "Carbondale, Southern Illinois - United States Of America (MDH)",
    value: "MDH",
  },
  {
    label: "Caribou, Caribou Mnpl - United States Of America (CAR)",
    value: "CAR",
  },
  {
    label: "Carlsbad, Cavern City Air Term - United States Of America (CNM)",
    value: "CNM",
  },
  {
    label: "Caro, Tuscola Area - United States Of America (TZC)",
    value: "TZC",
  },
  {
    label: "Carrizo Springs, Dimmit County - United States Of America (CZT)",
    value: "CZT",
  },
  {
    label: "Carroll, Arthur N Neu - United States Of America (CIN)",
    value: "CIN",
  },
  {
    label: "Carson City, Carson City - United States Of America (CSN)",
    value: "CSN",
  },
  {
    label: "Casa Grande, Casa Grande Mnpl - United States Of America (CGZ)",
    value: "CGZ",
  },
  {
    label: "Cascade, Cascade - United States Of America (ICS)",
    value: "ICS",
  },
  {
    label: "Cascade Locks, State - United States Of America (CZK)",
    value: "CZK",
  },
  {
    label: "Casper, Natrona County Intl - United States Of America (CPR)",
    value: "CPR",
  },
  {
    label: "Catalina Island, Apt In The Sky - United States Of America (CIB)",
    value: "CIB",
  },
  {
    label: "Catalina Island, Avalo Vor - United States Of America (SXC)",
    value: "SXC",
  },
  {
    label: "Catalina Island, Catalina - United States Of America (AVX)",
    value: "AVX",
  },
  {
    label: "Catalina Island, Two Harbors - United States Of America (TWH)",
    value: "TWH",
  },
  {
    label: "Cedar City, Regional - United States Of America (CDC)",
    value: "CDC",
  },
  {
    label: "Cedar Key, Gerorge T. Lewis - United States Of America (CDK)",
    value: "CDK",
  },
  {
    label: "Cedar Rapids, The Eastern Iowa - United States Of America (CID)",
    value: "CID",
  },
  {
    label: "Center Island, Center Island - United States Of America (CWS)",
    value: "CWS",
  },
  {
    label: "Centerville, Centerville Mnpl - United States Of America (GHM)",
    value: "GHM",
  },
  {
    label: "Central, Central - United States Of America (CEM)",
    value: "CEM",
  },
  {
    label: "Centralia, Centralia Mnpl - United States Of America (ENL)",
    value: "ENL",
  },
  {
    label: "Chadron, Municipal - United States Of America (CDR)",
    value: "CDR",
  },
  {
    label: "Chalkyitsik, Chalkyitsik - United States Of America (CIK)",
    value: "CIK",
  },
  {
    label: "Challis, Challis - United States Of America (CHL)",
    value: "CHL",
  },
  {
    label: "Champaign, University Of Illinois - United States Of America (CMI)",
    value: "CMI",
  },
  {
    label: "Chandalar, Chandalar Lake - United States Of America (WCR)",
    value: "WCR",
  },
  {
    label: "Chanute, Martin Johnson - United States Of America (CNU)",
    value: "CNU",
  },
  {
    label: "Charles City, Northeast Iowa Rgnl - United States Of America (CCY)",
    value: "CCY",
  },
  {
    label: "Charleston, SC - United States Of America (CHS)",
    value: "CHS",
  },
  {
    label: "Charleston, International Afb - United States Of America (CHS)",
    value: "CHS",
  },
  {
    label: "Charleston, WV - United States Of America (CRW)",
    value: "CRW",
  },
  {
    label: "Charleston, Yeager - United States Of America (CRW)",
    value: "CRW",
  },
  {
    label: "Charlotte, Douglas Intl - United States Of America (CLT)",
    value: "CLT",
  },
  {
    label: "Charlotte, Wilgrove Park - United States Of America (QWG)",
    value: "QWG",
  },
  {
    label: "Charlottesville, Albemarl - United States Of America (CHO)",
    value: "CHO",
  },
  {
    label: "Chatham, Chatham Spb - United States Of America (CYM)",
    value: "CYM",
  },
  {
    label: "Chattanooga, Metropolitan Apt - United States Of America (CHA)",
    value: "CHA",
  },
  {
    label: "Chefornak, Chefornak - United States Of America (CYF)",
    value: "CYF",
  },
  {
    label: "Chehalis, Chehalis Centralia - United States Of America (CLS)",
    value: "CLS",
  },
  {
    label:
      "Chena Hot Springs, Chena Hot Springs - United States Of America (CEX)",
    value: "CEX",
  },
  {
    label: "Cheraw, Municipal - United States Of America (HCW)",
    value: "HCW",
  },
  {
    label: "Chernofski, Spb - United States Of America (KCN)",
    value: "KCN",
  },
  {
    label: "Cherokee, Kegelman Af Aux Field - United States Of America (CKA)",
    value: "CKA",
  },
  {
    label: "Cherokee, Sharp County Regional - United States Of America (CKK)",
    value: "CKK",
  },
  {
    label: "Chevak, Chevak - United States Of America (VAK)",
    value: "VAK",
  },
  {
    label: "Cheyenne, Fe Warren Afb Hp - United States Of America (FEW)",
    value: "FEW",
  },
  {
    label: "Cheyenne, Regional - United States Of America (CYS)",
    value: "CYS",
  },
  {
    label: "Chicago, United States Of America (CHI)",
    value: "CHI",
  },
  {
    label: "Chicago, O Hare International - United States Of America (ORD)",
    value: "ORD",
  },
  {
    label: "Chicago, Midway International - United States Of America (MDW)",
    value: "MDW",
  },
  {
    label: "Chicago, Chicago Rockford Intl - United States Of America (RFD)",
    value: "RFD",
  },
  {
    label: "Chicago, Dupage - United States Of America (DPA)",
    value: "DPA",
  },
  {
    label: "Chicago, Executive - United States Of America (PWK)",
    value: "PWK",
  },
  {
    label: "Chickasha, Chickasha Mnpl - United States Of America (CHK)",
    value: "CHK",
  },
  {
    label: "Chicken, Chicken - United States Of America (CKX)",
    value: "CKX",
  },
  {
    label: "Chico, Municipal - United States Of America (CIC)",
    value: "CIC",
  },
  {
    label: "Chignik, Chignik Airport - United States Of America (KCG)",
    value: "KCG",
  },
  {
    label: "Chignik, Chignik Bay Spb - United States Of America (KBW)",
    value: "KBW",
  },
  {
    label: "Chignik, Chignik Lagoon - United States Of America (KCL)",
    value: "KCL",
  },
  {
    label: "Chignik, Chignik Lake - United States Of America (KCQ)",
    value: "KCQ",
  },
  {
    label: "Childress, Childress - United States Of America (CDS)",
    value: "CDS",
  },
  {
    label: "Chiloquin, State - United States Of America (CHZ)",
    value: "CHZ",
  },
  {
    label:
      "Chincoteague, Wallops Flt Facility - United States Of America (WAL)",
    value: "WAL",
  },
  {
    label: "Chino, Chino - United States Of America (CNO)",
    value: "CNO",
  },
  {
    label: "Chisana, Chisana - United States Of America (CZN)",
    value: "CZN",
  },
  {
    label: "Chistochina, Chistochina - United States Of America (CZO)",
    value: "CZO",
  },
  {
    label: "Chitina, Chitina Apt - United States Of America (CXC)",
    value: "CXC",
  },
  {
    label: "Chomley, Chomley - United States Of America (CIV)",
    value: "CIV",
  },
  {
    label: "Chuathbaluk, Chuathbaluk - United States Of America (CHU)",
    value: "CHU",
  },
  {
    label: "Cincinnati, Municipal Lunken Fld - United States Of America (LUK)",
    value: "LUK",
  },
  {
    label:
      "Cincinnati, Northern Kentucky Intl - United States Of America (CVG)",
    value: "CVG",
  },
  {
    label: "Cinder River, Cinder River - United States Of America (RCP)",
    value: "RCP",
  },
  {
    label: "Circle, Circle City - United States Of America (IRC)",
    value: "IRC",
  },
  {
    label:
      "Circle Hot Spring, Circle Hot Spring - United States Of America (CHP)",
    value: "CHP",
  },
  {
    label: "Claremont, Claremont Mnpl - United States Of America (CNH)",
    value: "CNH",
  },
  {
    label: "Clarinda, Schenck Field - United States Of America (ICL)",
    value: "ICL",
  },
  {
    label: "Clarks Point, Clarks Point - United States Of America (CLP)",
    value: "CLP",
  },
  {
    label: "Clarksburg, North Central W.V. - United States Of America (CKB)",
    value: "CKB",
  },
  {
    label: "Clarksdale, Fletcher Fld - United States Of America (CKM)",
    value: "CKM",
  },
  {
    label:
      "Clarksville, Montgomery County Rgnl - United States Of America (CKV)",
    value: "CKV",
  },
  {
    label: "Clayton, Municipal Airpark - United States Of America (CAO)",
    value: "CAO",
  },
  {
    label: "Clear Lake, Lampson Field - United States Of America (CKE)",
    value: "CKE",
  },
  {
    label: "Clearlake, Metroport - United States Of America (CLC)",
    value: "CLC",
  },
  {
    label: "Clemson, Oconee County Regional - United States Of America (CEU)",
    value: "CEU",
  },
  {
    label: "Cleveland, Burke Lakefron - United States Of America (BKL)",
    value: "BKL",
  },
  {
    label: "Cleveland, Cuyahoga - United States Of America (CGF)",
    value: "CGF",
  },
  {
    label: "Cleveland, Hopkins Int - United States Of America (CLE)",
    value: "CLE",
  },
  {
    label: "Clifton, Greenlee County - United States Of America (CFT)",
    value: "CFT",
  },
  {
    label: "Clinton, Municipal - United States Of America (CWI)",
    value: "CWI",
  },
  {
    label: "Clinton, Regional - United States Of America (CLK)",
    value: "CLK",
  },
  {
    label: "Clinton, Sampson County - United States Of America (CTZ)",
    value: "CTZ",
  },
  {
    label: "Clinton, Sherman - United States Of America (CSM)",
    value: "CSM",
  },
  {
    label: "Clintonville, Municipal - United States Of America (CLI)",
    value: "CLI",
  },
  {
    label: "Clovis, Cannon Afb - United States Of America (CVS)",
    value: "CVS",
  },
  {
    label: "Clovis, Clovis Mnpl - United States Of America (CVN)",
    value: "CVN",
  },
  {
    label: "Coalinga, Municipal - United States Of America (CLG)",
    value: "CLG",
  },
  {
    label: "Coatesville, Chester County - United States Of America (CTH)",
    value: "CTH",
  },
  {
    label: "Cocoa, Merritt Island - United States Of America (COI)",
    value: "COI",
  },
  {
    label: "Cocoa Beach, Patrick Afb - United States Of America (COF)",
    value: "COF",
  },
  {
    label: "Cody, Yellowstone Rgnl - United States Of America (COD)",
    value: "COD",
  },
  {
    label:
      "Coeur D'alene, Popy Boyington Field - United States Of America (COE)",
    value: "COE",
  },
  {
    label: "Coffee Point, Coffee Point - United States Of America (CFA)",
    value: "CFA",
  },
  {
    label: "Coffeyville, Coffeyville Mnpl - United States Of America (CFV)",
    value: "CFV",
  },
  {
    label: "Coffman Cove, Coffman Co - United States Of America (KCC)",
    value: "KCC",
  },
  {
    label: "Colby, Shalz Field - United States Of America (CBK)",
    value: "CBK",
  },
  {
    label: "Cold Bay, Cold Bay - United States Of America (CDB)",
    value: "CDB",
  },
  {
    label: "Coldfoot, Coldfoot - United States Of America (CXF)",
    value: "CXF",
  },
  {
    label: "Coleman, Municipal - United States Of America (COM)",
    value: "COM",
  },
  {
    label: "College Park, United States Of America (CGS)",
    value: "CGS",
  },
  {
    label: "College Park, College Park - United States Of America (CGS)",
    value: "CGS",
  },
  {
    label: "College Station, United States Of America (CLL)",
    value: "CLL",
  },
  {
    label: "College Station, Easterw - United States Of America (CLL)",
    value: "CLL",
  },
  {
    label: "Colorado Creek, United States Of America (KCR)",
    value: "KCR",
  },
  {
    label: "Colorado Creek, Colorado Creek - United States Of America (KCR)",
    value: "KCR",
  },
  {
    label: "Colorado Springs, United States Of America (COS)",
    value: "COS",
  },
  {
    label:
      "Colorado Springs, Colorado Springs Mnpl - United States Of America (COS)",
    value: "COS",
  },
  {
    label:
      "Colorado, Denver, Denver International - United States Of America (DEN)",
    value: "DEN",
  },
  {
    label: "Colorado, Gunnison Crested Butte - United States Of America (GUC)",
    value: "GUC",
  },
  {
    label:
      "Colorado Springs, Fort Carson Butts Aaf - United States Of America (FCS)",
    value: "FCS",
  },
  {
    label:
      "Colorado Springs, Usaf Academy Airfield - United States Of America (AFF)",
    value: "AFF",
  },
  {
    label: "Akron, United States Of America (AKO)",
    value: "AKO",
  },
  {
    label: "Akron, Colorado Plains Rgnl - United States Of America (AKO)",
    value: "AKO",
  },
  {
    label: "Columbia, United States Of America (CAE)",
    value: "CAE",
  },
  {
    label: "Columbia, Metropolitan Apt - United States Of America (CAE)",
    value: "CAE",
  },
  {
    label: "Columbia, Columbia - United States Of America (COA)",
    value: "COA",
  },
  {
    label: "Columbia, J.Hamilton-L.B. Owens - United States Of America (CUB)",
    value: "CUB",
  },
  {
    label: "Columbia, Maury County - United States Of America (MRC)",
    value: "MRC",
  },
  {
    label: "Columbia, Mcentire Jngb - United States Of America (MMT)",
    value: "MMT",
  },
  {
    label: "Columbia, Regional - United States Of America (COU)",
    value: "COU",
  },
  {
    label: "Columbus, United States Of America (CMH)",
    value: "CMH",
  },
  {
    label: "Columbus, Port Columbus - United States Of America (CMH)",
    value: "CMH",
  },
  {
    label: "Columbus, Rickenbacker Intl - United States Of America (LCK)",
    value: "LCK",
  },
  {
    label: "Columbus, State Univ - United States Of America (OSU)",
    value: "OSU",
  },
  {
    label: "Columbus, Lowndes County - United States Of America (UBS)",
    value: "UBS",
  },
  {
    label: "Columbus, Columbus Afb - United States Of America (CBM)",
    value: "CBM",
  },
  {
    label: "Columbus, Golden Triangle Rgnl - United States Of America (GTR)",
    value: "GTR",
  },
  {
    label: "Columbus, Lowndes County - United States Of America (UBS)",
    value: "UBS",
  },
  {
    label: "Columbus, United States Of America (CSG)",
    value: "CSG",
  },
  {
    label: "Columbus, Columbus Apt - United States Of America (CSG)",
    value: "CSG",
  },
  {
    label: "Columbus, Lawson Aaf-Ft Benning - United States Of America (LSF)",
    value: "LSF",
  },
  {
    label: "Columbus, Mckenna Aaf - United States Of America (MKF)",
    value: "MKF",
  },
  {
    label: "Columbus, United States Of America (CLU)",
    value: "CLU",
  },
  {
    label: "Columbus, Columbus Mncpl - United States Of America (CLU)",
    value: "CLU",
  },
  {
    label: "Columbus, United States Of America (CUS)",
    value: "CUS",
  },
  {
    label: "Columbus, Columbus Mnpl - United States Of America (CUS)",
    value: "CUS",
  },
  {
    label: "Columbus, United States Of America (OLU)",
    value: "OLU",
  },
  {
    label: "Columbus, Municipal - United States Of America (OLU)",
    value: "OLU",
  },
  {
    label: "Compton, United States Of America (CPM)",
    value: "CPM",
  },
  {
    label: "Compton, Woodley - United States Of America (CPM)",
    value: "CPM",
  },
  {
    label: "Concord, United States Of America (USA)",
    value: "USA",
  },
  {
    label: "Concord, Regional - United States Of America (USA)",
    value: "USA",
  },
  {
    label: "Concord, United States Of America (CCR)",
    value: "CCR",
  },
  {
    label: "Concord, Buchanan Fld - United States Of America (CCR)",
    value: "CCR",
  },
  {
    label: "Concord, United States Of America (CON)",
    value: "CON",
  },
  {
    label: "Concord, Municipal - United States Of America (CON)",
    value: "CON",
  },
  {
    label: "Concordia, United States Of America (CNK)",
    value: "CNK",
  },
  {
    label: "Concordia, Blosser Munic - United States Of America (CNK)",
    value: "CNK",
  },
  {
    label: "Connersville, Mettel Field - United States Of America (CEV)",
    value: "CEV",
  },
  {
    label: "Connersville, Mettel Field - United States Of America (CEV)",
    value: "CEV",
  },
  {
    label: "Conroe, United States Of America (CXO)",
    value: "CXO",
  },
  {
    label: "Conroe, Lone Star Executive - United States Of America (CXO)",
    value: "CXO",
  },
  {
    label: "Cooper Lodge, United States Of America (JLA)",
    value: "JLA",
  },
  {
    label: "Cooper Lodge, Quartz Creek - United States Of America (JLA)",
    value: "JLA",
  },
  {
    label: "Cooperstown, United States Of America (COP)",
    value: "COP",
  },
  {
    label: "Cooperstown, Westville - United States Of America (COP)",
    value: "COP",
  },
  {
    label: "Copper Center, United States Of America (CZC)",
    value: "CZC",
  },
  {
    label: "Copper Center, Cooper Center 2 - United States Of America (CZC)",
    value: "CZC",
  },
  {
    label: "Corcoran, United States Of America (CRO)",
    value: "CRO",
  },
  {
    label: "Corcoran, Corcoran - United States Of America (CRO)",
    value: "CRO",
  },
  {
    label: "Cordova, United States Of America (CDV)",
    value: "CDV",
  },
  {
    label: "Cordova, Merle K Mudhole Smith - United States Of America (CDV)",
    value: "CDV",
  },
  {
    label: "Cordova, Municipal - United States Of America (CKU)",
    value: "CKU",
  },
  {
    label: "Corinth, United States Of America (CRX)",
    value: "CRX",
  },
  {
    label: "Corinth, Roscoe Turner - United States Of America (CRX)",
    value: "CRX",
  },
  {
    label: "Corpus Christi, United States Of America (CRP)",
    value: "CRP",
  },
  {
    label:
      "Corpus Christi, Corpus Christi Intl - United States Of America (CRP)",
    value: "CRP",
  },
  {
    label:
      "Corpus Christi, Cabaniss Field Nolf - United States Of America (NGW)",
    value: "NGW",
  },
  {
    label: "Corpus Christi, Cuddihy - United States Of America (CUX)",
    value: "CUX",
  },
  {
    label: "Corpus Christi, Nas Truax Field - United States Of America (NGP)",
    value: "NGP",
  },
  {
    label: "Corsicana, United States Of America (CRS)",
    value: "CRS",
  },
  {
    label: "Corsicana, C David Campbell Fld - United States Of America (CRS)",
    value: "CRS",
  },
  {
    label: "Cortez, United States Of America (CEZ)",
    value: "CEZ",
  },
  {
    label: "Cortez, Cortez Municipal - United States Of America (CEZ)",
    value: "CEZ",
  },
  {
    label: "Cortland, United States Of America (CTX)",
    value: "CTX",
  },
  {
    label: "Cortland, Cortland County - United States Of America (CTX)",
    value: "CTX",
  },
  {
    label: "Corvallis, United States Of America (CVO)",
    value: "CVO",
  },
  {
    label: "Corvallis, Corvallis Municipal - United States Of America (CVO)",
    value: "CVO",
  },
  {
    label: "Cottonwood, United States Of America (CTW)",
    value: "CTW",
  },
  {
    label: "Cottonwood, Cottonwood - United States Of America (CTW)",
    value: "CTW",
  },
  {
    label: "Cotulla, United States Of America (COT)",
    value: "COT",
  },
  {
    label: "Cotulla, La Salle County - United States Of America (COT)",
    value: "COT",
  },
  {
    label: "Council, United States Of America (CIL)",
    value: "CIL",
  },
  {
    label: "Council, Council - United States Of America (CIL)",
    value: "CIL",
  },
  {
    label: "Council Bluffs, United States Of America (CBF)",
    value: "CBF",
  },
  {
    label:
      "Council Bluffs, Council Bluffs Mnpl - United States Of America (CBF)",
    value: "CBF",
  },
  {
    label: "Craig, United States Of America (CGA)",
    value: "CGA",
  },
  {
    label: "Craig, Craig Spb - United States Of America (CGA)",
    value: "CGA",
  },
  {
    label: "Craig, United States Of America (CIG)",
    value: "CIG",
  },
  {
    label: "Craig, Craig Moffat - United States Of America (CIG)",
    value: "CIG",
  },
  {
    label: "Crane, United States Of America (CCG)",
    value: "CCG",
  },
  {
    label: "Crane, Crane County - United States Of America (CCG)",
    value: "CCG",
  },
  {
    label: "Crane Island, United States Of America (CKR)",
    value: "CKR",
  },
  {
    label: "Crane Island, Crane Island - United States Of America (CKR)",
    value: "CKR",
  },
  {
    label: "Crescent City, United States Of America (CEC)",
    value: "CEC",
  },
  {
    label:
      "Crescent City, Del Norte County Rgnl - United States Of America (CEC)",
    value: "CEC",
  },
  {
    label: "Crested Butte, United States Of America (CSE)",
    value: "CSE",
  },
  {
    label: "Crested Butte, Buckhorn Ranch - United States Of America (CSE)",
    value: "CSE",
  },
  {
    label: "Creston, United States Of America (CSQ)",
    value: "CSQ",
  },
  {
    label: "Creston, Creston Mnpl - United States Of America (CSQ)",
    value: "CSQ",
  },
  {
    label: "Crestview, Bob Sikes - United States Of America (CEW)",
    value: "CEW",
  },
  {
    label: "Crestview, Duke Field - United States Of America (EGI)",
    value: "EGI",
  },
  {
    label: "Crooked Creek, Crooked Creek - United States Of America (CKD)",
    value: "CKD",
  },
  {
    label: "Crookston, Crookston Mnpl - United States Of America (CKN)",
    value: "CKN",
  },
  {
    label: "Cross City, Cross City - United States Of America (CTY)",
    value: "CTY",
  },
  {
    label: "Crossett, Zm Jack Stell Field - United States Of America (CRT)",
    value: "CRT",
  },
  {
    label:
      "Crossville, Memorial-Whitson Field - United States Of America (CSV)",
    value: "CSV",
  },
  {
    label: "Crows Landing, Crows Landing - United States Of America (NRC)",
    value: "NRC",
  },
  {
    label: "Crystal Lake, Crystal Lake - United States Of America (CYE)",
    value: "CYE",
  },
  {
    label: "Cube Cove, Cube Cove - United States Of America (CUW)",
    value: "CUW",
  },
  {
    label: "Culver City, Hughes - United States Of America (CVR)",
    value: "CVR",
  },
  {
    label:
      "Cumberland, Greater Cumberland Rgl - United States Of America (CBE)",
    value: "CBE",
  },
  {
    label: "Cushing, Cushing Mnpl - United States Of America (CUH)",
    value: "CUH",
  },
  {
    label: "Cut Bank, Cut Bank International - United States Of America (CTB)",
    value: "CTB",
  },
  {
    label: "Daggett, Barstow Daggett - United States Of America (DAG)",
    value: "DAG",
  },
  {
    label: "Dahl Creek, Dahl Creek - United States Of America (DCK)",
    value: "DCK",
  },
  {
    label: "Dahlgren, Nswc - United States Of America (DGN)",
    value: "DGN",
  },
  {
    label: "Dalhart, Municipal - United States Of America (DHT)",
    value: "DHT",
  },
  {
    label: "Dallas, TX - United States Of America (DFW)",
    value: "DFW",
  },
  {
    label: "Dallas, Dallas Ft Worth Intl - United States Of America (DFW)",
    value: "DFW",
  },
  {
    label: "Dallas, Love Field - United States Of America (DAL)",
    value: "DAL",
  },
  {
    label: "Dallas, Addison - United States Of America (ADS)",
    value: "ADS",
  },
  {
    label: "Dallas, Dallas North - United States Of America (DNE)",
    value: "DNE",
  },
  {
    label: "Dallas, Executive - United States Of America (RBD)",
    value: "RBD",
  },
  {
    label: "Dallas, Fort Worth Alliance - United States Of America (AFW)",
    value: "AFW",
  },
  {
    label: "Dallas, Fort Worth Meacham Int - United States Of America (FTW)",
    value: "FTW",
  },
  {
    label: "Dallas, Fort Worth Nas Jrb - United States Of America (FWH)",
    value: "FWH",
  },
  {
    label: "Dalton, Dalton Mnpl - United States Of America (DNN)",
    value: "DNN",
  },
  {
    label: "Danbury, Municipal - United States Of America (DXR)",
    value: "DXR",
  },
  {
    label: "Danger Bay, Danger Bay - United States Of America (DGB)",
    value: "DGB",
  },
  {
    label: "Dansville, Municipal - United States Of America (DSV)",
    value: "DSV",
  },
  {
    label: "Danville, Regional - United States Of America (DAN)",
    value: "DAN",
  },
  {
    label: "Danville, Vermilion Regional - United States Of America (DNV)",
    value: "DNV",
  },
  {
    label: "Davenport, Municipal - United States Of America (DVN)",
    value: "DVN",
  },
  {
    label: "Dayton, James M Cox Intl - United States Of America (DAY)",
    value: "DAY",
  },
  {
    label: "Dayton, Wright Afb - United States Of America (DWF)",
    value: "DWF",
  },
  {
    label: "Dayton, Wright Brothers - United States Of America (MGY)",
    value: "MGY",
  },
  {
    label: "Dayton, Wright Patterson Afb - United States Of America (FFO)",
    value: "FFO",
  },
  {
    label: "De Ridder, Beauregard Regional - United States Of America (DRI)",
    value: "DRI",
  },
  {
    label: "Dearborn, Hyatt Regency - United States Of America (DEO)",
    value: "DEO",
  },
  {
    label: "Death Valley, Furnance Creek - United States Of America (DTH)",
    value: "DTH",
  },
  {
    label: "Decatur, Decatur Apt - United States Of America (DEC)",
    value: "DEC",
  },
  {
    label: "Decatur, Decatur Hi Way - United States Of America (DCR)",
    value: "DCR",
  },
  {
    label: "Decatur, Pryor Field Regional - United States Of America (DCU)",
    value: "DCU",
  },
  {
    label: "Decatur Island, Decatur Shores - United States Of America (DTR)",
    value: "DTR",
  },
  {
    label: "Decorah, Decorah Mnpl - United States Of America (DEH)",
    value: "DEH",
  },
  {
    label: "Deep Bay, Deep Bay - United States Of America (WDB)",
    value: "WDB",
  },
  {
    label: "Deer Harbor, Sea Plane Base - United States Of America (DHB)",
    value: "DHB",
  },
  {
    label: "Deer Park, Deer Park - United States Of America (DPK)",
    value: "DPK",
  },
  {
    label: "Deering, Deering - United States Of America (DRG)",
    value: "DRG",
  },
  {
    label: "Defiance, Memorial - United States Of America (DFI)",
    value: "DFI",
  },
  {
    label: "Del Rio, Del Rio Intl - United States Of America (DRT)",
    value: "DRT",
  },
  {
    label: "Del Rio, Laughlin Afb - United States Of America (DLF)",
    value: "DLF",
  },
  {
    label: "Delta, Municipal - United States Of America (DTA)",
    value: "DTA",
  },
  {
    label:
      "Delta Junction, Delta Junction Airport - United States Of America (DJN)",
    value: "DJN",
  },
  {
    label: "Deming, Municipal - United States Of America (DMN)",
    value: "DMN",
  },
  {
    label: "Denison, Denison Mnpl - United States Of America (DNS)",
    value: "DNS",
  },
  {
    label: "Denver, Aurora Buckley Afb - United States Of America (BFK)",
    value: "BFK",
  },
  {
    label: "Denver, Centennial - United States Of America (APA)",
    value: "APA",
  },
  {
    label: "Denver, Rocky Mountain Metrop. - United States Of America (BJC)",
    value: "BJC",
  },
  {
    label: "Des Moines, International - United States Of America (DSM)",
    value: "DSM",
  },
  {
    label: "Detroit, MI - United States Of America (DTT)",
    value: "DTT",
  },
  {
    label: "Detroit, Coleman A Young Muni - United States Of America (DET)",
    value: "DET",
  },
  {
    label: "Detroit, Metropolitan Wayne Co - United States Of America (DTW)",
    value: "DTW",
  },
  {
    label: "Detroit, Willow Run - United States Of America (YIP)",
    value: "YIP",
  },
  {
    label: "Detroit Lakes, MN - United States Of America (DTL)",
    value: "DTL",
  },
  {
    label: "Detroit Lakes, Wething Field - United States Of America (DTL)",
    value: "DTL",
  },
  {
    label: "Devils Lake, Regional - United States Of America (DVL)",
    value: "DVL",
  },
  {
    label: "Dickinson, T.Roosevelt Regional - United States Of America (DIK)",
    value: "DIK",
  },
  {
    label: "Dillingham, Dillingham - United States Of America (DLG)",
    value: "DLG",
  },
  {
    label: "Dillon, Dillon - United States Of America (DLN)",
    value: "DLN",
  },
  {
    label: "Dillon, Dillon County - United States Of America (DLL)",
    value: "DLL",
  },
  {
    label: "Diomede Island, Heliport - United States Of America (DIO)",
    value: "DIO",
  },
  {
    label: "Dodge City, Regional - United States Of America (DDC)",
    value: "DDC",
  },
  {
    label: "Dolomi, Dolomi - United States Of America (DLO)",
    value: "DLO",
  },
  {
    label: "Dora Bay, Dora Bay - United States Of America (DOF)",
    value: "DOF",
  },
  {
    label: "Dothan, Regional - United States Of America (DHN)",
    value: "DHN",
  },
  {
    label: "Douglas, Bisbee Douglas Intl - United States Of America (DUG)",
    value: "DUG",
  },
  {
    label: "Douglas, Bisbee Municipal - United States Of America (BSQ)",
    value: "BSQ",
  },
  {
    label: "Douglas, Converse County - United States Of America (DGW)",
    value: "DGW",
  },
  {
    label: "Douglas, Douglas Mnpl - United States Of America (DGL)",
    value: "DGL",
  },
  {
    label: "Dover Cheswold, Dover Afb - United States Of America (DOV)",
    value: "DOV",
  },
  {
    label: "Downey, Heliport - United States Of America (JDY)",
    value: "JDY",
  },
  {
    label: "Doylestown, Doylestown - United States Of America (DYL)",
    value: "DYL",
  },
  {
    label: "Drift River, Drift River - United States Of America (DRF)",
    value: "DRF",
  },
  {
    label: "Drummond, Drummond - United States Of America (DRU)",
    value: "DRU",
  },
  {
    label: "Drummond Island, Drummond Island - United States Of America (DRE)",
    value: "DRE",
  },
  {
    label: "Dublin, New River Valley - United States Of America (PSK)",
    value: "PSK",
  },
  {
    label: 'Dublin, W.H. "Bud" Barron - United States Of America (DBN)',
    value: "DBN",
  },
  {
    label: "Dubois, Dubois Municipal - United States Of America (DBS)",
    value: "DBS",
  },
  {
    label: "Dubois, Regional - United States Of America (DUJ)",
    value: "DUJ",
  },
  {
    label: "Dubuque, Regional - United States Of America (DBQ)",
    value: "DBQ",
  },
  {
    label: "Dugway, Michael Aaf - United States Of America (DPG)",
    value: "DPG",
  },
  {
    label: "Duke, Pine Island - United States Of America (DUF)",
    value: "DUF",
  },
  {
    label: "Duluth, Duluth Intl - United States Of America (DLH)",
    value: "DLH",
  },
  {
    label: "Duluth, Lakeside Usaf - United States Of America (LKI)",
    value: "LKI",
  },
  {
    label: "Duluth, Richard I Bong - United States Of America (SUW)",
    value: "SUW",
  },
  {
    label: "Duncan, Halliburton Field - United States Of America (DUC)",
    value: "DUC",
  },
  {
    label: "Dunkirk, Chautauqua County - United States Of America (DKK)",
    value: "DKK",
  },
  {
    label: "Durango, Animas Air Park - United States Of America (AMK)",
    value: "AMK",
  },
  {
    label: "Durango, La Plata County - United States Of America (DRO)",
    value: "DRO",
  },
  {
    label: "Durant, Regional Eaker Field - United States Of America (DUA)",
    value: "DUA",
  },
  {
    label: "Dutch Harbor, Driftwood Bay Afs - United States Of America (DUT)",
    value: "DUT",
  },
  {
    label: "Eagle, Eagle - United States Of America (EAA)",
    value: "EAA",
  },
  {
    label: "Eagle Lake, Eagle Lake - United States Of America (ELA)",
    value: "ELA",
  },
  {
    label: "Eagle Pass, Maverick Co Mem Intl - United States Of America (EGP)",
    value: "EGP",
  },
  {
    label: "Eagle River, Union - United States Of America (EGV)",
    value: "EGV",
  },
  {
    label: "East Fork, East Fork - United States Of America (EFO)",
    value: "EFO",
  },
  {
    label: "East Hampton, East Hampton - United States Of America (HTO)",
    value: "HTO",
  },
  {
    label: "East Hartford, Rentschler - United States Of America (EHT)",
    value: "EHT",
  },
  {
    label:
      "East Stroudsburg, Stroudsburg Pocono - United States Of America (ESP)",
    value: "ESP",
  },
  {
    label: "East Tawas, Iosco County - United States Of America (ECA)",
    value: "ECA",
  },
  {
    label: "Eastland, Eastland Mnpl - United States Of America (ETN)",
    value: "ETN",
  },
  {
    label: "Easton, Newnam Field - United States Of America (ESN)",
    value: "ESN",
  },
  {
    label: "Easton, State - United States Of America (ESW)",
    value: "ESW",
  },
  {
    label: "Eastsound, Orcas Island - United States Of America (ESD)",
    value: "ESD",
  },
  {
    label: "Eau Claire, Chippewa Valley Rgnl - United States Of America (EAU)",
    value: "EAU",
  },
  {
    label: "Edenton, Northeastern Regional - United States Of America (EDE)",
    value: "EDE",
  },
  {
    label: "Edgewood, Weide Ahp - United States Of America (EDG)",
    value: "EDG",
  },
  {
    label: "Edna Bay, Sea Plane Base - United States Of America (EDA)",
    value: "EDA",
  },
  {
    label: "Edwards Afb, Edwards Afb - United States Of America (EDW)",
    value: "EDW",
  },
  {
    label: "Eek, Eek - United States Of America (EEK)",
    value: "EEK",
  },
  {
    label: "Egegik, Egegik - United States Of America (EGX)",
    value: "EGX",
  },
  {
    label:
      "Eight Fathom Bigh, Eight Fathom Bigh - United States Of America (EFB)",
    value: "EFB",
  },
  {
    label: "Ekuk, Ekuk - United States Of America (KKU)",
    value: "KKU",
  },
  {
    label: "Ekwok, Ekwok - United States Of America (KEK)",
    value: "KEK",
  },
  {
    label: "El Cajon, El Cajon - United States Of America (CJN)",
    value: "CJN",
  },
  {
    label: "El Centro, El Centro Naf - United States Of America (NJK)",
    value: "NJK",
  },
  {
    label: "El Centro, Imperial County - United States Of America (IPL)",
    value: "IPL",
  },
  {
    label: "El Dorado, Capt Jack Thomas Mem - United States Of America (EDK)",
    value: "EDK",
  },
  {
    label: "El Dorado, South Arkansas Rgnl - United States Of America (ELD)",
    value: "ELD",
  },
  {
    label: "El Monte, San Gabriel Valley - United States Of America (EMT)",
    value: "EMT",
  },
  {
    label: "El Paso, Biggs Aaf - United States Of America (BIF)",
    value: "BIF",
  },
  {
    label: "El Paso, El Paso Intl - United States Of America (ELP)",
    value: "ELP",
  },
  {
    label: "Eldred Rock, Coast Guard Heliport - United States Of America (ERO)",
    value: "ERO",
  },
  {
    label: "Electric City, Grand Coulee Dam - United States Of America (GCD)",
    value: "GCD",
  },
  {
    label: "Elfin Cove, Elfin Cove S - United States Of America (ELV)",
    value: "ELV",
  },
  {
    label: "Elim, Elim - United States Of America (ELI)",
    value: "ELI",
  },
  {
    label: "Elizabeth City, Regional - United States Of America (ECG)",
    value: "ECG",
  },
  {
    label:
      "Elizabethtown, Regional-Addington Fld - United States Of America (EKX)",
    value: "EKX",
  },
  {
    label: "Elk City, Regional Business - United States Of America (ELK)",
    value: "ELK",
  },
  {
    label: "Elkhart, Elkhart Mnpl - United States Of America (EKI)",
    value: "EKI",
  },
  {
    label: "Elkins, Randolph County - United States Of America (EKN)",
    value: "EKN",
  },
  {
    label: "Elko, Regional - United States Of America (EKO)",
    value: "EKO",
  },
  {
    label: "Ellamar, Sea Plane Base - United States Of America (ELW)",
    value: "ELW",
  },
  {
    label: "Ellensburg, Bowers Field - United States Of America (ELN)",
    value: "ELN",
  },
  {
    label: "Elmira, Regional - United States Of America (ELM)",
    value: "ELM",
  },
  {
    label: "Ely, Municipal - United States Of America (LYU)",
    value: "LYU",
  },
  {
    label: "Ely, Yelland Field - United States Of America (ELY)",
    value: "ELY",
  },
  {
    label: "Emeryville, Heliport - United States Of America (JEM)",
    value: "JEM",
  },
  {
    label: "Emmonak, Emmonak - United States Of America (EMK)",
    value: "EMK",
  },
  {
    label: "Emporia, Municipal - United States Of America (EMP)",
    value: "EMP",
  },
  {
    label: "Enid, Vance Afb - United States Of America (END)",
    value: "END",
  },
  {
    label: "Enid, Woodring Regional - United States Of America (WDG)",
    value: "WDG",
  },
  {
    label: "Enterprise, Enterprise Mnpl - United States Of America (ETS)",
    value: "ETS",
  },
  {
    label: "Ephrata, Municipal - United States Of America (EPH)",
    value: "EPH",
  },
  {
    label: "Erie, Erie Intl - United States Of America (ERI)",
    value: "ERI",
  },
  {
    label: "Errol, Errol - United States Of America (ERR)",
    value: "ERR",
  },
  {
    label: "Escanaba, Delta County - United States Of America (ESC)",
    value: "ESC",
  },
  {
    label: "Espanola, Okhay Owingeh - United States Of America (ESO)",
    value: "ESO",
  },
  {
    label: "Estherville, Estherville Mnpl - United States Of America (EST)",
    value: "EST",
  },
  {
    label: "Eufaula, Weedon Field - United States Of America (EUF)",
    value: "EUF",
  },
  {
    label: "Eugene, Mahlon Sweet Field - United States Of America (EUG)",
    value: "EUG",
  },
  {
    label: "Eunice, Eunice - United States Of America (UCE)",
    value: "UCE",
  },
  {
    label: "Eureka, Eureka - United States Of America (EUE)",
    value: "EUE",
  },
  {
    label: "Evadale, Ben Bruce Mem Airpark - United States Of America (EVA)",
    value: "EVA",
  },
  {
    label: "Evanston, Uinta County - United States Of America (EVW)",
    value: "EVW",
  },
  {
    label: "Evansville, Regional - United States Of America (EVV)",
    value: "EVV",
  },
  {
    label: "Eveleth, Eveleth-Virginia Muni - United States Of America (EVM)",
    value: "EVM",
  },
  {
    label: "Everett, Snohomish County - United States Of America (PAE)",
    value: "PAE",
  },
  {
    label: "Excursion Inlet, Spb - United States Of America (EXI)",
    value: "EXI",
  },
  {
    label: "Fairbanks, Eielson Afb - United States Of America (EIL)",
    value: "EIL",
  },
  {
    label: "Fairbanks, Fairbanks Intl - United States Of America (FAI)",
    value: "FAI",
  },
  {
    label: "Fairbanks, Ladd Aaf - United States Of America (FBK)",
    value: "FBK",
  },
  {
    label: "Fairbanks, Metro Field - United States Of America (MTX)",
    value: "MTX",
  },
  {
    label: "Fairbanks, Phillips Fld - United States Of America (PII)",
    value: "PII",
  },
  {
    label: "Fairbury, Fairbury Mnpl - United States Of America (FBY)",
    value: "FBY",
  },
  {
    label: "Fairfield, Fairfield Mnpl - United States Of America (FFL)",
    value: "FFL",
  },
  {
    label: "Fairfield, Travis Afb - United States Of America (SUU)",
    value: "SUU",
  },
  {
    label: "Fairmont, Municipal - United States Of America (FRM)",
    value: "FRM",
  },
  {
    label: "Fallon, Fallon Mnpl - United States Of America (FLX)",
    value: "FLX",
  },
  {
    label: "Fallon, Nas Van Voorhis Field - United States Of America (NFL)",
    value: "NFL",
  },
  {
    label: "Falls Bay, Falls Bay - United States Of America (FLJ)",
    value: "FLJ",
  },
  {
    label: "Falmouth, Cape Cod Coast Guard - United States Of America (FMH)",
    value: "FMH",
  },
  {
    label: "False Island, Sea Plane Base - United States Of America (FAK)",
    value: "FAK",
  },
  {
    label: "False Pass, False Pass - United States Of America (KFP)",
    value: "KFP",
  },
  {
    label: "Farewell, Farewell - United States Of America (FWL)",
    value: "FWL",
  },
  {
    label: "Fargo, Hector Intl - United States Of America (FAR)",
    value: "FAR",
  },
  {
    label: "Faribault, Faribault Mnpl - United States Of America (FBL)",
    value: "FBL",
  },
  {
    label: "Farmingdale, Republic - United States Of America (FRG)",
    value: "FRG",
  },
  {
    label: "Farmington, Four Corners Regional - United States Of America (FMN)",
    value: "FMN",
  },
  {
    label: "Farmington, Regional - United States Of America (FAM)",
    value: "FAM",
  },
  {
    label: "Fayetteville, Drake Field - United States Of America (FYV)",
    value: "FYV",
  },
  {
    label: "Fayetteville, Fayetteville Mnpl - United States Of America (FYM)",
    value: "FYM",
  },
  {
    label: "Fayetteville, Nw Arkansas Rgn - United States Of America (XNA)",
    value: "XNA",
  },
  {
    label:
      "Fayetteville, Regional  Grannis Fld) - United States Of America (FAY)",
    value: "FAY",
  },
  {
    label:
      "Fayetteville, Springdale Municipal - United States Of America (SPZ)",
    value: "SPZ",
  },
  {
    label: "Fergus Falls, Municipal - United States Of America (FFM)",
    value: "FFM",
  },
  {
    label:
      "Fictitious Point, Fictitious Point - United States Of America (QXY)",
    value: "QXY",
  },
  {
    label:
      "Fictitious Point, Fictitious Point - United States Of America (QXX)",
    value: "QXX",
  },
  {
    label: "Fillmore, Fillmore Mnpl - United States Of America (FIL)",
    value: "FIL",
  },
  {
    label: "Fin Creek, Fin Creek - United States Of America (FNK)",
    value: "FNK",
  },
  {
    label: "Findlay, Findlay - United States Of America (FDY)",
    value: "FDY",
  },
  {
    label: "Fire Cove, Fire Cove - United States Of America (FIC)",
    value: "FIC",
  },
  {
    label: "Fishers Island, Elizabet - United States Of America (FID)",
    value: "FID",
  },
  {
    label: "Five Finger, Coast Guard Heliport - United States Of America (FIV)",
    value: "FIV",
  },
  {
    label: "Five Mile, Five Mile - United States Of America (FMC)",
    value: "FMC",
  },
  {
    label: "Flat, Flat - United States Of America (FLT)",
    value: "FLT",
  },
  {
    label: "Flaxman Island, Flaxman Island - United States Of America (FXM)",
    value: "FXM",
  },
  {
    label: "Flint, Bishop Intl - United States Of America (FNT)",
    value: "FNT",
  },
  {
    label: "Flippin, Marion County Rgnl - United States Of America (FLP)",
    value: "FLP",
  },
  {
    label: "Florence, Municipal - United States Of America (FMU)",
    value: "FMU",
  },
  {
    label: "Florence, Regional - United States Of America (FLO)",
    value: "FLO",
  },
  {
    label: "Foley, Barin Nolf - United States Of America (NHX)",
    value: "NHX",
  },
  {
    label: "Fond Du Lac, Fond Du Lac County - United States Of America (FLD)",
    value: "FLD",
  },
  {
    label: "Forest City, Forest City Mnpl - United States Of America (FXY)",
    value: "FXY",
  },
  {
    label: "Forest Park, Morris Aaf - United States Of America (FOP)",
    value: "FOP",
  },
  {
    label: "Forrest City, Forrest City Mnpl - United States Of America (FCY)",
    value: "FCY",
  },
  {
    label: "Fort Belvoir, Davison Aaf - United States Of America (DAA)",
    value: "DAA",
  },
  {
    label: "Fort Bragg, Fort Bragg - United States Of America (FOB)",
    value: "FOB",
  },
  {
    label: "Fort Bragg, Pope Field - United States Of America (POB)",
    value: "POB",
  },
  {
    label: "Fort Bragg, Simmons Aaf - United States Of America (FBG)",
    value: "FBG",
  },
  {
    label: "Fort Bridger, Fort Bridger - United States Of America (FBR)",
    value: "FBR",
  },
  {
    label: "Fort Chaffee, Chaffee Afb - United States Of America (CCA)",
    value: "CCA",
  },
  {
    label:
      "Fort Collins Loveland, Fort Collins Bus Stn - United States Of America (QWF)",
    value: "QWF",
  },
  {
    label: "Fort Collins Loveland, Mncpl - United States Of America (FNL)",
    value: "FNL",
  },
  {
    label: "Fort Devens, Aaf Hpt - United States Of America (AYE)",
    value: "AYE",
  },
  {
    label: "Fort Dix, Mc Guire Afb - United States Of America (WRI)",
    value: "WRI",
  },
  {
    label: "Fort Dodge, Regional - United States Of America (FOD)",
    value: "FOD",
  },
  {
    label: "Fort Eustis, Felker Aaf - United States Of America (FAF)",
    value: "FAF",
  },
  {
    label: "Fort Huachuca, Libby Aaf - United States Of America (FHU)",
    value: "FHU",
  },
  {
    label: "Fort Indiantown, Muir Aaf - United States Of America (MUI)",
    value: "MUI",
  },
  {
    label: "Fort Irwin, Bicycle Lake Aaf - United States Of America (BYS)",
    value: "BYS",
  },
  {
    label: "Fort Jefferson, Fort Jefferson - United States Of America (RBN)",
    value: "RBN",
  },
  {
    label: "Fort Knox, Godman Aaf - United States Of America (FTK)",
    value: "FTK",
  },
  {
    label: "Fort Lauderdale, Executive - United States Of America (FXE)",
    value: "FXE",
  },
  {
    label: "Fort Lauderdale, Fll Intl - United States Of America (FLL)",
    value: "FLL",
  },
  {
    label: "Fort Lauderdale, Tri-Rail Station - United States Of America (ZFT)",
    value: "ZFT",
  },
  {
    label: "Fort Leavenworth, Sherman - United States Of America (FLV)",
    value: "FLV",
  },
  {
    label:
      "Fort Leonard Wood, Waynesvl St Robert Rgn - United States Of America (TBN)",
    value: "TBN",
  },
  {
    label: "Fort Madison, Fort Madison Mnpl - United States Of America (FMS)",
    value: "FMS",
  },
  {
    label: "Fort Meade, Tipton - United States Of America (FME)",
    value: "FME",
  },
  {
    label: "Florida, United States Of America (FMY)",
    value: "FMY",
  },
  {
    label: "Florida, Fort Myers, Page Field - United States Of America (FMY)",
    value: "FMY",
  },
  {
    label:
      "Florida, Fort Myers, Southwest Florida Intl - United States Of America (RSW)",
    value: "RSW",
  },
  {
    label:
      "Fort Pierce, Saint Lucie County Intl - United States Of America (FPR)",
    value: "FPR",
  },
  {
    label: "Fort Polk, Polk Aaf - United States Of America (POE)",
    value: "POE",
  },
  {
    label: "Fort Richardson, Bryant - United States Of America (FRN)",
    value: "FRN",
  },
  {
    label: "Fort Riley, Marshall Aaf - United States Of America (FRI)",
    value: "FRI",
  },
  {
    label: "Fort Scott, Fort Scott Mnpl - United States Of America (FSK)",
    value: "FSK",
  },
  {
    label: "Fort Sheridan, Haley Aaf - United States Of America (FSN)",
    value: "FSN",
  },
  {
    label: "Fort Smith, Regional - United States Of America (FSM)",
    value: "FSM",
  },
  {
    label: "Fort Stockton, Pecos Cnty - United States Of America (FST)",
    value: "FST",
  },
  {
    label: "Fort Sumner, Municipal - United States Of America (FSU)",
    value: "FSU",
  },
  {
    label: "Fort Wayne, International - United States Of America (FWA)",
    value: "FWA",
  },
  {
    label: "Fort Wayne, Smith Field - United States Of America (SMD)",
    value: "SMD",
  },
  {
    label: "Fort Yukon, Fort Yukon - United States Of America (FYU)",
    value: "FYU",
  },
  {
    label: "Fortuna Ledge, Fortuna Ledge - United States Of America (FTL)",
    value: "FTL",
  },
  {
    label: "Fox, Fox - United States Of America (FOX)",
    value: "FOX",
  },
  {
    label: "Frankfort, Capital City - United States Of America (FFT)",
    value: "FFT",
  },
  {
    label: "Franklin, Municipal-John B. Rose - United States Of America (FKN)",
    value: "FKN",
  },
  {
    label: "Franklin, Venango Regional - United States Of America (FKL)",
    value: "FKL",
  },
  {
    label: "Frederick, Frederick Mnpl - United States Of America (FDK)",
    value: "FDK",
  },
  {
    label: "Frederick, Regional - United States Of America (FDR)",
    value: "FDR",
  },
  {
    label: "Freeport, Albertus - United States Of America (FEP)",
    value: "FEP",
  },
  {
    label: "Fremont, Fremont Mnpl - United States Of America (FET)",
    value: "FET",
  },
  {
    label: "French Lick, French Lick Mnpl - United States Of America (FRH)",
    value: "FRH",
  },
  {
    label:
      "Frenchville, Northern Aroostook Rgl - United States Of America (WFK)",
    value: "WFK",
  },
  {
    label: "Fresh Water Bay, Fresh Water Bay - United States Of America (FRP)",
    value: "FRP",
  },
  {
    label: "Fresno, Chandler Executive - United States Of America (FCH)",
    value: "FCH",
  },
  {
    label: "Fresno, Fresno Yosemite Intl - United States Of America (FAT)",
    value: "FAT",
  },
  {
    label: "Friday Harbor, Airport - United States Of America (FRD)",
    value: "FRD",
  },
  {
    label: "Friday Harbor, Sea Plane Base - United States Of America (FBS)",
    value: "FBS",
  },
  {
    label: "Front Royal, Warren Cnty - United States Of America (FRR)",
    value: "FRR",
  },
  {
    label: "Fryeburg, Eastern Slopes Rgnl - United States Of America (FRY)",
    value: "FRY",
  },
  {
    label: "Fullerton, Fullerton Mnpl - United States Of America (FUL)",
    value: "FUL",
  },
  {
    label: "Funter Bay, Spb - United States Of America (FNR)",
    value: "FNR",
  },
  {
    label: "Gabbs, Gabbs - United States Of America (GAB)",
    value: "GAB",
  },
  {
    label: "Gadsden, Northeast Alabama Rgnl - United States Of America (GAD)",
    value: "GAD",
  },
  {
    label: "Gage, Gage - United States Of America (GAG)",
    value: "GAG",
  },
  {
    label: "Gainesville, Gainesville Mnpl - United States Of America (GLE)",
    value: "GLE",
  },
  {
    label: "Gainesville, Gainesville Regional - United States Of America (GNV)",
    value: "GNV",
  },
  {
    label: "Gainesville, Lee Gilmer - United States Of America (GVL)",
    value: "GVL",
  },
  {
    label:
      "Gaithersburg, Montgomery Co Airpark - United States Of America (GAI)",
    value: "GAI",
  },
  {
    label: "Gakona, Gakona - United States Of America (GAK)",
    value: "GAK",
  },
  {
    label: "Galbraith Lake, Galbraith Lake - United States Of America (GBH)",
    value: "GBH",
  },
  {
    label: "Galena, Edward G Pikta Sr - United States Of America (GAL)",
    value: "GAL",
  },
  {
    label: "Galesburg, Municipal - United States Of America (GBG)",
    value: "GBG",
  },
  {
    label: "Galion, Municipal - United States Of America (GQQ)",
    value: "GQQ",
  },
  {
    label: "Gallup, Municipal - United States Of America (GUP)",
    value: "GUP",
  },
  {
    label: "Galveston, Scholes Intl - United States Of America (GLS)",
    value: "GLS",
  },
  {
    label: "Gambell, Gambell - United States Of America (GAM)",
    value: "GAM",
  },
  {
    label: "Ganes Creek, Ganes Creek - United States Of America (GEK)",
    value: "GEK",
  },
  {
    label: "Garden City, Island Hpt - United States Of America (JHC)",
    value: "JHC",
  },
  {
    label: "Garden City, Regional - United States Of America (GCK)",
    value: "GCK",
  },
  {
    label: "Gardner, Gardner Mnpl - United States Of America (GDM)",
    value: "GDM",
  },
  {
    label: "Gatlinburg, Gatlinburg - United States Of America (GKT)",
    value: "GKT",
  },
  {
    label: "Gaylord, Regional - United States Of America (GLR)",
    value: "GLR",
  },
  {
    label: "Georgetown, Georgetown County - United States Of America (GGE)",
    value: "GGE",
  },
  {
    label: "Georgetown, Sussex County - United States Of America (GED)",
    value: "GED",
  },
  {
    label: "Gettysburg, Regional - United States Of America (GTY)",
    value: "GTY",
  },
  {
    label: "Gillette, Campbell County - United States Of America (GCC)",
    value: "GCC",
  },
  {
    label: "Glacier Creek, Glacier Creek - United States Of America (KGZ)",
    value: "KGZ",
  },
  {
    label: "Gladwin, Zettel Memorial - United States Of America (GDW)",
    value: "GDW",
  },
  {
    label: "Glasgow, Glasgow Intl - United States Of America (GGW)",
    value: "GGW",
  },
  {
    label: "Glasgow, Glasgow Mnpl - United States Of America (GLW)",
    value: "GLW",
  },
  {
    label: "Glendale, Fokker Field - United States Of America (GWV)",
    value: "GWV",
  },
  {
    label: "Glendale, Heliport - United States Of America (JGX)",
    value: "JGX",
  },
  {
    label: "Glendive, Dawson Communi - United States Of America (GDV)",
    value: "GDV",
  },
  {
    label: "Glennallen, Glennallen - United States Of America (GLQ)",
    value: "GLQ",
  },
  {
    label:
      "Glens Falls, Floyd Bennett Memorial - United States Of America (GFL)",
    value: "GFL",
  },
  {
    label: "Glenview, Nas - United States Of America (NBU)",
    value: "NBU",
  },
  {
    label:
      "Glenwood Springs, Glenwood Springs Mnpl - United States Of America (GWS)",
    value: "GWS",
  },
  {
    label: "Globe, San Carlos Apache - United States Of America (GLB)",
    value: "GLB",
  },
  {
    label: "Glynco, Nas - United States Of America (NEA)",
    value: "NEA",
  },
  {
    label: "Gold Beach, Municipal - United States Of America (GOL)",
    value: "GOL",
  },
  {
    label:
      "Golden Horn, Golden Horn Lodge Spb - United States Of America (GDH)",
    value: "GDH",
  },
  {
    label: "Goldsboro, Seymour Johns - United States Of America (GSB)",
    value: "GSB",
  },
  {
    label: "Golovin, Golovin - United States Of America (GLV)",
    value: "GLV",
  },
  {
    label: "Gooding, Gooding Municipal - United States Of America (GNG)",
    value: "GNG",
  },
  {
    label: "Goodland, Renner Field - United States Of America (GLD)",
    value: "GLD",
  },
  {
    label: "Goodnews Bay, Goodnews Bay - United States Of America (GNU)",
    value: "GNU",
  },
  {
    label: "Goodyear, Phoenix Goodyear - United States Of America (GYR)",
    value: "GYR",
  },
  {
    label: "Gordon, Municipal - United States Of America (GRN)",
    value: "GRN",
  },
  {
    label: "Gordonsville, Gordonsville Mnpl - United States Of America (GVE)",
    value: "GVE",
  },
  {
    label: "Goshen, Municipal - United States Of America (GSH)",
    value: "GSH",
  },
  {
    label: "Grand Canyon, Flagstaff Pulliam - United States Of America (FLG)",
    value: "FLG",
  },
  {
    label: "Grand Canyon, Heliport - United States Of America (JGC)",
    value: "JGC",
  },
  {
    label: "Grand Canyon, National - United States Of America (GCN)",
    value: "GCN",
  },
  {
    label: "Grand Canyon, North Rim - United States Of America (NGC)",
    value: "NGC",
  },
  {
    label:
      "Grand Canyon West, Grand Canyon West - United States Of America (GCW)",
    value: "GCW",
  },
  {
    label: "Grand Forks, Afb - United States Of America (RDR)",
    value: "RDR",
  },
  {
    label: "Grand Forks, International - United States Of America (GFK)",
    value: "GFK",
  },
  {
    label:
      "Grand Island, Central Nebraska Rgnl - United States Of America (GRI)",
    value: "GRI",
  },
  {
    label: "Grand Junction, Regional - United States Of America (GJT)",
    value: "GJT",
  },
  {
    label: "Grand Marais, Cook County - United States Of America (GRM)",
    value: "GRM",
  },
  {
    label: "Grand Rapids, Gerald R Ford Intl - United States Of America (GRR)",
    value: "GRR",
  },
  {
    label: "Grand Rapids, Itasca County - United States Of America (GPZ)",
    value: "GPZ",
  },
  {
    label: "Grandview, Richards Gebau - United States Of America (GVW)",
    value: "GVW",
  },
  {
    label: "Grangeville, Idaho County - United States Of America (IDH)",
    value: "IDH",
  },
  {
    label: "Granite Mountain, Air Station - United States Of America (GMT)",
    value: "GMT",
  },
  {
    label: "Grants, Grants Milan Municipal - United States Of America (GNT)",
    value: "GNT",
  },
  {
    label: "Grants Pass, Grants Pass - United States Of America (GTP)",
    value: "GTP",
  },
  {
    label: "Grantsburg, Grantsburg Mnpl - United States Of America (GTG)",
    value: "GTG",
  },
  {
    label: "Grayling, Grayling - United States Of America (KGX)",
    value: "KGX",
  },
  {
    label:
      "Great Barrington, Walter J Koladza - United States Of America (GBR)",
    value: "GBR",
  },
  {
    label: "Great Bend, Municipal - United States Of America (GBD)",
    value: "GBD",
  },
  {
    label: "Great Falls, Great Falls Intl - United States Of America (GTF)",
    value: "GTF",
  },
  {
    label:
      "Great Falls, Malmstrom Afb Heliport - United States Of America (GFA)",
    value: "GFA",
  },
  {
    label: "Greeley, Weld County - United States Of America (GXY)",
    value: "GXY",
  },
  {
    label: "Green Bay, Austin-Straubel Intl - United States Of America (GRB)",
    value: "GRB",
  },
  {
    label: "Green River, Municipal - United States Of America (RVR)",
    value: "RVR",
  },
  {
    label: "Greenfield, Pope Field - United States Of America (GFD)",
    value: "GFD",
  },
  {
    label:
      "Greensboro/High Point, Piedmont Triad Intl - United States Of America (GSO)",
    value: "GSO",
  },
  {
    label: "Greenville, Donaldson Field - United States Of America (GDC)",
    value: "GDC",
  },
  {
    label: "Greenville, Greene County Muni - United States Of America (GCY)",
    value: "GCY",
  },
  {
    label: "Greenville, Greenville - United States Of America (GRE)",
    value: "GRE",
  },
  {
    label: "Greenville, Greenville Downtown - United States Of America (GMU)",
    value: "GMU",
  },
  {
    label: "Greenville, International - United States Of America (GSP)",
    value: "GSP",
  },
  {
    label: "Greenville, Majors - United States Of America (GVT)",
    value: "GVT",
  },
  {
    label: "Greenville, Mid-Delta - United States Of America (GLH)",
    value: "GLH",
  },
  {
    label: "Greenville, Pitt Greenvi - United States Of America (PGV)",
    value: "PGV",
  },
  {
    label:
      "Greenville, Spartanb. Downtown Mem - United States Of America (SPA)",
    value: "SPA",
  },
  {
    label: "Greenwood, Greenwood County - United States Of America (GRD)",
    value: "GRD",
  },
  {
    label: "Greenwood, Leflore - United States Of America (GWO)",
    value: "GWO",
  },
  {
    label: "Greybull, South Big Horn - United States Of America (GEY)",
    value: "GEY",
  },
  {
    label: "Groton, Airport - United States Of America (GON)",
    value: "GON",
  },
  {
    label: "Gulf Shores, Jack Edwards - United States Of America (GUF)",
    value: "GUF",
  },
  {
    label: "Gulfport, Biloxi Regional - United States Of America (GPT)",
    value: "GPT",
  },
  {
    label: "Gulkana, Gulkana - United States Of America (GKN)",
    value: "GKN",
  },
  {
    label: "Gustavus, Bartlett Cove Sbp - United States Of America (BQV)",
    value: "BQV",
  },
  {
    label: "Gustavus, Gustavus Airport - United States Of America (GST)",
    value: "GST",
  },
  {
    label: "Guthrie, Guthrie Edmond Rgnl - United States Of America (GOK)",
    value: "GOK",
  },
  {
    label: "Guymon, Municipal - United States Of America (GUY)",
    value: "GUY",
  },
  {
    label: "Hagerstown, Regional - United States Of America (HGR)",
    value: "HGR",
  },
  {
    label: "Hailey, Sun Valley - United States Of America (SUN)",
    value: "SUN",
  },
  {
    label: "Haines, Haines - United States Of America (HNS)",
    value: "HNS",
  },
  {
    label: "Half Moon Bay, Half Moon Bay - United States Of America (HAF)",
    value: "HAF",
  },
  {
    label: "Hamilton, Butler County Regional - United States Of America (HAO)",
    value: "HAO",
  },
  {
    label: "Hamilton, Marion Co.Rankin Fite - United States Of America (HAB)",
    value: "HAB",
  },
  {
    label: "Hampton, Hampton Mnpl - United States Of America (HPT)",
    value: "HPT",
  },
  {
    label:
      "Hampton                 , Langley Afb - United States Of America (LFI)",
    value: "LFI",
  },
  {
    label: "Hana, Hana - United States Of America (HNM)",
    value: "HNM",
  },
  {
    label: "Hanalei, Princeville - United States Of America (HPV)",
    value: "HPV",
  },
  {
    label: "Hanapepe, Port Allen - United States Of America (PAK)",
    value: "PAK",
  },
  {
    label: "Hancock, Houghton County Mem - United States Of America (CMX)",
    value: "CMX",
  },
  {
    label: "Hanksville, Hanksville - United States Of America (HVE)",
    value: "HVE",
  },
  {
    label: "Hanna, Hanna - United States Of America (HNX)",
    value: "HNX",
  },
  {
    label: "Hanus Bay, Hanus Bay - United States Of America (HBC)",
    value: "HBC",
  },
  {
    label: "Harlingen, Valley Intl - United States Of America (HRL)",
    value: "HRL",
  },
  {
    label: "Harrisburg, Capital City - United States Of America (HAR)",
    value: "HAR",
  },
  {
    label: "Harrisburg, Harrisburg Intl - United States Of America (MDT)",
    value: "MDT",
  },
  {
    label: "Harrisburg, Raleigh - United States Of America (HSB)",
    value: "HSB",
  },
  {
    label: "Harrison, Boone County - United States Of America (HRO)",
    value: "HRO",
  },
  {
    label: "Hartford, Barnes - United States Of America (BNH)",
    value: "BNH",
  },
  {
    label: "Hartford, Bradley Intl - United States Of America (BDL)",
    value: "BDL",
  },
  {
    label: "Hartford, Brainard - United States Of America (HFD)",
    value: "HFD",
  },
  {
    label: "Hartsville, Regional - United States Of America (HVS)",
    value: "HVS",
  },
  {
    label: "Hastings, Municipal - United States Of America (HSI)",
    value: "HSI",
  },
  {
    label: "Hatteras, Billy Mitchell - United States Of America (HNC)",
    value: "HNC",
  },
  {
    label: "Hattiesburg, Bobby L Chai - United States Of America (HBG)",
    value: "HBG",
  },
  {
    label: "Havasupai, Lava Falls Heliport - United States Of America (HAE)",
    value: "HAE",
  },
  {
    label: "Havre, City County - United States Of America (HVR)",
    value: "HVR",
  },
  {
    label: "Hawk Inlet, Spb - United States Of America (HWI)",
    value: "HWI",
  },
  {
    label: "Hawthorne, Industrial - United States Of America (HTH)",
    value: "HTH",
  },
  {
    label: "Hawthorne, Municipal - United States Of America (HHR)",
    value: "HHR",
  },
  {
    label: "Haycock, Haycock - United States Of America (HAY)",
    value: "HAY",
  },
  {
    label: "Hayden, Yampa Valley - United States Of America (HDN)",
    value: "HDN",
  },
  {
    label: "Hays, Regional - United States Of America (HYS)",
    value: "HYS",
  },
  {
    label: "Hayward, Executive - United States Of America (HWD)",
    value: "HWD",
  },
  {
    label: "Hayward, Sawyer County - United States Of America (HYR)",
    value: "HYR",
  },
  {
    label: "Hazleton, Regional - United States Of America (HZL)",
    value: "HZL",
  },
  {
    label: "Healy Lake, Healy Lake - United States Of America (HKB)",
    value: "HKB",
  },
  {
    label: "Helena, Helena Regional - United States Of America (HLN)",
    value: "HLN",
  },
  {
    label: "Helena, Thompson Robbins - United States Of America (HEE)",
    value: "HEE",
  },
  {
    label: "Hemet, Ryan - United States Of America (HMT)",
    value: "HMT",
  },
  {
    label: "Herendeen, Herendeen Bay - United States Of America (HED)",
    value: "HED",
  },
  {
    label: "Herlong, Amedee Aaf - United States Of America (AHC)",
    value: "AHC",
  },
  {
    label: "Hermiston, Municipal - United States Of America (HES)",
    value: "HES",
  },
  {
    label: "Hibbing, Range Regional - United States Of America (HIB)",
    value: "HIB",
  },
  {
    label: "Hickory, Regional - United States Of America (HKY)",
    value: "HKY",
  },
  {
    label: "Hidden Falls, Hidden Falls - United States Of America (HDA)",
    value: "HDA",
  },
  {
    label: "Hill City, Municipal - United States Of America (HLC)",
    value: "HLC",
  },
  {
    label: "Hilo, Hilo International - United States Of America (ITO)",
    value: "ITO",
  },
  {
    label: "Hilton Head, Hilton Head - United States Of America (HHH)",
    value: "HHH",
  },
  {
    label: "Hinesville, Midcoast Regional - United States Of America (LIY)",
    value: "LIY",
  },
  {
    label: "Hobart, Regional - United States Of America (HBR)",
    value: "HBR",
  },
  {
    label: "Hobart Bay, Sea Plane Base - United States Of America (HBH)",
    value: "HBH",
  },
  {
    label: "Hobbs, Industrial Airpark - United States Of America (HBB)",
    value: "HBB",
  },
  {
    label: "Hobbs, Lea County Regional - United States Of America (HOB)",
    value: "HOB",
  },
  {
    label: "Hoffman, Mackall Aaf - United States Of America (HFF)",
    value: "HFF",
  },
  {
    label: "Hogatza, Hog River - United States Of America (HGZ)",
    value: "HGZ",
  },
  {
    label: "Holbrook, Municipal - United States Of America (HBK)",
    value: "HBK",
  },
  {
    label: "Holdrege, Brewster Field - United States Of America (HDE)",
    value: "HDE",
  },
  {
    label: "Holikachu, Holikachu - United States Of America (HOL)",
    value: "HOL",
  },
  {
    label: "Holland, Park Township - United States Of America (HLM)",
    value: "HLM",
  },
  {
    label: "Hollis, Clark Bay Spb - United States Of America (HYL)",
    value: "HYL",
  },
  {
    label: "Hollister, Municipal - United States Of America (HLI)",
    value: "HLI",
  },
  {
    label: "Hollywood, North Perry - United States Of America (HWO)",
    value: "HWO",
  },
  {
    label: "Holy Cross, Holy Cross - United States Of America (HCR)",
    value: "HCR",
  },
  {
    label: "Homer, Homer - United States Of America (HOM)",
    value: "HOM",
  },
  {
    label: "Homeshore, Homeshore - United States Of America (HMS)",
    value: "HMS",
  },
  {
    label: "Homestead, Arb - United States Of America (HST)",
    value: "HST",
  },
  {
    label: "Honolulu, United States Of America (HNL)",
    value: "HNL",
  },
  {
    label: "Honolulu, Honolulu Intl - United States Of America (HNL)",
    value: "HNL",
  },
  {
    label: "Honolulu, Hickam Afb - United States Of America (HIK)",
    value: "HIK",
  },
  {
    label: "Hoolehua, Molokai - United States Of America (MKK)",
    value: "MKK",
  },
  {
    label: "Hoonah, Hoonah - United States Of America (HNH)",
    value: "HNH",
  },
  {
    label: "Hooper Bay, Hooper Bay - United States Of America (HPB)",
    value: "HPB",
  },
  {
    label: "Hopkinsville, Campbell - United States Of America (HOP)",
    value: "HOP",
  },
  {
    label: "Hot Springs, Ingalls Fld - United States Of America (HSP)",
    value: "HSP",
  },
  {
    label: "Hot Springs, Memorial Fld - United States Of America (HOT)",
    value: "HOT",
  },
  {
    label: "Houghton, Roscommon County - United States Of America (HTL)",
    value: "HTL",
  },
  {
    label: "Houlton, Houlton Intl - United States Of America (HUL)",
    value: "HUL",
  },
  {
    label: "Houma, Terrebonne - United States Of America (HUM)",
    value: "HUM",
  },
  {
    label: "Houston, William P Hobby - United States Of America (HOU)",
    value: "HOU",
  },
  {
    label: "Houston, George Bush Intercont - United States Of America (IAH)",
    value: "IAH",
  },
  {
    label: "Houston, William P Hobby - United States Of America (HOU)",
    value: "HOU",
  },
  {
    label: "Houston, Andrau Airpark - United States Of America (AAP)",
    value: "AAP",
  },
  {
    label: "Houston, David Wayne Hooks Mem - United States Of America (DWH)",
    value: "DWH",
  },
  {
    label: "Houston, Ellington - United States Of America (EFD)",
    value: "EFD",
  },
  {
    label: "Houston, Spaceland - United States Of America (SPX)",
    value: "SPX",
  },
  {
    label: "Houston, West Houston - United States Of America (IWS)",
    value: "IWS",
  },
  {
    label: "Hudson, Columbia County - United States Of America (HCC)",
    value: "HCC",
  },
  {
    label: "Hughes, Hughes - United States Of America (HUS)",
    value: "HUS",
  },
  {
    label: "Hugo, Stan Stamper Municipal - United States Of America (HUJ)",
    value: "HUJ",
  },
  {
    label: "Humboldt, Humboldt Mnpl - United States Of America (HBO)",
    value: "HBO",
  },
  {
    label: "Humboldt, Municipal - United States Of America (HUD)",
    value: "HUD",
  },
  {
    label: "Huntingburg, Huntingburg - United States Of America (HNB)",
    value: "HNB",
  },
  {
    label: "Huntington, Lawrence Co Airpark - United States Of America (HTW)",
    value: "HTW",
  },
  {
    label: "Huntington, Tri-State - United States Of America (HTS)",
    value: "HTS",
  },
  {
    label: "Huntsville, Bruce Brothers Rgnl - United States Of America (HTV)",
    value: "HTV",
  },
  {
    label: "Huntsville, Intl-Carl T.Jones Fld - United States Of America (HSV)",
    value: "HSV",
  },
  {
    label: "Huntsville, Redstone Aaf - United States Of America (HUA)",
    value: "HUA",
  },
  {
    label: "Huron, Regional - United States Of America (HON)",
    value: "HON",
  },
  {
    label: "Huslia, Huslia - United States Of America (HSL)",
    value: "HSL",
  },
  {
    label: "Hutchinson, Municipal - United States Of America (HUT)",
    value: "HUT",
  },
  {
    label: "Hyannis, Barnstable Municipal - United States Of America (HYA)",
    value: "HYA",
  },
  {
    label: "Hydaburg, Spb - United States Of America (HYG)",
    value: "HYG",
  },
  {
    label: "Hyder, Spb - United States Of America (WHD)",
    value: "WHD",
  },
  {
    label: "Icy Bay, Icy Bay - United States Of America (ICY)",
    value: "ICY",
  },
  {
    label: "Ida Grove, Ida Grove Mnpl - United States Of America (IDG)",
    value: "IDG",
  },
  {
    label: "Idaho Falls, Idaho Falls Regional - United States Of America (IDA)",
    value: "IDA",
  },
  {
    label: "Igiugig, Igiugig - United States Of America (IGG)",
    value: "IGG",
  },
  {
    label: "Iliamna, Iliamna - United States Of America (ILI)",
    value: "ILI",
  },
  {
    label: "Immokalee, Regional - United States Of America (IMM)",
    value: "IMM",
  },
  {
    label: "Imperial, Municipal - United States Of America (IML)",
    value: "IML",
  },
  {
    label: "Imperial Beach, Nolf Ream Field - United States Of America (NRS)",
    value: "NRS",
  },
  {
    label: "Independence, Municipal - United States Of America (IDP)",
    value: "IDP",
  },
  {
    label: "Independence, Tri City - United States Of America (PPF)",
    value: "PPF",
  },
  {
    label: "Indian Springs, Creech Afb - United States Of America (INS)",
    value: "INS",
  },
  {
    label: "Indiana, Indiana County - United States Of America (IDI)",
    value: "IDI",
  },
  {
    label: "Indianapolis, Indianapolis Intl - United States Of America (IND)",
    value: "IND",
  },
  {
    label:
      "International Falls, International Falls - United States Of America (INL)",
    value: "INL",
  },
  {
    label: "Inyokern, Inyokern - United States Of America (IYK)",
    value: "IYK",
  },
  {
    label: "Iowa City, Municipal - United States Of America (IOW)",
    value: "IOW",
  },
  {
    label: "Iowa Falls, Municipal - United States Of America (IFA)",
    value: "IFA",
  },
  {
    label: "Iraan, Iraan Mnpl - United States Of America (IRB)",
    value: "IRB",
  },
  {
    label: "Iron Mountain, Ford - United States Of America (IMT)",
    value: "IMT",
  },
  {
    label: "Ironwood, Gogebic Iron County - United States Of America (IWD)",
    value: "IWD",
  },
  {
    label: "Isabel Pass, Isabel Pass - United States Of America (ISL)",
    value: "ISL",
  },
  {
    label: "Ithaca, Tompkins Regional - United States Of America (ITH)",
    value: "ITH",
  },
  {
    label: "Ivanof Bay, Spb - United States Of America (KIB)",
    value: "KIB",
  },
  {
    label: "Ivishak, Ivishak - United States Of America (IVH)",
    value: "IVH",
  },
  {
    label: "Jackpot, Hayden Field - United States Of America (KPT)",
    value: "KPT",
  },
  {
    label: "Jackson, Hawkins Field - United States Of America (HKS)",
    value: "HKS",
  },
  {
    label: "Jackson, Jackson County - United States Of America (JXN)",
    value: "JXN",
  },
  {
    label: "Jackson, Jackson Hole - United States Of America (JAC)",
    value: "JAC",
  },
  {
    label: "Jackson, Mckellar-Sipes Rgnl - United States Of America (MKL)",
    value: "MKL",
  },
  {
    label: "Jackson, Medgar W.Evers Intl - United States Of America (JAN)",
    value: "JAN",
  },
  {
    label: "Jackson, Municipal - United States Of America (MJQ)",
    value: "MJQ",
  },
  {
    label: "Jacksonville, A J Ellis - United States Of America (OAJ)",
    value: "OAJ",
  },
  {
    label: "Jacksonville, Cecil - United States Of America (VQQ)",
    value: "VQQ",
  },
  {
    label: "Jacksonville, Cherokee County - United States Of America (JKV)",
    value: "JKV",
  },
  {
    label: "Jacksonville, Executive  Craig) - United States Of America (CRG)",
    value: "CRG",
  },
  {
    label: "Jacksonville, Jacksonville Intl - United States Of America (JAX)",
    value: "JAX",
  },
  {
    label: "Jacksonville, Jacksonville Mnpl - United States Of America (IJX)",
    value: "IJX",
  },
  {
    label: "Jacksonville, Little Rock - United States Of America (LRF)",
    value: "LRF",
  },
  {
    label: "Jacksonville, Nas  Towers Field) - United States Of America (NIP)",
    value: "NIP",
  },
  {
    label: "Jacksonville, Whitehouse Nolf - United States Of America (NEN)",
    value: "NEN",
  },
  {
    label: "Jaffrey, Silver Ranch - United States Of America (AFN)",
    value: "AFN",
  },
  {
    label: "Jamestown, Chautauqua County - United States Of America (JHW)",
    value: "JHW",
  },
  {
    label: "Jamestown, Regional - United States Of America (JMS)",
    value: "JMS",
  },
  {
    label:
      "Janesville, Southern Wisconsin Rgn - United States Of America (JVL)",
    value: "JVL",
  },
  {
    label: "Jasper, Jasper County Bell Fld - United States Of America (JAS)",
    value: "JAS",
  },
  {
    label: "Jasper, Marion County - United States Of America (APT)",
    value: "APT",
  },
  {
    label: "Jefferson, Jefferson Mnpl - United States Of America (EFW)",
    value: "EFW",
  },
  {
    label: "Jefferson, Northeast Ohio Rgnl - United States Of America (JFN)",
    value: "JFN",
  },
  {
    label: "Jefferson City, Memorial - United States Of America (JEF)",
    value: "JEF",
  },
  {
    label: "John Day, Grant County Regional - United States Of America (JDA)",
    value: "JDA",
  },
  {
    label: "Johnson, Lbj Ranch - United States Of America (JCY)",
    value: "JCY",
  },
  {
    label: "Johnstown, John Murtha Cambria Co - United States Of America (JST)",
    value: "JST",
  },
  {
    label: "Joliet, Regional - United States Of America (JOT)",
    value: "JOT",
  },
  {
    label: "Jolon, Tusi Ahp - United States Of America (HGT)",
    value: "HGT",
  },
  {
    label: "Jonesboro, Municipal - United States Of America (JBR)",
    value: "JBR",
  },
  {
    label: "Joplin, Regional - United States Of America (JLN)",
    value: "JLN",
  },
  {
    label: "Jordan, Jordan - United States Of America (JDN)",
    value: "JDN",
  },
  {
    label: "Junction, Kimble County - United States Of America (JCT)",
    value: "JCT",
  },
  {
    label: "Juneau, Dodge County - United States Of America (UNU)",
    value: "UNU",
  },
  {
    label: "Juneau, Juneau Intl - United States Of America (JNU)",
    value: "JNU",
  },
  {
    label: "Kaanapali Maui, Kaanapali Maui - United States Of America (HKP)",
    value: "HKP",
  },
  {
    label: "Kagvik Creek, Kagvik Creek - United States Of America (KKF)",
    value: "KKF",
  },
  {
    label: "Kahului, Kahului - United States Of America (OGG)",
    value: "OGG",
  },
  {
    label:
      "Kaiser Lake Ozark, Lee C Fine Memorial - United States Of America (AIZ)",
    value: "AIZ",
  },
  {
    label: "Kake, Spb - United States Of America (KAE)",
    value: "KAE",
  },
  {
    label: "Kakhonak, Kokhanok - United States Of America (KNK)",
    value: "KNK",
  },
  {
    label: "Kalakaket, Air Station - United States Of America (KKK)",
    value: "KKK",
  },
  {
    label: "Kalamazoo, International - United States Of America (AZO)",
    value: "AZO",
  },
  {
    label: "Kalaupapa, Kalaupapa - United States Of America (LUP)",
    value: "LUP",
  },
  {
    label: "Kalispell, Glacier Park Intl - United States Of America (FCA)",
    value: "FCA",
  },
  {
    label: "Kalskag, Kalskag - United States Of America (KLG)",
    value: "KLG",
  },
  {
    label: "Kaltag, Kaltag - United States Of America (KAL)",
    value: "KAL",
  },
  {
    label: "Kamuela, Waimea Kohala - United States Of America (MUE)",
    value: "MUE",
  },
  {
    label: "Kanab, Minicipal - United States Of America (KNB)",
    value: "KNB",
  },
  {
    label: "Kaneohe Bay, Kaneohe Bay Mcas - United States Of America (NGF)",
    value: "NGF",
  },
  {
    label: "Kankakee, Great Kankakee - United States Of America (IKK)",
    value: "IKK",
  },
  {
    label: "Kansas City, MO - United States Of America (MKC)",
    value: "MKC",
  },
  {
    label: "Kansas City, Cb Wheeler Downtown - United States Of America (MKC)",
    value: "MKC",
  },
  {
    label: "Kansas City, Johnson Co Executive - United States Of America (OJC)",
    value: "OJC",
  },
  {
    label: "Kansas City, Kansas City Intl - United States Of America (MCI)",
    value: "MCI",
  },
  {
    label:
      "Kansas City, New Century Aircenter - United States Of America (JCI)",
    value: "JCI",
  },
  {
    label: "Kapalua, Kapalua - United States Of America (JHM)",
    value: "JHM",
  },
  {
    label: "Kapolei, Kalaeloa - United States Of America (JRF)",
    value: "JRF",
  },
  {
    label: "Karluk, Karluk - United States Of America (KYK)",
    value: "KYK",
  },
  {
    label: "Karluk Lake, Karluk Lake - United States Of America (KKL)",
    value: "KKL",
  },
  {
    label: "Kasaan, Kasaan Spb - United States Of America (KXA)",
    value: "KXA",
  },
  {
    label: "Kasigluk, Kasigluk - United States Of America (KUK)",
    value: "KUK",
  },
  {
    label: "Kauai Island, Lihue - United States Of America (LIH)",
    value: "LIH",
  },
  {
    label: "Kavik, Airstrip - United States Of America (VIK)",
    value: "VIK",
  },
  {
    label: "Kayenta, Kayenta - United States Of America (MVM)",
    value: "MVM",
  },
  {
    label: "Kearney, Regional - United States Of America (EAR)",
    value: "EAR",
  },
  {
    label: "Keene, Dillant Hopkins - United States Of America (EEN)",
    value: "EEN",
  },
  {
    label: "Kekaha, Barking Sands - United States Of America (BKH)",
    value: "BKH",
  },
  {
    label: "Kelso, Sw Washington Region - United States Of America (KLS)",
    value: "KLS",
  },
  {
    label: "Kemerer, Municipal - United States Of America (EMM)",
    value: "EMM",
  },
  {
    label: "Kenai, Kenai Municipal - United States Of America (ENA)",
    value: "ENA",
  },
  {
    label:
      "Kenmore Air Harbo, Kenmore Air Harbor Spb - United States Of America (KEH)",
    value: "KEH",
  },
  {
    label: "Kennett, Memorial - United States Of America (KNT)",
    value: "KNT",
  },
  {
    label: "Kennewick, Vista Field - United States Of America (VSK)",
    value: "VSK",
  },
  {
    label: "Kenosha, Regional - United States Of America (ENW)",
    value: "ENW",
  },
  {
    label: "Kentland, Municipal - United States Of America (KKT)",
    value: "KKT",
  },
  {
    label: "Keokuk, Municipal - United States Of America (EOK)",
    value: "EOK",
  },
  {
    label: "Kerrville, Municipal - United States Of America (ERV)",
    value: "ERV",
  },
  {
    label: "Ketchikan, Harbor Spb - United States Of America (WFB)",
    value: "WFB",
  },
  {
    label: "Ketchikan, Ketchikan Intl - United States Of America (KTN)",
    value: "KTN",
  },
  {
    label: "Key Largo, Port Largo - United States Of America (KYL)",
    value: "KYL",
  },
  {
    label: "Key West, Key West Intl - United States Of America (EYW)",
    value: "EYW",
  },
  {
    label: "Key West, Nas  Boca Chica Field) - United States Of America (NQX)",
    value: "NQX",
  },
  {
    label: "Kiana, Bob Barker Memorial - United States Of America (IAN)",
    value: "IAN",
  },
  {
    label: "Kill Devil Hills, First - United States Of America (FFA)",
    value: "FFA",
  },
  {
    label: "Killeen, Hood Aaf - United States Of America (HLR)",
    value: "HLR",
  },
  {
    label: "Killeen, Regional R Gray Aaf - United States Of America (GRK)",
    value: "GRK",
  },
  {
    label: "Killeen, Skylark Field - United States Of America (ILE)",
    value: "ILE",
  },
  {
    label: "King City, Mesa Del Rey - United States Of America (KIC)",
    value: "KIC",
  },
  {
    label: "King Cove, King Cove - United States Of America (KVC)",
    value: "KVC",
  },
  {
    label: "King Of Prussia, King Of Prussia - United States Of America (KPD)",
    value: "KPD",
  },
  {
    label: "King Salmon, King Salmon - United States Of America (AKN)",
    value: "AKN",
  },
  {
    label: "Kingman, Kingman - United States Of America (IGM)",
    value: "IGM",
  },
  {
    label: "Kingsville, Nas - United States Of America (NQI)",
    value: "NQI",
  },
  {
    label: "Kinston, Regional Jetport - United States Of America (ISO)",
    value: "ISO",
  },
  {
    label: "Kipnuk, Kipnuk - United States Of America (KPN)",
    value: "KPN",
  },
  {
    label: "Kirksville, Regional - United States Of America (IRK)",
    value: "IRK",
  },
  {
    label: "Kitoi Bay, Spb - United States Of America (KKB)",
    value: "KKB",
  },
  {
    label: "Kivalina, Kivalina - United States Of America (KVL)",
    value: "KVL",
  },
  {
    label: "Kizhuyak, Kizhuyak - United States Of America (KZH)",
    value: "KZH",
  },
  {
    label: "Klag Bay, Klag Bay - United States Of America (KBK)",
    value: "KBK",
  },
  {
    label:
      "Klamath Falls, Klamath Falls Airport - United States Of America (LMT)",
    value: "LMT",
  },
  {
    label: "Klawock, Klawock - United States Of America (KLW)",
    value: "KLW",
  },
  {
    label: "Knoxville, Mcghee Tyson - United States Of America (TYS)",
    value: "TYS",
  },
  {
    label: "Kobuk, Kobuk - United States Of America (OBU)",
    value: "OBU",
  },
  {
    label: "Kodiak, Kodiak Airport - United States Of America (ADQ)",
    value: "ADQ",
  },
  {
    label: "Kodiak, Kodiak Fss - United States Of America (WDY)",
    value: "WDY",
  },
  {
    label: "Kodiak, Kodiak Mnpl - United States Of America (KDK)",
    value: "KDK",
  },
  {
    label: "Kokomo, Municipal - United States Of America (OKK)",
    value: "OKK",
  },
  {
    label: "Kona, Keahole - United States Of America (KOA)",
    value: "KOA",
  },
  {
    label: "Kongiganak, Kongiganak - United States Of America (KKH)",
    value: "KKH",
  },
  {
    label: "Kosciusko, Attala County - United States Of America (OSX)",
    value: "OSX",
  },
  {
    label: "Kotlik, Kotlik - United States Of America (KOT)",
    value: "KOT",
  },
  {
    label: "Kotzebue, Ralph Wien Memorial - United States Of America (OTZ)",
    value: "OTZ",
  },
  {
    label: "Koyuk, Alfred Adams - United States Of America (KKA)",
    value: "KKA",
  },
  {
    label: "Koyukuk, Koyukuk - United States Of America (KYU)",
    value: "KYU",
  },
  {
    label: "Kugururok River, Kugururok River - United States Of America (KUW)",
    value: "KUW",
  },
  {
    label: "Kulik Lake, Kulik Lake - United States Of America (LKK)",
    value: "LKK",
  },
  {
    label: "Kuparuk, Ugnu-Kuparuk - United States Of America (UUK)",
    value: "UUK",
  },
  {
    label: "Kwethluk, Kwethluk - United States Of America (KWT)",
    value: "KWT",
  },
  {
    label: "Kwigillingok, Kwigillingok - United States Of America (KWK)",
    value: "KWK",
  },
  {
    label: "La Crosse, Regional - United States Of America (LSE)",
    value: "LSE",
  },
  {
    label: "La Grande, Union County - United States Of America (LGD)",
    value: "LGD",
  },
  {
    label: "La Grange, Callaway - United States Of America (LGC)",
    value: "LGC",
  },
  {
    label: "La Verne, Brackett Field - United States Of America (POC)",
    value: "POC",
  },
  {
    label: "Labouchere Bay, Labouchere Bay - United States Of America (WLB)",
    value: "WLB",
  },
  {
    label: "Laconia, Laconia Mnpl - United States Of America (LCI)",
    value: "LCI",
  },
  {
    label: "Lafayette, Purdue Univer - United States Of America (LAF)",
    value: "LAF",
  },
  {
    label: "Lafayette, Regional - United States Of America (LFT)",
    value: "LFT",
  },
  {
    label: "Lake Charles, Chennualt Intl - United States Of America (CWF)",
    value: "CWF",
  },
  {
    label: "Lake Charles, Regional - United States Of America (LCH)",
    value: "LCH",
  },
  {
    label: "Lake City, Lake City Gateway - United States Of America (LCQ)",
    value: "LCQ",
  },
  {
    label: "Lake Geneva, Grand Geneva Resort - United States Of America (XES)",
    value: "XES",
  },
  {
    label:
      "Lake Havasu City, Lake Havasu City - United States Of America (HII)",
    value: "HII",
  },
  {
    label:
      "Lake Jackson, Texas Gulf Coast Rgnl - United States Of America (LJN)",
    value: "LJN",
  },
  {
    label: "Lake Minchumina, Lake Minchumina - United States Of America (LMA)",
    value: "LMA",
  },
  {
    label: "Lake Placid, Lake Placid - United States Of America (LKP)",
    value: "LKP",
  },
  {
    label: "Lake Tahoe South, Lake Tahoe - United States Of America (TVL)",
    value: "TVL",
  },
  {
    label: "Lakehurst, Maxfield Field - United States Of America (NEL)",
    value: "NEL",
  },
  {
    label: "Lakeland, Linder Regional - United States Of America (LAL)",
    value: "LAL",
  },
  {
    label: "Lakeside, Lakeside - United States Of America (LKS)",
    value: "LKS",
  },
  {
    label: "Lakeview, Lake County - United States Of America (LKV)",
    value: "LKV",
  },
  {
    label: "Lamar, Lamar Municipal - United States Of America (LAA)",
    value: "LAA",
  },
  {
    label: "Lanai City, Lanai City - United States Of America (LNY)",
    value: "LNY",
  },
  {
    label: "Lancaster, General William J Fox - United States Of America (WJF)",
    value: "WJF",
  },
  {
    label: "Lancaster, Lancaster - United States Of America (LNS)",
    value: "LNS",
  },
  {
    label: "Lancaster, Quartz Hill - United States Of America (RZH)",
    value: "RZH",
  },
  {
    label: "Lander, Hunt Field - United States Of America (LND)",
    value: "LND",
  },
  {
    label: "Lansing, Capital Region Intl - United States Of America (LAN)",
    value: "LAN",
  },
  {
    label: "Laporte, Municipal - United States Of America (LPO)",
    value: "LPO",
  },
  {
    label: "Laramie, Regional - United States Of America (LAR)",
    value: "LAR",
  },
  {
    label: "Laredo, Laredo Intl - United States Of America (LRD)",
    value: "LRD",
  },
  {
    label: "Larsen Bay, Larsen Bay - United States Of America (KLN)",
    value: "KLN",
  },
  {
    label: "Las Cruces, International - United States Of America (LRU)",
    value: "LRU",
  },
  {
    label: "Las Vegas, NV - United States Of America (LAS)",
    value: "LAS",
  },
  {
    label: "Las Vegas, Mccarran International - United States Of America (LAS)",
    value: "LAS",
  },
  {
    label: "Las Vegas, Henderson Executive - United States Of America (HSH)",
    value: "HSH",
  },
  {
    label: "Las Vegas, Las Vegas Boulder Arpt - United States Of America (BLD)",
    value: "BLD",
  },
  {
    label: "Las Vegas, Nellis Afb - United States Of America (LSV)",
    value: "LSV",
  },
  {
    label: "Las Vegas, North Las Vegas Arpt - United States Of America (VGT)",
    value: "VGT",
  },
  {
    label: "Las Vegas, NM - United States Of America (LVS)",
    value: "LVS",
  },
  {
    label: "Las Vegas, Municipal - United States Of America (LVS)",
    value: "LVS",
  },
  {
    label: "Lathrop, Sharpe Aaf - United States Of America (LRO)",
    value: "LRO",
  },
  {
    label: "Lathrop Wells, Lathrop Wells - United States Of America (LTH)",
    value: "LTH",
  },
  {
    label: "Latrobe, Arnold Palmer Regional - United States Of America (LBE)",
    value: "LBE",
  },
  {
    label: "Laurel, Hattiesburg - United States Of America (PIB)",
    value: "PIB",
  },
  {
    label: "Laurel, Hesler-Noble Field - United States Of America (LUL)",
    value: "LUL",
  },
  {
    label: "Lawrence, Lawrence - United States Of America (LWC)",
    value: "LWC",
  },
  {
    label: "Lawrence, Municipal - United States Of America (LWM)",
    value: "LWM",
  },
  {
    label:
      "Lawrenceville, Brunswick Municipal - United States Of America (LVL)",
    value: "LVL",
  },
  {
    label: "Lawrenceville, Gwinnett County - United States Of America (LZU)",
    value: "LZU",
  },
  {
    label: "Lawrenceville, Vincennes Intl - United States Of America (LWV)",
    value: "LWV",
  },
  {
    label: "Lawton, Henry Post Aaf - United States Of America (FSI)",
    value: "FSI",
  },
  {
    label: "Lawton, Regional - United States Of America (LAW)",
    value: "LAW",
  },
  {
    label: "Leadville, Lake County - United States Of America (LXV)",
    value: "LXV",
  },
  {
    label: "Lebanon, Municipal - United States Of America (LEB)",
    value: "LEB",
  },
  {
    label: "Leesburg, International - United States Of America (LEE)",
    value: "LEE",
  },
  {
    label: "Lemars, Municipal - United States Of America (LRJ)",
    value: "LRJ",
  },
  {
    label: "Lemmon, Municipal - United States Of America (LEM)",
    value: "LEM",
  },
  {
    label: "Lemoore, Nas Reeves Field - United States Of America (NLC)",
    value: "NLC",
  },
  {
    label:
      "Leonardtown, Saint Mary's County Rgnl - United States Of America (LTW)",
    value: "LTW",
  },
  {
    label: "Levelock, Levelock - United States Of America (KLL)",
    value: "KLL",
  },
  {
    label: "Lewisburg, Greenbrier Valley - United States Of America (LWB)",
    value: "LWB",
  },
  {
    label: "Lewiston, Municipal - United States Of America (LEW)",
    value: "LEW",
  },
  {
    label: "Lewiston, Nez Perce County - United States Of America (LWS)",
    value: "LWS",
  },
  {
    label: "Lewistown, Lewistown Mnpl - United States Of America (LWT)",
    value: "LWT",
  },
  {
    label: "Lexington, Blue Grass - United States Of America (LEX)",
    value: "LEX",
  },
  {
    label: "Lexington, Creech Heliport - United States Of America (LSD)",
    value: "LSD",
  },
  {
    label: "Lexington, Jim Kelly Field - United States Of America (LXN)",
    value: "LXN",
  },
  {
    label: "Liberal, Mid America Regional - United States Of America (LBL)",
    value: "LBL",
  },
  {
    label: "Lima, Allen County - United States Of America (AOH)",
    value: "AOH",
  },
  {
    label: "Lime Village, Lime Village - United States Of America (LVD)",
    value: "LVD",
  },
  {
    label: "Limestone, Loring Intl - United States Of America (LIZ)",
    value: "LIZ",
  },
  {
    label: "Limon, Limon Mnpl - United States Of America (LIC)",
    value: "LIC",
  },
  {
    label: "Lincoln, Lincoln - United States Of America (LNK)",
    value: "LNK",
  },
  {
    label: "Lincoln Rock, Coast Guard - United States Of America (LRK)",
    value: "LRK",
  },
  {
    label: "Linden, Linden - United States Of America (LDJ)",
    value: "LDJ",
  },
  {
    label: "Little Naukati, Little Naukati - United States Of America (WLN)",
    value: "WLN",
  },
  {
    label:
      "Little Port Walte, Little Port Walte - United States Of America (LPW)",
    value: "LPW",
  },
  {
    label: "Little Rock, B And H Clinton Natl - United States Of America (LIT)",
    value: "LIT",
  },
  {
    label: "Livengood, Livengood Camp - United States Of America (LIV)",
    value: "LIV",
  },
  {
    label: "Livermore, Municipal - United States Of America (LVK)",
    value: "LVK",
  },
  {
    label: "Livingston, Mission Fld - United States Of America (LVM)",
    value: "LVM",
  },
  {
    label: "Lock Haven, West T Piper Mem - United States Of America (LHV)",
    value: "LHV",
  },
  {
    label: "Lockport, Lewis University - United States Of America (LOT)",
    value: "LOT",
  },
  {
    label: "Logan, Logan Cache - United States Of America (LGU)",
    value: "LGU",
  },
  {
    label: "Lompoc, Lompoc Apt - United States Of America (LPC)",
    value: "LPC",
  },
  {
    label: "Lompoc, Vandenberg Afb - United States Of America (VBG)",
    value: "VBG",
  },
  {
    label: "London, Magee Field - United States Of America (LOZ)",
    value: "LOZ",
  },
  {
    label: "Lone Rock, Tri-County Regional - United States Of America (LNR)",
    value: "LNR",
  },
  {
    label: "Lonely, Air Station - United States Of America (LNI)",
    value: "LNI",
  },
  {
    label: "Long Beach, Heliport - United States Of America (JLB)",
    value: "JLB",
  },
  {
    label: "Long Island, Long Island - United States Of America (LIJ)",
    value: "LIJ",
  },
  {
    label: "Longview, East Texas Regional - United States Of America (GGG)",
    value: "GGG",
  },
  {
    label: "Longview, Longview - United States Of America (LOG)",
    value: "LOG",
  },
  {
    label: "Lopez Island, Lopez Island - United States Of America (LPS)",
    value: "LPS",
  },
  {
    label: "Lordsburg, Municipal - United States Of America (LSB)",
    value: "LSB",
  },
  {
    label: "Loring, Sea Plane Base - United States Of America (WLR)",
    value: "WLR",
  },
  {
    label: "Los Alamos, Los Alamos - United States Of America (LAM)",
    value: "LAM",
  },
  {
    label: "Los Angeles, United States Of America (LAX)",
    value: "LAX",
  },
  {
    label: "Los Angeles, Los Angeles Intl - United States Of America (LAX)",
    value: "LAX",
  },
  {
    label:
      "Los Angeles, Burbank, Bob Hope Apt - United States Of America (BUR)",
    value: "BUR",
  },
  {
    label: "Los Angeles, Citicorp - United States Of America (JCX)",
    value: "JCX",
  },
  {
    label: "Los Angeles, Van Nuys - United States Of America (VNY)",
    value: "VNY",
  },
  {
    label: "Los Angeles, Whiteman - United States Of America (WHP)",
    value: "WHP",
  },
  {
    label:
      "Los Angeles, Oakland, Oakland Intl - United States Of America (OAK)",
    value: "OAK",
  },
  {
    label: "Los Angeles, Ontario, La - United States Of America (ONT)",
    value: "ONT",
  },
  {
    label:
      "Los Angeles, Palm Springs, International - United States Of America (PSP)",
    value: "PSP",
  },
  {
    label:
      "Los Angeles, Sacramento, International - United States Of America (SMF)",
    value: "SMF",
  },
  {
    label:
      "Los Angeles, Long Beach, Daugherty Field - United States Of America (LGB)",
    value: "LGB",
  },
  {
    label:
      "Los Angeles, San Francisco, San Francisco Intl - United States Of America (SFO)",
    value: "SFO",
  },
  {
    label:
      "Los Angeles, San Diego, International - United States Of America (SAN)",
    value: "SAN",
  },
  {
    label:
      "Los Angeles, Santa Barbara, Saint Ba Mnpl - United States Of America (SBA)",
    value: "SBA",
  },
  {
    label: "Los Banos, Municipal - United States Of America (LSN)",
    value: "LSN",
  },
  {
    label: "Lost Harbor, Sea Port - United States Of America (LHB)",
    value: "LHB",
  },
  {
    label: "Lost River, Lost River - United States Of America (LSR)",
    value: "LSR",
  },
  {
    label: "Louisa, Louisa Co.-Freeman Fld - United States Of America (LOW)",
    value: "LOW",
  },
  {
    label: "Louisburg, Triangle North Exec. - United States Of America (LFN)",
    value: "LFN",
  },
  {
    label: "Louisville, Bowman Field - United States Of America (LOU)",
    value: "LOU",
  },
  {
    label: "Louisville, Intercont - United States Of America (LJC)",
    value: "LJC",
  },
  {
    label: "Louisville, Intl-Standiford Fld - United States Of America (SDF)",
    value: "SDF",
  },
  {
    label: "Louisville, Winston Cnty - United States Of America (LMS)",
    value: "LMS",
  },
  {
    label: "Lovelock, Derby Field - United States Of America (LOL)",
    value: "LOL",
  },
  {
    label: "Lubbock, Preston Smith Intl - United States Of America (LBB)",
    value: "LBB",
  },
  {
    label: "Lubbock, Reese Airpark - United States Of America (REE)",
    value: "REE",
  },
  {
    label: "Ludington, Mason County - United States Of America (LDM)",
    value: "LDM",
  },
  {
    label: "Lufkin, Angelina County - United States Of America (LFK)",
    value: "LFK",
  },
  {
    label: "Lumberton, Municipal - United States Of America (LBT)",
    value: "LBT",
  },
  {
    label: "Lusk, Municipal - United States Of America (LSK)",
    value: "LSK",
  },
  {
    label: "Lynchburg, Regional-P. Glenn Fld - United States Of America (LYH)",
    value: "LYH",
  },
  {
    label: "Lyndonville, Caledonia County - United States Of America (LLX)",
    value: "LLX",
  },
  {
    label: "Lyons, Rice County Mncpl - United States Of America (LYO)",
    value: "LYO",
  },
  {
    label:
      "Machesney Rockford, Park And Ride Bus - United States Of America (ZRF)",
    value: "ZRF",
  },
  {
    label: "Mackinac Island, Mackinac Island - United States Of America (MCD)",
    value: "MCD",
  },
  {
    label: "Macomb, Macomb Mnpl - United States Of America (MQB)",
    value: "MQB",
  },
  {
    label: "Macon, Downtown Apt - United States Of America (MAC)",
    value: "MAC",
  },
  {
    label: "Macon, Middle Georgia Rgnl - United States Of America (MCN)",
    value: "MCN",
  },
  {
    label: "Madera, Municipal - United States Of America (MAE)",
    value: "MAE",
  },
  {
    label: "Madison, Bruce Campbell Field - United States Of America (DXE)",
    value: "DXE",
  },
  {
    label: "Madison, Dane Couty Regio - United States Of America (MSN)",
    value: "MSN",
  },
  {
    label: "Madison, Griswold - United States Of America (MPE)",
    value: "MPE",
  },
  {
    label: "Madison, Municipal - United States Of America (XMD)",
    value: "XMD",
  },
  {
    label: "Madison, Municipal - United States Of America (MDN)",
    value: "MDN",
  },
  {
    label: "Madras, Municipal - United States Of America (MDJ)",
    value: "MDJ",
  },
  {
    label: "Magnolia, Magnolia Mnpl - United States Of America (AGO)",
    value: "AGO",
  },
  {
    label: "Malad City, Malad City - United States Of America (MLD)",
    value: "MLD",
  },
  {
    label: "Malden, Regional - United States Of America (MAW)",
    value: "MAW",
  },
  {
    label: "Malta, Malta - United States Of America (MLK)",
    value: "MLK",
  },
  {
    label: "Mammoth Lakes, Mammoth Lakes - United States Of America (MMH)",
    value: "MMH",
  },
  {
    label: "Manassas, Regional - United States Of America (MNZ)",
    value: "MNZ",
  },
  {
    label:
      "Manchester, Manchester Boston Rgnl - United States Of America (MHT)",
    value: "MHT",
  },
  {
    label: "Manhattan, Regional - United States Of America (MHK)",
    value: "MHK",
  },
  {
    label: "Manila, Manila Mnpl - United States Of America (MXA)",
    value: "MXA",
  },
  {
    label: "Manistee, Manistee Co Blacker - United States Of America (MBL)",
    value: "MBL",
  },
  {
    label: "Manistique, Schoolcraft - United States Of America (ISQ)",
    value: "ISQ",
  },
  {
    label: "Manitowoc, Manitowoc County - United States Of America (MTW)",
    value: "MTW",
  },
  {
    label: "Mankato, Mankato Mnpl Hpt - United States Of America (JMN)",
    value: "JMN",
  },
  {
    label: "Mankato, Regional - United States Of America (MKT)",
    value: "MKT",
  },
  {
    label:
      "Manley Hot Spring, Manley Hot Spring - United States Of America (MLY)",
    value: "MLY",
  },
  {
    label: "Manokotak, Manokotak - United States Of America (KMO)",
    value: "KMO",
  },
  {
    label: "Mansfield, Lahm Regional - United States Of America (MFD)",
    value: "MFD",
  },
  {
    label: "Manteo, Dare County Reg - United States Of America (MEO)",
    value: "MEO",
  },
  {
    label: "Manti, Manti Ephraim - United States Of America (NTJ)",
    value: "NTJ",
  },
  {
    label: "Manville, Central Jersey Rgnl - United States Of America (JVI)",
    value: "JVI",
  },
  {
    label: "Marana, Pinal Airpark - United States Of America (MZJ)",
    value: "MZJ",
  },
  {
    label: "Marathon, The Florida Keys - United States Of America (MTH)",
    value: "MTH",
  },
  {
    label: "Marble Canyon, Marble Canyon - United States Of America (MYH)",
    value: "MYH",
  },
  {
    label: "Marco Island, Marco Island - United States Of America (MRK)",
    value: "MRK",
  },
  {
    label: "Marfa, Marfa Mnpl - United States Of America (MRF)",
    value: "MRF",
  },
  {
    label: "Marguerite Bay, Marguerite Bay - United States Of America (RTE)",
    value: "RTE",
  },
  {
    label: "Marietta, Atlanta Nas - United States Of America (NCQ)",
    value: "NCQ",
  },
  {
    label: "Marietta, Dobbins Arb - United States Of America (MGE)",
    value: "MGE",
  },
  {
    label: "Marion, Marion Mnpl - United States Of America (MNN)",
    value: "MNN",
  },
  {
    label: "Marion, Municipal - United States Of America (MZZ)",
    value: "MZZ",
  },
  {
    label: "Marion, Williamson County Rgnl - United States Of America (MWA)",
    value: "MWA",
  },
  {
    label: "Mariposa, Mariposa-Yosemite - United States Of America (RMY)",
    value: "RMY",
  },
  {
    label: "Marks, Selfs - United States Of America (MMS)",
    value: "MMS",
  },
  {
    label: "Marlborough, Marlborough - United States Of America (MXG)",
    value: "MXG",
  },
  {
    label: "Marquette, Sawyer Intl - United States Of America (MQT)",
    value: "MQT",
  },
  {
    label: "Marshall, Don Hunter Sr. - United States Of America (MLL)",
    value: "MLL",
  },
  {
    label: "Marshall, Harrison County - United States Of America (ASL)",
    value: "ASL",
  },
  {
    label: "Marshall, Memorial Mncp - United States Of America (MHL)",
    value: "MHL",
  },
  {
    label: "Marshall, Sw Minnesota Rgnl - United States Of America (MML)",
    value: "MML",
  },
  {
    label: "Marshalltown, Mncp - United States Of America (MIW)",
    value: "MIW",
  },
  {
    label: "Marshfield, Marshfield Mnpl - United States Of America (MFI)",
    value: "MFI",
  },
  {
    label:
      "Marthas Vineyard, Marthas Vineyard - United States Of America (MVY)",
    value: "MVY",
  },
  {
    label:
      "Martinsburg, Eastern W.V. Regional - United States Of America (MRB)",
    value: "MRB",
  },
  {
    label: "Marysville, Beale Afb - United States Of America (BAB)",
    value: "BAB",
  },
  {
    label: "Marysville, Yuba County - United States Of America (MYV)",
    value: "MYV",
  },
  {
    label: "Mason City, Municipal - United States Of America (MCW)",
    value: "MCW",
  },
  {
    label: "Massena, International - United States Of America (MSS)",
    value: "MSS",
  },
  {
    label:
      "Matagorda Isl, National Wildlife Ref - United States Of America (MGI)",
    value: "MGI",
  },
  {
    label: "Mattoon, Coles County Memorial - United States Of America (MTO)",
    value: "MTO",
  },
  {
    label: "Maxton, Laurinburg - United States Of America (MXE)",
    value: "MXE",
  },
  {
    label: "May Creek, May Creek - United States Of America (MYK)",
    value: "MYK",
  },
  {
    label: "Mayport, Naval Station - United States Of America (NRB)",
    value: "NRB",
  },
  {
    label: "Mc Alester, Regional - United States Of America (MLC)",
    value: "MLC",
  },
  {
    label: "Mc Rae, Telfair Wheeler - United States Of America (MQW)",
    value: "MQW",
  },
  {
    label: "Mcallen, Miller International - United States Of America (MFE)",
    value: "MFE",
  },
  {
    label: "Mccall, Mccall Municipal - United States Of America (MYL)",
    value: "MYL",
  },
  {
    label: "Mccarthy, Mccarthy - United States Of America (MXY)",
    value: "MXY",
  },
  {
    label: "Mccomb, Pike County - United States Of America (MCB)",
    value: "MCB",
  },
  {
    label: "Mccook, Ben Nelson Regional - United States Of America (MCK)",
    value: "MCK",
  },
  {
    label: "Mcgrath, Mcgrath - United States Of America (MCG)",
    value: "MCG",
  },
  {
    label:
      "Mcminnville, Warren County Memorial - United States Of America (RNC)",
    value: "RNC",
  },
  {
    label: "Mcpherson, Mcpherson - United States Of America (MPR)",
    value: "MPR",
  },
  {
    label: "Meadville, Port Meadville - United States Of America (MEJ)",
    value: "MEJ",
  },
  {
    label: "Medford, Rogue Valley Intl - United States Of America (MFR)",
    value: "MFR",
  },
  {
    label: "Medford, Taylor County - United States Of America (MDF)",
    value: "MDF",
  },
  {
    label: "Medfra, Medfra - United States Of America (MDR)",
    value: "MDR",
  },
  {
    label: "Mekoryuk, Mekoryuk - United States Of America (MYU)",
    value: "MYU",
  },
  {
    label: "Melfa, Accomack County - United States Of America (MFV)",
    value: "MFV",
  },
  {
    label: "Memphis, Memphis Intl - United States Of America (MEM)",
    value: "MEM",
  },
  {
    label: "Memphis, Nas - United States Of America (NQA)",
    value: "NQA",
  },
  {
    label: "Menominee, Menominee Marinette - United States Of America (MNM)",
    value: "MNM",
  },
  {
    label: "Merced, Castle - United States Of America (MER)",
    value: "MER",
  },
  {
    label: "Merced, Regional - United States Of America (MCE)",
    value: "MCE",
  },
  {
    label: "Mercury, Desert Rock - United States Of America (DRA)",
    value: "DRA",
  },
  {
    label: "Mercury, Yucca Airstrip - United States Of America (UCC)",
    value: "UCC",
  },
  {
    label: "Meridian, Regional  Key Field) - United States Of America (MEI)",
    value: "MEI",
  },
  {
    label: "Merrill, Merrill Mnpl - United States Of America (RRL)",
    value: "RRL",
  },
  {
    label: "Mesquite, Mesquite - United States Of America (MFH)",
    value: "MFH",
  },
  {
    label: "Metlakatla, Spb - United States Of America (MTM)",
    value: "MTM",
  },
  {
    label: "Meyers Chuck, Spb - United States Of America (WMK)",
    value: "WMK",
  },
  {
    label: "Miami, United States Of America (MIA)",
    value: "MIA",
  },
  {
    label: "Miami, Miami Intl - United States Of America (MIA)",
    value: "MIA",
  },
  {
    label: "Miami, Dade-Collier T T Apt - United States Of America (TNT)",
    value: "TNT",
  },
  {
    label: "Miami, Miami Executive - United States Of America (TMB)",
    value: "TMB",
  },
  {
    label: "Miami, Opa Locka Executive - United States Of America (OPF)",
    value: "OPF",
  },
  {
    label: "Miami, Seaplane Base - United States Of America (MPB)",
    value: "MPB",
  },
  {
    label: "Miami, United States Of America (MIO)",
    value: "MIO",
  },
  {
    label: "Miami, Municipal - United States Of America (MIO)",
    value: "MIO",
  },
  {
    label: "Michigan City, Municipal - United States Of America (MGC)",
    value: "MGC",
  },
  {
    label:
      "Middleton Island, Middleton Island - United States Of America (MDO)",
    value: "MDO",
  },
  {
    label: "Middletown, Regional Hook Field - United States Of America (MWO)",
    value: "MWO",
  },
  {
    label: "Midland, Airpark - United States Of America (MDD)",
    value: "MDD",
  },
  {
    label: "Midland, International - United States Of America (MAF)",
    value: "MAF",
  },
  {
    label: "Miles City, Franck Wiley Field - United States Of America (MLS)",
    value: "MLS",
  },
  {
    label: "Milford, Municipal - United States Of America (MLF)",
    value: "MLF",
  },
  {
    label: "Milledgeville, Baldwin C - United States Of America (MLJ)",
    value: "MLJ",
  },
  {
    label: "Millinocket, Municipal - United States Of America (MLT)",
    value: "MLT",
  },
  {
    label: "Millville, Municipal - United States Of America (MIV)",
    value: "MIV",
  },
  {
    label: "Milton, Whiting Fld Nas North - United States Of America (NSE)",
    value: "NSE",
  },
  {
    label: "Milwaukee, General Mitchell Intl - United States Of America (MKE)",
    value: "MKE",
  },
  {
    label: "Milwaukee, Intermodal Station - United States Of America (ZML)",
    value: "ZML",
  },
  {
    label: "Milwaukee, Timmerman - United States Of America (MWC)",
    value: "MWC",
  },
  {
    label: "Minchumina, Intermediate - United States Of America (MHM)",
    value: "MHM",
  },
  {
    label: "Minden, Minden Tahoe - United States Of America (MEV)",
    value: "MEV",
  },
  {
    label: "Mineral Wells, Fort Wolter - United States Of America (CWO)",
    value: "CWO",
  },
  {
    label: "Mineral Wells, Mineral Wells Arpt - United States Of America (MWL)",
    value: "MWL",
  },
  {
    label: "Minneapolis, MN - United States Of America (MSP)",
    value: "MSP",
  },
  {
    label: "Minneapolis, Saint Paul Intl - United States Of America (MSP)",
    value: "MSP",
  },
  {
    label: "Minneapolis, Crystal - United States Of America (MIC)",
    value: "MIC",
  },
  {
    label: "Minneapolis, Flying Cloud - United States Of America (FCM)",
    value: "FCM",
  },
  {
    label:
      "Minneapolis, Saint Paul Downtown Apt - United States Of America (STP)",
    value: "STP",
  },
  {
    label: "Minocqua, Lakeland - United States Of America (ARV)",
    value: "ARV",
  },
  {
    label: "Minot, Minot Afb - United States Of America (MIB)",
    value: "MIB",
  },
  {
    label: "Minot, Minot Intl - United States Of America (MOT)",
    value: "MOT",
  },
  {
    label: "Minto, Al Wright - United States Of America (MNT)",
    value: "MNT",
  },
  {
    label: "Missoula, Missoula International - United States Of America (MSO)",
    value: "MSO",
  },
  {
    label: "Mitchell, Mitchell Mnpl - United States Of America (MHE)",
    value: "MHE",
  },
  {
    label: "Moab, Canyonlands Field - United States Of America (CNY)",
    value: "CNY",
  },
  {
    label: "Moberly, Omar N Bradley - United States Of America (MBY)",
    value: "MBY",
  },
  {
    label: "Mobile, Downtown Arpt - United States Of America (BFM)",
    value: "BFM",
  },
  {
    label: "Mobile, Regional - United States Of America (MOB)",
    value: "MOB",
  },
  {
    label: "Mobridge, Municipal - United States Of America (MBG)",
    value: "MBG",
  },
  {
    label: "Modesto, City County - United States Of America (MOD)",
    value: "MOD",
  },
  {
    label: "Mojave, Mojave - United States Of America (MHV)",
    value: "MHV",
  },
  {
    label: "Moline, Quad City Intl - United States Of America (MLI)",
    value: "MLI",
  },
  {
    label: "Monahans, Roy Hurd Mem - United States Of America (MIF)",
    value: "MIF",
  },
  {
    label: "Monroe, Regional - United States Of America (MLU)",
    value: "MLU",
  },
  {
    label: "Monroeville, Monroe Cnty - United States Of America (MVC)",
    value: "MVC",
  },
  {
    label: "Montague, Rohrer Field - United States Of America (ROF)",
    value: "ROF",
  },
  {
    label: "Montague, Siskiyou County - United States Of America (SIY)",
    value: "SIY",
  },
  {
    label: "Montauk, Montauk - United States Of America (MTP)",
    value: "MTP",
  },
  {
    label: "Monterey, Marina Municipal - United States Of America (OAR)",
    value: "OAR",
  },
  {
    label: "Monterey, Monterey Regional - United States Of America (MRY)",
    value: "MRY",
  },
  {
    label: "Montevideo, Chippewa County - United States Of America (MVE)",
    value: "MVE",
  },
  {
    label: "Montgomery, Gunter Afb - United States Of America (GUN)",
    value: "GUN",
  },
  {
    label: "Montgomery, Maxwell Afb - United States Of America (MXF)",
    value: "MXF",
  },
  {
    label: "Montgomery, Orange County - United States Of America (MGJ)",
    value: "MGJ",
  },
  {
    label: "Montgomery, Regional-Dannelly Fld - United States Of America (MGM)",
    value: "MGM",
  },
  {
    label: "Monticello, Monticello - United States Of America (MXC)",
    value: "MXC",
  },
  {
    label: "Monticello, Regional - United States Of America (MXO)",
    value: "MXO",
  },
  {
    label: "Monticello, Sullivan Cnt - United States Of America (MSV)",
    value: "MSV",
  },
  {
    label: "Montpelier, Edward F Knapp State - United States Of America (MPV)",
    value: "MPV",
  },
  {
    label: "Montrose, Montrose Regional - United States Of America (MTJ)",
    value: "MTJ",
  },
  {
    label: "Montvale, Montvale - United States Of America (QMV)",
    value: "QMV",
  },
  {
    label: "Monument Valley, Monument Valley - United States Of America (GMV)",
    value: "GMV",
  },
  {
    label:
      "Morgan City, Harry P. Williams Mem - United States Of America (PTN)",
    value: "PTN",
  },
  {
    label: "Morganton, Foothills Regional - United States Of America (MRN)",
    value: "MRN",
  },
  {
    label: "Morgantown, Municipal - United States Of America (MGW)",
    value: "MGW",
  },
  {
    label: "Morris, Morris Mnpl - United States Of America (MOX)",
    value: "MOX",
  },
  {
    label: "Morristown, Moore Murrel - United States Of America (MOR)",
    value: "MOR",
  },
  {
    label: "Morristown, Morristown Mnpl - United States Of America (MMU)",
    value: "MMU",
  },
  {
    label: "Moser Bay, Sea Plane Base - United States Of America (KMY)",
    value: "KMY",
  },
  {
    label: "Moses Lake, Grant County Intl - United States Of America (MWH)",
    value: "MWH",
  },
  {
    label: "Moses Lake, Larson Afb - United States Of America (LRN)",
    value: "LRN",
  },
  {
    label: "Moses Point, Moses Point - United States Of America (MOS)",
    value: "MOS",
  },
  {
    label: "Moultrie, Municipal - United States Of America (MGR)",
    value: "MGR",
  },
  {
    label: "Moultrie, Spence - United States Of America (MUL)",
    value: "MUL",
  },
  {
    label: "Mount Clemens, Detroit Naf - United States Of America (NFB)",
    value: "NFB",
  },
  {
    label: "Mount Clemens, Selfridge Angb - United States Of America (MTC)",
    value: "MTC",
  },
  {
    label:
      "Mount Holly, South Jersey Regional - United States Of America (LLY)",
    value: "LLY",
  },
  {
    label: "Mount Mckinley, National Park - United States Of America (MCL)",
    value: "MCL",
  },
  {
    label: "Mount Pleasant, Mount Pleasant - United States Of America (MSD)",
    value: "MSD",
  },
  {
    label:
      "Mount Pleasant, Mount Pleasant Mnpl - United States Of America (MOP)",
    value: "MOP",
  },
  {
    label: "Mount Pleasant, Municipal - United States Of America (MPZ)",
    value: "MPZ",
  },
  {
    label: "Mount Pleasant, Regional - United States Of America (MPS)",
    value: "MPS",
  },
  {
    label:
      "Mount Pocono, Pocono Mountains Muni - United States Of America (MPO)",
    value: "MPO",
  },
  {
    label: "Mount Shasta, Dunsmuir Municipal - United States Of America (MHS)",
    value: "MHS",
  },
  {
    label: "Mount Union, Mount Union - United States Of America (MUU)",
    value: "MUU",
  },
  {
    label: "Mount Vernon, Mount Vernon - United States Of America (MVN)",
    value: "MVN",
  },
  {
    label: "Mount Vernon, Skagit Reg - United States Of America (MVW)",
    value: "MVW",
  },
  {
    label: "Mount Wilson, Mount Wilson - United States Of America (MWS)",
    value: "MWS",
  },
  {
    label: "Mountain Home, Afb - United States Of America (MUO)",
    value: "MUO",
  },
  {
    label: "Mountain Home, Ozark Regional - United States Of America (WMH)",
    value: "WMH",
  },
  {
    label:
      "Mountain View, Moffett Federal Airfld - United States Of America (NUQ)",
    value: "NUQ",
  },
  {
    label:
      "Mountain Village, Mountain Village - United States Of America (MOU)",
    value: "MOU",
  },
  {
    label: "Mullen, Hooker County - United States Of America (MHN)",
    value: "MHN",
  },
  {
    label: "Muncie, Delaware County Rgnl - United States Of America (MIE)",
    value: "MIE",
  },
  {
    label: "Murray, Calloway County - United States Of America (CEY)",
    value: "CEY",
  },
  {
    label: "Muscatine, Municipal - United States Of America (MUT)",
    value: "MUT",
  },
  {
    label:
      "Muscle Shoals, Northwest Alabama Rgnl - United States Of America (MSL)",
    value: "MSL",
  },
  {
    label: "Muskegon, Muskegon County - United States Of America (MKG)",
    value: "MKG",
  },
  {
    label: "Muskogee, Davis Field - United States Of America (MKO)",
    value: "MKO",
  },
  {
    label: "Muskogee, Hatbox Field - United States Of America (HAX)",
    value: "HAX",
  },
  {
    label: "Myrtle Beach, Grand Strand - United States Of America (CRE)",
    value: "CRE",
  },
  {
    label: "Myrtle Beach, International - United States Of America (MYR)",
    value: "MYR",
  },
  {
    label: "Nacogdoches, Al Mangham Jr Rgnl - United States Of America (OCH)",
    value: "OCH",
  },
  {
    label: "Naknek, Naknek - United States Of America (NNK)",
    value: "NNK",
  },
  {
    label: "Nakolik River, Nakolik River - United States Of America (NOL)",
    value: "NOL",
  },
  {
    label: "Nantucket, Memorial - United States Of America (ACK)",
    value: "ACK",
  },
  {
    label: "Nanwalek, Nanwalek - United States Of America (KEB)",
    value: "KEB",
  },
  {
    label: "Napa, Napa County - United States Of America (APC)",
    value: "APC",
  },
  {
    label: "Napakiak, Napakiak - United States Of America (WNA)",
    value: "WNA",
  },
  {
    label: "Napaskiak, Napaskiak - United States Of America (PKA)",
    value: "PKA",
  },
  {
    label: "Naples, Municipal - United States Of America (APF)",
    value: "APF",
  },
  {
    label: "Nashua, Boire Field - United States Of America (ASH)",
    value: "ASH",
  },
  {
    label: "Nashville, International - United States Of America (BNA)",
    value: "BNA",
  },
  {
    label: "Natchez, Adams County - United States Of America (HEZ)",
    value: "HEZ",
  },
  {
    label: "Naukiti, Sea Plane Base - United States Of America (NKI)",
    value: "NKI",
  },
  {
    label: "Needles, Needles - United States Of America (EED)",
    value: "EED",
  },
  {
    label: "Nelson Lagoon, Nelson Lagoon - United States Of America (NLG)",
    value: "NLG",
  },
  {
    label: "Nenana, Nenana Mnpl - United States Of America (ENN)",
    value: "ENN",
  },
  {
    label: "Neosho, Hugh Robinson - United States Of America (EOS)",
    value: "EOS",
  },
  {
    label: "Nephi, Municipal - United States Of America (NPH)",
    value: "NPH",
  },
  {
    label: "Nevada, Municipal - United States Of America (NVD)",
    value: "NVD",
  },
  {
    label: "New Bedford, New Bedford Regional - United States Of America (EWB)",
    value: "EWB",
  },
  {
    label: "New Bern, Coastal Carolina Rgnl - United States Of America (EWN)",
    value: "EWN",
  },
  {
    label: "New Chenega, Chenega Bay - United States Of America (NCN)",
    value: "NCN",
  },
  {
    label: "New Haven, Tweed - United States Of America (HVN)",
    value: "HVN",
  },
  {
    label: "New Iberia, Acadiana Rgnl - United States Of America (ARA)",
    value: "ARA",
  },
  {
    label: "New Koliganek, Koliganek - United States Of America (KGK)",
    value: "KGK",
  },
  {
    label: "New Orleans, L Armstrong Intl - United States Of America (MSY)",
    value: "MSY",
  },
  {
    label: "New Orleans, Lakefront - United States Of America (NEW)",
    value: "NEW",
  },
  {
    label: "New Orleans, Nas Jrb - United States Of America (NBG)",
    value: "NBG",
  },
  {
    label:
      "New Philadelphia, Harry Clever Field - United States Of America (PHD)",
    value: "PHD",
  },
  {
    label: "New Richmond, Regional - United States Of America (RNH)",
    value: "RNH",
  },
  {
    label: "New Stuyahok, New Stuyahok - United States Of America (KNW)",
    value: "KNW",
  },
  {
    label: "New Ulm, Municipal - United States Of America (ULM)",
    value: "ULM",
  },
  {
    label: "New York, United States Of America (NYC)",
    value: "NYC",
  },
  {
    label: "New York, John F Kennedy Intl - United States Of America (JFK)",
    value: "JFK",
  },
  {
    label: "New York, Newark Liberty Intl - United States Of America (EWR)",
    value: "EWR",
  },
  {
    label: "New York, Laguardia - United States Of America (LGA)",
    value: "LGA",
  },
  {
    label: "New York, Niagara Falls Intl - United States Of America (IAG)",
    value: "IAG",
  },
  {
    label: "New York, Stewart International - United States Of America (SWF)",
    value: "SWF",
  },
  {
    label: "New York, Long Island Macarthur - United States Of America (ISP)",
    value: "ISP",
  },
  {
    label: "Newcastle, United States Of America (ECS)",
    value: "ECS",
  },
  {
    label: "Newcastle, Mondell Field - United States Of America (ECS)",
    value: "ECS",
  },
  {
    label: "Newport, Municipal - United States Of America (ONP)",
    value: "ONP",
  },
  {
    label: "Newport, Parlin Field - United States Of America (NWH)",
    value: "NWH",
  },
  {
    label: "Newport, State - United States Of America (NPT)",
    value: "NPT",
  },
  {
    label: "Newport, State - United States Of America (EFK)",
    value: "EFK",
  },
  {
    label: "Newport Beach, Heliport - United States Of America (JNP)",
    value: "JNP",
  },
  {
    label:
      "Newport News Hampton, International - United States Of America (PHF)",
    value: "PHF",
  },
  {
    label:
      "Newport News Hampton, Newport News Rail Stn - United States Of America (ZWW)",
    value: "ZWW",
  },
  {
    label:
      "Newport News Hampton, Williamsburg Rail Stn - United States Of America (ZWB)",
    value: "ZWB",
  },
  {
    label: "Newtok, Newtok - United States Of America (WWT)",
    value: "WWT",
  },
  {
    label: "Newton, City County - United States Of America (EWK)",
    value: "EWK",
  },
  {
    label: "Newton, Newton Mnpl - United States Of America (TNU)",
    value: "TNU",
  },
  {
    label: "Niblack, Niblack - United States Of America (NIE)",
    value: "NIE",
  },
  {
    label: "Nichen Cove, Nichen Cove - United States Of America (NKV)",
    value: "NKV",
  },
  {
    label: "Nightmute, Nightmute - United States Of America (NME)",
    value: "NME",
  },
  {
    label: "Nikolai, Nikolai - United States Of America (NIB)",
    value: "NIB",
  },
  {
    label: "Nikolski, Air Station - United States Of America (IKO)",
    value: "IKO",
  },
  {
    label: "Niles, Jerry Tyler Memo - United States Of America (NLE)",
    value: "NLE",
  },
  {
    label: "Ninilchik, Ninilchik - United States Of America (NIN)",
    value: "NIN",
  },
  {
    label: "Noatak, Noatak - United States Of America (WTK)",
    value: "WTK",
  },
  {
    label: "Nogales, Nogales Intl - United States Of America (OLS)",
    value: "OLS",
  },
  {
    label: "Nome, Nome - United States Of America (OME)",
    value: "OME",
  },
  {
    label: "Nondalton, Nondalton - United States Of America (NNL)",
    value: "NNL",
  },
  {
    label: "Noorvik, R. Bob) Curtis Mem. - United States Of America (ORV)",
    value: "ORV",
  },
  {
    label: "Norfolk, VA - United States Of America (ORF)",
    value: "ORF",
  },
  {
    label: "Norfolk, Norfolk Intl - United States Of America (ORF)",
    value: "ORF",
  },
  {
    label: "Norfolk, Ns  Chambers Field) - United States Of America (NGU)",
    value: "NGU",
  },
  {
    label: "Norfolk, NE - United States Of America (OFK)",
    value: "OFK",
  },
  {
    label: "Norfolk, Regional - United States Of America (OFK)",
    value: "OFK",
  },
  {
    label: "Norman, Max Westheimer - United States Of America (OUN)",
    value: "OUN",
  },
  {
    label: "Norridgewock, Cntrl Maine - United States Of America (OWK)",
    value: "OWK",
  },
  {
    label: "North Bend, Southwest Oregon Rgnl - United States Of America (OTH)",
    value: "OTH",
  },
  {
    label:
      "North Platte, Regional Lee Bird Fld - United States Of America (LBF)",
    value: "LBF",
  },
  {
    label: "Northbrook, Sky Harbor - United States Of America (OBK)",
    value: "OBK",
  },
  {
    label: "Northeast Cape, Afs - United States Of America (OHC)",
    value: "OHC",
  },
  {
    label: "Northway, Northway - United States Of America (ORT)",
    value: "ORT",
  },
  {
    label: "Norwalk, Heliport - United States Of America (ORQ)",
    value: "ORQ",
  },
  {
    label: "Norwich, Lt Warren Eaton - United States Of America (OIC)",
    value: "OIC",
  },
  {
    label: "Norwood, Memorial - United States Of America (OWD)",
    value: "OWD",
  },
  {
    label: "Novato, Marin County - United States Of America (NOT)",
    value: "NOT",
  },
  {
    label: "Nuiqsut, Nuiqsut - United States Of America (NUI)",
    value: "NUI",
  },
  {
    label: "Nulato, Nulato - United States Of America (NUL)",
    value: "NUL",
  },
  {
    label: "Nunapitchuk, Nunapitchuk - United States Of America (NUP)",
    value: "NUP",
  },
  {
    label: "Nyac, Nyac - United States Of America (ZNC)",
    value: "ZNC",
  },
  {
    label: "Oahu, Dillingham - United States Of America (HDH)",
    value: "HDH",
  },
  {
    label: "Oak Harbor, Aj Eisenberg - United States Of America (ODW)",
    value: "ODW",
  },
  {
    label: "Oak Harbor, Whidbey Island Nas - United States Of America (NUW)",
    value: "NUW",
  },
  {
    label: "Oakdale, Oakdale - United States Of America (ODC)",
    value: "ODC",
  },
  {
    label: "Oakland, Convention Centre Hpt - United States Of America (JCE)",
    value: "JCE",
  },
  {
    label: "Oakland, Garrett County - United States Of America (ODM)",
    value: "ODM",
  },
  {
    label: "Oaktown, Ed Air - United States Of America (OTN)",
    value: "OTN",
  },
  {
    label: "Ocala, Intl  Jim Taylor Fld) - United States Of America (OCF)",
    value: "OCF",
  },
  {
    label: "Ocean Reef, Ocean Reef Club - United States Of America (OCA)",
    value: "OCA",
  },
  {
    label: "Oceana, Oceana Nas - United States Of America (NTU)",
    value: "NTU",
  },
  {
    label: "Oceanic, Oceanic - United States Of America (OCI)",
    value: "OCI",
  },
  {
    label: "Oceanside, Oceanside Mnpl - United States Of America (OCN)",
    value: "OCN",
  },
  {
    label: "Ogallala, Searle Field - United States Of America (OGA)",
    value: "OGA",
  },
  {
    label: "Ogden, Hill Afb - United States Of America (HIF)",
    value: "HIF",
  },
  {
    label: "Ogden, Hinckley - United States Of America (OGD)",
    value: "OGD",
  },
  {
    label: "Ogdensburg, International - United States Of America (OGS)",
    value: "OGS",
  },
  {
    label: "Oil City, Splane Memorial - United States Of America (OIL)",
    value: "OIL",
  },
  {
    label: "Okeechobee, Okechobee County - United States Of America (OBE)",
    value: "OBE",
  },
  {
    label: "Oklahoma City, OK - United States Of America (OKC)",
    value: "OKC",
  },
  {
    label: "Oklahoma City, Will Rogers World - United States Of America (OKC)",
    value: "OKC",
  },
  {
    label: "Oklahoma City, Downtown - United States Of America (DWN)",
    value: "DWN",
  },
  {
    label: "Oklahoma City, Tinker Afb - United States Of America (TIK)",
    value: "TIK",
  },
  {
    label: "Oklahoma City, Wiley Post - United States Of America (PWA)",
    value: "PWA",
  },
  {
    label: "Okmulgee, Regional - United States Of America (OKM)",
    value: "OKM",
  },
  {
    label: "Old Harbor, Old Harbor - United States Of America (OLH)",
    value: "OLH",
  },
  {
    label: "Old Town, Dewitt Fld Municipal - United States Of America (OLD)",
    value: "OLD",
  },
  {
    label: "Olean, Cattaraugus County - United States Of America (OLE)",
    value: "OLE",
  },
  {
    label: "Olga Bay, Olga Bay Spb - United States Of America (KOY)",
    value: "KOY",
  },
  {
    label: "Olive Branch, Olive Branc - United States Of America (OLV)",
    value: "OLV",
  },
  {
    label: "Olney, Municipal - United States Of America (ONY)",
    value: "ONY",
  },
  {
    label: "Olney, Olney Noble - United States Of America (OLY)",
    value: "OLY",
  },
  {
    label: "Olympia, Regional - United States Of America (OLM)",
    value: "OLM",
  },
  {
    label: "Omaha, Eppley Airfield - United States Of America (OMA)",
    value: "OMA",
  },
  {
    label: "Omaha, Millard - United States Of America (MIQ)",
    value: "MIQ",
  },
  {
    label: "Omak, Omak - United States Of America (OMK)",
    value: "OMK",
  },
  {
    label: "Oneill, Municipal - United States Of America (ONL)",
    value: "ONL",
  },
  {
    label: "Oneonta, Oneonta Mnpl - United States Of America (ONH)",
    value: "ONH",
  },
  {
    label: "Onion Bay, Onion Bay - United States Of America (ONN)",
    value: "ONN",
  },
  {
    label: "Ontario, Ontario Municipal - United States Of America (ONO)",
    value: "ONO",
  },
  {
    label: "Opelousas, Saint Landry - United States Of America (OPL)",
    value: "OPL",
  },
  {
    label: "Orange, The City Heliport - United States Of America (JOR)",
    value: "JOR",
  },
  {
    label: "Orangeburg, Orangeburg Mnpl - United States Of America (OGB)",
    value: "OGB",
  },
  {
    label: "Orlando, United States Of America (ORL)",
    value: "ORL",
  },
  {
    label: "Orlando, Executive - United States Of America (ORL)",
    value: "ORL",
  },
  {
    label: "Orlando, Kissimmee Gateway - United States Of America (ISM)",
    value: "ISM",
  },
  {
    label: "Orlando, Orlando Intl - United States Of America (MCO)",
    value: "MCO",
  },
  {
    label: "Orlando, Disney World - United States Of America (DWS)",
    value: "DWS",
  },
  {
    label:
      "Orlando, Daytona Beach, International - United States Of America (DAB)",
    value: "DAB",
  },
  {
    label: "Orlando, Key West Intl - United States Of America (EYW)",
    value: "EYW",
  },
  {
    label: "Orlando, Melbourne, International - United States Of America (MLB)",
    value: "MLB",
  },
  {
    label: "Orlando, Sanford Intl - United States Of America (SFB)",
    value: "SFB",
  },
  {
    label:
      "Orlando, Panama City, Nw Florida Beaches Int - United States Of America (ECP)",
    value: "ECP",
  },
  {
    label:
      "Orlando, Tallahassee, International - United States Of America (TLH)",
    value: "TLH",
  },
  {
    label: "Oroville, United States Of America (OVE)",
    value: "OVE",
  },
  {
    label: "Oroville, Municipal - United States Of America (OVE)",
    value: "OVE",
  },
  {
    label: "Osage Beach, Grand Glaize - United States Of America (OSB)",
    value: "OSB",
  },
  {
    label: "Osceola, L.O. Simenstad Muni - United States Of America (OEO)",
    value: "OEO",
  },
  {
    label: "Oscoda, Wurtsmith - United States Of America (OSC)",
    value: "OSC",
  },
  {
    label: "Oshkosh, United States Of America (OKS)",
    value: "OKS",
  },
  {
    label: "Oshkosh, Garden County - United States Of America (OKS)",
    value: "OKS",
  },
  {
    label: "Oshkosh, United States Of America (OSH)",
    value: "OSH",
  },
  {
    label: "Oshkosh, Wittman Regional - United States Of America (OSH)",
    value: "OSH",
  },
  {
    label: "Oskaloosa, Oskaloosa Mnpl - United States Of America (OOA)",
    value: "OOA",
  },
  {
    label: "Otto, Vor - United States Of America (OTO)",
    value: "OTO",
  },
  {
    label: "Ottumwa, Regional - United States Of America (OTM)",
    value: "OTM",
  },
  {
    label: "Ouzinkie, Ouzinkie - United States Of America (KOZ)",
    value: "KOZ",
  },
  {
    label: "Owatonna, Degner Regional - United States Of America (OWA)",
    value: "OWA",
  },
  {
    label: "Owensboro, Daviess Count - United States Of America (OWB)",
    value: "OWB",
  },
  {
    label: "Oxford, Waterbury Oxford - United States Of America (OXC)",
    value: "OXC",
  },
  {
    label: "Oxford, Miami University - United States Of America (OXD)",
    value: "OXD",
  },
  {
    label: "Oxford, University Oxford - United States Of America (UOX)",
    value: "UOX",
  },
  {
    label: "Oxnard, Oxnard Apt - United States Of America (OXR)",
    value: "OXR",
  },
  {
    label: "Ozark, Cairns Aaf - United States Of America (OZR)",
    value: "OZR",
  },
  {
    label: "Pacific City, State - United States Of America (PFC)",
    value: "PFC",
  },
  {
    label: "Pack Creek, Pack Creek - United States Of America (PBK)",
    value: "PBK",
  },
  {
    label: "Paducah, Barkley Regional - United States Of America (PAH)",
    value: "PAH",
  },
  {
    label: "Paf Warren, Paf Warren - United States Of America (PFA)",
    value: "PFA",
  },
  {
    label: "Page, Municipal - United States Of America (PGA)",
    value: "PGA",
  },
  {
    label: "Pagosa Springs, Stevens F - United States Of America (PGO)",
    value: "PGO",
  },
  {
    label: "Pahokee, Palm Beach Glade - United States Of America (PHK)",
    value: "PHK",
  },
  {
    label: "Paimiut, Spb - United States Of America (PMU)",
    value: "PMU",
  },
  {
    label: "Painesville, Casement - United States Of America (PVZ)",
    value: "PVZ",
  },
  {
    label: "Painter Creek, Painter Creek - United States Of America (PCE)",
    value: "PCE",
  },
  {
    label: "Palacios, Municipal - United States Of America (PSX)",
    value: "PSX",
  },
  {
    label: "Palestine, Municipal - United States Of America (PSN)",
    value: "PSN",
  },
  {
    label: "Palm Springs, Bermuda - United States Of America (UDD)",
    value: "UDD",
  },
  {
    label: "Palmdale, La Palmdale Regional - United States Of America (PMD)",
    value: "PMD",
  },
  {
    label: "Palmer, Palmer - United States Of America (PMX)",
    value: "PMX",
  },
  {
    label: "Palmer, Palmer Mnpl - United States Of America (PAQ)",
    value: "PAQ",
  },
  {
    label: "Palo Alto, Santa Clara County - United States Of America (PAO)",
    value: "PAO",
  },
  {
    label: "Pampa, Perry Lefors Field - United States Of America (PPA)",
    value: "PPA",
  },
  {
    label: "Panama City, Bay County - United States Of America (PFN)",
    value: "PFN",
  },
  {
    label: "Panama City, Tyndall Afb - United States Of America (PAM)",
    value: "PAM",
  },
  {
    label: "Panguitch, Municipal - United States Of America (PNU)",
    value: "PNU",
  },
  {
    label: "Paonia, North Frk Valley - United States Of America (WPO)",
    value: "WPO",
  },
  {
    label: "Paradise, Skypark - United States Of America (PYS)",
    value: "PYS",
  },
  {
    label: "Paragould, Kirk Field - United States Of America (PGR)",
    value: "PGR",
  },
  {
    label: "Paris, Cox Field - United States Of America (PRX)",
    value: "PRX",
  },
  {
    label: "Paris, Henry County - United States Of America (PHT)",
    value: "PHT",
  },
  {
    label: "Park Falls, Municipal - United States Of America (PKF)",
    value: "PKF",
  },
  {
    label:
      "Park Rapids, Municipal-Konshok Fld - United States Of America (PKD)",
    value: "PKD",
  },
  {
    label: "Parkersburg, Mid-Ohio Valley Rgnl - United States Of America (PKB)",
    value: "PKB",
  },
  {
    label: "Parks, Parks Spb - United States Of America (KPK)",
    value: "KPK",
  },
  {
    label: "Pasadena, Heliport - United States Of America (JPD)",
    value: "JPD",
  },
  {
    label: "Pascagoula, Trent Lott Intl - United States Of America (PGL)",
    value: "PGL",
  },
  {
    label: "Pasco, Tri Cities - United States Of America (PSC)",
    value: "PSC",
  },
  {
    label: "Paso Robles, Municipal - United States Of America (PRB)",
    value: "PRB",
  },
  {
    label:
      "Patuxent River, Nas  Trapnell Field) - United States Of America (NHK)",
    value: "NHK",
  },
  {
    label: "Pauloff Harbor, Pauloff - United States Of America (KPH)",
    value: "KPH",
  },
  {
    label: "Payson, Payson - United States Of America (PJB)",
    value: "PJB",
  },
  {
    label:
      "Peach Springs, Grand Canyon Caverns - United States Of America (PGS)",
    value: "PGS",
  },
  {
    label: "Pecos City, Municipal - United States Of America (PEQ)",
    value: "PEQ",
  },
  {
    label: "Pedro Bay, Pedro Bay - United States Of America (PDB)",
    value: "PDB",
  },
  {
    label: "Pelican, Pelican Spb - United States Of America (PEC)",
    value: "PEC",
  },
  {
    label: "Pell City, Saint Clair Cnty - United States Of America (PLR)",
    value: "PLR",
  },
  {
    label: "Pellston, Regional Emmet Co - United States Of America (PLN)",
    value: "PLN",
  },
  {
    label: "Pembina, Municipal - United States Of America (PMB)",
    value: "PMB",
  },
  {
    label: "Pendleton, Eastern Oregon Rgnl - United States Of America (PDT)",
    value: "PDT",
  },
  {
    label: "Pensacola, Ellyson Nas - United States Of America (NDP)",
    value: "NDP",
  },
  {
    label: "Pensacola, International - United States Of America (PNS)",
    value: "PNS",
  },
  {
    label: "Pensacola, Nas  F.Sherman Field) - United States Of America (NPA)",
    value: "NPA",
  },
  {
    label: "Pensacola, Saufley Field Nolf - United States Of America (NUN)",
    value: "NUN",
  },
  {
    label: "Peoria, General Downing Intl - United States Of America (PIA)",
    value: "PIA",
  },
  {
    label: "Perry, Perry Foley - United States Of America (FPY)",
    value: "FPY",
  },
  {
    label: "Perry, Perry Mnpl - United States Of America (PRO)",
    value: "PRO",
  },
  {
    label: "Perry Island, Perry Spb - United States Of America (PYL)",
    value: "PYL",
  },
  {
    label: "Perryville, Perryville - United States Of America (KPV)",
    value: "KPV",
  },
  {
    label: "Peru, Grissom Arb - United States Of America (GUS)",
    value: "GUS",
  },
  {
    label: "Peru, Illinois Valley Rgnl - United States Of America (VYS)",
    value: "VYS",
  },
  {
    label: "Petersburg, Dinwiddie County - United States Of America (PTB)",
    value: "PTB",
  },
  {
    label: "Petersburg, Fort Lee Aaf - United States Of America (FLE)",
    value: "FLE",
  },
  {
    label: "Petersburg, Grant County - United States Of America (PGC)",
    value: "PGC",
  },
  {
    label: "Petersburg, James A Johnson - United States Of America (PSG)",
    value: "PSG",
  },
  {
    label:
      "Peterson's Point, Peterson's Point - United States Of America (PNF)",
    value: "PNF",
  },
  {
    label: "Philadelphia, United States Of America (PHL)",
    value: "PHL",
  },
  {
    label: "Philadelphia, Philadelphia Intl - United States Of America (PHL)",
    value: "PHL",
  },
  {
    label: "Philadelphia, Essington Spb - United States Of America (PSQ)",
    value: "PSQ",
  },
  {
    label: "Philadelphia, Mustin Alf - United States Of America (MUV)",
    value: "MUV",
  },
  {
    label:
      "Philadelphia, Northeast Philadelphia - United States Of America (PNE)",
    value: "PNE",
  },
  {
    label: "Philadelphia, Trenton-Mercer - United States Of America (TTN)",
    value: "TTN",
  },
  {
    label: "Philadelphia, Wings Field - United States Of America (BBX)",
    value: "BBX",
  },
  {
    label: "Philip, United States Of America (PHP)",
    value: "PHP",
  },
  {
    label: "Philip, Philip - United States Of America (PHP)",
    value: "PHP",
  },
  {
    label: "Philipsburg, United States Of America (PSB)",
    value: "PSB",
  },
  {
    label: "Philipsburg, Mid State - United States Of America (PSB)",
    value: "PSB",
  },
  {
    label: "Phoenix, United States Of America (PHX)",
    value: "PHX",
  },
  {
    label: "Phoenix, Sky Harbor Intl - United States Of America (PHX)",
    value: "PHX",
  },
  {
    label: "Phoenix, Mesa Gateway - United States Of America (AZA)",
    value: "AZA",
  },
  {
    label: "Phoenix, Deer Valley - United States Of America (DVT)",
    value: "DVT",
  },
  {
    label: "Phoenix, Falcon Field - United States Of America (MSC)",
    value: "MSC",
  },
  {
    label: "Phoenix, Luke Afb - United States Of America (LUF)",
    value: "LUF",
  },
  {
    label: "Phoenix, Scottsdale Apt - United States Of America (SCF)",
    value: "SCF",
  },
  {
    label: "Picayune, Pearl River County - United States Of America (PCU)",
    value: "PCU",
  },
  {
    label: "Pickens, Pickens County - United States Of America (LQK)",
    value: "LQK",
  },
  {
    label: "Pierre, Regional - United States Of America (PIR)",
    value: "PIR",
  },
  {
    label: "Pikeville, Pike County Regional - United States Of America (PVL)",
    value: "PVL",
  },
  {
    label: "Pilot Point, Pilot Point Airport - United States Of America (PIP)",
    value: "PIP",
  },
  {
    label: "Pilot Point, Ugashik Bay - United States Of America (UGB)",
    value: "UGB",
  },
  {
    label: "Pilot Station, Pilot Station - United States Of America (PQS)",
    value: "PQS",
  },
  {
    label: "Pine Bluff, Grider Field - United States Of America (PBF)",
    value: "PBF",
  },
  {
    label: "Pine Mountain, Harris County - United States Of America (PIM)",
    value: "PIM",
  },
  {
    label: "Pine Ridge, Pine Ridge - United States Of America (XPR)",
    value: "XPR",
  },
  {
    label: "Pinedale, Ralph Wenz Field - United States Of America (PWY)",
    value: "PWY",
  },
  {
    label: "Pittsburg, Atkinson Municipal - United States Of America (PTS)",
    value: "PTS",
  },
  {
    label: "Pittsburgh, Allegheny Cou - United States Of America (AGC)",
    value: "AGC",
  },
  {
    label: "Pittsburgh, Civic Ar Hpt - United States Of America (CVA)",
    value: "CVA",
  },
  {
    label: "Pittsburgh, International - United States Of America (PIT)",
    value: "PIT",
  },
  {
    label: "Pittsfield, Municipal - United States Of America (PSF)",
    value: "PSF",
  },
  {
    label: "Placerville, Placerville - United States Of America (PVF)",
    value: "PVF",
  },
  {
    label: "Plainview, Hale County - United States Of America (PVW)",
    value: "PVW",
  },
  {
    label: "Platinum, Platinum - United States Of America (PTU)",
    value: "PTU",
  },
  {
    label: "Plattsburgh, Plattsburgh Intl Apt - United States Of America (PBG)",
    value: "PBG",
  },
  {
    label:
      "Pleasant Harbour, Pleasant Harbour - United States Of America (PTR)",
    value: "PTR",
  },
  {
    label: "Pleasanton, Hacienda Bus Park Hpt - United States Of America (JBS)",
    value: "JBS",
  },
  {
    label: "Plentywood, Sher Wood - United States Of America (PWD)",
    value: "PWD",
  },
  {
    label: "Plymouth, Municipal - United States Of America (PYM)",
    value: "PYM",
  },
  {
    label: "Plymouth, Municipal - United States Of America (PLY)",
    value: "PLY",
  },
  {
    label: "Pocahontas, Pocahontas Mnpl - United States Of America (POH)",
    value: "POH",
  },
  {
    label: "Pocatello, Pocatello Regional - United States Of America (PIH)",
    value: "PIH",
  },
  {
    label: "Pohakuloa, Bradshaw Aaf - United States Of America (BSF)",
    value: "BSF",
  },
  {
    label: "Point Baker, Point Baker - United States Of America (KPB)",
    value: "KPB",
  },
  {
    label: "Point Hope, Point Hope - United States Of America (PHO)",
    value: "PHO",
  },
  {
    label: "Point Lay, Point Lay - United States Of America (PIZ)",
    value: "PIZ",
  },
  {
    label: "Point Lookout, M Graham Clark - United States Of America (PLK)",
    value: "PLK",
  },
  {
    label: "Point Retreat, Coastguard Hpt - United States Of America (PRT)",
    value: "PRT",
  },
  {
    label: "Polacca, Polacca - United States Of America (PXL)",
    value: "PXL",
  },
  {
    label: "Pompano Beach, Airpark - United States Of America (PPM)",
    value: "PPM",
  },
  {
    label: "Ponca City, Regional - United States Of America (PNC)",
    value: "PNC",
  },
  {
    label: "Pontiac, Oakland County Intl - United States Of America (PTK)",
    value: "PTK",
  },
  {
    label: "Pope Vanoy, Pope Vanoy - United States Of America (PVY)",
    value: "PVY",
  },
  {
    label: "Poplar Bluff, Municipal - United States Of America (POF)",
    value: "POF",
  },
  {
    label: "Porcupine Creek, Porcupine Creek - United States Of America (PCK)",
    value: "PCK",
  },
  {
    label:
      "Port Alexander, Port Alexander Spb - United States Of America (PTD)",
    value: "PTD",
  },
  {
    label: "Port Alice, Port Alice Spb - United States Of America (PTC)",
    value: "PTC",
  },
  {
    label: "Port Alsworth, Port Alsworth - United States Of America (PTA)",
    value: "PTA",
  },
  {
    label: "Port Angeles, Wr Fairchild Intl - United States Of America (CLM)",
    value: "CLM",
  },
  {
    label: "Port Armstrong, Port Armstrong - United States Of America (PTL)",
    value: "PTL",
  },
  {
    label: "Port Bailey, Port Bailey - United States Of America (KPY)",
    value: "KPY",
  },
  {
    label:
      "Port Clarence, Coast Guard Station - United States Of America (KPC)",
    value: "KPC",
  },
  {
    label: "Port Frederick, Port Frederick - United States Of America (PFD)",
    value: "PFD",
  },
  {
    label: "Port Graham, Port Graham - United States Of America (PGM)",
    value: "PGM",
  },
  {
    label: "Port Heiden, Port Heiden - United States Of America (PTH)",
    value: "PTH",
  },
  {
    label: "Port Hueneme, Nas - United States Of America (NTD)",
    value: "NTD",
  },
  {
    label: "Port Huron, Saint Clair Intl - United States Of America (PHN)",
    value: "PHN",
  },
  {
    label: "Port Johnson, Port Johnson - United States Of America (PRF)",
    value: "PRF",
  },
  {
    label: "Port Lions, Port Lions - United States Of America (ORI)",
    value: "ORI",
  },
  {
    label: "Port Moller, Port Moller - United States Of America (PML)",
    value: "PML",
  },
  {
    label: "Port Oceanic, Port Oceanic - United States Of America (PRL)",
    value: "PRL",
  },
  {
    label: "Port Protection, Sea Plane Base - United States Of America (PPV)",
    value: "PPV",
  },
  {
    label: "Port San Juan, Port San Juan - United States Of America (PJS)",
    value: "PJS",
  },
  {
    label:
      "Port Townsend, Jefferson County Intl - United States Of America (TWD)",
    value: "TWD",
  },
  {
    label: "Port Walter, Sea Plane Base - United States Of America (PWR)",
    value: "PWR",
  },
  {
    label: "Port Williams, P Williams - United States Of America (KPR)",
    value: "KPR",
  },
  {
    label: "Portage Creek, Portage Creek - United States Of America (PCA)",
    value: "PCA",
  },
  {
    label: "Porterville, Municipal - United States Of America (PTV)",
    value: "PTV",
  },
  {
    label: "Portland, OR - United States Of America (PDX)",
    value: "PDX",
  },
  {
    label: "Portland, Portland Intl - United States Of America (PDX)",
    value: "PDX",
  },
  {
    label: "Portland, Hillsboro - United States Of America (HIO)",
    value: "HIO",
  },
  {
    label: "Portland, Troutdale - United States Of America (TTD)",
    value: "TTD",
  },
  {
    label: "Portland, ME - United States Of America (PWM)",
    value: "PWM",
  },
  {
    label: "Portland, Portland Intl - United States Of America (PWM)",
    value: "PWM",
  },
  {
    label: "Portsmouth, Regional - United States Of America (PMH)",
    value: "PMH",
  },
  {
    label: "Poteau, Robert S Kerr - United States Of America (RKR)",
    value: "RKR",
  },
  {
    label: "Pottstown, Heritage Field - United States Of America (PTW)",
    value: "PTW",
  },
  {
    label: "Poughkeepsie, Dutchess County Apt - United States Of America (POU)",
    value: "POU",
  },
  {
    label: "Poulsbo, Marina Moorage Spb - United States Of America (PUL)",
    value: "PUL",
  },
  {
    label: "Powell, Powell Municipal - United States Of America (POY)",
    value: "POY",
  },
  {
    label: "Prairie Du Chien, Mncpl - United States Of America (PCD)",
    value: "PCD",
  },
  {
    label: "Pratt, Regional - United States Of America (PTT)",
    value: "PTT",
  },
  {
    label: "Prentice, Prentice - United States Of America (PRW)",
    value: "PRW",
  },
  {
    label: "Prescott, Ernest A Love Field - United States Of America (PRC)",
    value: "PRC",
  },
  {
    label: "Presque Isle, Northern Maine Rgnl - United States Of America (PQI)",
    value: "PQI",
  },
  {
    label: "Price, Carbon County Regional - United States Of America (PUC)",
    value: "PUC",
  },
  {
    label: "Princeton, Municipal - United States Of America (PNN)",
    value: "PNN",
  },
  {
    label: "Princeton, Princeton - United States Of America (PCT)",
    value: "PCT",
  },
  {
    label: "Princeton, Princeton Jet Rail Stn - United States Of America (ZTJ)",
    value: "ZTJ",
  },
  {
    label: "Prineville, Prineville - United States Of America (PRZ)",
    value: "PRZ",
  },
  {
    label: "Prospect Creek, Prospect Creek - United States Of America (PPC)",
    value: "PPC",
  },
  {
    label: "Providence, T F Green State Apt - United States Of America (PVD)",
    value: "PVD",
  },
  {
    label: "Provincetown, Municipal - United States Of America (PVC)",
    value: "PVC",
  },
  {
    label: "Provo, Municipal - United States Of America (PVU)",
    value: "PVU",
  },
  {
    label: "Prudhoe Bay, Prudhoe Bay - United States Of America (PUO)",
    value: "PUO",
  },
  {
    label: "Prudhoe Bay Deadh, Deadhorse - United States Of America (SCC)",
    value: "SCC",
  },
  {
    label:
      "Pseudo City Code, Pseudo City Code - United States Of America (JDK)",
    value: "JDK",
  },
  {
    label: "Pueblo, Memorial - United States Of America (PUB)",
    value: "PUB",
  },
  {
    label: "Pullman, Regional - United States Of America (PUW)",
    value: "PUW",
  },
  {
    label: "Punta Gorda, Punta Gorda - United States Of America (PGD)",
    value: "PGD",
  },
  {
    label: "Quakertown, Quakertown - United States Of America (UKT)",
    value: "UKT",
  },
  {
    label: "Quantico, Mcaf  Turner Field) - United States Of America (NYG)",
    value: "NYG",
  },
  {
    label: "Queen, Queen - United States Of America (UQE)",
    value: "UQE",
  },
  {
    label: "Quillayute, Quillayute - United States Of America (UIL)",
    value: "UIL",
  },
  {
    label: "Quincy, Gansner Field - United States Of America (GNF)",
    value: "GNF",
  },
  {
    label: "Quincy, Quincy - United States Of America (MQI)",
    value: "MQI",
  },
  {
    label: "Quincy, Regional  Baldwin Fld) - United States Of America (UIN)",
    value: "UIN",
  },
  {
    label: "Quinhagak, Quinhagak - United States Of America (KWN)",
    value: "KWN",
  },
  {
    label: "Quonset Point, Quonset State - United States Of America (NCO)",
    value: "NCO",
  },
  {
    label: "Racine, John H.Batten - United States Of America (RAC)",
    value: "RAC",
  },
  {
    label: "Rail Generic, Rail Generic - United States Of America (XZU)",
    value: "XZU",
  },
  {
    label: "Raleigh Durham, International - United States Of America (RDU)",
    value: "RDU",
  },
  {
    label: "Rampart, Rampart - United States Of America (RMP)",
    value: "RMP",
  },
  {
    label: "Rancho, French Valley - United States Of America (RBK)",
    value: "RBK",
  },
  {
    label: "Rangely, International - United States Of America (RNG)",
    value: "RNG",
  },
  {
    label: "Ranger, Municipal - United States Of America (RGR)",
    value: "RGR",
  },
  {
    label: "Rapid City, Ellsworth Afb - United States Of America (RCA)",
    value: "RCA",
  },
  {
    label: "Rapid City, Regional - United States Of America (RAP)",
    value: "RAP",
  },
  {
    label:
      "Raspberry Strait, Raspberry Strait - United States Of America (RSP)",
    value: "RSP",
  },
  {
    label: "Raton, Municipal Crews Fld - United States Of America (RTN)",
    value: "RTN",
  },
  {
    label: "Rawlins, Municipal Harvey Fld - United States Of America (RWL)",
    value: "RWL",
  },
  {
    label: "Reading, Regional - United States Of America (RDG)",
    value: "RDG",
  },
  {
    label: "Red Bluff, Municipal - United States Of America (RBL)",
    value: "RBL",
  },
  {
    label: "Red Devil, Red Devil - United States Of America (RDV)",
    value: "RDV",
  },
  {
    label: "Red Dog, Red Dog - United States Of America (RDB)",
    value: "RDB",
  },
  {
    label: "Redding, Benton Field - United States Of America (BZF)",
    value: "BZF",
  },
  {
    label: "Redding, Municipal - United States Of America (RDD)",
    value: "RDD",
  },
  {
    label: "Redmond, Roberts Field - United States Of America (RDM)",
    value: "RDM",
  },
  {
    label: "Redwood Falls, Municipal - United States Of America (RWF)",
    value: "RWF",
  },
  {
    label: "Reed City, Nartron Field - United States Of America (RCT)",
    value: "RCT",
  },
  {
    label: "Reedsville, Mifflin Cnty - United States Of America (RED)",
    value: "RED",
  },
  {
    label: "Refugio, Rooke Field - United States Of America (RFG)",
    value: "RFG",
  },
  {
    label: "Refugio, Tom O'connor - United States Of America (VDU)",
    value: "VDU",
  },
  {
    label: "Rehoboth Beach, Rehoboth Beach - United States Of America (REH)",
    value: "REH",
  },
  {
    label: "Reno, Tahoe Intl - United States Of America (RNO)",
    value: "RNO",
  },
  {
    label: "Rensselaer, Jasper County - United States Of America (RNZ)",
    value: "RNZ",
  },
  {
    label: "Renton, Municipal - United States Of America (RNT)",
    value: "RNT",
  },
  {
    label: "Rexburg, Madison County - United States Of America (RXE)",
    value: "RXE",
  },
  {
    label: "Rhinelander, Oneida Coun - United States Of America (RHI)",
    value: "RHI",
  },
  {
    label: "Rice Lake, Regional-Carl's Field - United States Of America (RIE)",
    value: "RIE",
  },
  {
    label: "Richfield, Municipal - United States Of America (RIF)",
    value: "RIF",
  },
  {
    label: "Richland, Richland - United States Of America (RLD)",
    value: "RLD",
  },
  {
    label: "Richmond,VA - United States Of America (RIC)",
    value: "RIC",
  },
  {
    label: "Richmond, International - United States Of America (RIC)",
    value: "RIC",
  },
  {
    label: "Richmond, IN - United States Of America (RID)",
    value: "RID",
  },
  {
    label: "Richmond, Municipal - United States Of America (RID)",
    value: "RID",
  },
  {
    label: "Rifle, Garfield County Rgnl - United States Of America (RIL)",
    value: "RIL",
  },
  {
    label: "Riverside, Flabob - United States Of America (RIR)",
    value: "RIR",
  },
  {
    label: "Riverside, March Jarb - United States Of America (RIV)",
    value: "RIV",
  },
  {
    label: "Riverside, Metro Center Heliport - United States Of America (JRD)",
    value: "JRD",
  },
  {
    label: "Riverside, Riverside - United States Of America (RAL)",
    value: "RAL",
  },
  {
    label: "Riverton, Regional - United States Of America (RIW)",
    value: "RIW",
  },
  {
    label: "Roanoke, Regional  Woodrum Fld) - United States Of America (ROA)",
    value: "ROA",
  },
  {
    label:
      "Roanoke Rapids, Halifax-Northampton - United States Of America (RZZ)",
    value: "RZZ",
  },
  {
    label: "Roche Harbor, Sea Plane Base - United States Of America (RCE)",
    value: "RCE",
  },
  {
    label: "Rochester, Fulton County - United States Of America (RCR)",
    value: "RCR",
  },
  {
    label: "Rochester, Greater Rochester Intl - United States Of America (ROC)",
    value: "ROC",
  },
  {
    label: "Rochester, International - United States Of America (RST)",
    value: "RST",
  },
  {
    label: "Rochester, Rochester Mnpl Hpt - United States Of America (JRC)",
    value: "JRC",
  },
  {
    label: "Rock Hill, York County-Bryant Fld - United States Of America (RKH)",
    value: "RKH",
  },
  {
    label: "Rock Springs, Sweetwater - United States Of America (RKS)",
    value: "RKS",
  },
  {
    label: "Rockdale, Hh Coffield Regional - United States Of America (RCK)",
    value: "RCK",
  },
  {
    label: "Rockland, Knox County Reg - United States Of America (RKD)",
    value: "RKD",
  },
  {
    label: "Rockport, Aransas County - United States Of America (RKP)",
    value: "RKP",
  },
  {
    label: "Rockwood, Rockwood Mnpl - United States Of America (RKW)",
    value: "RKW",
  },
  {
    label: "Rocky Mount, Wilson Regional - United States Of America (RWI)",
    value: "RWI",
  },
  {
    label: "Rogers, Municipal Carter Fld - United States Of America (ROG)",
    value: "ROG",
  },
  {
    label: "Rolla, National - United States Of America (RLA)",
    value: "RLA",
  },
  {
    label: "Roma, Falcon State - United States Of America (FAL)",
    value: "FAL",
  },
  {
    label: "Rome, Griffiss Intl - United States Of America (RME)",
    value: "RME",
  },
  {
    label: "Rome, Richard B Russell Rgnl - United States Of America (RMG)",
    value: "RMG",
  },
  {
    label: "Rome, State - United States Of America (REO)",
    value: "REO",
  },
  {
    label: "Roosevelt, Municipal - United States Of America (ROL)",
    value: "ROL",
  },
  {
    label: "Rosario, Rosario Spb - United States Of America (RSJ)",
    value: "RSJ",
  },
  {
    label: "Roseau, Roseau Mnpl - United States Of America (ROX)",
    value: "ROX",
  },
  {
    label: "Roseburg, Regional - United States Of America (RBG)",
    value: "RBG",
  },
  {
    label: "Roswell, Intl Air Center - United States Of America (ROW)",
    value: "ROW",
  },
  {
    label: "Rotunda, Rotunda Heliport - United States Of America (RTD)",
    value: "RTD",
  },
  {
    label: "Roundup, Roundup - United States Of America (RPX)",
    value: "RPX",
  },
  {
    label: "Rouses Point, Seaplane Base - United States Of America (RSX)",
    value: "RSX",
  },
  {
    label: "Rowan Bay, Rowan Bay - United States Of America (RWB)",
    value: "RWB",
  },
  {
    label: "Ruby, Ruby - United States Of America (RBY)",
    value: "RBY",
  },
  {
    label: "Ruidoso, Sierra Blanca Regional - United States Of America (RUI)",
    value: "RUI",
  },
  {
    label: "Russell, Municipal - United States Of America (RSL)",
    value: "RSL",
  },
  {
    label: "Russian Mission, Russian Mission - United States Of America (RSH)",
    value: "RSH",
  },
  {
    label: "Ruston, Regional - United States Of America (RSN)",
    value: "RSN",
  },
  {
    label: "Rutland, Southern Vermont Rgnl - United States Of America (RUT)",
    value: "RUT",
  },
  {
    label: "Sacramento, Executive - United States Of America (SAC)",
    value: "SAC",
  },
  {
    label: "Sacramento, Mather - United States Of America (MHR)",
    value: "MHR",
  },
  {
    label: "Sacramento, Mcclellan - United States Of America (MCC)",
    value: "MCC",
  },
  {
    label: "Sacramento, Stockton Metropolitan - United States Of America (SCK)",
    value: "SCK",
  },
  {
    label: "Safford, Regional - United States Of America (SAD)",
    value: "SAD",
  },
  {
    label: "Saginaw, Mbs Intl - United States Of America (MBS)",
    value: "MBS",
  },
  {
    label: "Saginaw Bay, Saginaw Spb - United States Of America (SGW)",
    value: "SGW",
  },
  {
    label: "Sagwon, Sagwon - United States Of America (SAG)",
    value: "SAG",
  },
  {
    label:
      "Saint Augustine, Northeast Florida Rgnl - United States Of America (UST)",
    value: "UST",
  },
  {
    label: "Saint Cloud, Mncpl Hpt - United States Of America (JSK)",
    value: "JSK",
  },
  {
    label: "Saint Cloud, Regional - United States Of America (STC)",
    value: "STC",
  },
  {
    label: "Saint George, Municipal - United States Of America (SGU)",
    value: "SGU",
  },
  {
    label:
      "Saint George Island, Saint George Island - United States Of America (STG)",
    value: "STG",
  },
  {
    label: "Saint Johns, Industrial Air Park - United States Of America (SJN)",
    value: "SJN",
  },
  {
    label: "Saint Joseph, Rosecrans Mem - United States Of America (STJ)",
    value: "STJ",
  },
  {
    label: "Saint Louis, Downtown Airport - United States Of America (CPS)",
    value: "CPS",
  },
  {
    label: "Saint Louis, Lambert - United States Of America (STL)",
    value: "STL",
  },
  {
    label: "Saint Louis, Spirit Of - United States Of America (SUS)",
    value: "SUS",
  },
  {
    label: "Saint Marys, Municipal - United States Of America (STQ)",
    value: "STQ",
  },
  {
    label: "Saint Marys, Saint Marys - United States Of America (XSM)",
    value: "XSM",
  },
  {
    label: "Saint Marys, Saint Mary's - United States Of America (KSM)",
    value: "KSM",
  },
  {
    label: "Saint Michael, Saint Michael - United States Of America (SMK)",
    value: "SMK",
  },
  {
    label:
      "Saint Paul Island, Saint Paul Island - United States Of America (SNP)",
    value: "SNP",
  },
  {
    label: "Salem, Leckrone - United States Of America (SLO)",
    value: "SLO",
  },
  {
    label: "Salem, Mcnary Field - United States Of America (SLE)",
    value: "SLE",
  },
  {
    label: "Salida, Harriet Alexander Fld - United States Of America (SLT)",
    value: "SLT",
  },
  {
    label: "Salina, Regional - United States Of America (SLN)",
    value: "SLN",
  },
  {
    label: "Salina, Salina Gunnison - United States Of America (SBO)",
    value: "SBO",
  },
  {
    label: "Salinas, Municipal - United States Of America (SNS)",
    value: "SNS",
  },
  {
    label: "Salisbury, Ocean City Municipal - United States Of America (OCE)",
    value: "OCE",
  },
  {
    label: "Salisbury, Rowan County - United States Of America (SRW)",
    value: "SRW",
  },
  {
    label: "Salisbury, Wicomico Regional - United States Of America (SBY)",
    value: "SBY",
  },
  {
    label: "Salmon, Lemhi County - United States Of America (SMN)",
    value: "SMN",
  },
  {
    label:
      "Salt Lake City, Salt Lake City Intl - United States Of America (SLC)",
    value: "SLC",
  },
  {
    label: "Salton City, Salton Sea - United States Of America (SAS)",
    value: "SAS",
  },
  {
    label: "San Angelo, Goodfellow Af - United States Of America (GOF)",
    value: "GOF",
  },
  {
    label: "San Angelo, Regional Mathis Fld - United States Of America (SJT)",
    value: "SJT",
  },
  {
    label:
      "San Antonio, Lackland Afb Kelly Fld - United States Of America (SKF)",
    value: "SKF",
  },
  {
    label: "San Antonio, Martindale Ahp - United States Of America (MDA)",
    value: "MDA",
  },
  {
    label: "San Antonio, Randolph Afb - United States Of America (RND)",
    value: "RND",
  },
  {
    label: "San Antonio, San Antonio Intl - United States Of America (SAT)",
    value: "SAT",
  },
  {
    label: "San Antonio, Stinson Mun - United States Of America (SSF)",
    value: "SSF",
  },
  {
    label: "San Bernardino, International - United States Of America (SBD)",
    value: "SBD",
  },
  {
    label: "San Carlos, San Carlos - United States Of America (SQL)",
    value: "SQL",
  },
  {
    label: "San Diego, Brown Field - United States Of America (SDM)",
    value: "SDM",
  },
  {
    label: "San Diego, Gillespie - United States Of America (SEE)",
    value: "SEE",
  },
  {
    label: "San Diego, Mc Clellan Palomar - United States Of America (CLD)",
    value: "CLD",
  },
  {
    label: "San Diego, Miramar Mcas - United States Of America (NKX)",
    value: "NKX",
  },
  {
    label: "San Diego, Montgomery Fld - United States Of America (MYF)",
    value: "MYF",
  },
  {
    label: "San Diego, North Island Nas - United States Of America (NZY)",
    value: "NZY",
  },
  {
    label: "San Fernando, San Fernando - United States Of America (SFR)",
    value: "SFR",
  },
  {
    label: "San Francisco, China Hpt - United States Of America (JCC)",
    value: "JCC",
  },
  {
    label: "San Francisco, Embarkader - United States Of America (EMB)",
    value: "EMB",
  },
  {
    label: "San Jose, Reid Hillview - United States Of America (RHV)",
    value: "RHV",
  },
  {
    label: "San Jose, San Jose Mnpl - United States Of America (SJC)",
    value: "SJC",
  },
  {
    label: "San Juan, San Juan Spb - United States Of America (WSJ)",
    value: "WSJ",
  },
  {
    label: "San Luis Obispo, O'sullivan Ahp - United States Of America (CSL)",
    value: "CSL",
  },
  {
    label:
      "San Luis Obispo, San Luis County Rgnl - United States Of America (SBP)",
    value: "SBP",
  },
  {
    label: "San Miguel, Roberts Ahp - United States Of America (SYL)",
    value: "SYL",
  },
  {
    label: "San Pedro, Catalina Air Sea Hp - United States Of America (SPQ)",
    value: "SPQ",
  },
  {
    label: "San Rafael, Airport - United States Of America (SRF)",
    value: "SRF",
  },
  {
    label: "San Rafael, Heliport - United States Of America (JSG)",
    value: "JSG",
  },
  {
    label: "Sand Point, Sand Point - United States Of America (SDP)",
    value: "SDP",
  },
  {
    label: "Sandusky, Griffing - United States Of America (SKY)",
    value: "SKY",
  },
  {
    label: "Sanford, Seacoast Regional - United States Of America (SFM)",
    value: "SFM",
  },
  {
    label: "Santa Ana, Downtown Hpt - United States Of America (DNT)",
    value: "DNT",
  },
  {
    label: "Santa Ana, Heliport - United States Of America (JOC)",
    value: "JOC",
  },
  {
    label: "Santa Ana, John Wayne - United States Of America (SNA)",
    value: "SNA",
  },
  {
    label: "Santa Cruz, Skypark - United States Of America (SRU)",
    value: "SRU",
  },
  {
    label:
      "Santa Cruz Island, Santa Cruz Island - United States Of America (SZN)",
    value: "SZN",
  },
  {
    label: "Santa Fe, NM - United States Of America (SAF)",
    value: "SAF",
  },
  {
    label: "Santa Fe, Santa Fe - United States Of America (SAF)",
    value: "SAF",
  },
  {
    label: "Santa Maria, Public Apt - United States Of America (SMX)",
    value: "SMX",
  },
  {
    label: "Santa Monica, Municipal - United States Of America (SMO)",
    value: "SMO",
  },
  {
    label: "Santa Paula, Santa Paula - United States Of America (SZP)",
    value: "SZP",
  },
  {
    label: "Santa Rosa, Charles M Schulz - United States Of America (STS)",
    value: "STS",
  },
  {
    label: "Santa Ynez, Santa Ynez - United States Of America (SQA)",
    value: "SQA",
  },
  {
    label: "Saranac Lake, Adirondack Regional - United States Of America (SLK)",
    value: "SLK",
  },
  {
    label: "Sarasota, International - United States Of America (SRQ)",
    value: "SRQ",
  },
  {
    label: "Saratoga, Shively Field - United States Of America (SAA)",
    value: "SAA",
  },
  {
    label: "Sarichef, Cape Sarichef - United States Of America (WSF)",
    value: "WSF",
  },
  {
    label:
      "Sault Ste Marie, Chippewa County Intl - United States Of America (CIU)",
    value: "CIU",
  },
  {
    label: "Sault Ste Marie, Kinchelo - United States Of America (INR)",
    value: "INR",
  },
  {
    label: "Sausalito, Commodore Center Hp - United States Of America (JMC)",
    value: "JMC",
  },
  {
    label: "Savannah, Hilton Head Intl - United States Of America (SAV)",
    value: "SAV",
  },
  {
    label: "Savannah, Hunter Aaf - United States Of America (SVN)",
    value: "SVN",
  },
  {
    label: "Savoonga, Savoonga - United States Of America (SVA)",
    value: "SVA",
  },
  {
    label: "Scammon Bay, Scammon Bay - United States Of America (SCM)",
    value: "SCM",
  },
  {
    label: "Schaumburg, Marriott Hpt - United States Of America (JMH)",
    value: "JMH",
  },
  {
    label: "Schenectady, Schenectady County - United States Of America (SCH)",
    value: "SCH",
  },
  {
    label:
      "Scottsbluff, Western Nebraska Rgnl - United States Of America (BFF)",
    value: "BFF",
  },
  {
    label: "Scranton, International - United States Of America (AVP)",
    value: "AVP",
  },
  {
    label: "Scranton, Scranton Mnpl - United States Of America (SCR)",
    value: "SCR",
  },
  {
    label: "Scranton, Wyoming Val - United States Of America (WBW)",
    value: "WBW",
  },
  {
    label: "Scribner, State - United States Of America (SCB)",
    value: "SCB",
  },
  {
    label: "Seal Bay, Sea Plane Base - United States Of America (SYB)",
    value: "SYB",
  },
  {
    label: "Searcy, Municipal - United States Of America (SRC)",
    value: "SRC",
  },
  {
    label: "Seattle, WA - United States Of America (SEA)",
    value: "SEA",
  },
  {
    label: "Seattle, Seattle Tacoma Intl - United States Of America (SEA)",
    value: "SEA",
  },
  {
    label: "Seattle, Boeing Fld King Co Int - United States Of America (BFI)",
    value: "BFI",
  },
  {
    label: "Seattle, Lake Union Spb - United States Of America (LKE)",
    value: "LKE",
  },
  {
    label: "Sebring, Regional - United States Of America (SEF)",
    value: "SEF",
  },
  {
    label: "Sedalia, Regional - United States Of America (DMO)",
    value: "DMO",
  },
  {
    label: "Sedona, Sedona - United States Of America (SDX)",
    value: "SDX",
  },
  {
    label: "Selawik, Selawik - United States Of America (WLK)",
    value: "WLK",
  },
  {
    label: "Seldovia, Seldovia - United States Of America (SOV)",
    value: "SOV",
  },
  {
    label: "Selinsgrove, Penn Valley - United States Of America (SEG)",
    value: "SEG",
  },
  {
    label: "Selma, Craig Field - United States Of America (SEM)",
    value: "SEM",
  },
  {
    label: "Selma, Selfield - United States Of America (SES)",
    value: "SES",
  },
  {
    label: "Sequim, Sequim Valley Apt - United States Of America (SQV)",
    value: "SQV",
  },
  {
    label: "Sewanee, Franklin County - United States Of America (UOS)",
    value: "UOS",
  },
  {
    label: "Seward, Seward - United States Of America (SWD)",
    value: "SWD",
  },
  {
    label: "Seymour, Freeman Mncpl - United States Of America (SER)",
    value: "SER",
  },
  {
    label: "Shafter, Minter Field - United States Of America (MIT)",
    value: "MIT",
  },
  {
    label: "Shageluk, Shageluk - United States Of America (SHX)",
    value: "SHX",
  },
  {
    label: "Shaktoolik, Shaktoolik - United States Of America (SKK)",
    value: "SKK",
  },
  {
    label: "Shangri La, Grand Lake Regional - United States Of America (NRI)",
    value: "NRI",
  },
  {
    label: "Shawnee, Regional - United States Of America (SNL)",
    value: "SNL",
  },
  {
    label: "Sheboygan, Sheboygan County Mem. - United States Of America (SBM)",
    value: "SBM",
  },
  {
    label: "Sheep Mountain, Sheep Mountain - United States Of America (SMU)",
    value: "SMU",
  },
  {
    label: "Shelby, Shelby - United States Of America (SBX)",
    value: "SBX",
  },
  {
    label:
      "Shelbyville, Municipal-Bomar Field - United States Of America (SYI)",
    value: "SYI",
  },
  {
    label: "Sheldon Point, Sheldon Point - United States Of America (SXP)",
    value: "SXP",
  },
  {
    label: "Shelton, Sanderson Field - United States Of America (SHN)",
    value: "SHN",
  },
  {
    label: "Shemya, Eareckson Air Station - United States Of America (SYA)",
    value: "SYA",
  },
  {
    label: "Sheridan, Sheridan County - United States Of America (SHR)",
    value: "SHR",
  },
  {
    label: "Sherman Denison, North Texas - United States Of America (PNX)",
    value: "PNX",
  },
  {
    label: "Shirley, Brookhaven - United States Of America (WSH)",
    value: "WSH",
  },
  {
    label: "Shishmaref, Shishmaref - United States Of America (SHH)",
    value: "SHH",
  },
  {
    label: "Shoal Cove, Shoal Cove - United States Of America (HCB)",
    value: "HCB",
  },
  {
    label: "Show Low, Regional - United States Of America (SOW)",
    value: "SOW",
  },
  {
    label: "Shreveport, Barksdale Afb - United States Of America (BAD)",
    value: "BAD",
  },
  {
    label: "Shreveport, Downtown Apt - United States Of America (DTN)",
    value: "DTN",
  },
  {
    label: "Shreveport, Regional - United States Of America (SHV)",
    value: "SHV",
  },
  {
    label: "Shungnak, Shungnak - United States Of America (SHG)",
    value: "SHG",
  },
  {
    label: "Sidney, Municipal - United States Of America (SXY)",
    value: "SXY",
  },
  {
    label: "Sidney, Municipal - United States Of America (SNY)",
    value: "SNY",
  },
  {
    label: "Sidney, Richland Mncpl - United States Of America (SDY)",
    value: "SDY",
  },
  {
    label: "Sikeston, Memorial Municipal - United States Of America (SIK)",
    value: "SIK",
  },
  {
    label: "Siloam Springs, Smith Fld - United States Of America (SLG)",
    value: "SLG",
  },
  {
    label: "Silver City, Grant County - United States Of America (SVC)",
    value: "SVC",
  },
  {
    label: "Sioux City, Sioux Gateway - United States Of America (SUX)",
    value: "SUX",
  },
  {
    label: "Sioux Falls, Joe Foss Field - United States Of America (FSD)",
    value: "FSD",
  },
  {
    label: "Sitka, Rocky Gutierrez - United States Of America (SIT)",
    value: "SIT",
  },
  {
    label: "Sitkinak Island, Sitkinak - United States Of America (SKJ)",
    value: "SKJ",
  },
  {
    label: "Skagway, Skagway - United States Of America (SGY)",
    value: "SGY",
  },
  {
    label: "Skwentna, Skwentna - United States Of America (SKW)",
    value: "SKW",
  },
  {
    label: "Slayton, Municipal - United States Of America (NSL)",
    value: "NSL",
  },
  {
    label: "Sleetmute, Sleetmute - United States Of America (SLQ)",
    value: "SLQ",
  },
  {
    label: "Smith Cove, Smith Cove - United States Of America (SCJ)",
    value: "SCJ",
  },
  {
    label: "Smithfield, North Central State - United States Of America (SFZ)",
    value: "SFZ",
  },
  {
    label: "Smyrna, Smyrna - United States Of America (MQY)",
    value: "MQY",
  },
  {
    label: "Snyder, Winston Field - United States Of America (SNK)",
    value: "SNK",
  },
  {
    label: "Socorro, Municipal - United States Of America (ONM)",
    value: "ONM",
  },
  {
    label: "Soldotna, Soldotna - United States Of America (SXQ)",
    value: "SXQ",
  },
  {
    label: "Solomon, State Field - United States Of America (SOL)",
    value: "SOL",
  },
  {
    label: "Somerset, Lake Cumberland Rgnl - United States Of America (SME)",
    value: "SME",
  },
  {
    label: "South Bend, International - United States Of America (SBN)",
    value: "SBN",
  },
  {
    label: "South Naknek, South Naknek - United States Of America (WSN)",
    value: "WSN",
  },
  {
    label: "South Weymouth, South Weymouth - United States Of America (NZW)",
    value: "NZW",
  },
  {
    label: "Southern Pines, Moore County - United States Of America (SOP)",
    value: "SOP",
  },
  {
    label: "Sparrevohn, Sparrevohn - United States Of America (SVW)",
    value: "SVW",
  },
  {
    label: "Sparta, Fort Mccoy - United States Of America (CMY)",
    value: "CMY",
  },
  {
    label: "Sparta, Sparta Community - United States Of America (SAR)",
    value: "SAR",
  },
  {
    label: "Spearfish, Black Hills - United States Of America (SPF)",
    value: "SPF",
  },
  {
    label: "Spencer, Spencer Mnpl - United States Of America (SPW)",
    value: "SPW",
  },
  {
    label: "Spirit Lake, Municipal - United States Of America (RTL)",
    value: "RTL",
  },
  {
    label: "Spokane, WA - United States Of America (GEG)",
    value: "GEG",
  },
  {
    label: "Spokane, Spokane Intl - United States Of America (GEG)",
    value: "GEG",
  },
  {
    label: "Spokane, Fairchild Afb - United States Of America (SKA)",
    value: "SKA",
  },
  {
    label: "Spokane, Felts Field - United States Of America (SFF)",
    value: "SFF",
  },
  {
    label: "Springfield, IL - United States Of America (SPI)",
    value: "SPI",
  },
  {
    label: "Springfield, A Lincoln Capital - United States Of America (SPI)",
    value: "SPI",
  },
  {
    label: "Springfield, OH - United States Of America (SGH)",
    value: "SGH",
  },
  {
    label: "Springfield, Beckley Municipal - United States Of America (SGH)",
    value: "SGH",
  },
  {
    label: "Springfield, VT - United States Of America (VSF)",
    value: "VSF",
  },
  {
    label: "Springfield, Hartness State - United States Of America (VSF)",
    value: "VSF",
  },
  {
    label: "Springfield, MA - United States Of America (SFY)",
    value: "SFY",
  },
  {
    label: "Springfield, Springfield - United States Of America (SFY)",
    value: "SFY",
  },
  {
    label:
      "Springfield, Westover Metropolitan - United States Of America (CEF)",
    value: "CEF",
  },
  {
    label: "Springfield, MO - United States Of America (SGF)",
    value: "SGF",
  },
  {
    label:
      "Springfield, Springfld Branson Natl - United States Of America (SGF)",
    value: "SGF",
  },
  {
    label: "Stanton, Stanton - United States Of America (SYN)",
    value: "SYN",
  },
  {
    label: "State College, United States Of America (SCE)",
    value: "SCE",
  },
  {
    label: "State College, University Park - United States Of America (SCE)",
    value: "SCE",
  },
  {
    label: "Statesboro, United States Of America (TBR)",
    value: "TBR",
  },
  {
    label: "Statesboro, Bulloch County - United States Of America (TBR)",
    value: "TBR",
  },
  {
    label: "Statesville, United States Of America (SVH)",
    value: "SVH",
  },
  {
    label: "Statesville, Regional - United States Of America (SVH)",
    value: "SVH",
  },
  {
    label: "Staunton, United States Of America (SHD)",
    value: "SHD",
  },
  {
    label: "Staunton, Shenandoah Valley Rgnl - United States Of America (SHD)",
    value: "SHD",
  },
  {
    label: "Steamboat Bay, United States Of America (WSB)",
    value: "WSB",
  },
  {
    label: "Steamboat Bay, Spb - United States Of America (WSB)",
    value: "WSB",
  },
  {
    label: "Steamboat Springs, United States Of America (SBS)",
    value: "SBS",
  },
  {
    label:
      "Steamboat Springs, Bob Adams Field - United States Of America (SBS)",
    value: "SBS",
  },
  {
    label: "Stebbins, United States Of America (WBB)",
    value: "WBB",
  },
  {
    label: "Stebbins, Stebbins - United States Of America (WBB)",
    value: "WBB",
  },
  {
    label: "Stephenville, United States Of America (SEP)",
    value: "SEP",
  },
  {
    label:
      "Stephenville, Clark Field Regional - United States Of America (SEP)",
    value: "SEP",
  },
  {
    label: "Sterling, Sterling Municipal - United States Of America (STK)",
    value: "STK",
  },
  {
    label:
      "Sterling Rockfalls, Whiteside County - United States Of America (SQI)",
    value: "SQI",
  },
  {
    label: "Stevens Point, Municipal - United States Of America (STE)",
    value: "STE",
  },
  {
    label: "Stevens Village, Stevens Village - United States Of America (SVS)",
    value: "SVS",
  },
  {
    label: "Stillwater, Regional - United States Of America (SWO)",
    value: "SWO",
  },
  {
    label: "Stony River, Stony River - United States Of America (SRV)",
    value: "SRV",
  },
  {
    label: "Storm Lake, Storm Lake Mnpl - United States Of America (SLB)",
    value: "SLB",
  },
  {
    label: "Stow, Minute Man Airfield - United States Of America (MMN)",
    value: "MMN",
  },
  {
    label: "Stowe, State - United States Of America (MVL)",
    value: "MVL",
  },
  {
    label: "Stratford, Sikorsky Hpt - United States Of America (JSD)",
    value: "JSD",
  },
  {
    label: "Stroud, Municipal - United States Of America (SUD)",
    value: "SUD",
  },
  {
    label: "Stuart, Witham Field - United States Of America (SUA)",
    value: "SUA",
  },
  {
    label: "Stuart Island, Airpark - United States Of America (SSW)",
    value: "SSW",
  },
  {
    label:
      "Sturgeon Bay, Door County Cherryland - United States Of America (SUE)",
    value: "SUE",
  },
  {
    label: "Sturgis, Kirsch Mncpl - United States Of America (IRS)",
    value: "IRS",
  },
  {
    label: "Stuttgart, Municipal - United States Of America (SGT)",
    value: "SGT",
  },
  {
    label: "Sugar Land, Mncpl - United States Of America (SGR)",
    value: "SGR",
  },
  {
    label: "Sullivan, Sullivan County - United States Of America (SIV)",
    value: "SIV",
  },
  {
    label: "Sulphur Springs, Municipal - United States Of America (SLR)",
    value: "SLR",
  },
  {
    label: "Summit, Summit - United States Of America (UMM)",
    value: "UMM",
  },
  {
    label: "Sumter, Shaw Afb - United States Of America (SSC)",
    value: "SSC",
  },
  {
    label: "Sumter, Sumter Apt - United States Of America (SUM)",
    value: "SUM",
  },
  {
    label: "Sun River, Sunriver - United States Of America (SUO)",
    value: "SUO",
  },
  {
    label: "Sundance, Schloredt - United States Of America (SUC)",
    value: "SUC",
  },
  {
    label: "Susanville, Municipal - United States Of America (SVE)",
    value: "SVE",
  },
  {
    label: "Sweetwater, Avenger Field - United States Of America (SWW)",
    value: "SWW",
  },
  {
    label: "Sylvester, Sylvester - United States Of America (SYV)",
    value: "SYV",
  },
  {
    label: "Syracuse, Hancock Intl - United States Of America (SYR)",
    value: "SYR",
  },
  {
    label: "Tacoma, Gray Aaf - United States Of America (GRF)",
    value: "GRF",
  },
  {
    label: "Tacoma, Mcchord Field - United States Of America (TCM)",
    value: "TCM",
  },
  {
    label: "Tacoma, Tacoma Narrows - United States Of America (TIW)",
    value: "TIW",
  },
  {
    label: "Tahneta Pass Lodg, Tahnet - United States Of America (HNE)",
    value: "HNE",
  },
  {
    label: "Takotna, Takotna - United States Of America (TCT)",
    value: "TCT",
  },
  {
    label: "Taku Lodge, Taku Spb - United States Of America (TKL)",
    value: "TKL",
  },
  {
    label: "Talkeetna, Talkeetna - United States Of America (TKA)",
    value: "TKA",
  },
  {
    label: "Talladega, Municipal - United States Of America (ASN)",
    value: "ASN",
  },
  {
    label: "Tampa, United States Of America (TPA)",
    value: "TPA",
  },
  {
    label: "Tampa, Tampa Intl - United States Of America (TPA)",
    value: "TPA",
  },
  {
    label: "Tampa, Clearwater Air Park - United States Of America (CLW)",
    value: "CLW",
  },
  {
    label: "Tampa, Macdill Afb - United States Of America (MCF)",
    value: "MCF",
  },
  {
    label: "Tampa, North Aero Park - United States Of America (KYO)",
    value: "KYO",
  },
  {
    label: "Tampa, Peter O.Knight - United States Of America (TPF)",
    value: "TPF",
  },
  {
    label: "Tampa, St Pete-Albert Whitted - United States Of America (SPG)",
    value: "SPG",
  },
  {
    label: "Tampa, St.Pete-Clearwater Int - United States Of America (PIE)",
    value: "PIE",
  },
  {
    label: "Tanacross, Tanacross - United States Of America (TSG)",
    value: "TSG",
  },
  {
    label: "Tanalian Point, Tanalian Point - United States Of America (TPO)",
    value: "TPO",
  },
  {
    label: "Tanana, Ralph M Calhum Mem - United States Of America (TAL)",
    value: "TAL",
  },
  {
    label: "Taos, Regional - United States Of America (TSM)",
    value: "TSM",
  },
  {
    label: "Tatalina, Tatalina - United States Of America (TLJ)",
    value: "TLJ",
  },
  {
    label: "Tatitlek, Tatitlek - United States Of America (TEK)",
    value: "TEK",
  },
  {
    label: "Taylor, Taylor - United States Of America (TWE)",
    value: "TWE",
  },
  {
    label: "Taylor, Taylor - United States Of America (TYZ)",
    value: "TYZ",
  },
  {
    label: "Tehachapi, Municipal - United States Of America (TSP)",
    value: "TSP",
  },
  {
    label: "Telida, Telida - United States Of America (TLF)",
    value: "TLF",
  },
  {
    label: "Teller, Teller - United States Of America (TLA)",
    value: "TLA",
  },
  {
    label: "Teller Mission, Brevig Mission - United States Of America (KTS)",
    value: "KTS",
  },
  {
    label: "Telluride, Telluride Bus - United States Of America (ZTL)",
    value: "ZTL",
  },
  {
    label: "Telluride, Telluride Municipal - United States Of America (TEX)",
    value: "TEX",
  },
  {
    label: "Temple, Draughon Miller - United States Of America (TPL)",
    value: "TPL",
  },
  {
    label: "Tenakee Springs, Tenakee Spb - United States Of America (TKE)",
    value: "TKE",
  },
  {
    label: "Terre Haute, Intl Hulman Field - United States Of America (HUF)",
    value: "HUF",
  },
  {
    label: "Terrell, Municipal - United States Of America (TRL)",
    value: "TRL",
  },
  {
    label: "Teterboro, Teterboro - United States Of America (TEB)",
    value: "TEB",
  },
  {
    label: "Tetlin, Tetlin - United States Of America (TEH)",
    value: "TEH",
  },
  {
    label: "Texarkana, Regional Webb Field - United States Of America (TXK)",
    value: "TXK",
  },
  {
    label: "The Dalles, Columbia Gorge Rgnl - United States Of America (DLS)",
    value: "DLS",
  },
  {
    label: "Thermal, J Cochran Regional - United States Of America (TRM)",
    value: "TRM",
  },
  {
    label: "Thermopolis, Hot Springs County - United States Of America (THP)",
    value: "THP",
  },
  {
    label: "Thief River Falls, Reg - United States Of America (TVF)",
    value: "TVF",
  },
  {
    label: "Thomasville, Regional - United States Of America (TVI)",
    value: "TVI",
  },
  {
    label: "Thompsonfield, Thompson Falls - United States Of America (THM)",
    value: "THM",
  },
  {
    label: "Thorne Bay, Sea Plane Base - United States Of America (KTB)",
    value: "KTB",
  },
  {
    label: "Thousand Oaks, Heliport - United States Of America (JTO)",
    value: "JTO",
  },
  {
    label: "Three Rivers, Municipal Dr Haines - United States Of America (HAI)",
    value: "HAI",
  },
  {
    label: "Tifton, Henry Tift Myers - United States Of America (TMA)",
    value: "TMA",
  },
  {
    label: "Tikchik, Tikchik Lodge Spb - United States Of America (KTH)",
    value: "KTH",
  },
  {
    label: "Tillamook, Tillamook - United States Of America (OTK)",
    value: "OTK",
  },
  {
    label: "Tin City, Tin City - United States Of America (TNC)",
    value: "TNC",
  },
  {
    label: "Tioga, Tioga Mnpl - United States Of America (VEX)",
    value: "VEX",
  },
  {
    label: "Titusville, Space Coast Regional - United States Of America (TIX)",
    value: "TIX",
  },
  {
    label: "Toccoa, R.G. Letourneau Field - United States Of America (TOC)",
    value: "TOC",
  },
  {
    label: "Togiak Fish, Togiak Fish - United States Of America (GFB)",
    value: "GFB",
  },
  {
    label: "Togiak Village, Togiak - United States Of America (TOG)",
    value: "TOG",
  },
  {
    label: "Tok, Tok Junction - United States Of America (TKJ)",
    value: "TKJ",
  },
  {
    label: "Tokeen, Sea Plane Base - United States Of America (TKI)",
    value: "TKI",
  },
  {
    label: "Toksook Bay, Toksook Bay - United States Of America (OOK)",
    value: "OOK",
  },
  {
    label: "Toledo, Executive - United States Of America (TDZ)",
    value: "TDZ",
  },
  {
    label: "Toledo, Express - United States Of America (TOL)",
    value: "TOL",
  },
  {
    label: "Toledo, South Lewis County - United States Of America (TDO)",
    value: "TDO",
  },
  {
    label: "Toms River, Ocean County - United States Of America (MJX)",
    value: "MJX",
  },
  {
    label: "Tonopah, Test Range - United States Of America (XSD)",
    value: "XSD",
  },
  {
    label: "Tonopah, Tonopah Arpt - United States Of America (TPH)",
    value: "TPH",
  },
  {
    label: "Topeka, Forbes Field - United States Of America (FOE)",
    value: "FOE",
  },
  {
    label: "Topeka, Philip Billard Muni - United States Of America (TOP)",
    value: "TOP",
  },
  {
    label: "Torrance, Zamperini Field - United States Of America (TOA)",
    value: "TOA",
  },
  {
    label: "Torrington, Torrington Mnpl - United States Of America (TOR)",
    value: "TOR",
  },
  {
    label: "Traverse City, Cherry Capital - United States Of America (TVC)",
    value: "TVC",
  },
  {
    label: "Tree Point, Coast Gd Hpt - United States Of America (TRP)",
    value: "TRP",
  },
  {
    label: "Tremonton, Tremonton - United States Of America (TRT)",
    value: "TRT",
  },
  {
    label: "Trenton, Municipal - United States Of America (TRX)",
    value: "TRX",
  },
  {
    label: "Trinidad, Perry Stokes - United States Of America (TAD)",
    value: "TAD",
  },
  {
    label: "Trona, Trona - United States Of America (TRH)",
    value: "TRH",
  },
  {
    label: "Troy, Troy Mnpl - United States Of America (TOI)",
    value: "TOI",
  },
  {
    label: "Truckee, Truckee Tahoe - United States Of America (TKF)",
    value: "TKF",
  },
  {
    label: "Truth Or Conseque, Mncpl - United States Of America (TCS)",
    value: "TCS",
  },
  {
    label: "Tuba City, Tuba City - United States Of America (TBC)",
    value: "TBC",
  },
  {
    label: "Tucson, Davis Monthan Afb - United States Of America (DMA)",
    value: "DMA",
  },
  {
    label: "Tucson, Marana Regional - United States Of America (AVW)",
    value: "AVW",
  },
  {
    label: "Tucson, Tucson Intl - United States Of America (TUS)",
    value: "TUS",
  },
  {
    label: "Tucumcari, Municipal - United States Of America (TCC)",
    value: "TCC",
  },
  {
    label: "Tulare, Mefford Field - United States Of America (TLR)",
    value: "TLR",
  },
  {
    label: "Tullahoma, Arnold Afs - United States Of America (TUH)",
    value: "TUH",
  },
  {
    label: "Tullahoma, Regional - United States Of America (THA)",
    value: "THA",
  },
  {
    label: "Tulsa, Richard Lloyd Jones Jr - United States Of America (RVS)",
    value: "RVS",
  },
  {
    label: "Tulsa, Tulsa Intl - United States Of America (TUL)",
    value: "TUL",
  },
  {
    label: "Tuluksak, Tuluksak - United States Of America (TLT)",
    value: "TLT",
  },
  {
    label: "Tunica, Tunica Municipal - United States Of America (UTM)",
    value: "UTM",
  },
  {
    label: "Tuntutuliak, Tuntutuliak - United States Of America (WTL)",
    value: "WTL",
  },
  {
    label: "Tununak, Tununak - United States Of America (TNK)",
    value: "TNK",
  },
  {
    label: "Tupelo, Regional - United States Of America (TUP)",
    value: "TUP",
  },
  {
    label: "Tuscaloosa, Regional - United States Of America (TCL)",
    value: "TCL",
  },
  {
    label: "Tuskegee, Sharpe Field - United States Of America (TGE)",
    value: "TGE",
  },
  {
    label:
      "Twentynine Palms, Twentynine Palms - United States Of America (TNP)",
    value: "TNP",
  },
  {
    label: "Twin Falls, Magic Valley Regional - United States Of America (TWF)",
    value: "TWF",
  },
  {
    label: "Twin Hills, Twin Hills - United States Of America (TWA)",
    value: "TWA",
  },
  {
    label: "Tyler, Pounds Regional - United States Of America (TYR)",
    value: "TYR",
  },
  {
    label: "Tyonek, Tyonek - United States Of America (TYE)",
    value: "TYE",
  },
  {
    label: "Uganik, San Juan Uganik Spb - United States Of America (UGI)",
    value: "UGI",
  },
  {
    label: "Ugashik, Ugashik - United States Of America (UGS)",
    value: "UGS",
  },
  {
    label: "Ukiah, Municipal - United States Of America (UKI)",
    value: "UKI",
  },
  {
    label: "Umiat, Umiat - United States Of America (UMT)",
    value: "UMT",
  },
  {
    label: "Umnak Island, North Shore - United States Of America (UMB)",
    value: "UMB",
  },
  {
    label: "Umnak Island, Umnak - United States Of America (UNS)",
    value: "UNS",
  },
  {
    label: "Unalakleet, Unalakleet - United States Of America (UNK)",
    value: "UNK",
  },
  {
    label: "Union City, Everett-Stewart Rgnl - United States Of America (UCY)",
    value: "UCY",
  },
  {
    label: "Union S Carolina, Union County - United States Of America (USC)",
    value: "USC",
  },
  {
    label: "Upland, Cable - United States Of America (CCB)",
    value: "CCB",
  },
  {
    label: "Upland, Cable Hpt - United States Of America (JUP)",
    value: "JUP",
  },
  {
    label: "Upolu Point, Upolu - United States Of America (UPP)",
    value: "UPP",
  },
  {
    label: "Utica, Berz Macomb - United States Of America (UIZ)",
    value: "UIZ",
  },
  {
    label: "Utica, Oneida County - United States Of America (UCA)",
    value: "UCA",
  },
  {
    label: "Utopia Creek, Indian Mountain - United States Of America (UTO)",
    value: "UTO",
  },
  {
    label: "Uvalde, Garner Fld - United States Of America (UVA)",
    value: "UVA",
  },
  {
    label: "Vail Eagle, Eagle County Regional - United States Of America (EGE)",
    value: "EGE",
  },
  {
    label: "Valdez, Pioneer Field - United States Of America (VDZ)",
    value: "VDZ",
  },
  {
    label: "Valdosta, Moody Afb - United States Of America (VAD)",
    value: "VAD",
  },
  {
    label: "Valdosta, Regional - United States Of America (VLD)",
    value: "VLD",
  },
  {
    label: "Valentine, Miller Field - United States Of America (VTN)",
    value: "VTN",
  },
  {
    label: "Valle, Valle - United States Of America (VLE)",
    value: "VLE",
  },
  {
    label: "Vallejo, Stolport - United States Of America (VLO)",
    value: "VLO",
  },
  {
    label: "Valparaiso, Destin Executive Apt - United States Of America (DSI)",
    value: "DSI",
  },
  {
    label:
      "Valparaiso, Destin Ft Walton Beach - United States Of America (VPS)",
    value: "VPS",
  },
  {
    label: "Valparaiso, Porter Co Municipal - United States Of America (VPZ)",
    value: "VPZ",
  },
  {
    label: "Van Horn, Culberson Cnty - United States Of America (VHN)",
    value: "VHN",
  },
  {
    label: "Vandalia, Municipal - United States Of America (VLA)",
    value: "VLA",
  },
  {
    label: "Venetie, Venetie - United States Of America (VEE)",
    value: "VEE",
  },
  {
    label: "Venice, Municipal - United States Of America (VNC)",
    value: "VNC",
  },
  {
    label: "Vernal, Regional - United States Of America (VEL)",
    value: "VEL",
  },
  {
    label: "Vero Beach, Vero Beach Mnpl - United States Of America (VRB)",
    value: "VRB",
  },
  {
    label: "Versailles, Roy Otten Memorial - United States Of America (VRS)",
    value: "VRS",
  },
  {
    label: "Vichy, Rolla National - United States Of America (VIH)",
    value: "VIH",
  },
  {
    label: "Vicksburg, Municipal - United States Of America (VKS)",
    value: "VKS",
  },
  {
    label: "Victoria, Regional - United States Of America (VCT)",
    value: "VCT",
  },
  {
    label:
      "Victorville, S Calif Logistics Apt - United States Of America (VCV)",
    value: "VCV",
  },
  {
    label: "Vidalia, Regional - United States Of America (VDI)",
    value: "VDI",
  },
  {
    label: "View Cove, View Cove - United States Of America (VCB)",
    value: "VCB",
  },
  {
    label: "Vincennes, Oneal - United States Of America (OEA)",
    value: "OEA",
  },
  {
    label: "Visalia, Municipal - United States Of America (VIS)",
    value: "VIS",
  },
  {
    label: "Waco, Regional - United States Of America (ACT)",
    value: "ACT",
  },
  {
    label: "Waco, Tstc Waco - United States Of America (CNW)",
    value: "CNW",
  },
  {
    label: "Wahiawa, Wheeler Aaf - United States Of America (HHI)",
    value: "HHI",
  },
  {
    label: "Wahpeton, Harry Stern - United States Of America (WAH)",
    value: "WAH",
  },
  {
    label: "Waikoloa, Heliport - United States Of America (WKL)",
    value: "WKL",
  },
  {
    label: "Waimanalo, Bellows Field - United States Of America (BLW)",
    value: "BLW",
  },
  {
    label: "Wainwright, Wainwright - United States Of America (AIN)",
    value: "AIN",
  },
  {
    label: "Waldron Island, Waldronaire - United States Of America (WDN)",
    value: "WDN",
  },
  {
    label: "Wales, Wales - United States Of America (WAA)",
    value: "WAA",
  },
  {
    label: "Walla Walla, Regional - United States Of America (ALW)",
    value: "ALW",
  },
  {
    label: "Walnut Ridge, Regional - United States Of America (ARG)",
    value: "ARG",
  },
  {
    label: "Walterboro, Lowcountry Regional - United States Of America (RBW)",
    value: "RBW",
  },
  {
    label: "Waltham, Waltham - United States Of America (WLM)",
    value: "WLM",
  },
  {
    label: "Wapakoneta, Neil Armstron - United States Of America (AXV)",
    value: "AXV",
  },
  {
    label: "Ware, Ware - United States Of America (UWA)",
    value: "UWA",
  },
  {
    label: "Warner Robins, Robins Afb - United States Of America (WRB)",
    value: "WRB",
  },
  {
    label: "Warrensburg, Whiteman Afb - United States Of America (SZL)",
    value: "SZL",
  },
  {
    label: "Warroad, International Memorial - United States Of America (RRT)",
    value: "RRT",
  },
  {
    label: "Washington, DC - United States Of America (WAS)",
    value: "WAS",
  },
  {
    label: "Washington, Dulles Intl - United States Of America (IAD)",
    value: "IAD",
  },
  {
    label: "Washington, R Reagan Nat - United States Of America (DCA)",
    value: "DCA",
  },
  {
    label: "Washington, Baltimore - United States Of America (BWI)",
    value: "BWI",
  },
  {
    label:
      "Washington, Baltimore Martin State - United States Of America (MTN)",
    value: "MTN",
  },
  {
    label: "Washington, Buzzards Pt S - United States Of America (BZS)",
    value: "BZS",
  },
  {
    label: "Washington, NC - United States Of America (OCW)",
    value: "OCW",
  },
  {
    label: "Washington, Warren Field - United States Of America (OCW)",
    value: "OCW",
  },
  {
    label: "Washington, PA - United States Of America (WSG)",
    value: "WSG",
  },
  {
    label: "Washington, Washington County - United States Of America (WSG)",
    value: "WSG",
  },
  {
    label: "Wasilla, Wasilla - United States Of America (WWA)",
    value: "WWA",
  },
  {
    label: "Waterfall, Waterfall Spb - United States Of America (KWF)",
    value: "KWF",
  },
  {
    label: "Waterloo, Regional - United States Of America (ALO)",
    value: "ALO",
  },
  {
    label: "Watertown, International - United States Of America (ART)",
    value: "ART",
  },
  {
    label: "Watertown, Regional - United States Of America (ATY)",
    value: "ATY",
  },
  {
    label: "Waterville, Robert Lafleur - United States Of America (WVL)",
    value: "WVL",
  },
  {
    label: "Watsonville, Municipal - United States Of America (WVI)",
    value: "WVI",
  },
  {
    label: "Waukegan, Regional - United States Of America (UGN)",
    value: "UGN",
  },
  {
    label: "Waukesha, Waukesha County - United States Of America (UES)",
    value: "UES",
  },
  {
    label: "Waukon, Waukon Mnpl - United States Of America (UKN)",
    value: "UKN",
  },
  {
    label: "Wausau, Central Wisconsin - United States Of America (CWA)",
    value: "CWA",
  },
  {
    label: "Wausau, Downtown Apt - United States Of America (AUW)",
    value: "AUW",
  },
  {
    label: "Waycross, Ware County - United States Of America (AYS)",
    value: "AYS",
  },
  {
    label: "Waynesburg, Greene County - United States Of America (WAY)",
    value: "WAY",
  },
  {
    label: "Weatherford, Parker Cnty - United States Of America (WEA)",
    value: "WEA",
  },
  {
    label: "Webster City, Webster City Mnpl - United States Of America (EBS)",
    value: "EBS",
  },
  {
    label: "Weeping Water, Browns - United States Of America (EPG)",
    value: "EPG",
  },
  {
    label: "Wells, Municipal Harriet Fld - United States Of America (LWL)",
    value: "LWL",
  },
  {
    label: "Wellsville, Wellsville Mnpl - United States Of America (ELZ)",
    value: "ELZ",
  },
  {
    label: "Wenatchee, Pangborn Memorial - United States Of America (EAT)",
    value: "EAT",
  },
  {
    label: "Wendover, Wendover - United States Of America (ENV)",
    value: "ENV",
  },
  {
    label: "West Bend, Municipal - United States Of America (ETB)",
    value: "ETB",
  },
  {
    label:
      "West Branch, West Branch Community - United States Of America (WBK)",
    value: "WBK",
  },
  {
    label: "West Kavik, West Kavik - United States Of America (VKW)",
    value: "VKW",
  },
  {
    label: "West Kuparuk, West Kuparuk - United States Of America (XPU)",
    value: "XPU",
  },
  {
    label: "West Memphis, West Memphis Mnpl - United States Of America (AWM)",
    value: "AWM",
  },
  {
    label: "West Palm Beach, United States Of America (PBI)",
    value: "PBI",
  },
  {
    label: "West Palm Beach, Palm Beach Intl - United States Of America (PBI)",
    value: "PBI",
  },
  {
    label:
      "West Palm Beach, Palm Beach County Park - United States Of America (LNA)",
    value: "LNA",
  },
  {
    label: "West Point, West Point Vilage Spb - United States Of America (KWP)",
    value: "KWP",
  },
  {
    label: "West Yellowstone, Yellow - United States Of America (WYS)",
    value: "WYS",
  },
  {
    label:
      "Westchester County, Westchester County - United States Of America (HPN)",
    value: "HPN",
  },
  {
    label: "Westerly, State - United States Of America (WST)",
    value: "WST",
  },
  {
    label: "Westfield, Barnes Regional - United States Of America (BAF)",
    value: "BAF",
  },
  {
    label: "Westhampton, Francis S Gabreski - United States Of America (FOK)",
    value: "FOK",
  },
  {
    label: "Westsound, Sea Plane Base - United States Of America (WSX)",
    value: "WSX",
  },
  {
    label: "Whale Pass, North Whale Spb - United States Of America (WWP)",
    value: "WWP",
  },
  {
    label: "Wharton, Regional - United States Of America (WHT)",
    value: "WHT",
  },
  {
    label: "Wheatland, Phifer Airfield - United States Of America (EAN)",
    value: "EAN",
  },
  {
    label: "Wheeling, Ohio County - United States Of America (HLG)",
    value: "HLG",
  },
  {
    label: "White Mountain, White Mountain - United States Of America (WMO)",
    value: "WMO",
  },
  {
    label: "White River, Whiteriver - United States Of America (WTR)",
    value: "WTR",
  },
  {
    label: "White Sands, Condron Aaf - United States Of America (WSD)",
    value: "WSD",
  },
  {
    label: "White Sulphur, Greenbrier - United States Of America (SSU)",
    value: "SSU",
  },
  {
    label: "Whitefield, Mount Washington Rgnl - United States Of America (HIE)",
    value: "HIE",
  },
  {
    label: "Whitesburg, Whitesburg Mnpl - United States Of America (BRG)",
    value: "BRG",
  },
  {
    label: "Wichita, Beech Factory Apt - United States Of America (BEC)",
    value: "BEC",
  },
  {
    label: "Wichita, Cessna Aircraft - United States Of America (CEA)",
    value: "CEA",
  },
  {
    label: "Wichita, Dwight D Eisenhower - United States Of America (ICT)",
    value: "ICT",
  },
  {
    label: "Wichita, Mcconnell Afb - United States Of America (IAB)",
    value: "IAB",
  },
  {
    label:
      "Wichita Falls, Kickapoo Downtown Apt - United States Of America (KIP)",
    value: "KIP",
  },
  {
    label:
      "Wichita Falls, Municipal Sheppard Afb - United States Of America (SPS)",
    value: "SPS",
  },
  {
    label: "Wilbarger, County - United States Of America (WIB)",
    value: "WIB",
  },
  {
    label: "Wildman Lake, Wildman Lake - United States Of America (EWD)",
    value: "EWD",
  },
  {
    label: "Wildwood, Cape May County - United States Of America (WWD)",
    value: "WWD",
  },
  {
    label: "Wildwood, Usaf Heliport - United States Of America (WWS)",
    value: "WWS",
  },
  {
    label: "Wilkesboro, Wilkes Count - United States Of America (IKB)",
    value: "IKB",
  },
  {
    label: "Willcox, Cochise County - United States Of America (CWX)",
    value: "CWX",
  },
  {
    label:
      "Williamsport, Williamsport Reg Apt - United States Of America (IPT)",
    value: "IPT",
  },
  {
    label: "Williston, Sloulin Field - United States Of America (ISN)",
    value: "ISN",
  },
  {
    label: "Willmar, Municipal - United States Of America (ILL)",
    value: "ILL",
  },
  {
    label: "Willoughby, Lost Nation Municipal - United States Of America (LNN)",
    value: "LNN",
  },
  {
    label: "Willow, Willow - United States Of America (WOW)",
    value: "WOW",
  },
  {
    label: "Willow Grove, Willow Grov - United States Of America (NXX)",
    value: "NXX",
  },
  {
    label: "Willows, Glenn County - United States Of America (WLW)",
    value: "WLW",
  },
  {
    label: "Wilmington, Air Park - United States Of America (ILN)",
    value: "ILN",
  },
  {
    label: "Wilmington, International - United States Of America (ILM)",
    value: "ILM",
  },
  {
    label: "Wilmington, New Castle - United States Of America (ILG)",
    value: "ILG",
  },
  {
    label: "Winchester, Regional - United States Of America (WGO)",
    value: "WGO",
  },
  {
    label: "Winder, Barrow County - United States Of America (WDR)",
    value: "WDR",
  },
  {
    label: "Windom, Windom Mnpl - United States Of America (MWM)",
    value: "MWM",
  },
  {
    label: "Winfield, Strother Field - United States Of America (WLD)",
    value: "WLD",
  },
  {
    label: "Wink, Winkler County - United States Of America (INK)",
    value: "INK",
  },
  {
    label: "Winnemucca, Municipal - United States Of America (WMC)",
    value: "WMC",
  },
  {
    label: "Winona, Winona Mnpl - United States Of America (ONA)",
    value: "ONA",
  },
  {
    label: "Winslow, Lindbergh Regional - United States Of America (INW)",
    value: "INW",
  },
  {
    label: "Winston Salem, Smith Reynolds - United States Of America (INT)",
    value: "INT",
  },
  {
    label:
      "Winter Haven, Winter Haven's Gilbert - United States Of America (GIF)",
    value: "GIF",
  },
  {
    label: "Wiscasset, Wiscasset - United States Of America (ISS)",
    value: "ISS",
  },
  {
    label:
      "Wisconsin Rapids, South Wood County - United States Of America (ISW)",
    value: "ISW",
  },
  {
    label: "Wise, Lonesome Pine - United States Of America (LNP)",
    value: "LNP",
  },
  {
    label: "Wiseman, Wiseman - United States Of America (WSM)",
    value: "WSM",
  },
  {
    label: "Woburn, Cummings Park - United States Of America (WBN)",
    value: "WBN",
  },
  {
    label: "Wolf Point, L.M. Clayton - United States Of America (OLF)",
    value: "OLF",
  },
  {
    label: "Wood River, Wood River - United States Of America (WOD)",
    value: "WOD",
  },
  {
    label: "Woodchopper, Woodchopper - United States Of America (WOO)",
    value: "WOO",
  },
  {
    label: "Woodward, West Woodward - United States Of America (WWR)",
    value: "WWR",
  },
  {
    label: "Wooster, Wayne County - United States Of America (BJJ)",
    value: "BJJ",
  },
  {
    label: "Worcester, Regional - United States Of America (ORH)",
    value: "ORH",
  },
  {
    label: "Worland, Municipal - United States Of America (WRL)",
    value: "WRL",
  },
  {
    label: "Worthington, Municipal - United States Of America (OTG)",
    value: "OTG",
  },
  {
    label: "Wrangell, Wrangell - United States Of America (WRG)",
    value: "WRG",
  },
  {
    label: "Wrench Creek, Wrench Creek - United States Of America (WRH)",
    value: "WRH",
  },
  {
    label: "Yakataga, Yakataga - United States Of America (CYT)",
    value: "CYT",
  },
  {
    label: "Yakima, Air Terminal - United States Of America (YKM)",
    value: "YKM",
  },
  {
    label: "Yakima, Vagabond Ahp - United States Of America (FCT)",
    value: "FCT",
  },
  {
    label: "Yakutat, Yakutat - United States Of America (YAK)",
    value: "YAK",
  },
  {
    label: "Yankton, Chan Gurney Municipal - United States Of America (YKN)",
    value: "YKN",
  },
  {
    label: "Yerington, Municipal - United States Of America (EYR)",
    value: "EYR",
  },
  {
    label: "Yes Bay, Yes Bay Lodge Spb - United States Of America (WYB)",
    value: "WYB",
  },
  {
    label: "York, York - United States Of America (THV)",
    value: "THV",
  },
  {
    label:
      "Yosemite Ntl Park, Yosemite Ntl Park - United States Of America (OYS)",
    value: "OYS",
  },
  {
    label: "Youngstown Warren, Regional - United States Of America (YNG)",
    value: "YNG",
  },
  {
    label: "Yreka, Yreka - United States Of America (RKC)",
    value: "RKC",
  },
  {
    label: "Yuma, Laguna Aaf - United States Of America (LGF)",
    value: "LGF",
  },
  {
    label: "Yuma, Yuma Intl - United States Of America (YUM)",
    value: "YUM",
  },
  {
    label: "Zachar Bay, Zachar Bay S - United States Of America (KZB)",
    value: "KZB",
  },
  {
    label: "Zephyrhills, Municipal - United States Of America (ZPH)",
    value: "ZPH",
  },
  {
    label: "Artigas, Artigas - Uruguay (ATI)",
    value: "ATI",
  },
  {
    label: "Bella Union, Placeres - Uruguay (BUV)",
    value: "BUV",
  },
  {
    label: "Colonia, Laguna De Los Patos - Uruguay (CYR)",
    value: "CYR",
  },
  {
    label: "Durazno, Santa Bernardina - Uruguay (DZO)",
    value: "DZO",
  },
  {
    label: "Melo, Cerro Largo - Uruguay (MLZ)",
    value: "MLZ",
  },
  {
    label: "Montevideo, Carrasco - Uruguay (MVD)",
    value: "MVD",
  },
  {
    label: "Montevideo, Tres Cruces Bus Stn - Uruguay (VDT)",
    value: "VDT",
  },
  {
    label: "Paysandu, Tydeo Larre Borges - Uruguay (PDU)",
    value: "PDU",
  },
  {
    label: "Punta Del Este, Punta Del Este - Uruguay (PDP)",
    value: "PDP",
  },
  {
    label: "Rivera, Oscar D.Gestido - Uruguay (RVY)",
    value: "RVY",
  },
  {
    label: "Salto, Nueva Hesperides - Uruguay (STY)",
    value: "STY",
  },
  {
    label: "Tacuarembo, Tacuarembo - Uruguay (TAW)",
    value: "TAW",
  },
  {
    label: "Treinta Y Tres, Treinta Y Tres - Uruguay (TYT)",
    value: "TYT",
  },
  {
    label: "Vichadero, Vichadero - Uruguay (VCH)",
    value: "VCH",
  },
  {
    label: "Andizhan, Andizhan - Uzbekistan (AZN)",
    value: "AZN",
  },
  {
    label: "Bukhara, International - Uzbekistan (BHK)",
    value: "BHK",
  },
  {
    label: "Fergana, International - Uzbekistan (FEG)",
    value: "FEG",
  },
  {
    label: "Karshi, International - Uzbekistan (KSQ)",
    value: "KSQ",
  },
  {
    label: "Namangan, Namangan - Uzbekistan (NMA)",
    value: "NMA",
  },
  {
    label: "Navoi, International - Uzbekistan (NVI)",
    value: "NVI",
  },
  {
    label: "Nukus, Nukus - Uzbekistan (NCU)",
    value: "NCU",
  },
  {
    label: "Samarkand, International - Uzbekistan (SKD)",
    value: "SKD",
  },
  {
    label: "Tashkent, International - Uzbekistan (TAS)",
    value: "TAS",
  },
  {
    label: "Termez Uz, Termez Uz - Uzbekistan (TMJ)",
    value: "TMJ",
  },
  {
    label: "Urgench, International - Uzbekistan (UGC)",
    value: "UGC",
  },
  {
    label: "Zarafshan, Zarafshan - Uzbekistan (AFS)",
    value: "AFS",
  },
  {
    label: "Aneityum, Anatom Island - Vanuatu (AUY)",
    value: "AUY",
  },
  {
    label: "Aniwa, Aniwa Island - Vanuatu (AWD)",
    value: "AWD",
  },
  {
    label: "Craig Cove, Vanuatu (CCV)",
    value: "CCV",
  },
  {
    label: "Craig Cove, Craig Cove - Vanuatu (CCV)",
    value: "CCV",
  },
  {
    label: "Dillons Bay, Dillon S Bay - Vanuatu (DLY)",
    value: "DLY",
  },
  {
    label: "Emae, Siwo - Vanuatu (EAE)",
    value: "EAE",
  },
  {
    label: "Espiritu Santo, Santo Pekoa Intl - Vanuatu (SON)",
    value: "SON",
  },
  {
    label: "Futuna Island, Futuna Apt - Vanuatu (FTA)",
    value: "FTA",
  },
  {
    label: "Gaua, Gaua Island - Vanuatu (ZGU)",
    value: "ZGU",
  },
  {
    label: "Ipota, Ipota - Vanuatu (IPA)",
    value: "IPA",
  },
  {
    label: "Lamap, Lamap - Vanuatu (LPM)",
    value: "LPM",
  },
  {
    label: "Lamen Bay, Lamen Bay - Vanuatu (LNB)",
    value: "LNB",
  },
  {
    label: "Longana, Longana - Vanuatu (LOD)",
    value: "LOD",
  },
  {
    label: "Lonorore, Lonorore - Vanuatu (LNE)",
    value: "LNE",
  },
  {
    label: "Maewo, Maewo Island - Vanuatu (MWF)",
    value: "MWF",
  },
  {
    label: "Mota Lava, Mota Lava Island - Vanuatu (MTV)",
    value: "MTV",
  },
  {
    label: "Norsup, Norsup - Vanuatu (NUS)",
    value: "NUS",
  },
  {
    label: "Olpoi, Olpoi - Vanuatu (OLJ)",
    value: "OLJ",
  },
  {
    label: "Paama, Paama Island - Vanuatu (PBJ)",
    value: "PBJ",
  },
  {
    label: "Port Vila, Bauerfield Intl - Vanuatu (VLI)",
    value: "VLI",
  },
  {
    label: "Quine Hill, Quoin Hill - Vanuatu (UIQ)",
    value: "UIQ",
  },
  {
    label: "Redcliffe, Redcliffe - Vanuatu (RCL)",
    value: "RCL",
  },
  {
    label: "Sara, Sara - Vanuatu (SSR)",
    value: "SSR",
  },
  {
    label: "Sola, Vanua Lava - Vanuatu (SLH)",
    value: "SLH",
  },
  {
    label: "South West Bay, South West Bay - Vanuatu (SWJ)",
    value: "SWJ",
  },
  {
    label: "Tanna, Whitegrass - Vanuatu (TAH)",
    value: "TAH",
  },
  {
    label: "Tongoa, Tongoa Island - Vanuatu (TGH)",
    value: "TGH",
  },
  {
    label: "Torres, Torres Islands - Vanuatu (TOH)",
    value: "TOH",
  },
  {
    label: "Ulei, Ulei - Vanuatu (ULB)",
    value: "ULB",
  },
  {
    label: "Valesdir, Valesdir - Vanuatu (VLS)",
    value: "VLS",
  },
  {
    label: "Walaha, Walaha - Vanuatu (WLH)",
    value: "WLH",
  },
  {
    label: "Acarigua, Oswaldo Guevara Mujica - Venezuela (AGV)",
    value: "AGV",
  },
  {
    label: "Anaco, Anaco - Venezuela (AAO)",
    value: "AAO",
  },
  {
    label: "Barcelona, General Ja Anzoategui - Venezuela (BLA)",
    value: "BLA",
  },
  {
    label: "Barinas, Barinas - Venezuela (BNS)",
    value: "BNS",
  },
  {
    label: "Barquisimeto, Jacinto Lara Intl - Venezuela (BRM)",
    value: "BRM",
  },
  {
    label: "Cabimas, Oro Negro - Venezuela (CBS)",
    value: "CBS",
  },
  {
    label: "Caicara De Oro, Caicara De Orinoco - Venezuela (CXA)",
    value: "CXA",
  },
  {
    label: "Calabozo, Calabozo - Venezuela (CLZ)",
    value: "CLZ",
  },
  {
    label: "Canaima, Canaima - Venezuela (CAJ)",
    value: "CAJ",
  },
  {
    label: "Caracas - Venezuela (CCS)",
    value: "CCS",
  },
  {
    label: "Caracas, Simon Bolivar Intl - Venezuela (CCS)",
    value: "CCS",
  },
  {
    label: "Carora, Carora - Venezuela (VCR)",
    value: "VCR",
  },
  {
    label: "Carupano, Jose F.Bermudez - Venezuela (CUP)",
    value: "CUP",
  },
  {
    label: "Casigua, Casigua El Cubo - Venezuela (CUV)",
    value: "CUV",
  },
  {
    label: "Ciudad Bolivar, Tomas De Heres - Venezuela (CBL)",
    value: "CBL",
  },
  {
    label: "Ciudad Guayana, Ciudad Guayana - Venezuela (CGU)",
    value: "CGU",
  },
  {
    label: "Coro, Jose Leonardo Chirinos - Venezuela (CZE)",
    value: "CZE",
  },
  {
    label: "Cumana, Antonio Jose De Sucre - Venezuela (CUM)",
    value: "CUM",
  },
  {
    label: "El Dorado, El Dorado - Venezuela (EOR)",
    value: "EOR",
  },
  {
    label: "El Tigre, El Tigre - Venezuela (ELX)",
    value: "ELX",
  },
  {
    label: "El Vigia, Juan P.Perez Alfonso - Venezuela (VIG)",
    value: "VIG",
  },
  {
    label: "Elorza, Elorza - Venezuela (EOZ)",
    value: "EOZ",
  },
  {
    label: "Guanare, Guanare - Venezuela (GUQ)",
    value: "GUQ",
  },
  {
    label: "Guasdualito, Guasdualito - Venezuela (GDO)",
    value: "GDO",
  },
  {
    label: "Guiria, Guiria - Venezuela (GUI)",
    value: "GUI",
  },
  {
    label: "Higuerote, Higuerote - Venezuela (HGE)",
    value: "HGE",
  },
  {
    label: "Icabaru, Icabaru - Venezuela (ICA)",
    value: "ICA",
  },
  {
    label: "Isla De Coche, A M Salazar Marcano - Venezuela (ICC)",
    value: "ICC",
  },
  {
    label: "Kamarata, Kamarata - Venezuela (KTV)",
    value: "KTV",
  },
  {
    label: "Kavanayen, Kanavayen - Venezuela (KAV)",
    value: "KAV",
  },
  {
    label: "La Fria, La Fria - Venezuela (LFR)",
    value: "LFR",
  },
  {
    label: "La Guaira, La Guaira - Venezuela (LAG)",
    value: "LAG",
  },
  {
    label: "Lagunillas, Lagunillas - Venezuela (LGY)",
    value: "LGY",
  },
  {
    label: "Las Piedras, Josefa Camejo Intl - Venezuela (LSP)",
    value: "LSP",
  },
  {
    label: "Los Roques, Los Roques - Venezuela (LRV)",
    value: "LRV",
  },
  {
    label: "Maracaibo, La Chinita Intl - Venezuela (MAR)",
    value: "MAR",
  },
  {
    label: "Maracay, Mariscal Sucre - Venezuela (MYC)",
    value: "MYC",
  },
  {
    label: "Maturin, Jose T. Monagas Intl - Venezuela (MUN)",
    value: "MUN",
  },
  {
    label: "Merida, Alberto Carnevalli - Venezuela (MRD)",
    value: "MRD",
  },
  {
    label: "Palmarito, Palmarito - Venezuela (PTM)",
    value: "PTM",
  },
  {
    label: "Pedernales, Pedernales - Venezuela (PDZ)",
    value: "PDZ",
  },
  {
    label: "Peraitepuy, Peraitepuy - Venezuela (PPH)",
    value: "PPH",
  },
  {
    label: "Pijiguaos, Armando Schwarck - Venezuela (LPJ)",
    value: "LPJ",
  },
  {
    label: "Porlamar, Delcaribe Intl - Venezuela (PMV)",
    value: "PMV",
  },
  {
    label: "Puerto Ayacucho, Cacique Aramare - Venezuela (PYH)",
    value: "PYH",
  },
  {
    label: "Puerto Cabello, Bartolome Salom Intl - Venezuela (PBL)",
    value: "PBL",
  },
  {
    label: "Puerto Ordaz, Manuel Carlos Piar - Venezuela (PZO)",
    value: "PZO",
  },
  {
    label: "Puerto Paez, Puerto Paez - Venezuela (PPZ)",
    value: "PPZ",
  },
  {
    label: "San Antonio, Juan V.Gomez Intl - Venezuela (SVZ)",
    value: "SVZ",
  },
  {
    label: "San Cristobal, Paramillo - Venezuela (SCI)",
    value: "SCI",
  },
  {
    label: "San Felipe, Nestor Arias - Venezuela (SNF)",
    value: "SNF",
  },
  {
    label: "San Felix, Macagua - Venezuela (SFX)",
    value: "SFX",
  },
  {
    label: "San Fernando De Apure, Las Flecheras - Venezuela (SFD)",
    value: "SFD",
  },
  {
    label: "San Salvador De, San Salvador De Paul - Venezuela (SVV)",
    value: "SVV",
  },
  {
    label: "San Tome, San Tome - Venezuela (SOM)",
    value: "SOM",
  },
  {
    label: "Santa Barbara Ba, Santa Barbara Barinas - Venezuela (SBB)",
    value: "SBB",
  },
  {
    label: "Santa Barbara Ed, Miguel U.Fernandez - Venezuela (STB)",
    value: "STB",
  },
  {
    label: "Santa Elena, Santa Elena De Uairen - Venezuela (SNV)",
    value: "SNV",
  },
  {
    label: "Santo Domingo, Buenaventura Vivas - Venezuela (STD)",
    value: "STD",
  },
  {
    label: "Tucupita, San Rafael - Venezuela (TUV)",
    value: "TUV",
  },
  {
    label: "Tumeremo, Tumeremo - Venezuela (TMO)",
    value: "TMO",
  },
  {
    label: "Uriman, Uriman - Venezuela (URM)",
    value: "URM",
  },
  {
    label: "Valencia, Arturo Michelena Intl - Venezuela (VLN)",
    value: "VLN",
  },
  {
    label: "Valera, Antonio N.Briceno - Venezuela (VLV)",
    value: "VLV",
  },
  {
    label: "Valle De Pascua, Valle De La Pascua - Venezuela (VDP)",
    value: "VDP",
  },
  {
    label: "Wonken, Uonquen - Venezuela (WOK)",
    value: "WOK",
  },
  {
    label: "Banmethuot, Buon Ma Thuot - Vietnam (BMV)",
    value: "BMV",
  },
  {
    label: "Ca Mau, Ca Mau - Vietnam (CAH)",
    value: "CAH",
  },
  {
    label: "Can Tho, International - Vietnam (VCA)",
    value: "VCA",
  },
  {
    label: "Chu Lai, International - Vietnam (VCL)",
    value: "VCL",
  },
  {
    label: "Con Dao, Co Ong - Vietnam (VCS)",
    value: "VCS",
  },
  {
    label: "Da Nang, International - Vietnam (DAD)",
    value: "DAD",
  },
  {
    label: "Dalat, Lien Khuong - Vietnam (DLI)",
    value: "DLI",
  },
  {
    label: "Dien Bien Phu, Dien Bien Phu - Vietnam (DIN)",
    value: "DIN",
  },
  {
    label: "Dong Hoi, Dong Hoi - Vietnam (VDH)",
    value: "VDH",
  },
  {
    label: "Haiphong, Cat Bi Intl - Vietnam (HPH)",
    value: "HPH",
  },
  {
    label: "Hanoi, Noi Bai Intl - Vietnam (HAN)",
    value: "HAN",
  },
  {
    label: "Ho Chi Minh City, Tan Son Nhat Intl - Vietnam (SGN)",
    value: "SGN",
  },
  {
    label: "Hue, Phu Bai Intl - Vietnam (HUI)",
    value: "HUI",
  },
  {
    label: "Kontum, Kon Tum - Vietnam (KON)",
    value: "KON",
  },
  {
    label: "Long Xuyen, Long Xuyen - Vietnam (XLO)",
    value: "XLO",
  },
  {
    label: "Nha Trang, Air Base - Vietnam (NHA)",
    value: "NHA",
  },
  {
    label: "Nha Trang, Cam Ranh Intl - Vietnam (CXR)",
    value: "CXR",
  },
  {
    label: "Phan Rang, Air Base - Vietnam (PHA)",
    value: "PHA",
  },
  {
    label: "Phan Thiet, Phan Thiet - Vietnam (PHH)",
    value: "PHH",
  },
  {
    label: "Phu Quoc, International - Vietnam (PQC)",
    value: "PQC",
  },
  {
    label: "Phu Vinh, Phuoc Long - Vietnam (PHU)",
    value: "PHU",
  },
  {
    label: "Phuoclong, Phuoclong - Vietnam (VSO)",
    value: "VSO",
  },
  {
    label: "Pleiku, Pleiku - Vietnam (PXU)",
    value: "PXU",
  },
  {
    label: "Quanduc, Nhon Co - Vietnam (HOO)",
    value: "HOO",
  },
  {
    label: "Quang Ngai, Quang Ngai - Vietnam (XNG)",
    value: "XNG",
  },
  {
    label: "Qui Nhon, Phu Cat - Vietnam (UIH)",
    value: "UIH",
  },
  {
    label: "Rach Gia, Rach Gia - Vietnam (VKG)",
    value: "VKG",
  },
  {
    label: "Soc Trang, Soc Trang - Vietnam (SOA)",
    value: "SOA",
  },
  {
    label: "Son La, Na San - Vietnam (SQH)",
    value: "SQH",
  },
  {
    label: "Tamky, Tam Ky - Vietnam (TMK)",
    value: "TMK",
  },
  {
    label: "Thanh Hoa, Tho Xuan - Vietnam (THD)",
    value: "THD",
  },
  {
    label: "Tuy Hoa, Dong Tac - Vietnam (TBB)",
    value: "TBB",
  },
  {
    label: "Vinh City, Vinh - Vietnam (VII)",
    value: "VII",
  },
  {
    label: "Vinh Long, Vinh Long - Vietnam (XVL)",
    value: "XVL",
  },
  {
    label: "Vung Tau, Vung Tau - Vietnam (VTG)",
    value: "VTG",
  },
  {
    label: "Anegada, Auguste George - Virgin Islands-British (NGD)",
    value: "NGD",
  },
  {
    label: "Tortola, Beef Island - Virgin Islands-British (EIS)",
    value: "EIS",
  },
  {
    label: "Tortola, Road Town Spb - Virgin Islands-British (RAD)",
    value: "RAD",
  },
  {
    label: "Tortola, West End Spb - Virgin Islands-British (TOV)",
    value: "TOV",
  },
  {
    label:
      "Virgin Gorda, North Sound Launch Pt. - Virgin Islands-British (NSX)",
    value: "NSX",
  },
  {
    label: "Virgin Gorda, Virgin Gorda - Virgin Islands-British (VIJ)",
    value: "VIJ",
  },
  {
    label:
      "Saint Croix Island, Christiansted Spb - Virgin Islands-United States (SSB)",
    value: "SSB",
  },
  {
    label:
      "Saint Croix Island, Downtown Hpt - Virgin Islands-United States (JCD)",
    value: "JCD",
  },
  {
    label:
      "Saint Croix Island, Henry E Rohlsen - Virgin Islands-United States (STX)",
    value: "STX",
  },
  {
    label:
      "Saint John Island, Cruz Bay Spb - Virgin Islands-United States (SJF)",
    value: "SJF",
  },
  {
    label:
      "Saint Thomas Island, Charlotte Amalie Spb - Virgin Islands-United States (SPB)",
    value: "SPB",
  },
  {
    label:
      "Saint Thomas Island, Cyril E. King - Virgin Islands-United States (STT)",
    value: "STT",
  },
  {
    label: "Futuna Island, Pointe Vele - Wallis And Futuna Islands (FUT)",
    value: "FUT",
  },
  {
    label: "Wallis Island, Hihifo - Wallis And Futuna Islands (WLS)",
    value: "WLS",
  },
  {
    label: "Abbs, Yemen Republic (EAB)",
    value: "EAB",
  },
  {
    label: "Abbs, Abbs - Yemen Republic (EAB)",
    value: "EAB",
  },
  {
    label: "Aden,Yemen Republic (ADE)",
    value: "ADE",
  },
  {
    label: "Aden, Aden Intl - Yemen Republic (ADE)",
    value: "ADE",
  },
  {
    label: "Al Bayda,Yemen Republic (BYD)",
    value: "BYD",
  },
  {
    label: "Al Bayda, Al Bayda - Yemen Republic (BYD)",
    value: "BYD",
  },
  {
    label: "Al Buq, Yemen Republic (BUK)",
    value: "BUK",
  },
  {
    label: "Al Buq, Al Buq - Yemen Republic (BUK)",
    value: "BUK",
  },
  {
    label: "Al Ghaydah, Yemen Republic (AAY)",
    value: "AAY",
  },
  {
    label: "Al Ghaydah, Al Ghaydah - Yemen Republic (AAY)",
    value: "AAY",
  },
  {
    label: "Ataq, Yemen Republic (AXK)",
    value: "AXK",
  },
  {
    label: "Ataq, Ataq - Yemen Republic (AXK)",
    value: "AXK",
  },
  {
    label: "Beihan, Yemen Republic (BHN)",
    value: "BHN",
  },
  {
    label: "Beihan, Beihan - Yemen Republic (BHN)",
    value: "BHN",
  },
  {
    label: "Dathina, Yemen Republic (DAH)",
    value: "DAH",
  },
  {
    label: "Dathina, Dathina - Yemen Republic (DAH)",
    value: "DAH",
  },
  {
    label: "Dhala, Yemen Republic (DHL)",
    value: "DHL",
  },
  {
    label: "Dhala, Dhala - Yemen Republic (DHL)",
    value: "DHL",
  },
  {
    label: "Dhamar, Yemen Republic (DMR)",
    value: "DMR",
  },
  {
    label: "Dhamar, Dhamar - Yemen Republic (DMR)",
    value: "DMR",
  },
  {
    label: "Hodeidah, Yemen Republic (HOD)",
    value: "HOD",
  },
  {
    label: "Hodeidah, International - Yemen Republic (HOD)",
    value: "HOD",
  },
  {
    label: "Kamaran Island - Yemen Republic (KAM)",
    value: "KAM",
  },
  {
    label: "Kamaran Island, Kamaran Island - Yemen Republic (KAM)",
    value: "KAM",
  },
  {
    label: "Lawdar - Yemen Republic (LDR)",
    value: "LDR",
  },
  {
    label: "Lawdar, Lawdar - Yemen Republic (LDR)",
    value: "LDR",
  },
  {
    label: "Marib - Yemen Republic (MYN)",
    value: "MYN",
  },
  {
    label: "Marib, Marib - Yemen Republic (MYN)",
    value: "MYN",
  },
  {
    label: "Mayfa'ah - Yemen Republic (MFY)",
    value: "MFY",
  },
  {
    label: "Mayfa'ah, Mayfa'ah - Yemen Republic (MFY)",
    value: "MFY",
  },
  {
    label: "Mukalla - Yemen Republic (RIY)",
    value: "RIY",
  },
  {
    label: "Mukalla, Riyan - Yemen Republic (RIY)",
    value: "RIY",
  },
  {
    label: "Mukeiras - Yemen Republic (UKR)",
    value: "UKR",
  },
  {
    label: "Mukeiras, Mukeiras - Yemen Republic (UKR)",
    value: "UKR",
  },
  {
    label: "Qishn - Yemen Republic (IHN)",
    value: "IHN",
  },
  {
    label: "Qishn, Qishn - Yemen Republic (IHN)",
    value: "IHN",
  },
  {
    label: "Rawdah - Yemen Republic (RXA)",
    value: "RXA",
  },
  {
    label: "Rawdah, Rawdah - Yemen Republic (RXA)",
    value: "RXA",
  },
  {
    label: "Sadah - Yemen Republic (SYE)",
    value: "SYE",
  },
  {
    label: "Sadah, Sadah - Yemen Republic (SYE)",
    value: "SYE",
  },
  {
    label: "Sanaa - Yemen Republic (SAH)",
    value: "SAH",
  },
  {
    label: "Sanaa, Sanaa Intl - Yemen Republic (SAH)",
    value: "SAH",
  },
  {
    label: "Sayun - Yemen Republic (GXF)",
    value: "GXF",
  },
  {
    label: "Sayun, Sayun - Yemen Republic (GXF)",
    value: "GXF",
  },
  {
    label: "Socotra - Yemen Republic (SCT)",
    value: "SCT",
  },
  {
    label: "Socotra, Socotra - Yemen Republic (SCT)",
    value: "SCT",
  },
  {
    label: "Taizz - Yemen Republic (TAI)",
    value: "TAI",
  },
  {
    label: "Taizz, International - Yemen Republic (TAI)",
    value: "TAI",
  },
  {
    label: "Wadi Ain - Yemen Republic (WDA)",
    value: "WDA",
  },
  {
    label: "Wadi Ain, Wadi Ain - Yemen Republic (WDA)",
    value: "WDA",
  },
  {
    label: "Chingola,Zambia (CGJ)",
    value: "CGJ",
  },
  {
    label: "Chingola, Kasompe - Zambia (CGJ)",
    value: "CGJ",
  },
  {
    label: "Chipata, Zambia (CIP)",
    value: "CIP",
  },
  {
    label: "Chipata, Chipata - Zambia (CIP)",
    value: "CIP",
  },
  {
    label: "Kalabo, Zambia (KLB)",
    value: "KLB",
  },
  {
    label: "Kalabo, Kalabo - Zambia (KLB)",
    value: "KLB",
  },
  {
    label: "Kaoma, Zambia (KMZ)",
    value: "KMZ",
  },
  {
    label: "Kaoma, Kaoma - Zambia (KMZ)",
    value: "KMZ",
  },
  {
    label: "Kasaba Bay, Zambia (ZKB)",
    value: "ZKB",
  },
  {
    label: "Kasaba Bay, Kasaba Bay - Zambia (ZKB)",
    value: "ZKB",
  },
  {
    label: "Kasama, Zambia (KAA)",
    value: "KAA",
  },
  {
    label: "Kasama, Kasama - Zambia (KAA)",
    value: "KAA",
  },
  {
    label: "Kitwe, Zambia (KIW)",
    value: "KIW",
  },
  {
    label: "Kitwe, Southdowns - Zambia (KIW)",
    value: "KIW",
  },
  {
    label: "Livingstone, Zambia (LVI)",
    value: "LVI",
  },
  {
    label: "Livingstone, Livingstone - Zambia (LVI)",
    value: "LVI",
  },
  {
    label: "Lower Zambezi Nat Park, Zambia (RYL)",
    value: "RYL",
  },
  {
    label: "Lower Zambezi Nat Park, Royal - Zambia (RYL)",
    value: "RYL",
  },
  {
    label: "Lower Zambezi Nat Park, Jeki - Zambia (JEK)",
    value: "JEK",
  },
  {
    label: "Lukulu, Zambia (LXU)",
    value: "LXU",
  },
  {
    label: "Lukulu, Lukulu - Zambia (LXU)",
    value: "LXU",
  },
  {
    label: "Lusaka, Zambia (LUN)",
    value: "LUN",
  },
  {
    label: "Lusaka, Lusaka Intl - Zambia (LUN)",
    value: "LUN",
  },
  {
    label: "Mansa, Zambia (MNS)",
    value: "MNS",
  },
  {
    label: "Mansa, Mansa - Zambia (MNS)",
    value: "MNS",
  },
  {
    label: "Mbala, Zambia (MMQ)",
    value: "MMQ",
  },
  {
    label: "Mbala, Mbala - Zambia (MMQ)",
    value: "MMQ",
  },
  {
    label: "Mfuwe, Zambia (MFU)",
    value: "MFU",
  },
  {
    label: "Mfuwe, Mfuwe - Zambia (MFU)",
    value: "MFU",
  },
  {
    label: "Mongu, Zambia (MNR)",
    value: "MNR",
  },
  {
    label: "Mongu, Mongu - Zambia (MNR)",
    value: "MNR",
  },
  {
    label: "Ndola, Zambia (NLA)",
    value: "NLA",
  },
  {
    label: "Ndola, Ndola - Zambia (NLA)",
    value: "NLA",
  },
  {
    label: "Ngoma, Zambia (ZGM)",
    value: "ZGM",
  },
  {
    label: "Ngoma, Ngoma - Zambia (ZGM)",
    value: "ZGM",
  },
  {
    label: "Senanga, Zambia (SXG)",
    value: "SXG",
  },
  {
    label: "Senanga, Senanga - Zambia (SXG)",
    value: "SXG",
  },
  {
    label: "Sesheke, Zambia (SJQ)",
    value: "SJQ",
  },
  {
    label: "Sesheke, Sesheke - Zambia (SJQ)",
    value: "SJQ",
  },
  {
    label: "Solwezi, Zambia (SLI)",
    value: "SLI",
  },
  {
    label: "Solwezi, Solwezi - Zambia (SLI)",
    value: "SLI",
  },
  {
    label: "Zambezi, Zambia (BBZ)",
    value: "BBZ",
  },
  {
    label: "Zambezi, Zambezi - Zambia (BBZ)",
    value: "BBZ",
  },
  {
    label: "Bulawayo, Zimbabwe (BUQ)",
    value: "BUQ",
  },
  {
    label: "Bulawayo, Joshua M.Nkomo Intl - Zimbabwe (BUQ)",
    value: "BUQ",
  },
  {
    label: "Bumi Hills, Zimbabwe (BZH)",
    value: "BZH",
  },
  {
    label: "Bumi Hills, Bumi Hills Airfield - Zimbabwe (BZH)",
    value: "BZH",
  },
  {
    label: "Chipinge, Zimbabwe (CHJ)",
    value: "CHJ",
  },
  {
    label: "Chipinge, Chipinge - Zimbabwe (CHJ)",
    value: "CHJ",
  },
  {
    label: "Chiredzi, Zimbabwe (BFO)",
    value: "BFO",
  },
  {
    label: "Chiredzi, Buffalo Range - Zimbabwe (BFO)",
    value: "BFO",
  },
  {
    label: "Gweru, Zimbabwe (GWE)",
    value: "GWE",
  },
  {
    label: "Gweru, Gweru - Zimbabwe (GWE)",
    value: "GWE",
  },
  {
    label: "Harare, Zimbabwe (HRE)",
    value: "HRE",
  },
  {
    label: "Harare, International - Zimbabwe (HRE)",
    value: "HRE",
  },
  {
    label: "Hwange, Zimbabwe (WKI)",
    value: "WKI",
  },
  {
    label: "Hwange, Hwange - Zimbabwe (WKI)",
    value: "WKI",
  },
  {
    label: "Hwange National Park, Zimbabwe (HWN)",
    value: "HWN",
  },
  {
    label: "Hwange National Park, Hwange National Park - Zimbabwe (HWN)",
    value: "HWN",
  },
  {
    label: "Kariba, Zimbabwe (KAB)",
    value: "KAB",
  },
  {
    label: "Kariba, Kariba - Zimbabwe (KAB)",
    value: "KAB",
  },
  {
    label: "Mahenye, Mahenye Airfield - Zimbabwe (MJW)",
    value: "MJW",
  },
  {
    label: "Mahenye, Mahenye Airfield - Zimbabwe (MJW)",
    value: "MJW",
  },
  {
    label: "Masvingo, Zimbabwe (MVZ)",
    value: "MVZ",
  },
  {
    label: "Masvingo, Masvingo - Zimbabwe (MVZ)",
    value: "MVZ",
  },
  {
    label: "Mutare, Zimbabwe (UTA)",
    value: "UTA",
  },
  {
    label: "Mutare, Mutare - Zimbabwe (UTA)",
    value: "UTA",
  },
  {
    label: "Victoria Falls, Zimbabwe (VFA)",
    value: "VFA",
  },
  {
    label: "Victoria Falls, International - Zimbabwe (VFA)",
    value: "VFA",
  },
];

// let AirPorts = document.getElementById("AirPorts");
// AirPorts.innerHTML = Airports_Options.map((i) => `<option>${i.label}</option>`);
