import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  bookingCruises: {},

  loadingBooking: false,
  errorBooking: null,
};

export const fetchBookingCruises = createAsyncThunk(
  "bookingCruises/fetchBookingCruises",
  async ({
    cruises_ItineraryId,
    cruises_startDateCruises,
    cruises_adultsCruises,
    cruises_childrenCruises,
    searchTerm,
    currency,
    language,
  }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_NileCruise/${language}/CruiseBooking?ItineraryTypeId=${cruises_ItineraryId}&search=${searchTerm}&ItineraryDate=${cruises_startDateCruises}&AdultNo=${cruises_adultsCruises}&ChildNo=${cruises_childrenCruises}&currency=${currency}`
    );
    return response.data;
  }
);

const bookingCruisesSlice = createSlice({
  name: "bookingCruises",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookingCruises.pending, (state) => {
        state.loadingBooking = true;
      })

      .addCase(fetchBookingCruises.fulfilled, (state, action) => {
        state.loadingBooking = false;
        state.bookingCruises = action.payload;
      })

      .addCase(fetchBookingCruises.rejected, (state, action) => {
        state.loadingBooking = false;
        state.errorBooking = action.error.message;
      });
  },
});

export default bookingCruisesSlice.reducer;
