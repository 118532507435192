import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";
import { refreshAccessToken } from "../authUtils.js";

const initialState = {
  transfersOrders: {},
  loading: false,
  error: null,
};

const fetchTransfersOrder = async ({ orderId, language }) => {
  let token = localStorage.getItem("accessToken"); // Retrieve the token from local storage

  try {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_User/${language}/TransferOrder?OrderId=${orderId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Set the Bearer token in the headers
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      token = await refreshAccessToken(); // Refresh the access token
      const retryResponse = await axios.get(
        `${baseUrl}/apiTITSol/TTG24_User/${language}/TransferOrder?OrderId=${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Set the new Bearer token in the headers
          },
        }
      );

      return retryResponse.data;
    } else {
      throw new Error("Failed to fetch transfersOrders");
    }
  }
};

export const fetchTransfersOrders = createAsyncThunk(
  "transfersOrders/fetchTransfersOrders",
  async ({ orderId, language }) => {
    const response = await fetchTransfersOrder({ orderId, language });
    return response;
  }
);

const transfersOrdersSlice = createSlice({
  name: "transfersOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransfersOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTransfersOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.transfersOrders = action.payload;
      })
      .addCase(fetchTransfersOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default transfersOrdersSlice.reducer;
