import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  destinationHandbook: {},
  loading: false,
  error: null,
};

export const fetchDestinationHandbook = createAsyncThunk(
  "destinationHandbook/fetchDestinationHandbook",
  async (language) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Destinations/${language}/DestinationsHandbook`
    );
    return response.data;
  }
);

const DestinationHandbookSlice = createSlice({
  name: "destinationHandbook",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDestinationHandbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDestinationHandbook.fulfilled, (state, action) => {
        state.loading = false;
        state.destinationHandbook = action.payload;
      })
      .addCase(fetchDestinationHandbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default DestinationHandbookSlice.reducer;
