import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";
import { refreshAccessToken } from "../authUtils.js";

export const updatePassword = createAsyncThunk(
  "password/update",
  async (passwordData, { rejectWithValue }) => {
    let token = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(
        "https://api.travco.com/apiTITSol/TTG24_User/UpdatePassword",
        passwordData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        token = await refreshAccessToken(); // Refresh the access token
        const retryResponse = await axios.get(
          `${baseUrl}/apiTITSol/TTG24_User/UpdatePassword`,
          passwordData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Set the new Bearer token in the headers
            },
          }
        );

        return retryResponse.data;
      } else {
        throw new Error("Failed to fetch profile");
      }
    }
  }
);
const passwordUpdateSlice = createSlice({
  name: "passwordUpdate",
  initialState: {
    loading: false,
    success: false,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePassword.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload || "Failed to update password";
      });
  },
});

export const { resetState } = passwordUpdateSlice.actions;
export default passwordUpdateSlice.reducer;
