import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  loading: false,
  token: "",
  refreshToken: "",
  error: null,
  message: "",
  user: {
    firstName: "",
    lastName: "",
  },
};

export const login = createAsyncThunk(
  "login/userLogin",
  async (user, thunkAPI) => {
    try {
      const res = await fetch(`${baseUrl}/apiTITSol/TTG24_User/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (!res.ok) {
        const errorData = await res.json();
        return thunkAPI.rejectWithValue(errorData);
      }

      const data = await res.json();
      const tokenExpiryTime = new Date();
      tokenExpiryTime.setSeconds(tokenExpiryTime.getSeconds() + data.expiresIn);
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      localStorage.setItem("tokenExpiry", tokenExpiryTime);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: "Failed to login. Please try again.",
      });
    }
  }
);

export const loginWithGoogle = createAsyncThunk(
  "login/loginWithGoogle",
  async (idToken, thunkAPI) => {
    try {
      const apiEndpoint =
        "https://api.travco.com/apiTITSol/TTG24_User/LoginWithGoogle";
      const response = await fetch(`${apiEndpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ idToken: idToken }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        return thunkAPI.rejectWithValue(errorData);
      }

      const data = await response.json();
      const tokenExpiryTime = new Date();
      tokenExpiryTime.setSeconds(tokenExpiryTime.getSeconds() + data.expiresIn);
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      localStorage.setItem("tokenExpiry", tokenExpiryTime);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: "Failed to login with Google. Please try again.",
      });
    }
  }
);

export const refresh = createAsyncThunk(
  "login/refreshToken",
  async (_, thunkAPI) => {
    try {
      const refreshToken = thunkAPI.getState().login.refreshToken;
      const res = await fetch(`${baseUrl}/apiTITSol/TTG24_User/refresh-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken }),
      });

      if (!res.ok) {
        throw new Error("Failed to refresh token");
      }

      const data = await res.json();
      const tokenExpiryTime = new Date();
      tokenExpiryTime.setSeconds(tokenExpiryTime.getSeconds() + data.expiresIn);
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("tokenExpiry", tokenExpiryTime);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: "Failed to refresh access token",
      });
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout(state) {
      state.loading = false;
      state.token = "";
      state.refreshToken = "";
      state.error = null;
      state.message = "";
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("tokenExpiry");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.message = action.payload.message;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error || "An unknown error occurred";
      })
      .addCase(loginWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.message = action.payload.message;
      })
      .addCase(loginWithGoogle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error || "Failed to login with Google";
      })
      .addCase(refresh.pending, (state) => {
        state.loading = true;
      })
      .addCase(refresh.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.accessToken;
      })
      .addCase(refresh.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error || "Failed to refresh token";
        state.message = action.payload.title || "Failed to refresh token";
      });
  },
});

export const { logout } = loginSlice.actions;

export default loginSlice.reducer;
