import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

// Define the initial state of the cstDataForCruises
const initialState = {
  cstDataForCruises: {},
  loadingCstDataForCruises: false,
  errorCstDataForCruises: null,
};

// Define an async thunk to send the cstDataForCruises data
export const postCstDataForCruises = createAsyncThunk(
  "cstDataForCruises/postCstDataForCruises",
  async ({ cstDataForCruisesData, language }) => {
    const response = await axios.post(
      `${baseUrl}/apiTITSol/TTG24_NileCruise/${language}/opInsertOrderCruise`,
      cstDataForCruisesData
    );
    return response.data;
  }
);
const cstDataForCruisesSlice = createSlice({
  name: "cstDataForCruises",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postCstDataForCruises.pending, (state) => {
        state.loadingCstDataForCruises = true;
      })
      .addCase(postCstDataForCruises.fulfilled, (state, action) => {
        state.loadingCstDataForCruises = false;
        state.cstDataForCruises = action.payload;
      })
      .addCase(postCstDataForCruises.rejected, (state, action) => {
        state.loadingCstDataForCruises = false;
        state.errorCstDataForCruises = action.error.message;
      });
  },
});

export default cstDataForCruisesSlice.reducer;
