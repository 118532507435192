import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  miceDetails: {},
  loading: false,
  error: null,
};

export const fetchMiceDetails = createAsyncThunk(
  "miceDetails/fetchMiceDetails",
  async ({ serviceUrl, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_MICE/${language}/${serviceUrl}`
    );
    return response.data;
  }
);

const miceDetailsSlice = createSlice({
  name: "miceDetails",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMiceDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMiceDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.miceDetails = action.payload;
      })
      .addCase(fetchMiceDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default miceDetailsSlice.reducer;
