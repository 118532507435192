import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { juniperUrl } from "./juniperUrl.js";

export const fetchPayment = createAsyncThunk(
  "payment/fetchPayment",
  async ({ BookingId, currency }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${juniperUrl}/opPaymentHotelBooking?BookingId=${BookingId}&currency=${currency}`
      );
      return { data: response.data };
      // return { BookingId, data: response.data };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    payment: {},
    loadingPayment: false,
    errorPayment: null,
    isErrorPayment: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayment.pending, (state) => {
        state.loadingPayment = true;
        state.isErrorPayment = false;
      })
      .addCase(fetchPayment.fulfilled, (state, action) => {
        state.loadingPayment = false;
        state.isErrorPayment = false;
        state.payment = action.payload.data;
      })
      .addCase(fetchPayment.rejected, (state, action) => {
        state.loadingPayment = false;
        state.isErrorPayment = true;
        state.errorPayment = action.error.message;
      });
  },
});

export default paymentSlice.reducer;
