import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  transfersSearch: {},
  pageIndex: 1,

  loading: false,
  error: null,
};

export const fetchTransfersSearch = createAsyncThunk(
  "transfersSearch/fetchTransfersSearch",
  async ({
    pageIndex,
    DateValues,
    searchTerm,
    currency,
    Sort,
    selectedStyle,
    selectedDestination,
    participantNumber,
    language,
  }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_Transfers/${language}/GetTransfers?PageIndex=${pageIndex}&DateValues=${DateValues}&ParticipantNumber=${participantNumber}&Search=${searchTerm}&Sort=${Sort}&currency=${currency}&styles=${selectedStyle.join(
        ","
      )}&destinations=${selectedDestination.join(",")}`
    );
    return response.data;
  }
);

const transfersSearchSlice = createSlice({
  name: "transfersSearch",
  initialState,
  reducers: {
    setPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransfersSearch.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchTransfersSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.transfersSearch = action.payload;
        state.pageIndex = action.payload.pageIndex;
      })

      .addCase(fetchTransfersSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setPageIndex } = transfersSearchSlice.actions;

export default transfersSearchSlice.reducer;
