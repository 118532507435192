import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  checkout: {},

  loadingCheckout: false,
  errorCheckout: null,
};

export const fetchCheckout = createAsyncThunk(
  "checkout/fetchCheckout",
  async ({
    OPPriceID,
    cruises_adultsCruises,
    cruises_childrenCruises,
    currency,
    language,
  }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_NileCruise/${language}/BookingCruise?OPPriceID=${OPPriceID}&AdultNo=${cruises_adultsCruises}&ChildNo=${cruises_childrenCruises}&currency=${currency}`
    );
    return response.data;
  }
);

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckout.pending, (state) => {
        state.loadingCheckout = true;
      })

      .addCase(fetchCheckout.fulfilled, (state, action) => {
        state.loadingCheckout = false;
        state.checkout = action.payload;
      })

      .addCase(fetchCheckout.rejected, (state, action) => {
        state.loadingCheckout = false;
        state.errorCheckout = action.error.message;
      });
  },
});

export default checkoutSlice.reducer;
