import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  affiliatedCompaniesDetails: {},

  loading: false,
  error: null,
};

export const fetchAffiliatedCompaniesDetails = createAsyncThunk(
  "affiliatedCompaniesDetails/fetchAffiliatedCompaniesDetails",
  async ({ affiliatedCompaniesUrl, language }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_AffiliatedCompanies/${language}/${affiliatedCompaniesUrl}`
    );
    return response.data;
  }
);

const affiliatedCompaniesDetailsSlice = createSlice({
  name: "affiliatedCompaniesDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAffiliatedCompaniesDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAffiliatedCompaniesDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.affiliatedCompaniesDetails = action.payload;
      })
      .addCase(fetchAffiliatedCompaniesDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default affiliatedCompaniesDetailsSlice.reducer;
