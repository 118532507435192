import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../baseUrl.js";

const initialState = {
  travelIdeaExplore: {},
  pageIndex: 1,
  totalPages: 1,
  loading: false,
  error: null,
};

export const fetchTravelIdeaExplore = createAsyncThunk(
  "travelIdeaExplore/fetchTravelIdeaExplore",
  async ({
    StyleURL,
    pageIndex,
    searchTerm,
    currency,
    Sort,
    destinations,
    durations,
    language,
  }) => {
    const response = await axios.get(
      `${baseUrl}/apiTITSol/TTG24_TravelIdea/${language}/${StyleURL}?PageIndex=${pageIndex}&Sort=${Sort}&Search=${searchTerm}&currency=${currency}&destinations=${destinations.join(
        ","
      )}&durations=${durations.join(",")}`
    );
    return response.data;
  }
);

const travelIdeaExploreSlice = createSlice({
  name: "travelIdeaExplore",
  initialState,
  reducers: {
    setPageIndex(state, action) {
      state.pageIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTravelIdeaExplore.pending, (state) => {
        state.loading = true;
      })

      .addCase(fetchTravelIdeaExplore.fulfilled, (state, action) => {
        state.loading = false;
        state.travelIdeaExplore = action.payload;
        state.pageIndex = action.payload.pageIndex;
        state.totalPages = action.payload.totalPages;
      })

      .addCase(fetchTravelIdeaExplore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setPageIndex } = travelIdeaExploreSlice.actions;

export default travelIdeaExploreSlice.reducer;
